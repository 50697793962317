import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const Label = ({ ...rest }) => <Typography fontSize={16} {...rest} />;

const ViewOnly = ({ label, value, labelStyle, valueStyle, rest }) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        ...(rest && { ...rest }),
      }}
    >
      <Label
        style={{ fontWeight: "600", ...(labelStyle && { ...labelStyle }) }}
      >
        {label}
      </Label>
      <Box style={{ ...(valueStyle && { ...valueStyle }) }}>{value}</Box>
    </Box>
  );
};

ViewOnly.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]),
  labelStyle: PropTypes.object,
  valueStyle: PropTypes.object,
};

export default ViewOnly;
