import { dateConverter } from "../makeData";
import { CreditNotesSellerPayout } from "./constants";

const { creditYup, creditFieldErrors } = CreditNotesSellerPayout;

export const validateDate = (date) => {
  return date ? new Date(date) : "";
};

export const validateBoolean = (value) =>
  value?.toLowerCase() === "true" ? true : false;

/**
 * @param {*} parsedData
 * @description returns parsed data in required format
 */

export const generateOffErpRequestData = (parsedData) => {
  const offOMSData = [];
  parsedData?.forEach((ele, _) => {
    if (Object.keys(ele).length >= 12) {
      let offOMSMapper = {};
      offOMSMapper.transactionType = ele["Document type"];
      offOMSMapper.fromAccount = ele["From account"];
      offOMSMapper.toAccount = ele["To account"];
      offOMSMapper.originalInvoiceId =
        ele[`Original invoices (";" separated)`]?.split(";");
      offOMSMapper.documentId = ele["Document number"];
      offOMSMapper.documentDate = dateConverter(ele["Document date"]);
      offOMSMapper.invoiceComments = ele["Comments"];
      offOMSMapper.documentAmount = ele["Document amount"];
      offOMSMapper.gstAmount = ele["GST amount"];
      offOMSMapper.reverseTcsTds = validateBoolean(
        ele["Reverse TCS/TDS transactions?"],
      );
      offOMSMapper.payoutRequired = validateBoolean(ele["Payout required?"]);
      offOMSData.push(offOMSMapper);
    }
  });
  return offOMSData;
};

export const getError = (errors, unique, field, txErrors) => {
  if (txErrors && Object.keys(txErrors)?.includes(field)) {
    return creditFieldErrors[field];
  }
  return errors?.[creditYup]?.[unique]?.[field]?.message;
};

export const validateData = (data) => {
  return data ? data : "-";
};
