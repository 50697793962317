import { Card, Box } from "@mui/material";

import theme from "themeProvider";

export const UserCard = ({ cardHeader, cardBody, cardFooter }) => {
  return (
    <Card
      style={{
        minWidth: "18%",
        width: "fit-content",
        height: "80vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.grey[100],
      }}
    >
      <Box p={6} style={{ backgroundColor: theme.palette.grey[300] }}>
        {cardHeader}
      </Box>

      <Box
        display={"flex"}
        flexDirection={"column"}
        height={"-webkit-fill-available"}
        justifyContent={"space-between"}
      >
        {cardBody}
      </Box>
      <Box
        p={3}
        pl={5}
        style={{
          backgroundColor: theme.palette.grey[300],
        }}
      >
        {cardFooter}
      </Box>
    </Card>
  );
};

export default UserCard;
