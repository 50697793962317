import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { ButtonV1, ExportCta, ModalBox } from "components";
import OrderBlockTable from "./OrderBlockTable";
import OrderBlockDisbursement from "./OrderBlockDisbursement";
import { QuickFilter } from "../components";

import { useFetchData, useToaster } from "hooks";
import { deleteCall, getCall_v2, postCall_v2 } from "services";
import { downloadCsv } from "utils";

import { error, success } from "constants";
import { FinancePayouts } from "../../constants";

const { quick_filter } = FinancePayouts;

const endpointUrl = "/oms/off-oms-payout/order-block-summaries";
const rowsPerPage = 50;
const initialQuickFilter = "all";

const OrderBlock = ({ filters }) => {
  const [selectedPayout, setSelectedPayout] = useState([]);
  const [page, setPage] = useState(0);
  const [disbursement, setDisbursement] = useState(null);
  const [selectedQuickFilter, setSelectedQuickFilter] =
    useState(initialQuickFilter);

  const handleQuickFilter = useCallback(
    (value) => setSelectedQuickFilter(value),
    [],
  );

  const triggerToaster = useToaster();

  const orderBlockFilter = useMemo(() => {
    const appliedFilter = {};
    Object.entries(filters).forEach((entries) => {
      if (!!entries[1]) {
        appliedFilter[entries[0]] = entries[1];
      }
    });

    return {
      limit: rowsPerPage,
      filterType: selectedQuickFilter,
      ...appliedFilter,
    };
  }, [selectedQuickFilter, filters]);

  const {
    refetch: getOrderBlockList,
    data: orderBlockList,
    isFetching: fetchingOrderBlock,
  } = useFetchData("order-block-pending-payouts", endpointUrl, null, null, {
    ...orderBlockFilter,
    offset: page * rowsPerPage,
  });

  const handlePageChange = useCallback((_event, pageNumber) => {
    setPage(pageNumber);
  }, []);

  const handleRejection = useCallback(async (payoutId) => {
    const res = await deleteCall(
      `oms/off-oms-payout/order-block/delete?payoutId=${payoutId}`,
    );
    if (!res) {
      triggerToaster("Something went wrong", error);
    } else {
      triggerToaster("Order block is successfully deleted", success);
      getOrderBlockList();
    }
  }, []);

  const handleInitiate = useCallback(async (payoutIds) => {
    try {
      const payload = { ids: payoutIds };
      await postCall_v2("/oms/payout/order-block-payouts", payload);
      getOrderBlockList();
      setDisbursement(null);
      triggerToaster("Payout has been initated successfuly", success);
    } catch (err) {
      triggerToaster("Payout initation failed", error);
    }
  }, []);

  const handleSelectPayout = useCallback((value) => {
    setSelectedPayout(value);
  }, []);

  const handleCancelDisbursement = useCallback(() => setDisbursement(null), []);

  const handleConfirmationModal = useCallback(() => {
    const data = [];
    selectedPayout.forEach((id) => {
      const {
        entityGstin,
        orderId,
        payoutId,
        advanceAmount,
        creditAmount,
        creditInstrument,
      } = orderBlockList?.data?.payoutSummaries?.find(
        ({ payoutId }) => id === payoutId,
      );

      const obj = {
        entityGstin,
        orderId,
        payoutId,
        advanceAmount,
        creditAmount,
        creditInstrument,
      };
      data.push(obj);
    });
    setDisbursement(data);
  }, [orderBlockList, selectedPayout]);

  useEffect(() => {
    if (page) {
      setPage(0);
    } else {
      getOrderBlockList();
    }
  }, [orderBlockFilter]);

  useEffect(() => {
    getOrderBlockList();
  }, [page]);

  const onExportCSV = async () => {
    try {
      const payoutUrl = `oms/off-oms-payout/download/order-block-summaries`;
      const { data } = await getCall_v2(payoutUrl, {
        ...orderBlockFilter,
        limit: null,
      });
      triggerToaster(`Downloading file`, success);
      downloadCsv(data, "order_block_payouts.csv");
    } catch (err) {
      console.error(`Error in download, ${err}`);
      triggerToaster(`Please try again later`, error);
    }
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"baseline"}
      >
        <Typography fontWeight={600}>Order payout</Typography>
        <Box sx={{ display: "flex", flexDirection: "row", marginBottom: -4 }}>
          <Box sx={{ marginRight: 3 }}>
            <ExportCta onExport={onExportCSV} />
          </Box>
          <ButtonV1
            title={"Initiate"}
            size={"small"}
            disabled={!selectedPayout?.length}
            onClick={handleConfirmationModal}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box display={"flex"}>
          {Object.keys(quick_filter).map((elem, ind) => (
            <QuickFilter
              key={ind}
              label={quick_filter[elem]}
              value={elem}
              onClick={handleQuickFilter}
              selectedFilter={selectedQuickFilter}
              filterCount={orderBlockList?.data?.totalCount}
            />
          ))}
        </Box>
      </Box>
      <OrderBlockTable
        isLoading={fetchingOrderBlock}
        data={orderBlockList?.data}
        onReject={handleRejection}
        onInitate={handleInitiate}
        selectedPayout={selectedPayout}
        onSelectedPayout={handleSelectPayout}
        onPageChange={handlePageChange}
      />
      <ModalBox
        open={!!disbursement}
        onCloseModal={handleCancelDisbursement}
        width="50%"
      >
        <>
          <OrderBlockDisbursement data={disbursement} />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <ButtonV1
              variant="contained"
              color="secondary"
              title="Confirm"
              size="medium"
              onClick={async () => await handleInitiate(selectedPayout)}
            />
          </Box>
        </>
      </ModalBox>
    </Box>
  );
};

OrderBlock.propTypes = {
  filter: PropTypes.object,
};

export default OrderBlock;
