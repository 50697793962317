import { useMemo } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import { StyledTableRow } from "CustomStyledComponents";
import { validateNull, moneyFormat } from "utils";
import { ZOHO_ORDER_CONSTANTS } from "../constants";

const BlockStatusTable = ({ blockStatusList }) => {
  const tableFields = useMemo(() => {
    return [
      {
        title: "Block ID",
        field: "blockId",
        render: (ele) => (
          <Typography style={{ width: 120 }} fontSize={14}>
            {validateNull(ele?.blockId)}
          </Typography>
        ),
      },
      {
        title: "Block type",
        field: "blockType",
        render: (ele) => (
          <Typography style={{ width: 120 }} fontSize={14}>
            {validateNull(ele?.blockType)}
          </Typography>
        ),
      },
      {
        title: "Credit amount",
        field: "creditAmount",
        render: (ele) => (
          <Typography style={{ width: 120 }} fontSize={14}>
            {moneyFormat(ele?.creditAmount)}
          </Typography>
        ),
      },
      {
        title: "Advance amount",
        field: "advanceAmount",
        render: (ele) => (
          <Typography fontSize={14}>
            {moneyFormat(ele?.advanceAmount)}
          </Typography>
        ),
      },
      {
        title: "Status",
        field: "status",
        render: (ele) => (
          <Typography fontSize={14}>
            {ZOHO_ORDER_CONSTANTS[ele?.status]}
          </Typography>
        ),
      },
    ];
  }, []);

  return (
    <Box p={2}>
      <Box component={Paper}>
        <TableContainer
          sx={{
            minWidth: 700,
            maxHeight: "calc(100vh - 120px)",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              {tableFields?.map((el) => (
                <TableCell key={el.field}>{el.title}</TableCell>
              ))}
            </TableHead>
            <TableBody>
              {!blockStatusList?.length ? (
                <StyledTableRow>
                  <TableCell colSpan={tableFields.length} align="center">
                    <Typography textAlign={"center"}>No data found</Typography>
                  </TableCell>
                </StyledTableRow>
              ) : (
                blockStatusList?.map((row) => (
                  <StyledTableRow key={row?.shipmentNumber}>
                    {tableFields?.map((el) => (
                      <TableCell key={el?.field}>{el.render(row)}</TableCell>
                    ))}
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

BlockStatusTable.propTypes = {
  blockStatusList: PropTypes.array,
};
export default BlockStatusTable;
