export const getFormattedUserList = (userlist, groupList) => {
  let formattedGroupList = [];
  groupList.forEach((group) => {
    const groupUserList = (group?.users || []).map((user) => {
      const userClone = { ...user };
      userClone.groupName = group?.groups?.[0]?.name;
      return userClone;
    });
    formattedGroupList = [...formattedGroupList, ...groupUserList];
  });

  const formattedList = userlist.map((user) => {
    const filteredGroup = formattedGroupList.filter(
      (group) => group.id === user?.users?.[0].id,
    );
    const userClone = { ...user };
    userClone.userGroups = filteredGroup;
    return userClone;
  });
  return formattedList;
};
