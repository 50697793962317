import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import InvoiceTable from "./InvoiceTable";
import PayoutPrompt from "../components/PayoutPrompt";
import ActionCta from "../components/ActionCta";
import { ExportCta, ButtonV1 } from "components";
import MarkSuccessPrompt from "../components/MarkSuccessPrompt";
import UploadRedirectCTA from "../components/UploadRedirectCTA";
import { useFetchData, useToaster } from "hooks";
import { truncateTx } from "dataStore/approvedCheckedTx";

import { downloadCsv } from "utils";
import { getCall_v2, postCall_v2 } from "services";
import { success, error } from "constants";
import { FinancePayouts } from "pages/FinancePayout/constants";
import { createPayoutCSV } from "../makeData";

const { APPROVAL_ACTION_TYPE, MARK_SUCCESS_ERROR_MSG, PAYMENT_IN_PROGRESS } =
  FinancePayouts;

const rowsPerPage = 50;

const InvoiceSeller = ({ transactionType, quickFilter, filters }) => {
  const [page, setPage] = useState(0);
  const [actionType, setActionType] = useState();
  const [disbursementData, setDisbursementData] = useState();
  const [checkBox, setCheckBox] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [selectedTx, setSelectedTx] = useState([]);

  const dispatch = useDispatch();
  const checkedTx = useSelector((state) => state.approvedCheckedTx);
  const triggerToaster = useToaster();
  const invoiceTableRef = useRef();

  const fetchApiParams = useMemo(
    () => ({
      limit: rowsPerPage,
      transactionType: transactionType,
      filterType: quickFilter,
      ...filters,
    }),
    [transactionType, quickFilter, filters],
  );

  const {
    data: approvedTransactions,
    isFetching,
    refetch: refetchApprovedList,
  } = useFetchData(
    "fetch-approval-invoice",
    "/oms/payout/approved-payouts",
    null,
    null,
    { ...fetchApiParams, offset: page * rowsPerPage },
  );

  /**
   * @description Handle pagination
   */
  const handlePageChange = useCallback(
    (_event, pageNumber) => setPage(pageNumber),
    [],
  );

  const onExportData = async () => {
    try {
      const payoutUrl = `/oms/payout/download/approved-payouts`;
      const { data } = await getCall_v2(payoutUrl, {
        ...fetchApiParams,
        limit: 5000,
        offset: 0,
      });
      triggerToaster(`Downloading file`, success);
      downloadCsv(data, "approved_invoice_seller_payouts");
    } catch (err) {
      console.error(`Error in download, ${err}`);
      triggerToaster(`Please try again later`, error);
    }
  };

  const makeDisbursement = (paymentAction = null) => {
    const disbursementData = {};

    if (paymentAction === PAYMENT_IN_PROGRESS) {
      checkedTx.forEach((ele) => {
        disbursementData[ele?.payoutId] = {
          payoutId: ele?.payoutId,
          orderType: ele?.orderType,
          orderId: ele?.customerOrdNumber,
          payoutEntity: ele?.entityName,
          documentAmount: ele?.shipmentInvoiceDetails.totalInvoicedAmount,
          payoutAmount: ele?.amount,
          documentId:
            ele?.shipmentInvoiceDetails.invoiceDetails[0].invoiceNumber,
          shipmentId: ele?.shipmentId,
          fundAccountDetails: ele?.fundAccountDetails,
          selectedFundAccountId:
            ele?.selectedFundAccountId ||
            ele?.defaultFundAccountId ||
            ele?.fundAccountDetails?.sellerAccount,
        };
      });
    } else {
      approvedTransactions.data.payoutSummaries.forEach((ele) => {
        if (checkBox.includes(ele?.payoutId)) {
          disbursementData[ele?.payoutId] = {
            payoutId: ele?.payoutId,
            orderType: ele?.orderType,
            orderId: ele?.customerOrdNumber,
            payoutEntity: ele?.entityName,
            documentAmount: ele?.shipmentInvoiceDetails.totalInvoicedAmount,
            payoutAmount: ele?.amount,
            documentId:
              ele?.shipmentInvoiceDetails.invoiceDetails[0].invoiceNumber,
            shipmentId: ele?.shipmentId,
            fundAccountDetails: ele?.fundAccountDetails,
            selectedFundAccountId:
              ele?.selectedFundAccountId ||
              ele?.defaultFundAccountId ||
              ele?.fundAccountDetails?.sellerAccount,
          };
        }
      });
    }
    setDisbursementData(disbursementData);
  };

  const handlePayout = (action) => {
    makeDisbursement(action);
    setActionType(action);
  };

  const handleCloseDisbursement = useCallback(() => {
    setActionType(null);
  }, []);

  const handleCloseAndReload = useCallback(() => {
    setActionType(null);
    window.location.reload();
  }, []);

  const handleInitiatePayout = async (tx) => {
    setLoading(true);
    const payload = {
      payoutRequestDTOList: tx,
      nextState: {
        state: actionType,
      },
    };
    try {
      if (actionType === APPROVAL_ACTION_TYPE.payout) {
        //temporary fix
        const temp = [];
        tx.map((ele) => {
          temp.push(ele.id);
        });
        setSelectedTx(temp);
        //
        await postCall_v2("/oms/payout/initiate-payouts", payload);
      } else {
        await postCall_v2("/oms/payout/approve-payouts", payload);
      }

      handleCloseDisbursement();
      setDisbursementData(null);
      setCheckBox([]);
      setTimeout(() => {
        refetchApprovedList();
      }, 1000);
      setLoading(false);
    } catch (err) {
      triggerToaster("Unable to initate the process. Try again", error);
      console.warn("handleInitiatePayout:- ", err);
      setLoading(false);

      setSelectedTx([]);
    }
  };

  const onMarkSuccessful = () => {
    let isError = false;
    let data = approvedTransactions?.data?.payoutSummaries;
    for (let i = 0; i < data.length; i++) {
      if (checkBox.includes(data[i]?.payoutId)) {
        if (data[i]?.status === APPROVAL_ACTION_TYPE.payout) {
          isError = true;
          triggerToaster(MARK_SUCCESS_ERROR_MSG, error);
          break;
        }
      }
    }
    if (!isError) {
      setSuccessModalOpen(true);
      makeDisbursement();
    }
  };

  const handleMarkSuccessful = async (tx) => {
    setLoading(true);
    const payload = {
      payoutRequestDTOList: tx,
    };
    try {
      await postCall_v2("oms/payout/status-change-for-invoice-payout", payload);
      handleCloseDisbursement();
      setSuccessModalOpen(false);
      setDisbursementData(null);
      setCheckBox([]);
      refetchApprovedList();
    } catch (err) {
      triggerToaster("Unable to initate the process. Try again", error);
      console.warn("handleMarkSuccessful:- ", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (page) {
      setPage(0);
    } else {
      refetchApprovedList();
    }
  }, [fetchApiParams]);

  useEffect(() => {
    refetchApprovedList();
    invoiceTableRef.current.unSelectCheckAllBox();
  }, [page]);

  /**
   * @description Download checked transactions
   * produce csv
   */
  const downloadApprovedTx = async (tx) => {
    try {
      const payload = checkedTx?.map((ele) => {
        const { payoutId, payoutType } = ele;
        const selectedTx = tx.find((ele) => ele.payoutId === payoutId);
        return {
          payoutId,
          payoutType,
          fundAccountId: selectedTx?.selectedFundAccountId,
        };
      });
      const toBeDownloadedTx = await postCall_v2(
        "/oms/payout/download-payout",
        payload,
      );
      const { data } = toBeDownloadedTx;
      if (!data || !data.length) {
        triggerToaster("No tx to be downloaded", error);
        return;
      }
      if (data) {
        await createPayoutCSV(data);
        triggerToaster(
          "Selected transactions downloaded successfully",
          success,
        );
      }
    } catch (ex) {
      console.error(`Exception occurred : ${ex}`);
    } finally {
      handleCloseDisbursement(); //hides modal
      setDisbursementData(null);
      setCheckBox([]);
      setSelectedTx([]);
      dispatch(truncateTx());
    }
  };

  /**
   * @description Download tx based on tx status
   */
  const downloadTx = async () => {
    try {
      const {
        status = "",
        maxTimestamp = "",
        minTimestamp = "",
        transactionType = "",
        payoutType = "",
        orderType = "",
        orderNumber = "",
      } = fetchApiParams;

      const resultSheet = await getCall_v2(
        `/oms/payout/download-processed-payout?payoutStatus=${status}&transactionType=${encodeURIComponent(
          transactionType,
        )}&maxTimestamp=${maxTimestamp}&minTimestamp=${minTimestamp}&payoutType=${payoutType}&orderNumber=${
          orderNumber ?? ""
        }&orderType=${orderType ?? ""}`,
      );
      const { data } = resultSheet;
      if (!data || !data.length) {
        triggerToaster("No transactions to be downloaded", error);
      } else {
        await createPayoutCSV(data);
        triggerToaster("Transactions downloaded successfully", success);
      }
    } catch (ex) {
      console.log(`Exception occurred ${ex}`);
    } finally {
      handleCloseDisbursement(); //hides modal
      setDisbursementData(null);
      setCheckBox([]);
      setSelectedTx([]);
      dispatch(truncateTx());
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 2,
          marginTop: "-2.8%",
          alignItems: "baseline",
          gap: 4,
        }}
      >
        <ExportCta onExport={onExportData} />
        <UploadRedirectCTA />
        <ButtonV1
          variant="outlined"
          color="secondary"
          title="Download filtered transactions"
          size="medium"
          onClick={downloadTx}
        />
        <ActionCta
          isEnabled={checkBox.length}
          onInitiatePayout={handlePayout}
          onMarkSuccessful={onMarkSuccessful}
        />
      </Box>
      <InvoiceTable
        approvedTransactions={approvedTransactions?.data}
        rowsPerPage={rowsPerPage}
        pageNumber={page}
        loading={isFetching}
        onPageChange={handlePageChange}
        setCheckBox={setCheckBox}
        checkBox={checkBox}
        selectedTx={selectedTx}
        ref={invoiceTableRef}
      />
      {actionType && (
        <PayoutPrompt
          actionType={actionType}
          onClosePayoutDisbursement={handleCloseAndReload}
          disbursementData={disbursementData}
          onInitiatePayout={handleInitiatePayout}
          onDownloadClick={downloadApprovedTx}
        />
      )}
      {isSuccessModalOpen && (
        <MarkSuccessPrompt
          actionType={isSuccessModalOpen}
          onClosePayoutDisbursement={handleCloseAndReload}
          disbursementData={disbursementData}
          onInitiatePayout={handleMarkSuccessful}
          loading={loading}
        />
      )}
    </>
  );
};

export default InvoiceSeller;
