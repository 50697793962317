import { Box } from "@mui/material";
import PropTypes from "prop-types";

import theme from "themeProvider";

const FloatingBar = ({
  children,
  bottom = 0,
  position = "fixed",
  width = "90%",
}) => {
  return (
    <Box display={"flex"} justifyContent={"center"}>
      <Box
        style={{
          position,
          bottom: bottom,
          height: 64,
          width: width,
          display: "flex",
          justifyContent: "flex-end",
          backgroundColor: "#ffffffe6",
          padding: 12,
          borderRadius: 12,
          boxShadow: `0 0 12px 6px ${theme.palette.grey["400"]}`,
          border: `1px solid ${theme.palette.grey["400"]}`,
          zIndex: 1,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default FloatingBar;

FloatingBar.propTypes = {
  children: PropTypes.element.isRequired,
  bottom: PropTypes.number,
};
