import { Grid, Box, Typography } from "@mui/material";
import theme from "themeProvider";

const HoverBlock = ({ data, firstColumnXs = 3, secondColumnXs = 9 }) => {
  return (
    data && (
      <Box
        borderRadius={1}
        maxWidth={280}
        minWidth={160}
        boxShadow={`0 0 3px 2px ${theme.palette.text.secondary}`}
        p={2}
      >
        {Object.entries(data).map(([key, value], i) => (
          <Grid
            container
            key={key}
            style={{
              border: Object.entries(data).length === i + 1 && 0,
              borderBottomStyle: "dotted",
              borderColor: theme.palette.text.secondary,
            }}
            pt={2}
            pb={2}
          >
            <Grid item xs={firstColumnXs}>
              <Typography fontSize={12}>{key}</Typography>
            </Grid>
            <Grid item xs={secondColumnXs}>
              <Typography fontSize={12} fontWeight={"bold"}>
                {value}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
    )
  );
};

export default HoverBlock;
