import { useCallback, useEffect, useMemo, useState } from "react";

import { postCall_v2 } from "services";
import { useFetchData, useToaster } from "hooks";
import OrderBlockTable from "./OrderBlockTable";
import { error, success } from "constants";

const rowsPerPage = 50;

const endPoint = `/oms/off-oms-payout/order-block/historical`;

const OrderBlock = ({ transactionType, filters }) => {
  const [page, setPage] = useState(0);

  const triggerToaster = useToaster();

  const orderBlockHistoricalParams = useMemo(() => {
    return {
      limit: rowsPerPage,
      transactionType,
      ...filters,
    };
  }, [filters, transactionType]);

  const {
    refetch: getHistoricalPayouts,
    data: historicalOrderBlockData,
    isFetching,
  } = useFetchData("historical-order-block-data", endPoint, null, null, {
    ...orderBlockHistoricalParams,
    offset: page * rowsPerPage,
  });

  /**
   * @description fetch order block history payouts during page load
   */
  useEffect(() => {
    if (page) {
      setPage(0);
    } else {
      getHistoricalPayouts();
    }
  }, [orderBlockHistoricalParams]);

  useEffect(() => {
    getHistoricalPayouts();
  }, [page]);

  /**
   * @description Handle pagination
   */
  const handlePageChange = (_event, pageNumber) => {
    setPage(pageNumber);
  };

  const handleCloseTx = useCallback(async (id) => {
    try {
      const payload = {
        ids: [id],
      };
      const res = await postCall_v2(
        "oms/payout/order-unblock-payouts",
        payload,
      );

      if (res.status === 200) {
        triggerToaster("Transaction is closed", success);
        getHistoricalPayouts();
      } else {
        triggerToaster("Transaction is not closed", error);
      }
    } catch (err) {
      console.error(`error in handleCloseTx`, err);
      triggerToaster("Something went wrong. Try after sometime", error);
    }
  }, []);

  return (
    <OrderBlockTable
      transactionType={transactionType}
      payoutData={historicalOrderBlockData?.data ?? {}}
      isLoading={isFetching}
      page={page}
      onPageChange={handlePageChange}
      onCloseTx={handleCloseTx}
      filters={orderBlockHistoricalParams}
    />
  );
};

export default OrderBlock;
