import { useQuery } from "react-query";
import axios from "services/axiosInstance";

export const useFetchData = (
  key = "key",
  url,
  successCallback,
  errorCallback,
  params = {},
  responseType = "json",
  enabled = false,
) => {
  const getCall = (url) => {
    return axios.get(url, {
      params,
      data: "",
      responseType,
    });
  };

  const errorHandler = (err) => {
    //common error handling done
    errorCallback && errorCallback(err);
  };

  const successHandler = (res) => {
    //common success handling to be done
    successCallback && successCallback(res);
  };

  return useQuery(key, () => getCall(url), {
    onSuccess: (res) => successHandler(res),
    onError: (err) => errorHandler(err),
    enabled: enabled,
    retry: false,
  });
};
