import {
  appendRupeeIcon,
  validateNull,
  moneyFormat,
  checkIsDirectOrder,
  checkIsPrivateBrand,
} from "utils";

import { seller, customer } from "./constants";
import { OrderDetails_constant } from "../constants";

const { OrderStatus } = OrderDetails_constant;

export const getCashbackData = (orderInfo) => {
  let sellerCashbackObject = {};
  let customerCashbackObject = {};
  if (orderInfo && orderInfo.hasOwnProperty("cashBacks")) {
    const cashBacks = orderInfo?.cashBacks;
    // eslint-disable-next-line array-callback-return
    Object.keys(cashBacks).map((item) => {
      if (cashBacks[item]["cashbackType"] === seller) {
        const {
          id,
          amount: { displayAmount },
        } = cashBacks[item];
        sellerCashbackObject[`${item}-${id}`] = displayAmount;
      } else if (cashBacks[item]["cashbackType"] === customer) {
        const {
          id,
          amount: { displayAmount },
        } = cashBacks[item];

        customerCashbackObject[`${item}-${id}`] = displayAmount;
      }
    });
    return { sellerCashbackObject, customerCashbackObject };
  }
};

export const getTooltipDetails = (details) => {
  if (details?.buyerDetails) {
    let tooltipDetails = Object.assign({}, details?.buyerDetails);
    const { gstin, billTo, shipTo, retailerName } = details?.buyerDetails;

    for (let item in tooltipDetails) {
      if (typeof tooltipDetails[item] === "object") {
        const address = tooltipDetails[item];
        address.streetLine
          ? (tooltipDetails[item] = `
              ${address?.streetLine},
              ${address.city},
              ${address.state},
              ${address.postalCode}`)
          : (tooltipDetails[item] = `${address.city},
              ${address.state},
              ${address.postalCode}`);
      }
    }

    let formattedTooltipDetails = {
      // "Mobile no.": tooltipDetails?.mobile,
      "Email id": tooltipDetails?.email,
      "Shipping address":
        tooltipDetails?.shippingAddress ?? `No billing address was specified`,
      "Billing address":
        tooltipDetails?.billingAddress ?? `No billing address was specified`,
      ...(gstin && { GSTIN: gstin }),
      ...((checkIsDirectOrder(details?.category) ||
        checkIsPrivateBrand(details?.category)) && {
        "Bill to": billTo,
        "Ship to": `${retailerName ? retailerName + ", " : ""} ${shipTo}`,
      }),
    };
    return formattedTooltipDetails;
  }
};

/**
 * * formats the discount object as label and value from api
 * @returns formattedDiscounts
 */
export const getFormattedDiscount = (orderInfo) => {
  let formattedDiscount = {};
  const discount = orderInfo?.discount?.length && orderInfo?.discount[0];
  if (discount) {
    formattedDiscount = {
      "Coupon code": discount?.code,
      "Coupon name": discount?.name,
      Description: discount?.description,
      "Discount Type": validateNull(discount?.discountType),
    };
  }
  return Object.keys(formattedDiscount).length ? formattedDiscount : null;
};

const getFloatValue = (displayAmount) => {
  return displayAmount ? parseFloat(displayAmount.replaceAll(",", "")) : 0;
};

const pendingAmountCheck = (amounts, orderStatus, totalInvoiceAmount) => {
  let result = 0;
  const totalPaidAmount = amounts?.TPA?.amount?.displayAmount;
  const totalOrderAmount = amounts?.TOA?.amount?.displayAmount;
  //refund amount = order amount - invoiced amount
  let refundAmount =
    getFloatValue(totalOrderAmount) - getFloatValue(totalInvoiceAmount) < 0
      ? 0
      : getFloatValue(totalOrderAmount) - getFloatValue(totalInvoiceAmount);
  if (orderStatus === OrderStatus.delivered) {
    result =
      getFloatValue(totalInvoiceAmount) -
      getFloatValue(totalPaidAmount) -
      refundAmount;
  } else {
    result = getFloatValue(totalOrderAmount) - getFloatValue(totalPaidAmount);
  }
  return result < 0 ? 0 : result;
};

/**
 * @param {*} summaryInfo
 * @returns  payment summary keys in sequence
 */
const sequenceMapper = (summaryInfo) => {
  const summarySequence = new Set([
    "LIA",
    "LIT",
    "OCA",
    "FRA",
    "FRT",
    "TOA",
    ...Object.keys(summaryInfo),
  ]);

  const summarySequenceObject = {};
  Array.from(summarySequence).forEach((field) => {
    summarySequenceObject[field] = summaryInfo[field];
  });

  return summarySequenceObject;
};

/**
 * * formats amounts object from api as label and value
 * @returns formattedAmounts
 */
export const getAmountsData = (orderInfo, recordedTransaction) => {
  let { amounts: orderSummary, orderStatus } = orderInfo;
  const amounts = sequenceMapper(orderSummary);
  let formattedAmounts = {};
  let totalOrderAmount = 0;

  const totalInvoiceAmount =
    recordedTransaction?.totalInvoiceAmount?.displayAmount;
  const priceAfterDiscount =
    getFloatValue(amounts?.LDA?.amount.displayAmount) ??
    0 + getFloatValue(amounts?.LDT?.amount?.displayAmount) ??
    0 + getFloatValue(amounts?.FDA?.amount.displayAmount) ??
    0 + getFloatValue(amounts?.FDT?.amount.displayAmount) ??
    0;
  let positiveTolerance =
    getFloatValue(totalInvoiceAmount) -
    getFloatValue(amounts?.TOA?.amount?.displayAmount);
  positiveTolerance = positiveTolerance > 0 ? positiveTolerance : 0;
  if (amounts) {
    const advanceAmount = (orderInfo?.category === "DIRECT") &
      (amounts?.ADP?.amount?.displayAmount != null) && {
      "Advance amount": appendRupeeIcon(
        amounts?.ADP?.amount?.displayAmount ?? 0,
      ),
    };

    let FRT = amounts?.FRT?.amount?.centAmount?amounts?.FRT?.amount?.centAmount/100:0;
    let FRA =  amounts?.FRA?.amount?.centAmount?amounts?.FRA?.amount?.centAmount/100:0;
    totalOrderAmount = Number( amounts?.TOA?.amount?.centAmount/100)+Number(FRT)+Number(FRA);
    formattedAmounts = {
      "Total price": appendRupeeIcon(amounts?.LIA?.amount.displayAmount ?? 0),
      "Price after discount": appendRupeeIcon(
        amounts?.LDA?.amount.displayAmount ??
          0 + amounts?.LDT?.amount?.displayAmount ??
          0 + amounts?.FDA?.amount.displayAmount ??
          0 + amounts?.FDT?.amount.displayAmount ??
          0,
      ),
      Tax: appendRupeeIcon(
        amounts.LDT
          ? amounts?.LDT?.amount.displayAmount
          : amounts?.LIT?.amount?.displayAmount,
      ),
      "Freight charges": appendRupeeIcon(
        amounts?.FRA?.amount?.displayAmount ?? 0,
      ),
      "GST on freight": appendRupeeIcon(
        amounts?.FRT?.amount?.displayAmount ?? 0,
      ),
      "Other charges with GST": appendRupeeIcon(
        amounts?.OCA?.amount?.displayAmount ?? 0,
      ),

      "Total order amount": appendRupeeIcon(
        totalOrderAmount,
      ),
      ...advanceAmount,
      "Positive tolerance amount": moneyFormat(positiveTolerance),
      "Total paid amount": appendRupeeIcon(
        amounts?.TPA?.amount.displayAmount ?? 0,
      ),
      "Pending amount": moneyFormat(
        pendingAmountCheck(amounts, orderStatus, totalInvoiceAmount),
      ),
    };
  }
  if (
    getFloatValue(amounts?.LIA?.amount.displayAmount) - priceAfterDiscount ===
    0
  ) {
    delete formattedAmounts["Price after discount"];
  }
  return formattedAmounts;
};

/**
 * formats multiseller amount from api
 * @returns structured multi seller payment summary
 */

export const getMultiSellerSummary = (orderInfo) => {
  const multiSellerAmount = orderInfo?.multiSellerOrderAmount;

  let multiSellerSummary = {};
  let totalPositiveTolerance = 0;
  let totalOrderAmount = 0;
  let totalAdvanceAmount = 0;
  let totalPaidAmount = 0;
  let totalFrieghtCharges = 0;
  let totalFreightTax = 0;

  let formattedAmounts = {};

  // eslint-disable-next-line array-callback-return
  Object.keys(multiSellerAmount).map((sellerName) => {
    multiSellerSummary[sellerName] = {};
    const sellerOrderSummary = sequenceMapper(multiSellerAmount[sellerName]);

    // eslint-disable-next-line array-callback-return
    Object.keys(sellerOrderSummary).map((item) => {
      switch (item) {
        case "LIA": {
          multiSellerSummary[sellerName]["Total price"] = appendRupeeIcon(
            multiSellerAmount?.[sellerName]?.[item]?.amount?.displayAmount ?? 0,
          );
          break;
        }
        case "LIT": {
          multiSellerSummary[sellerName]["Tax"] = appendRupeeIcon(
            multiSellerAmount?.[sellerName]?.[item]?.amount?.displayAmount ?? 0,
          );
          break;
        }
        case "FRA": {
          if (multiSellerAmount?.[sellerName]?.[item]?.amount?.centAmount > 0) {
            // multiSellerSummary[sellerName]["Freight charges"] = appendRupeeIcon(
            //   multiSellerAmount?.[sellerName]?.[item]?.amount?.displayAmount ??
            //     0,
            // );
            totalFrieghtCharges = parseFloat(
              multiSellerAmount?.[sellerName]?.[item]?.amount?.centAmount / 100,
            );
          }
          break;
        }
        case "OCA": {
          if (multiSellerAmount?.[sellerName]?.[item]?.amount?.centAmount > 0) {
            multiSellerSummary[sellerName]["Other charges with GST"] =
              appendRupeeIcon(
                multiSellerAmount?.[sellerName]?.[item]?.amount
                  ?.displayAmount ?? 0,
              );
          }
          break;
        }
        case "TOA": {
          multiSellerSummary[sellerName]["Seller order amount"] =
            appendRupeeIcon(
              multiSellerAmount?.[sellerName]?.[item]?.amount?.displayAmount ??
                0,
            );
          const totalOrderedAmt = getFloatValue(
            multiSellerAmount?.[sellerName]?.[item]?.amount?.displayAmount,
          );
          const totalInvoiceAmount = getFloatValue(
            orderInfo?.multiSellerInvoiceAmount?.[sellerName]?.amount
              ?.displayAmount,
          );
          const positiveTolerance = totalInvoiceAmount - totalOrderedAmt;

          if (positiveTolerance > 0) {
            totalPositiveTolerance += positiveTolerance;
            multiSellerSummary[sellerName]["Positive tolerance"] =
              moneyFormat(positiveTolerance);
          }
          //sum up all amounts
          totalOrderAmount += parseFloat(
            (multiSellerAmount?.[sellerName]?.[item]?.amount?.centAmount ?? 0) /
              100,
          );
          break;
        }
        case "ADP": {
          totalAdvanceAmount += parseFloat(
            (multiSellerAmount?.[sellerName]?.[item]?.amount?.centAmount ?? 0) /
              100,
          );
          break;
        }
        case "TPA": {
          if (orderInfo?.paymentStatus === "Partial") {
            totalPaidAmount = parseFloat(
              (multiSellerAmount?.[sellerName]?.[item]?.amount?.centAmount ??
                0) / 100,
            );
          } else {
            totalPaidAmount += parseFloat(
              (multiSellerAmount?.[sellerName]?.[item]?.amount?.centAmount ??
                0) / 100,
            );
          }
          break;
        }
        case "FRT": {
          const frtAmountTax =
            multiSellerAmount?.[sellerName]?.[item]?.amount?.centAmount;

          if (frtAmountTax && frtAmountTax > 0) {
            totalFreightTax = parseFloat(frtAmountTax / 100);
          }
          break;
        }
        default:
          break;
      }
    });
  });

  let pendingAmount =
    totalOrderAmount + totalPositiveTolerance - totalPaidAmount;
  pendingAmount = pendingAmount > 0 ? pendingAmount : 0;
  const finalOrderSummary = {
    "Freight charges":moneyFormat(totalFrieghtCharges),
    "GST on freight":moneyFormat(totalFreightTax),
    "Total order amount": moneyFormat(Number(totalOrderAmount)+Number(totalFrieghtCharges)+Number(totalFreightTax)),
        "Total paid amount": moneyFormat(totalPaidAmount),
    ...(pendingAmount > 0 && {
      "Pending amount": moneyFormat(pendingAmount),
    }),
    ...(checkIsDirectOrder(orderInfo?.category) && {
      "Advance amount": moneyFormat(totalAdvanceAmount),
    }),
    ...(totalPositiveTolerance > 0 && {
      "Positive tolerance amount": moneyFormat(totalPositiveTolerance),
    }),
  };

  formattedAmounts = {
    "Freight charges": moneyFormat(totalFrieghtCharges),
    "Positive tolerance amount": moneyFormat(totalPositiveTolerance),
    "Pending amount": moneyFormat(pendingAmount),
    "Advance amount": moneyFormat(totalAdvanceAmount),
    ...finalOrderSummary,
  };

  const result = {
    multiSellerSummary,
    finalOrderSummary,
    formattedAmounts,
  };
  return result;
};
