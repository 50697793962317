import { pascalCase, moneyFormat, dateConvertor, validateNull } from "utils";

export const formatCustomerDetails = (details) => {
  const formattedDetails = [
    {
      label: "Customer name",
      value: pascalCase(details?.companyName),
    },
    {
      label: "Mobile number",
      value: details?.mobileNumber?.number,
    },
    {
      label: "Email address",
      value: details?.email,
    },
    {
      label: "PAN",
      value: details?.pan,
    },
    {
      label: "GSTIN",
      value: details?.gstin,
    },
  ];
  return formattedDetails;
};

export const getCreditInstrumentDetails = (details) => {
  const creditInfo = [
    {
      label: "Credit limit Reference ID",
      value: details?.camId,
    },
    {
      label: "Expiry date",
      value: dateConvertor(details?.creditExpiry),
    },
    {
      label: "Credit days",
      value: details?.creditDueDay,
    },
    {
      label: "Grace period (in days)",
      value: details?.gracePeriod,
    },
    {
      label: "Penal charges",
      value: details.penalRate ? `${details.penalRate}%` : "-",
    },
    {
      label: "CAM document",
      value: details?.fileName,
    },
  ];
  const limitDetails = [
    {
      label: "Sanction limit",
      value: moneyFormat(details?.creditSanctioned),
    },
    {
      label: "Utilised limit",
      value: moneyFormat(details?.creditUtilised),
    },
    {
      label: "Available limit",
      value: moneyFormat(details?.creditAvailable),
    },
    {
      label: "Blocked limit",
      value: moneyFormat(details?.creditBlocked),
    },
    {
      label: "Usable limit",
      value: moneyFormat(details?.creditUsable),
    },
    {
      label: "Advance",
      value: details?.advancePercentage
        ? `${details?.advancePercentage}%`
        : "-",
    },
    {
      label: "Seller PAN",
      value: validateNull(details?.sellerPan),
    },
  ];
  return { creditInfo, limitDetails };
};

export const getCreditType = (details) => {
  let creditType = [];
  details?.forEach((element) => {
    if (!creditType.some((el) => el?.value === element?.creditType)) {
      creditType.push({
        label: element?.creditType,
        value: element?.creditType,
      });
    }
  });
  return creditType;
};

export const getInstrumentType = (details, type) => {
  let instrumentType = [];
  details?.forEach((ele) => {
    if (ele?.creditType === type) {
      ele?.instrumentType &&
        instrumentType.push({
          label: ele?.instrumentType,
          value: ele?.instrumentType,
        });
    }
  });
  return instrumentType;
};
