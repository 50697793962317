import { forwardRef, useImperativeHandle } from "react";
import { Typography, Box } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import ButtonView from "../components/ButtonView";
import RefundCashbackForm from "./RefundCashbackForm";

import { RefundAndCashbackPayout } from "./constants";
import { refundCashbackManualSchema } from "./tests";

const { refundFields, REFUND_FORM } = RefundAndCashbackPayout;

const ManualInput = forwardRef(({ saveManualUpload }, ref) => {
  const methods = useForm({
    mode: "onTouched",
    resolver: yupResolver(refundCashbackManualSchema),
  });

  const {
    reset,
    handleSubmit,
    getValues,
    formState: { isDirty },
  } = methods;

  const handleSave = () => {
    const formData = getValues(REFUND_FORM);
    saveManualUpload(formData);
  };

  // hook to expose function - Reset fields
  useImperativeHandle(ref, () => ({
    reset: () => reset(),
  }));

  return (
    <FormProvider {...methods}>
      <Typography marginTop={5}>Manual upload</Typography>
      <Box
        as="form"
        onSubmit={handleSubmit(handleSave)}
        sx={{
          paddingY: 3,
        }}
      >
        {[refundFields].map((_txItem, index) => (
          <RefundCashbackForm key={index} id={index} />
        ))}

        <ButtonView disabled={!isDirty} onClearAll={() => reset()} />
      </Box>
    </FormProvider>
  );
});

export default ManualInput;
