import { INVOICE_SELLER_PAYOUT } from "../constant";

const { manualUpload } = INVOICE_SELLER_PAYOUT;

const ERROR_MSG = Object.freeze({
  [manualUpload.orderNumber]: "Enter the Order ID",
  [manualUpload.fromGstin]: "Enter a valid GSTIN",
  [manualUpload.toGstin]: "Enter a valid GSTIN",
  [manualUpload.documentType]: "Select a document type",
  [manualUpload.documentId]: "Document id, payment is already done",
  [manualUpload.originalInvoiceId]: `Enter at least one original Invoice number`,
  [manualUpload.documentInfo]: "Upload the document file",
  [manualUpload.documentDate]: "Enter a document date in DD/MM/YYYY format",
  [manualUpload.documentInfo]: "Upload the document file",
  [manualUpload.invoiceAmount]: "Enter the document amount",
  [manualUpload.creditAmount]: "Enter credit amount more than 0",
  [manualUpload.creditDueDate]: "Enter the credit due date",
  [manualUpload.payoutAmount]: "Invalid payout amount",
});

export default ERROR_MSG;
