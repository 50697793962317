export const RefundAndCashbackPayout = Object.freeze({
  REFUND_FORM: "refundForm",
  refundFields: {
    ORDER_NUMBER: "orderNumber",
    PAYOUT_TYPE: "payoutType",
    FROM_ACCOUNT: "fromGstin",
    TO_ACCOUNT: "toGstin",
    INVOICE_NO: "invoiceNo",
    INVOICE_DATE: "invoiceDate",
    INVOICE_AMOUNT: "invoiceAmount",
    PAYOUT_AMOUNT: "payoutAmount",
    INVOICE_COMMENTS: "invoiceComments",
    ADVICE: "advice",
  },
  payoutTypes: {
    IPA: "Seller payout - Invoice",
    CASHBACK: "Cashback",
    REFUND: "Refund",
  },
  refundFieldErrors: {
    toGstin: "GSTIN doesn’t exist",
    fromGstin: "GSTIN doesn’t exist",
    payoutAmount: "Invalid payout amount",
    payoutType: "Invalid payout type",
    orderNumber: "Invalid order number",
  },
});
