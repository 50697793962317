import { FinancePayouts } from "pages/FinancePayout/constants";

export const INVOICE_SELLER_PAYOUT = Object.freeze({
  manualUpload: {
    orderNumber: "orderNumber",
    payoutType: "payoutType",
    fromGstin: "fromGstin",
    toGstin: "toGstin",
    documentType: "documentType",
    documentId: "documentId",
    documentDate: "documentDate",
    originalInvoiceId: "originalInvoiceId",
    documentInfo: "documentInfo",
    invoiceAmount: "invoiceAmount",
    creditAmount: "creditAmount",
    creditDueDate: "creditDueDate",
    advanceAmount: "advanceAmount",
    cgstAmount: "cgstAmount",
    sgstAmount: "sgstAmount",
    igstAmount: "igstAmount",
    isTcsTdsDetected: "isTcsTdsDetected",
    invoiceComments: "invoiceComments",
    payoutAmount: "payoutAmount",
  },
  invoiceYup: "invoiceSeller",
  gstTypes: {
    interState: "interState",
    intraState: "intraState",
  },
  tableHead: [
    "Order ID",
    "From account GSTIN",
    "To account GSTIN",
    "Document type",
    "Document / Invoice ID Original invoice ID",
    "Invoice / Document date",
    "Document / Invoice amount",
    "Payout amount",
    "Documents",
    // "Action",
    "Comment",
  ],
  action: { edit: "Edit", delete: "Delete" },
  documentType: FinancePayouts.documentType,
  plantGstin: ["AAACJ4323N", "AACCM3988L"],
});
