const handleUndefined = (value) => {
  return value ? value : null;
};
export const makeTrackingData = (
  shipmentNumber,
  sapShipmentNumber,
  updatedValues,
  oldTrackingData,
) => {
  const {
    address,
    driverName,
    driverNo,
    eta,
    lrNo,
    ltAddress,
    recordedAt,
    totalDistance,
    tripId,
    tripOrigin,
    truckNo,
  } = updatedValues;

  const updatedTripDetails = {
    shipmentNumber,
    sapShipmentNumber,
    trackingInfo: {
      status: handleUndefined(oldTrackingData?.status),
      drivers: [
        {
          name: driverName,
          contact: driverNo,
        },
      ],
      origins: [
        {
          atd: handleUndefined(oldTrackingData?.origins?.[0]?.atd),
          etd: handleUndefined(oldTrackingData?.origins?.[0]?.etd),
          point: handleUndefined(oldTrackingData?.origins?.[0]?.point),
          address: tripOrigin,
        },
      ],
      trip_id: tripId,
      vehicle: {
        license_plate: truckNo,
      },
      end_time: handleUndefined(oldTrackingData?.end_time),
      invoices: handleUndefined(oldTrackingData?.invoices),
      lr_number: lrNo,
      share_url: handleUndefined(oldTrackingData?.share_url),
      waypoints: handleUndefined(oldTrackingData?.waypoints),
      close_time: handleUndefined(oldTrackingData?.close_time),
      start_time: handleUndefined(oldTrackingData?.start_time),
      status_code: handleUndefined(oldTrackingData?.status_code),
      destinations: [
        {
          ata: handleUndefined(oldTrackingData?.destinations?.[0]?.ata),
          eta,
          point: handleUndefined(oldTrackingData?.destinations?.[0]?.point),
          address,
          eta_updated_at: recordedAt,
          distance_from_last_location: handleUndefined(
            oldTrackingData?.destinations?.[0]?.distance_from_last_location,
          ),
        },
      ],
      total_distance: totalDistance,
      last_known_location: {
        address: ltAddress,
      },
    },
  };

  return updatedTripDetails;
};
