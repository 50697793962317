import { INTERNAL_FUND_TRANSFER } from "./constants";
/**
 * @param {*} csvData
 * @returns format csv data to use as body in api
 */
const { manualUpload } = INTERNAL_FUND_TRANSFER;

export const formatCSVData = (csvData = [{}]) =>
  csvData.map((item) => {
    return {
      [manualUpload.fromGstin]: item?.["From account"]?.trim(),
      [manualUpload.toGstin]: item?.["To account"]?.trim(),
      [manualUpload.ledgerDescription]: item?.["Ledger description"]?.trim(),
      [manualUpload.advice]: item?.["Advice - Internal"]?.trim(),
      [manualUpload.comment]: item?.comment?.trim(),
      [manualUpload.amount]: item?.Amount,
    };
  });

export const resetFieldMapper = () =>
  formatCSVData().map((item) =>
    Object.entries(item).reduce((result, [key, value]) => {
      return { ...result, [key]: !!value ? value : "" };
    }, {}),
  );

export const payloadMapper = (payload = [{}]) => {
  const response = payload.map((item) => {
    return {
      [manualUpload.fromGstin]: item?.[manualUpload.fromGstin],
      [manualUpload.toGstin]: item?.[manualUpload.toGstin],
      [manualUpload.ledgerDescription]: item?.[manualUpload.ledgerDescription],
      [manualUpload.advice]: item?.[manualUpload.advice],
      [manualUpload.comment]: item?.[manualUpload.comment],
      [manualUpload.amount]: item?.[manualUpload.amount],
      // If it is edit tx payload
      ...(item?.id && { id: item?.id }),
    };
  });

  return response;
};