import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import { ButtonV1, ModalBox } from "components";

import { moneyFormat, validateNull } from "utils";
import CREDIT_UPDATE from "../constants";

const { CONFIRMATION_APPROVALS_HEADER } = CREDIT_UPDATE;

const ApproveRejectModalConfirmation = ({
  data,
  isModalOpen,
  onCloseModal,
  onConfirm,
  isApprove,
}) => {
  return (
    <ModalBox open={isModalOpen} onCloseModal={onCloseModal} width="60%">
      <Box p={4}>
        <Typography fontWeight={"bold"} mb={4}>
          {`Do you want to ${
            isApprove ? "approve" : "reject"
          } pending approvals?`}
        </Typography>
        <Paper>
          <TableContainer
            sx={{
              minWidth: 700,
              maxHeight: "calc(100vh - 200px)",
            }}
          >
            <Table stickyHeader>
              <TableHead style={{ borderRadius: "10px 10px 0 0" }}>
                <TableRow>
                  {CONFIRMATION_APPROVALS_HEADER.map((heading) => (
                    <TableCell key={heading}>{heading}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!!data?.length ? (
                  data?.map((order) => {
                    return (
                      <TableRow key={order.updateId}>
                        <TableCell>
                          {validateNull(order?.customerName)}/<br />
                          {validateNull(order?.customerPan)}/ <br />
                          {validateNull(order?.customerGstin)}
                        </TableCell>
                        <TableCell>{validateNull(order?.camId)}</TableCell>
                        <TableCell>
                          {validateNull(order?.creditType)}-
                          {validateNull(order?.creditInstrument)}
                        </TableCell>
                        <TableCell>
                          {moneyFormat(order?.sanctionedLimit)}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={CONFIRMATION_APPROVALS_HEADER.length}>
                      <Typography textAlign={"center"}>
                        No record selected
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      <Box display={"flex"} justifyContent={"center"} gap={4}>
        <ButtonV1 variant="outlined" title={"Cancel"} onClick={onCloseModal} />
        <ButtonV1 title={"Confirm"} onClick={onConfirm} />
      </Box>
    </ModalBox>
  );
};

ApproveRejectModalConfirmation.propTypes = {
  data: PropTypes.array,
  isModalOpen: PropTypes.bool.isRequired,
  isApprove: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default ApproveRejectModalConfirmation;
