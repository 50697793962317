import { Box, Chip, Typography, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { QUICK_FILTER } from "../constant";
import { updateQuery } from "../utils";

const QuickFilter = ({ defaultFilter }) => {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const customStyle = {
    borderRadius: 0.8,
    "&.MuiChip-outlinedDefault": {
      borderColor: theme.palette.common.black,
      color: theme.palette.common.black,
    },
    "&.MuiChip-filled": {
      border: `1px solid ${theme.palette.grey[400]}`,
      color: theme.palette.grey[700],
      backgroundColor: theme.palette.grey[200],
    },
  };

  const handleSelect = (elem) => {
    const updatedQuery = updateQuery(searchParams, {
      filterValue: elem,
      page: null,
    });
    setSearchParams(updatedQuery);
  };

  return (
    <Box display={"flex"} sx={{ gap: 3 }}>
      <Typography sx={{ fontWeight: 600 }}>Quick filters:</Typography>
      {Object.keys(QUICK_FILTER).map((elem) => (
        <Chip
          key={elem}
          label={QUICK_FILTER[elem]}
          size="small"
          variant={defaultFilter === elem ? "outlined" : "filled"}
          sx={customStyle}
          onClick={() => handleSelect(elem)}
        />
      ))}
    </Box>
  );
};

export default QuickFilter;
