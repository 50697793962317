import { TextField, Typography, Stack } from "@mui/material";

const InputField = ({
  label,
  isRequired = false,
  type = "text",
  onChange,
  value,
  onBlur,
  highlightError,
  ...rest
}) => {
  return (
    <Stack direction={"column"}>
      <Typography variant="body1" mb={2}>
        {label}
        {!!isRequired && <span style={{ color: "red" }}>*</span>}
      </Typography>
      <TextField
        variant="outlined"
        size="small"
        type={type}
        onChange={onChange}
        error={highlightError}
        value={value || ""}
        onBlur={onBlur}
        {...rest}
      />
    </Stack>
  );
};

export default InputField;
