import { useMemo } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  Typography,
} from "@mui/material";

import {
  ExportCta,
  HyperLink,
  Loader,
  PaginationAction,
  StatusChip,
} from "components";
import { ColumnTitle, StyledTableRow } from "CustomStyledComponents";
import DocumentAmountBreakUp from "pages/FinancePayout/components/DocumentAmountBreakUp";
import DocumentAndInvoiceColumn from "pages/FinancePayout/components/DocumentAndInvoiceColumn";

import { FinancePayouts } from "pages/FinancePayout/constants";
import { downloadCsv, formatDate } from "utils";
import { getCall_v2 } from "services";
import { useToaster } from "hooks";
import { STATUS_CHIP } from "constants";
import { success, error } from "constants";

const ROWS_PER_PAGE = 50;

const CreditNotesTable = ({
  payoutData,
  onPageChange,
  isLoading,
  page,
  filters,
}) => {
  const triggerToaster = useToaster();

  const tableFields = useMemo(() => {
    return [
      {
        title: "Order type",
        field: "orderType",
        render: (ele) => <StatusChip label={ele?.orderType || "-"} />,
      },
      {
        title: "Order ID / Entity name",
        field: "orderIdAndEntityName",
        render: (ele) => (
          <Box style={{ maxWidth: 120 }}>
            <HyperLink title={ele?.customerOrdNumber} />
            <Typography fontSize={14}>{ele?.entityName || "-"}</Typography>
          </Box>
        ),
      },
      {
        title: "Transaction type",
        field: "transactionType",
        render: (ele) => (
          <Typography style={{ width: 120 }} fontSize={14}>
            {ele?.transactionType}
          </Typography>
        ),
      },
      {
        title: "Document ID / Original invoice number",
        field: "documentIdAndInvoiceIds",
        render: (ele) => {
          return <DocumentAndInvoiceColumn ele={ele} />;
        },
      },
      {
        title: "Document date",
        field: "documentDate",
        render: (ele) => {
          return (
            <Typography fontSize={14}>
              {ele?.documentDate ? formatDate(ele?.documentDate) : "-"}
            </Typography>
          );
        },
      },
      {
        title: "Payout date",
        field: "payoutDate",
        render: (ele) => (
          <Typography fontSize={14}>
            {formatDate(ele?.payoutDate) || "-"}
          </Typography>
        ),
      },
      {
        title: "Document amount",
        field: "documentAmount",
        render: (ele) => (
          <Box>
            {ele?.originalInvoiceId?.length ? (
              <DocumentAmountBreakUp data={ele} />
            ) : (
              "-"
            )}
          </Box>
        ),
      },
      {
        title: "Status",
        field: "status",
        render: (ele) => (
          <StatusChip
            label={FinancePayouts.success}
            type={STATUS_CHIP.success}
          />
        ),
      },
    ];
  }, []);

  const onExportData = async () => {
    try {
      const payoutUrl = `oms/off-oms-payout/download/credit-and-sales-note/historical`;
      const { data } = await getCall_v2(payoutUrl, {
        ...filters,
        limit: 5000,
        offset: 0,
      });
      triggerToaster(`Downloading file`, success);
      downloadCsv(data, "credit_notes_and_sales_return");
    } catch (err) {
      console.error(`Error in download, ${err}`);
      triggerToaster(`Please try again later`, error);
    }
  };

  const { payoutSummaries, totalCount } = payoutData;

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end">
        <ExportCta onExport={onExportData} />
      </Box>
      <Box component={Paper}>
        <TableContainer
          sx={{
            minWidth: 700,
            maxHeight: "calc(100vh - 120px)",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              {tableFields.map((el) => (
                <TableCell key={el.field}>
                  <ColumnTitle>{el.title}</ColumnTitle>
                </TableCell>
              ))}
            </TableHead>
            <TableBody>
              {isLoading || !payoutSummaries?.length ? (
                <StyledTableRow>
                  <TableCell colSpan={tableFields.length} align="center">
                    {isLoading ? (
                      <Loader sx={{ marginTop: 0 }} />
                    ) : (
                      <Typography textAlign={"center"}>
                        No data found
                      </Typography>
                    )}
                  </TableCell>
                </StyledTableRow>
              ) : (
                payoutSummaries?.map((row) => (
                  <StyledTableRow key={row?.customerOrdNumber}>
                    {tableFields?.map((el) => (
                      <TableCell key={el?.field}>{el.render(row)}</TableCell>
                    ))}
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
          <TableFooter
            sx={{ display: "flex", flexDirection: "column-reverse" }}
          >
            {!!payoutSummaries?.length && (
              <TablePagination
                rowsPerPageOptions={[ROWS_PER_PAGE]}
                rowsPerPage={ROWS_PER_PAGE}
                page={page}
                count={totalCount ?? 0}
                onPageChange={onPageChange}
                sx={{ display: "flex", flexDirection: "column-reverse" }}
                ActionsComponent={PaginationAction}
              />
            )}
          </TableFooter>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default CreditNotesTable;
