import { Chip, useTheme } from "@mui/material";

const GreyChip = ({ label, onClick, ...rest }) => {
  const theme = useTheme();
  return (
    <Chip
      label={label}
      onClick={onClick}
      sx={{
        borderRadius: 1,
        color: theme.palette.text.secondary,
        background: theme.palette.grey["100"],
        fontWeight: 500,
        border: `0.5px solid ${theme.palette.text.disabled}`,
      }}
      {...rest}
    />
  );
};

export default GreyChip;
