import { Box, styled } from "@mui/material";

const BoxShadow = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  paddingTop: 16,
  paddingBottom: 16,
  paddingLeft: 32,
  paddingRight: 32,
  border: `2px solid ${theme.palette.grey["A100"]}`,
  display: "flex",
  marginTop: 20,
  flex: 1,
  marginBottom: 20,
}));

export default BoxShadow;
