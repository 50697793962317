import { moneyFormat } from "utils";

export const getBuyerRefundSummaryHeader = (data) => {
  const totalCashBack = data?.cashBacks?.reduce(
    (acc, val) => acc + Number(val?.displayAmount?.replaceAll(",", "")),
    0,
  );

  let header = [
    {
      label: "Amount paid by buyer",
      value: data?.totalPaidAmount?.displayAmount,
    },
    {
      label: "Invoiced amount",
      value: data?.totalInvoicedAmount?.displayAmount,
    },
    {
      label: "Refund amount",
      value: data?.totalRefundAmount?.displayAmount,
    },
    {
      label: "Cashback",
      value: moneyFormat(totalCashBack),
    },
    {
      label: "PT pending",
      value: data?.ptpending?.displayAmount,
    },
  ];
  return header;
};
export const formattedBuyerRefundSummary = (data) => {
  let formattedData = [
    {
      dueDate: data?.refundDueDate,
      type: "Refund",
      payable: data?.totalRefundAmount?.displayAmount,
      status: data?.refundStatus?.label,
      statusState: data?.refundStatus?.state,
      timestamp: data?.refundTimeStamp,
      razorpayId: data?.transferId,
      utr: data?.utr,
      allowRefund: data?.allowRefund,
      state: data?.refundStatus?.state,
    },
  ];
  let cashbackObj = {
    dueDate: data?.cashbackDueDate,
    type: "Cashback",
    payable: data?.totalCashBack?.displayAmount,
    status: data?.cashbackStaus?.label,
    statusState: data?.cashbackStaus?.state,
    timestamp: data?.cashbackTimeStamp,
    razorpayId: data?.cashbackTransferId,
    allowRefund: data?.allowCashback,
    state: data?.cashbackStaus?.state,
  };
  if (data?.allowCashback) {
    formattedData.push(cashbackObj);
  }
  return formattedData;
};
