import { useRef, useState } from "react";
import { Box } from "@mui/material";

import UploadCSV from "components/UploadCSV";
import ReviewContent from "./ReviewContent";

import { postCall_v2 } from "services";
import { useToaster } from "hooks";
import { success, error, JOMS_API_BASE_URL } from "constants";

const BulkShipment = () => {
  const [shipmentDetails, setShipmentDetails] = useState([]);
  const [selectedShipmentIndex, setSelectedShipmentIndex] = useState();
  const csvUploadRef = useRef();
  const triggerToaster = useToaster();

  /**
   * @description callback fn on file upload
   */
  const fileUploadCallback = async (contents) => {
    if (contents) {
      const file = csvUploadRef?.current?.getFile();

      try {
        const formData = new FormData();
        formData.append("filePart", file);
        const res = await postCall_v2(
          `/oms/shipment/sap/shipment/bulk/upload`,
          formData,
        );
        if (res) {
          setShipmentDetails(res?.data?.sapShipmentWithInvoiceDetailsDtos);
        }
      } catch (err) {
        triggerToaster(
          err?.response?.data?.detail || `Error occurred, please try again`,
          error,
        );
      }
    }
  };

  /**
   * @description Api call of create shipment and remove created element
   */
  const createShipment = async (
    shipmentData,
    fileShipmentData,
    collapsibleInfoRef,
    index,
  ) => {
    setSelectedShipmentIndex(index);
    try {
      const { data } = await postCall_v2(
        `/oms/shipment/create/sap`,
        shipmentData,
      );
      const cloneShipmentDetails = [...shipmentDetails];
      const filteredShipmentDetails = cloneShipmentDetails.filter(
        (shipmentData) =>
          shipmentData?.invoiceNumber !== fileShipmentData?.invoiceNumber,
      );
      if (data) {
        //post shipments
        const invoicePosting = await postCall_v2(
          `${JOMS_API_BASE_URL}/external/v1/shipment/post-invoice`,
          {
            shipmentNumber: data.number,
            sellerOrderId: "",
          },
        );
        if (invoicePosting.isSuccess) {
          console.log("invoice posted successfully");
        } else {
          console.log("invoice posted failed");
        }

        if (!filteredShipmentDetails?.length) {
          removeFileContents();
        }
        setShipmentDetails(filteredShipmentDetails);
        triggerToaster(`Shipment created successfully`, success);
      }
    } catch (err) {
      const { data } = err;
      triggerToaster(data?.detail || data?.title, error);
      collapsibleInfoRef?.current?.handleExpand(false);
    } finally {
      setSelectedShipmentIndex(null);
    }
  };

  /**
   * @description Delete file contents on cancellation
   */
  const removeFileContents = () => {
    setShipmentDetails(null);
    csvUploadRef?.current?.resetFile();
  };

  return (
    <Box>
      <UploadCSV
        headerText={"Bulk upload"}
        parsedFileCallbackFn={fileUploadCallback}
        allowedMaxSize={5}
        ref={csvUploadRef}
      />
      <ReviewContent
        shipmentDetails={shipmentDetails}
        onCancel={removeFileContents}
        createShipment={createShipment}
        selectedShipmentIndex={selectedShipmentIndex}
      />
    </Box>
  );
};

export default BulkShipment;
