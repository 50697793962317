import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { ButtonV1, TooltipLabel } from "components";

import { FinancePayouts } from "pages/FinancePayout/constants";

const { APPROVAL_ACTION_TYPE } = FinancePayouts;

const ActionCta = ({ onInitiatePayout, isEnabled, onMarkSuccessful }) => {
  const theme = useTheme();

  return (
    <TooltipLabel
      customStyle={{
        "& .MuiTooltip-tooltip": {
          border: `1px solid ${theme.palette.grey["400"]}`,
        },
        "& .MuiTooltip-arrow": {
          "&::before": {
            backgroundColor: "white",
            border: `1px solid ${theme.palette.grey["400"]}`,
          },
        },
      }}
      title={
        <>
          <ButtonV1
            title={"Initiate"}
            size={"small"}
            variant="text"
            onClick={() => onInitiatePayout(APPROVAL_ACTION_TYPE.payout)}
          />
          <Divider />
          <ButtonV1
            title={"Hold"}
            size={"small"}
            variant="text"
            onClick={() => onInitiatePayout(APPROVAL_ACTION_TYPE.hold)}
          />
          <Divider />
          <ButtonV1
            title={"Mark success"}
            size={"small"}
            variant="text"
            onClick={() => onMarkSuccessful()}
          />
        </>
      }
      labelChildren={
        <Box
          style={{
            padding: 8,
            backgroundColor: !isEnabled
              ? theme.palette.grey["400"]
              : theme.palette.primary.main,
            color: !isEnabled
              ? theme.palette.text.disabled
              : theme.palette.common.white,
            borderRadius: 4,
            display: "flex",
            pointerEvents: !isEnabled && "none",
          }}
        >
          <Typography fontWeight={"600"}>Action</Typography>
          <KeyboardArrowDown fontSize="small" />
        </Box>
      }
    />
  );
};

export default ActionCta;
