import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { FileDownloadOutlined } from "@mui/icons-material";

import { StatusChip } from "../../PendingPayoutsTab/components";
import {
  Loader,
  PaginationAction,
  SvgIcon,
  HyperLink,
  ExportCta,
} from "components";
import {
  DocumentPriceBreakup,
  PriceBreakup,
  IconTooltip,
} from "pages/FinancePayout/components";

import {
  dateConvertor,
  moneyFormat,
  validateNull,
  getDateRange,
  downloadCsv,
} from "utils";
import { useDownloadInvoiceFile, useFetchData, useToaster } from "hooks";
import { getCall_v2 } from "services";

import { FinancePayouts } from "pages/FinancePayout/constants";
import { success, error } from "constants";

const { INVOICE_HISTORICAL_HEADERS } = FinancePayouts;

const limit = 50;
const invoiceType = "INVOICE";
const { startDate, endDate } = getDateRange();

const baseUrl = "oms/payout/history";
export const InvoiceSeller = ({ filters, transactionType }) => {
  const theme = useTheme();
  const [page, setPage] = useState(0);

  const pendingInvoiceParams = useMemo(() => {
    return {
      limit,
      transactionType,
      minTimestamp: startDate,
      maxTimestamp: endDate,
      ...filters,
    };
  }, [filters, transactionType]);

  const { triggerDownloadInvoice } = useDownloadInvoiceFile();
  const triggerToaster = useToaster();

  const handleDownloadFile = async (data) => {
    const invoiceFileObj =
      data?.shipmentInvoiceDetails?.invoiceDetails[0]?.files?.invoice;
    triggerDownloadInvoice(
      invoiceFileObj[Object.keys(invoiceFileObj)[0]],
      invoiceType,
      data?.shipmentId,
      data?.invoiceNumber,
    );
  };

  const {
    refetch,
    data: historicalPayouts,
    isFetching,
  } = useFetchData("historical-invoice-seller", baseUrl, null, null, {
    ...pendingInvoiceParams,
    offset: page * limit,
  });

  const handlePageChange = useCallback(
    (_event, pageNumber) => setPage(pageNumber),
    [],
  );

  const onExportData = async () => {
    try {
      const payoutUrl = `oms/payout/download/history`;
      const { data } = await getCall_v2(payoutUrl, {
        ...pendingInvoiceParams,
        limit: 5000,
        offset: 0,
      });
      triggerToaster(`Downloading file`, success);
      downloadCsv(data, "invoice_seller_payouts");
    } catch (err) {
      console.error(`Error in download, ${err}`);
      triggerToaster(`Please try again later`, error);
    }
  };

  useEffect(() => {
    if (page) {
      setPage(0);
    } else {
      refetch();
    }
  }, [pendingInvoiceParams]);

  useEffect(() => {
    refetch();
  }, [page]);

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end">
        <ExportCta onExport={onExportData} />
      </Box>

      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {INVOICE_HISTORICAL_HEADERS.map((head) => (
                <TableCell key={head}>{head}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {historicalPayouts?.data?.payoutsList?.length
              ? historicalPayouts?.data?.payoutsList?.map((item) => (
                  <TableRow>
                    <TableCell>
                      <StatusChip status={item?.orderType} />
                    </TableCell>
                    <TableCell>
                      <HyperLink title={item?.customerOrdNumber} />
                      <br />
                      {validateNull(item?.entityName)}
                    </TableCell>

                    <TableCell>
                      {dateConvertor(
                        item?.payoutAuditTrailDto?.payoutUploadedAt,
                      )}
                    </TableCell>
                    <TableCell>
                      {dateConvertor(
                        item?.payoutAuditTrailDto?.payoutInitiatedAt,
                      )}
                    </TableCell>
                    <TableCell>
                      {dateConvertor(
                        item?.payoutAuditTrailDto?.payoutApprovedAt,
                      )}
                    </TableCell>
                    <TableCell>{validateNull(item?.paymentType)}</TableCell>
                    <TableCell>{validateNull(item?.utr)}</TableCell>
                    <TableCell>
                      {item?.invoiceNumber ? (
                        <Box display={"flex"} alignItems={"center"} gap={2}>
                          {item?.invoiceNumber}
                          {item?.shipmentInvoiceDetails?.invoiceDetails[0]
                            ?.files && (
                            <IconButton
                              disableRipple={true}
                              onClick={() => handleDownloadFile(item)}
                            >
                              <FileDownloadOutlined
                                fontSize="small"
                                sx={{ color: theme.palette.secondary.main }}
                              />
                            </IconButton>
                          )}
                        </Box>
                      ) : (
                        "-"
                      )}
                      {item?.originalInvoiceId?.[0] ? (
                        <IconTooltip
                          label={item?.originalInvoiceId?.[0]}
                          icon={
                            <Box height={16}>
                              <SvgIcon name={"info"} width={16} height={16} />
                            </Box>
                          }
                        >
                          <>
                            <Typography
                              color={"text.secondary"}
                              sx={{ paddingBottom: 2 }}
                            >
                              Original invoices
                            </Typography>
                            {item?.originalInvoiceId?.map((id) => (
                              <Typography>{id}</Typography>
                            ))}
                          </>
                        </IconTooltip>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {validateNull(dateConvertor(item?.documentDate))}
                    </TableCell>
                    <TableCell>
                      {validateNull(dateConvertor(item?.payoutDate))}
                    </TableCell>
                    <TableCell>
                      <Box display={"flex"} alignItems={"center"} gap={1}>
                        {moneyFormat(
                          item?.shipmentInvoiceDetails?.totalInvoicedAmount,
                        )}
                        {item?.creditMetaInfo && (
                          <DocumentPriceBreakup
                            data={item?.shipmentInvoiceDetails}
                            addInfo={item?.creditMetaInfo}
                            tcsTdsDeductionReq={item?.tcsTdsDeductionReq}
                          />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box display={"flex"} gap={1}>
                        {moneyFormat(item?.amount)}
                        {item?.creditMetaInfo && <PriceBreakup data={item} />}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {item?.status ? (
                        <StatusChip status={item?.status} />
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                ))
              : !isFetching && (
                  <TableRow>
                    <TableCell colSpan={INVOICE_HISTORICAL_HEADERS.length}>
                      <Typography textAlign={"center"}>
                        No data Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
            {isFetching && (
              <TableRow>
                <TableCell colSpan={INVOICE_HISTORICAL_HEADERS.length}>
                  <Loader sx={{ margin: 0 }} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {!!historicalPayouts?.data?.totalCount && (
          <TableFooter style={{ display: "flex", justifyContent: "flex-end" }}>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[limit]}
                rowsPerPage={limit}
                page={page}
                count={historicalPayouts?.data?.totalCount ?? 0}
                onPageChange={handlePageChange}
                sx={{ display: "flex", flexDirection: "column-reverse" }}
                ActionsComponent={PaginationAction}
              />
            </TableRow>
          </TableFooter>
        )}
      </TableContainer>
    </Box>
  );
};

export default InvoiceSeller;
