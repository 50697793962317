import { FinancePayouts } from "./constants";

const {
  payoutTypes,
  transactionFilter: {
    REFUND_CASHBACK,
    INVOICE_SELLER_PAYOUTS,
    CREDIT_NOTE_SALES_RETURN,
    INTERNAL_FUND_TRANSFER,
    ORDER_BLOCK,
  },
  approvedPaymentStatus,
  pendingPaymentStatus,
  financeTab,
} = FinancePayouts;

export const appendParams = (url, filterRef = {}, filters) => {
  let selectedFilters;
  if (Object.keys(filterRef).length) {
    selectedFilters = filterRef;
  } else {
    selectedFilters = filters;
  }
  let urlRef = url;
  selectedFilters &&
    Object.keys(selectedFilters).map((ele, ind) => {
      if (selectedFilters[ele]) {
        urlRef += `&${ele}=${selectedFilters[ele]}`;
      }
      return urlRef;
    });
  return urlRef;
};

const convertDateToUnixTs = (date) => Math.round(date.getTime() / 1000);

const getPayoutType = (value) => {
  return Object.keys(payoutTypes).find((key) => payoutTypes[key] === value);
};

const getPaymentStatus = (value, isPendingTab) => {
  return isPendingTab
    ? Object.keys(pendingPaymentStatus).find(
        (key) => pendingPaymentStatus[key] === value,
      )
    : Object.keys(approvedPaymentStatus).find(
        (key) => approvedPaymentStatus[key] === value,
      );
};
const removeTrailingSpaces = (value) => {
  return value ? value?.trim() : null;
};

export const formatFiltersData = (filters, tab, transactionType) => {
  if (tab === financeTab.historical) {
    switch (transactionType) {
      case INVOICE_SELLER_PAYOUTS:
      case REFUND_CASHBACK:
        return {
          entityName: removeTrailingSpaces(filters?.entityName),
          orderType: removeTrailingSpaces(filters?.orderType),
          orderNumber: removeTrailingSpaces(filters?.orderNumber),
          minTimestamp: convertDateToUnixTs(filters?.fromDate),
          maxTimestamp: convertDateToUnixTs(filters?.toDate),
          invoiceNumberList: removeTrailingSpaces(filters?.invoiceNo),
          payoutType: getPayoutType(filters?.payoutType),
        };
      case CREDIT_NOTE_SALES_RETURN:
        return {
          entityName: removeTrailingSpaces(filters?.entityName),
          orderType: removeTrailingSpaces(filters?.orderType),
          minTimestamp: convertDateToUnixTs(filters?.fromDate),
          maxTimestamp: convertDateToUnixTs(filters?.toDate),
          invoiceNumberList: removeTrailingSpaces(filters?.invoiceNo),
          orderNumber: removeTrailingSpaces(filters?.orderNumber),
        };
      case ORDER_BLOCK:
        return {
          creditInstrument: removeTrailingSpaces(filters?.creditInstrument),
          orderId: removeTrailingSpaces(filters?.orderId),
        };
      case INTERNAL_FUND_TRANSFER:
        return {
          entityName: removeTrailingSpaces(filters?.entityName),
          orderNumber: removeTrailingSpaces(filters?.orderNumber),
        };

      default:
        return {};
    }
  }
  if (tab === financeTab.pending) {
    switch (transactionType) {
      case INVOICE_SELLER_PAYOUTS:
      case REFUND_CASHBACK:
        return {
          entityName: removeTrailingSpaces(filters?.entityName),
          orderType: removeTrailingSpaces(filters?.orderType),
          invoiceNumberList: removeTrailingSpaces(filters?.invoiceNo),
          payoutType: getPayoutType(filters?.payoutType),
          orderNumber: removeTrailingSpaces(filters?.orderNumber),
          minTimestamp: convertDateToUnixTs(filters?.fromDate),
          maxTimestamp: convertDateToUnixTs(filters?.toDate),
          status: getPaymentStatus(filters?.status, true),
        };
      case CREDIT_NOTE_SALES_RETURN:
        return {
          entityName: removeTrailingSpaces(filters?.entityName),
          orderType: removeTrailingSpaces(filters?.orderType),
          invoiceNumberList: removeTrailingSpaces(filters?.invoiceNo),
          transactionType: removeTrailingSpaces(filters?.payoutType),
          orderNumber: removeTrailingSpaces(filters?.orderNumber),
        };
      case ORDER_BLOCK:
        return {
          creditInstrument: removeTrailingSpaces(filters?.creditInstrument),
          orderId: removeTrailingSpaces(filters?.orderId),
        };
      case INTERNAL_FUND_TRANSFER:
        return {
          entityName: removeTrailingSpaces(filters?.entityName),
          orderNumber: removeTrailingSpaces(filters?.orderNumber),
        };
      default:
        return {};
    }
  }
  if (tab === financeTab.upload) {
    switch (transactionType) {
      case INVOICE_SELLER_PAYOUTS:
      case REFUND_CASHBACK:
      case CREDIT_NOTE_SALES_RETURN:
      case INTERNAL_FUND_TRANSFER:
        return {
          entityName: removeTrailingSpaces(filters?.entityName),
          invoiceNumberList: removeTrailingSpaces(filters?.invoiceNo),
          orderNumber: removeTrailingSpaces(filters?.orderNumber),
        };
      case ORDER_BLOCK:
        return {
          creditInstrument: filters?.creditInstrument?.trim(),
          orderId: filters?.orderId?.trim(),
        };
      default:
        return {};
    }
  }
  if (tab === financeTab.approve) {
    switch (transactionType) {
      case INVOICE_SELLER_PAYOUTS:
      case REFUND_CASHBACK:
        return {
          entityName: removeTrailingSpaces(filters?.entityName),
          orderType: removeTrailingSpaces(filters?.orderType),
          invoiceNumberList: removeTrailingSpaces(filters?.invoiceNo),
          payoutType: getPayoutType(filters?.payoutType),
          orderNumber: removeTrailingSpaces(filters?.orderNumber),
          minTimestamp: convertDateToUnixTs(filters?.fromDate),
          maxTimestamp: convertDateToUnixTs(filters?.toDate),
          status: getPaymentStatus(filters?.status),
        };
      default:
        return {};
    }
  }
};

export const getValidPayoutId = (value) => {
  if (value.includes(":")) {
    const splittedArr = value.split(":");
    if (splittedArr) {
      return splittedArr[0];
    }
  }
  return value;
};

export const getValidRefundPayoutId = (value) => {
  if (value.includes("ord_")) {
    return value.substring(4);
  }
  return value;
};
