import { createSlice } from "@reduxjs/toolkit";

export const shipmentDetails = createSlice({
  name: "shipmentDetails",
  initialState: [],
  reducers: {
    setShipmentDetailsAction: (_, action) => {
      return action.payload;
    },
  },
});

export const { setShipmentDetailsAction } = shipmentDetails.actions;

export default shipmentDetails.reducer;
