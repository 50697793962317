export const orderStatusList = [
  { label: "Shipped", value: "shipped" },
  { label: "Delivered", value: "delivered" },
  { label: "Pending", value: "pending" },
  { label: "Cancelled", value: "cancelled" },
];

export const cancelledOrder = "Cancelled";
export const SO_TYPE = ["Production_SO", "Dispatch_SO"];

export const distributedLineItemTableHeader = [
  "Sku name",
  "Variant MM ID",
  "Delivery status",
  "Seller name",
  "Vendor master ID",
  "Quantity ordered",
  "Quantity shipped",
  "Discounted price",
  "Ordered value",
  "Delivered value",
  "Service cost",
  "Shipment no.",
  "Cashback",
  "Short closed Qty",
];

export const lineItemTableHeader = [
  "Sku name",
  "Variant MM ID",
  "Delivery status",
  "Seller name",
  "Vendor master ID",
  "Quantity ordered",
  "Quantity shipped",
  "DO Released Quantity",
  "Discounted price",
  "Ordered value",
  "Delivered value",
  "Service cost",
  "Shipment no.",
  "Cashback",
  "Short closed Qty",
];

export const channelOfOrderList = [
  { label: "Success", value: "Success" },
  { label: "IS", value: "IS" },
  { label: "Pune Acquisition", value: "Pune Acquisition" },
  { label: "Mumbai Acquisition", value: "Mumbai Acquisition" },
  { label: "Chennai Acquisition", value: "Chennai Acquisition" },
  { label: "Coimbatore Acquisition", value: "Coimbatore Acquisition" },
  { label: "Hyderabad Acquisition", value: "Hyderabad Acquisition" },
  { label: "Straight Acquisition", value: "Straight Acquisition" },
  { label: "Others", value: "Others" },
];

export const lineItemSoType = [
  {
    label: "Production SO",
    value: "PRODUCTION_SO",
  },
  {
    label: "Dispatch SO",
    value: "DISPATCH_SO",
  },
];

export const seller = "SELLER";

export const customer = "CUSTOMER";

export const paymentTypeList = [
  { state: "Cash & Carry" },
  { state: "Cash & Carry - Staggered" },
  { state: "Credit" },
  { state: "LC" },
  { state: "Channel Finance" },
];
export const buyerFileType = "ORDERS_MISC";
export const sellerFileType = "SELLER_ORDERS_MISC";
export const Confirmed = "Confirmed";

export const invalidCashbackMsg =
  "Cashback amount cannot be greater than order amount.";

export const DetailsTab = Object.freeze({
  CREDIT_TYPES: [
    "LC",
    "Trade C - JODL",
    "Trade C - Others",
    "CF - YBL",
    "CF - JSWS - YBL",
    "CF - Tata Cap",
  ],
  creditFieldsLabel: {
    PAYMENT_TYPE: "Payment type",
    CREDIT_TYPE: "Credit type",
    CASH_AMOUNT: "Cash amount",
    CREDIT_PERIOD: "Credit period(in days)",
    PENALTY_CHARGE: "Penalty charges (in %)",
    AVAILABLE_LIMIT: "Available limit",
    CREDIT_AMOUNT: "Credit amount",
    EXPIRY_DATE: "CAM Expiry Date",
    CAM_ID: "CAM ID",
    CREDIT_BYPASS: "Credit bypass",
    PRO_RATED_UTILISATION: "Pro-rated utilisation of advance",
    YES: "Yes",
    NO: "No",
  },
  creditFields: {
    CREDIT_TYPE: "creditType",
    CREDIT_PERIOD: "creditPeriod",
    PENALTY_CHARGE: "penaltyCharge",
    AVAILABLE_LIMIT: "availableLimit",
    CREDIT_AMOUNT: "creditAmount",
    EXPIRY_DATE: "expiryDate",
    PRO_RATED_UTILISATION: "proRatedUtilisation",
    YES: "yes",
    NO: "no",
  },
  BLOCKED_AMOUNT_HEADERS: ["Order number", "Order amount", "Block amount"],
  errorMsgs: {
    LIMIT_ERR_MSG: "Order amount exceeds usable limit",
    ERROR_MSG: "An error occurred, please try again.",
  },
  CREDIT_APPROVER_MODULE: "Credit Approver",
  CREATED: "Created",
  PLACED: "Placed",
  DELIVERY_IN_PROGRESS: "Delivery in progress",
  PARTIALLY_DELIVERED: "Partially Delivered",
  CREDIT: "Credit",
  CANCELLED: "Cancelled",
  DECLINED: "Declined",
  CONFIRMED: "Confirmed",
  PAYOUT_CREATION_MSG: "You are rejecting the order.",
  PAYOUT_CREATION_CONFIRM_MSG:
    "Do you want to initiate payout for the refund amount?",
  CANCEL: "Cancel",
  DECLINE: "Decline",
  JODL_ORDER_STATUS: {
    success: "SUCCESS",
    failed: "FAILED",
  },
  REASONS_FOR_SHORT_CLOSE: [
    "FG Not Available - Production Shut Down",
    "FG Not Available - Stock Out",
    "Order MOQ Issue",
    "Vehicle Placement",
    "Delayed Payment",
    "QC Failed",
    "Allocation Unapproved",
    "Buyer Cancelled",
    "Pricing Validity Expired",
    "Order Invalid",
  ],
});
