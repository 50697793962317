import PropTypes from "prop-types";

export const selectedCustomerPropTypes = {
  companyName: PropTypes.string,
  ctId: PropTypes.string,
  email: PropTypes.string,
  gstin: PropTypes.string.isRequired,
  mobileNumber: PropTypes.shape({
    number: PropTypes.string,
    country_code: PropTypes.string,
  }),
  name: PropTypes.shape({
    en: PropTypes.string,
  }),
  numOrdersPending: PropTypes.number,
};
