import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";

import { TransactionFilter, Filters } from "../components";
import RefundAndCashback from "./RefundAndCashback";
import CreditNotesAndSellerReturn from "./CreditNotesAndSellerReturn";
import InvoiceSeller from "./InvoiceSeller";
import InternalFundTransfer from "./InternalFundTransfer";
import OrderBlock from "./OrderBlock";

import { FinancePayouts } from "../constants";
import { formatFiltersData } from "../makeData";
import { getCall_v2 } from "services";
import { getDateRange } from "utils";
import useFetchParam from "../useFetchParam";

const {
  transactionFilter: {
    INVOICE_SELLER_PAYOUTS,
    CREDIT_NOTE_SALES_RETURN,
    INTERNAL_FUND_TRANSFER,
    REFUND_CASHBACK,
    ORDER_BLOCK,
  },
  financeTab,
  queryFilters,
} = FinancePayouts;

/**
 * @description get default date range
 */
const { startDate, endDate } = getDateRange();

const timeRange = {
  minTimestamp: startDate,
  maxTimestamp: endDate,
};

/**
 *
 * @description Historical section
 */
const HistoricalPayouts = () => {
  const { fetchParams, updateParams } = useFetchParam();
  const [dropdownList, setDropdownList] = useState([]);

  /**
   * @description fetch type & tab from useFetchParam hook
   */
  const { [queryFilters.transactionType]: type, [queryFilters.tab]: tab } =
    fetchParams;

  /**
   * @description get all filter params
   */
  const filterParams = useMemo(() => {
    const {
      [queryFilters.tab]: tab,
      [queryFilters.transactionType]: type,
      ...filter
    } = fetchParams;

    return filter;
  }, [fetchParams]);

  /**
   * @description set transaction type
   */
  const transactionType = useMemo(
    () => FinancePayouts.transactionFilter[type],
    [type],
  );

  const getDropdownList = useCallback(async (transactionTypeParams) => {
    if (transactionTypeParams === ORDER_BLOCK)
      try {
        const res = await getCall_v2("/oms/credit/getAllCreditInstrumentList");
        setDropdownList(res.data);
      } catch (error) {
        console.error("Unable to fetch credit instrument");
      }
  }, []);

  useEffect(() => {
    getDropdownList(transactionType);
  }, [transactionType]);

  /**
   * @description - render table component depending on transaction type
   */
  const getTableView = () => {
    switch (transactionType) {
      case CREDIT_NOTE_SALES_RETURN:
        return (
          <CreditNotesAndSellerReturn
            transactionType={transactionType}
            filters={filterParams}
          />
        );
      case INVOICE_SELLER_PAYOUTS:
        return (
          <InvoiceSeller
            transactionType={transactionType}
            filters={filterParams}
          />
        );
      case INTERNAL_FUND_TRANSFER:
        return (
          <InternalFundTransfer
            transactionType={transactionType}
            filters={filterParams}
          />
        );
      case REFUND_CASHBACK:
        return (
          <RefundAndCashback
            transactionType={transactionType}
            filters={filterParams}
          />
        );
      case ORDER_BLOCK:
        return (
          <OrderBlock
            transactionType={transactionType}
            filters={filterParams}
          />
        );
      default:
        console.warn("Out of option", transactionType);
    }
  };

  const handleReset = () => {
    const param = {
      ...timeRange,
      [queryFilters.tab]: fetchParams[queryFilters.tab],
      [queryFilters.transactionType]: fetchParams[queryFilters.transactionType],
    };

    updateParams({ param, appendWithExisting: false });
  };

  const onSubmit = (filters) => {
    const formattedFiltersData = formatFiltersData(
      filters,
      financeTab.historical,
      transactionType,
    );
    updateParams({ param: formattedFiltersData });
  };

  return (
    <>
      <Box mb={4}>
        <TransactionFilter />
      </Box>
      <Box mb={4}>
        <Filters
          onSubmit={onSubmit}
          onReset={handleReset}
          transactionType={transactionType}
          dropdownList={dropdownList}
          financeTab={tab}
          hideDateRange={[ORDER_BLOCK, INTERNAL_FUND_TRANSFER].includes(
            transactionType,
          )}
        />
      </Box>

      {getTableView()}
    </>
  );
};

export default HistoricalPayouts;
