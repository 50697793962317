import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  Typography,
  Chip,
  Box,
  Avatar,
  IconButton,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import PropTypes from "prop-types";

import theme from "themeProvider";

const MultiSelectDropdown = forwardRef(
  (
    {
      defaultSelectedValues,
      itemList,
      isListOfObjects = true,
      displayAttribute,
      label,
      setIsDirty,
      ...rest
    },
    ref,
  ) => {
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
      if (defaultSelectedValues && defaultSelectedValues.length) {
        //filter out empty string
        // eslint-disable-next-line react-hooks/exhaustive-deps
        defaultSelectedValues = defaultSelectedValues.filter((item) => item);

        setSelectedOptions(defaultSelectedValues);
      }
    }, []);

    const handleCheckedValue = (evt, item) => {
      const {
        target: { checked },
      } = evt;

      if (checked) {
        setSelectedOptions([...selectedOptions, item]);
      } else {
        const filteredList = selectedOptions.filter((ele) => ele !== item);
        setSelectedOptions(filteredList);
      }
    };

    const removeSelectedValue = (item) => {
      const filteredList = selectedOptions.filter((ele) => ele !== item);
      setSelectedOptions(filteredList);
    };

    const rowItemClick = (item) => {
      const searchItem = selectedOptions.findIndex((ele) => ele === item);
      if (searchItem > -1) {
        //remove selected item
        selectedOptions.splice(searchItem, 1);
        setSelectedOptions([...selectedOptions]);
      } else {
        setSelectedOptions([...selectedOptions, item]);
      }
    };

    useEffect(() => {
      if (selectedOptions && selectedOptions.length) {
        setIsDirty && setIsDirty(true);
      } else {
        setIsDirty && setIsDirty(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOptions]);

    // hook to expose function - which will return selected items
    useImperativeHandle(ref, () => ({
      fetchValue: () => selectedOptions,
      resetValue: () => setSelectedOptions([]),
      fetchSelectedObjects: () => {
        const selectedItem = [];
        itemList?.forEach((item) => {
          if (selectedOptions?.includes(item?.[displayAttribute]))
            selectedItem.push(item);
        });
        return selectedItem;
      },
    }));

    const deriveElement = (item) => {
      return isListOfObjects ? item[displayAttribute] : item;
    };

    return (
      <FormControl fullWidth sx={{ padding: 0 }}>
        <Select
          multiple
          value={itemList ?? []}
          displayEmpty
          size="small"
          renderValue={() => {
            return (
              <Box display={"flex"} alignItems={"center"}>
                <Typography
                  color={theme.palette.grey["700"]}
                  fontSize={16}
                  pr={1}
                >
                  {label}
                </Typography>
                {!!selectedOptions.length && (
                  <Avatar
                    sizes="small"
                    sx={{
                      width: 24,
                      height: 24,
                      padding: 2,
                      fontSize: 12,
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.grey["A100"],
                    }}
                  >
                    {selectedOptions.length}
                  </Avatar>
                )}
              </Box>
            );
          }}
          MenuProps={{
            style: {
              maxHeight: 300,
            },
          }}
          sx={{
            "& .MuiSelect-outlined": {
              padding: 2,
            },
          }}
          {...rest}
        >
          {/* box to highlight selected items */}
          {!!selectedOptions.length && (
            <Box p={2} borderBottom={1} borderColor={theme.palette.grey["900"]}>
              <Box
                border={1}
                borderColor={theme.palette.grey["A100"]}
                p={2}
                borderRadius={1}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box
                  display={"flex"}
                  flexWrap={"wrap"}
                  width={"max-content"}
                  maxWidth={290}
                >
                  {selectedOptions.map((ele, index) => {
                    return (
                      <Box pl={2} pb={1} key={index}>
                        <Chip
                          key={ele}
                          style={{
                            borderRadius: 6,
                            backgroundColor: theme.palette.grey["A100"],
                          }}
                          label={ele}
                          onDelete={() => {
                            removeSelectedValue(ele);
                          }}
                          deleteIcon={
                            <CloseOutlined
                              style={{
                                fontSize: 12,
                                color: theme.palette.text.primary,
                              }}
                            />
                          }
                        />
                      </Box>
                    );
                  })}
                </Box>
                <IconButton onClick={ref.current.resetValue}>
                  <CloseOutlined
                    style={{
                      fontSize: 12,
                      color: theme.palette.text.primary,
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          )}

          {/* display list of constants to be shown  */}
          {itemList?.map((ele, index) => (
            <MenuItem
              key={index}
              onClick={() => rowItemClick(deriveElement(ele))}
              sx={{
                borderLeftColor: "transparent",
                borderLeftWidth: 2,
                borderLeftStyle: "solid",
                "&:hover": {
                  borderLeftColor: theme.palette.primary.main,
                  borderLeftWidth: 2,
                  borderLeftStyle: "solid",
                },
              }}
            >
              <Checkbox
                checked={selectedOptions?.includes(deriveElement(ele))}
                onChange={(evt) => handleCheckedValue(evt, deriveElement(ele))}
                disableRipple={true}
                sx={{ p: 0, mr: 2 }}
              />
              <ListItemText primary={deriveElement(ele)} />
            </MenuItem>
          ))}
          {/* list end  */}
        </Select>
      </FormControl>
    );
  },
);

MultiSelectDropdown.propTypes = {
  defaultSelectedValues: PropTypes.array,
  displayAttribute: PropTypes.string,
  isListOfObjects: PropTypes.bool,
  itemList: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  setIsDirty: PropTypes.func,
};

export default MultiSelectDropdown;
