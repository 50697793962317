import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";

import { SvgIcon } from "components";

const NoCustomer = () => {
  const theme = useTheme();
  return (
    <Box
      mt={5}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: 10,
      }}
    >
      <SvgIcon
        name={"customer-ledger"}
        style={{
          width: 100,
          height: 100,
        }}
      />
      <Typography
        fontSize={18}
        color={theme.palette.grey["A300"]}
        fontWeight={"bold"}
      >
        No customer selected
      </Typography>
      <Typography fontSize={16}>
        Select customer to view their ledger
      </Typography>
    </Box>
  );
};

export default NoCustomer;
