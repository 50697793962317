import { useEffect, useState, lazy, Suspense } from "react";
import { Box } from "@mui/material";

import { useFetchData, useToaster } from "hooks";
import { getCall, deleteCall } from "services";
import { getFormattedUserList } from "./makeData";
import { Loader, SearchBar, DialogBox } from "components";
import {
  DELETE_API_RESPONSE,
  DELETE_USER_SUCCESS_MSG,
  API_FAILED_MSG,
} from "./constants";

import { DELETE_USER_CONFIRM_MSG } from "constants";
import { success } from "constants";
import { error } from "constants";
const UserList = lazy(() => import("./UserList"));

const ViewUsers = () => {
  const [userlist, setUserList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [userId, setUserId] = useState("");

  const triggerToaster = useToaster();

  const onSuccess = (res) => {
    setUserList(res.data);
  };

  const onSuccessGroup = (res) => {
    setGroupList(res.data);
  };

  const { refetch: fetchUserList, isLoading } = useFetchData(
    "user-list",
    "/auth2-service/user/all",
    onSuccess,
  );

  const { refetch: refetchGroup } = useFetchData(
    "group-list",
    "/auth2-service/groups/all",
    onSuccessGroup,
  );

  useEffect(() => {
    fetchUserList();
    refetchGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteUser = async () => {
    const result = await deleteCall(`/auth2-service/user?id=${userId}`);
    if (result === DELETE_API_RESPONSE) {
      setIsConfirmModalOpen(false);
      triggerToaster(DELETE_USER_SUCCESS_MSG, success);
      fetchUserList();
    } else {
      triggerToaster(API_FAILED_MSG, error);
    }
  };

  const clearEnteredInput = () => {
    fetchUserList();
  };

  const getUserList = async (searchInput) => {
    let response = [];
    const res = await getCall(
      `/auth2-service/auto-suggest?userEmail=${searchInput}`,
    );
    if (res) {
      response = res.userEmail.map((email) => {
        return { email };
      });
    }
    return response;
  };

  const handleEmailSelection = async (searchValue) => {
    const searchResult = await getCall(
      `/auth2-service/user/search?email=${searchValue.email}`,
    );
    if (searchResult) {
      setUserList(searchResult);
    } else {
      setUserList([]);
    }
  };

  const onDeleteUser = (userId) => {
    setIsConfirmModalOpen(true);
    setUserId(userId);
  };

  return (
    <Box
      style={{
        marginTop: 34,
        marginRight: 28,
        marginLeft: 24,
      }}
    >
      <Box style={{ display: "flex" }}>
        <SearchBar
          style={{ width: "100%" }}
          inputStyle={{ height: 50 }}
          resolver={getUserList}
          displayAttribute="email"
          onItemSelect={handleEmailSelection}
          placeholder={"Search by mail"}
          onClear={clearEnteredInput}
        />
      </Box>
      <Suspense fallback={<Loader />}>
        <UserList
          userlist={getFormattedUserList(userlist, groupList)}
          onDeleteUser={onDeleteUser}
          loading={isLoading}
        />
      </Suspense>
      <DialogBox
        handleClose={() => setIsConfirmModalOpen(false)}
        open={isConfirmModalOpen}
        handleConfirm={handleDeleteUser}
        message={DELETE_USER_CONFIRM_MSG}
      />
    </Box>
  );
};

export default ViewUsers;
