import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
} from "@mui/material";
import PropTypes, { element } from "prop-types";
import { styled } from "@mui/system";
import theme from "themeProvider";
import TablePaginationActions from "./TablePaginationActions";

const Label = styled("span")({
  fontWeight: "600",
  textAlign: "center",
});

export default function TableWrapper({
  tableHeader,
  tableBody,
  tableHeaderStyle,
  errorMessage,
  errorStyle,
  wrapperStyle,
  isPagination = false,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  rows,
  count,
  rowsPerPageOptions = [10, 20, { label: "All", value: -1 }],
}) {
  return (
    <>
      <TableContainer
        component={Paper}
        style={wrapperStyle}
        sx={{
          border: `1px solid ${theme.palette.grey[100]}`,
          boxShadow: 0,
          overflow: "unset",
        }}
      >
        <Table aria-label="table-label" data-testid="table-test-id">
          <TableHead
            sx={{ background: theme.palette.grey["A100"], ...tableHeaderStyle }}
          >
            <TableRow>{tableHeader}</TableRow>
          </TableHead>
          <TableBody>{tableBody}</TableBody>
        </Table>
        {errorMessage && <Label style={errorStyle}>{errorMessage}</Label>}
        {isPagination && count ? (
          <TableFooter style={{ display: "flex", justifyContent: "flex-end" }}>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={3}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        ) : (
          ""
        )}
      </TableContainer>
    </>
  );
}

TableWrapper.propTypes = {
  tableHeader: PropTypes.arrayOf(element).isRequired,
  tableBody: PropTypes.arrayOf(element),
  tableHeaderStyle: PropTypes.object,
  errorStyle: PropTypes.object,
  wrapperStyle: PropTypes.object,
  errorMessage: PropTypes.string,
};
