import { Typography, Grid } from "@mui/material";
import { ViewOnly } from "components";

export const SectionTitle = (props) => {
  return (
    <Typography variant="h3" margin={0} style={{ marginBottom: 24 }}>
      {props.title}
    </Typography>
  );
};

export const GridSkeleton = ({ firstChild, secondChild }) => {
  return (
    <Grid
      container
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Grid item xs={5}>
        {firstChild}
      </Grid>
      <Grid item xs={5}>
        {secondChild}
      </Grid>
    </Grid>
  );
};

export const CustomViewOnly = ({ label, value, labelStyle }) => {
  return (
    <ViewOnly
      valueStyle={{ width: "45%" }}
      labelStyle={{ width: "45%", marginBottom: "6px", ...labelStyle }}
      label={label}
      value={value}
    />
  );
};
