import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Avatar, Divider } from "@mui/material";
import { DeleteOutline, Report } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import {
  UserCard,
  EditableInput,
  EditableChipSet,
  RightDrawer,
} from "../components";
import theme from "themeProvider";
import { useFetchData, useToaster } from "hooks";
import { useForm, Controller } from "react-hook-form";
import { fetchRegion, fetchBusiness, fetchModules } from "./filterData";
import { deleteCall_v2, putCall_v2 } from "services";
import { ButtonV1, Loader } from "components";
import { error, success } from "constants";

const RoleDetails = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { roleId } = useParams();
  const {
    getValues,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onTouched",
  });
  const navigate = useNavigate();
  const triggerToaster = useToaster();

  const { refetch: getLocationData, data: regionList } = useFetchData(
    "region",
    "/auth2-service/region",
  );
  const { refetch: getBusinessList, data: businessUnitList } = useFetchData(
    "business",
    "/auth2-service/business-unit",
  );
  const { refetch: getModuleList, data: modulesList } = useFetchData(
    "modules",
    "/auth2-service/modules",
  );

  const {
    refetch: getRoleDetails,
    data: roleDetails,
    isLoading: isLoadingRoles,
  } = useFetchData(
    "role-detail",
    `/auth2-service/roles?id=${roleId}`,
    (roleDetails) => {
      setValue(
        "roleName",
        roleDetails?.data?.role?.label ?? roleDetails?.data?.role?.name,
      );
    },
  );

  useEffect(() => {
    getLocationData();
    getBusinessList();
    getModuleList();
    Promise.all([getLocationData(), getBusinessList(), getModuleList()])
      .then(() => {
        getRoleDetails();
      })
      .catch((err) => console.error(`Error in fetching constant data-${err}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLocation = (data) => {
    let locationId = [];
    data.map((ele) => locationId.push(ele.id));
    updateRoleDetails({ location: locationId });
  };

  const handleModules = (data) => {
    let modulesId = [];
    data.map((ele) => modulesId.push(ele.id));
    updateRoleDetails({ modules: modulesId });
  };

  const handleBusiness = (data) => {
    let businessId = [];
    data.map((ele) => businessId.push(ele.id));
    updateRoleDetails({ business: businessId });
  };

  const updateRoleDetails = async ({ location, modules, business }) => {
    const { id, label } = roleDetails?.data?.role;
    const { users } = roleDetails?.data;
    let roleName = getValues("roleName") || label;
    const payload = {
      id,
      label: roleName,
      scopes: [
        {
          stateId: location,
          businessUnitId: business,
          modulesId: modules,
          userId: users,
        },
      ],
    };
    try {
      await putCall_v2("/auth2-service/roles", payload);
      triggerToaster("Role details updated", success);
      getRoleDetails();
    } catch (ex) {
      triggerToaster(
        ex?.data?.message ?? "Please try again, error occurred",
        error,
      );
      console.error(ex, "Exception in updating role");
    }
  };

  const handleDeleteRole = async () => {
    const { id, name } = roleDetails?.data?.role;
    const { users, regionList, businessUnitList, moduleList } =
      roleDetails?.data;
    const payload = {
      id,
      name,
      userId: users,
      scopeTags: [
        {
          stateId: regionList,
          businessUnitId: businessUnitList,
          modulesId: moduleList,
        },
      ],
    };
    try {
      await deleteCall_v2(`/auth2-service/roles`, payload);
      triggerToaster("Role deleted", success);
      navigate(`/user-management`);
    } catch (ex) {
      triggerToaster(
        ex?.data?.message ?? "Please try again, error occurred",
        error,
      );
      console.error(ex, "Exception in deleting role");
    }
  };

  const returnBusinessList = useCallback(
    () =>
      fetchBusiness(
        businessUnitList?.data,
        roleDetails?.data?.businessUnitList,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [roleDetails?.data],
  );
  const returnLocationList = useCallback(
    () => fetchRegion(regionList?.data, roleDetails?.data?.regionList),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [roleDetails?.data],
  );
  const returnModuleList = useCallback(
    () => fetchModules(modulesList?.data, roleDetails?.data?.moduleList),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [roleDetails?.data],
  );

  const toggleDrawer = (value) => {
    setDrawerOpen(value);
  };

  return (
    <Box pl={10} pr={10}>
      <Typography variant="h3">Role details</Typography>
      {isLoadingRoles ? (
        <Box sx={{ width: "80%", minHeight: 520 }}>
          <Loader />
        </Box>
      ) : (
        <Box display={"flex"}>
          <Box display={"flex"}>
            <UserCard
              cardHeader={
                <>
                  <Avatar alt="role" style={{ marginBottom: 8 }}>
                    {roleDetails?.data?.role?.name
                      ?.substring(0, 2)
                      .toUpperCase()}
                  </Avatar>
                  {!!roleDetails?.data && (
                    <Box
                      as="form"
                      width={"max-content"}
                      display={"flex"}
                      onSubmit={handleSubmit(isDirty && updateRoleDetails)}
                    >
                      <Controller
                        control={control}
                        name="roleName"
                        render={({ field: { value, onChange, onBlur } }) => (
                          <EditableInput
                            value={value}
                            error={errors.roleName}
                            onChange={onChange}
                            onBlur={onBlur}
                          />
                        )}
                      />
                    </Box>
                  )}
                </>
              }
              cardFooter={
                <ButtonV1
                  startIcon={<DeleteOutline />}
                  title="Remove role"
                  variant="text"
                  size="small"
                  onClick={() => toggleDrawer(true)}
                  style={{
                    color: theme.palette.text.primary,
                    fontWeight: "normal",
                  }}
                />
              }
            />
          </Box>
          <Box ml={6} display={"flex"} flexDirection={"column"} width={"100%"}>
            <Typography variant="h4">Access</Typography>
            <Box width={"100%"}>
              <Typography variant="body1" mb={2}>
                Location
              </Typography>
              <EditableChipSet
                data={returnLocationList()}
                options={regionList?.data}
                optionKey={"stateName"}
                dataKey={"stateName"}
                onUpdate={handleLocation}
              />
            </Box>
            <Box width={"100%"}>
              <Divider style={{ marginBottom: 8 }} />
              <Typography variant="body1" mb={2}>
                Modules
              </Typography>
              <EditableChipSet
                data={returnModuleList()}
                options={modulesList?.data}
                optionKey={"name"}
                dataKey={"name"}
                onUpdate={handleModules}
              />
            </Box>
            <Box width={"100%"}>
              <Divider style={{ marginBottom: 8 }} />
              <Typography variant="body1" mb={2}>
                Business
              </Typography>
              <EditableChipSet
                data={returnBusinessList()}
                options={businessUnitList?.data}
                optionKey={"name"}
                dataKey={"name"}
                onUpdate={handleBusiness}
              />
            </Box>
          </Box>
        </Box>
      )}

      <RightDrawer
        toggleDrawer={toggleDrawer}
        icon={
          <object
            data={`/assets/group.svg`}
            width={28}
            alt="Group icon"
            aria-label="group icon"
          />
        }
        heading={"Delete role"}
        isDrawerOpen={isDrawerOpen}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          style={{ height: 650 }}
        >
          <Box>
            <Typography variant="h4" mb={2}>
              {roleDetails?.data?.role?.name}
            </Typography>
            <Typography variant="body1" mb={2}>
              Are you sure you want to delete this role?
            </Typography>
            <Box
              height={110}
              mt={5}
              style={{
                backgroundColor: theme.palette.warning.light,
                border: 1,
                borderStyle: "solid",
                borderColor: theme.palette.warning.dark,
                borderRadius: 8,
              }}
              px={4}
              py={5}
            >
              <Box display={"flex"}>
                <Report
                  style={{
                    color: theme.palette.warning.dark,
                    width: 16,
                    marginRight: 8,
                  }}
                />
                <Box>
                  <Typography variant="h5">Warning</Typography>
                  <Typography variant="body1" mb={2}>
                    {`when you delete ${roleDetails?.data?.role?.name} role it can't be undone.`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box alignSelf={"flex-end"} mr={9}>
            <ButtonV1
              variant="contained"
              title={"Delete"}
              style={{ paddingLeft: 15, paddingRight: 15 }}
              onClick={() => handleDeleteRole()}
            />
          </Box>
        </Box>
      </RightDrawer>
    </Box>
  );
};

export default RoleDetails;
