import { styled } from "@mui/system";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import { TextField } from "components";

const Label = styled("span")({
  fontWeight: "600",
});

const InputField = ({
  label,
  value = "",
  onChange,
  disabled,
  placeholder,
  valueStyle,
  type,
  defaultValue,
  isRequired,
  multiline,
  maxRows,
  highlightError,
  labelStyle,
  inputProps,
  onKeyDown,
  rest,
  customPadding,
  onBlur,
  onFocus,
}) => {
  const defaultNegativeCheck = (evt) => {
    if (type === "number" && (evt.key === "e" || evt.key === "-")) {
      evt.preventDefault();
    }
    onKeyDown && onKeyDown();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        ...rest,
      }}
    >
      <Label
        style={{
          ...(labelStyle && { ...labelStyle }),
          paddingRight: 6,
        }}
      >
        {label}
      </Label>
      <TextField
        data-testid="text-field"
        aria-label="text-field-label"
        variant="outlined"
        style={{ ...(valueStyle && { ...valueStyle }) }}
        customPadding={customPadding}
        placeholder={placeholder}
        multiline={multiline}
        maxRows={maxRows}
        size="small"
        disabled={disabled}
        highlightError={highlightError}
        type={type}
        onChange={onChange}
        defaultValue={defaultValue || ""}
        value={value}
        required={isRequired}
        InputProps={{ inputProps: { min: 0, ...inputProps } }}
        onKeyDown={(evt) => defaultNegativeCheck(evt)}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </Box>
  );
};

InputField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  valueStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  type: PropTypes.string,
  isRequired: PropTypes.bool,
  multiline: PropTypes.bool,
  maxRows: PropTypes.number,
  inputProps: PropTypes.object,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export default InputField;
