import { forwardRef, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import CustomerTable from "./CustomerTable";
import TaggedPaymentHeader from "./CreditCustomersHeader";

import { useFetchData, useToaster } from "hooks";
import { error } from "constants";
import { searchConstants } from "./constants";

const { SEARCH_FILTER_FORM } = searchConstants;

const CreditCustomers = forwardRef((_, ref) => {
  const [taggedFilters, setTaggedFilters] = useState("");
  const [page, setPage] = useState(0);
  const navigation = useNavigate();
  const triggerToaster = useToaster();

  const filterRef = useRef(null);

  const searchCustomerMasterApi = `oms/credit/list/search?${taggedFilters}`;

  const {
    refetch: fetchCustomers,
    data: fetchedCustomersData,
    isFetching: customerFetching,
  } = useFetchData("searched-customers", searchCustomerMasterApi);

  // trigger on Page number change and filterChange
  useEffect(() => {
    fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, taggedFilters]);
  const handleFilterApi = (filter) => {
    if (!filter?.[SEARCH_FILTER_FORM.searchValue]) {
      triggerToaster(`Please Enter Value To Search`, error);
      return;
    }
    if (!filter?.[SEARCH_FILTER_FORM.searchType]) {
      triggerToaster(`Please Enter Type To Search`, error);
      return;
    }

    setTaggedFilters(
      `&searchType=${filter?.[SEARCH_FILTER_FORM.searchType].trim() || ""}
             &searchValue=${filter?.[SEARCH_FILTER_FORM.searchValue] || ""}`,
    );

    setPage(0);
  };

  const onRowClick = (customerPan, event, gstin) => {
    if (customerPan && !event.target.href) {
      navigation(`/credit-limit/${customerPan}?gstin=${gstin}`);
    }
  };

  return (
    <>
      <TaggedPaymentHeader onTriggerFilter={handleFilterApi} ref={filterRef} />
      <CustomerTable
        isLoading={customerFetching}
        data={fetchedCustomersData?.data ?? []}
        onVerify={onRowClick}
      />
    </>
  );
});

export default CreditCustomers;
