import React, { useCallback } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import { ButtonV1, DatePickerV1 } from "components";
import { getAttributes, moneyFormat } from "utils";
import { DISPATCH_ORDER } from "../constants";

const ConfirmPaymentModal = ({
  selectedLineItemList = [],
  estimatedPrice,
  onCloseModal,
  onRequestPayment,
}) => {
  // const theme = useTheme();
  const { control } = useFormContext();

  /**
   * @description payment request fn invoked
   */
  const handleConfirmModal = useCallback(
    async () => await onRequestPayment(),
    [onRequestPayment],
  );
  // const totalPayableAmount = estimatedPrice - ledgerDetail;

  return (
    <>
      <Typography variant="h4">Payment request</Typography>
      <Divider />
      <Box display="flex" sx={{ alignItems: "center", my: 4, gap: 2 }}>
        Estimate date of dispatch:
        <Controller
          control={control}
          name={"estimatedDispatchDate"}
          render={({ field: { value, onChange } }) => (
            <DatePickerV1
              value={value}
              onChange={onChange}
              dateStyle={{
                width: 150,
                "&.MuiTextField-root": {
                  ">.MuiOutlinedInput-root": {
                    paddingRight: 2,
                    borderRadius: 0.5,
                    ">.MuiOutlinedInput-input": {
                      padding: "4px 8px",
                    },
                  },
                },
              }}
            />
          )}
        />
      </Box>
      <Paper elevation={1} sx={{ marginBottom: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              {DISPATCH_ORDER.paymentRequest.map((head) => (
                <TableCell>{head}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedLineItemList?.map((lineItem) => {
              const totalPlannedQty = lineItem.plannedQty;
              return (
                <TableRow>
                  <TableCell>
                    {lineItem.skuName}
                    {getAttributes(lineItem?.attributes)}
                  </TableCell>
                  <TableCell>
                    {lineItem.totalQuantity} {lineItem.uom}
                  </TableCell>
                  <TableCell>
                    {totalPlannedQty} {lineItem.uom}
                  </TableCell>
                  <TableCell>
                    {moneyFormat(totalPlannedQty * lineItem.ratePerUnit, 0)}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell colSpan={DISPATCH_ORDER.dispatchTableHeader.length}>
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flex: 1,
                    gap: 1,
                  }}
                >
                  Total shipment value :<b>{moneyFormat(estimatedPrice)}</b>
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <Box
        display={"flex"}
        sx={{
          justifyContent: "flex-end",
          gap: 4,
          alignItems: "center",
        }}
      >
        {/* <Box
          display={"flex"}
          sx={{
            flex: 1,
            visibility: totalPayableAmount > 0 ? "visible" : "hidden",
          }}
        >
          <Typography
            display={"flex"}
            sx={{
              backgroundColor: theme.palette.grey["A100"],
              padding: 2,
              borderRadius: 0.5,
              alignItems: "center",
              gap: 2,
              fontSize: 14,
            }}
          >
            <SvgIcon name="info" width={15} height={15} /> Note: Customer will
            receive a mail to pay
            <Typography fontWeight={600}>
              {moneyFormat(totalPayableAmount)}
            </Typography>
          </Typography>
        </Box> */}

        <ButtonV1 title={"Cancel"} variant="outlined" onClick={onCloseModal} />
        <ButtonV1 title={"Confirm"} onClick={handleConfirmModal} />
      </Box>
    </>
  );
};

ConfirmPaymentModal.propTypes = {
  selectedLineItemList: PropTypes.array,
  ledgerDetail: PropTypes.string,
  advanceDetail: PropTypes.string,
  estimatedPrice: PropTypes.number,
  onCloseModal: PropTypes.func,
  onRequestPayment: PropTypes.func,
};

export default React.memo(ConfirmPaymentModal);
