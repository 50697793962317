import PropTypes from "prop-types";

import CustomerBalanceDetails from "./CustomerBalanceDetails";
import CreditTable from "./CreditTable";
import LedgerTable from "./LedgerTable";

import { selectedCustomerPropTypes } from "./propTypes";

const LedgerDetails = ({ selectedCustomer }) => {
  return (
    <>
      <CustomerBalanceDetails selectedCustomer={selectedCustomer} />
      <CreditTable selectedCustomer={selectedCustomer} />
      <LedgerTable selectedCustomer={selectedCustomer} />
    </>
  );
};

export default LedgerDetails;

LedgerDetails.propTypes = {
  selectedCustomer: PropTypes.shape(selectedCustomerPropTypes).isRequired,
};
