import { CloseOutlined } from "@mui/icons-material";
import { Chip, useTheme } from "@mui/material";

const CustomChip = ({ option, displayAttribute, ...rest }) => {
  const theme = useTheme();
  return (
    <Chip
      variant="outlined"
      label={option?.[displayAttribute] || option}
      size="small"
      style={{
        border: "none",
        borderRadius: 6,
        backgroundColor: theme.palette.grey["A100"],
        marginRight: 6,
      }}
      deleteIcon={
        <CloseOutlined
          style={{
            fontSize: 12,
            color: theme.palette.text.primary,
          }}
        />
      }
      {...rest}
    />
  );
};

export default CustomChip;
