import { useCallback, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";

import {
  ButtonV1,
  Loader,
  PaginationAction,
  StatusChip,
  SearchBar,
  TableRowWithError,
  DialogBox,
} from "components";
import { QuickFilter } from "./components";

import { useFetchData, useToaster } from "hooks";
import { setSelectedUserProfileAction } from "dataStore/selectedUserProfile";
import { getCall_v2, postCall_v2 } from "services";
import { STATUS_CHIP } from "constants";
import customerProfile from "./constants";
import { success, error } from "constants";

const {
  CUSTOMER_BANK_ACCOUNTS_HEADER,
  profileStatus: { APPROVED, REJECTED, PENDING },
} = customerProfile;

const rowsPerPage = 20;

export const getStatusType = (status) => {
  switch (status) {
    case APPROVED.toUpperCase():
      return STATUS_CHIP.success;
    case "DELETED":
    case REJECTED.toUpperCase():
      return STATUS_CHIP.error;
    case PENDING.toUpperCase():
      return STATUS_CHIP.warning;
    default:
      break;
  }
};

const SearchResultBox = ({ name, email, gstin }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={1} minWidth={180}>
      <Typography sx={{ fontWeight: 600 }}>{name}</Typography>
      <Typography variant="body2"> {email}</Typography>
      <Typography variant="body2"> {gstin}</Typography>
    </Box>
  );
};

const CustomerProfile = () => {
  const [page, setPage] = useState(0);
  const [searchDetails, setSearchDetails] = useState();
  const [failedAccounts, setFailedAccounts] = useState([]);
  const [status, setStatus] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rejectId, setRejectId] = useState("");
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const triggerToaster = useToaster();
  const searchRef = useRef(null);
  const theme = useTheme();

  const {
    data: bankAccounts,
    refetch,
    isFetching,
  } = useFetchData(
    "bank-account-list",
    `/oms/customer/getCustomerBankDetailsRequestList?offset=${
      page * rowsPerPage
    }&limit=${rowsPerPage}&status=${status?.toUpperCase()}&gst=${
      searchDetails?.gstin ?? ""
    }`,
  );

  useEffect(() => {
    refetch();
  }, [page, status, searchDetails]);

  const getSearchedData = async (search) => {
    try {
      const { data } = await getCall_v2(`/oms/customer/search-user/${search}`);
      return data;
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleQuickFilter = useCallback((filter) => {
    setStatus(filter);
  }, []);

  const onViewDetails = useCallback((data) => {
    navigation(`/customer-profile-details/${data?.customerGstin}`);
    dispatch(setSelectedUserProfileAction(data));
  }, []);

  const onPageChange = useCallback((_event, page) => setPage(page), []);
  const passValue = useCallback((data) => setSearchDetails(data), []);

  const handleReject = useCallback((id) => {
    setIsModalOpen(true);
    setRejectId(id);
  }, []);

  const handleApproveReject = async (status, item = {}) => {
    try {
      const res = await postCall_v2(
        `/oms/customer/updateCustomerBankDetailsRequest`,
        {
          id: `${status === "APPROVED" ? item?.id : rejectId}`,
          status,
          comments: "",
          customerGstin: item?.customerGstin ?? "",
        },
      );
      if (res) {
        const { data } = res;
        if (typeof data?.message === "string") {
          const msgType = data?.success ? success : error;
          triggerToaster(data?.message, msgType);
          setIsModalOpen(false);
          refetch();
        }
      }
    } catch (err) {
      triggerToaster(`Error: Fund ID not generated`, error);
    }
  };

  const onCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleCloseError = useCallback(
    (id) => {
      const cloneFailedAccounts = [...failedAccounts];
      cloneFailedAccounts.push(id);
      setFailedAccounts(cloneFailedAccounts);
    },
    [failedAccounts],
  );

  return (
    <Box px={5}>
      <Typography variant="h5">Customer profile (Bank accounts)</Typography>
      <Stack
        direction="row"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <QuickFilter passQuickFilter={handleQuickFilter} />
        <SearchBar
          style={{ marginTop: 2, width: 270 }}
          resolver={getSearchedData}
          displayAttribute="name"
          onItemSelect={passValue}
          renderItem={SearchResultBox}
          placeholder={"Entity Search by name or GSTIN"}
          ref={searchRef}
        />
      </Stack>
      <Paper>
        <TableContainer
          sx={{
            minWidth: 700,
            maxHeight: "calc(100vh - 120px)",
          }}
        >
          <Table stickyHeader>
            <TableHead style={{ borderRadius: "10px 10px 0 0" }}>
              <TableRow>
                {CUSTOMER_BANK_ACCOUNTS_HEADER.map((heading) => (
                  <TableCell key={heading}>{heading}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {bankAccounts?.data?.customerBankAccountRequestlist?.length &&
              !isFetching ? (
                bankAccounts?.data?.customerBankAccountRequestlist?.map(
                  (item, i) => (
                    <>
                      <TableRow key={item?.id}>
                        <TableCell
                          sx={{
                            maxWidth: 200,
                            color: theme.palette.primary.main,
                            fontWeight: "bold",
                          }}
                        >
                          {item?.companyName}
                        </TableCell>
                        <TableCell sx={{ maxWidth: 200 }}>
                          {item?.beneficiaryName}
                        </TableCell>
                        <TableCell>
                          <StatusChip
                            label={item?.status}
                            type={getStatusType(item?.status)}
                          />
                        </TableCell>
                        <TableCell sx={{ maxWidth: 200 }}>
                          {item.status === PENDING.toLocaleUpperCase()
                            ? item?.comments
                            : "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          {item.status === PENDING.toUpperCase() &&
                          item?.comments === "New" ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                maxWidth: 200,
                              }}
                            >
                              <ButtonV1
                                title="Approve"
                                variant="text"
                                sx={{ padding: 1, marginLeft: -2 }}
                                onClick={() =>
                                  handleApproveReject(
                                    APPROVED.toUpperCase(),
                                    item,
                                  )
                                }
                              />
                              <Box
                                sx={{
                                  height: 16,
                                  border: `1px solid ${theme.palette.grey["400"]}`,
                                  alignSelf: "center",
                                  marginLeft: 2,
                                }}
                              />
                              <ButtonV1
                                title="Reject"
                                variant="text"
                                onClick={() => handleReject(item?.id)}
                                sx={{
                                  color: theme.palette.common.red,
                                  padding: 1,
                                }}
                              />
                            </Box>
                          ) : (
                            <ButtonV1
                              onClick={() => onViewDetails(item)}
                              title="View"
                              variant="text"
                              sx={{ padding: 0, marginLeft: -3 }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                      {item.status === "FAILED" &&
                        !failedAccounts.includes(item?.id) && (
                          <TableCell
                            colSpan={CUSTOMER_BANK_ACCOUNTS_HEADER.length + 1}
                            sx={{ textAlign: "center", padding: 0 }}
                          >
                            <TableRowWithError
                              message={item?.errorMessage}
                              id={item?.id}
                              key={item?.id}
                              onClose={handleCloseError}
                              length={CUSTOMER_BANK_ACCOUNTS_HEADER.length + 1}
                            />
                          </TableCell>
                        )}
                    </>
                  ),
                )
              ) : isFetching ? (
                <TableCell
                  colSpan={CUSTOMER_BANK_ACCOUNTS_HEADER.length}
                  sx={{ textAlign: "center" }}
                >
                  <Loader sx={{ marginTop: 0 }} />
                </TableCell>
              ) : (
                <TableCell
                  colSpan={CUSTOMER_BANK_ACCOUNTS_HEADER.length}
                  sx={{ textAlign: "center" }}
                >
                  No data found
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!!bankAccounts?.data?.customerBankAccountRequestlist?.length && (
          <TablePagination
            rowsPerPageOptions={[rowsPerPage]}
            rowsPerPage={rowsPerPage}
            page={page}
            count={bankAccounts?.data?.totalCount ?? 0}
            onPageChange={onPageChange}
            sx={{ display: "flex", flexDirection: "column-reverse" }}
            ActionsComponent={PaginationAction}
          />
        )}
        <DialogBox
          handleClose={onCloseModal}
          open={isModalOpen}
          handleConfirm={() => handleApproveReject(REJECTED.toUpperCase())}
          message={"Are you sure you want to reject?"}
        />
      </Paper>
    </Box>
  );
};

export default CustomerProfile;
