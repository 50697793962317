import { Box, Chip, Typography, styled } from "@mui/material";
import { CREDIT } from "../constant";

const { quickFilter: QUICK_FILTER } = CREDIT;

const QuickChip = styled(Chip)(({ theme }) => ({
  borderRadius: 4,
  minWidth: 60,
  ".MuiChip-outlinedDefault": {
    borderColor: theme.palette.common.black,
    color: theme.palette.common.black,
  },
  ".MuiChip-filled": {
    border: `1px solid ${theme.palette.grey[400]}`,
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.grey[200],
  },
}));

const QuickFilter = ({ onQuickFilter, state }) => {
  const handleSelect = (elem) => {
    onQuickFilter(elem);
  };

  return (
    <Box display={"flex"} sx={{ gap: 3 }}>
      <Typography sx={{ fontWeight: 600 }}>Quick filters:</Typography>
      {Object.keys(QUICK_FILTER).map((elem) => (
        <QuickChip
          key={elem}
          label={QUICK_FILTER[elem]}
          size="small"
          variant={state === elem ? "outlined" : "filled"}
          onClick={() => handleSelect(elem)}
        />
      ))}
    </Box>
  );
};

export default QuickFilter;
