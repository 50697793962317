import { Suspense, lazy } from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material";

import { ButtonV1, Loader } from "components";

import { useToaster } from "hooks";
import { success } from "constants";

const CreateShipment = lazy(() => import("./CreateShipment"));

/**
 * @description Review content
 * post file upload
 */
const ReviewContent = ({
  shipmentDetails,
  onCancel,
  createShipment,
  selectedShipmentIndex,
}) => {
  const theme = useTheme();

  const triggerToaster = useToaster();

  /**
   * @description Remove all credit instruments in review list
   */
  const removeAll = () => {
    onCancel();
    triggerToaster(
      `Cleared uploaded bulk shipment sheet successfully`,
      success,
    );
  };

  return (
    <>
      {!!(shipmentDetails && shipmentDetails.length) && (
        <>
          <Box
            sx={{
              display: "flex",
              direction: "row",
              justifyContent: "space-between",
              marginBottom: 4,
            }}
          >
            <Typography fontSize={18} fontWeight={"bold"} mb={2}>
              {`Review shipments (${shipmentDetails?.length})`}
            </Typography>
            <ButtonV1
              title={"Clear all"}
              onClick={removeAll}
              variant="outlined"
              style={{
                color: theme.palette.common.red,
                borderColor: theme.palette.common.red,
                "&:hover": {
                  borderColor: theme.palette.common.red,
                },
              }}
            />
          </Box>
          <Suspense fallback={<Loader />}>
            {shipmentDetails.map((ele, index) => {
              return (
                <CreateShipment
                  data={ele}
                  index={index}
                  createShipment={(data, ref) =>
                    createShipment(data, ele, ref, index)
                  }
                  selectedShipmentIndex={selectedShipmentIndex}
                />
              );
            })}
          </Suspense>
        </>
      )}
    </>
  );
};

ReviewContent.propTypes = {
  shipmentDetails: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  createShipment: PropTypes.func,
  selectedShipmentIndex: PropTypes.number,
};
export default ReviewContent;
