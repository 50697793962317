import { Box, Divider, Typography } from "@mui/material";
import { VisibilityOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import Groups from "./Groups";
import Roles from "./Roles";
import AddUser from "./AddUser";
import { ButtonV1 } from "components";

const UserManagement = () => {
  const navigate = useNavigate();

  return (
    <Box pl={10} pr={10}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h3">User Access Management</Typography>
        <Box display={"flex"}>
          <AddUser />
          <ButtonV1
            onClick={() => navigate("/user-management/users")}
            title="View user"
            variant="outlined"
            size="small"
            style={{ marginLeft: 4, minWidth: "unset", borderRadius: 20 }}
            startIcon={<VisibilityOutlined />}
          />
        </Box>
      </Box>
      <Groups />
      <Divider style={{ marginBottom: 32 }} />
      <Roles />
    </Box>
  );
};

export default UserManagement;
