import { useMemo } from "react";
import { SearchOutlined } from "@mui/icons-material";
import { Typography, Box, IconButton, Button } from "@mui/material";
import {
  LastPage,
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";

import { DatePickerV1, Loader, TextField } from "components";
import { timeConvertor, dateConvertor } from "utils";

export const SearchList = ({ companyName, email, gstin, numOrdersPending }) => {
  return (
    <>
      <Typography sx={{ fontWeight: 600 }}>
        {companyName}
        {` (${numOrdersPending ?? 0})`}
      </Typography>
      <Typography variant="body2"> {email}</Typography>
      <Typography variant="body2"> {gstin}</Typography>
    </>
  );
};

export const SearchBar = ({ theme, value, onChange }) => (
  <TextField
    size="small"
    placeholder="Search tagged payment"
    sx={{ width: 220 }}
    value={value}
    onChange={onChange}
    InputProps={{
      startAdornment: (
        <SearchOutlined
          fontSize="small"
          sx={{ color: theme.palette.text.disabled, marginRight: 1 }}
        />
      ),
    }}
  />
);

export const DateSelector = ({ label, minDate, value, onChange, sx = {} }) => (
  <Box display={"flex"} sx={{ alignItems: "center", gap: 3, ...sx }}>
    <Typography textTransform={"capitalize"}>{label}</Typography>
    <DatePickerV1 value={value} onChange={onChange} minDate={minDate} />
  </Box>
);

export const Timestamp = ({ timeStamp }) => {
  const date = useMemo(() => {
    let ts = `${timeStamp}Z`;
    const convertedDate = dateConvertor(ts);
    const convertedTime = timeConvertor(ts);
    return [convertedDate, convertedTime];
  }, [timeStamp]);

  return (
    <Typography>
      {date[0]}
      <br />
      <Typography noWrap>{date[1]}</Typography>
    </Typography>
  );
};

export const CustomTablePagination = (props) => {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0}>
        <FirstPage />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <LastPage />
      </IconButton>
    </Box>
  );
};

export const ButtonWithLoader = ({ title, onClick, isLoading, variant }) => (
  <Button onClick={onClick} variant={variant} disabled={isLoading}>
    {isLoading ? <Loader sx={{ marginTop: 0 }} /> : title}
  </Button>
);
