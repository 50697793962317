import { validateNull } from "utils";

const validateValue = (value) => {
  return value ? `${value},` : "";
};

const getFormattedAddress = (address) => {
  const { addressLine1, addressLine2, city, state, pincode } = address;
  return `
  ${validateValue(addressLine1)}
  ${validateValue(addressLine2)}
  ${validateValue(city)}
  ${validateValue(state)}
  ${validateValue(pincode)}`;
};

export const getShipFromAddress = (sourceSeller) => {
  let shipFrom = "";
  if (sourceSeller) {
    const { shippingAddress } = sourceSeller;
    if (shippingAddress) {
      shipFrom = getFormattedAddress(shippingAddress);
    }
  }
  return shipFrom;
};

export const getBillFromAddress = (sourceSeller) => {
  let billFrom = "";
  if (sourceSeller) {
    const { billingAddress } = sourceSeller;
    if (billingAddress) {
      billFrom = getFormattedAddress(billingAddress);
    }
  }
  return billFrom;
};

export const formatSourceSellerData = (sourceSeller) => {
  const shipFormData = getShipFromAddress(sourceSeller);
  const billFromData = getBillFromAddress(sourceSeller);
  const formattedData = {
    Name: validateNull(sourceSeller?.companyName),
    "Mobile no.": validateNull(sourceSeller?.mobileNumber),
    "Email ID": validateNull(sourceSeller?.email),
    GSTIN: validateNull(sourceSeller?.gstin),
    "Ship from": validateNull(shipFormData?.trim()),
    "Bill from": validateNull(billFromData?.trim()),
  };
  return formattedData;
};

export const getSellerInfo = (sellerDetails, orderInfo) => {
  if (sellerDetails) {
    const { sellerKey } = sellerDetails;
    let sellerInfo = orderInfo?.sellerDetails?.[sellerKey];
    let address;
    if (sellerInfo?.address) {
      const { streetLine, city, state, postalCode } = sellerInfo?.address;
      address = `${streetLine ? streetLine + ",\n" : ""} ${
        city ? city + ", " : ""
      } ${state ? state + ", " : ""} ${postalCode ?? ""}`;
    }

    let formattedSellerInfo = {
      Name: orderInfo?.sellerDetails[sellerKey]?.name,
      ...(orderInfo?.sellerDetails[sellerKey]?.gstin && {
        GSTIN: orderInfo?.sellerDetails[sellerKey]?.gstin,
      }),
    };
    if (address)
      return {
        ...formattedSellerInfo,
        "Ship from": address,
        "Bill from": address,
      };
    return formattedSellerInfo;
  }
};
