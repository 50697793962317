import * as Yup from "yup";
import { getFixedWords } from "utils";

const createGroupSchema = Yup.object().shape({
  groupName: Yup.string().required("Please enter group name"),
  groupDescription: Yup.string().test(
    "len",
    "Maximum 150 words are allowed",
    (value) => getFixedWords(value, 150),
  ),
});

export { createGroupSchema };
