import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import {
  TableCell,
  TableRow,
  Typography,
  Box,
  Grid,
  Collapse,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";

import {
  ButtonV1,
  Loader,
  TableWrapper,
  ModalBox,
  StatusChip,
} from "components";
import { DISPATCH_ORDER } from "./constants";
import { ColumnTitle } from "CustomStyledComponents";

import { useFetchData, useToaster } from "hooks";
import { getAttributes, decimalRoundOff, moneyFormat } from "utils";
import { getLabelType } from "./utils";
import { getCall_v2, postCall_v2, putCall_v2 } from "services";
import { success, error } from "constants";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import RequestShortCloseModal from "./RequestShortCloseModal";

const {
  dispatchRecordHeader,
  dispatchOrderLineItemHeader,
  status,
  dipatchOrderDetailsHeader,
  plannedShipmentStatus,
} = DISPATCH_ORDER;

const DispatchOrderRecord = forwardRef(
  ({ fetchLineItem, isCreditBlocked }, ref) => {
    const [isModalVisible, setModalVisibility] = useState(false);
    const [isRequestModalOpen, setRequestModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [lineItemData, setLineItemData] = useState();
    const [isCollapseOpen, setCollapseOpen] = useState(false);
    const [doRequestItem, setDoRequestItem] = useState();
    const triggerToaster = useToaster();

    const orderDetails = useSelector((state) => state.orderDetails);
    const { orderNumber } = orderDetails;
    const {
      refetch: fetchOrderRecords,
      isFetching: isRecordFetching,
      data: dispatchRecord,
    } = useFetchData(
      "dispatch-order-record",
      `/oms/plant-supply/requested-payments?orderNumber=${orderNumber}`,
    );
    useEffect(() => {
      fetchOrderRecords();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref, () => ({
      invokeRecordApi: () => fetchOrderRecords(),
    }));

    /**
     * @description toggle modal and set action
     */
    const toggleModal = async (record) => {
      if (!isModalVisible) {
        try {
          const { data } = await getCall_v2(
            `/oms/plant-supply/dispatch-order/details?psId=${record?.psId}`,
          );
          setLineItemData(data);
        } catch (err) {
          triggerToaster(`Error in getting records`, error);
          return;
        }
        setSelectedRecord(record);
      } else {
        setCollapseOpen(false);
        setSelectedRecord(null);
      }
      setModalVisibility(!isModalVisible);
    };
    /**
     * @description Invoke status change api
     */
    const markDispatchRecord = async (selectedStatus) => {
      try {
        let url;
        if (selectedStatus === status.release) {
          url = `oms/plant-supply/status/release?psId=${selectedRecord?.psId}`;
          await postCall_v2(url);
        } else {
          url = `/oms/plant-supply`;
          const { psId, psIdentifier } = selectedRecord;
          const payload = {
            psId,
            psIdentifier,
            transactionStatus:
              status.rejected.charAt(0).toUpperCase() +
              status.rejected.slice(1),
            rejectionReason: `Rejected ${psId}`,
          };
          await putCall_v2(url, payload);
        }
        triggerToaster(`Successfully changed status`, success);
        fetchOrderRecords();
        //invoke dispatch lineitems
        fetchLineItem();
      } catch (ex) {
        console.log(`Error in changing dispatch status, ${ex}`);
        triggerToaster(`Error in changing dispatch order status`, error);
      } finally {
        //close modal & remove set status
        setModalVisibility(!isModalVisible);
        setSelectedRecord(null);
      }
    };

    const handleCollapsible = () => {
      setCollapseOpen((prev) => !prev);
    };

    const getLineItemDispatchedQty = (rowItem) => {
      const dispatchQty = rowItem?.shipmentLinkList.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue?.shippedQuantity,
        0,
      );
      return dispatchQty;
    };

    const showPendingQtyLabel = (record) => {
      const { psPlannedQuantity, psShippedQuantity } = record ?? {};
      const result = Math.abs(
        decimalRoundOff(psShippedQuantity - psPlannedQuantity, 3),
      );
      const uom =
        record?.psLineItemsDetails?.[0]?.measurementUnit ===
        DISPATCH_ORDER.uomBag
          ? DISPATCH_ORDER.uomBagLabel
          : record?.psLineItemsDetails?.[0]?.measurementUnit;
      let quantityText =
        psPlannedQuantity > psShippedQuantity ? "Pending" : "PT";
      quantityText = result > 0 ? `(${quantityText})` : "";
      return `${result}  ${uom}  ${quantityText}`;
    };

    const showPendingQtyLabelInDetailsModel = (pendingQuantity, uom) => {
      const result = Math.abs(pendingQuantity);
      let quantityText = pendingQuantity > 0 ? "Pending" : "PT";
      quantityText = result > 0 ? `(${quantityText})` : "";
      return `${result}  ${uom}  ${quantityText}`;
    };

    const toggleShortCloseModal = () => {
      setRequestModalOpen(!isRequestModalOpen);
    };

    const autoShortCloseRequest = async (item) => {
      const payload = {
        psIdentifier: selectedRecord?.psIdentifier,
        orderNumber,
        lineItemIds: [item?.lineItemId],
      };
      try {
        const res = await postCall_v2(
          `/oms/plant-supply/do/short-close/release`,
          payload,
        );
        triggerToaster(res?.data, success);
      } catch (err) {
        triggerToaster(`${err?.data?.detail}`, error);
      } finally {
        toggleModal();
      }
    };
    const onRequestShortClose = (item, totalDispatchedQty) => {
      if (totalDispatchedQty > item?.plannedQuantity * 0.9) {
        autoShortCloseRequest(item);
      } else {
        setDoRequestItem({
          record: selectedRecord,
          item,
          totalDispatchedQty,
          orderNumber: orderNumber,
        });
        toggleModal();
        toggleShortCloseModal();
      }
    };

    return (
      <>
        <TableWrapper
          tableHeader={dispatchRecordHeader.map((heading, index) => {
            return (
              <TableCell key={index}>
                <ColumnTitle>{heading}</ColumnTitle>
              </TableCell>
            );
          })}
          tableBody={
            isRecordFetching ? (
              <>
                <TableRow>
                  <TableCell colSpan={dispatchRecordHeader.length}>
                    <Loader sx={{ margin: 0 }} />
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <>
                {dispatchRecord?.data?.plantSupplyRequestPayments ? (
                  dispatchRecord?.data?.plantSupplyRequestPayments?.map(
                    (record) => {
                      return (
                        <TableRow>
                          <TableCell>{record?.psIdentifier}</TableCell>
                          <TableCell>
                            {moneyFormat(
                              record?.psPlannedShipmentAmount +
                                +(record?.freightValue || 0),
                            )}
                          </TableCell>
                          <TableCell>
                            {`${record?.psPlannedQuantity} `}
                            {record?.psLineItemsDetails?.[0]
                              ?.measurementUnit === DISPATCH_ORDER.uomBag
                              ? DISPATCH_ORDER.uomBagLabel
                              : record?.psLineItemsDetails?.[0]
                                  ?.measurementUnit}
                          </TableCell>
                          <TableCell>
                            {`${record?.psShippedQuantity ?? 0} `}
                            {record?.psLineItemsDetails?.[0]
                              ?.measurementUnit === DISPATCH_ORDER.uomBag
                              ? DISPATCH_ORDER.uomBagLabel
                              : record?.psLineItemsDetails?.[0]
                                  ?.measurementUnit}
                          </TableCell>
                          <TableCell>{showPendingQtyLabel(record)}</TableCell>
                          <TableCell>
                            <StatusChip
                              label={record.transactionStatus}
                              type={getLabelType(record.transactionStatus)}
                              style={{ minWidth: 75 }}
                            />
                          </TableCell>
                          <TableCell>
                            <ButtonV1
                              title={"Details"}
                              variant="text"
                              style={{ padding: 0 }}
                              boxStyle={{ justifyContent: "left" }}
                              onClick={() => toggleModal(record)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    },
                  )
                ) : (
                  <>
                    <TableCell
                      colSpan={dispatchRecordHeader.length}
                      align="center"
                    >
                      <Typography textAlign={"center"}>
                        No dispatch order records found
                      </Typography>
                    </TableCell>
                  </>
                )}
              </>
            )
          }
        />

        <ModalBox open={isModalVisible} onCloseModal={toggleModal} width="80%">
          <Box p={4}>
            <Box display={"flex"} flexDirection={"column"} mb={4}>
              <Box display={"flex"} mb={4}>
                <Typography fontSize={20}>Dispatch order id: </Typography>
                <Typography fontSize={20} fontWeight={600}>
                  {selectedRecord?.psId}
                </Typography>
              </Box>

              <Grid container columns={14}>
                <Grid xs={2}>
                  <Typography fontWeight={"bold"} fontSize={14}>
                    Transaction status
                  </Typography>
                  <StatusChip
                    label={selectedRecord?.transactionStatus}
                    type={getLabelType(selectedRecord?.transactionStatus)}
                    style={{ minWidth: 75 }}
                  />
                </Grid>
                <Grid xs={2}>
                  <Typography fontWeight={"bold"} fontSize={14}>
                    Total DO quantity
                  </Typography>
                  <Typography>
                    {`${selectedRecord?.psPlannedQuantity} `}
                    {selectedRecord?.psLineItemsDetails?.[0]
                      ?.measurementUnit === DISPATCH_ORDER.uomBag
                      ? DISPATCH_ORDER.uomBagLabel
                      : selectedRecord?.psLineItemsDetails?.[0]
                          ?.measurementUnit}
                  </Typography>
                </Grid>
                <Grid xs={2}>
                  <Typography fontWeight={"bold"} fontSize={14}>
                    Dispatched quantity
                  </Typography>
                  <Typography>
                    {`${selectedRecord?.psShippedQuantity ?? 0} `}
                    {selectedRecord?.psLineItemsDetails?.[0]
                      ?.measurementUnit === DISPATCH_ORDER.uomBag
                      ? DISPATCH_ORDER.uomBagLabel
                      : selectedRecord?.psLineItemsDetails?.[0]
                          ?.measurementUnit}
                  </Typography>
                </Grid>
                <Grid xs={2}>
                  <Typography fontWeight={"bold"} fontSize={14}>
                    Pending quantity
                  </Typography>
                  <Typography>
                    {showPendingQtyLabel(selectedRecord)}
                    {/* {`${
                    Number(selectedRecord?.psPlannedQuantity ?? 0) -
                    Number(selectedRecord?.psShippedQuantity ?? 0)
                  } `}
                  {selectedRecord?.psLineItemsDetails?.[0]?.measurementUnit ===
                  DISPATCH_ORDER.uomBag
                    ? DISPATCH_ORDER.uomBagLabel
                    : selectedRecord?.psLineItemsDetails?.[0]?.measurementUnit} */}
                  </Typography>
                </Grid>
                <Grid xs={2}>
                  <Typography fontWeight={"bold"} fontSize={14}>
                    Total DO value
                  </Typography>
                  <Typography>
                    {moneyFormat(
                      +selectedRecord?.psPlannedShipmentAmount +
                        +(selectedRecord?.freightValue ?? 0),
                    )}
                  </Typography>
                </Grid>
                <Grid xs={2}>
                  <Typography fontWeight={"bold"} fontSize={14}>
                    Freight value
                  </Typography>
                  <Typography>
                    {moneyFormat(selectedRecord?.freightValue || 0)}
                  </Typography>
                </Grid>
                <Grid xs={2}>
                  <Typography fontWeight={"bold"} fontSize={14}>
                    Material value
                  </Typography>
                  <Typography>
                    {moneyFormat(selectedRecord?.psPlannedShipmentAmount || 0)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box mb={6}>
              <TableWrapper
                tableHeader={
                  <>
                    {dipatchOrderDetailsHeader.map((heading, index) => {
                      return (
                        <TableCell key={index}>
                          <ColumnTitle>{heading}</ColumnTitle>
                        </TableCell>
                      );
                    })}
                    <TableCell></TableCell>
                  </>
                }
                tableBody={
                  lineItemData &&
                  lineItemData?.plannedShipmentLineItemDetails?.map((item) => {
                    const totalDispatchedQty = getLineItemDispatchedQty(item);
                    const measurementUnit =
                      item.measurementUnit === DISPATCH_ORDER.uomBag
                        ? DISPATCH_ORDER.uomBagLabel
                        : item.measurementUnit;
                    return (
                      <>
                        <TableRow as={Box} onClick={handleCollapsible}>
                          <TableCell style={{ width: 200 }}>
                            {item?.productInfo?.name}
                            {getAttributes(item?.productInfo?.attributes)}
                          </TableCell>
                          <TableCell>
                            {`${item?.plannedQuantity} `}
                            {measurementUnit}
                          </TableCell>
                          <TableCell>
                            {`${totalDispatchedQty} `}
                            {measurementUnit}
                          </TableCell>
                          <TableCell>
                            {decimalRoundOff(
                              (item?.plannedQuantity ?? 0) -
                                (totalDispatchedQty ?? 0),
                            )}
                            {measurementUnit}
                          </TableCell>
                          <TableCell>{moneyFormat(item?.price)}</TableCell>
                          <TableCell>
                            <ButtonV1
                              title={"Request for DO short closure"}
                              variant="text"
                              size="small"
                              disabled={
                                selectedRecord?.transactionStatus !==
                                plannedShipmentStatus?.Release
                              }
                              onClick={() =>
                                onRequestShortClose(item, totalDispatchedQty)
                              }
                            />
                          </TableCell>

                          <TableCell>
                            {isCollapseOpen ? (
                              <ArrowDropUp />
                            ) : (
                              <ArrowDropDown />
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ padding: 0 }}>
                          <TableCell
                            sx={{ padding: isCollapseOpen ? 3 : 0 }}
                            colSpan={6}
                          >
                            <Collapse
                              in={isCollapseOpen}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      {dispatchOrderLineItemHeader.map(
                                        (ele) => (
                                          <TableCell>{ele}</TableCell>
                                        ),
                                      )}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {item?.shipmentLinkList.length ? (
                                      item?.shipmentLinkList?.map(
                                        (shipment) => {
                                          return (
                                            <TableRow>
                                              <TableCell>
                                                {shipment?.shipmentNumber}
                                              </TableCell>
                                              <TableCell>
                                                {shipment?.releasedQuantity}
                                                {measurementUnit}
                                              </TableCell>
                                              <TableCell>
                                                {`${shipment?.shippedQuantity} `}
                                                {measurementUnit}
                                              </TableCell>
                                              <TableCell>
                                                {showPendingQtyLabelInDetailsModel(
                                                  shipment?.pendingQuantity,
                                                  measurementUnit,
                                                )}
                                                {/* {decimalRoundOff(
                                                releasedQuantity?.[index] -
                                                  shipment?.shippedQuantity,
                                              )}{" "}
                                              {/* {`${
                                                releasedQuantity?.[index] -
                                                shipment?.shippedQuantity
                                              } `} */}
                                                {/* {measurementUnit} */}
                                              </TableCell>
                                              <TableCell>
                                                {moneyFormat(
                                                  shipment?.shippedValue,
                                                )}
                                              </TableCell>
                                            </TableRow>
                                          );
                                        },
                                      )
                                    ) : (
                                      <TableRow>
                                        <TableCell
                                          colSpan={5}
                                          sx={{ textAlign: "center" }}
                                        >
                                          No data found
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })
                }
              />
            </Box>

            <Box display={"flex"} gap={4} justifyContent={"flex-end"}>
              <ButtonV1
                title={"Release"}
                size="small"
                onClick={() => markDispatchRecord(status.release)}
                disabled={
                  [status.release, status.rejected].includes(
                    selectedRecord?.transactionStatus?.toLowerCase(),
                  ) || isCreditBlocked || orderDetails?.customerFreezed || orderDetails?.isCreditBlocked
                }
              />
              <ButtonV1
                variant="outlined"
                title={"Reject"}
                size="small"
                disabled={[status.release, status.rejected].includes(
                  selectedRecord?.transactionStatus?.toLowerCase(),
                )}
                onClick={() => markDispatchRecord(status.cancel)}
              />
            </Box>
          </Box>
        </ModalBox>
        <RequestShortCloseModal
          data={doRequestItem}
          open={isRequestModalOpen}
          toggleModal={toggleShortCloseModal}
        />
      </>
    );
  },
);

export default DispatchOrderRecord;
