import { Box, Typography } from "@mui/material";

import { AmountWithHead } from "pages/FinancePayout/components";
import PriceBreakup from "./PriceBreakupManual";
import { SvgIcon } from "components";
import IconTooltip from "../../components/IconTooltip";

const PayoutBreakup = ({
  invoiceAmount,
  creditAmount,
  advanceAmount,
  gstAmount,
  icon,
  isPlantGST,
  isTcsTdsDetected,
}) => {
  return (
    <IconTooltip
      icon={
        !!icon ? (
          icon
        ) : (
          <Box height={16}>
            <SvgIcon name={"info"} width={16} height={16} />
          </Box>
        )
      }
    >
      <Box padding={2}>
        <AmountWithHead head={"Invoice amount"} amount={invoiceAmount} />
        <Box display={"flex"} gap={1} pt={2}>
          <Box>
            <Typography fontWeight={600} pt={2}>
              Advance details
            </Typography>
            <PriceBreakup
              value={advanceAmount}
              isPlantGST={isPlantGST}
              isTcsTdsDetected={isTcsTdsDetected}
              gstAmount={gstAmount}
              invoiceAmount={invoiceAmount}
            />
          </Box>
          <Box>
            <Typography fontWeight={600} pt={2}>
              Credit details
            </Typography>
            <PriceBreakup
              value={creditAmount}
              isPlantGST={isPlantGST}
              isTcsTdsDetected={isTcsTdsDetected}
              gstAmount={gstAmount}
              invoiceAmount={invoiceAmount}
            />
          </Box>
        </Box>
      </Box>
    </IconTooltip>
  );
};

export default PayoutBreakup;
