import { useEffect } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";

import HistoricalPayoutsTab from "./HistoricalPayoutsTab";
import PendingPayoutsTab from "./PendingPayoutsTab";
import UploadPayoutsTab from "./UploadPayoutsTab";
import ApprovalPayoutTab from "./ApprovalPayoutTab";

import { FinancePayouts } from "./constants";

import theme from "themeProvider";
import useFetchParam from "./useFetchParam";

const {
  queryFilters,
  tabs: { HISTORICAL, PENDING, UPLOAD, APPROVE },
  financeTab,
} = FinancePayouts;

const TabPanel = ({ children, value, selectedTab, tabName, ...rest }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== selectedTab}
      id={`tabpanel-${value}`}
      aria-labelledby={`tab-${value}`}
      {...rest}
      style={{ cursor: "pointer" }}
    >
      {value === selectedTab && <>{children}</>}
    </Box>
  );
};

const FinancePayout = () => {
  const { fetchParams, updateParams } = useFetchParam();

  const tabName = fetchParams?.[queryFilters.tab];
  const transactionType = fetchParams?.[queryFilters.transactionType];

  /**
   *
   * @param {*} newTabValue - tab name
   * @description - This is used to set updated tab in state
   * and also to update URL
   */
  const handleChange = (newTabValue) => {
    const param = {
      [queryFilters.tab]: newTabValue,
      [queryFilters.transactionType]: transactionType,
    };

    updateParams({ param, appendWithExisting: false });
  };

  /**
   * @description - append INVOICE_SELLER_PAYOUT (default) tab in url query params
   */
  useEffect(() => {
    if (
      !(
        fetchParams?.[queryFilters.tab] &&
        fetchParams?.[queryFilters.transactionType]
      )
    ) {
      updateParams({ appendWithExisting: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box px={8} py={2}>
      <Typography variant="h2">Ledger update utility</Typography>
      <Box sx={{ borderBottom: 1, borderColor: "lightgrey", mb: 4 }}>
        <Tabs
          value={tabName}
          onChange={(_, newTabValue) => {
            handleChange(newTabValue);
          }}
          sx={{
            ">.MuiTabs-scroller": {
              ">.MuiTabs-indicator": {
                backgroundColor: theme.palette.common.black,
              },

              ">.MuiTabs-flexContainer>.Mui-selected": {
                fontWeight: 600,
                color: theme.palette.common.black,
              },
              ">.MuiTabs-flexContainer>.MuiButtonBase-root": {
                paddingLeft: 0,
                paddingRight: 0,
                marginRight: 6,
              },
            },
          }}
          aria-label="Finance payout tabs"
        >
          <Tab label={HISTORICAL} value={financeTab.historical} />
          <Tab label={PENDING} value={financeTab.pending} />
          <Tab label={UPLOAD} value={financeTab.upload} />
          <Tab label={APPROVE} value={financeTab.approve} />
        </Tabs>
      </Box>
      <TabPanel value={financeTab.historical} selectedTab={tabName}>
        <HistoricalPayoutsTab />
      </TabPanel>
      <TabPanel value={financeTab.pending} selectedTab={tabName}>
        <PendingPayoutsTab />
      </TabPanel>
      <TabPanel value={financeTab.upload} selectedTab={tabName}>
        <UploadPayoutsTab />
      </TabPanel>
      <TabPanel value={financeTab.approve} selectedTab={tabName}>
        <ApprovalPayoutTab />
      </TabPanel>
    </Box>
  );
};

export default FinancePayout;
