import { memo } from "react";
import { Typography, Box, Divider } from "@mui/material";
import { styled } from "@mui/system";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";

import theme from "themeProvider";

const FlexJustify = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
});

const FlexGap = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  color: theme.palette.text.primary,
});

const PaymentSummary = ({ data }) => {
  const warningBorder =
    data["Pending amount"]?.split("")[1] !== "0" ? true : false;

  return (
    <Box
      p={4}
      mb={6}
      borderRadius={2}
      style={{
        border: warningBorder ? `1px solid ${theme.palette.error.dark}` : "",
        boxShadow: `${theme.palette.grey["200"]} 0px 0px 4px 2px`,
      }}
    >
      <FlexJustify mb={2}>
        <Typography variant="h3" m={0}>
          Order summary
        </Typography>
        <ReceiptLongOutlinedIcon sx={{ fontSize: 34 }} />
      </FlexJustify>
      <Divider sx={{ borderBottomWidth: 2, marginBottom: 2 }} />
      <FlexGap>
        {Object.keys(data).map((item) => (
          <FlexJustify key={item}>
            <Typography>{item}</Typography>
            <Typography>{data[item]}</Typography>
          </FlexJustify>
        ))}
      </FlexGap>
    </Box>
  );
};

export default memo(PaymentSummary);
