import { IncomingPayment } from "../constants";

const { PAYMENT_ACTION } = IncomingPayment;
export const getTagApiPayload = (data, buyerAccount) => {
  try {
    const {
      transactionReference,
      whenCreated,
      money,
      notes: {
        paymentMethod,
        status,
        bankAccountName,
        bankAccountNumber,
        bankName,
        ifscCode,
        description,
        channelFinanceFlag,
        channelFinanceId,
      },
    } = data;
    const {
      email,
      companyName,
      gstin,
      ctId,
      name: { en: buyerName },
      mobileNumber: { number: phoneNumber },
    } = buyerAccount;

    return {
      transactionReference,
      buyerEmail: email,
      buyerCompanyName: companyName,
      buyerName,
      gstIn: gstin,
      paymentMethod,
      status,
      bankAccountName,
      bankAccountNumber,
      bankName,
      ifscCode,
      ctId,
      phoneNumber,
      originalWhenUpdated: whenCreated,
      paymentAction: PAYMENT_ACTION.VERIFIED,
      description,
      channelFinanceFlag,
      channelFinanceId,
      amount: money,
    };
  } catch (error) {
    console.log(error, "Tag api payload failed");
    return {};
  }
};
