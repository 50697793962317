import { useCallback } from "react";

import { error, success } from "constants";
import { useToaster } from "hooks";
import { getCall } from "services";

const downloadUrl = "/oms/shipment/documents";

const useDownloadInvoiceFile = () => {
  const triggerToaster = useToaster();

  const downloadFile = useCallback((res, fileName) => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(res);
    link.download = fileName;
    link.click();
  }, []);

  const triggerDownloadInvoice = useCallback(
    async (file, fileType, shipmentId, invoiceNumber) => {
      const params = {
        shipmentId,
        fileType,
        fileId: file?.generatedId,
        invoiceNumber,
      };

      const res = await getCall(
        downloadUrl,
        params,
        (err) =>
          triggerToaster(
            err?.detail || `Downloading ${invoiceNumber} invoice`,
            error,
          ),
        "blob",
      );

      if (res) {
        downloadFile(res, file.originalName);
        triggerToaster(`Downloading ${invoiceNumber} invoice`, success);
      }
    },
    [triggerToaster],
  );

  return { triggerDownloadInvoice };
};

export default useDownloadInvoiceFile;
