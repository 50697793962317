import { useState, useEffect, useMemo } from "react";
import { Box, Fab } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useLocation, useSearchParams } from "react-router-dom";

import Summary from "./Summary";
import Tabulation from "./Tabulation";
import Filter from "./Filter";
import { getCall } from "services";
import { useFetchData } from "hooks/useFetchData";
import { createFilterObj, generateFilterQuery } from "./dashboardUtils";
import { useSelector, useDispatch } from "react-redux";
import { setDashboardPathParams } from "dataStore/dashboardPathParams";

const Dashboard = () => {
  const businessList = useSelector((state) => state.userData.businessUnits);
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState();
  const [selectedFilters, setSelectedFilters] = useState({});

  const allowedBU = useMemo(() => {
    if (Array.isArray(businessList)) {
      return businessList?.map(({ name }) => name).join(",");
    } else {
      return "";
    }
  }, [businessList]);

  const onSuccess = (res) => {
    setTotalCount(res?.data?.totalCount);
    setIsLoading(false);
    if (page !== 0) {
      setTableData((prev) => [...prev, ...res?.data?.response]);
    } else {
      setTableData(res?.data?.response);
    }
  };

  const { refetch: getOrderList } = useFetchData(
    "order-list-dashboard",
    `/oms/order/list?offset=${page * 50}&limit=50${
      selectedFilters ? "&" + generateFilterQuery(selectedFilters) : ""
    }&businessUnit=${allowedBU || ""}`,
    onSuccess,
    () => setIsLoading(false),
  );

  useEffect(() => {
    if (selectedFilters && Object.keys(selectedFilters)?.length) {
      setIsLoading(true);
      onFilterChange(selectedFilters);
    }
    if (!search) {
      setIsLoading(true);
      getOrderList();
    }
    dispatch(setDashboardPathParams(searchParams.toString()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, selectedFilters]);

  const handlePageCount = () => {
    if (page < 1) {
      setPage((prev) => prev + 1);
    } else {
      setPage(0);
    }
  };

  useEffect(() => {
    if (search) {
      const decodedFilters = createFilterObj(search);
      setSelectedFilters(decodedFilters);
    }
  }, [search]);

  const onFilterChange = async (filterValue) => {
    const { companyName, orderState, opsSpoc } = filterValue;
    if (companyName || orderState || opsSpoc) {
      const query = generateFilterQuery(filterValue);
      const result = await getCall(
        `/oms/order/list/search?offset=${page * 50}&limit=50&businessUnit=${
          allowedBU || ""
        }&${query}`,
      );
      setTotalCount(result?.totalCount);
      setIsLoading(false);
      if (page) {
        setTableData((prev) => [...prev, ...result?.response]);
      } else {
        setTableData(result?.response);
      }
    } else {
      getOrderList();
    }
  };

  const resetFilters = () => setSelectedFilters();
  return (
    <Box ml={8} mr={8}>
      <Box mb={4}>
        <Filter resetFilters={resetFilters} />
      </Box>

      <Box mb={4}>
        <Summary allowedBU={allowedBU} />
      </Box>

      <Box position={"relative"}>
        {totalCount > 50 && (
          <Fab
            color="primary"
            size="small"
            sx={{ position: "absolute", right: 10, bottom: 10 }}
            onClick={handlePageCount}
          >
            {page < 1 ? (
              <KeyboardArrowDown sx={{ width: 35, height: 35 }} />
            ) : (
              <KeyboardArrowUp sx={{ width: 35, height: 35 }} />
            )}
          </Fab>
        )}
        <Tabulation isLoading={isLoading} table={tableData} />
      </Box>
    </Box>
  );
};

export default Dashboard;
