import { Box } from "@mui/material";

/**
 *
 * @description Stack items in flex column format
 */
const ColumnStack = ({ children }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={2} width={"70%"}>
      {children}
    </Box>
  );
};

export default ColumnStack;
