import { createSlice } from "@reduxjs/toolkit";

export const dashboardPathParams = createSlice({
  name: "dashboardPathParams",
  initialState: "",
  reducers: {
    setDashboardPathParams: (state, action) => {
      return action.payload;
    },
  },
});

export const { setDashboardPathParams } = dashboardPathParams.actions;
export default dashboardPathParams.reducer;
