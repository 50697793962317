import * as Yup from "yup";
import { FORM_ADD_USER } from "../AddUser/constant";

const createUserSchema = Yup.object().shape({
  [FORM_ADD_USER.EMAIL]: Yup.string()
    .email("Please enter correct email address")
    .required("Email is required"),
  [FORM_ADD_USER.NAME]: Yup.string().required("Name is required"),
  [FORM_ADD_USER.MOBILE]: Yup.number()
    .required("Please enter your mobile number")
    .min(1000000000, "Invalid number. Eg:91xxxxxx81")
    .max(9999999999, "Invalid number. Eg:91xxxxxx81"),
});

export { createUserSchema };
