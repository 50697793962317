import { useMemo } from "react";
import { Typography, Chip, useTheme, Grid } from "@mui/material";

import { FinancePayouts } from "../constants";
import useFetchParam from "../useFetchParam";

const {
  transactionFilter: {
    CREDIT_NOTE_SALES_RETURN,
    INVOICE_SELLER_PAYOUTS,
    REFUND_CASHBACK,
    INTERNAL_FUND_TRANSFER,
    ORDER_BLOCK,
  },
  financeTab: { approve: APPROVED },
  queryFilters,
} = FinancePayouts;

const TransactionFilter = () => {
  const { fetchParams, updateParams } = useFetchParam();

  const { [queryFilters.tab]: tab, [queryFilters.transactionType]: type } =
    fetchParams;

  const theme = useTheme();

  const selectedChip = useMemo(
    () => FinancePayouts?.transactionFilter[type],
    [type],
  );

  /**
   * @description - reverse filter key/value
   */
  const transactionFilterKey = useMemo(() => {
    return Object.fromEntries(
      Object.entries(FinancePayouts.transactionFilter).map(([key, value]) => [
        value,
        key,
      ]),
    );
  }, []);

  const transactionTab = useMemo(() => {
    let allowedTab = [];

    switch (tab) {
      case APPROVED:
        allowedTab = [INVOICE_SELLER_PAYOUTS, REFUND_CASHBACK];
        break;
      default:
        allowedTab = Object.values(FinancePayouts.transactionFilter);
        break;
    }

    return Object.values(FinancePayouts.transactionFilter).filter((tab) =>
      allowedTab.includes(tab),
    );
  }, [tab]);

  const handleClick = (data) => {
    const type = data.target.innerText;
    const param = {
      [queryFilters.transactionType]: transactionFilterKey[type],
      [queryFilters.tab]: tab,
    };
    updateParams({ param, appendWithExisting: false });
  };

  return (
    <>
      <Typography variant="h5" mb={4}>
        Select transaction type
      </Typography>
      <Grid container>
        {transactionTab.map((label) => {
          return (
            <Grid item xs={2.4}>
              <Chip
                key={label}
                disabled={
                  ![
                    INVOICE_SELLER_PAYOUTS,
                    REFUND_CASHBACK,
                    INTERNAL_FUND_TRANSFER,
                    CREDIT_NOTE_SALES_RETURN,
                    ORDER_BLOCK,
                  ].includes(label)
                }
                label={label}
                style={{
                  color:
                    label === selectedChip
                      ? theme.palette.grey["A800"]
                      : theme.palette.info.blueNavyColor,
                  fontWeight: label === selectedChip ? 600 : "normal",
                  border: 0,
                  borderBottom:
                    label === selectedChip &&
                    `2px solid ${theme.palette.info.blueBorderColor}`,
                  borderRadius: 0,
                  fontSize: 14,
                  backgroundColor:
                    label === selectedChip
                      ? theme.palette.info.lightBlueBackround
                      : theme.palette.common.white,
                }}
                onClick={handleClick}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default TransactionFilter;
