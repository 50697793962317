import { ORDER_BLOCK } from "../constant";

const { manualUpload } = ORDER_BLOCK;

const ERROR_MSG = Object.freeze({
  [manualUpload.orderId]: "Enter the Order ID",
  [manualUpload.entityGstin]: "Enter a valid GSTIN",
  [manualUpload.advanceAmount]: "Enter a valid advance amount",
  [manualUpload.orderAmount]: "Enter a valid order amount",
  [manualUpload.creditAmount]: "Enter a valid credit amount",
  [manualUpload.creditInstrument]: "Choose a credit instrument",
});

export default ERROR_MSG;
