import { useEffect, useMemo, useState } from "react";

import InternalFundTable from "./InternalFundTable";

import { useFetchData } from "hooks";

const baseUrl = "/oms/off-oms-internal-transfer/list";
const limit = 50;

const InternalFundTransfer = ({ transactionType, filters }) => {
  const [page, setPage] = useState(0);

  const pendingInternalParams = useMemo(() => {
    const { entityName, orderNumber } = filters;
    return {
      limit,
      transactionType,
      filterValue: "PAYMENT_SUCCESS",
      entityName,
      orderNumber,
    };
  }, [transactionType, filters]);

  const {
    refetch: getHistoricalPayouts,
    data: historicalPayouts,
    isFetching,
  } = useFetchData("historical-internal-fund-transfer", baseUrl, null, null, {
    ...pendingInternalParams,
    offset: page * limit,
  });

  useEffect(() => {
    if (page) {
      setPage(0);
    } else {
      getHistoricalPayouts();
    }
  }, [pendingInternalParams]);

  useEffect(() => {
    getHistoricalPayouts();
  }, [page]);

  /**
   * @description Handle pagination
   */
  const handlePageChange = (_event, pageNumber) => {
    setPage(pageNumber);
  };

  return (
    <InternalFundTable
      transactionType={transactionType}
      payoutData={historicalPayouts?.data ?? {}}
      isLoading={isFetching}
      page={page}
      onPageChange={handlePageChange}
      filters={pendingInternalParams}
    />
  );
};

export default InternalFundTransfer;
