import { styled } from "@mui/system";
import {
  Typography,
  Box,
  Divider,
  TableRow,
  TableCell,
  tableCellClasses,
} from "@mui/material";

const SummaryModal = styled(Box)(({ theme }) => ({
  boxShadow: `0 0 4px 2px ${theme.palette.grey[200]}`,
  borderRadius: theme.shape.borderRadius,
  position: "relative",
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  maxHeight: "130px",
}));

const FlexBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(4),
  zIndex: 1,
}));

const TextHead = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 600,
  fontSize: 22,
}));

const SubText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 600,
  paddingLeft: theme.spacing(3),
  paddingTop: theme.spacing(1),
}));
const LineSeperator = styled(Divider)({
  borderRightWidth: 2.5,
  borderRightStyle: "solid",
});

const OpenOrderBox = styled(SummaryModal)(({ theme }) => ({
  padding: theme.spacing(4),
  minHeight: "100%",
  maxHeight: "unset",
}));

const SearchBarBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  paddingBottom: theme.spacing(4),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e9f0ff",
    color: theme.palette.text.primary,
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#f8fafb",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:hover": {
    backgroundColor: theme.palette.grey["A100"],
    cursor: "pointer",
  },
}));

export {
  LineSeperator,
  SubText,
  TextHead,
  FlexBox,
  SummaryModal,
  OpenOrderBox,
  SearchBarBox,
  StyledTableRow,
  StyledTableCell,
};
