import { memo } from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import theme from "themeProvider";

const MultiSellerPaymentSummary = ({ title, orderSummary }) => {
  const multiSellerSummary = orderSummary?.multiSellerSummary;
  const totalOrderInfo = orderSummary?.finalOrderSummary;

  const orderInfo = useSelector((state) => state.orderDetails);

  return (
    <Box
      p={4}
      borderRadius={2}
      boxShadow={`${theme.palette.grey["200"]} 0px 0px 4px 2px`}
    >
      <Box
        sx={{ borderBottom: 3, borderColor: theme.palette.grey["A100"] }}
        mb={2}
      >
        <Typography variant="h4">{title}</Typography>
      </Box>
      <>
        {Object.keys(multiSellerSummary).map((sellerKey) => {
          const sellerName =
            orderInfo?.sellerDetails?.[sellerKey]?.displayCompanyName;
          return (
            <Box key={sellerKey}>
              <Typography variant="h4">{sellerName}</Typography>
              <Box
                sx={{
                  borderBottom: 3,
                  borderColor: theme.palette.grey["A100"],
                  borderBottomStyle: "dashed",
                }}
                mb={2}
              >
                {Object.keys(multiSellerSummary[sellerKey]).map((item, ind) => {
                  return (
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      pb={2}
                      key={`${item}-${ind}`}
                    >
                      <Typography>{item}</Typography>
                      <Typography>
                        {multiSellerSummary[sellerKey][item]}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          );
        })}
      </>
      <Box mb={2}>
        <Typography variant="h4">{"Total"}</Typography>
        {Object.keys(totalOrderInfo).map((item, ind) => {
          return (
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              pb={2}
              key={`${item}-${ind}`}
            >
              <Typography>{item}</Typography>
              <Typography>{totalOrderInfo[item]}</Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

MultiSellerPaymentSummary.propTypes = {
  title: PropTypes.string.isRequired,
  orderSummary: PropTypes.object.isRequired,
};

export default memo(MultiSellerPaymentSummary);
