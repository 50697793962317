import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";

import theme from "themeProvider";
import { TextField } from "components";
import { useDebounce } from "hooks";

const SearchBar = ({
  placeholder = "Search here",
  onClear,
  inputStyle = {},
  onInputSearch,
}) => {
  const [inputValue, setInputValue] = useState("");
  const search = useDebounce(inputValue, 1000);

  useEffect(() => {
    onInputSearch(search);
  }, [search]);

  const handleChange = useCallback((e) => {
    setInputValue(e.target.value);
  }, []);

  const handleClearInput = useCallback(() => {
    setInputValue("");
    onClear();
  }, []);

  return (
    <Box>
      <TextField
        sx={{ position: "relative", width: "100%" }}
        id="search-bar"
        InputProps={{
          style: inputStyle,
          startAdornment: (
            <SearchIcon
              fontSize="small"
              className={inputValue && "text-added"}
              sx={{
                "&.MuiSvgIcon-root": {
                  color: theme.palette.text.disabled,
                  marginRight: 1,
                  "&.text-added": {
                    color: theme.palette.text.primary,
                  },
                },
              }}
            />
          ),
          endAdornment: !!onClear && !!inputValue && (
            <ClearRoundedIcon
              sx={{ cursor: "pointer" }}
              fontSize="small"
              onClick={handleClearInput}
            />
          ),
        }}
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder}
        size="small"
        variant="outlined"
      />
    </Box>
  );
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onInputSearch: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  inputStyle: PropTypes.object,
};
export default SearchBar;
