import { useState, useCallback, useEffect, useRef, useMemo } from "react";
import { Box, Typography } from "@mui/material";

import { ButtonV1, ExportCta, ModalBox } from "components";
import CreditNotesTable from "./CreditNotesTable";
import { QuickFilter, PayoutDisbursement } from "../components";

import { useFetchData, useToaster } from "hooks";
import { getCall_v2, postCall_v2 } from "services";
import { downloadCsv } from "utils";
import { FinancePayouts } from "../../constants";
import { success, error } from "constants";

const initialQuickFilter = "all";

const { quick_filter } = FinancePayouts;
const rowsPerPage = 50;

const endUrl = `oms/off-oms-payout/creditSales-payout-summaries`;

const PendingPayouts = ({ transactionType, filters }) => {
  const [selectedQuickFilter, setSelectedQuickFilter] =
    useState(initialQuickFilter);
  const [page, setPage] = useState(0);
  const [filterCount, setFilterCount] = useState({});
  const [selectedPayout, setSelectedPayout] = useState([]);
  const [isDisbursementOpen, setDisbursementOpen] = useState(false);
  const [disbursementData, setDisbursementData] = useState([]);
  const showToaster = useToaster();

  const payoutTableRef = useRef(null);

  const pendingCreditParam = useMemo(
    () => ({
      ...filters,
      limit: rowsPerPage,

      filterType: selectedQuickFilter,
    }),
    [selectedQuickFilter, filters],
  );

  const addTotalCount = (res) => {
    let filterCountRef = { ...filterCount };
    filterCountRef[selectedQuickFilter] = res?.data?.totalCount;
    setFilterCount(filterCountRef);
  };

  const {
    refetch: getPendingPayouts,
    data: pendingTransactions,
    isFetching,
  } = useFetchData(
    ["pending-credit-payouts", pendingCreditParam],
    endUrl,
    addTotalCount,
    null,
    { ...pendingCreditParam, offset: page * rowsPerPage },
  );

  /**
   * @description set filters on click of quick filters
   */
  const handleQuickFilter = (value) => {
    setSelectedQuickFilter(value);
  };

  /**
   * @description fetch pending payouts during page load
   */
  useEffect(() => {
    if (page) {
      setPage(0);
    } else {
      getPendingPayouts();
    }
  }, [pendingCreditParam]);

  useEffect(() => {
    getPendingPayouts();
  }, [page]);

  /**
   * @description Handle pagination
   */
  const handlePageChange = useCallback((_, pageNumber) => {
    setPage(pageNumber);
    resetSelectItems();
  }, []);

  const makeDisbursementData = () => {
    const data = selectedPayout.map((ele) => ({
      orderType: ele?.orderType,
      orderId: ele?.customerOrdNumber,
      payoutEntity: ele?.entityName,
      documentAmount: ele?.documentAmount,
      documentId: ele?.documentId,
      creditMetaInfo: { type: "credit notes" },
    }));
    setDisbursementData(data);
  };

  const getSelectedSummaryData = (data) => {
    setSelectedPayout(data);
  };

  const onClosePayoutDisbursement = () => {
    setDisbursementOpen(false);
  };
  const openPayoutModal = () => {
    makeDisbursementData();
    setDisbursementOpen(true);
  };

  const onInitiatePayout = async () => {
    if (selectedPayout.length) {
      let selectedIds = selectedPayout.map((ele) => ele.payoutId);
      try {
        await postCall_v2("oms/payout/initiate-credit-sales-return", {
          ids: selectedIds,
        });
        getPendingPayouts();
        showToaster(`Payout initiated`, success);
        resetSelectItems();
        setDisbursementOpen(false);
      } catch (err) {
        showToaster(`An error occured, please try again.`, error);
      }
    }
  };

  const onExportCSV = async () => {
    try {
      const payoutUrl = `oms/off-oms-payout/download/creditSales-payout-summaries`;
      const { data } = await getCall_v2(payoutUrl, {
        ...pendingCreditParam,
        limit: null,
      });
      showToaster(`Downloading file`, success);
      downloadCsv(data, "credit_note_payouts.csv");
    } catch (err) {
      console.error(`Error in download, ${err}`);
      showToaster(`Please try again later`, error);
    }
  };

  /**
   * @description reset selected items and parent checkbox
   */
  const resetSelectItems = useCallback(() => {
    payoutTableRef?.current?.resetParentCheckBox();
    setSelectedPayout([]);
  }, []);

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={4}
      >
        <Box>
          <Typography fontSize={20} fontWeight={600} mb={2}>
            Pending payouts
          </Typography>
          <Box display={"flex"}>
            {Object.keys(quick_filter).map((elem, ind) => (
              <QuickFilter
                key={ind}
                label={quick_filter[elem]}
                value={elem}
                onClick={handleQuickFilter}
                selectedFilter={selectedQuickFilter}
                filterCount={filterCount}
              />
            ))}
          </Box>
        </Box>
        <Box sx={{ display: "flex" }} alignItems={"flex-end"}>
          <Box sx={{ marginRight: 4, marginTop: 10, marginBottom: -1 }}>
            <ExportCta onExport={onExportCSV} />
          </Box>
          <ButtonV1
            title={"Initiate"}
            size={"small"}
            disabled={!selectedPayout?.length}
            onClick={openPayoutModal}
          />
        </Box>
      </Box>
      <ModalBox
        open={!!isDisbursementOpen}
        onCloseModal={onClosePayoutDisbursement}
        width="50%"
      >
        <>
          <PayoutDisbursement data={disbursementData} isCredit={true} />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <ButtonV1
              variant="contained"
              color="secondary"
              title="Confirm"
              size="medium"
              onClick={onInitiatePayout}
            />
          </Box>
        </>
      </ModalBox>
      <CreditNotesTable
        ref={payoutTableRef}
        pendingTransactions={pendingTransactions?.data}
        rowsPerPage={rowsPerPage}
        pageNumber={page}
        loading={isFetching}
        onPageChange={handlePageChange}
        passSelectedData={getSelectedSummaryData}
        getPendingPayouts={getPendingPayouts}
        selectedPayout={selectedPayout}
      />
    </>
  );
};

export default PendingPayouts;
