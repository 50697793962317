import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import { moneyFormat, validateNull, getSubstring } from "utils";
import { error, success, STATUS_CHIP } from "constants";
import {
  ButtonV1,
  Loader,
  ModalBox,
  PaginationAction,
  StatusChip,
  Timestamp,
} from "components";
import { postCall_v2, getCall_v2 } from "services";
import { useFetchData, useToaster, useDownload } from "hooks";

import ModalInfo from "./ModalInfo";
import CreditDetailTable from "./CreditDetailTable";
import HoverBlock from "../components/HoverBlock";
import CREDIT_UPDATE from "../constants";

const rowsPerPage = 20;

const CreditApprovalTable = ({ filter }) => {
  const userName = useSelector((state) => state.userData.name);
  const [page, setPage] = useState(0);
  const [camId, setCamId] = useState([]);
  const [sanction, setSanction] = useState(null);
  const [message, setMessage] = useState();

  const triggerToaster = useToaster();
  const { downloadFile } = useDownload();

  const filterParams = useMemo(
    () => (filter ? new URLSearchParams(filter).toString() : ""),
    [filter],
  );

  const url = useMemo(() => {
    return `oms/credit/getCreditUpdateRequestList?offset=${
      page * rowsPerPage
    }&limit=${rowsPerPage}&${filterParams}`;
  }, [page, filterParams]);

  const {
    data,
    isFetching,
    refetch: fetchPendingCreditList,
  } = useFetchData("credit-pending", url);

  const handlePageChange = useCallback(
    (_event, pageNumber) => setPage(pageNumber),
    [],
  );

  const handleCreditAction = useCallback(
    (sanction) => setSanction(sanction),
    [],
  );

  const handleCloseModal = useCallback(() => {
    setSanction(null);
    setMessage(null);
  }, []);

  const handleSanctionCall = useCallback(
    async (requesterComments) => {
      try {
        const isApprove = sanction?.type === CREDIT_UPDATE.actionType.approve;
        const isInsertCall =
          sanction?.order?.requestType === CREDIT_UPDATE.apiRequestType.insert;

        const url = isInsertCall
          ? "/oms/credit/statusUpdate/createCredit/"
          : "/oms/credit/statusUpdate/sanctionAndUtilisedLimit/";

        const payload = {
          status: isApprove,
          id: sanction?.order.updateId,
          message: `${requesterComments}@#%Approver comments- ${message}`,
          ...(isInsertCall
            ? {
                creditType: sanction?.order?.creditType,
                loanAccountNumber: "",
                approvedBy: userName,
              }
            : { updatedBy: userName }),
        };

        const data = await postCall_v2(url, payload);
        triggerToaster(
          data?.data?.message,
          data?.data?.successful ? success : error,
        );
        fetchPendingCreditList();
        handleCloseModal();
      } catch (err) {
        triggerToaster("Something went wrong", error);
      }
    },
    [sanction, userName, message, fetchPendingCreditList],
  );

  const handleMessage = (event) => setMessage(event.target.value);

  useEffect(() => {
    fetchPendingCreditList();
  }, [url, fetchPendingCreditList]);

  /**
   *
   * @param {*} comments
   * @description this function splits the comment using a given sequence
   * @returns  object of splitted comments with respective keys
   */
  const getFormattedComments = (comment) => {
    let splittedComment = comment.split("@#%");
    const requesterComments =
      splittedComment?.[0]?.replace("Requester comments -", "") ?? "-";
    const approverComments =
      splittedComment?.[1]?.replace("Approver comments-", "") ?? "-";
    let formattedComment = {
      "Requester comments": requesterComments,
      "Approver comments": approverComments,
    };
    return formattedComment;
  };

  const handleDocDownload = useCallback(async (filePath) => {
    const fileName = "CAM document";
    try {
      const { data } = await getCall_v2(
        `/oms/credit/documents/customerCreditDocs`,
        { filePath: filePath },
        "blob",
      );
      downloadFile(data, fileName);
      triggerToaster(`Downloading ${fileName}`, success);
    } catch (err) {
      triggerToaster(`Unable to download ${fileName}`, error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreditDetail = useCallback((camId) => {
    setCamId((prev) => {
      if (prev?.includes(camId)) {
        return prev.filter((id) => id !== camId);
      } else {
        return [...prev, camId];
      }
    });
  }, []);

  return (
    <>
      <ModalBox open={!!sanction} onCloseModal={handleCloseModal}>
        <ModalInfo
          order={sanction}
          approver={userName}
          onClick={handleSanctionCall}
          onCancel={handleCloseModal}
          onMessage={handleMessage}
        />
      </ModalBox>
      <Paper>
        <TableContainer
          sx={{
            minWidth: 700,
            maxHeight: "calc(100vh - 120px)",
          }}
        >
          <Table stickyHeader>
            <TableHead style={{ borderRadius: "10px 10px 0 0" }}>
              <TableRow>
                {Object.values(CREDIT_UPDATE.tableHeader).map((heading) => (
                  <TableCell key={heading}>{heading}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                <TableRow>
                  <TableCell
                    colSpan={Object.values(CREDIT_UPDATE.tableHeader).length}
                  >
                    <Loader sx={{ margin: 0 }} />
                  </TableCell>
                </TableRow>
              ) : !!data?.data.response?.length ? (
                data?.data.response.map((order) => {
                  const isApprovalPending =
                    order.status === CREDIT_UPDATE.orderInReview;
                  const buttonText = !camId.includes(order?.camId) ? (
                    <Box display={"flex"} alignItems={"center"}>
                      {`+${order?.requestList?.length} existing`}
                      <KeyboardArrowDown sx={{ width: 20, height: 20 }} />
                    </Box>
                  ) : (
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                    >
                      Hide
                      <KeyboardArrowUp sx={{ width: 20, height: 20 }} />
                    </Box>
                  );
                  return (
                    <>
                      <TableRow key={order.updateId}>
                        <TableCell>
                          {validateNull(order?.customerName)}/<br />
                          {validateNull(order?.customerPan)}/ <br />
                          {validateNull(order?.customerGstin)}
                        </TableCell>
                        <TableCell>{validateNull(order?.camId)}</TableCell>
                        <TableCell>
                          {validateNull(order?.creditType)}-
                          {validateNull(order?.creditInstrument)}
                          <br />
                          {CREDIT_UPDATE.orderInReview === order.status &&
                            !!order?.requestList?.length && (
                              <ButtonV1
                                variant="text"
                                size="small"
                                title={buttonText}
                                onClick={() => handleCreditDetail(order?.camId)}
                              />
                            )}
                        </TableCell>
                        <TableCell>
                          <Box>
                            <ButtonV1
                              boxStyle={{ justifyContent: "flex-start" }}
                              variant="text"
                              title={getSubstring(order.fileName, 20)}
                              size="small"
                              onClick={() => handleDocDownload(order?.path)}
                              style={{
                                padding: 0,
                                minWidth: "fit-content",
                                fontSize: 14,
                              }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell>
                          {moneyFormat(order?.sanctionedLimit)}
                        </TableCell>
                        <TableCell>
                          {moneyFormat(order?.utilisedLimit)}
                        </TableCell>
                        <TableCell>
                          <Timestamp timeStamp={order?.expiryDate} />
                        </TableCell>
                        <TableCell>
                          {validateNull(order?.requestedBy)}
                        </TableCell>
                        <TableCell>
                          <Box style={{ textAlign: "center" }}>
                            {order?.comments ? (
                              <HoverBlock
                                LabelChildren={
                                  <IconButton>
                                    <object
                                      data={`/assets/chat-unread.png`}
                                      width={24}
                                      alt="Group icon"
                                      aria-label="group icon"
                                    />
                                  </IconButton>
                                }
                                data={getFormattedComments(order?.comments)}
                              />
                            ) : (
                              <IconButton>
                                <object
                                  data={`/assets/chat-read.png`}
                                  width={24}
                                  alt="Group icon"
                                  aria-label="group icon"
                                />
                              </IconButton>
                            )}
                          </Box>
                        </TableCell>

                        <TableCell>
                          {isApprovalPending ? (
                            <Box
                              display={"flex"}
                              gap={2}
                              justifyContent={"center"}
                            >
                              <ButtonV1
                                title={"Approve"}
                                size="small"
                                onClick={() =>
                                  handleCreditAction({
                                    order,
                                    type: CREDIT_UPDATE.actionType.approve,
                                  })
                                }
                              />
                              <Divider flexItem orientation="vertical" />
                              <ButtonV1
                                title={"Reject"}
                                size="small"
                                variant="text"
                                onClick={() =>
                                  handleCreditAction({
                                    order,
                                    type: CREDIT_UPDATE.actionType.reject,
                                  })
                                }
                              />
                            </Box>
                          ) : (
                            <Box display={"flex"} justifyContent={"flex-start"}>
                              <StatusChip
                                label={CREDIT_UPDATE.orderStatus[order.status]}
                                type={
                                  CREDIT_UPDATE.orderApproved === order.status
                                    ? STATUS_CHIP.success
                                    : STATUS_CHIP.error
                                }
                              />
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                      {!!camId.includes(order?.camId) && (
                        <CreditDetailTable order={order} />
                      )}
                    </>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={Object.values(CREDIT_UPDATE.tableHeader).length}
                  >
                    <Typography textAlign={"center"}>No data found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!!data?.data.response?.length && (
          <TablePagination
            rowsPerPageOptions={[rowsPerPage]}
            rowsPerPage={rowsPerPage}
            page={Math.ceil(data?.data.offset / rowsPerPage)}
            count={data?.data.totalCount ?? 0}
            onPageChange={handlePageChange}
            sx={{ display: "flex", flexDirection: "column-reverse" }}
            ActionsComponent={PaginationAction}
          />
        )}
      </Paper>
    </>
  );
};

export default CreditApprovalTable;
