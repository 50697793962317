import { useState } from "react";
import { Typography, Box, useTheme } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";

import { DialogBox } from "components";
import ButtonView from "../components/ButtonView";
import RefundCashbackForm from "./RefundCashbackForm";

import { FinancePayouts } from "../../constants";
import { RefundAndCashbackPayout } from "./constants";

const { msgStatements } = FinancePayouts;
const { CLEAR_ONE_MSG, CLEAR_ALL_MSG } = msgStatements;

const { REFUND_FORM } = RefundAndCashbackPayout;

const ReviewTransactions = ({
  invalidCsvList,
  retryPayoutSubmission,
  passInvalidCsvList,
  fileRef,
}) => {
  const [isResetTxConfirmationOpen, setResetTxConfirmation] = useState(false);
  const [singleTxDeletionId, setSingleTxDeletionId] = useState(null);
  const theme = useTheme();

  const methods = useForm({
    mode: "onChange",
  });
  const { handleSubmit, getValues, resetField } = methods;

  /**
   * @description toggle confirmation modal
   */
  const resetConfirmation = () => {
    if (!isResetTxConfirmationOpen && singleTxDeletionId) {
      setSingleTxDeletionId(null);
    }
    setResetTxConfirmation(!isResetTxConfirmationOpen);
  };

  /**
   * @description get confirmation to remove a single tx
   * @param {*} txData
   */
  const toConfirmSingleTxRemoval = (id) => {
    setSingleTxDeletionId(id);
    setResetTxConfirmation(true);
  };

  /**
   * @description - clear all invalid records
   */
  const removeRecord = () => {
    clearTransactions(singleTxDeletionId);
    setResetTxConfirmation(false);
    setSingleTxDeletionId(null);
  };

  const handleSave = () => {
    retryPayoutSubmission(getValues()?.[REFUND_FORM]);
  };

  /**
   * @description - clears invalid transactions
   */
  const clearTransactions = (listId = null) => {
    if (listId) {
      //fetch single tx & remove
      let listRef = [...invalidCsvList];
      if (singleTxDeletionId !== -1) {
        listRef.splice(singleTxDeletionId, 1);
      }
      passInvalidCsvList(listRef);
      resetField(`${REFUND_FORM}.${singleTxDeletionId}`);
    } else {
      //clear all tx
      passInvalidCsvList([]);
      fileRef.current.resetFile();
    }
  };

  return (
    <>
      <Typography fontSize={16} fontWeight={"bold"} mb={4}>
        Review transactions
      </Typography>
      <FormProvider {...methods}>
        <Box as="form" onSubmit={handleSubmit(handleSave)}>
          {invalidCsvList.map((item, index) => {
            return (
              <Box
                key={item.txData.id}
                sx={{
                  padding: 4,
                  border: `1px solid ${theme.palette.grey["400"]}`,
                  borderRadius: 2,
                  marginTop: 4,
                }}
              >
                <RefundCashbackForm
                  transaction={item?.txData}
                  id={index}
                  toConfirmSingleTxRemoval={(id) =>
                    toConfirmSingleTxRemoval(id)
                  }
                  txErrors={item.errors}
                  isReviewTransaction={true}
                />
              </Box>
            );
          })}
          <ButtonView onClearAll={resetConfirmation} />
        </Box>
      </FormProvider>
      <DialogBox
        handleClose={() => resetConfirmation(false)}
        open={isResetTxConfirmationOpen}
        handleConfirm={removeRecord}
        message={
          singleTxDeletionId
            ? `${CLEAR_ONE_MSG} ${singleTxDeletionId} ?`
            : CLEAR_ALL_MSG
        }
      />
    </>
  );
};

export default ReviewTransactions;
