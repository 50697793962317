import {
  useImperativeHandle,
  useState,
  forwardRef,
  useCallback,
  useEffect,
} from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TablePagination,
  Typography,
  TextField,
  IconButton,
  Paper,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

import {
  PaginationAction,
  ModalBox,
  Loader,
  SvgIcon,
  ButtonV1,
  TableRowWithError,
} from "components";
import { StatusChip, HoverBlock } from "../components";

import { deleteCall, postCall_v2 } from "services";
import { moneyFormat, validateNull } from "utils";
import { useToaster } from "hooks";

import { success, error } from "constants";
import { FinancePayouts } from "../../constants";

const {
  INTERNAL_FUND_TRANSFER_HEADERS,
  PAYMENT_IN_PROGRESS,
  PAYMENT_FAILED,
  TRX_ERROR_MSG,
  msgStatements: { ERROR_MSG },
} = FinancePayouts;

const InternalFundTable = forwardRef(
  (
    {
      pendingTransactions = {},
      rowsPerPage,
      pageNumber,
      onPageChange,
      passSelectedData,
      getPendingPayouts,
      loading,
      selectedPayout,
    },
    ref,
  ) => {
    const [isAllChecked, setAllChecked] = useState(false);
    const [isButtonLoading, setButtonLoading] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [rejectData, setRejectData] = useState();
    const [showErrorList, setShowErrorList] = useState([]);

    const triggerToaster = useToaster();

    const {
      control,
      reset,
      formState: { isDirty },
    } = useForm({
      mode: "onTouched",
      defaultValues: { reason: "" },
    });

    const onCloseModal = () => {
      reset();
      setRejectData("");
      setModalOpen(false);
    };

    const validateRejection = (data) => {
      setModalOpen(true);
      setRejectData(data);
    };

    const onRetryPayout = async (id, i) => {
      try {
        let tempLoading = [...isButtonLoading];
        tempLoading[i] = true;
        setButtonLoading(tempLoading);
        const res = await postCall_v2(
          "oms/payment/initiate-internal-transfer",
          {
            transferIds: [id],
          },
        );
        if (res?.status === 200) {
          getPendingPayouts();
          ref.current.resetSelectedItems();
          triggerToaster(`Payout initiated`, success);
        } else {
          triggerToaster(ERROR_MSG, error);
        }
        tempLoading[i] = false;
        setButtonLoading(tempLoading);
      } catch (err) {
        triggerToaster(ERROR_MSG, error);
      }
    };

    /*
     @description Reject Payout Api call
     */
    const onRejectPayout = async () => {
      const res = await deleteCall(
        `/oms/off-oms-internal-transfer?transferId=${rejectData?.id}`,
        {},
        {},
        (error) => {
          triggerToaster(error.data.detail, "error");
        },
      );
      if (res) {
        getPendingPayouts();
        onCloseModal();
        ref.current.resetSelectedItems();
        triggerToaster(`Payout rejected`, success);
      }
    };

    const handleChildCheckbox = (e, i, val) => {
      let tempSelectedData = [...selectedPayout];
      if (e.target.checked) {
        tempSelectedData.push(pendingTransactions?.response[i]);
      } else {
        tempSelectedData = tempSelectedData.filter(
          (item) => item?.["id"] !== val?.["id"],
        );
      }
      passSelectedData(tempSelectedData);
    };

    const handleParentCheckbox = (e) => {
      let tempData = [];
      if (e.target.checked) {
        tempData = pendingTransactions?.response.filter(
          (ele) => ele?.status !== PAYMENT_IN_PROGRESS,
        );
      } else {
        tempData = [];
      }
      setAllChecked(e.target.checked);
      passSelectedData(tempData);
    };

    // hook to expose function - which will unselect all selected items
    useImperativeHandle(
      ref,
      () => ({
        resetParentCheckBox: () => {
          setAllChecked(false);
        },
      }),
      [],
    );

    useEffect(() => {
      const errorList = [];
      pendingTransactions?.response?.forEach((data) => {
        if (data?.currentState?.state === PAYMENT_FAILED) {
          errorList.push(data?.id);
        }
      });
      setShowErrorList(errorList);
    }, [pendingTransactions]);

    const handleErrorToggle = useCallback((value) => {
      setShowErrorList((prev) => {
        if (prev.includes(value)) {
          return prev.filter((id) => value !== id);
        } else {
          return [...prev, value];
        }
      });
    }, []);

    /**
     * @description Validated checkbox is selected or not.
     * @param {*} id
     * @returns true if selected else false
     */
    const getIsTxSelected = (id) => {
      return selectedPayout?.some((item) => item?.id === id);
    };

    return (
      <Box>
        <>
          <Paper>
            <TableContainer
              sx={{
                minWidth: 700,
                maxHeight: "calc(100vh - 120px)",
              }}
            >
              <Table stickyHeader>
                <TableHead style={{ borderRadius: "10px 10px 0 0" }}>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={isAllChecked}
                        onChange={(e) => handleParentCheckbox(e)}
                      />
                    </TableCell>
                    {INTERNAL_FUND_TRANSFER_HEADERS.map((heading, i) => (
                      <TableCell key={heading}>{heading}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pendingTransactions?.response?.length && !loading ? (
                    pendingTransactions?.response?.map((data, i) => (
                      <>
                        <TableRow key={data?.id}>
                          <TableCell>
                            <Checkbox
                              key={data?.id}
                              checked={
                                getIsTxSelected(data?.id) &&
                                data?.status !== PAYMENT_IN_PROGRESS
                              }
                              disabled={data?.status === PAYMENT_IN_PROGRESS}
                              onChange={(e) => handleChildCheckbox(e, i, data)}
                            />
                          </TableCell>
                          <TableCell>
                            <Typography fontSize={14} style={{ maxWidth: 110 }}>
                              {validateNull(data.fromCompanyData.companyName)}
                              <br />({data.fromGstin})
                            </Typography>
                          </TableCell>
                          <TableCell style={{ maxWidth: 125 }}>
                            <Typography fontSize={14}>
                              {validateNull(data.toCompanyData.companyName)}
                              <br />({data.toGstin})
                            </Typography>
                          </TableCell>
                          <TableCell style={{ maxWidth: 120 }}>
                            {validateNull(data?.ledgerDescription)}
                          </TableCell>
                          <TableCell style={{ maxWidth: 120 }}>
                            {validateNull(data?.advice)}
                          </TableCell>
                          <TableCell>
                            <Typography fontSize={14}>
                              {validateNull(moneyFormat(data?.amount))}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <StatusChip status={data?.currentState?.state} />
                          </TableCell>
                          <TableCell>
                            <Box style={{ textAlign: "center" }}>
                              {data?.comment ? (
                                <HoverBlock
                                  LabelChildren={
                                    <IconButton>
                                      <SvgIcon
                                        name={"chat-unread"}
                                        width={24}
                                        height={24}
                                      />
                                    </IconButton>
                                  }
                                  data={{
                                    "Invoice comments": data?.comment,
                                  }}
                                />
                              ) : (
                                <IconButton>
                                  <SvgIcon
                                    name={"chat-read"}
                                    width={24}
                                    height={24}
                                  />
                                </IconButton>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box display={"flex"} alignItems={"center"}>
                              {data?.currentState?.state === PAYMENT_FAILED ? (
                                <Box display={"flex"} gap={1}>
                                  <ButtonV1
                                    title={"Retry"}
                                    variant={"contained"}
                                    size={"small"}
                                    onClick={() => onRetryPayout(data?.id, i)}
                                  />
                                  <ButtonV1
                                    title={"Reject"}
                                    variant={"text"}
                                    size={"small"}
                                    onClick={() => validateRejection(data)}
                                    disabled={
                                      data?.status === PAYMENT_IN_PROGRESS
                                    }
                                  />
                                </Box>
                              ) : (
                                <ButtonV1
                                  title={"Reject"}
                                  variant={"outlined"}
                                  size={"small"}
                                  onClick={() => validateRejection(data)}
                                  disabled={
                                    data?.status === PAYMENT_IN_PROGRESS
                                  }
                                />
                              )}
                              {data?.currentState?.state === PAYMENT_FAILED && (
                                <InfoOutlined
                                  onClick={() => handleErrorToggle(data?.id)}
                                  style={{ width: 16, marginLeft: 6 }}
                                />
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                        {showErrorList.includes(data?.id) && (
                          <TableCell
                            colSpan={INTERNAL_FUND_TRANSFER_HEADERS.length + 1}
                            sx={{ textAlign: "center", padding: 0 }}
                          >
                            <TableRowWithError
                              message={TRX_ERROR_MSG}
                              onClose={handleErrorToggle}
                              id={data?.id}
                              key={data?.id}
                              length={INTERNAL_FUND_TRANSFER_HEADERS.length + 1}
                            />
                          </TableCell>
                        )}
                      </>
                    ))
                  ) : loading ? (
                    <TableCell
                      colSpan={INTERNAL_FUND_TRANSFER_HEADERS.length}
                      sx={{ textAlign: "center" }}
                    >
                      <Loader sx={{ marginTop: 0 }} />
                    </TableCell>
                  ) : (
                    <TableCell
                      colSpan={INTERNAL_FUND_TRANSFER_HEADERS.length}
                      sx={{ textAlign: "center" }}
                    >
                      No data found
                    </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {!!pendingTransactions?.response?.length && (
              <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                rowsPerPage={rowsPerPage}
                page={pageNumber}
                count={pendingTransactions?.totalCount ?? 0}
                onPageChange={onPageChange}
                sx={{ display: "flex", flexDirection: "column-reverse" }}
                ActionsComponent={PaginationAction}
              />
            )}
          </Paper>
          <ModalBox open={isModalOpen} onCloseModal={onCloseModal} width="50%">
            <Typography fontWeight={"bold"} mb={4}>
              Reject {rejectData?.customerOrdNumber}
            </Typography>
            <Controller
              control={control}
              name={"reason"}
              render={({ field: { value, onChange } }) => (
                <TextField
                  multiline
                  inputProps={{
                    style: {
                      height: 104,
                    },
                  }}
                  sx={{
                    width: "100%",
                  }}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                marginTop: 4,
              }}
            >
              <ButtonV1
                variant="outlined"
                color="secondary"
                title="Close"
                size="small"
                onClick={onCloseModal}
                style={{ marginRight: 4 }}
              />
              <ButtonV1
                variant="contained"
                color="secondary"
                title="Confirm"
                disabled={!isDirty}
                onClick={onRejectPayout}
                size="small"
              />
            </Box>
          </ModalBox>
        </>
      </Box>
    );
  },
);

export default InternalFundTable;
