import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Tabs, Tab, Box, useTheme } from "@mui/material";

// import BuyerTab from "./BuyerTab";
// import SellerTab from "./SellerTab";
// import OrdersTab from "./OrdersTab";
// import AccountPayable from "./AccountPayable";
// import AccountReceivable from "./AccountReceivable";
import CustomerLedger from "./CustomerLedger";

const TabPanel = ({ children, value, selectedTab, tabName, ...rest }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== selectedTab}
      id={`tabpanel-${value}`}
      aria-labelledby={`tab-${value}`}
      {...rest}
    >
      {value === selectedTab && <>{children}</>}
    </Box>
  );
};

const defaultTab = "customerLedger";

const Ledger = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabName, setTabName] = useState(defaultTab);
  const theme = useTheme();

  /**
   * @description set query params in url
   * @param {*} tab
   */
  const updateQueryParams = (tab) => {
    searchParams.set("tab", tab);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    updateQueryParams(defaultTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @param {*} tabType - ledger type
   * @description -  change tab type & set query param
   */
  const handleChange = (tabType) => {
    updateQueryParams(tabType);
    setTabName(tabType);
  };

  return (
    <Box p={4}>
      <Box
        sx={{ borderBottom: 1, borderColor: theme.palette.grey[400], mb: 8 }}
      >
        <Tabs
          value={tabName}
          onChange={(_, newValue) => {
            handleChange(newValue);
          }}
          sx={{
            ">.MuiTabs-scroller>.MuiTabs-flexContainer": {
              display: "flex",
              gap: "20px",
            },
            ">.MuiTabs-scroller": {
              ">.MuiTabs-indicator": {
                backgroundColor: theme.palette.common.black,
              },
              ">.MuiTabs-flexContainer>.Mui-selected": {
                fontWeight: 600,
                color: theme.palette.common.black,
              },
            },
          }}
          aria-label="Ledger tabs"
        >
          {/* <Tab label="Buyer" value="buyer" />
          <Tab label="Seller" value="seller" />
          <Tab label="Orders" value="orders" />

          <Tab label="Account payable" value="accountPayable" />
          <Tab label="Account receivable" value="accountReceivable" /> */}
          <Tab label="Customer ledger" value="customerLedger" />
        </Tabs>
      </Box>
      {/* <TabPanel value="buyer" selectedTab={tabName}>
        <BuyerTab />
      </TabPanel>
      <TabPanel value="seller" selectedTab={tabName}>
        <SellerTab />
      </TabPanel>
      <TabPanel value="orders" selectedTab={tabName}>
        <OrdersTab />
      </TabPanel>

      <TabPanel value="accountPayable" selectedTab={tabName}>
        <AccountPayable />
      </TabPanel>
      <TabPanel value="accountReceivable" selectedTab={tabName}>
        <AccountReceivable />
      </TabPanel> */}
      <TabPanel value="customerLedger" selectedTab={tabName}>
        <CustomerLedger />
      </TabPanel>
    </Box>
  );
};

export default Ledger;
