import { useState, useRef, useCallback } from "react";
import {
  Box,
  Grid,
  Typography,
  useTheme,
  TextField,
  styled,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";

import {
  ButtonV1,
  DropdownV1,
  MultiSelectDropdown,
  MultiSelectSearch,
} from "components";
import { getCall } from "services";
import { updateQuery } from "../utils";
import { PAYMENT_TYPE_LIST } from "../constant";
import { DISPLAY_ATTRIBUTE, BUSINESS_TYPES } from "constants";

const WrapperBox = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  paddingTop: 16,
  paddingBottom: 16,
  paddingLeft: 32,
  paddingRight: 32,
  border: `2px solid ${theme.palette.grey["A100"]}`,
  display: "flex",
  flexDirection: "column",
  maxWidth: "100%",
  marginTop: 20,
  marginBottom: 20,
}));

export const OrderFilter = ({
  disableReset,
  refetchList,
  setDisableReset,
  disableListCallFn,
}) => {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const buyerRef = useRef();
  const sellerNameRef = useRef();
  const selectRef = useRef(null);
  const businessUnitRef = useRef(null);

  const sellerNameParams = searchParams.get("companyName")?.split(",");
  const businessUnitParams = searchParams.get("businessUnit")?.split(",");
  const buyerNameParams = searchParams.get("buyerName")?.split(",");
  const filterValueParams = searchParams.get("filterValue");
  const orderPaymentTypeParams = searchParams.get("orderPaymentType");

  const customerOrderNumberParam = searchParams.get("customerOrderNumber");

  const [toggleFilter, setToggleFilter] = useState(false);
  const [isDirtyBuyer, setIsDirtBuyer] = useState(false);
  const [isDirtySellerName, setIsDirtySellerName] = useState(false);
  const [isBusinessUnitSelected, setIsBusinessUnitSelected] = useState(false);

  const {
    getValues,
    control,
    reset,
    formState: { isDirty },
  } = useForm({
    mode: "onTouched",
  });

  const getSearchedData = async (search, endpoint) => {
    const params = {
      [endpoint]: search,
    };
    const res = await getCall(`/oms/order/list/search/auto-suggest`, params);
    return res;
  };

  const handleReset = () => {
    disableListCallFn(false);
    setSearchParams(`filterValue=${filterValueParams}`);
    refetchList();
    reset();

    setDisableReset(true);
    //reset filter using ref
    businessUnitRef.current?.resetValue();
    selectRef.current?.resetValue();
    buyerRef?.current?.resetValue();
    sellerNameRef?.current?.resetValue();
  };

  const validateFilterValue = (ref, attribute) => {
    const values = ref?.current?.fetchValue();
    if (values && typeof values?.[0] !== "string") {
      return values.map((company) => company[attribute]).join();
    }
    return values;
  };

  const handleFilter = () => {
    const companyName = validateFilterValue(
      sellerNameRef,
      DISPLAY_ATTRIBUTE.sellerName,
    );
    const buyerName = validateFilterValue(
      buyerRef,
      DISPLAY_ATTRIBUTE.buyerName,
    );

    const filterData = {
      customerOrderNumber: getValues("customerOrderNumber")?.trim(),
      companyName,
      buyerName,
      businessUnit: businessUnitRef.current?.fetchValue(),
      orderPaymentType: getValues("orderPaymentType"),
      page: 0,
    };

    if (filterValueParams) {
      filterData.filterValue = filterValueParams;
    }
    const updatedQuery = updateQuery(searchParams, filterData);
    disableListCallFn(true);
    setSearchParams(updatedQuery);
  };

  const isCTADisabled =
    isDirtyBuyer || isDirtySellerName || isBusinessUnitSelected || isDirty;

  const handleToggleFilter = useCallback(
    () => setToggleFilter((prev) => !prev),
    [],
  );

  return (
    <WrapperBox>
      <Box display={"flex"} flexDirection="row">
        <Grid container flex={4} spacing={4} rowGap={2}>
          <Grid item xs={3}>
            <MultiSelectSearch
              ref={buyerRef}
              defaultSelectedValues={buyerNameParams}
              displayAttribute={DISPLAY_ATTRIBUTE.buyerName}
              label="Customer name"
              onSearch={(searchInput) =>
                getSearchedData(searchInput, DISPLAY_ATTRIBUTE.buyerName)
              }
              setIsDirty={setIsDirtBuyer}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              control={control}
              defaultValue={customerOrderNumberParam ?? ""}
              name={"customerOrderNumber"}
              render={({ field: { value, onChange } }) => (
                <TextField
                  label="Customer order number"
                  size="small"
                  onChange={onChange}
                  value={value}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>

          {toggleFilter && (
            <>
              <Grid item xs={3}>
                <MultiSelectSearch
                  ref={sellerNameRef}
                  defaultSelectedValues={sellerNameParams}
                  displayAttribute="companyName"
                  label="Seller name"
                  onSearch={(searchInput) =>
                    getSearchedData(searchInput, DISPLAY_ATTRIBUTE.sellerName)
                  }
                  setIsDirty={setIsDirtySellerName}
                />
              </Grid>
              <Grid item xs={3}>
                <MultiSelectDropdown
                  ref={businessUnitRef}
                  itemList={BUSINESS_TYPES}
                  displayAttribute={"state"}
                  label={"Business unit"}
                  isListOfObjects={true}
                  defaultSelectedValues={businessUnitParams ?? []}
                  setIsDirty={setIsBusinessUnitSelected}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  control={control}
                  defaultValue={orderPaymentTypeParams ?? ""}
                  name="orderPaymentType"
                  render={({ field: { value, onChange } }) => (
                    <DropdownV1
                      listType="array"
                      sx={{ width: "100%" }}
                      placeholder="Payment type"
                      itemList={PAYMENT_TYPE_LIST}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Grid>
            </>
          )}
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
              gap: 4,
              alignItems: "start",
            }}
          >
            <ButtonV1
              title="Submit"
              onClick={handleFilter}
              disabled={!isCTADisabled}
              color="primary"
              variant="contained"
            />
            <ButtonV1
              style={
                !disableReset && {
                  color: theme.palette.primary.main,
                  border: `1px solid ${theme.palette.primary.main}`,
                }
              }
              onClick={handleReset}
              disabled={!isCTADisabled}
              variant="outlined"
              title="Reset"
            />
          </Grid>
        </Grid>
        <Box
          item
          sx={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontWeight: 600,
              display: "flex",
              cursor: "pointer",
            }}
            onClick={handleToggleFilter}
          >
            {toggleFilter ? "Show less filter" : "Show advance filter"}
            {toggleFilter ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Typography>
        </Box>
      </Box>
    </WrapperBox>
  );
};
