import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/system";

const StyledBox = styled(Box)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 50vh;
  }
`;

const linkStyle = {
  textDecoration: "none",
  fontSize: "1rem",
};

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: "" };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      return (
        <StyledBox>
          <Typography variant="h3">Something went wrong</Typography>
          <Typography variant="h4">{this.state.error.message}</Typography>
          <Link to="/" style={linkStyle}>
            HomePage
          </Link>
        </StyledBox>
      );
    }

    return this.props.children;
  }
}
