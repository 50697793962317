import * as React from "react";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { hideToasterAction } from "dataStore/showSnackBar";

import theme from "themeProvider";

const Toaster = ({ variant, message }) => {
  const dispatch = useDispatch();
  const openToaster = useSelector((state) => state.showSnackBar);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleClose = () => {
    dispatch(hideToasterAction({ value: false }));
  };
  return (
    <Snackbar
      open={openToaster?.value}
      autoHideDuration={10000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      sx={{ width: "30%" }}
    >
      <Alert
        onClose={handleClose}
        severity={openToaster.variant}
        sx={{
          width: "100%",
          border: 1,
          borderColor: theme?.palette?.[openToaster?.variant]?.dark,
          color: theme?.palette?.[openToaster?.variant]?.dark,
          "& .MuiAlert-root": {
            borderRadius: 8,
          },
        }}
      >
        {openToaster.message}
      </Alert>
    </Snackbar>
  );
};

export default Toaster;
