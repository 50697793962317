import {
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import { StatusChip } from "../components";
import { StyledTableRow } from "CustomStyledComponents";

import { FinancePayouts } from "pages/FinancePayout/constants";
import { moneyFormat } from "utils";

const { REFUND_MODAL_HEADER, payoutTypes, accountStatus } = FinancePayouts;

const RefundModalTable = ({ selectedData }) => {
  return (
    <>
      <TableContainer sx={{ maxHeight: 420 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {REFUND_MODAL_HEADER.map((item) => (
                <TableCell>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedData?.map((item) => (
              <StyledTableRow>
                <TableCell>
                  <StatusChip status={item?.orderType} isOrderType />
                </TableCell>
                <TableCell>
                  <Typography fontSize={14}>
                    {item?.customerOrdNumber}
                  </Typography>
                  <br />
                  <Typography fontSize={14}>{item?.entityName}</Typography>
                </TableCell>
                <TableCell style={{ maxWidth: 120 }}>
                  {payoutTypes[item?.payoutTypeKey]}
                </TableCell>
                <TableCell>
                  {!!item?.amount && moneyFormat(item?.amount)}
                </TableCell>
                <TableCell>
                  <StatusChip
                    status={
                      item?.customerBankAccountVerificationDetails?.isVerified
                        ? accountStatus.verified
                        : accountStatus.unverified
                    }
                  />
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

RefundModalTable.propTypes = {
  selectedData: PropTypes.array,
};

export default RefundModalTable;
