import { TableRow, Grid, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

import theme from "themeProvider";

export const StyledTableRow = styled(TableRow)(({ customStyle }) => ({
  "&.MuiTableRow-root": {
    "&:nth-of-type(even)": {
      background: theme.palette.grey["100"],
    },
    "&:nth-of-type(odd): hover": {
      transform: "unset",
    },
    ...customStyle,
  },
}));

export const ColumnTitle = styled("span")({
  color: theme.palette.common.black,
});

export const GridSkeleton = ({
  firstChild,
  secondChild,
  thirdChild,
  style,
}) => {
  return (
    <Grid container style={style}>
      <Grid item xs={4}>
        {firstChild}
      </Grid>
      <Grid item xs={4}>
        {secondChild}
      </Grid>
      <Grid item xs={4}>
        {thirdChild}
      </Grid>
    </Grid>
  );
};

export const ErrorMsg = styled("p")({
  color: "red",
  margin: "0.25rem 0rem 1.5rem 0rem",
  fontSize: 12,
  textAlign: "left",
  minHeight: 12,
});

export const WarningMsg = styled("p")({
  color: theme.palette.warning.main,
  margin: "0.25rem 0rem 1.5rem 0rem",
  fontSize: 12,
  textAlign: "left",
  minHeight: 12,
});

export const StyledInputButton = styled("input")({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontFamily: "Source Sans Pro,sans-serif",
  fontSize: "1rem",
  lineHeight: 1.75,
  border: 0,
  cursor: "pointer",
  fontWeight: 600,
  padding: "5px 25px",
  borderRadius: 20,
});

export const StyledBox = styled(Box)({
  borderRadius: 8,
  backgroundColor: theme.palette.primary.main,
  cursor: "pointer",
  maxHeight: 40,
  padding: 2,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const ErrorMsgV1 = ({ msg, style }) => (
  <Typography
    style={{
      fontSize: 12,
      color: "red",
      visibility: msg ? "visible" : "hidden",
      height: 12,
      ...style,
    }}
  >
    {msg}
  </Typography>
);
