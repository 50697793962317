import { useEffect } from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import NoAccess from "./NoAccess";
import SideBar from "components/Sidebar";

import { restrictPath } from "./utils";
import { setTab } from "dataStore/selectedTab";
import { sellerOrderTag } from "constants";

const ProtectedRoute = () => {
  const login = useSelector((state) => state.isLoggedIn);
  const selectedTab = useSelector((state) => state.selectedTab);
  const allowedModules = useSelector((state) => state.userData.modules);

  const dispatch = useDispatch();
  const { pathname, search: queryParams } = useLocation();
  const dynamicId = useParams();

  useEffect(() => {
    const {
      location: { pathname },
    } = window;
    if (!pathname.includes(sellerOrderTag) && selectedTab !== null) {
      dispatch(setTab(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!login.value) {
    return <Navigate to="/" replace />;
  } else if (restrictPath(allowedModules, pathname, queryParams, dynamicId)) {
    return (
      <SideBar>
        <NoAccess />
      </SideBar>
    );
  }

  return <Outlet />;
};

export default ProtectedRoute;
