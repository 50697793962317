import { styled } from "@mui/system";
import { Box, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import PropTypes from "prop-types";

const Label = styled("span")({
  fontWeight: "600",
});

const DropdownModule = ({
  label,
  value,
  dropdownList,
  disabled,
  onChange,
  labelStyle,
  dropdownLabel,
  customStyle,
  valueKey = "",
  labelKey = "",
  selectStyle,
  menuStyle,
  rest,
  placeholder = "",
}) => {
  return (
    <Box
      style={{
        display: "flex",
        direction: "row",
        alignItems: "baseline",
        ...rest,
      }}
    >
      {label && (
        <Label
          style={{
            ...(labelStyle && { ...labelStyle }),
          }}
        >
          {label}
        </Label>
      )}
      <FormControl
        style={{
          ...(customStyle && { ...customStyle }),
        }}
        size="small"
      >
        <InputLabel>{dropdownLabel}</InputLabel>
        <Select
          value={value || ""}
          label={dropdownLabel}
          disabled={disabled}
          onChange={onChange}
          displayEmpty={true}
          renderValue={(value) => (value?.length ? value : placeholder)}
          sx={{ ...selectStyle }}
        >
          {valueKey === "LIST"
            ? !!dropdownList &&
              dropdownList.map((item) => (
                <MenuItem value={item} key={item} style={{ ...menuStyle }}>
                  {item}
                </MenuItem>
              ))
            : !!dropdownList &&
              dropdownList.map((item) => {
                return valueKey ? (
                  <MenuItem
                    style={{ ...menuStyle }}
                    value={item[valueKey]}
                    key={item[valueKey]}
                  >
                    {labelKey ? item[labelKey] : item[valueKey]}
                  </MenuItem>
                ) : (
                  <MenuItem
                    style={{ ...menuStyle }}
                    value={item.value}
                    key={item.value}
                  >
                    {item.label}
                  </MenuItem>
                );
              })}
        </Select>
      </FormControl>
    </Box>
  );
};

DropdownModule.propTypes = {
  label: PropTypes.string,
  dropdownLabel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  dropdownBoxStyle: PropTypes.object,
  labelWidth: PropTypes.string,
  dropdownList: PropTypes.array,
  customStyle: PropTypes.object,
  placeholder: PropTypes.string,
};

export default DropdownModule;
