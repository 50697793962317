import { useState, useCallback, useEffect, useRef, useMemo } from "react";
import { Box, Typography } from "@mui/material";

import { ButtonV1, ExportCta, ModalBox } from "components";
import { QuickFilter } from "../components";
import InternalFundTable from "./InternalFundTable";
import InternalFundDisbursement from "./InternalFundDisbursement";

import { getCall_v2, postCall_v2 } from "services";
import { useFetchData, useToaster } from "hooks";

import { success, error } from "constants";
import { FinancePayouts } from "../../constants";
import { downloadCsv } from "utils";

const initialQuickFilter = "all";

const {
  quick_filter,
  msgStatements: { ERROR_MSG },
} = FinancePayouts;
const rowsPerPage = 50;

const endUrl = `/oms/off-oms-internal-transfer/list?filterValue=PAYMENT_PENDING`;

const InternalFundTransfer = ({ filters }) => {
  const [selectedQuickFilter, setSelectedQuickFilter] =
    useState(initialQuickFilter);
  const [page, setPage] = useState(0);
  const [filterCount, setFilterCount] = useState({});
  const [selectedPayout, setSelectedPayout] = useState([]);
  const [isDisbursementOpen, setDisbursementOpen] = useState(false);

  const triggerToaster = useToaster();

  const payoutTableRef = useRef(null);

  const pendingInternalParam = useMemo(
    () => ({
      limit: rowsPerPage,
      filterType: selectedQuickFilter,
      ...filters,
    }),
    [selectedQuickFilter, filters],
  );

  const addTotalCount = (res) => {
    let filterCountRef = { ...filterCount };
    filterCountRef[selectedQuickFilter] = res?.data?.totalCount;
    setFilterCount(filterCountRef);
  };

  const {
    refetch: getPendingPayouts,
    data: pendingTransactions,
    isFetching,
  } = useFetchData(
    ["internal-pending-payouts", pendingInternalParam],
    endUrl,
    addTotalCount,
    null,
    { ...pendingInternalParam, offset: page * rowsPerPage },
  );

  /**
   * @description set filters on click of quick filters
   */
  const handleQuickFilter = (value) => {
    setSelectedQuickFilter(value);
  };

  /**
   * @description fetch pending payouts during page load
   */
  useEffect(() => {
    if (page) {
      setPage(0);
    } else {
      getPendingPayouts();
    }
  }, [pendingInternalParam]);

  useEffect(() => {
    getPendingPayouts();
  }, [page]);

  /**
   * @description Handle pagination
   */
  const handlePageChange = useCallback((_, pageNumber) => {
    setPage(pageNumber);
    resetSelectItems();
  }, []);

  const getSelectedSummaryData = (data) => {
    setSelectedPayout(data);
  };

  const onClosePayoutDisbursement = () => {
    setDisbursementOpen(false);
  };
  const openPayoutModal = () => {
    setDisbursementOpen(true);
  };

  /**
   * @description handle initiate payout
   */
  const onInitiatePayout = async () => {
    try {
      if (selectedPayout.length) {
        let selectedIds = selectedPayout.map((ele) => ele.id);
        const res = await postCall_v2(
          "oms/payment/initiate-internal-transfer",
          {
            transferIds: selectedIds,
          },
        );
        if (res?.status === 200) {
          getPendingPayouts();
          triggerToaster(`Payout initiated`, success);
          resetSelectItems();
          setDisbursementOpen(false);
        } else {
          triggerToaster(ERROR_MSG, error);
        }
      }
    } catch (err) {
      triggerToaster(ERROR_MSG, error);
    }
  };

  const onExportCSV = async () => {
    try {
      const payoutUrl = `oms/off-oms-internal-transfer/download/list`;
      const { data } = await getCall_v2(payoutUrl, {
        ...pendingInternalParam,
        filterValue: "PAYMENT_PENDING",
        limit: null,
      });
      triggerToaster(`Downloading file`, success);
      downloadCsv(data, "internal_fund_payouts.csv");
    } catch (err) {
      console.error(`Error in download, ${err}`);
      triggerToaster(`Please try again later`, error);
    }
  };

  /**
   * @description reset selected items and parent checkbox
   */
  const resetSelectItems = useCallback(() => {
    payoutTableRef?.current?.resetParentCheckBox();
    setSelectedPayout([]);
  }, []);

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={4}
      >
        <Box>
          <Typography fontSize={20} fontWeight={600} mb={2}>
            Pending payouts
          </Typography>
          <Box display={"flex"}>
            {Object.keys(quick_filter).map((elem, ind) => (
              <QuickFilter
                key={ind}
                label={quick_filter[elem]}
                value={elem}
                onClick={handleQuickFilter}
                selectedFilter={selectedQuickFilter}
                filterCount={filterCount}
              />
            ))}
          </Box>
        </Box>
        <Box sx={{ display: "flex" }} alignItems={"flex-end"}>
          <Box sx={{ marginRight: 4, marginTop: 10, marginBottom: -1 }}>
            <ExportCta onExport={onExportCSV} />
          </Box>
          <ButtonV1
            title={"Initiate"}
            size={"small"}
            disabled={!selectedPayout?.length}
            onClick={openPayoutModal}
          />
        </Box>
      </Box>
      <ModalBox
        open={!!isDisbursementOpen}
        onCloseModal={onClosePayoutDisbursement}
        width="50%"
      >
        <>
          <InternalFundDisbursement data={selectedPayout} />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <ButtonV1
              variant="contained"
              color="secondary"
              title="Confirm"
              size="medium"
              onClick={onInitiatePayout}
            />
          </Box>
        </>
      </ModalBox>
      <InternalFundTable
        ref={payoutTableRef}
        pendingTransactions={pendingTransactions?.data}
        rowsPerPage={rowsPerPage}
        pageNumber={page}
        loading={isFetching}
        onPageChange={handlePageChange}
        passSelectedData={getSelectedSummaryData}
        getPendingPayouts={getPendingPayouts}
        selectedPayout={selectedPayout}
      />
    </>
  );
};

export default InternalFundTransfer;
