const customerProfile = Object.freeze({
  CUSTOMER_BANK_ACCOUNTS_HEADER: [
    "GSTIN Name",
    "Bank Account Name",
    "Verification status",
    "Details",
    "Action",
  ],
  profileStatus: {
    APPROVED: "Approved",
    REJECTED: "Rejected",
    PENDING: "Pending",
  },
});

export default customerProfile;
