import { Box, Popover, TextField, useTheme } from "@mui/material";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCallback, useEffect, useState } from "react";
import { ButtonV1 } from "components";
import { Controller } from "react-hook-form";

const InvoiceEntry = ({
  value,
  control,
  unique,
  name,
  unregister,
  getValues,
  onDelete,
}) => {
  const theme = useTheme();

  useEffect(() => {
    return () => {
      !getValues(`${name}.${unique}`) && unregister(`${name}.${unique}`);
    };
  }, []);

  return (
    <Controller
      control={control}
      defaultValue={value}
      name={`${name}.${unique}`}
      render={({ field: { value, onChange } }) => (
        <TextField
          value={value}
          size="small"
          placeholder="Enter original invoice no."
          onChange={onChange}
          InputProps={{
            endAdornment: value ? (
              <DeleteIcon
                onClick={() => {
                  unregister(`${name}.${unique}`);
                  onDelete();
                }}
                style={{
                  color: theme.palette.text.secondary,
                  width: 20,
                  height: 20,
                }}
              />
            ) : (
              <></>
            ),
          }}
        />
      )}
    />
  );
};

const InvoiceId = ({ defaultValue, name, methods, clearInvoiceIds }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState();

  const { getValues, control, unregister, setValue } = methods;

  const [invoiceList, setInvoiceList] = useState([]);

  const toggleInvoiceBox = useCallback((e) => {
    setAnchorEl((prev) => {
      if (prev) return null;
      else return e?.currentTarget;
    });
  }, []);

  const addNewInvoice = () => {
    const latestValue = getValues(name);
    if (latestValue) {
      unregister(name);
      setInvoiceList([...latestValue?.filter((i) => !!i && i), ""]);
    } else {
      setInvoiceList([""]);
    }
  };

  useEffect(() => {
    if (!defaultValue?.length) {
      addNewInvoice();
    } else {
      defaultValue?.forEach((val, index) => setValue(`${name}.${index}`, val));
      setInvoiceList(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (!!clearInvoiceIds) {
      setInvoiceList([""]);
    }
  }, [clearInvoiceIds]);

  const getLatestValue = () => {
    const latestValue = getValues(name);
    unregister(name);
    const filterOutEmpty = latestValue?.filter((i) => !!i && i);
    if (filterOutEmpty?.length) {
      setInvoiceList(filterOutEmpty);
    } else {
      addNewInvoice();
    }
  };

  return (
    <>
      <TextField
        label="Original Invoice no."
        value={invoiceList[0] || ""}
        onClick={toggleInvoiceBox}
        size="small"
        sx={{ input: { cursor: "pointer" }, width: "100%" }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <OpenInFullIcon
              style={{
                color: theme.palette.text.secondary,
                width: 20,
                height: 20,
              }}
            />
          ),
        }}
      />
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          getLatestValue();
          toggleInvoiceBox();
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box display={"flex"} flexDirection={"column"} gap={3} padding={2}>
          {invoiceList?.map((invoice, index) => {
            return (
              <InvoiceEntry
                key={invoice}
                unique={index}
                name={name}
                value={invoice}
                control={control}
                getValues={getValues}
                unregister={unregister}
                onDelete={() => {
                  getLatestValue();
                }}
              />
            );
          })}
          <Box display={"flex"}>
            <ButtonV1
              size="small"
              variant="text"
              onClick={addNewInvoice}
              title={"Add another"}
              textStyle={{ flex: 1, justifyContent: "flex-start" }}
            />
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default InvoiceId;
