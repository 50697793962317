import {
  IconButton,
  TableCell,
  TableRow,
  Typography,
  Box,
  useTheme,
  Divider,
} from "@mui/material";
import { ArrowForwardIosOutlined } from "@mui/icons-material";
import { SvgIcon } from "components";
import { styled } from "@mui/styles";

const StyledRow = styled(TableRow)(({ theme }) => ({
  boxShadow: ` 0 0 8px 0 ${theme.palette.grey[200]}`,
  margin: "24px 0",
  "&:hover": {
    transform: "translateY(2px)",
  },
}));

const TableRowEdit = ({ onDelete, onViewDetail, children }) => {
  const theme = useTheme();
  return (
    <StyledRow>
      {children}
      <TableCell>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: 4,
            alignItems: "center",
          }}
        >
          {!!onDelete && (
            <IconButton
              component="label"
              disableRipple={true}
              onClick={onDelete}
            >
              <SvgIcon name={"delete"} width={20} height={20} />
            </IconButton>
          )}

          {!!onViewDetail && (
            <>
              <Divider orientation="vertical" flexItem />
              <IconButton component="label" disableRipple={true}>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: 12,
                    color: theme.palette.grey["A200"],
                  }}
                  onClick={onViewDetail}
                >
                  View details
                </Typography>
                <ArrowForwardIosOutlined
                  style={{
                    width: 12,
                    height: 12,
                    marginLeft: 4,
                    marginTop: 2,
                  }}
                />
              </IconButton>
            </>
          )}
        </Box>
      </TableCell>
    </StyledRow>
  );
};

export default TableRowEdit;
