import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { CollapsibleInfo, Loader } from "components";

import { useFetchData } from "hooks";
import { SectionTitle, PayoutSummaryHeader } from "./CustomComponents";
import ShipmentPayoutSummary from "./ShipmentPayoutSummary";
import { disbursementState, NO_PAYOUTS_MSG } from "./constants";
import theme from "themeProvider";

const PayoutsTab = () => {
  const [shipmentSummaries, setShipmentSummaries] = useState([]);
  const [shipmentDisbursement, setShipmentDisbursement] = useState({});

  const { id } = useParams();
  const orderDetails = useSelector((state) => state.orderDetails);

  const { refetch, isFetching } = useFetchData(
    "payment-summary",
    `/oms/payout/seller-payout-summaries/order-number/${id}`,
    (res) => {
      const { sellerPayoutSummary } = res.data;
      let totalShipmentPayouts = [];
      if (sellerPayoutSummary) {
        // eslint-disable-next-line array-callback-return
        sellerPayoutSummary?.payoutSellerSummary.map((ele) => {
          totalShipmentPayouts = [
            ...totalShipmentPayouts,
            ...ele.shipmentPayouts,
          ];
        });
      }
      setShipmentSummaries(totalShipmentPayouts);
    },
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkboxSelection = (event, data) => {
    let refObj = shipmentDisbursement;
    if (event.target.checked) {
      const selectedShipment = shipmentSummaries.find(
        (item) => item.shipmentId === data,
      );
      refObj[data] = selectedShipment;
      setShipmentDisbursement({ ...refObj });
    } else {
      delete refObj[data];
      setShipmentDisbursement({ ...refObj });
    }
  };

  if (isFetching) return <Loader />;
  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"} mt={4} mb={6}>
        <SectionTitle title="Shipment summary" />
      </Box>
      <>
        {shipmentSummaries.length ? (
          shipmentSummaries?.map((item) => {
            return (
              <Box
                key={item?.sellerOrdNumber}
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "baseline",
                  marginBottom: 32,
                }}
              >
                <Box style={{ width: "100%" }}>
                  <CollapsibleInfo
                    summaryStyles={{ width: "94%" }}
                    summary={
                      <PayoutSummaryHeader
                        item={item}
                        checkboxSelection={checkboxSelection}
                        disbursementState={disbursementState}
                      />
                    }
                    details={
                      <Box
                        style={{
                          padding: "20px 50px",
                          margin: "0 20px 20px 20px",
                          border: `1px solid ${theme.palette.grey["400"]}`,
                          borderRadius: 8,
                          background: theme.palette.grey["100"],
                        }}
                      >
                        <ShipmentPayoutSummary
                          orderDetails={orderDetails}
                          shipmentSummary={item}
                          refetchShipmentSummary={refetch}
                        />
                      </Box>
                    }
                  />
                </Box>
              </Box>
            );
          })
        ) : (
          <Typography textAlign={"center"}>{NO_PAYOUTS_MSG}</Typography>
        )}
      </>
    </>
  );
};
export default PayoutsTab;
