import { useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

import theme from "themeProvider";
import { ButtonV1 } from "components";
import { success, error } from "constants";
import { postCall_v2 } from "services";
import { downloadCsv } from "utils";
import { useToaster } from "hooks";

const failed = "failed";
const BuyerMisLedger = "BuyerMisLedger";
const BuyerLedger = "BuyerLedger";

const FileDragAndDrop = () => {
  const [file, setFile] = useState();
  const [ledgerType, setLedgerType] = useState();
  const [ledgerResponse, setLedgerResponse] = useState();
  const [isPostDisabled, setPostDisabled] = useState(false);
  const triggerToaster = useToaster();

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleRadioButton = (e) => {
    setLedgerType(e?.target?.value);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const fileFormat = "text/csv";
    const file = [...e.dataTransfer.files];

    if (file.length > 1) {
      triggerToaster(`Only 1 file can be uploaded at a time`, error);
      return;
    }
    const allowedMaxSize = 5;
    const fileSize = file?.[0]?.size / 1024 / 1024; //convert to MB

    if (fileSize.toFixed(2) > allowedMaxSize) {
      triggerToaster("Allowed max file size is 2MB", error);
      return;
    }

    if (file?.[0]?.type !== fileFormat) {
      triggerToaster(`Please upload a csv file`, error);
      return;
    }
    if (file && file.length) {
      setFile(file?.[0]);
    }
  };

  const csvColumns = [
    "Correction Entry reference",
    "From",
    "To",
    "TransactionReference",
    "Amount",
    "Current balance",
    "Status",
  ];

  const createCsvData = (data) => {
    let csvValues = [];
    const csvColumn = csvColumns?.join(",");
    csvValues.push(csvColumn);
    // eslint-disable-next-line array-callback-return
    data?.map((ele) => {
      const {
        correctionEntryReference,
        fromReference,
        toReference,
        transactionReference,
        amount,
        currentBalance,
        errorMessage,
      } = { ...ele };
      const value = `${correctionEntryReference},${fromReference},${toReference},${transactionReference},${amount},${currentBalance},${errorMessage}`;
      csvValues.push(value);
    });
    return csvValues.join("\n");
  };

  const onPost = async () => {
    setPostDisabled(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      const { data } = await postCall_v2(
        `/oms/payment/ledger/correction?ledgerType=${ledgerType}`,
        formData,
      );
      if (data) {
        setLedgerResponse(data?.[0]);
        if (data?.[0]?.status === failed) {
          triggerToaster(
            `Validation error. Download the csv for error details.`,
            error,
          );
        } else if (data?.[0]?.status === success) {
          triggerToaster(`Validation success`, success);
        }
        setPostDisabled(false);
      }
    } catch (err) {
      setPostDisabled(false);
      triggerToaster(`An error occured. Please try again.`, error);
    }
  };

  const resetData = () => {
    setFile(null);
    setLedgerResponse([]);
    setPostDisabled(false);
  };

  const downloadCsvFile = () => {
    const data = createCsvData(ledgerResponse?.ledgerCorrectionEntryList);
    ledgerType === BuyerMisLedger
      ? downloadCsv(data, "BuyerMis_ledger")
      : downloadCsv(data, "Buyer_ledger");
  };

  return (
    <Box display={"flex"} justifyContent={"center"}>
      <Box
        px={6}
        py={4}
        style={{
          border: 1,
          borderStyle: "solid",
          borderColor: theme.palette.grey[900],
          width: 570,
          borderRadius: 12,
        }}
        mt={30}
      >
        <Box display={"flex"} justifyContent={"space-between"} mb={4}>
          <Typography fontWeight={"bold"} fontSize={20}>
            Upload files
          </Typography>
          <Typography fontSize={14} color={theme.palette.grey[700]}>
            Maximum size 5 MB
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          style={{
            border: 1,
            borderStyle: "dashed",
            borderColor: theme.palette.grey[900],
            borderRadius: 12,
            backgroundColor: theme.palette.grey[100],
          }}
          mb={4}
          p={10}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <object
            alt="Upload icon"
            aria-label="upload icon"
            type="image/svg+xml"
            data={`/assets/upload-cloud.svg`}
            style={{ pointerEvents: "none", height: 26, marginBottom: 12 }}
          />
          <Typography marginBottom={2}>{file?.name}</Typography>
          {!file && (
            <Typography marginBottom={4}>Drag and drop files here</Typography>
          )}
          {!file && (
            <Typography
              style={{ marginBottom: 4 }}
              fontSize={12}
              color={theme.palette.grey[700]}
            >
              File supported: CSV
            </Typography>
          )}
          {file && (
            <>
              <FormControl style={{ marginBottom: 15 }}>
                <RadioGroup onChange={handleRadioButton} row>
                  <FormControlLabel
                    value={BuyerMisLedger}
                    control={<Radio size="small" />}
                    label="Buyer MIS ledger correction"
                    sx={{ fontSize: 14 }}
                  />
                  <FormControlLabel
                    value={BuyerLedger}
                    control={<Radio size="small" />}
                    label="Buyer ledger correction"
                  />
                </RadioGroup>
              </FormControl>
              <ButtonV1
                style={{ borderRadius: 4 }}
                size="small"
                title={"Post"}
                disabled={ledgerResponse?.length || isPostDisabled}
                onClick={onPost}
              />
            </>
          )}
        </Box>
        {!!ledgerResponse?.status && (
          <Box display={"flex"} justifyContent={"flex-end"}>
            {ledgerResponse?.status === failed ? (
              <ButtonV1
                style={{ borderRadius: 4 }}
                size="small"
                title={"Download CSV"}
                onClick={downloadCsvFile}
              />
            ) : (
              <ButtonV1
                style={{ borderRadius: 4 }}
                size="small"
                title={"Upload more"}
                variant={"outlined"}
                onClick={resetData}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FileDragAndDrop;
