import { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box, List, CssBaseline, Divider, Drawer, AppBar } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { DialogBox, Ribbon } from "components";
import { setVerifyFieldsEdit } from "dataStore/verifyFieldsEdit";
import { verifyLoginAction } from "dataStore/auth";
import { setOrderDetailsAction } from "dataStore/orderDetails";
import { remUserDataAction } from "dataStore/userProfile";
import { setTab } from "dataStore/selectedTab";

import {
  SIDE_MENU,
  DrawerHeader,
  closedMixin,
  openedMixin,
  CustomListItemText,
  StyledListItem,
  drawerWidth,
  ToggleSidebar,
  AppHeader,
  CustomListItemIcon,
} from "./SidebarCustomComponents";
import Support from "components/Support";

const CustomAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  boxShadow: "unset",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  position: "absolute",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

let clonePath = "";

export default function SideBar({ children }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [selectedPath, setPath] = useState("");
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const location = useLocation();

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);
  const verifyFieldsEdit = useSelector((state) => state.verifyFieldsEdit);
  const userData = useSelector((state) => state.userData);
  const dashboardPathParams = useSelector((state) => state.dashboardPathParams);
  const orderListPathParams = useSelector((state) => state.orderListPathParams);

  const redirectToHome = () => {
    dispatch(verifyLoginAction({ value: false, token: "" }));
    dispatch(setOrderDetailsAction({}));
    dispatch(remUserDataAction());
    dispatch(setTab(null));

    navigation("/");
  };

  const setDefaultFilter = (path) => {
    switch (path) {
      case "dashboard":
        return `/${path}?${dashboardPathParams}`;
      case "order-list":
        if (location.pathname === "/dashboard") {
          return `/${path}?filterValue=today&${dashboardPathParams}`;
        } else {
          return orderListPathParams
            ? `/${path}?${orderListPathParams}`
            : `/${path}?filterValue=today`;
        }
      default:
        return `/${path}`;
    }
  };

  const navigateToPage = (path) => {
    const { isFieldsEdit } = verifyFieldsEdit;
    clonePath = path;
    if (isFieldsEdit) {
      setIsOpenConfirmModal(true);
    } else {
      const newPath = setDefaultFilter(path);
      navigation(newPath);
      setPath(path);
    }
  };

  useEffect(() => {
    if (location) {
      setPath(location.pathname.split("/")[1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleExitPage = () => {
    dispatch(setVerifyFieldsEdit(false));
    setIsOpenConfirmModal(false);
    navigation(`/${clonePath}`);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <CustomAppBar position="fixed" open={open}>
        <Ribbon />
        <AppHeader redirectToHome={redirectToHome} userName={userData?.name} />
      </CustomAppBar>
      <CustomDrawer
        variant="permanent"
        open={open}
        sx={{
          "& .MuiDrawer-paper": {
            marginTop: 10,
            height: "100%",
            boxShadow: open && theme.shadows[0],
            boxSizing: "border-box",
            backgroundColor: theme.palette.bgColor,
            borderRight: `1px solid ${theme.palette.grey["100"]}`,
          },
        }}
      >
        <Divider />
        <List
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ToggleSidebar
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
            open={open}
          />
          {SIDE_MENU.map((menu) => (
            <StyledListItem
              button
              key={menu.label}
              style={{
                fontFamily: "unset",
                display: userData?.allowed_URL?.[menu?.label] ? "" : "none",
              }}
              onClick={() => navigateToPage(menu.path)}
            >
              <CustomListItemIcon selectedPath={selectedPath} menu={menu} />
              <CustomListItemText primary={menu.label} />
            </StyledListItem>
          ))}
          <Support open={open} />
        </List>
      </CustomDrawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, pl: 14 }}
        onMouseOver={handleDrawerClose}
      >
        <DrawerHeader />
        {children}
      </Box>
      <DialogBox
        handleClose={() => setIsOpenConfirmModal(false)}
        open={isOpenConfirmModal}
        handleConfirm={handleExitPage}
      />
    </Box>
  );
}
