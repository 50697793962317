import { Box } from "@mui/material";
import { Loader } from "components";

const OverlayLoader = ({ isLoading, children }) => {
  return (
    <Box position="relative">
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            background: "#f9f9f969",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              position: "relative",
              top: 200,
            }}
          >
            <Loader sx={{ height: "unset" }} />
          </Box>
        </Box>
      )}
      {children}
    </Box>
  );
};
export default OverlayLoader;
