import {
  useImperativeHandle,
  useState,
  forwardRef,
  useCallback,
  useEffect,
} from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TablePagination,
  Typography,
  TextField,
  IconButton,
  Paper,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import {
  ButtonV1,
  PaginationAction,
  ModalBox,
  Loader,
  HyperLink,
  SvgIcon,
  TableRowWithError,
} from "components";

import { success, error } from "constants";
import { moneyFormat, dateConvertor } from "utils";
import { postCall_v2 } from "services";
import { useToaster } from "hooks";

import { StatusChip, HoverBlock } from "../components";
import { FinancePayouts } from "../../constants";
import {
  getValidPayoutId,
  getValidRefundPayoutId,
} from "pages/FinancePayout/makeData";

const {
  pendingRefundHeaders,
  payoutTypes,
  PAYMENT_IN_PROGRESS,
  PAYMENT_FAILED,
  FUND_VALIDATION_ERROR,
  accountStatus,
} = FinancePayouts;

const PayoutTable = forwardRef(
  (
    {
      summary = {},
      rowsPerPage,
      pageNumber,
      onPageChange,
      passSelectedData,
      getPendingPayouts,
      loading,
      selectedPayout,
    },
    ref,
  ) => {
    const [isAllChecked, setAllChecked] = useState(false);
    const [isButtonLoading, setButtonLoading] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [rejectData, setRejectData] = useState();
    const [showErrorList, setShowErrorList] = useState([]);
    const navigation = useNavigate();

    const showToaster = useToaster();

    const {
      control,
      reset,
      getValues,
      formState: { isDirty },
    } = useForm({
      mode: "onTouched",
      defaultValues: { reason: "" },
    });

    const onCloseModal = () => {
      reset();
      setRejectData("");
      setModalOpen(false);
    };

    const validateRejection = (data) => {
      setModalOpen(true);
      setRejectData(data);
    };

    const getCustomerVerificationDetails = (data) => {
      const { customerBankAccountNumber, customerIfscCode, verifiedAt } =
        data ?? {};
      let formattedData = {
        "Account nuumber": customerBankAccountNumber,
        "IFSC code": customerIfscCode,
        "Verified at": dateConvertor(verifiedAt),
      };
      return formattedData;
    };

    const onRetryPayout = async (id, i) => {
      try {
        let tempLoading = [...isButtonLoading];
        tempLoading[i] = true;
        setButtonLoading(tempLoading);
        const res = await postCall_v2(
          "oms/payout/initiate-payouts",
          {
            payoutRequestDTOList: [{ id }],
          },
          "",
          () => {
            let tempLoading = [...isButtonLoading];
            tempLoading[i] = false;
            setButtonLoading(tempLoading);
            showToaster(`An error occured, please try again.`, error);
          },
        );
        if (res) {
          getPendingPayouts();
        }
      } catch (err) {
        console.error(err, `Please try again later`);
      } finally {
        let tempLoading = [...isButtonLoading];
        tempLoading[i] = false;
        setButtonLoading(tempLoading);
        showToaster(`Refund initiated`, success);
      }
    };

    const onRejectPayout = async () => {
      try {
        const { orderType, payoutId, payoutType } = rejectData;
        await postCall_v2("oms/payout/reject/shipmentPayout", {
          message: getValues("reason"),
          reference:
            orderType === FinancePayouts.orderTypes[0]
              ? getValidRefundPayoutId(getValidPayoutId(payoutId))
              : getValidPayoutId(payoutId),
          orderType,
          payoutType,
        });
        getPendingPayouts();
        const msg = `${
          payoutType === "REFUND" ? "Refund" : "Cashback"
        } rejected`;
        showToaster(msg, success);
      } catch (err) {
        showToaster(`Please try again later, refund failed`, error);
        console.error(err, `Please try again later`);
      } finally {
        onCloseModal();
      }
    };

    const handleChildCheckbox = (e, i, val) => {
      let tempSelectedData = [...selectedPayout];
      if (e.target.checked) {
        tempSelectedData.push(summary?.payoutSummaries[i]);
      } else {
        tempSelectedData = tempSelectedData.filter(
          (item) => item?.["payoutId"] !== val?.["payoutId"],
        );
      }
      passSelectedData(tempSelectedData);
    };

    const handleParentCheckbox = (e) => {
      let tempData = [];
      if (e.target.checked) {
        tempData = summary?.payoutSummaries.filter((ele) => {
          const isValidFilter =
            ele?.customerBankAccountVerificationDetails?.isVerified &&
            ele?.status !== PAYMENT_IN_PROGRESS &&
            ele?.ledgerBalance > ele?.amount; // validate, Filter out if ladgerBalance > amount
          return !!ele?.isNew
            ? isValidFilter && ele?.fundAccountDetails?.customerAccount
            : isValidFilter;
        });
      } else {
        tempData = [];
      }

      setAllChecked(e.target.checked);
      passSelectedData(tempData);
    };

    const getPayoutTooltipData = (data) => {
      let formattedData = {
        "Invoice amount": moneyFormat(
          data?.shipmentInvoiceDetails?.totalInvoicedAmount,
        ),
        "TCS on GST (1%) on Invoice": moneyFormat(data?.tcsInvoiceAmount),
        "TDS (1%) on Invoice": moneyFormat(data?.tdsInvoiceAmount),
        "JOPL commission for  seller": moneyFormat(data?.joplCommision),
        "Payable to seller": moneyFormat(data?.payableToSeller),
        "Payout amount  now": moneyFormat(data?.amount),
      };
      return formattedData;
    };

    const handleErrorToggle = useCallback((value) => {
      setShowErrorList((prev) => {
        if (prev.includes(value)) {
          return prev.filter((id) => value !== id);
        } else {
          return [...prev, value];
        }
      });
    }, []);

    // hook to expose function - which will unselect all selected items
    useImperativeHandle(
      ref,
      () => ({
        resetParentCheckBox: () => {
          setAllChecked(false);
        },
      }),
      [],
    );

    useEffect(() => {
      const errorList = [];
      summary?.payoutSummaries?.forEach((data) => {
        if (data?.status === PAYMENT_FAILED) {
          errorList.push(data?.payoutId);
        }
      });
      setShowErrorList(errorList);
    }, [summary]);

    const handleCheckBalance = useCallback((data) => {
      const encode = btoa(data?.toGstin);
      encode && navigation(`/ledger?buyer=${encode}&tab=buyer`);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * @description Validated checkbox is selected or not.
     * @param {*} Transaction Item
     * @returns true if selected else false
     */
    const getIsTxSelected = (data) => {
      const isCheckedItem = selectedPayout?.some(
        (item) => item?.payoutId === data?.payoutId,
      );
      return isCheckedItem;
    };

    const getIsCheckBoxDisabled = (data) => {
      const allowedDiff = Number(data.amount) - Number(data?.ledgerBalance);
      const isDisabled =
        data?.status === PAYMENT_IN_PROGRESS ||
        data?.ledgerBalance < 0 ||
        !(allowedDiff <= 1);
      if (!!data?.isNew) {
        return isDisabled || !data.fundAccountDetails?.customerAccount;
      }
      return isDisabled;
    };

    const showError = (data, msg) => {
      return (
        <TableCell
          colSpan={pendingRefundHeaders.length + 1}
          sx={{ textAlign: "center", padding: 0 }}
        >
          <TableRowWithError
            message={msg ?? data?.errorMessage}
            onClose={handleErrorToggle}
            id={data?.payoutId}
            key={data?.payoutId}
            length={pendingRefundHeaders.length + 1}
          />
          {data?.ledgerBalance < data.amount && (
            <TableRowWithError
              message={FUND_VALIDATION_ERROR}
              id={data?.payoutId}
              key={data?.payoutId}
              length={pendingRefundHeaders.length + 1}
              sx={{ marginTop: 1, marginBottom: 1 }}
            />
          )}
        </TableCell>
      );
    };

    return (
      <Box>
        <>
          <Paper>
            <TableContainer
              sx={{
                minWidth: 700,
                maxHeight: "calc(100vh - 120px)",
              }}
            >
              <Table stickyHeader>
                <TableHead style={{ borderRadius: "10px 10px 0 0" }}>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={isAllChecked}
                        onChange={(e) => handleParentCheckbox(e)}
                      />
                    </TableCell>
                    {pendingRefundHeaders.map((heading, i) => (
                      <TableCell key={i}>{heading}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {summary?.payoutSummaries?.length && !loading ? (
                    summary?.payoutSummaries?.map((data, i) => (
                      <>
                        <TableRow key={data?.payoutId}>
                          <TableCell>
                            <Checkbox
                              key={data?.payoutId}
                              checked={getIsTxSelected(data)}
                              disabled={getIsCheckBoxDisabled(data)}
                              onChange={(e) => handleChildCheckbox(e, i, data)}
                            />
                          </TableCell>
                          <TableCell>
                            <StatusChip status={data?.orderType} isOrderType />
                          </TableCell>
                          <TableCell style={{ maxWidth: 120 }}>
                            <>
                              <HyperLink title={data?.customerOrdNumber} />
                              <br />
                              <Typography fontSize={14}>
                                {data?.entityName}
                              </Typography>
                            </>
                          </TableCell>
                          <TableCell style={{ maxWidth: 120 }}>
                            {payoutTypes[data?.payoutTypeKey]}
                          </TableCell>
                          <TableCell>
                            {dateConvertor(data?.transactionCreatedDate)}
                          </TableCell>
                          <TableCell>
                            <>
                              {data?.shipmentInvoiceDetails?.invoiceDetails?.[0]
                                ?.invoiceDate ? (
                                <>
                                  <Typography fontSize={14}>
                                    {dateConvertor(
                                      data?.shipmentInvoiceDetails
                                        ?.invoiceDetails?.[0]?.invoiceDate,
                                    )}
                                    <br />
                                  </Typography>
                                  <Typography fontSize={14}>
                                    {dateConvertor(
                                      data?.shipmentInvoiceDetails
                                        ?.invoiceDetails?.[0]?.invoiceDueDate,
                                    )}
                                  </Typography>
                                </>
                              ) : (
                                <Typography fontSize={14}>-</Typography>
                              )}
                            </>
                          </TableCell>

                          <TableCell>
                            <ButtonV1
                              title={moneyFormat(data?.ledgerBalance)}
                              size="small"
                              variant="text"
                              onClick={() => handleCheckBalance(data)}
                            />
                          </TableCell>

                          <TableCell>
                            <Box display={"flex"} alignItems={"center"}>
                              {!!data?.amount && moneyFormat(data?.amount)}
                              {!!data?.amount &&
                                !["CASHBACK", "REFUND"].includes(
                                  data?.payoutTypeKey,
                                ) && (
                                  <HoverBlock
                                    LabelChildren={
                                      <InfoOutlined
                                        style={{ width: 16, marginLeft: 6 }}
                                        color={"primary"}
                                      />
                                    }
                                    data={getPayoutTooltipData(data)}
                                  />
                                )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box>
                              {!!data?.customerBankAccountVerificationDetails
                                ?.isVerified ? (
                                <>
                                  <HoverBlock
                                    LabelChildren={
                                      <Box>
                                        <StatusChip
                                          status={accountStatus.verified}
                                        />
                                      </Box>
                                    }
                                    data={getCustomerVerificationDetails(
                                      data?.customerBankAccountVerificationDetails,
                                    )}
                                  />
                                </>
                              ) : (
                                <>
                                  <StatusChip
                                    status={accountStatus.unverified}
                                  />
                                </>
                              )}
                            </Box>
                          </TableCell>

                          <TableCell>
                            <StatusChip
                              status={data?.nextStates}
                              isTooltip={true}
                            />
                          </TableCell>
                          <TableCell>
                            <Box style={{ textAlign: "center" }}>
                              {data?.shipmentInvoiceDetails?.invoiceComments ? (
                                <HoverBlock
                                  LabelChildren={
                                    <IconButton>
                                      <SvgIcon
                                        name={"chat-unread"}
                                        width={24}
                                        height={24}
                                      />
                                    </IconButton>
                                  }
                                  data={{
                                    "Invoice comments":
                                      data?.shipmentInvoiceDetails
                                        ?.invoiceComments,
                                  }}
                                />
                              ) : (
                                <IconButton>
                                  <SvgIcon
                                    name={"chat-read"}
                                    width={24}
                                    height={24}
                                  />
                                </IconButton>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box display={"flex"} alignItems={"center"}>
                              {!!(PAYMENT_FAILED === data.status) && (
                                <ButtonV1
                                  title={"Retry"}
                                  variant={"contained"}
                                  size={"small"}
                                  onClick={() =>
                                    onRetryPayout(data?.payoutId, i)
                                  }
                                />
                              )}

                              <ButtonV1
                                title={"Reject"}
                                variant={"text"}
                                size={"small"}
                                onClick={() => validateRejection(data)}
                                disabled={data?.status === PAYMENT_IN_PROGRESS}
                              />

                              {data?.status === PAYMENT_FAILED && (
                                <InfoOutlined
                                  onClick={() =>
                                    handleErrorToggle(data?.payoutId)
                                  }
                                  style={{ width: 16, marginLeft: 6 }}
                                />
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>

                        {!data?.isNew
                          ? showErrorList.includes(data?.payoutId) &&
                            showError(data)
                          : !data?.fundAccountDetails?.customerAccount &&
                            showError(data, "Customer account not found.")}
                      </>
                    ))
                  ) : loading ? (
                    <TableCell
                      colSpan={pendingRefundHeaders.length}
                      sx={{ textAlign: "center" }}
                    >
                      <Loader sx={{ marginTop: 0 }} />
                    </TableCell>
                  ) : (
                    <TableCell
                      colSpan={pendingRefundHeaders.length}
                      sx={{ textAlign: "center" }}
                    >
                      No data found
                    </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {!!summary?.payoutSummaries?.length && (
              <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                rowsPerPage={rowsPerPage}
                page={pageNumber}
                count={summary?.totalCount ?? 0}
                onPageChange={onPageChange}
                sx={{ display: "flex", flexDirection: "column-reverse" }}
                ActionsComponent={PaginationAction}
              />
            )}
          </Paper>
          <ModalBox open={isModalOpen} onCloseModal={onCloseModal} width="50%">
            <Typography fontWeight={"bold"} mb={4}>
              Reject {rejectData?.customerOrdNumber}
            </Typography>
            <Controller
              control={control}
              name={"reason"}
              render={({ field: { value, onChange } }) => (
                <TextField
                  multiline
                  inputProps={{
                    style: {
                      height: 104,
                    },
                  }}
                  sx={{
                    width: "100%",
                  }}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                marginTop: 4,
              }}
            >
              <ButtonV1
                variant="outlined"
                color="secondary"
                title="Close"
                size="small"
                onClick={onCloseModal}
                style={{ marginRight: 4 }}
              />
              <ButtonV1
                variant="contained"
                color="secondary"
                title="Confirm"
                disabled={!isDirty}
                onClick={onRejectPayout}
                size="small"
              />
            </Box>
          </ModalBox>
        </>
      </Box>
    );
  },
);

export default PayoutTable;
