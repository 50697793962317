import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import _debounce from "lodash/debounce";

import { SectionTitle } from "./CustomComponents";
import { ButtonV1, ModalBox, TextField, ViewOnly } from "components";
import theme from "themeProvider";
import {
  appendRupeeIcon,
  dateConvertor,
  moneyFormat,
  validateNull,
} from "utils";
import { postCall, getCall } from "services";
import {
  formattedBuyerRefundSummary,
  getBuyerRefundSummaryHeader,
} from "./makeData";
import {
  success,
  error,
  refund,
  disbursementState,
  CASHBACK,
} from "./constants";

const RowView = ({ label, children }) => {
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: 16,
      }}
    >
      <Typography fontWeight={"bold"} style={{ marginRight: 16, width: "55%" }}>
        {label}
      </Typography>
      <Box>{children}</Box>
    </Box>
  );
};

const ColumnView = ({ label, value }) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography
          fontWeight={"bold"}
          fontSize={14}
          style={{ marginRight: 16, width: "55%", whiteSpace: "nowrap" }}
        >
          {label}
        </Typography>
        <Typography fontSize={14}>{value}</Typography>
      </Box>
    </Box>
  );
};

const BuyerRefundSummary = ({
  buyerRefundSummary,
  customerOrderId,
  refetchSummaryData,
  onClickStatus,
  onInitiateRefund,
}) => {
  const { id } = useParams();

  const [isModalOpen, setModalOpen] = useState(false);
  const [ledgerRefund, setLedgerRefund] = useState("0");
  const [customerBalance, setCustomerBalance] = useState();
  const [totalRefund, setTotalRefund] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [initiateRefundData, setInitiateRefundData] = useState({});

  const orderDetails = useSelector((state) => state.orderDetails);
  const gstin = orderDetails?.buyerDetails?.gstin;
  const buyerRefundSummaryHeader =
    getBuyerRefundSummaryHeader(buyerRefundSummary);
  const buyerRefundSummaryFormatted =
    formattedBuyerRefundSummary(buyerRefundSummary);

  const getCustomerBalance = async (gstin) => {
    if (gstin) {
      const data = await getCall(`/oms/payment/balance/${gstin}`);
      setCustomerBalance(data?.balance);
    }
  };

  useEffect(() => {
    const gstin = orderDetails?.buyerDetails?.gstin;
    getCustomerBalance(gstin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const payableAmount = initiateRefundData?.payable?.replaceAll(",", ""); // initiateRefundData?.payable value is in moneyFormat so removing commas
    let total = Number(ledgerRefund ?? 0) + Number(payableAmount ?? 0);
    setTotalRefund(moneyFormat(total));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ledgerRefund, initiateRefundData]);

  const checkPaymentMethod = async () => {
    const paymentMethod = await getCall(
      `/oms/payment/recordedTransaction?customerOrderNumber=${id}`,
    );
    let paymentType = "Net-banking";
    if (paymentMethod?.recordedTransaction) {
      const res = paymentMethod?.recordedTransaction.some(
        (ele) => ele?.transactionType !== "NetBanking",
      );
      if (res) paymentType = "NEFT";
    }
    return paymentType;
  };

  const onInitiateBuyerRefund = async () => {
    setErrorMessage("");
    const paymentMethod = await checkPaymentMethod();
    const requestBuyerRefund = await postCall(
      `/oms/payout/buyer-refund`,
      {
        customerOrderId,
        amountRequestedFromLedger: ledgerRefund,
        paymentMethod,
        gstin,
      },
      {},
      (err) => {
        refetchSummaryData(error, err?.data?.detail);
      },
    );
    if (requestBuyerRefund) {
      refetchSummaryData(success, requestBuyerRefund?.errorMessage);
      setModalOpen(false);
    }
  };

  const onInitiateBuyerCashback = async () => {
    setErrorMessage("");
    const paymentMethod = await checkPaymentMethod();
    const requestBuyerCashback = await postCall(
      `/oms/payout/buyer-cashback`,
      {
        customerOrderId,
        paymentMethod,
        gstin,
      },
      {},
      (err) => {
        refetchSummaryData(error, err?.data?.detail);
      },
    );
    if (requestBuyerCashback) {
      refetchSummaryData(success, requestBuyerCashback?.errorMessage);
      setModalOpen(false);
    }
  };

  const handleModal = (refundData) => {
    setInitiateRefundData(refundData);
    setModalOpen(!isModalOpen);
  };

  const handleCloseModal = () => {
    setModalOpen(!isModalOpen);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebounce = useCallback(
    _debounce((ledgerRefundVal, customerBal) => {
      if (ledgerRefundVal > customerBal) {
        setErrorMessage("Entered amount is greater than ledger balance");
      } else {
        setErrorMessage("");
      }
    }, 1000),
    [],
  );

  const handleChange = (value) => {
    setLedgerRefund(value);
    handleDebounce(value, customerBalance);
  };

  const handleSignedValues = (e) => {
    ["-", "+", "e", "E"].includes(e.key) && e.preventDefault();
  };

  /**
   *
   * @param {*} txData
   * @description fn to disable payment cta
   */
  const checkPaymentInitiation = (txData) => {
    if (
      !txData?.allowRefund ||
      txData?.razorpayId ||
      txData.state === disbursementState.in_process
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    !!buyerRefundSummary && (
      <Box style={{ marginBottom: 24 }}>
        <SectionTitle title="Buyer refund summary" />
        <Box
          style={{
            boxShadow: `10px 0px 9px ${theme.palette.grey[400]}`,
            borderRadius: 5,
          }}
        >
          <Box
            style={{
              background: "#e9f0ff",
              borderRadius: "8px 8px 0 0",
              padding: 14,
            }}
          >
            <Grid container alignItems={"center"}>
              {buyerRefundSummaryHeader.map((item, i) => (
                <Grid item xs={2.4} key={i}>
                  <ColumnView
                    label={item?.label}
                    value={validateNull(item.value)}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box
            style={{
              padding: 10,
              border: 1,
              borderTop: 0,
              borderStyle: "solid",
              borderRadius: "0 0 8px 8px",
              borderColor: theme.palette.grey[100],
            }}
          >
            {buyerRefundSummaryFormatted.map(
              (ele, i) =>
                !!ele?.payable && (
                  <Box
                    mx={3}
                    key={i}
                    my={3}
                    px={3}
                    py={3}
                    style={{
                      border: 1,
                      borderStyle: "solid",
                      borderRadius: 8,
                      display: "flex",
                      justifyContent: "space-between",
                      borderColor: theme.palette.grey[400],
                    }}
                  >
                    <Grid container alignItems={"center"} width={"80%"}>
                      <Grid item xs={1.7}>
                        <ColumnView
                          label={"Due date"}
                          value={dateConvertor(ele?.dueDate) ?? "-"}
                        />
                      </Grid>
                      <Grid item xs={1.7}>
                        <ColumnView label={"Type"} value={ele?.type ?? "-"} />
                      </Grid>
                      <Grid item xs={1.7}>
                        <ColumnView
                          label={"Payable"}
                          value={appendRupeeIcon(ele?.payable) ?? "-"}
                        />
                      </Grid>
                      <Grid item xs={1.7}>
                        <ColumnView
                          label={"Status"}
                          value={ele?.status ?? "-"}
                        />
                      </Grid>
                      <Grid item xs={1.7}>
                        <ColumnView
                          label={"Timestamp"}
                          value={
                            ele?.timestamp ? dateConvertor(ele?.timestamp) : "-"
                          }
                        />
                      </Grid>
                      <Grid item xs={1.8}>
                        <ColumnView
                          label={"Razorpay ID"}
                          value={validateNull(ele.razorpayId)}
                        />
                      </Grid>
                      <Grid item xs={1.7}>
                        <ColumnView
                          label={"UTR No"}
                          value={validateNull(ele.utr)}
                        />
                      </Grid>
                    </Grid>
                    <ButtonV1
                      variant="text"
                      title={ele.status}
                      onClick={() => onClickStatus(ele)}
                    />
                    <ButtonV1
                      style={{ padding: 0, paddingLeft: 2, paddingRight: 2 }}
                      variant="text"
                      disabled={ele?.dueDate || ele?.type === CASHBACK}
                      title={"Create refund"}
                      onClick={onInitiateRefund}
                    />
                  </Box>
                ),
            )}
          </Box>
        </Box>
        <ModalBox
          open={isModalOpen}
          onCloseModal={handleCloseModal}
          width={initiateRefundData?.type === refund ? "50%" : "20%"}
        >
          {initiateRefundData?.type === refund ? (
            <>
              <Typography variant="h2" mb={5} style={{ textAlign: "center" }}>
                Initiate refund
              </Typography>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                width={"100%"}
              >
                <Box
                  style={{
                    border: 1,
                    borderColor: theme.palette.grey["900"],
                    borderStyle: "solid",
                    padding: 40,
                    borderRadius: 6,
                    width: "65%",
                  }}
                  mb={4}
                >
                  <RowView label={"Ledger refund : "}>
                    <TextField
                      value={ledgerRefund}
                      type={"number"}
                      highlightError={errorMessage}
                      onKeyDown={handleSignedValues}
                      onChange={(e) => handleChange(e.target.value)}
                      customPadding={8}
                    />
                  </RowView>
                  <Typography
                    style={{
                      visibility: errorMessage ? "visible" : "hidden",
                      marginBottom: 10,
                      marginTop: -10,
                    }}
                    color={theme.palette.error.dark}
                  >
                    {errorMessage}
                  </Typography>
                  <RowView label={"Ledger balance : "}>
                    <ViewOnly value={moneyFormat(customerBalance)} />
                  </RowView>

                  <RowView label={"Order refund amount : "}>
                    <ViewOnly
                      value={appendRupeeIcon(initiateRefundData?.payable ?? 0)}
                    />
                  </RowView>
                  <RowView label={"Total refund : "}>
                    <ViewOnly value={totalRefund} />
                  </RowView>
                </Box>
                <Box display={"flex"} justifyContent={"center"}>
                  <ButtonV1
                    title={"Submit"}
                    onClick={onInitiateBuyerRefund}
                    disabled={errorMessage || !ledgerRefund}
                  />
                </Box>
              </Box>
            </>
          ) : (
            <Box p={10}>
              <Typography variant="h3" mb={5} style={{ textAlign: "center" }}>
                Are you sure you want to initiate cashback?
              </Typography>
              <Box display={"flex"} justifyContent={"center"}>
                <ButtonV1
                  title={"Confirm"}
                  style={{ marginRight: 10 }}
                  size="small"
                  onClick={onInitiateBuyerCashback}
                />
                <ButtonV1
                  title={"Close"}
                  size="small"
                  variant={"outlined"}
                  onClick={handleModal}
                />
              </Box>
            </Box>
          )}
        </ModalBox>
      </Box>
    )
  );
};

export default BuyerRefundSummary;
