import { useState } from "react";
import { Box, Typography, Divider } from "@mui/material";
import { styled } from "@mui/system";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const LineSeperator = styled(Divider)({
  borderRightWidth: 2.5,
  borderRightStyle: "solid",
});

const FlexBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flex: 1,
  gap: 8,
});

const FlexTypography = styled(Typography)({
  display: "flex",
  flex: 1,
  margin: 0,
  "&.MuiTypography-h6": {
    fontWeight: "600",
    fontSize: "1rem",
  },
});

const TootTip = styled(Box)({
  boxShadow: "0 0 4px 1px #80808087",
  background: "white",
  padding: 6,
  minWidth: 200,
  zIndex: 2,
  borderRadius: 8,
  transform: "translateX(-45%)",
  width: "fit-content",
});
const BankDetailTip = ({ bankInfo, vanEmail }) => {
  const [toolTip, setToolTip] = useState(false);
  return (
    <Box
      onMouseEnter={() => setToolTip(true)}
      onMouseLeave={() => setToolTip(false)}
    >
      <Box>{bankInfo.bankAccountName ?? "-"}</Box>
      {bankInfo.bankAccountNumber && toolTip && (
        <Box position={"absolute"}>
          <ArrowDropUpIcon sx={{ marginBottom: "-6px" }} />
          <TootTip>
            {!!bankInfo?.bankAccountNumber && (
              <>
                <FlexBox>
                  <FlexTypography>Account no.</FlexTypography>
                  <FlexTypography variant="h6">
                    {bankInfo.bankAccountNumber}
                  </FlexTypography>
                </FlexBox>
              </>
            )}
            {!!bankInfo?.ifscCode && (
              <>
                <LineSeperator />
                <FlexBox>
                  <FlexTypography>IFSC code</FlexTypography>
                  <FlexTypography variant="h6">
                    {bankInfo.ifscCode}
                  </FlexTypography>
                </FlexBox>
              </>
            )}
            {!!bankInfo?.bankName && (
              <>
                <LineSeperator />
                <FlexBox>
                  <FlexTypography>Bank name</FlexTypography>
                  <FlexTypography variant="h6">
                    {bankInfo.bankName}
                  </FlexTypography>
                </FlexBox>
              </>
            )}
            {!!vanEmail && (
              <>
                <LineSeperator />
                <FlexBox>
                  <FlexTypography>VAN email</FlexTypography>
                  <FlexTypography variant="h6">{vanEmail}</FlexTypography>
                </FlexBox>
              </>
            )}
          </TootTip>
        </Box>
      )}
    </Box>
  );
};

export default BankDetailTip;
