export const getOtherServicesModel = (otherCharges) => {
  if (!otherCharges && !Object.keys(otherCharges).length) return;

  const {
    processingCharge,
    gstProcessingCharge,
    processingChargeTax,
    freightCharge,
    gstFreightCharge,
    freightChargeTax,
    loadingCharge,
    gstLoadingCharge,
    loadingChargeTax,
    otherCharge,
    gstOtherCharge,
    otherChargeTax,
  } = otherCharges;

  const otherChargeModel = {
    processingCharge: {
      processingCharge: processingCharge ?? 0,
      gst: gstProcessingCharge ?? 0,
      tax: processingChargeTax ?? 0,
      displayName: "Processing charge",
    },
    freightCharge: {
      freightCharge: freightCharge ?? 0,
      gst: gstFreightCharge ?? 0,
      tax: freightChargeTax ?? 0,
      displayName: "Freight charge",
    },
    loadingCharge: {
      loadingCharge: loadingCharge ?? 0,
      gst: gstLoadingCharge ?? 0,
      tax: loadingChargeTax ?? 0,
      displayName: "Loading charge",
    },
    otherCharge: {
      otherCharge: otherCharge ?? 0,
      gst: gstOtherCharge ?? 0,
      tax: otherChargeTax ?? 0,
      displayName: "Other charge",
    },
  };

  return otherChargeModel;
};

export const getZohoOtherServicesModel = (otherCharges) => {
  if (!otherCharges && otherCharges?.length) return;

  // Initialize an object to store grouped data
  const groupedData = {};

  // Group otherCharges by 'chargeDescriptionType'
  otherCharges &&
    otherCharges.forEach((item) => {
      if (!groupedData[item.chargeDescriptionType]) {
        groupedData[item.chargeDescriptionType] = [];
      }
      groupedData[item.chargeDescriptionType].push(item);
    });

  // Convert groupedData object into formatted output
  const formattedData = Object.keys(groupedData).map((key) => {
    return {
      [key]: groupedData[key],
      description: groupedData[key]?.[0].description,
      key: groupedData[key]?.[0].chargeDescriptionType,
    };
  });
  return formattedData;
};
