import { Typography, Box } from "@mui/material";

import theme from "themeProvider";
import { dateConvertor, timeConvertor } from "utils";

const PaymentChip = ({ paymentStatus, paymentCompletionDate }) => {
  const getPaymentStatusColor = (status) => {
    let highlight = {
      Pending: "red",
      Full: "green",
      default: "orange",
    };
    return highlight[status] || highlight["default"];
  };

  return (
    <Box display={"flex"} alignItems={"center"}>
      <Box
        style={{
          ...(paymentStatus === "Full" && {
            backgroundColor: theme.palette.green.light,
            borderColor: theme.palette.green.dark,
            borderStyle: "solid",
            paddingLeft: 4,
            paddingRight: 4,
            marginRight: 4,
          }),
        }}
      >
        <Typography
          fontWeight={"600"}
          style={{
            color: getPaymentStatusColor(paymentStatus),
          }}
        >
          {paymentStatus ?? "-"}
        </Typography>
      </Box>
      {!!(paymentStatus === "Full" && paymentCompletionDate) && (
        <Typography fontWeight={"bold"}>
          on {dateConvertor(paymentCompletionDate)},
          {timeConvertor(paymentCompletionDate, +"Z")}
        </Typography>
      )}
    </Box>
  );
};

export default PaymentChip;
