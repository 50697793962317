import { SvgIcon } from "components";
import { Box, Typography, useTheme } from "@mui/material";

const Export = ({ onExport }) => {
  const theme = useTheme();

  return (
    <Box
      display={"flex"}
      pb={3}
      sx={{
        gap: 2,
        cursor: "pointer",
      }}
      onClick={onExport}
    >
      <Typography sx={{ color: theme.palette.primary.main, fontWeight: 600 }}>
        Export
      </Typography>
      <SvgIcon
        name={"export"}
        height={20}
        width={20}
        style={{ color: theme.palette.primary.main }}
      />
    </Box>
  );
};

export default Export;
