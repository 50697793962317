import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  useTheme,
} from "@mui/material";

import { Loader, ButtonV1 } from "components";

import { searchConstants } from "./constants";

const { CUSTOMER_SEARCH_HEADING } = searchConstants;

const PaymentTable = ({ isLoading, data = [], onVerify }) => {
  const theme = useTheme();

  return (
    <Paper>
      <TableContainer
        sx={{
          minWidth: 700,
          maxHeight: "calc(100vh - 120px)",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {CUSTOMER_SEARCH_HEADING.map((heading, index) => (
                <TableCell key={`${heading}${index}`}>{heading}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={CUSTOMER_SEARCH_HEADING.length}>
                  <Loader sx={{ marginTop: 0 }} />
                </TableCell>
              </TableRow>
            )}
            {!isLoading && !data.length && (
              <TableRow>
                <TableCell colSpan={CUSTOMER_SEARCH_HEADING.length}>
                  <Typography textAlign={"center"}>
                    No Customer Found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              !!data.length &&
              data.map((row, index) => (
                <TableRow key={row.email + index} style={{ cursor: "default" }}>
                  <TableCell>{row?.companyName}</TableCell>
                  <TableCell>{row?.gstin}</TableCell>
                  <TableCell> {row?.pan}</TableCell>
                  <TableCell>{row?.email}</TableCell>
                  <TableCell>{row?.mobileNumber.number}</TableCell>

                  <TableCell>
                    {row?.pan ?? row?.pan ? (
                      <ButtonV1
                        variant="outlined"
                        title={"View Credit Info"}
                        onClick={(e) =>
                          onVerify && onVerify(row?.pan, e, row?.gstin)
                        }
                      />
                    ) : (
                      <Typography
                        color={theme.palette.text.disabled}
                        fontWeight={600}
                        textAlign="center"
                      >
                        Pan Details Not Found
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PaymentTable;
