import { FileDownloadOutlined, InfoOutlined } from "@mui/icons-material";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { TooltipLabel } from "components";

import { getSubstring, validateNull } from "utils";
import { useDownload } from "hooks";
import { FinancePayouts } from "../constants";

const {
  financeTab: { pending },
} = FinancePayouts;

const DocumentAndInvoiceColumn = ({ ele }) => {
  const { downloadDocument } = useDownload();
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get("tab");

  const handleDownloadFile = async (data) => {
    const id = currentTab === pending ? data?.payoutId : data?.id;
    downloadDocument(
      `oms/off-oms-payout/document/credit-and-sales-note/get?payoutId=${id}`,
      data?.documentId,
    );
  };
  const theme = useTheme();
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography fontSize={14}>{ele?.documentId ?? "-"}</Typography>
        {ele?.filePath && (
          <IconButton
            disableRipple={true}
            onClick={() => handleDownloadFile(ele)}
          >
            <FileDownloadOutlined
              fontSize="small"
              sx={{ color: theme.palette.secondary.main }}
            />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {ele?.originalInvoiceId?.length ? (
          <TooltipLabel
            placement="right"
            title={
              <Box>
                <Typography
                  style={{
                    color: theme.palette.text.secondary,
                    fontSize: 10,
                  }}
                >
                  Original invoices
                </Typography>
                {ele?.originalInvoiceId?.map((invoiceId) => (
                  <Typography
                    style={{
                      color: theme.palette.text.secondary,
                      fontSize: 14,
                    }}
                  >
                    {invoiceId}
                  </Typography>
                ))}
              </Box>
            }
            labelChildren={
              <Box style={{ display: "flex" }}>
                <Typography fontSize={14}>
                  {validateNull(getSubstring(ele?.originalInvoiceId?.[0], 25))}
                </Typography>
                <InfoOutlined
                  sx={{
                    alignSelf: "center",
                    height: "0.8rem",
                    width: "0.8rem",
                    marginLeft: 1,
                  }}
                />
              </Box>
            }
          />
        ) : (
          "-"
        )}
      </Box>
    </Box>
  );
};

export default DocumentAndInvoiceColumn;
