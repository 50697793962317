import * as Yup from "yup";
import { INTERNAL_FUND_TRANSFER } from "../constants";

const { manualUpload, yupName } = INTERNAL_FUND_TRANSFER;

const internalFundValidationSchema = Yup.object().shape({
  [yupName]: Yup.array().of(
    Yup.object().shape({
      [manualUpload.fromGstin]: Yup.string()
        .required("Please select the from account")
        .length(15, "Please enter valid gstin"),
      [manualUpload.toGstin]: Yup.string()
        .required("Please select the to account")
        .length(15, "Please enter valid gstin"),
      [manualUpload.ledgerDescription]: Yup.string().required(
        "Please add ledger description",
      ),
      [manualUpload.advice]: Yup.string().required("Please add advice details"),
      [manualUpload.amount]: Yup.number()
        .required("Amount is required")
        .typeError("Please add valid amount"),
    }),
  ),
});

export { internalFundValidationSchema };
