import { Box, Tooltip, useTheme } from "@mui/material";

const IconTooltip = ({ label, icon, children }) => {
  const theme = useTheme();
  return (
    <Box display={"flex"} alignItems={"center"} gap={2}>
      {label}
      <Tooltip
        PopperProps={{
          disablePortal: true,
          sx: {
            height: 10,
            "& .MuiTooltip-tooltip": {
              border: 1,
              borderColor: theme.palette.grey[400],
              color: theme.palette.grey["A300"],
              backgroundColor: "white",
              fontSize: 14,
              textAlign: "left",
              maxWidth: 600,
              "& .MuiTooltip-arrow::before": {
                color: "white",
                border: 1,
                borderColor: theme.palette.grey[400],
              },
            },
          },
        }}
        arrow
        placement="bottom"
        title={children}
      >
        {icon}
      </Tooltip>
    </Box>
  );
};

export default IconTooltip;
