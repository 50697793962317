import { useCallback, useState } from "react";
import { Divider, Typography, Box, TableCell } from "@mui/material";

import { StyledTableRow, ColumnTitle } from "CustomStyledComponents";
import { TableWrapper, ButtonV1, Timestamp, ModalBox } from "components";
import { CustomViewOnly, FlexBox, GridSkeleton } from "./CustomComponents";
import SellerDisbursement from "./SellerDisbursement";

import makeData from "./makeData";
import { postCall } from "services";
import { success, error } from "constants";
import { appendRupeeIcon, moneyFormat } from "utils";
import { useToaster } from "hooks";

const ShipmentPayoutSummary = ({ shipmentSummary, refetchShipmentSummary }) => {
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [shipmentDisbursementModal, setShipmentDisbursementOpen] =
    useState(false);
  const [modalData, setModalData] = useState([]);
  const triggerToaster = useToaster();

  const onCloseShipmentDisbursement = () => {
    setShipmentDisbursementOpen(false);
  };
  const openConfirmationModal = (row) => {
    makeModalData(row);
    setShipmentDisbursementOpen(true);
  };

  const makeModalData = (row) => {
    let data = {
      shipmentId: shipmentSummary?.shipmentId,
      shipmentNumber: shipmentSummary?.shipmentNumber,
      sellerOrdNumber: shipmentSummary?.sellerOrdNumber,
      amount: row?.amount?.displayAmount,
      payoutTypeChargeCode: row?.payoutTypeChargeCode,
      payoutChargeDisplayName: row?.payoutChargeDisplayName,
      fundAccountDetails: shipmentSummary?.fundAccountDetails,
      selectedFundAccountId:
        row?.selectedFundAccountId ||
        shipmentSummary?.defaultFundAccountId ||
        shipmentSummary?.fundAccountDetails?.sellerAccount,
    };
    setModalData(data);
  };

  const { shipmentSummaryData } = makeData(
    shipmentSummary,
    shipmentSummary?.stateOfDisbursement?.state,
  );

  /**
   * @description - verifies payout calculated is same as per BE value
   */
  const validatePayoutCalculation = useCallback(
    (value, fieldName) => {
      return (
        <Box display={"flex"} sx={{ gap: 2, alignItems: "center" }}>
          <Typography>{value}</Typography>
        </Box>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shipmentSummary],
  );

  const payoutHeaders = [
    "Due date",
    "Type of payout",
    "Payable",
    "Status",
    "Timestamp",
    "Razorpay ID",
    "Action",
  ];

  const handleLoading = (val) => {
    setButtonLoading(val);
  };

  const handlePayout = async () => {
    handleLoading(true);
    let invoice = "Invoice";
    let res;
    const {
      payoutTypeChargeCode,
      payoutChargeDisplayName,
      selectedFundAccountId,
    } = modalData;

    if (payoutChargeDisplayName?.includes(invoice)) {
      res = await postCall(
        "oms/payout/shipments",
        {
          requestList: [
            {
              id: payoutTypeChargeCode,
              fundAccountId: selectedFundAccountId,
            },
          ],
        },
        "",
        (err) =>
          triggerToaster(
            err?.data?.detail ?? `An error occured, please try again.`,
            error,
          ),
      );
    } else {
      res = await postCall(
        "oms/payout/PtAndHold",
        {
          reference: payoutTypeChargeCode,
          fundAccountId: selectedFundAccountId,
        },
        "",
        (err) =>
          triggerToaster(
            err?.data?.detail ?? `An error occured, please try again.`,
            error,
          ),
      );
    }
    if (res) {
      triggerToaster(res?.errorMessage ?? `Payout initiated`, success);
      refetchShipmentSummary();
    }
    handleLoading(false);
    setShipmentDisbursementOpen(false);
  };

  const handleRadioButton = useCallback(
    (e) => {
      const cloneModalData = { ...modalData };
      cloneModalData.selectedFundAccountId = e.target.value;
      setModalData(cloneModalData);
    },
    [modalData],
  );

  return (
    <>
      <FlexBox>
        <GridSkeleton
          firstChild={
            <CustomViewOnly
              label={shipmentSummaryData[0].label}
              value={shipmentSummaryData[0].value}
            />
          }
          secondChild={
            <CustomViewOnly
              label={shipmentSummaryData[1].label}
              value={validatePayoutCalculation(
                shipmentSummaryData[1].value,
                "TCS",
              )}
            />
          }
          thirdChild={
            <CustomViewOnly
              label={shipmentSummaryData[2].label}
              value={validatePayoutCalculation(
                shipmentSummaryData[2].value,
                "TDS",
              )}
            />
          }
        />
        <GridSkeleton
          firstChild={
            <CustomViewOnly
              label={shipmentSummaryData[3].label}
              value={shipmentSummaryData[3].value}
            />
          }
          secondChild={
            <CustomViewOnly
              label={shipmentSummaryData[4].label}
              value={shipmentSummaryData[4].value}
            />
          }
          thirdChild={
            <CustomViewOnly
              label={shipmentSummaryData[5].label}
              value={validatePayoutCalculation(
                shipmentSummaryData[5].value,
                "payable",
              )}
            />
          }
        />
        <GridSkeleton
          firstChild={
            <CustomViewOnly
              label={shipmentSummaryData[6].label}
              value={shipmentSummaryData[6].value}
            />
          }
          secondChild={
            <CustomViewOnly
              label={shipmentSummaryData?.[7]?.label}
              value={shipmentSummaryData?.[7]?.value}
            />
          }
          thirdChild={
            <CustomViewOnly
              label={shipmentSummaryData?.[8]?.label}
              value={shipmentSummaryData?.[8]?.value}
            />
          }
        />
      </FlexBox>
      <Divider orientation="horizontal" style={{ marginBottom: 32 }} />
      <Box marginBottom={"2rem"}>
        <GridSkeleton
          firstChild={
            <Box mt={9}>
              <CustomViewOnly
                label={"Positive tolerance"}
                value={appendRupeeIcon(
                  shipmentSummary?.shipmentPositiveTolerancePendingAmt
                    ?.displayAmount ?? 0,
                )}
              />
            </Box>
          }
          secondChild={
            <>
              <Typography
                variant="body1"
                style={{ fontWeight: "600", marginBottom: 12 }}
              >
                Payable to hold
              </Typography>
              <CustomViewOnly
                label={"Quality issues"}
                value={moneyFormat(
                  shipmentSummary?.shipmentInvoiceDetails
                    ?.invoicePayoutHoldAmount ?? 0,
                )}
              />
            </>
          }
          thirdChild={
            !!shipmentSummary?.shipmentInvoiceDetails?.invoiceComments && (
              <CustomViewOnly
                label={"Invoice comments"}
                value={shipmentSummary?.shipmentInvoiceDetails?.invoiceComments}
                rest={{ width: "90%" }}
              />
            )
          }
        />
      </Box>

      <TableWrapper
        tableHeader={payoutHeaders.map((item) => {
          return (
            <TableCell key={item}>
              <ColumnTitle>{item}</ColumnTitle>
            </TableCell>
          );
        })}
        tableBody={shipmentSummary?.payoutTypeCharges?.map((row) => (
          <StyledTableRow
            key={row.payoutTypeChargeCode}
            style={{ cursor: "default" }}
          >
            <TableCell>
              <Timestamp timeStamp={row?.dueDate} showTime={false} />
            </TableCell>
            <TableCell>{row?.payoutChargeDisplayName}</TableCell>
            <TableCell>{appendRupeeIcon(row?.amount?.displayAmount)}</TableCell>
            <TableCell>{row?.currentState}</TableCell>
            <TableCell>
              {row?.transferId ? (
                <Timestamp timeStamp={row?.whenUpdated} />
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell>{row?.transferId ?? "-"}</TableCell>
            <TableCell>
              <ButtonV1
                onClick={() => openConfirmationModal(row)}
                disabled={true}
                title="Initiate payout"
                variant={"text"}
                style={{ padding: 0 }}
              />
            </TableCell>
          </StyledTableRow>
        ))}
      />
      <ModalBox
        open={shipmentDisbursementModal}
        onCloseModal={onCloseShipmentDisbursement}
        width="50%"
      >
        <SellerDisbursement
          data={modalData}
          updateLatestData={refetchShipmentSummary}
          onInitiateDisbursement={handlePayout}
          loading={isButtonLoading}
          handleRadioButton={handleRadioButton}
        />
      </ModalBox>
    </>
  );
};

export default ShipmentPayoutSummary;
