import {
  TableCell,
  Box,
  Typography,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import PropTypes from "prop-types";

import { StyledTableRow, ColumnTitle } from "CustomStyledComponents";

import { moneyFormat, validateNull } from "utils";

import { FinancePayouts } from "../../constants";

const { INTERNAL_DISBURSEMENT_HEADERS } = FinancePayouts;

const InternalFundDisbursement = ({ data }) => {
  return (
    <Box mb={4}>
      <Typography fontWeight={"bold"} textAlign={"center"} mb={4}>
        Do you want to confirm the payout ?
      </Typography>
      {!!data && (
        <Box mb={6}>
          <TableContainer sx={{ maxHeight: 420 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {INTERNAL_DISBURSEMENT_HEADERS.map((item) => (
                    <TableCell key={item}>
                      <ColumnTitle>{item}</ColumnTitle>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((ele) => (
                  <StyledTableRow key={ele?.id}>
                    <TableCell>
                      {validateNull(ele?.fromCompanyData?.gstin)}
                    </TableCell>
                    <TableCell>
                      {validateNull(ele?.toCompanyData?.gstin)}
                    </TableCell>
                    <TableCell>
                      {validateNull(moneyFormat(ele?.amount))}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default InternalFundDisbursement;

InternalFundDisbursement.prototype = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      fromCompanyData: PropTypes.objectOf(
        PropTypes.shape({ gstin: PropTypes.string }),
      ),
      toCompanyData: PropTypes.objectOf(
        PropTypes.shape({ gstin: PropTypes.string }),
      ),
      amount: PropTypes.number,
    }),
  ),
};
