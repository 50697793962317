import { INTERNAL_FUND_TRANSFER } from "../constants";

const { manualUpload } = INTERNAL_FUND_TRANSFER;

export const ERROR_MSG = Object.freeze({
  [manualUpload.fromGstin]: "Please enter from Gstin",
  [manualUpload.toGstin]: "Please enter to Gstin",
  [manualUpload.advice]: "Please add advice details",
  [manualUpload.ledgerDescription]: "Please add ledger description",
  [manualUpload.amount]: "Amount is required",
});
