import { useRef, useState } from "react";
import { Typography, Box, useTheme } from "@mui/material";

import { SearchBar } from "components";
import LedgerDetails from "./LedgerDetails";
import NoCustomer from "./components/NoCustomer";

import { getCall_v2 } from "services";

const SearchResultBox = ({ companyName, gstin }) => {
  const theme = useTheme();

  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      gap={1}
      minWidth={300}
    >
      <Typography sx={{ fontSize: 14, color: theme.palette.grey["A300"] }}>
        {companyName}
      </Typography>
      <Typography variant="body2" sx={{ color: theme.palette.grey["1000"] }}>
        {gstin}
      </Typography>
    </Box>
  );
};

const CustomerLedger = () => {
  const [searchDetails, setSearchDetails] = useState();
  const filterRef = useRef();
  const theme = useTheme();

  /**
   * @param {*} searchInput
   * @returns a list of data matching with search input
   */
  const getSearchResponse = async (searchInput) => {
    try {
      const res = await getCall_v2(
        `/joms/api/external/v1/customer-details-by-reference/${searchInput}`,
      );
      return res?.data?.data;
    } catch (err) {
      console.error(`Error in fetching search results, ${err}`);
    }
  };

  /**
   * @param {*} data
   * @description save search results in state
   */
  const passValue = (data) => setSearchDetails(data);

  return (
    <Box>
      <Box
        sx={{
          border: `1px solid ${theme.palette.grey["400"]}`,
          padding: 4,
          borderRadius: 1,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box>
          <Typography
            mb={2}
            sx={{ color: theme.palette.grey["A300"], fontSize: 14 }}
          >
            Select customer
          </Typography>
          <SearchBar
            style={{ width: 310 }}
            resolver={getSearchResponse}
            displayAttribute="companyName"
            onItemSelect={passValue}
            renderItem={SearchResultBox}
            placeholder={"Search here"}
            ref={filterRef}
            customListStyle={{
              border: 0,
              boxShadow: `0 0 12px 6px ${theme.palette.grey["400"]}`,
            }}
          />
        </Box>
      </Box>
      {searchDetails ? (
        <LedgerDetails selectedCustomer={searchDetails} />
      ) : (
        <NoCustomer />
      )}
    </Box>
  );
};

export default CustomerLedger;
