import {
  Box,
  Divider,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Loader, PaginationAction } from "components";
import { FinancePayouts } from "pages/FinancePayout/constants";

import { moneyFormat, validateNull } from "utils";
import HoverModal from "../components/HoverModal";
import { MoreVert } from "@mui/icons-material";
import { INVOICE_SELLER_PAYOUT } from "../InvoiceSellerPayout/constant";

const { pendingInternalFundsHeaders } = FinancePayouts;

const CommentBox = ({ comment }) => {
  return (
    <HoverModal
      buttonIcon={
        <>
          {comment ? (
            <object
              data={`/assets/chat-unread.png`}
              width={24}
              alt="Group icon"
              aria-label="group icon"
            />
          ) : (
            <object
              data={`/assets/chat-read.png`}
              width={24}
              alt="Group icon"
              aria-label="group icon"
            />
          )}
        </>
      }
    >
      <Box style={{ padding: 10 }}>
        <Typography>Invoice comments</Typography>
        <TextField
          size="small"
          value={comment}
          style={{ marginTop: 8 }}
          inputProps={{ maxLength: 150 }}
        />
      </Box>
    </HoverModal>
  );
};

const PendingTransaction = ({
  editingId,
  data,
  onClickMenu,
  isFetching,
  onPageChange,
  page,
  rowsPerPage,
}) => {
  const theme = useTheme();
  return (
    <>
      <Typography style={{ fontWeight: 600 }}>Pending transactions</Typography>
      <TableContainer
        sx={{
          minWidth: 700,
          maxHeight: "calc(100vh - 120px)",
          marginTop: 3,
        }}
        component={Paper}
      >
        <Table stickyHeader>
          <TableHead style={{ borderRadius: "10px 10px 0 0" }}>
            <TableRow>
              {pendingInternalFundsHeaders.map((heading) => (
                <TableCell key={heading}>{heading}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.response?.length && !isFetching ? (
              data?.response?.map((data, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Typography fontSize={14}>
                      {validateNull(data?.fromCompanyData?.companyName)}
                      <br />({validateNull(data?.fromGstin)})
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={14}>
                      {validateNull(data?.toCompanyData?.companyName)}
                      <br />({validateNull(data?.toGstin)})
                    </Typography>
                  </TableCell>
                  <TableCell style={{ maxWidth: 150 }}>
                    <Typography fontSize={14}>
                      {validateNull(data?.ledgerDescription)}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ maxWidth: 150 }}>
                    <Typography fontSize={14}>
                      {validateNull(data?.advice)}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography fontSize={14}>
                      {validateNull(moneyFormat(data?.amount))}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={14}>
                      {validateNull(data?.transferDate)}
                    </Typography>
                  </TableCell>

                  <TableCell style={{ display: "flex", flexDirection: "row" }}>
                    <CommentBox comment={data?.comment} />
                    <Divider
                      variant="middle"
                      orientation="horizontal"
                      flexItem
                      sx={{ borderRightWidth: 1 }}
                    />
                    <HoverModal
                      isDisabled={data?.id === editingId}
                      isClickClose={true}
                      buttonIcon={
                        <MoreVert
                          sx={{
                            color:
                              data?.id === editingId && theme.palette.grey[200],
                          }}
                        />
                      }
                    >
                      {Object.keys(INVOICE_SELLER_PAYOUT.action)?.map(
                        (option) => {
                          return (
                            <MenuItem
                              key={option}
                              onClick={() => onClickMenu(option, data)}
                            >
                              {INVOICE_SELLER_PAYOUT.action[option]}
                            </MenuItem>
                          );
                        },
                      )}
                    </HoverModal>
                  </TableCell>
                </TableRow>
              ))
            ) : isFetching ? (
              <TableCell
                colSpan={pendingInternalFundsHeaders?.length}
                sx={{ textAlign: "center" }}
              >
                <Loader sx={{ marginTop: 0 }} />
              </TableCell>
            ) : (
              <TableCell
                colSpan={pendingInternalFundsHeaders?.length}
                sx={{ textAlign: "center" }}
              >
                No data found
              </TableCell>
            )}
          </TableBody>
        </Table>
        <TableFooter style={{ display: "flex", justifyContent: "flex-end" }}>
          <TableRow>
            {data?.response?.length && (
              <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                rowsPerPage={rowsPerPage}
                page={page}
                count={data?.totalCount ?? 0}
                onPageChange={onPageChange}
                sx={{ display: "flex", flexDirection: "column-reverse" }}
                ActionsComponent={PaginationAction}
              />
            )}
          </TableRow>
        </TableFooter>
      </TableContainer>
    </>
  );
};

export default PendingTransaction;
