import { Box, Grid, IconButton, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { DeleteOutlined } from "@mui/icons-material";

import { DropdownV1 } from "components";
import { ErrorMsg } from "CustomStyledComponents";

import Comment from "../components/Comment";
import { RefundAndCashbackPayout } from "./constants";

const { refundFields, REFUND_FORM, refundFieldErrors } =
  RefundAndCashbackPayout;

const Error = ({ message }) => (
  <ErrorMsg sx={{ position: "absolute", maxWidth: 120, marginTop: 0 }}>
    {message}
  </ErrorMsg>
);

const RefundCashbackForm = ({
  id,
  transaction,
  txErrors,
  toConfirmSingleTxRemoval,
}) => {
  const methods = useFormContext();

  const {
    control,
    watch,
    formState: { errors },
  } = methods;

  const {
    ORDER_NUMBER,
    PAYOUT_TYPE,
    FROM_ACCOUNT,
    TO_ACCOUNT,
    PAYOUT_AMOUNT,
    INVOICE_COMMENTS,
  } = refundFields;

  /**
   * @description - Get error message either FE validation or API error message
   */
  const getError = (unique, field) => {
    if (txErrors && Object.keys(txErrors)?.includes(field)) {
      return refundFieldErrors[field];
    }
    return errors?.[REFUND_FORM]?.[unique]?.[field]?.message;
  };

  return (
    <Grid
      container
      gap={3}
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <Grid item xs={1.8}>
        <Controller
          control={control}
          defaultValue={transaction?.orderNumber ?? ""}
          name={`${REFUND_FORM}.${id}.${ORDER_NUMBER}`}
          render={({ field: { value, onChange } }) => (
            <TextField
              label="Order number"
              size="small"
              onChange={onChange}
              value={value}
            />
          )}
        />
        <Error message={getError(id, ORDER_NUMBER)} />
      </Grid>
      <Grid item xs={1.8}>
        <Controller
          control={control}
          defaultValue={transaction?.payoutType ?? ""}
          name={`${REFUND_FORM}.${id}.${PAYOUT_TYPE}`}
          render={({ field: { value, onChange } }) => (
            <DropdownV1
              onChange={onChange}
              itemList={["Cashback", "Refund"]}
              placeholder="Payout type"
              listType="constants"
              value={value}
            />
          )}
        />
        <Error message={getError(id, PAYOUT_TYPE)} />
      </Grid>
      <Grid item xs={1.8}>
        <Controller
          control={control}
          defaultValue={transaction?.fromGstin ?? ""}
          name={`${REFUND_FORM}.${id}.${FROM_ACCOUNT}`}
          render={({ field: { value, onChange } }) => (
            <TextField
              disabled={true}
              label="From account"
              size="small"
              onChange={onChange}
              value={
                watch(REFUND_FORM)?.[id]?.[PAYOUT_TYPE]?.toLowerCase() ===
                "cashback"
                  ? "JSW One"
                  : ""
              }
            />
          )}
        />
        <Error message={getError(id, FROM_ACCOUNT)} />
      </Grid>
      <Grid item xs={1.8}>
        <Controller
          control={control}
          defaultValue={transaction?.toGstin ?? ""}
          name={`${REFUND_FORM}.${id}.${TO_ACCOUNT}`}
          render={({ field: { value, onChange } }) => (
            <TextField
              label="To account"
              size="small"
              onChange={onChange}
              value={value}
            />
          )}
        />
        <Error message={getError(id, TO_ACCOUNT)} />
      </Grid>
      <Grid item xs={1.8}>
        <Controller
          control={control}
          defaultValue={transaction?.payoutAmount ?? ""}
          name={`${REFUND_FORM}.${id}.${PAYOUT_AMOUNT}`}
          render={({ field: { value, onChange } }) => (
            <TextField
              label="Payout amount"
              size="small"
              type={"number"}
              onChange={onChange}
              value={value}
            />
          )}
        />
        <Error message={getError(id, PAYOUT_AMOUNT)} />
      </Grid>
      <Grid item>
        <Box display={"flex"} alignItems={"center"}>
          <Comment
            name={`${REFUND_FORM}.${id}.${INVOICE_COMMENTS}`}
            invoiceComments={transaction?.invoiceComments}
          />
          {txErrors && (
            <>
              <Typography>|</Typography>
              <IconButton
                aria-label="comment-icon"
                disableRipple
                onClick={() => toConfirmSingleTxRemoval(id)}
              >
                <DeleteOutlined />
              </IconButton>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default RefundCashbackForm;
