import { createSlice } from "@reduxjs/toolkit";

export const orderListPathParams = createSlice({
  name: "orderListPathParams",
  initialState: "",
  reducers: {
    setorderListPathParams: (_state, action) => {
      return action.payload;
    },
  },
});

export const { setorderListPathParams } = orderListPathParams.actions;
export default orderListPathParams.reducer;
