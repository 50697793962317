import { useRef, useState } from "react";
import { Box } from "@mui/material";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";

import { MultiSelectSearch, MultiSelectDropdown, ButtonV1 } from "components";
import theme from "themeProvider";
import { getCall } from "services";
import { opsSpocList } from "constants";
import { generateFilterQuery } from "./dashboardUtils";
import { filterDisplayFields } from "./constant";

const Filter = ({ resetFilters }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigation = useNavigate();
  const { search } = useLocation();

  const selectRef = useRef(null);
  const sellerCompanyRef = useRef(null);
  const deliveryStateRef = useRef(null);

  const sellerCompanyParams = searchParams.get("companyName")?.split(",");
  const deliveryStateParams = searchParams.get("orderState")?.split(",");
  const opsSpocParams = searchParams.get("opsSpoc")?.split(",");

  const [isOpsMemberSelected, setIsOpsMemberSelected] = useState(false);
  const [isDirtySellerCompany, setIsDirtySellerCompany] = useState(false);
  const [isDirtyDeliveryState, setIsDirtyDeliveryState] = useState(false);

  const handleFilter = () => {
    const selectedOpsMembers = selectRef.current.fetchValue();

    const companyName = sellerCompanyRef?.current
      ?.fetchValue()
      ?.map(
        (company) => company?.[filterDisplayFields.sellerCompany] || company,
      )
      .join();
    const orderState = deliveryStateRef?.current
      ?.fetchValue()
      ?.map((order) => order?.[filterDisplayFields.deliveryState] || order)
      .join();

    const filterObj = {
      companyName,
      orderState,
      opsSpoc: selectedOpsMembers,
    };
    setSearchParams(generateFilterQuery(filterObj));
  };

  const getSearchData = async (search, endpoint) => {
    const params = {
      [endpoint]: search,
    };
    const res = await getCall(`/oms/order/list/search/auto-suggest`, params);
    return res || [];
  };

  const clearFilters = () => {
    deliveryStateRef?.current?.resetValue();
    sellerCompanyRef?.current?.resetValue();
    selectRef.current.resetValue();

    resetFilters();
    navigation("/dashboard", { replace: true });
  };
  return (
    <Box p={4} border={`1px solid ${theme.palette.grey["A100"]}`}>
      <Box
        style={{
          display: "flex",
          flex: 1,
          gap: 24,
        }}
      >
        <MultiSelectSearch
          sx={{ flex: 1 }}
          ref={sellerCompanyRef}
          defaultSelectedValues={sellerCompanyParams ?? []}
          displayAttribute={filterDisplayFields.sellerCompany}
          label="Seller company name"
          onSearch={(searchInput) =>
            getSearchData(searchInput, filterDisplayFields.sellerCompany)
          }
          setIsDirty={setIsDirtySellerCompany}
        />
        <MultiSelectSearch
          sx={{ flex: 1 }}
          ref={deliveryStateRef}
          defaultSelectedValues={deliveryStateParams ?? []}
          displayAttribute={filterDisplayFields.deliveryState}
          label="Delivery State"
          onSearch={(searchInput) =>
            getSearchData(searchInput, filterDisplayFields.deliveryState)
          }
          setIsDirty={setIsDirtyDeliveryState}
        />

        <Box sx={{ flex: 1 }}>
          <MultiSelectDropdown
            ref={selectRef}
            itemList={opsSpocList}
            displayAttribute={"value"}
            label={"Ops SPOC"}
            isListOfObjects={true}
            defaultSelectedValues={opsSpocParams ?? []}
            setIsDirty={setIsOpsMemberSelected}
          />
        </Box>
        <Box sx={{ flex: 2, display: "flex" }}>
          <ButtonV1
            style={{ maxHeight: 40, marginRight: 4 }}
            onClick={handleFilter}
            title="Submit"
            disabled={
              !isDirtyDeliveryState &&
              !isDirtySellerCompany &&
              !isOpsMemberSelected
            }
          />
          <ButtonV1
            style={{ maxHeight: 40 }}
            variant="outlined"
            title="Reset"
            onClick={clearFilters}
            disabled={
              !isDirtyDeliveryState &&
              !isDirtySellerCompany &&
              !isOpsMemberSelected &&
              !search
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Filter;
