import { displayLabel } from "constants";

export const CREDIT = Object.freeze({
  tab: {
    pending: "PENDING",
    approval: "APPROVAL",
  },
  quickFilter: {
    ...displayLabel,
    all: "All",
  },
  tableHeader: [
    "Buyer order no.",
    "Seller order no.",
    "Order date",
    "Order type",
    "Buyer order status",
    "Seller order status",
    "Ops SPOC",
    "Payment status",
    "Committed delivery",
    "Planned delivery",
    "Buyer",
    "Seller",
    "Delivery State",
  ],
  ERROR_MSG: "An error occurred, please try again.",
});
