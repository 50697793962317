import { Typography, Box, Grid } from "@mui/material";

import { CollapsibleInfo, StatusChip } from "components";
import { getStatusType } from "pages/CustomerProfile";
import { Attachment, ColumnView } from "./components";

import { getMaskData, validateNull, formatDate } from "utils";

const CustomGridItem = ({ label, children }) => {
  return (
    <Grid item xs={1.71}>
      <ColumnView label={label}>{children}</ColumnView>
    </Grid>
  );
};

const ArchivedDetailsList = ({ archivedAccounts }) => {
  return (
    !!archivedAccounts.length && (
      <Box mt={2}>
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: "600",
            marginBottom: 4,
          }}
        >
          Archived bank account details
        </Typography>
        <CollapsibleInfo
          hideExpandIcon={false}
          defaultExpand={true}
          summaryStyles={{ paddingLeft: 10 }}
          summary={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: "600",
                }}
              >
                {archivedAccounts?.[0]?.companyName} -{" "}
                {archivedAccounts?.[0]?.customerGstin}
              </Typography>
            </Box>
          }
          details={archivedAccounts.map((account, index) => {
            return (
              <Box p={4} key={index}>
                <Grid container>
                  <CustomGridItem label="Sr. no">
                    <Typography sx={{ fontWeight: "600" }}>
                      {index + 1}
                    </Typography>
                  </CustomGridItem>
                  <CustomGridItem label="Bank account name">
                    <Typography sx={{ fontWeight: "600" }}>
                      {validateNull(account?.beneficiaryName)}
                    </Typography>
                  </CustomGridItem>
                  <CustomGridItem label="Bank account number">
                    <Typography sx={{ fontWeight: "600" }}>
                      {getMaskData(account?.bankAccountNumber)}
                    </Typography>
                  </CustomGridItem>
                  <CustomGridItem label="IFSC code">
                    <Typography sx={{ fontWeight: "600" }}>
                      {validateNull(account?.ifscCode)}
                    </Typography>
                  </CustomGridItem>
                  <CustomGridItem label="Attachment">
                    {account?.filePath ? (
                      <Attachment filePath={account?.filePath} />
                    ) : (
                      <b>NA</b>
                    )}
                  </CustomGridItem>
                  <CustomGridItem label="Timestamp">
                    <Typography sx={{ fontWeight: "600" }}>
                      {formatDate(account.actionDate)}
                    </Typography>
                  </CustomGridItem>
                  <CustomGridItem label="Status">
                    <StatusChip
                      label={account?.status}
                      type={getStatusType(account?.status)}
                      style={{ fontWeight: "bold" }}
                    />
                  </CustomGridItem>
                </Grid>
              </Box>
            );
          })}
        />
      </Box>
    )
  );
};

export default ArchivedDetailsList;
