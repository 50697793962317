import * as Yup from "yup";
import { INVOICE_SELLER_PAYOUT } from "../constant";
import moment from "moment";
import ERROR_MSG from "./constant";

const { manualUpload, invoiceYup, documentType, plantGstin } =
  INVOICE_SELLER_PAYOUT;

export const invoiceSellerSchema = Yup.object().shape({
  [invoiceYup]: Yup.array().of(
    Yup.object().shape({
      [manualUpload.orderNumber]: Yup.string().required(
        ERROR_MSG[manualUpload.orderNumber],
      ),
      [manualUpload.fromGstin]: Yup.string()
        .length(15, "GSTIN must be 15 characters")
        .required(ERROR_MSG[manualUpload.fromGstin]),
      [manualUpload.toGstin]: Yup.string()
        .length(15, "GSTIN must be 15 characters")
        .required(ERROR_MSG[manualUpload.toGstin]),
      [manualUpload.documentType]: Yup.string()
        .required(ERROR_MSG[manualUpload.documentType])
        .oneOf(
          Object.values(documentType),
          ERROR_MSG[manualUpload.documentType],
        ),
      [manualUpload.documentId]: Yup.string().required("Enter a document Id"),
      [manualUpload.originalInvoiceId]: Yup.array()
        .typeError("")
        .test({
          name: "originalInvoiceId",
          test(value, ctx) {
            const { [manualUpload.documentType]: docType } = ctx.parent;

            if (
              [
                documentType.debitNotes,
                documentType.interestCharge,
                documentType.penalCharges,
              ].includes(docType) &&
              (value === undefined || value?.length === 0)
            ) {
              return ctx.createError({
                message: `Enter at least one original Invoice number against which ${docType} was furnished`,
              });
            }
            return true;
          },
        }),
      [manualUpload.documentDate]: Yup.date()
        .typeError(ERROR_MSG[manualUpload.documentDate])
        .required(ERROR_MSG[manualUpload.documentDate]),
      [manualUpload.invoiceAmount]: Yup.number()
        .typeError("Invalid document amount")
        .required(ERROR_MSG[manualUpload.invoiceAmount])
        .min(0, "Document amount should be positive"),
      [manualUpload.creditAmount]: Yup.string().test({
        name: "creditAmount",
        message: ERROR_MSG[manualUpload.creditAmount],
        test(value, ctx) {
          const {
            [manualUpload.documentType]: docType,
            [manualUpload.invoiceAmount]: invoiceAmount,
          } = ctx.parent;

          if (
            [documentType.bnplInvoice, documentType.channelFinance].includes(
              docType,
            )
          ) {
            if (!value) {
              return false;
            }
            if (invoiceAmount < value) {
              return ctx.createError({
                message: "Credit amount should be less than invoice amount",
              });
            } else return true;
          } else return true;
        },
      }),
      [manualUpload.creditDueDate]: Yup.mixed().test({
        name: "creditDueDate",
        message: ERROR_MSG[manualUpload.creditDueDate],
        test(value, ctx) {
          const { [manualUpload.documentType]: docType } = ctx.parent;
          if (
            [documentType.channelFinance, documentType.bnplInvoice].includes(
              docType,
            ) &&
            !value
          ) {
            return false;
          }
          if (docType === documentType.channelFinance) {
            return moment().add(3, "days").isAfter(value);
          }
          if (docType === documentType.bnplInvoice) {
            return true;
          }
          return true;
        },
      }),
      [manualUpload.payoutAmount]: Yup.string().test({
        name: "payoutAmount",
        message: ERROR_MSG[manualUpload.payoutAmount],
        test(value, ctx) {
          const {
            invoiceAmount,
            [manualUpload.toGstin]: toGstin,
            [manualUpload.isTcsTdsDetected]: isTcsTdsDetected,
            [manualUpload.igstAmount]: igst,
            [manualUpload.cgstAmount]: cgst,
            [manualUpload.sgstAmount]: sgst,
          } = ctx.parent;
          const tcsTds =
            (invoiceAmount - (igst || 0) - (cgst || 0) - (sgst || 0)) * 0.01;
          const payoutAmountCalc = Number(invoiceAmount - tcsTds * 2).toFixed(
            2,
          );

          const checkPlantGST = () => {
            const plantPanRegex = new RegExp(`${plantGstin.join("|")}`, "g");
            return !!toGstin?.match(plantPanRegex)?.[0];
          };
          if (checkPlantGST() || !isTcsTdsDetected) {
            if (+value > invoiceAmount) return false;
            else return true;
          } else if (+value > payoutAmountCalc) return false;
          else return true;
        },
      }),
    }),
  ),
});
