import { getFixedValue, moneyFormat, decimalRoundOff } from "utils";
import {
  hookFormLabel,
  shipmentStatus,
  DIRECT,
  PAYMENT_TYPE,
} from "./constants";
import {categoryType} from 'constants';

const { delivered, cancelled } = shipmentStatus;

export const getTotalInvoiceAmt_otherServices = (
  taxfreightCharge = 0,
  taxprocessingCharge = 0,
  taxloadingCharge = 0,
  taxotherCharge = 0,
) => {
  return (
    Number(taxfreightCharge) +
    Number(taxprocessingCharge) +
    Number(taxloadingCharge) +
    Number(taxotherCharge)
  );
};

export const getZohoTotalInvoiceAmt_otherServices = (zohoServiceCharges) => {
  if (zohoServiceCharges) {
    return zohoServiceCharges.reduce?.(
      (total, item) => total + +(item?.amountWithGst ?? 0),
      0,
    );
  }
  return 0;
};

export const amountWithGST = (serviceCharge = 0, serviceTax = 0) => {
  const chargeAmount = Number(serviceCharge);
  const tax = Number(serviceTax) / 100;
  const calculatedTax = chargeAmount * tax;
  const result = chargeAmount + calculatedTax;
  return result;
};

export const getTotalInvoiceAmt_baseValue = (
  formValues,
  lineItemsDetails,
  sumOfOtherChargesWithoutTax,
) => {
  const lineItemFormValues = formValues["lineItemsDetails"];

  let result = 0;
  // eslint-disable-next-line array-callback-return
  lineItemsDetails.map((ele) => {
    const { lineitemId } = ele;

    const subtractDiscount =
      Number(
        lineItemFormValues[lineitemId]["confirmInvoicedRatePerUnit"] ?? 0,
      ) - Number(lineItemFormValues[lineitemId]["confirmDiscountPerUnit"] ?? 0);

    result +=
      subtractDiscount *
      Number(lineItemFormValues[lineitemId]["confirmInvoicedQty"] ?? 0);
  });
  result += sumOfOtherChargesWithoutTax;
  return decimalRoundOff(result);
};

export const getTotalInvoiceAmount_withGST = (
  formValues,
  shipmentDetails,
  sumOfOtherChargesWithoutTax,
  orderDetails
) => {
  const lineItemsDetails = shipmentDetails.lineItemsDetails;
  const lineItemFormValues = formValues["lineItemsDetails"];
  const gstAmount =
    (formValues[hookFormLabel.totalInvoiceOtherServicesAmt] ?? 0) -
    sumOfOtherChargesWithoutTax;

  let result = 0;
  // eslint-disable-next-line array-callback-return
  lineItemsDetails.map((ele) => {
    const { lineitemId, gst } = ele;

    const subtractDiscount =
      Number(
        lineItemFormValues[lineitemId]["confirmInvoicedRatePerUnit"] ?? 0,
      ) - Number(lineItemFormValues[lineitemId]["confirmDiscountPerUnit"] ?? 0);

    const price =
      subtractDiscount *
      Number(lineItemFormValues[lineitemId]["confirmInvoicedQty"] ?? 0);

    if((orderDetails?.buyerDetails?.shippingAddress?.state && shipmentDetails?.sellerShipmentsInfo?.state)&& (shipmentDetails?.sellerShipmentsInfo?.state.toLowerCase() === orderDetails?.buyerDetails?.shippingAddress?.state.toLowerCase())){
        //apply gst twice if gst
        const taxPercentage = Number((gst ?? 0) / 2);
        const taxAmount = price * Number(taxPercentage ?? 0);
        const roundOffTax = decimalRoundOff(Number(taxAmount));
        result += roundOffTax * 2; 
    }else{
        //igst
        const taxAmount = price * Number(gst ?? 0);
        const roundOffTax = decimalRoundOff(Number(taxAmount));
        result += roundOffTax;
    }
   
  });
  result += gstAmount;
  return decimalRoundOff(result);
};

export const registerServiceChargeValues = (setValue, otherAmounts) => {
  // eslint-disable-next-line array-callback-return
  Object.keys(otherAmounts).map((ele, index) => {
    setValue(ele, otherAmounts[ele][ele]);
    setValue(`confirm${ele}`, otherAmounts[ele][ele]);
    setValue(`gst${ele}`, otherAmounts[ele]?.gst);
    setValue(`tax${ele}`, otherAmounts[ele]?.tax);
  });
};

export const moneyString = (value) => moneyFormat(getFixedValue(value));

export const calculateAllFreightCharges = (
  sellerShipmentsInfo,
  sellerOrderId,
  shipmentId,
) => {
  let totalFrieghtCharge = 0;
  const shipmentDetailsList = sellerShipmentsInfo?.filter(
    (ele) => ele.sellerOrderId === sellerOrderId,
  );

  const completedShipments = shipmentDetailsList?.[0]?.shipmentDetails?.filter(
    (ele) => ele?.currentState?.state !== shipmentStatus.cancelled,
  );

  if (completedShipments && completedShipments?.length) {
    // eslint-disable-next-line array-callback-return
    completedShipments?.map((ele) => {
      const { id = 0 } = completedShipments;
      if (id !== shipmentId) {
        const { freightChargeTax = 0 } =
          ele?.shipmentInvoiceDetails?.invoiceOtherAmounts;
        totalFrieghtCharge += Number(freightChargeTax);
      }
    });
  }
  return totalFrieghtCharge;
};

export const checkInvoiceValues = (shipmentData) => {
  const { totalBaseInvoiceAmt, totalGSTInvoiceAmount, totalInvoiceAmt } =
    shipmentData;
  if (
    totalBaseInvoiceAmt > 0 &&
    totalGSTInvoiceAmount > 0 &&
    totalInvoiceAmt > 0
  ) {
    return true;
  }
  return false;
};

export const enableShipmentCreation = (shipment, orderDetails) => {
  const { amounts, paymentStatus, category, paymentType, isMultiSeller } =
    orderDetails;
  const totalPaidAmount = amounts?.TPA?.amount?.centAmount;
  const advanceAmount = amounts?.ADP?.amount?.centAmount;
  const totalOrderAmount = amounts?.TOA?.amount?.centAmount;
  const partial = "Partial";
  const full = "Full";

  let state = false;
 
  if ((orderDetails?.customerFreezed || orderDetails?.isCreditBlocked) && (orderDetails?.category.toLowerCase()===categoryType?.DISTRIBUTED.toLowerCase())) {
    //avoid shipment creation if false
    return state;
  }
  switch (paymentType) {
    case PAYMENT_TYPE.credit:
      state = true;
      break;
    case PAYMENT_TYPE.cashNcarryAdvance:
    case PAYMENT_TYPE.cashNcarryPart:
      if (isMultiSeller) {
        state = true;
      } else {
        state = totalPaidAmount >= advanceAmount;
      }
      break;
    default:
      let lineItems = [];
      shipment.forEach((ele) => {
        if (ele?.lineItems) {
          lineItems.push(...ele?.lineItems);
        }
      });
      for (const ele of lineItems) {
        const { currentState } = ele;
        if (![delivered, cancelled].includes(currentState)) {
          state = true;
          break;
        }
      }

      if (paymentStatus === partial && category !== DIRECT) {
        if (totalPaidAmount / 100 < totalOrderAmount / 100 - 500) {
          state = false;
        }
      }
      if (paymentStatus === full) {
        state = true;
      }
  }

  return state;
};

// Return lineitems if status is not Delivered & Cancelled

export const getValidLineItems = (shipment) => {
  if (shipment?.lineItems) {
    return shipment?.lineItems.filter((lineItem) => {
      if (lineItem?.currentState) {
        const { currentState } = lineItem;
        return currentState !== delivered && currentState !== cancelled;
      }
      return true;
    });
  }
};
