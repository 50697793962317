import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { ExportCSVCTA, HyperLink, Loader, PaginationAction } from "components";
import QuickFilter from "../Components/QuickFilter";

import { pascalCase, moneyFormat, appendRupeeIcon, formatDate } from "utils";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { ColumnTitle, StyledTableRow } from "CustomStyledComponents";

const ROWS_PER_PAGE = 20;

const OrderTable = ({
  res,
  totalCount,
  offset,
  onPageChange,
  onExportData,
  defaultQuickFilter = "today",
  isLoading,
}) => {
  const navigation = useNavigate();

  const tableFields = useMemo(() => {
    return [
      {
        title: "Buyer order no.",
        field: "customerOrderNumber",
        render: (ele) => <HyperLink title={ele?.customerOrderNumber} />,
      },
      {
        title: "Seller order no.",
        field: "orderNumber",
        render: (ele) => ele?.orderNumber,
      },
      {
        title: "Order date",
        field: "orderDate",
        render: (ele) => (
          <Typography variant="body2">{formatDate(ele?.orderDate)}</Typography>
        ),
      },
      {
        title: "Order status",
        field: "orderStatus",
        render: (ele) => ele?.orderStatus,
      },
      {
        title: "Payment Type",
        field: "orderPaymentType",
        render: (ele) => ele?.orderPaymentType,
      },
      {
        title: "Ops SPOC",
        field: "opsSpoc",
        render: (ele) => ele?.opsSpoc,
      },
      {
        title: "Customer company Name",
        field: "buyerDetails.companyName",
        render: (ele) => (
          <Typography variant="body2">
            {pascalCase(ele?.buyerDetails?.companyName)}
          </Typography>
        ),
      },
      {
        title: "Seller company name",
        field: "sellerDetails.companyName",
        render: (ele) => ele?.sellerDetails?.companyName,
      },
      {
        title: "Order amount",
        field: "orderAmount",
        render: (ele) => (
          <Typography variant="body2">
            {appendRupeeIcon(ele?.netOrderAmount?.displayAmount)}
          </Typography>
        ),
      },
      {
        title: "Ledger balance (Buyer ledger)",
        field: "ledgerBalance",
        render: (ele) => (
          <Typography variant="body2">
            {moneyFormat(ele?.accountBalance?.balance)}
          </Typography>
        ),
      },
    ];
  }, []);

  const navigateToOrderDetail = ({ customerOrderNumber }) => {
    if (customerOrderNumber) {
      navigation(`/order-list/${customerOrderNumber}?tab=orderDetails`);
    }
  };

  const page = Math.abs(offset / ROWS_PER_PAGE);

  return (
    <>
      <QuickFilter defaultFilter={defaultQuickFilter} />
      <Box display="flex" justifyContent="flex-end">
        <ExportCSVCTA
          onExportData={onExportData}
          fileName="pending-order-list"
          tableFields={tableFields}
        />
      </Box>
      <Box>
        <TableContainer
          sx={{
            minWidth: 700,
            maxHeight: "calc(100vh - 120px)",
          }}
          component={Paper}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {tableFields.map((el) => (
                  <TableCell key={el.field}>
                    <ColumnTitle>{el.title}</ColumnTitle>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading || !res?.length ? (
                <StyledTableRow>
                  <TableCell colSpan={tableFields.length} align="center">
                    {isLoading ? (
                      <Loader sx={{ marginTop: 0 }} />
                    ) : (
                      <Typography textAlign={"center"}>
                        No data found
                      </Typography>
                    )}
                  </TableCell>
                </StyledTableRow>
              ) : (
                res?.map((row) => (
                  <StyledTableRow
                    key={row?.customerOrderNumber}
                    onClick={() => navigateToOrderDetail(row)}
                  >
                    {tableFields?.map((el) => (
                      <TableCell key={el?.field}>{el.render(row)}</TableCell>
                    ))}
                  </StyledTableRow>
                ))
              )}
            </TableBody>
            {!!res?.length && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[ROWS_PER_PAGE]}
                    rowsPerPage={ROWS_PER_PAGE}
                    page={page}
                    count={totalCount ?? 0}
                    onPageChange={onPageChange}
                    ActionsComponent={PaginationAction}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default OrderTable;
