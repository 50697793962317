import { downloadCsv } from "utils";

const forceNumbersAsText = (csv) => {
  // Split the CSV into lines
  const lines = csv.split("\n");

  // Iterate through each line and add double quotes around numeric type
  const modifiedCsv = lines
    .map((line) => {
      const columns = line.split(",");
      return columns
        .map((column) => {
          // Check if the column value is a number
          if (!isNaN(column.trim())) {
            // Enclose numeric values in double quotes
            return `"${column.trim()}"`;
          }
          return column;
        })
        .join(",");
    })
    .join("\n");

  return modifiedCsv;
};

export const createPayoutCSV = (data) => {
  const csvHeader = [
    "Debit Ac No",
    "Beneficiary Ac No",
    "Beneficiary Name",
    "Amt",
    "Pay Mod",
    "Date",
    "IFSC Code",
    "Payable Location name",
    "Print Location",
    "Bene Mobile no",
    "Bene email id",
    "Ben add1",
    "Ben add2",
    "Ben add3",
    "Ben add4",
    "System Ref No.",
    "BENE LEI Number",
    "BENE LEI Expiry Date",
    "Sender/ Remitter LEI Number",
    "Sender/ Remitter LEI Expiry Date",
    "Order Number",
    "Document Id",
    "Transaction Type",
    "Credit Narration",
    "Debit Narration",
    "Remarks",
    "Instrument_Refrence_No",
  ];
  try {
    let resultSheet = csvHeader.join(",") + "\n";

    data.forEach((ele, index) => {
      const {
        debitAccountNumber = "",
        beneficiaryAccountNumber,
        beneficiaryName,
        amount,
        paymentMode,
        date,
        ifscCode,
        payableLocation,
        printLocation,
        beneficiaryMobileNo,
        beneficiaryEmailId,
        beneficiaryAddressLine1 = "",
        beneficiaryAddressLine2,
        beneficiaryAddressLine3,
        beneficiaryAddressLine4,
        systemReferenceNumber,
        beneficiaryLeiNumber,
        beneficiaryLeiExpiryDate,
        remitterLeiNumber,
        remitterLeiExpiryDate,
        orderNumber,
        documentId,
        documentType,
        creditNarration,
        debitNarration,
        remarks,
      } = ele;
      const commaSeparatedColumn = `${debitAccountNumber},${beneficiaryAccountNumber},${beneficiaryName},${amount},${paymentMode},${date},${ifscCode}`;
      const location = `${payableLocation ?? ""},${printLocation ?? ""}`;
      const beneficiaryDetails = `${beneficiaryMobileNo ?? ""},${
        beneficiaryEmailId ?? ""
      },${beneficiaryAddressLine1 ?? ""},${beneficiaryAddressLine2 ?? ""},${
        beneficiaryAddressLine3 ?? ""
      },${beneficiaryAddressLine4 ?? ""},${systemReferenceNumber ?? ""},${
        beneficiaryLeiNumber ?? ""
      },${beneficiaryLeiExpiryDate ?? ""},${remitterLeiNumber ?? ""},${
        remitterLeiExpiryDate ?? ""
      }`;
      const orderDetails = `${orderNumber},${documentId},${documentType},${
        creditNarration ?? ""
      },${debitNarration ?? ""},${remarks ?? ""}`;
      const row = `${commaSeparatedColumn},${location},${beneficiaryDetails},${orderDetails}${
        index === data.length - 1 ? "" : "\n"
      }`;
      resultSheet += row;

      if (index === data.length - 1) {
        const modifiedCsv = forceNumbersAsText(resultSheet);
        downloadCsv(modifiedCsv, `ICICI_Payout_${new Date().toISOString()}`);
      }
    });
  } catch (ex) {
    console.log(`Exception in creating payout csv : ${ex}`);
  }
};
