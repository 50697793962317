export const INTERNAL_FUND_TRANSFER = Object.freeze({
  yupName: "internalFundYup",
  manualUpload: {
    fromGstin: "fromGstin",
    toGstin: "toGstin",
    advice: "advice",
    amount: "amount",
    comment: "comment",
    ledgerDescription: "ledgerDescription",
  },
  action: { edit: "Edit", delete: "Delete" },
});
