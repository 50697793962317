import { Box, Typography } from "@mui/material";
import { moneyFormat } from "utils";

const AmountWithHead = ({ head, amount }) => {
  return (
    <Box display={"flex"} flex={1} flexDirection={"column"}>
      <Typography color={"text.disabled"} fontWeight={600} fontSize={12} noWrap>
        {head}
      </Typography>
      <Typography fontWeight={600} fontSize={14}>
        {amount ? moneyFormat(amount, 2) : "-"}
      </Typography>
    </Box>
  );
};

export default AmountWithHead;
