import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Proptypes from "prop-types";
import { categoryType } from "constants";

const useCategoryTypeCheck = (categoryTypeProp = null) => {
  const { category } = useSelector((state) => state.orderDetails);
  const [isDirect, setIsDirect] = useState(false);
  const [isDistributed, setIsDistributed] = useState(false);
  const [isPborDirect, setIsPborDirect] = useState(false);
  const [isPrivateBrand, setIsPrivateBrand] = useState(false);

  useEffect(() => {
    let orderCategory = categoryTypeProp ? categoryTypeProp : category;
    orderCategory = orderCategory?.toLowerCase();

    const checkDirect = () => {
      if (
        [
          categoryType.DIRECT.toLowerCase(),
          categoryType.PLANT_SUPPLY.toLowerCase(),
        ].includes(orderCategory)
      ) {
        setIsDirect(true);
      }
    };

    const checkPborDirect = () => {
      if (
        [
          categoryType.DIRECT.toLowerCase(),
          categoryType.PLANT_SUPPLY.toLowerCase(),
          categoryType.PRIVATE_BRAND.toLowerCase(),
        ].includes(orderCategory)
      ) {
        setIsPborDirect(true);
      }
    };

    const checkDistributed = () => {
      if ([categoryType.DISTRIBUTED.toLowerCase()].includes(orderCategory)) {
        setIsDistributed(true);
      }
    };

    const checkPrivateBrand = () => {
      if ([categoryType.PRIVATE_BRAND.toLowerCase()].includes(orderCategory)) {
        setIsPrivateBrand(true);
      }
    };

    checkDirect();
    checkPborDirect();
    checkDistributed();
    checkPrivateBrand();
  }, [category]);

  return { isDirect, isDistributed, isPborDirect, isPrivateBrand };
};

useCategoryTypeCheck.proptypes = {
  categoryTypeProp: Proptypes.string,
};

export default useCategoryTypeCheck;
