import { useRef, useState, lazy, Suspense, useCallback } from "react";
import { Box } from "@mui/material";
import moment from "moment";

import FileUploadWrapper from "../components/FileUploadWrapper";
import ManualInput from "./ManualInput";
import ReviewTransactions from "./ReviewTransactions";
import FileDragAndDrop from "../components/FileDragAndDrop";
import UpdateTransaction from "./UpdateTransaction";

import { useToaster } from "hooks";
import { postCall_v2, putCall } from "services";
import { constructInvalidTxList, generateRefundOffErpData } from "../makeData";
import { error, success } from "constants";
import { FinancePayouts } from "../../constants";

const PendingTransactions = lazy(() => import("./PendingTransactions"));

const {
  ERROR_MSG,
  SAVED_SUCCESS_MSG,
  TRANSACTION_SAVED_MSG,
  TRANSACTION_UPDATED_MSG,
} = FinancePayouts.msgStatements;
const isCsvData = true;
const isUpdateTransaction = true;

const TxView = ({
  invalidCsvList,
  transaction,
  handleInvalidCsvList,
  handleSaveData,
  fileRef,
  updateTransaction,
  manualInputRef,
  onUpdateTxReset,
}) => {
  if (invalidCsvList.length && !transaction) {
    return (
      <ReviewTransactions
        invalidCsvList={invalidCsvList}
        passInvalidCsvList={handleInvalidCsvList}
        retryPayoutSubmission={handleSaveData}
        fileRef={fileRef}
      />
    );
  } else if (transaction) {
    return (
      <UpdateTransaction
        updateTransaction={(data) => updateTransaction(data)}
        transaction={transaction}
        onReset={onUpdateTxReset}
        key={transaction.id}
      />
    );
  } else {
    return (
      <ManualInput
        saveManualUpload={(data) => handleSaveData(data)}
        ref={manualInputRef}
      />
    );
  }
};

const RefundAndCashback = ({ transactionType, filters, children }) => {
  const fileRef = useRef();
  const pendingTransactionsRef = useRef();
  const manualInputRef = useRef();
  const [invalidCsvList, setInvalidCsvList] = useState([]);
  const [transaction, setTransaction] = useState(null);
  const showToaster = useToaster();

  /**
   * @param {*} csvData
   * @description - Triggered when a file is uploaded
   */
  const uploadOffERP_payouts = (csvData) => {
    const formattedData = generateRefundOffErpData(csvData);
    handleSaveData(formattedData);
  };

  const handleSaveData = async (data) => {
    const formattedData = [];
    data.forEach((ele) => {
      if (ele) {
        formattedData.push({
          ...ele,
          invoiceAmount: ele?.payoutAmount,
          tcsTdsDeductionReq: false,
          documentDate: moment().format("YYYY-MM-DD"),
        });
      }
    });

    try {
      const res = await postCall_v2("/oms/off-oms-payout", {
        offOmsPayoutRequestList: formattedData,
      });
      validateResponse(res?.data, formattedData);
    } catch (err) {
      showToaster(ERROR_MSG, error);
    }
  };

  /**
   * @description Validate response and set invalidList
   */
  const validateResponse = (response, data, isUpdate) => {
    if (response && Object.keys(response).length) {
      const invalidPayloadList = Object.keys(response);
      handleTxResponse(invalidPayloadList, response, data);
    } else {
      const SUCCESS_MSG = isUpdate
        ? TRANSACTION_UPDATED_MSG
        : TRANSACTION_SAVED_MSG;
      fileRef.current.resetFile();
      showToaster(SUCCESS_MSG, success);
      setInvalidCsvList([]);
    }
    manualInputRef?.current?.reset();
    pendingTransactionsRef?.current.refetchPendingTransactions();
    setTransaction(null);
  };

  /**
   * @description Validate response and show toaster message
   */
  const handleTxResponse = (invalidPayloadList, res, data) => {
    if (invalidPayloadList.length > 0) {
      const errorStr = invalidPayloadList > 1 ? "errors" : "error";
      setInvalidCsvList(constructInvalidTxList(Object.values(res)));
      const errorMsg = `${
        data.length - invalidPayloadList.length
      } have been saved and ${invalidPayloadList.length} have ${errorStr}`;
      showToaster(errorMsg, error);
    } else {
      showToaster(SAVED_SUCCESS_MSG, success);
      fileRef.current.resetFile();
    }
  };

  /**
   * @param {*} csvList
   * @description set verified invalid list response as new list in review table
   */
  const handleInvalidCsvList = (csvList) => {
    setInvalidCsvList(csvList);
  };

  /**
   * @description Update transaction
   */
  const updateTransaction = async (data) => {
    try {
      const result = await putCall(
        `/oms/off-oms-payout?payoutId=${data?.[0].id}`,
        { offOmsPayoutRequestList: data },
        {},
        () => showToaster(ERROR_MSG, error),
      );
      if (result) {
        validateResponse(result, data, isUpdateTransaction);
      }
    } catch (err) {
      showToaster(ERROR_MSG, error);
    }
  };

  const handleUpdateTxReset = useCallback(() => {
    setTransaction(null);
  }, []);

  return (
    <>
      <FileUploadWrapper>
        <Box mb={6}>
          <FileDragAndDrop
            ref={fileRef}
            invokeOMSPayout={(csvData) =>
              uploadOffERP_payouts(csvData, isCsvData)
            }
          />
        </Box>
        <TxView
          invalidCsvList={invalidCsvList}
          transaction={transaction}
          handleInvalidCsvList={handleInvalidCsvList}
          handleSaveData={handleSaveData}
          fileRef={fileRef}
          updateTransaction={updateTransaction}
          manualInputRef={manualInputRef}
          onUpdateTxReset={handleUpdateTxReset}
        />
      </FileUploadWrapper>
      {children}
      <Suspense fallback={<Box> Loading... </Box>}>
        <PendingTransactions
          filters={filters}
          transactionType={transactionType}
          ref={pendingTransactionsRef}
          onUpdate={(transaction) => setTransaction(transaction)}
        />
      </Suspense>
    </>
  );
};

export default RefundAndCashback;
