import * as Yup from "yup";
import { FinancePayouts } from "../../constants";

const { payoutFilters } = FinancePayouts;

const payoutSchema = (hideDateRange) => {
  if (!hideDateRange) {
    return Yup.object().shape({
      [payoutFilters.fromDate]: Yup.date()
        .typeError("Enter a valid date")
        .required("Please enter To from")
        .test({
          name: "From date is required",
          message: "From date is required",
          test(_value, ctx) {
            const { toDate, fromDate } = ctx.parent;
            return !!toDate && !fromDate ? false : true;
          },
        }),

      [payoutFilters.toDate]: Yup.date()
        .typeError("Enter a valid date")
        .required("Please enter To date")
        .when([payoutFilters.fromDate], (fromDate, schema) => {
          if (new Date(fromDate).toString() !== "Invalid Date") {
            return schema
              .min(fromDate, "Date range is inappropriate")
              .required("Please enter To date");
          }
        }),
    });
  } else {
    return Yup.object().shape({});
  }
};

export default payoutSchema;
