import React from "react";
import { Box } from "@mui/material";

import { AmountWithHead } from "pages/FinancePayout/components";

const PriceBreakup = ({
  value,
  isPlantGST,
  gstAmount,
  isTcsTdsDetected,
  invoiceAmount,
}) => {
  const tcsTds = value
    ? (+value - value * (gstAmount / invoiceAmount)) * 0.01
    : 0;
  const payoutAmount = value ? value - tcsTds * 2 : 0;

  return (
    <Box display={"flex"} flex={1} gap={2} flexDirection={"column"}>
      <AmountWithHead head={"Advance amount"} amount={value} />
      <AmountWithHead head={"TCS on GST (1%) on Invoice"} amount={tcsTds} />
      <AmountWithHead head={"TDS (1%) on Invoice"} amount={tcsTds} />
      <AmountWithHead
        head={"Payout amount now"}
        amount={isPlantGST || !isTcsTdsDetected ? value : payoutAmount}
      />
    </Box>
  );
};

export default React.memo(PriceBreakup);
