import { useMemo } from "react";
import { Typography, Box } from "@mui/material";
import moment from "moment";
import { dateConvertor, timeConvertor } from "utils";

const Timestamp = ({
  timeStamp,
  showTime = true,
  showDate = true,
  istConversion = true,
}) => {
  const date = useMemo(() => {
    if (timeStamp) {
      if (istConversion) {
        let ts =
          timeStamp?.charAt(timeStamp?.length - 1)?.toUpperCase() === "Z"
            ? timeStamp
            : `${timeStamp}Z`;
        const convertedDate = dateConvertor(ts);
        const convertedTime = timeConvertor(ts);
        return [convertedDate, convertedTime];
      } else {
        return moment(timeStamp)
          .format("DD/MM/YYYY, LTS")
          .toString()
          .split(", ");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeStamp]);

  return (
    <>
      {timeStamp && moment(timeStamp).isValid() ? (
        <Box display={showDate ? "block" : "none"} fontSize={14}>
          {date[0]}
          <br />
          <Typography
            noWrap
            display={showTime ? "block" : "none"}
            fontSize={14}
          >
            {date[1]}
          </Typography>
        </Box>
      ) : (
        "-"
      )}
    </>
  );
};

export default Timestamp;
