import { displayLabel } from "constants";

const TABLE_HEADING = {
  followUps: "Follow Ups",
  payoutDue: "Payout Due",
  confirmationPending: "Confirmation Pending",
  shipmentsDue: "Shipments Due",
  openOrders: "Open Orders",
  refundDue: "Refund Due",
  default: "Order list",
};

const QUICK_FILTER = {
  ...displayLabel,
  all: "All",
};

const PLANNED_SHIPMENT_STATUS = [
  { state: "New" },
  { state: "Rejected" },
  { state: "Planned" },
  { state: "Revoke" },
  { state: "Paid" },
  { state: "Release" },
];

const orderListTabs = {
  PENDING_PAYMENT_TAB: "Pending payment",
  FULL_PAYMENT_TAB: "Full payment",
};

const PAYMENT_TYPE_LIST = [
  "Cash & Carry",
  "Cash & Carry - Advance",
  "Cash & Carry - Part",
  "Credit",
];
const SO_DETAIL_COLUMN_HEADER = "SO details";
const PLANT_SUPPLY_HEADER = [
  "Buyer’s Order ID",
  "Customer Name",
  "SKU Name",
  "Quantity Ordered",
  "Quantity Shipped",
  "Price/Unit",
  SO_DETAIL_COLUMN_HEADER,
];

const SO_TYPE = ["PRODUCTION_SO", "DISPATCH_SO"];

const ORDER_PAYMENT_TRANSACTION_TYPES = [
  { label: "Nodal", value: "NODAL" },
  { label: "Non Nodal", value: "NON_NODAL" },
];

const ORDER_TRANSACTION_TYPES = {
  NODAL: "Nodal",
  NON_NODAL: "Non Nodal",
};

export {
  TABLE_HEADING,
  QUICK_FILTER,
  PLANNED_SHIPMENT_STATUS,
  PAYMENT_TYPE_LIST,
  orderListTabs,
  PLANT_SUPPLY_HEADER,
  SO_DETAIL_COLUMN_HEADER,
  SO_TYPE,
  ORDER_PAYMENT_TRANSACTION_TYPES,
  ORDER_TRANSACTION_TYPES,
};
