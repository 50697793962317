import { useEffect, useState, useMemo } from "react";
import { Box, useTheme, IconButton, Typography } from "@mui/material";
import { AttachmentSharp } from "@mui/icons-material";
import PropTypes from "prop-types";

import { MAX_FILE_SIZE } from "constants";
import { getSubstring } from "utils";

const Attachment = ({ onFileUpload, file, style, boxStyle }) => {
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState("");
  const [files, setFiles] = useState();

  useEffect(() => {
    file && setFiles(file);
  }, []);

  const fileName = useMemo(() => {
    if (files) {
      return files?.name
        ? getSubstring(files?.name, 20)
        : getSubstring(files, 20);
    } else {
      return "Attach file";
    }
  }, [files]);

  const onFileAttach = (evt) => {
    let attachedFiles = evt?.target?.files?.[0];
    if (attachedFiles) {
      let validatedResult = true;
      const fileSize = attachedFiles.size / 1024 / 1024; //convert to MB
      if (fileSize.toFixed(2) > MAX_FILE_SIZE) {
        setErrorMessage("Allowed max file size is 2MB");
        validatedResult = false;
      }
      if (validatedResult) {
        setErrorMessage("");
        let isFileAttached = onFileUpload(attachedFiles);
        isFileAttached && setFiles(attachedFiles);
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          border: 1,
          padding: 2,
          borderStyle: "solid",
          borderWidth: 1,
          borderRadius: 1,
          borderColor: theme.palette.text.disabled,
          width: "80%",
          ...boxStyle,
        }}
      >
        <IconButton
          sx={{ ml: 0, ...style }}
          disableRipple={true}
          component="label"
        >
          <AttachmentSharp
            color="primary"
            style={{ marginRight: 10, paddingTop: 0 }}
          />
          <input
            aria-label="file"
            type="file"
            accept="image/*, .pdf, .xlx, .xlsx,.xls,.PDF"
            hidden
            onChange={(e) => onFileAttach(e)}
          />
          <Typography color={"primary"}>{fileName}</Typography>
        </IconButton>
      </Box>
      <Typography color={theme.palette.error.dark}>{errorMessage}</Typography>
    </>
  );
};

Attachment.propTypes = {
  file: PropTypes.string.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  style: PropTypes.object,
  boxStyle: PropTypes.object,
};

export default Attachment;
