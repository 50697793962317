import { useMemo } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Typography,
} from "@mui/material";

import {
  ExportCta,
  HyperLink,
  Loader,
  PaginationAction,
  StatusChip,
} from "components";
import { ColumnTitle, StyledTableRow } from "CustomStyledComponents";

import {
  downloadCsv,
  formatDate,
  moneyFormat,
  validateNull,
  dateConvertor,
} from "utils";
import { getCall_v2 } from "services";
import { useToaster } from "hooks";

import { STATUS_CHIP } from "constants";
import { FinancePayouts } from "../../constants";
import { success, error } from "constants";

const { payoutTypes, STATUS_DATA } = FinancePayouts;

const ROWS_PER_PAGE = 50;

const PayoutTable = ({
  payoutData,
  onPageChange,
  isLoading,
  page,
  filters,
}) => {
  const triggerToaster = useToaster();

  const getPaymentType = (type) => {
    const { IPTA, IPHA, IPA, REFUND, CASHBACK } = payoutTypes;
    const paymentType = {
      IPTA,
      IPHA,
      IPA,
      REFUND,
      CASHBACK,
    };
    return paymentType[type] || "";
  };

  const getStatusType = (status) => {
    switch (status) {
      case "PAYMENT_SUCCESS":
        return STATUS_CHIP.success;
      case "PAYMENT_REJECTED":
      case "PAYMENT_FAILED":
        return STATUS_CHIP.error;
      case "PAYMENT_ON_HOLD":
        return STATUS_CHIP.warning;
      default:
        break;
    }
  };

  const tableFields = useMemo(() => {
    return [
      {
        title: "Payout source",
        field: "orderType",
        render: (ele) => (
          <StatusChip
            label={
              ele?.orderType === FinancePayouts.orderTypes[0]
                ? ele?.orderType
                : "Order book"
            }
          />
        ),
      },
      {
        title: "Order number / Entity name",
        field: "orderNoAndEntityName",
        render: (ele) => (
          <Box style={{ maxWidth: 120 }}>
            <HyperLink title={ele?.customerOrdNumber} />
            <Typography fontSize={14}>{ele?.entityName}</Typography>
          </Box>
        ),
      },
      {
        title: "Created At",
        field: "payoutAuditTrailDto?.payoutUploadedAt",
        render: (ele) => (
          <Typography fontSize={14}>
            {dateConvertor(ele?.payoutAuditTrailDto?.payoutUploadedAt)}
          </Typography>
        ),
      },
      {
        title: "Initated At",
        field: "payoutAuditTrailDto?.payoutInitiatedAt",
        render: (ele) => (
          <Typography fontSize={14}>
            {dateConvertor(ele?.payoutAuditTrailDto?.payoutInitiatedAt)}
          </Typography>
        ),
      },
      {
        title: "Approved At",
        field: "payoutAuditTrailDto?.payoutApprovedAt",
        render: (ele) => (
          <Typography fontSize={14}>
            {dateConvertor(ele?.payoutAuditTrailDto?.payoutApprovedAt)}
          </Typography>
        ),
      },
      {
        title: "Payout type",
        field: "paymentType",
        render: (ele) => (
          <Typography style={{ width: 120 }} fontSize={14}>
            {getPaymentType(ele?.paymentType)}
          </Typography>
        ),
      },
      {
        title: "Utr number",
        field: "utr",
        render: (ele) => (
          <Typography style={{ width: 120 }} fontSize={14}>
            {validateNull(ele?.utr)}
          </Typography>
        ),
      },
      {
        title: "Order completion date",
        field: "invoiceDate",
        render: (ele) => {
          const invoiceDate =
            ele?.shipmentInvoiceDetails?.invoiceDetails[0]?.invoiceDate;
          return (
            <Typography fontSize={14}>
              {invoiceDate ? formatDate(invoiceDate) : "-"}
            </Typography>
          );
        },
      },
      {
        title: "Refund initiation date",
        field: "payoutInitiatedAt",
        render: (ele) => (
          <Typography fontSize={14}>
            {ele?.payoutInitiatedAt ? formatDate(ele?.payoutInitiatedAt) : "-"}
          </Typography>
        ),
      },
      {
        title: "Payout date",
        field: "payoutDate",
        render: (ele) => (
          <Typography fontSize={14}>{formatDate(ele?.payoutDate)}</Typography>
        ),
      },
      {
        title: "Payout amount",
        field: "amount",
        render: (ele) => (
          <Typography fontSize={14}> {moneyFormat(ele?.amount)}</Typography>
        ),
      },
      {
        title: "Status",
        field: "status",
        render: (ele) => (
          <StatusChip
            label={STATUS_DATA[ele.status]}
            type={getStatusType(ele.status)}
          />
        ),
      },
    ];
  }, []);

  const onExportData = async () => {
    try {
      const payoutUrl = `oms/payout/download/history`;
      const { data } = await getCall_v2(payoutUrl, {
        ...filters,
        limit: 5000,
        offset: 0,
      });
      triggerToaster(`Downloading file`, success);
      downloadCsv(data, "historical-payouts");
    } catch (err) {
      console.error(`Error in download, ${err}`);
      triggerToaster(`Please try again later`, error);
    }
  };

  const { payoutsList, totalCount } = payoutData;

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end">
        <ExportCta onExport={onExportData} />
      </Box>
      <Box component={Paper}>
        <TableContainer
          sx={{
            minWidth: 700,
            maxHeight: "calc(100vh - 120px)",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              {tableFields.map((el) => (
                <TableCell key={el.field}>
                  <ColumnTitle>{el.title}</ColumnTitle>
                </TableCell>
              ))}
            </TableHead>
            <TableBody>
              {isLoading || !payoutsList?.length ? (
                <StyledTableRow>
                  <TableCell colSpan={tableFields.length} align="center">
                    {isLoading ? (
                      <Loader sx={{ marginTop: 0 }} />
                    ) : (
                      <Typography textAlign={"center"}>
                        No data found
                      </Typography>
                    )}
                  </TableCell>
                </StyledTableRow>
              ) : (
                payoutsList?.map((row) => (
                  <StyledTableRow key={row?.customerOrdNumber}>
                    {tableFields?.map((el) => (
                      <TableCell key={el?.field}>{el.render(row)}</TableCell>
                    ))}
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!!payoutsList?.length && (
          <TablePagination
            rowsPerPageOptions={[ROWS_PER_PAGE]}
            rowsPerPage={ROWS_PER_PAGE}
            page={page}
            count={totalCount ?? 0}
            onPageChange={onPageChange}
            sx={{ display: "flex", flexDirection: "column-reverse" }}
            ActionsComponent={PaginationAction}
          />
        )}
      </Box>
    </Box>
  );
};

export default PayoutTable;
