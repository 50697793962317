import { useMemo } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { ViewOnly, TextField } from "components";
import { appendRupeeIcon, formatDate, getFixedValue } from "utils";

import theme from "themeProvider";
import StatusChip from "./StatusChip";

export const CustomViewOnly = ({ label, value }) => {
  return (
    <ViewOnly
      label={label}
      labelStyle={{ width: "100%", marginBottom: 12 }}
      valueStyle={{ color: theme.palette.text.primary }}
      rest={{ flexDirection: "column" }}
      value={value}
    />
  );
};

export const GridSkeleton = ({
  firstChild,
  secondChild,
  thirdChild,
  containerStyle = {},
  firstChildMd = 4,
  secondChildMd = 4,
  thirdChildMd = 4,
}) => {
  return (
    <Grid container spacing={2} style={{ ...containerStyle }}>
      <Grid item xs={6} md={firstChildMd}>
        {firstChild}
      </Grid>
      <Grid item xs={6} md={secondChildMd}>
        {secondChild}
      </Grid>
      {thirdChild && (
        <Grid item xs={6} md={thirdChildMd}>
          {thirdChild}
        </Grid>
      )}
    </Grid>
  );
};

export const ShipmentHeaderColumn = ({ label, value }) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography style={{ fontWeight: 600 }}>{label}</Typography>
      <Typography>{value}</Typography>
    </Box>
  );
};

export const ShipmentHeader = ({
  shipmentNumber,
  sellerOrderNumber,
  plannedDispatchDate,
  actualDispatchDate,
  orderStatus,
  orderOnTrack,
  invoiceNumber,
  isCreditOrder,
  invoicePaymentStatus,
}) => {
  return useMemo(
    () => (
      <>
        <ShipmentHeaderColumn label={`Shipment no.`} value={shipmentNumber} />
        <ShipmentHeaderColumn label={`Invoice no.`} value={invoiceNumber} />
        <ShipmentHeaderColumn
          label={`Seller order no.`}
          value={sellerOrderNumber}
        />
        {isCreditOrder && (
          <ShipmentHeaderColumn
            label={`Payment status`}
            value={invoicePaymentStatus}
          />
        )}
        <ShipmentHeaderColumn
          label={`Scheduled dispatch date`}
          value={formatDate(plannedDispatchDate)}
        />
        <ShipmentHeaderColumn
          label={`Dispatch date`}
          value={formatDate(actualDispatchDate)}
        />
        <StatusChip label={orderStatus} status={orderOnTrack} />
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      plannedDispatchDate,
      actualDispatchDate,
      orderStatus,
      orderOnTrack,
      invoiceNumber,
    ],
  );
};

export const ShipmentDeliveryData = ({ shipmentValue, mode, deliveryType }) => {
  return useMemo(
    () => (
      <GridSkeleton
        containerStyle={{ marginBottom: 32 }}
        firstChild={
          <CustomViewOnly
            label="Shipment value"
            value={appendRupeeIcon(getFixedValue(shipmentValue))}
          />
        }
        secondChild={<CustomViewOnly label="Mode of shipment" value={mode} />}
        thirdChild={
          <CustomViewOnly label="Delivery type" value={deliveryType ?? ""} />
        }
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};

export const CustomTextField = ({
  rightPad = true,
  mask = false,
  value,
  onChange,
  type,
  digitsAfterDot = 3,
  decimal = false,
  highlightError = false,
  disablePaste = true,
  customPadding,
  ...rest
}) => (
  <TextField
    sx={{
      "& legend": { display: "none" },
      "& fieldset": { top: 0 },
      input: {
        textAlign: "center",
        padding: customPadding,
      },
    }}
    highlightError={highlightError}
    style={{
      paddingRight: rightPad ? 6 : 0,
      ...(mask && { WebkitTextSecurity: "disc" }),
      ...(mask && { WebkitTextSecurity: "disc" }),
    }}
    value={type === "number" && decimal ? getFixedValue(value, 4) : value}
    onChange={onChange}
    onPaste={(e) => disablePaste && e.preventDefault()}
    type={type}
    autoComplete="off"
    InputProps={{
      inputProps: {
        min: 0,
        step: 0.001,
      },
    }}
    {...rest}
  />
);

export const ErrorMsg = ({ msg }) => (
  <Typography
    style={{
      fontSize: 12,
      color: "red",
      visibility: msg ? "visible" : "hidden",
      height: 12,
    }}
  >
    {msg}
  </Typography>
);

export const ColumnView = ({ label, children, bold = false, style }) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
        ...style,
      }}
    >
      <Typography
        fontWeight={bold ? "bold" : "normal"}
        style={{ marginBottom: 6 }}
      >
        {label}
      </Typography>
      <Box>{children}</Box>
    </Box>
  );
};

export const InputField = ({ ...rest }) => {
  return (
    <TextField
      data-testid="text-field"
      aria-label="text-field-label"
      variant="outlined"
      size="small"
      {...rest}
    />
  );
};
