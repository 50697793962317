import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Box, Typography, Divider, Grid } from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";

import { ButtonV1, TooltipLabel } from "components";
import { ColumnView, InputField } from "./ShipmentCustomComponents";
import { makeTrackingData } from "./DataModel";
import theme from "themeProvider";
import { getCall, postCall_v2 } from "services";
import { success, error } from "./constants";
import { useToaster } from "hooks";

export const GridSkeleton = ({
  firstChild,
  secondChild,
  thirdChild,
  fourthChild,
  containerStyle = {},
}) => {
  return (
    <Grid container spacing={2} style={{ ...containerStyle }}>
      <Grid item xs={6} md={3}>
        {firstChild}
      </Grid>
      <Grid item xs={6} md={3}>
        {secondChild}
      </Grid>
      <Grid item xs={6} md={3}>
        {thirdChild}
      </Grid>
      <Grid item xs={6} md={3}>
        {fourthChild}
      </Grid>
    </Grid>
  );
};

const TrackingShipment = ({ sapShipmentNumber = "", shipmentNumber = "" }) => {
  const [trackingInfo, setTrackingInfo] = useState(null);
  const [blockRender, setBlockRender] = useState(true);
  const triggerToaster = useToaster();

  const getTrackingData = async () => {
    const data = await getCall(`/oms/shipment/plant-supply/trip-details`, {
      sapShipmentNumber,
      shipmentNumber,
    });
    if (data) {
      setTrackingInfo(data);
    }
    setBlockRender(false);
  };

  useEffect(() => {
    getTrackingData();
    /*eslint-disable */
  }, []);

  const displayDeliveryMap = () => {
    const trackingUrl =
      trackingInfo?.share_url?.substring(0, 4) === "http"
        ? trackingInfo?.share_url
        : `http://${trackingInfo?.share_url}`;
    window.open(trackingUrl, "_blank");
  };

  const { getValues, control } = useForm({
    mode: "onTouched",
  });

  const OnUpdateTracking = async () => {
    try {
      const payload = makeTrackingData(
        shipmentNumber,
        sapShipmentNumber,
        getValues(),
        trackingInfo,
      );

      const data = await postCall_v2(
        "/oms/shipment/plant-supply/trip-details",
        payload,
      );
      if (data) {
        triggerToaster("Tracking details updated successfully", success);
      }
    } catch (err) {
      triggerToaster(err?.data?.detail, error);
    }
  };

  if (blockRender) return;
  return (
    <Box
      as={"form"}
      style={{
        borderBottom: 1,
        borderColor: theme.palette.grey["400"],
        borderBottomStyle: "solid",
      }}
      mb={12}
    >
      <Box display={"flex"} justifyContent={"space-between"} mb={4}>
        <Box display={"flex"} alignItems={"center"}>
          <Typography fontWeight={"bold"}>Tracking details</Typography>
          <TooltipLabel
            placement="right"
            title={
              <Typography
                border={1}
                p={2}
                borderRadius={1}
                borderColor={theme.palette.text.secondary}
                fontSize={12}
              >
                {"Shipment details refreshes after every 2 hours."}
              </Typography>
            }
            labelChildren={
              <IconButton disableRipple={true}>
                <InfoOutlinedIcon />
              </IconButton>
            }
          />
        </Box>

        <Box display={"flex"} alignItems={"center"}>
          <ButtonV1
            startIcon={
              <PlaceIcon
                fontSize="16"
                sx={{
                  color: trackingInfo?.share_url
                    ? theme.palette.primary.main
                    : theme.palette.text.disabled,
                }}
              />
            }
            disableRipple={true}
            variant="text"
            disabled={!trackingInfo?.share_url}
            title="Track shipment"
            size="small"
            onClick={displayDeliveryMap}
          />

          <Divider
            orientation="vertical"
            sx={{
              ml: 2,
              mr: 2,
              height: 12,
              color: `${theme.palette.grey["400"]}`,
            }}
          />

          <ButtonV1
            disableRipple={true}
            title="Update tracking details"
            size="small"
            variant="text"
            onClick={OnUpdateTracking}
          />
        </Box>
      </Box>
      <Box mb={8}>
        <GridSkeleton
          firstChild={
            <Controller
              control={control}
              defaultValue={trackingInfo?.vehicle?.license_plate}
              name="truckNo"
              render={({ field: { value, onChange } }) => (
                <ColumnView label="Truck no.">
                  <InputField onChange={onChange} value={value} />
                </ColumnView>
              )}
            />
          }
          secondChild={
            <Controller
              control={control}
              defaultValue={trackingInfo?.lr_number}
              name="lrNo"
              render={({ field: { value, onChange } }) => (
                <ColumnView label="LR no.">
                  <InputField onChange={onChange} value={value} />
                </ColumnView>
              )}
            />
          }
          thirdChild={
            <Controller
              control={control}
              defaultValue={trackingInfo?.drivers?.[0]?.contact}
              name="driverNo"
              render={({ field: { value, onChange } }) => (
                <ColumnView label="Driver number">
                  <InputField onChange={onChange} value={value} />
                </ColumnView>
              )}
            />
          }
        />
      </Box>
    </Box>
  );
};

export default TrackingShipment;
