import { useCallback, useState } from "react";
import CreditApprovalTable from "./CreditApprovalTable";
import Filter from "../components/Filter";

const CreditApprove = () => {
  const [filter, setFilter] = useState(null);

  const handleResetFilter = useCallback(() => setFilter(null), []);
  const handleSubmitFilter = useCallback((value) => setFilter(value), []);

  return (
    <>
      <Filter onReset={handleResetFilter} onSumbit={handleSubmitFilter} />
      <CreditApprovalTable filter={filter} />
    </>
  );
};

export default CreditApprove;
