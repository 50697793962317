import {
  Box,
  Typography,
  Tooltip,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import theme from "themeProvider";

const HoverBlock = ({
  data,
  open,
  onClose,
  LabelChildren,
  invoicedTCSAmount,
  isInvoiceAmount,
}) => {
  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
        sx: {
          "& .MuiTooltip-tooltip": {
            border: 1,
            borderColor: theme.palette.grey[400],
            color: theme.palette.grey["A300"],
            backgroundColor: "white",
            fontSize: 14,
            textAlign: "left",
            "& .MuiTooltip-arrow::before": {
              color: "white",
              border: 1,
              borderColor: theme.palette.grey[400],
            },
          },
        },
      }}
      onClose={onClose}
      open={open}
      placement="bottom"
      arrow
      title={
        <>
          {!!data && (
            <Box borderRadius={1} maxWidth={280} minWidth={160}>
              {Object.entries(data).map(([key, value], i) => (
                <Box key={key} pt={2} pb={2} pl={2}>
                  <Box mb={1}>
                    <Typography
                      fontSize={12}
                      color={theme.palette.text.secondary}
                      fontWeight={600}
                    >
                      {key}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize={14}>{value}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
          {!!isInvoiceAmount && (
            <FormControlLabel
              checked={!!invoicedTCSAmount}
              control={<Checkbox size="small" />}
              disabled
              label={<Typography fontSize={14}>0.1% TCS on IT</Typography>}
            />
          )}
        </>
      }
    >
      {LabelChildren}
    </Tooltip>
  );
};

export default HoverBlock;
