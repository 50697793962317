import { Typography, Box } from "@mui/material";
import { styled } from "@mui/system";

import { GOOGLE_AUTH_URL, OUTLOOK_AUTH_URL } from "constants";

const CustomAnchor = styled("a")(({ borderColor }) => ({
  cursor: "pointer",
  color: "unset",
  textDecoration: "unset",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 10,
  borderRadius: 5,
  border: `1px solid ${borderColor}`,
}));

function Oauth() {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={4} width={"100%"}>
      <CustomAnchor href={GOOGLE_AUTH_URL} borderColor={"#3c88ba"}>
        <img width={32} src="/assets/google.png" alt="google link " />
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          style={{ color: "#3c88ba" }}
        >
          Continue with Google
        </Typography>
      </CustomAnchor>
      <CustomAnchor href={OUTLOOK_AUTH_URL} borderColor={"#3c88ba"}>
        <img width={32} src="/assets/outlook.png" alt="outlook link " />
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          style={{ color: "#3c88ba" }}
        >
          Continue with Outlook
        </Typography>
      </CustomAnchor>
    </Box>
  );
}
export default Oauth;
