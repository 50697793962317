import { memo, useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import PropTypes from "prop-types";

import SummaryBox from "./SummaryBox";
import { useFetchData } from "hooks";
import { summaryTag, tags } from "./constant";
import { useLocation } from "react-router-dom";

const SummaryBlock = styled(Box)({
  display: "flex",
  flex: 1,
  gap: 20,
  flexDirection: "column",
});

const Summary = ({ allowedBU = "" }) => {
  const { search } = useLocation();
  const summaryUrl = "/oms/dashboard/count";

  const queryFilter = useMemo(() => {
    if (search) {
      return `${search}&businessUnit=${allowedBU || ""}`;
    }
    return `?businessUnit=${allowedBU || ""}`;
  }, [allowedBU, search]);

  const {
    refetch: followUp,
    data: followUpList,
    isFetching: isFollowUpFetching,
  } = useFetchData("bucket-follow-up", `${summaryUrl}/followup${queryFilter}`);

  const {
    refetch: shipmentDue,
    data: shipmentDueList,
    isFetching: isShipmentFetching,
  } = useFetchData(
    "bucket-shipments-due",
    `${summaryUrl}/shipmentsdue${queryFilter}`,
  );

  const {
    refetch: openOrders,
    data: openOrderList,
    isFetching: isOpenOrderFetching,
  } = useFetchData(
    "bucket-open-order",
    `${summaryUrl}/openorder${queryFilter}`,
  );

  const {
    refetch: paymentDue,
    data: paymentDueList,
    isFetching: isPayoutFetching,
  } = useFetchData(
    "bucket-payout-due",
    `${summaryUrl}/payoutdue${queryFilter}`,
  );

  const {
    refetch: confirmationPending,
    data: confirmationPendingList,
    isFetching: isPendingOrderFetching,
  } = useFetchData(
    "bucket-confirmation-pending",
    `${summaryUrl}/confirmationpending${queryFilter}`,
  );
  const {
    refetch: refundDue,
    data: refundDueList,
    isFetching: isRefundFetching,
  } = useFetchData(
    "bucket-refund-due",
    `${summaryUrl}/refundDue${queryFilter}`,
  );

  const invokeAllQueries = () => {
    followUp();
    shipmentDue();
    openOrders();
    confirmationPending();
    paymentDue();
    refundDue();
  };

  useEffect(() => {
    invokeAllQueries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const mapQueryResultsToMap = (list, type, isFetching) => {
    let mappedObj = { isFetching };

    if (list?.length) {
      // eslint-disable-next-line array-callback-return
      list?.map((ele) => {
        mappedObj[ele[type]] = ele?.count;
      });
    }
    return mappedObj;
  };

  return (
    <Box sx={{ display: "flex", gap: 6 }}>
      <SummaryBlock>
        <SummaryBox
          heading={tags[summaryTag.openOrder].label}
          info={mapQueryResultsToMap(
            openOrderList?.data,
            "openorder",
            isOpenOrderFetching,
          )}
          tag={summaryTag.openOrder}
        />
        <SummaryBox
          heading={tags[summaryTag.confirmationPending].label}
          info={mapQueryResultsToMap(
            confirmationPendingList?.data,
            "confirmationpending",
            isPendingOrderFetching,
          )}
          tag={summaryTag.confirmationPending}
        />
      </SummaryBlock>
      <SummaryBlock>
        <SummaryBox
          heading={tags[summaryTag.shipmentsDue].label}
          info={mapQueryResultsToMap(
            shipmentDueList?.data,
            "shipments",
            isShipmentFetching,
          )}
          tag={summaryTag.shipmentsDue}
        />
        <SummaryBox
          heading={tags[summaryTag.followUps].label}
          info={mapQueryResultsToMap(
            followUpList?.data,
            "followup",
            isFollowUpFetching,
          )}
          tag={summaryTag.followUps}
        />
      </SummaryBlock>
      <SummaryBlock>
        <SummaryBox
          heading={tags[summaryTag.payoutDue].label}
          info={mapQueryResultsToMap(
            paymentDueList?.data,
            "payoutdue",
            isPayoutFetching,
          )}
          tag={summaryTag.payoutDue}
        />
        <SummaryBox
          heading={tags[summaryTag.refundDue].label}
          info={mapQueryResultsToMap(
            refundDueList?.data,
            "refunddue",
            isRefundFetching,
          )}
          tag={summaryTag.refundDue}
        />
      </SummaryBlock>
    </Box>
  );
};

Summary.propTypes = {
  filterQuery: PropTypes.object,
};

export default memo(Summary);
