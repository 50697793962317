import { Box, Divider, Typography, IconButton } from "@mui/material";
import { FileDownloadOutlined } from "@mui/icons-material";
import PropTypes from "prop-types";

import theme from "themeProvider";
import { ButtonV1 } from "components";
import { dateConvertor, timeConvertor } from "utils";

const ModalContent = ({
  fileType,
  toggleModal,
  files,
  onFileDownload,
  onFileDelete,
}) => {
  const dateFormat = (date) => dateConvertor(date);
  const timeFormat = (date) => timeConvertor(date);

  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography variant="h3" fontWeight={600}>
          {fileType.label}
        </Typography>
        <Typography
          color="primary"
          fontWeight={600}
          style={{ cursor: "pointer" }}
          onClick={toggleModal}
        >
          Close
        </Typography>
      </Box>
      <Box>
        {Object.keys(files).map((ele, k) => {
          return (
            <Box
              py={3}
              px={5}
              mb={2}
              key={k}
              style={{
                border: 1,
                borderStyle: "solid",
                borderColor: theme.palette.grey["700"],
                borderRadius: 8,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box display={"flex"} width={"80%"}>
                <Typography
                  style={{
                    fontSize: 16,
                    color: theme.palette.grey["A300"],
                  }}
                >
                  {files[ele]?.originalName.length > 30
                    ? files[ele]?.originalName?.substring(0, 30) + "..."
                    : files[ele]?.originalName}
                </Typography>
                <Divider
                  orientation="vertical"
                  sx={{
                    borderColor: theme.palette.grey[400],
                    ml: 2,
                    mr: 2,
                    mt: 1.5,
                    height: 12,
                  }}
                />
                <Box display={"flex"} color={theme.palette.text.secondary}>
                  <Typography mr={2}>
                    {dateFormat(files[ele]?.whenCreated)}
                  </Typography>
                  <Typography>{timeFormat(files[ele]?.whenCreated)}</Typography>
                </Box>
              </Box>

              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"end"}
              >
                <IconButton
                  color="primary"
                  style={{ padding: 0 }}
                  onClick={() => onFileDownload(files[ele], fileType.fileType)}
                >
                  <FileDownloadOutlined fontSize="small" color="primary" />
                </IconButton>

                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    marginRight: 2,
                    marginLeft: 2,
                    borderColor: theme.palette.grey["700"],
                  }}
                />
                <ButtonV1
                  style={{
                    minWidth: 20,
                    padding: 0,
                    backgroundColor: "transparent",
                  }}
                  onClick={() => {
                    onFileDelete(fileType, files[ele], toggleModal);
                  }}
                  title={
                    <img
                      width={16}
                      src="/assets/trash-2.svg"
                      style={{ stroke: theme.palette.primary.main }}
                      alt="Trash icon"
                    />
                  }
                />
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

ModalContent.propTypes = {
  fileType: PropTypes.object,
  files: PropTypes.object,
  toggleModal: PropTypes.func,
  onFileDownload: PropTypes.func,
  onFileDelete: PropTypes.func,
};

export default ModalContent;
