export const OrderDetails_constant = {
  OrderStatus: {
    delivered: "Delivered",
    cancelled: "Cancelled",
    placed: "Placed",
    inProgress: "Delivery in progress",
    created: "Created",
    partiallyDelivered: "Partially Delivered",
    declined: "Declined",
  },
};
