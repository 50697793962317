import { createSlice } from "@reduxjs/toolkit";

export const userData = createSlice({
  name: "userData",
  initialState: {},
  reducers: {
    setUserDataAction: (state, action) => {
      return action.payload;
    },
    remUserDataAction: () => {
      return {};
    },
  },
});

export const { setUserDataAction, remUserDataAction } = userData.actions;

export default userData.reducer;
