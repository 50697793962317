import { useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextareaAutosize, Box } from "@mui/material";
import { styled } from "@mui/system";

import { GridSkeleton } from "CustomStyledComponents";
import { ColumnView } from "./CustomDetailComponents";
import theme from "themeProvider";

const TextArea = styled(TextareaAutosize)({
  fontFamily: "inherit",
  minWidth: 240,
  padding: 8,
  borderRadius: 4,
  borderColor: "transparent",
  color: theme.palette.text.secondary,
  maxHeight: 60,
  background: "transparent",
  resize: "none",
});

const Comments = ({ orderInfo }) => {
  const { control } = useFormContext();
  const instructionsRef = useRef(null);
  const buyerCommentsRef = useRef(null);
  const sellerCommentsRef = useRef(null);

  const handleFocusOut = (ref) => {
    ref.current.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <Box
      pb={20}
      sx={{
        borderRadius: 2,
        background:
          "linear-gradient(to top, rgb(178 178 178 / 13%),rgb(178 178 178 / 5%),rgb(178 178 178 / 0%))",
      }}
    >
      <GridSkeleton
        firstChild={
          <ColumnView label={"Special delivery instructions"} bold={true}>
            <Controller
              control={control}
              name={"specialDeliveryInstructions"}
              defaultValue={orderInfo?.specialDeliveryInstruction}
              render={({ field: { value, onChange } }) => (
                <TextArea
                  placeholder="Please enter your comments"
                  value={value}
                  onChange={onChange}
                  ref={instructionsRef}
                  onBlur={() => handleFocusOut(instructionsRef)}
                  minRows={3}
                  style={{
                    overflowY: value?.length > 90 ? "scroll" : "hidden",
                  }}
                />
              )}
            />
          </ColumnView>
        }
        secondChild={
          <ColumnView label={"Buyer comments"} bold={true}>
            <Controller
              control={control}
              name={"buyerComments"}
              defaultValue={orderInfo?.buyerComments}
              render={({ field: { value, onChange } }) => (
                <TextArea
                  placeholder="Please enter your comments"
                  ref={buyerCommentsRef}
                  value={value}
                  onChange={onChange}
                  onBlur={() => handleFocusOut(buyerCommentsRef)}
                  minRows={3}
                  style={{
                    overflowY: value?.length > 90 ? "scroll" : "hidden",
                  }}
                />
              )}
            />
          </ColumnView>
        }
        thirdChild={
          <ColumnView label={"Seller comments"} bold={true}>
            <Controller
              control={control}
              name={"sellerComments"}
              defaultValue={orderInfo?.sellerComments}
              render={({ field: { value, onChange } }) => (
                <TextArea
                  ref={sellerCommentsRef}
                  placeholder="Please enter your comments"
                  value={value}
                  onChange={onChange}
                  minRows={3}
                  onBlur={() => handleFocusOut(sellerCommentsRef)}
                  style={{
                    overflowY: value?.length > 90 ? "scroll" : "hidden",
                  }}
                />
              )}
            />
          </ColumnView>
        }
      />
    </Box>
  );
};

export default Comments;
