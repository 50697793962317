import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import ButtonV1 from "components/ButtonV1";
import TooltipLabel from "components/TooltipLabel";

const supportType = {
  raiseTicket: "https://jswone.atlassian.net/servicedesk/customer/portal/9",
  faq: "https://docs.google.com/document/d/12E7QAfcV7ddZUgZXeP2BKLQzOnKj8NtpmgMZqq1aZ44",
  video:
    "https://jswone.atlassian.net/wiki/spaces/TEC/pages/1616019620/Training+Videos",
};

const Support = ({ open }) => {
  const theme = useTheme();

  const handleClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Box
      style={{
        position: "fixed",
        bottom: 20,
        left: 10,
        color: "unset",
      }}
    >
      <TooltipLabel
        placement="right-start"
        customStyle={{
          "& .MuiTooltip-tooltip": {
            border: `1px solid ${theme.palette.grey["400"]}`,
            padding: "8px",
          },
          "& .MuiTooltip-arrow": {
            "&::before": {
              backgroundColor: "white",
              border: `1px solid ${theme.palette.grey["400"]}`,
            },
          },
        }}
        title={
          <>
            <ButtonV1
              title={"Click here to raise a support ticket"}
              size={"small"}
              variant="text"
              onClick={() => handleClick(supportType.raiseTicket)}
            />
            <Divider />
            <ButtonV1
              title={"FAQ Document"}
              size={"small"}
              variant="text"
              onClick={() => handleClick(supportType.faq)}
            />
            <Divider />
            <ButtonV1
              title={"ERP Training Videos"}
              size={"small"}
              variant="text"
              onClick={() => handleClick(supportType.video)}
            />
          </>
        }
        labelChildren={
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{ cursor: "pointer" }}
          >
            <SupportAgentIcon fontSize="large" color="primary" />
            {open && <Typography paddingLeft={3}>Help desk</Typography>}
          </Box>
        }
      />
    </Box>
  );
};

export default Support;
