import { useRef } from "react";
import { Box, Typography } from "@mui/material";

import TaggedPayment from "./TaggedPayment";
import UntaggedPayment from "./UntaggedPayment";

const IncomingPayments = () => {
  const taggedPayment = useRef();

  /**
   * @description invoked when a untagged payment is tagged & need
   *  to refetch latest data
   */
  const invokeTaggedPaymentApi = () => {
    taggedPayment.current.triggerTaggedPaymentApi();
  };

  return (
    <Box sx={{ paddingX: 4, paddingTop: 3 }}>
      <Typography sx={{ fontWeight: 600, fontSize: 28 }}>
        Incoming payments
      </Typography>
      <Box mb={4}>
        <UntaggedPayment triggerTaggedPaymentApi={invokeTaggedPaymentApi} />
      </Box>
      <TaggedPayment ref={taggedPayment} />
    </Box>
  );
};

export default IncomingPayments;
