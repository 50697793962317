import { persistStore, persistReducer } from "redux-persist";
import autoMergeLevel1 from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";

import rootSaga from "./saga";
import rootReducer from "./rootReducer";

const persistConfig = {
  key: "root",
  storage: autoMergeLevel1,
  whitelist: [
    "isLoggedIn",
    "userData",
    // "selectedTab",
    // "orderDetails",
    "selectedUserProfile",
  ],
};

const pReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

// Add logger if env is not PROD
if (process.env.REACT_APP_ENV !== "PROD") {
  middleware.push(logger);
}

export const store = configureStore({
  reducer: pReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middleware),
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
