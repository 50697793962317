import { Box, Typography, Divider } from "@mui/material";
import { ViewOnly } from "components";

import theme from "themeProvider";

export const ViewableItem = ({ label, value }) => {
  return (
    <ViewOnly
      label={label}
      labelStyle={{ width: "100%", marginBottom: 6, fontWeight: "normal" }}
      valueStyle={{ color: theme.palette.text.primary, fontWeight: 600 }}
      rest={{ flexDirection: "column" }}
      value={value}
    />
  );
};

export const LineSeperator = () => (
  <Divider style={{ borderBottomWidth: 2, marginBottom: 16 }} />
);

export const RowSeparator = ({ children }) => <Box mb={8}>{children}</Box>;

export const ColumnView = ({ label, children, bold = false }) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
      }}
    >
      <Typography
        fontWeight={bold ? "bold" : "normal"}
        style={{ marginBottom: 6 }}
      >
        {label}
      </Typography>
      <Box>{children}</Box>
    </Box>
  );
};
