import { useCallback } from "react";

import { getCall_v2 } from "services/updateCall";
import { success, error } from "constants";
import useToaster from "./useToaster";

const useDownload = () => {
  const triggerToaster = useToaster();

  const downloadFile = useCallback((res, fileName) => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(res);
    link.download = fileName;
    link.click();
  }, []);

  const downloadDocument = useCallback(async (url, fileName, params = {}) => {
    try {
      const { data } = await getCall_v2(url, params, "blob");
      downloadFile(data, fileName);
      triggerToaster(`Downloading ${fileName} document`, success);
    } catch (err) {
      triggerToaster(`Unable to download ${fileName} document`, error);
    } finally {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   * @param {Array} data
   * @param {String} filename
   * @description Generate CSV and download with fileName.
   */
  const downloadCSV = (data = [{}], filename) => {
    const csvHead = `${Object.keys(data[0]).join(",")}\n`;

    const csvBody = data
      .map((item) => Object.values(item).join(","))
      .join("\n");

    const blob = new Blob([[csvHead, csvBody].join("")], {
      type: "text/csv",
    });

    downloadFile(blob, `${filename}.csv`);
  };

  return { downloadDocument, downloadFile, downloadCSV };
};

export default useDownload;
