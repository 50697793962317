import {
  Paper,
  Typography,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  TablePagination,
  Box,
} from "@mui/material";

import { StatusChip } from "pages/FinancePayout/PendingPayoutsTab/components";
import { ButtonV1, ExportCta, PaginationAction } from "components";

import { dateConvertor, downloadCsv, moneyFormat, validateNull } from "utils";
import { getCall_v2 } from "services";
import { useToaster } from "hooks";

import { success } from "constants";
import { error } from "constants";
import { FinancePayouts } from "../../constants";

const { ORDER_BLOCK_HISTORICAL_HEADER } = FinancePayouts;

const OrderBlockTable = ({
  payoutData,
  onPageChange,
  isLoading,
  onCloseTx,
  filters,
}) => {
  const { payoutSummaries, totalCount, offset, limit } = payoutData;
  const triggerToaster = useToaster();

  const onExportData = async () => {
    try {
      const payoutUrl = `oms/off-oms-payout/download/order-block/historical`;
      const { data } = await getCall_v2(payoutUrl, {
        ...filters,
        limit: 5000,
        offset: 0,
      });
      triggerToaster(`Downloading file`, success);
      downloadCsv(data, "order_block_payout");
    } catch (err) {
      console.error(`Error in download, ${err}`);
      triggerToaster(`Please try again later`, error);
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <ExportCta onExport={onExportData} />
      </Box>
      <Paper>
        <TableContainer
          sx={{
            minWidth: 700,
            maxHeight: "calc(100vh - 120px)",
          }}
        >
          <Table stickyHeader>
            <TableHead style={{ borderRadius: "10px 10px 0 0" }}>
              <TableRow>
                {ORDER_BLOCK_HISTORICAL_HEADER.map((heading, i) => (
                  <TableCell key={i}>{heading}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading && !payoutSummaries?.length && (
                <TableRow>
                  <TableCell colSpan={ORDER_BLOCK_HISTORICAL_HEADER.length + 1}>
                    <Typography textAlign={"center"}>No data found</Typography>
                  </TableCell>
                </TableRow>
              )}
              {!isLoading &&
                payoutSummaries?.map((record) => (
                  <TableRow key={record.id}>
                    <TableCell>{validateNull(record?.orderId)}</TableCell>
                    <TableCell>
                      <StatusChip status={record?.orderType} isOrderType />
                    </TableCell>
                    <TableCell>{validateNull(record.entityGstin)}</TableCell>
                    <TableCell>
                      {validateNull(record.creditInstrument)}
                    </TableCell>
                    <TableCell>{dateConvertor(record.date)}</TableCell>
                    <TableCell>{moneyFormat(record.orderAmount)}</TableCell>
                    <TableCell>{moneyFormat(record.advanceAmount)}</TableCell>
                    <TableCell>{moneyFormat(record.creditAmount)}</TableCell>
                    <TableCell>
                      <StatusChip status={record?.currentState?.state} />
                    </TableCell>
                    <TableCell>
                      <ButtonV1
                        title={"Close"}
                        size="small"
                        disabled={record?.isShortClosed}
                        onClick={async () => await onCloseTx(record.id)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!!payoutSummaries?.length && (
          <TablePagination
            rowsPerPageOptions={[limit]}
            rowsPerPage={limit}
            page={offset / limit}
            count={totalCount ?? 0}
            onPageChange={onPageChange}
            sx={{ display: "flex", flexDirection: "column-reverse" }}
            ActionsComponent={PaginationAction}
          />
        )}
      </Paper>
    </>
  );
};

export default OrderBlockTable;
