import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Grid, Box, Typography, Divider } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";

import { useToaster } from "hooks";
import { postCall_v2 } from "services";
import { success, error } from "constants";
import { ButtonV1, DatePickerV1, ModalBox, TextField } from "components";
import { moneyFormat, moneyTextField, removeTimeOffset } from "utils";
import { UpdateCreditInstrumentSchema } from "./test";
import Attachment from "../components/Attachment";
import CREDIT_UPDATE from "../constants";

const UpdateCreditInstrumentModal = ({
  open,
  handleModal,
  data,
  customerDetails,
  omsUserDetail,
}) => {
  const [filePath, setFilePath] = useState();
  const {
    control,
    reset,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      sanctionLimit: (+data?.creditSanctioned / CREDIT_UPDATE.lakh).toFixed(2),
      utilisedLimit: data?.creditUtilised,
      creditDays: data?.creditDueDay,
      gracePeriod: data?.gracePeriod,
      camId: data?.camId,
    },
    resolver: yupResolver(UpdateCreditInstrumentSchema),
  });

  const triggerToaster = useToaster();

  const onSaveDetails = async () => {
    const {
      sanctionLimit,
      utilisedLimit,
      creditDays,
      camId,
      gracePeriod,
      comments,
      expiryDate,
      advancePercentage,
    } = getValues();

    const res = await postCall_v2(
      "/oms/credit/updateSanctionAndUtilisedLimit",
      {
        creditInstrumentType: data?.creditInstrument,
        expiryDate: removeTimeOffset(expiryDate),
        creditType: data?.creditType,
        sanctionedLimit:
          +sanctionLimit?.replaceAll(",", "") * CREDIT_UPDATE.lakh ?? null,
        utilisedLimit: +utilisedLimit ?? null,
        pan: customerDetails?.data?.[0]?.pan,
        requestedBy: omsUserDetail.name,
        penalRate: data?.penalRate,
        creditDueDays: creditDays,
        camId,
        gracePeriod,
        comments: comments ?? "",
        path: filePath ? filePath : data?.path,
        companyName: customerDetails?.data?.[0]?.companyName,
        gstin: customerDetails?.data?.[0]?.gstin,
        advancePercentage,
      },
      "",
      () => {
        triggerToaster(`An error occured`, error);
      },
    );
    if (res.status === 200) {
      res?.data?.successful
        ? triggerToaster(res?.data?.message, success)
        : triggerToaster(res?.data?.message, error);
      handleModal(false);
      reset();
    }
  };

  const onCloseModal = () => {
    reset();
    handleModal(false);
  };

  const handleUpload = async (files) => {
    const formData = new FormData();
    formData.append("file", files);
    const res = await postCall_v2(
      `/oms/credit/document/upload?pan=${customerDetails?.data?.[0]?.pan}`,
      formData,
      "",
      (err) => {
        triggerToaster(
          err?.data?.message || `Error occurred, please try again`,
          error,
        );
      },
    );
    if (res) {
      setFilePath(res?.data?.creditDocumentPath);
      triggerToaster(`Files uploaded`, success);
      return true;
    }
  };

  const { REACT_APP_EXPIRY_DAYS } = process.env;
  const expiryDays = REACT_APP_EXPIRY_DAYS ?? 0;
  const minDate = new Date().getTime() + expiryDays * 24 * 60 * 60 * 1000;

  return (
    <>
      <ModalBox open={open} onCloseModal={onCloseModal} width="50%">
        <Box
          as="form"
          onSubmit={handleSubmit(onSaveDetails)}
          style={{ padding: 30 }}
        >
          <Typography fontWeight={600} fontSize={20} marginBottom={8}>
            Edit details
          </Typography>
          <Grid container marginBottom={8}>
            <Grid item xs={6} marginBottom={4}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>Instrument name</Typography>
                <Typography fontWeight={600}>{data?.creditType}</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} marginBottom={4}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>Expiry date</Typography>
                <Controller
                  control={control}
                  name={"expiryDate"}
                  defaultValue={new Date(data?.creditExpiry)}
                  render={({ field: { value, onChange } }) => (
                    <DatePickerV1
                      dateStyle={{ width: "80%" }}
                      value={value}
                      onChange={onChange}
                      minDate={new Date(minDate)}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={6} marginBottom={4}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>Credit program name</Typography>
                <Typography fontWeight={600}>
                  {data?.creditInstrument ?? "-"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} marginBottom={4}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>Penal charges</Typography>
                <Typography fontWeight={600}>
                  {data?.penalRate ?? "-"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} marginBottom={4}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>Credit days</Typography>
                <Controller
                  control={control}
                  name={"creditDays"}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      inputProps={{
                        style: {
                          height: 10,
                        },
                      }}
                      sx={{
                        width: "80%",
                      }}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Box>
              {/* <ErrorMsgV1 msg={errors?.customerProgramId?.message} /> */}
            </Grid>
            <Grid item xs={6} marginBottom={4}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>Grace period (in days)</Typography>
                <Controller
                  control={control}
                  name={"gracePeriod"}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      inputProps={{
                        style: {
                          height: 10,
                        },
                      }}
                      sx={{
                        width: "80%",
                      }}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Box>
              {/* <ErrorMsgV1 msg={errors?.customerProgramId?.message} /> */}
            </Grid>
            <Grid item xs={6} marginBottom={4}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>
                  Credit limit Reference ID
                </Typography>
                <Controller
                  control={control}
                  name={"camId"}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      inputProps={{
                        style: {
                          height: 10,
                        },
                      }}
                      sx={{
                        width: "80%",
                      }}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Box>
              {/* <ErrorMsgV1 msg={errors?.customerProgramId?.message} /> */}
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>Advance (%)</Typography>
                <Controller
                  control={control}
                  defaultValue={data?.advancePercentage}
                  name={"advancePercentage"}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      inputProps={{
                        style: {
                          height: 10,
                        },
                      }}
                      sx={{
                        width: "80%",
                      }}
                      type={"number"}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Box>
              {/* <ErrorMsgV1 msg={errors?.advancePercentage?.message} /> */}
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>CAM document</Typography>
                <Attachment onFileUpload={handleUpload} file={data?.fileName} />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>Remarks</Typography>
                <Controller
                  control={control}
                  name={"comments"}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      inputProps={{
                        style: {
                          height: 40,
                        },
                      }}
                      placeholder="Comment goes here"
                      multiline
                      sx={{
                        width: "80%",
                      }}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
          <Divider />
          <Grid container marginBottom={8} marginTop={4}>
            <Grid item xs={6} marginBottom={4}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>
                  Sanctioned limit(in lakhs)
                </Typography>
                <Controller
                  control={control}
                  name={"sanctionLimit"}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      inputProps={{
                        style: {
                          height: 10,
                        },
                      }}
                      sx={{
                        width: "80%",
                      }}
                      value={value}
                      {...moneyTextField(value, onChange)}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={6} marginBottom={4}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>Blocked limit</Typography>
                <Typography fontWeight={600}>
                  {moneyFormat(data?.creditBlocked)}
                </Typography>
                {/* <Controller
                  control={control}
                  name={"blockedLimit"}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      inputProps={{
                        style: {
                          height: 10,
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                      disabled
                      sx={{
                        width: "80%",
                      }}
                      value={data?.creditBlocked}
                      onChange={onChange}
                    />
                  )}
                /> */}
              </Box>
            </Grid>
            <Grid item xs={6} marginBottom={4}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>Utilised limit</Typography>
                <Controller
                  control={control}
                  name={"utilisedLimit"}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      inputProps={{
                        style: {
                          height: 10,
                        },
                      }}
                      sx={{
                        width: "80%",
                      }}
                      value={value}
                      {...moneyTextField(value, onChange)}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={6} marginBottom={4}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>Usable limit</Typography>
                <Typography fontWeight={600}>
                  {moneyFormat(data?.creditUsable)}
                </Typography>
                {/* <Controller
                  control={control}
                  name={"usableLimit"}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      inputProps={{
                        style: {
                          height: 10,
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                      disabled
                      sx={{
                        width: "80%",
                      }}
                      value={data?.creditUsable}
                      onChange={onChange}
                    />
                  )}
                /> */}
              </Box>
            </Grid>
            <Grid item xs={6} marginBottom={4}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>Available limit</Typography>
                <Typography fontWeight={600}>
                  {moneyFormat(data?.creditAvailable)}
                </Typography>
                {/* 
                <Controller
                  control={control}
                  name={"availableLimit"}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      inputProps={{
                        style: {
                          height: 10,
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                      disabled
                      sx={{
                        width: "80%",
                      }}
                      value={data?.creditAvailable}
                      onChange={onChange}
                    />
                  )}
                /> */}
              </Box>
            </Grid>
          </Grid>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <ButtonV1
              title={"Close"}
              variant="outlined"
              style={{ marginRight: 5 }}
              onClick={onCloseModal}
            />
            <ButtonV1 title={"Save"} type="submit" />
          </Box>
        </Box>
      </ModalBox>
    </>
  );
};

export default UpdateCreditInstrumentModal;
