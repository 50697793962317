import * as Yup from "yup";
import { RefundAndCashbackPayout } from "../constants";

const { REFUND_FORM, refundFields } = RefundAndCashbackPayout;

const refundCashbackManualSchema = Yup.object().shape({
  [REFUND_FORM]: Yup.array().of(
    Yup.object().shape({
      [refundFields.TO_ACCOUNT]: Yup.string().required(
        "Please select the to account",
      ),
    }),
  ),
});

export { refundCashbackManualSchema };
