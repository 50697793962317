import { useEffect } from "react";
import { TableCell, Box, Stack } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { StyledTableRow, ColumnTitle } from "CustomStyledComponents";
import { CustomTextField, ErrorMsg } from "./ShipmentCustomComponents";
import { TableWrapper } from "components";

import { getFixedValue, getAttributes } from "utils";
import theme from "themeProvider";
import { useCategoryTypeCheck } from "hooks";
import {
  shipmentUpdateHeaders,
  priceWarningInvoiceRate,
  shipmentStatus,
} from "./constants";

const WrapTableCell = ({ children }) => (
  <TableCell>
    <Box style={{ display: "flex" }}>{children}</Box>
  </TableCell>
);

const { invoiced, completed } = shipmentStatus;

function ShipmentLineItem({
  retriggerCalculation,
  lineItemsDetails,
  isPlantSupplyAndDispatched,
}) {
  const orderDetails = useSelector((state) => state.orderDetails);
  const {
    formState: { errors },
    getValues,
    control,
    watch,
  } = useFormContext();

  const { isDirect } = useCategoryTypeCheck();
  const getUom = (primaryQuantity) => primaryQuantity?.split(" ")[1];

  const confirmInvoiceQty = watch(
    `lineItemsDetails.${lineItemsDetails[0].lineitemId}.confirmInvoicedQty`,
  );

  // Calculate Invoiced amount etc on mount as invoice qty is auto filled and value is planned qty
  useEffect(() => {
    retriggerCalculation();
  }, [confirmInvoiceQty]);

  const getDiscountedPrice = (id) => {
    const lineItem = orderDetails?.lineItemDetails?.filter(
      (item) => item?.lineItemId === id,
    );
    if (lineItem) {
      const discountedPrice = lineItem[0]?.discountedPrice?.displayAmount;
      return discountedPrice?.replaceAll(",", "");
    }
  };

  const watchEnteredPrice = (lineItemPrice, id) => {
    const enteredPrice = getValues(
      `lineItemsDetails.${id}.confirmInvoicedRatePerUnit`,
    );
    if (lineItemPrice && enteredPrice) {
      if (Number(lineItemPrice) !== Number(enteredPrice)) {
        if (!isDirect) {
          return priceWarningInvoiceRate; //[OMS-7586] disable this condition for direct order as we can change invoiceRatePerUnit for direct order
        }
      }
    }
  };

  const getQtyPlanned = (row) => {
    return `${getFixedValue(row?.plannedDelivery?.quantity, 4)}${getUom(
      row?.productInfo?.primaryQuantity,
    )}`;
  };

  const getIsInvoicePerUnitDisabled = () => {
    if (isDirect) {
      if ([invoiced, completed].includes(watch("shipmentStatus"))) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <TableWrapper
      tableHeaderStyle={{ backgroundColor: `${theme.palette.grey["100"]}` }}
      wrapperStyle={{
        boxShadow: `0px 0px 1px 1px ${theme.palette.grey["400"]}`,
      }}
      tableHeader={shipmentUpdateHeaders.map((item) => {
        return (
          <TableCell key={item}>
            <ColumnTitle>{item}</ColumnTitle>
          </TableCell>
        );
      })}
      tableBody={lineItemsDetails.map((row, _) => (
        <StyledTableRow
          customStyle={{ verticalAlign: "top" }}
          key={row.lineitemId}
        >
          <TableCell>
            {row?.productInfo?.name}
            {getAttributes(row?.productInfo?.attributes)}
          </TableCell>
          <TableCell style={{ verticalAlign: "center" }}>
            <Controller
              control={control}
              name={`lineItemsDetails.${row.lineitemId}.plannedQty`}
              defaultValue={getQtyPlanned(row)}
              render={({ field: { value } }) => (
                <CustomTextField
                  value={value}
                  customPadding={2}
                  type="text"
                  disabled={true}
                />
              )}
            />
          </TableCell>
          <WrapTableCell>
            <Stack direction={"column"}>
              <Stack direction={"row"} mb={2}>
                <Controller
                  control={control}
                  name={`lineItemsDetails.${row.lineitemId}.confirmInvoicedQty`}
                  defaultValue={
                    row?.invoiceDelivery?.quantity ??
                    getFixedValue(row?.plannedDelivery?.quantity, 4)
                  }
                  render={({ field: { value, onChange, onBlur } }) => (
                    <CustomTextField
                      value={value}
                      onChange={(evt) => {
                        onChange(evt.target.value);
                        retriggerCalculation();
                      }}
                      customPadding={2}
                      type={"number"}
                      decimal
                      disabled={true}
                      onBlur={onBlur}
                      highlightError={
                        errors?.lineItemsDetails?.[
                          row.lineitemId
                        ]?.invoicedQty?.hasOwnProperty("message") ||
                        errors?.lineItemsDetails?.[
                          row.lineitemId
                        ]?.confirmInvoicedQty?.hasOwnProperty("message")
                      }
                    />
                  )}
                />
                <CustomTextField
                  disabled={true}
                  value={getUom(row?.productInfo?.primaryQuantity)}
                  rightPad={false}
                  customPadding={2}
                />
              </Stack>
              <ErrorMsg
                msg={
                  errors?.lineItemsDetails?.[row.lineitemId]?.invoicedQty
                    ?.message ||
                  errors?.lineItemsDetails?.[row.lineitemId]?.confirmInvoicedQty
                    ?.message
                }
              />
            </Stack>
          </WrapTableCell>
          <WrapTableCell>
            <Stack direction={"column"}>
              <Stack direction={"row"} mb={2}>
                <Controller
                  control={control}
                  name={`lineItemsDetails.${row.lineitemId}.invoicedRatePerUnit`}
                  defaultValue={getFixedValue(
                    row?.invoiceDelivery?.invoiceInfo?.perUnitRate ??
                      getDiscountedPrice(row.lineitemId),
                  )}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <CustomTextField
                      value={value}
                      mask={true}
                      onChange={onChange}
                      customPadding={2}
                      type={"number"}
                      disabled={
                        getIsInvoicePerUnitDisabled() ||
                        isPlantSupplyAndDispatched
                      }
                      onBlur={onBlur}
                      decimal
                      highlightError={
                        errors?.lineItemsDetails?.[
                          row.lineitemId
                        ]?.invoicedRatePerUnit?.hasOwnProperty("message") ||
                        errors?.lineItemsDetails?.[
                          row.lineitemId
                        ]?.confirmInvoicedRatePerUnit?.hasOwnProperty(
                          "message",
                        ) ||
                        watchEnteredPrice(
                          getDiscountedPrice(row.lineitemId),
                          row.lineitemId,
                        )
                      }
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`lineItemsDetails.${row.lineitemId}.confirmInvoicedRatePerUnit`}
                  defaultValue={getFixedValue(
                    row?.invoiceDelivery?.invoiceInfo?.perUnitRate ??
                      getDiscountedPrice(row.lineitemId),
                  )}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <CustomTextField
                      value={value}
                      onChange={(evt) => {
                        onChange(evt.target.value);
                        retriggerCalculation();
                      }}
                      customPadding={2}
                      disabled={
                        getIsInvoicePerUnitDisabled() ||
                        isPlantSupplyAndDispatched
                      }
                      type={"number"}
                      onBlur={onBlur}
                      decimal
                      highlightError={
                        errors?.lineItemsDetails?.[
                          row.lineitemId
                        ]?.invoicedRatePerUnit?.hasOwnProperty("message") ||
                        errors?.lineItemsDetails?.[
                          row.lineitemId
                        ]?.confirmInvoicedRatePerUnit?.hasOwnProperty(
                          "message",
                        ) ||
                        watchEnteredPrice(
                          getDiscountedPrice(row.lineitemId),
                          row.lineitemId,
                        )
                      }
                    />
                  )}
                />
              </Stack>
              <ErrorMsg
                msg={
                  errors?.lineItemsDetails?.[row.lineitemId]
                    ?.invoicedRatePerUnit?.message ||
                  errors?.lineItemsDetails?.[row.lineitemId]
                    ?.confirmInvoicedRatePerUnit?.message ||
                  watchEnteredPrice(
                    getDiscountedPrice(row.lineitemId),
                    row.lineitemId,
                  )
                }
              />
            </Stack>
          </WrapTableCell>
          <WrapTableCell>
            <Stack direction={"column"}>
              <Stack direction={"row"} mb={2}>
                <Controller
                  control={control}
                  name={`lineItemsDetails.${row.lineitemId}.discountPerUnit`}
                  defaultValue={
                    row?.invoiceDelivery?.invoiceInfo?.perUnitDiscountRate.toString() ??
                    "0"
                  }
                  render={({ field: { value, onChange } }) => (
                    <CustomTextField
                      value={value}
                      mask={true}
                      onChange={onChange}
                      customPadding={2}
                      type={"number"}
                      decimal
                      disabled={!isDirect}
                      highlightError={
                        errors?.lineItemsDetails?.[
                          row.lineitemId
                        ]?.discountPerUnit?.hasOwnProperty("message") ||
                        errors?.lineItemsDetails?.[
                          row.lineitemId
                        ]?.confirmDiscountPerUnit?.hasOwnProperty("message")
                      }
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`lineItemsDetails.${row.lineitemId}.confirmDiscountPerUnit`}
                  defaultValue={
                    row?.invoiceDelivery?.invoiceInfo?.perUnitDiscountRate.toString() ??
                    "0"
                  }
                  render={({ field: { value, onChange, onBlur } }) => (
                    <CustomTextField
                      value={value}
                      onChange={(evt) => {
                        onChange(evt.target.value);
                        retriggerCalculation();
                      }}
                      customPadding={2}
                      type={"number"}
                      onBlur={onBlur}
                      decimal
                      disabled={!isDirect}
                      highlightError={
                        errors?.lineItemsDetails?.[
                          row.lineitemId
                        ]?.discountPerUnit?.hasOwnProperty("message") ||
                        errors?.lineItemsDetails?.[
                          row.lineitemId
                        ]?.confirmDiscountPerUnit?.hasOwnProperty("message")
                      }
                    />
                  )}
                />
              </Stack>
              <ErrorMsg
                msg={
                  errors?.lineItemsDetails?.[row.lineitemId]?.discountPerUnit
                    ?.message ||
                  errors?.lineItemsDetails?.[row.lineitemId]
                    ?.confirmDiscountPerUnit?.message
                }
              />
            </Stack>
          </WrapTableCell>
        </StyledTableRow>
      ))}
    />
  );
}

ShipmentLineItem.propTypes = {
  retriggerCalculation: PropTypes.func,
  isPlantSupplyAndDispatched: PropTypes.bool,
  lineItemsDetails: PropTypes.array,
};

export default ShipmentLineItem;
