import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { PersonAddAltOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import { postCall } from "services";
import { ButtonV1, MultiSelectDropdown } from "components";
import { error, success } from "constants";
import { useFetchData, useToaster } from "hooks";
import { FORM_ADD_USER, NEW_USER_ERROR } from "./constant";
import { RightDrawer, ErrorMsg, InputField } from "../components";
import { createUserSchema } from "../TestSchema/createUserSchema";

const AddUser = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [groups, setGroups] = useState([]);
  const [roles, setRoles] = useState([]);

  const omsUserDetail = useSelector((state) => state.userData);
  const triggerToaster = useToaster();

  const groupSelectionRef = useRef(null);
  const roleSelectionRef = useRef(null);

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(createUserSchema),
  });

  const { refetch: fetchGroups } = useFetchData(
    "fetch-all-groups",
    "/auth2-service/getAllGroups",
    (res) => setGroups(res.data),
    (error) => console.error(error),
  );

  const { refetch: fetchRoles } = useFetchData(
    "fetch-all-roles",
    "/auth2-service/getAllRoles",
    (res) => setRoles(res.data),
    (error) => console.error(error),
  );

  useEffect(() => {
    fetchGroups();
    fetchRoles();
  }, [fetchGroups, fetchRoles]);

  const handleToggleDrawer = useCallback(
    () => setOpenDrawer((prev) => !prev),
    [],
  );

  const getIds = () => {
    const selectedRoleName =
      roleSelectionRef.current?.fetchSelectedObjects() || [];
    const roleId = selectedRoleName?.map(({ id }) => id);
    const selectedGroupName =
      groupSelectionRef.current?.fetchSelectedObjects() || [];
    const groupId = selectedGroupName?.map(({ id }) => id);
    return { roleId, groupId };
  };

  const handleAddUser = async () => {
    const {
      imageUrl,
      emailVerified = false,
      provider,
      locations,
      providerId,
    } = omsUserDetail;

    const { roleId, groupId } = getIds();

    const payload = {
      imageUrl,
      emailVerified,
      provider,
      locations,
      providerId,
      name: getValues(FORM_ADD_USER.NAME),
      email: getValues(FORM_ADD_USER.EMAIL),
      roleId,
      groupId,
      mobileNumber: getValues(FORM_ADD_USER.MOBILE),
    };

    const res = await postCall("/auth2-service/user", payload, {}, (res) => {
      triggerToaster(res?.data?.message ?? NEW_USER_ERROR, error);
    });
    if (res) {
      triggerToaster(`New user - ${res?.name ?? ""} is added.`, success);
      reset();
      handleToggleDrawer();
    }
  };

  return (
    <>
      <ButtonV1
        title="Add user"
        startIcon={<PersonAddAltOutlined />}
        onClick={handleToggleDrawer}
        variant="outlined"
        size="small"
        style={{ borderRadius: 20 }}
      />
      <RightDrawer
        icon={<PersonAddAltOutlined />}
        heading={"Add user"}
        toggleDrawer={handleToggleDrawer}
        isDrawerOpen={openDrawer}
      >
        <Box
          component={"form"}
          onSubmitCapture={handleSubmit(handleAddUser)}
          sx={{ display: "flex", flexDirection: "column", gap: 2, flex: 1 }}
        >
          <Controller
            name={FORM_ADD_USER.EMAIL}
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <>
                <InputField
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  label="Email address"
                  isRequired={true}
                  highlightError={errors?.[FORM_ADD_USER.EMAIL]?.hasOwnProperty(
                    "message",
                  )}
                  placeholder="Enter the email address"
                />
                <ErrorMsg msg={errors?.[FORM_ADD_USER.EMAIL]?.message} />
              </>
            )}
          />
          <Controller
            name={FORM_ADD_USER.NAME}
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <>
                <InputField
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  label="User name"
                  isRequired={true}
                  highlightError={errors?.[FORM_ADD_USER.NAME]?.hasOwnProperty(
                    "message",
                  )}
                  placeholder="Enter name"
                />
                <ErrorMsg msg={errors?.[FORM_ADD_USER.NAME]?.message} />
              </>
            )}
          />
          <Controller
            name={FORM_ADD_USER.MOBILE}
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <>
                <InputField
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  label="Mobile no."
                  isRequired={true}
                  highlightError={errors?.[
                    FORM_ADD_USER.MOBILE
                  ]?.hasOwnProperty("message")}
                  type="number"
                  placeholder="Enter mobile no."
                />
                <ErrorMsg msg={errors?.[FORM_ADD_USER.MOBILE]?.message} />
              </>
            )}
          />
          <Box pb={5}>
            <Typography variant="body1" paddingBottom={2}>
              Group
            </Typography>
            <MultiSelectDropdown
              ref={groupSelectionRef}
              itemList={groups}
              displayAttribute={"name"}
              label={"Select group"}
              isListOfObjects={true}
            />
          </Box>
          <Box pb={4}>
            <Typography variant="body1" paddingBottom={2}>
              Role
            </Typography>
            <MultiSelectDropdown
              ref={roleSelectionRef}
              itemList={roles}
              displayAttribute={"name"}
              label={"Select role"}
              isListOfObjects={true}
            />
          </Box>
          <Box mt={20} display={"flex"} sx={{ justifyContent: "flex-end" }}>
            <ButtonV1 type="submit" title={"Add user"} size={"large"} />
          </Box>
        </Box>
      </RightDrawer>
    </>
  );
};

export default AddUser;
