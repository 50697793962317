export const IncomingPayment = Object.freeze({
  UNTAGGED_PAYMENT_HEADING: [
    "Timestamp",
    "Transaction type",
    "Amount",
    "Transaction id",
    "Status",
    "Bank account name",
    "Buyer company",
    "Action",
  ],
  TAGGED_PAYMENT_HEADING: [
    "Timestamp",
    "Transaction type",
    "Amount",
    "Transaction id",
    "Status",
    "Buyer GSTIN",
    "Bank account name",
    "Buyer company",
    "Action",
  ],
  TAGGED_PAYMENT_FILTERS: [
    "Status",
    "Buyer GST",
    "Buyer company",
    "Transaction type",
    "Action",
    "Transaction ID",
  ],
  PAYMENT_ACTION: {
    VERIFIED: "VERIFIED",
    NOT_VERIFIED: "NOT_VERIFIED",
  },
  UNTAGGED_PAYMENT_TABS: {
    NEW_PAYMENTS: "New payments",
    OLD_PAYMENTS: "Old payments",
  },
  ACTION_VALUES: [
    { label: "Verified", value: "Verified" },
    { label: "Not verified", value: "Not verified" },
  ],
  STATUS_VALUES: [
    { label: "Captured", value: "captured" },
    { label: "Not captured", value: "Not captured" },
  ],
});
