import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Divider,
  Grid,
  TextareaAutosize,
  Typography,
  useTheme,
} from "@mui/material";

import { GridSkeleton } from "CustomStyledComponents";
import { ButtonV1, ModalBox } from "components";
import { ColumnView } from "./components/ColumnView";
import DocumentUploader from "./components/DocumentUploader";

import { postCall_v2 } from "services";
import { decimalRoundOff, getAttributes, moneyFormat } from "utils";
import { useFetchData, useToaster } from "hooks";
import { error, success } from "constants";

const RequestShortCloseModal = ({ open, toggleModal, data = {} }) => {
  const [uploadedFiles, setUploadedFiles] = useState();
  const [soDetails, setSoDetails] = useState();
  const theme = useTheme();
  const triggerToaster = useToaster();
  const { record, item, orderNumber } = data;

  const { control, getValues, setValue } = useForm({
    mode: "onTouched",
  });

  /**
   * @description Fetch SO lineitems
   */

  const getSoDetails = () => {
    let soNumber = [];
    let soLineItem = [];
    soData?.data?.map((ele) => {
      soNumber.push(ele?.soNumber);
      soLineItem.push(ele?.soLineItemNumber);
    });
    let soDetail = {
      soNumber: soNumber.join(","),
      soLineItem: soLineItem.join(","),
    };
    setSoDetails(soDetail);
  };

  const { data: soData, refetch: fetchSoData } = useFetchData(
    "SO-data",
    `/oms/line-item/so-mappings/${item?.lineItemId}`,
  );

  const onFileUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", ...file);

      const res = await postCall_v2(
        `/oms/plant-supply/do/short-close/upload`,
        formData,
        {
          psIdentifier: record?.psIdentifier,
          lineItemId: item?.lineItemId,
        },
      );

      setUploadedFiles(res?.data?.doShortClosureFile?.sellerConfirmationFiles);
    } catch (err) {
      triggerToaster(
        err?.data?.title || `Error occurred, please try again`,
        error,
      );
    }
  };

  const onRequestShortClose = async () => {
    if (uploadedFiles) {
      const remark = getValues("remarks");
      const payload = {
        psIdentifier: record?.psIdentifier,
        orderNumber,
        lineItemIds: [item?.lineItemId],
        remarks: remark,
      };
      try {
        const res = await postCall_v2(
          `/oms/plant-supply/do/short-close/release`,
          payload,
        );

        triggerToaster(res?.data, success);
      } catch (err) {
        triggerToaster(`${err?.data?.detail}`, error);
      } finally {
        setValue("remarks", "");
        toggleModal();
        setUploadedFiles();
      }
    } else {
      triggerToaster(`Please attach seller confirmation file`, error);
    }
  };
  useEffect(() => {
    setUploadedFiles();
    setValue("remarks", "");
    if (item?.lineItemId) {
      fetchSoData();
    }
  }, [open]);

  useEffect(() => {
    if (soData) {
      getSoDetails();
    }
  }, [soData]);

  return (
    <>
      <ModalBox open={open} onCloseModal={toggleModal} width="80%">
        <Box style={{ padding: 20 }}>
          <Box display={"flex"} mb={4}>
            <Typography fontSize={20} fontWeight={600}>
              Request for DO short closure
            </Typography>
          </Box>
          <Divider style={{ marginBottom: 15 }} />
          <Box>
            <GridSkeleton
              style={{ marginBottom: 20 }}
              firstChild={
                <ColumnView label="DO ID" value={record?.psIdentifier} />
              }
              secondChild={
                <ColumnView
                  gridXs={12}
                  label="Sales order ID"
                  value={orderNumber}
                />
              }
              thirdChild={
                <ColumnView
                  label="SKU name"
                  value={`${item?.productInfo?.name}${getAttributes(
                    item?.productInfo?.attributes,
                  )}`}
                />
              }
            />
            <GridSkeleton
              style={{ marginBottom: 20 }}
              firstChild={
                <ColumnView
                  label="Released SKU quantity"
                  value={
                    item?.releaseQuantity
                      ? `${item?.releaseQuantity} ${item?.measurementUnit}`
                      : "-"
                  }
                />
              }
              secondChild={
                <ColumnView
                  label="Dispatched quantity"
                  value={
                    item?.shipmentLinkList?.[0]?.shippedQuantity
                      ? `${item?.shipmentLinkList?.[0]?.shippedQuantity} ${item?.measurementUnit}`
                      : "-"
                  }
                />
              }
              thirdChild={
                <ColumnView
                  gridXs={12}
                  label="Residual quantity"
                  value={`${decimalRoundOff(
                    (item?.releaseQuantity ?? 0) -
                      (item?.shipmentLinkList?.[0]?.shippedQuantity ?? 0),
                  )} ${item?.measurementUnit}`}
                />
              }
            />
            <GridSkeleton
              style={{ marginBottom: 20 }}
              firstChild={
                <ColumnView
                  gridXs={12}
                  label="Residual value"
                  value={`${moneyFormat(
                    ((item?.releaseQuantity ?? 0) -
                      (item?.shipmentLinkList?.[0]?.shippedQuantity ?? 0)) *
                      item?.price,
                  )}`}
                />
              }
              secondChild={
                <ColumnView
                  gridXs={12}
                  label="Release value"
                  value={moneyFormat(item?.releaseQuantity * item?.price)}
                />
              }
              thirdChild={
                <ColumnView
                  gridXs={12}
                  label="SO  number"
                  value={soDetails?.soNumber}
                />
              }
            />
            <GridSkeleton
              style={{ marginBottom: 20 }}
              firstChild={
                <ColumnView
                  gridXs={12}
                  label="SO line item"
                  value={soDetails?.soLineItem}
                />
              }
              secondChild={
                <Grid item>
                  <Box width={"90%"}>
                    <Typography>
                      Attach seller confirmation{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>

                    <DocumentUploader
                      onUpload={onFileUpload}
                      uploadedFiles={uploadedFiles}
                    />
                  </Box>
                </Grid>
              }
            />
            <Box width={"100%"} marginBottom={4}>
              <Controller
                control={control}
                name={"remarks"}
                render={({ field: { value, onChange } }) => (
                  <TextareaAutosize
                    style={{
                      width: "100%",
                      borderRadius: 8,
                      border: `1px solid ${theme.palette.grey[400]}`,
                      padding: 8,
                      fontFamily: "inherit",
                      resize: "none",
                    }}
                    value={value}
                    onChange={onChange}
                    placeholder="Enter comments"
                    minRows={6}
                    maxRows={6}
                  />
                )}
              />
            </Box>
            <Box display={"flex"} justifyContent={"flex-end"} gap={3}>
              <ButtonV1
                title={"Cancel"}
                variant="outlined"
                onClick={toggleModal}
              />
              <ButtonV1 title={"Request"} onClick={onRequestShortClose} />
            </Box>
          </Box>
        </Box>
      </ModalBox>
    </>
  );
};

export default RequestShortCloseModal;
