import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material";
import moment from "moment";

import { ButtonV1, DatePickerV1, TextField, Loader } from "components";
import ColumnStack from "../../components/ColumnStack";

import { postCall_v2 } from "services";
import { useToaster } from "hooks";
import { success, error } from "constants";

/**
 *
 * @description Review content
 * post file upload
 */
const ReviewContent = ({ creditData, onCancel }) => {
  const theme = useTheme();
  const [isLoaderVisibile, setLoaderVisibility] = useState(false);

  const { control, getValues, handleSubmit } = useForm({
    mode: "onTouched",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "fileContent",
  });

  const triggerToaster = useToaster();

  useEffect(() => {
    if (creditData && creditData.length) {
      creditData.forEach((ele) => {
        const { GSTIN, CREDIT_PROGRAM_NAME, PAN } = ele;
        if (GSTIN && CREDIT_PROGRAM_NAME && PAN) {
          append(ele);
        }else{
          console.error("Some column values are missing");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditData]);

  /**
   * @description submit bulk credit instrument update data
   */
  const onCreditUpdation = async () => {
    try {
      setLoaderVisibility(true);
      const payload = getValues();
      const formattedData = payload?.fileContent.map((ele) => {
        const parsedDate = moment.utc(ele?.expiryDate).startOf("day");
        const formattedDate = parsedDate.format("YYYY-MM-DDTHH:mm:ss[Z]");

        const obj = {
          creditInstrumentType: ele?.creditInstrumentType,
          expiryDate: formattedDate,
          creditType: ele?.creditType,
          sanctionedLimit: ele?.sanctionedLimit,
          utilisedLimit: ele?.utilisedLimit,
          pan: ele?.PAN ?? null,
          requestedBy: ele?.REQUESTED_BY ?? null,
          penalRate: ele?.penalRate,
          creditDueDays: ele?.creditDueDays,
          camId: ele?.camId,
          gracePeriod: ele?.gracePeriod,
          comments: ele?.comments,
          path: ele?.PATH ?? null,
          companyName: ele?.companyName ?? null,
          gstin: ele?.gstin ?? null,
          advancePercentage: ele?.advancePercentage,
          isCreditBlocked: ele?.IS_CREDIT_BLOCKED === "TRUE" ? true : false,
        };
        return obj;
      });
      const { data } = await postCall_v2(
        "/oms/credit/updateSanctionAndUtilisedLimitList",
        formattedData,
      );
      if (data?.successful) {
        triggerToaster(`Credit instruments updated successfully`, success);
        remove();
        onCancel();
      } else {
        remove();
        onCancel();
        triggerToaster(
          data?.message ?? `Updating credit instruments failed`,
          error,
        );
        //remove data & only show row which has error
        const result = data?.creditUpdateBulkResponses?.map((ele) => {
          if (!ele.successful) {
            const {
              camId,
              gracePeriod,
              comments,
              companyName,
              gstin,
              advancePercentage,
              creditType,
              expiryDate,
              creditInstrument,
              sanctionedLimit,
              utilisedLimit,
              penalRate,
              creditDueDays,
              pan,
              message,
              isCreditBlocked,
            } = ele;
            return {
              INSTRUMENT_NAME: creditType,
              EXPIRY_DATE: expiryDate,
              CREDIT_PROGRAM_NAME: creditInstrument,
              PENAL_RATE: penalRate,
              CREDIT_DUE_DAYS: creditDueDays,
              GRACE_PERIOD: gracePeriod,
              CAM_ID: camId,
              ADVANCE_PERCENTAGE: advancePercentage,
              COMMENTS: comments,
              SANCTIONED_LIMIT: sanctionedLimit,
              UTILISED_LIMIT: utilisedLimit,
              COMPANY_NAME: companyName,
              GSTIN: gstin,
              PAN: pan,
              isCreditBlocked,
              isError: message,
            };
          }
        });
        //timer to ensure remove() operation is completed
        //since it is asynchronous
        setTimeout(() => {
          result.forEach((ele) => {
            if (ele && Object.keys(ele)?.length) {
              append(ele);
            }
          });
        }, 1000);
      }
    } catch (ex) {
      console.log(`Exception on updating credit data ${ex}`);
      triggerToaster(`Updating credit instruments failed`, error);
    } finally {
      setLoaderVisibility(false);
    }
  };

  /**
   *
   * @description Remove all credit instruments in review list
   */
  const removeAll = () => {
    remove();
    onCancel(); //from props
    triggerToaster(`Removed uploaded credit limit sheet successfully`, success);
  };

  return (
    <>
      {isLoaderVisibile ? (
        <Loader sx={{ marginTop: 0 }} />
      ) : (
        <>
          {!!(fields && fields.length) && (
            <>
              <Typography fontSize={18} fontWeight={"bold"} mb={2}>
                Review uploaded credit limits
              </Typography>
              <Box
                as="form"
                onSubmit={handleSubmit(onCreditUpdation)}
                border={1}
                maxHeight={600}
                borderColor={"lightgray"}
                overflow={"auto"}
                overflowY={"scroll"}
                borderRadius={4}
                p={8}
              >
                {fields.map((ele, index) => {
                  return (
                    <Grid
                      container
                      mb={6}
                      border={1}
                      borderColor={
                        ele?.isError ? theme.palette.error.dark : "lightgray"
                      }
                      borderRadius={4}
                      p={8}
                    >
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>
                            Instrument name
                          </Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.creditType`}
                            defaultValue={ele?.INSTRUMENT_NAME}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                size={"small"}
                                disabled={true}
                              />
                            )}
                          />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>Expiry date</Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.expiryDate`}
                            defaultValue={ele?.EXPIRY_DATE}
                            render={({ field: { value, onChange } }) => (
                              <DatePickerV1
                                dateStyle={{ width: "80%" }}
                                value={value}
                                onChange={onChange}
                              />
                            )}
                          />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>
                            Credit program name
                          </Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.creditInstrumentType`}
                            defaultValue={ele?.CREDIT_PROGRAM_NAME}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                size={"small"}
                                disabled={true}
                              />
                            )}
                          />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>
                            Penal charges (% per annum)
                          </Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.penalRate`}
                            defaultValue={ele?.PENAL_RATE}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                size={"small"}
                                disabled={true}
                              />
                            )}
                          />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>Credit days</Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.creditDueDays`}
                            defaultValue={ele?.CREDIT_DUE_DAYS}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                size={"small"}
                              />
                            )}
                          />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>
                            Grace period (in days)
                          </Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.gracePeriod`}
                            defaultValue={ele?.GRACE_PERIOD}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                size={"small"}
                                disabled={true}
                              />
                            )}
                          />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>
                            Credit limit Reference ID
                          </Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.camId`}
                            defaultValue={ele?.CAM_ID}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                size={"small"}
                                disabled={true}
                              />
                            )}
                          />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>Advance (%)</Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.advancePercentage`}
                            defaultValue={ele?.ADVANCE_PERCENTAGE}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                size={"small"}
                                disabled={true}
                              />
                            )}
                          />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>Remarks</Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.comments`}
                            defaultValue={ele?.COMMENTS}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                size={"small"}
                                disabled={true}
                              />
                            )}
                          />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>
                            Sanctioned limit
                          </Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.sanctionedLimit`}
                            defaultValue={ele?.SANCTIONED_LIMIT}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                size={"small"}
                              />
                            )}
                          />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>
                            Utilised limit
                          </Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.utilisedLimit`}
                            defaultValue={ele?.UTILISED_LIMIT}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                size={"small"}
                              />
                            )}
                          />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>Company name</Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.companyName`}
                            defaultValue={ele?.COMPANY_NAME}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                size={"small"}
                                disabled={true}
                              />
                            )}
                          />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>GSTIN</Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.gstin`}
                            defaultValue={ele?.GSTIN}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                size={"small"}
                                disabled={true}
                              />
                            )}
                          />
                        </ColumnStack>
                      </Grid>
                      {!!ele.isError && (
                        <Grid item xs={12} mb={2}>
                          <Typography
                            fontSize={14}
                            color={theme.palette.error.dark}
                          >
                            {ele?.isError}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  );
                })}
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                  gap={4}
                  position={"sticky"}
                  bottom={0}
                >
                  <ButtonV1
                    type={"submit"}
                    title="Submit"
                    color="primary"
                    variant="contained"
                  />
                  <ButtonV1
                    title="Cancel"
                    variant="outlined"
                    onClick={removeAll}
                  />
                </Box>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

ReviewContent.Proptype = {
  creditData: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
};
export default ReviewContent;
