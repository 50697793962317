import { Box, Typography } from "@mui/material";

import { AmountWithHead } from "pages/FinancePayout/components";
import IconTooltip from "pages/FinancePayout/components/IconTooltip";

import { SvgIcon } from "components";

const updatedTCS_Date = "2024-01-23";

const getValidTdsTcsLabel = (txDate) => {
  return new Date(txDate).getTime() < new Date(updatedTCS_Date).getTime()
    ? "Invoice"
    : "basic value";
};

const CreditPriceBreak = ({
  invoiceAmount,
  isCredit,
  paymentInfo,
  transactionCreatedDate,
}) => (
  <Box display={"flex"} flexDirection={"column"} gap={2} padding={2}>
    <AmountWithHead head={"Invoice amount"} amount={invoiceAmount} />
    <Typography fontWeight={600} pt={2}>
      {isCredit ? "Credit details" : "Advance details"}
    </Typography>
    <AmountWithHead
      head={isCredit ? "Credit amount" : "Advance amount"}
      amount={paymentInfo.invoiceAmount}
    />
    <AmountWithHead
      head={`TCS on GST (1%) on ${getValidTdsTcsLabel(
        transactionCreatedDate,
      )} `}
      amount={paymentInfo.tcsAmount}
    />
    <AmountWithHead
      head={`TDS (1%) on ${getValidTdsTcsLabel(transactionCreatedDate)} `}
      amount={paymentInfo.tdsAmount}
    />
    <AmountWithHead
      head={"Payout amount now"}
      amount={paymentInfo.payoutAmount}
    />
  </Box>
);

const OrderPriceBreakUp = ({ data }) => (
  <Box display={"flex"} flexDirection={"column"} gap={4} padding={2}>
    <AmountWithHead
      head={"Total invoice amount"}
      amount={data.shipmentInvoiceDetails?.totalInvoicedAmount}
    />
    <AmountWithHead
      head={`TCS on GST (1%) on ${getValidTdsTcsLabel(
        data?.transactionCreatedDate,
      )} `}
      amount={data?.tcsInvoiceAmount}
    />
    <AmountWithHead
      head={`TDS (1%) on  ${getValidTdsTcsLabel(
        data?.transactionCreatedDate,
      )} `}
      amount={data?.tdsInvoiceAmount}
    />
    <AmountWithHead head={"Payable to seller"} amount={data?.payableToSeller} />
    <AmountWithHead head={"Payout amount now"} amount={data?.payableToSeller} />
  </Box>
);

const PriceBreakup = ({ data, icon }) => {
  const { payoutMetaInfo, amount, transactionCreatedDate } = data;
  const isCredit = payoutMetaInfo?.payoutType === "CREDIT";

  return (
    <IconTooltip
      icon={
        icon || (
          <Box height={16}>
            <SvgIcon name={"info"} width={16} height={16} />
          </Box>
        )
      }
    >
      {payoutMetaInfo ? (
        <CreditPriceBreak
          invoiceAmount={amount}
          isCredit={isCredit}
          paymentInfo={payoutMetaInfo}
          transactionCreatedDate={transactionCreatedDate}
        />
      ) : (
        <OrderPriceBreakUp data={data} />
      )}
    </IconTooltip>
  );
};

export default PriceBreakup;
