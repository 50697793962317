import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { SvgIcon } from "components";
import { AmountWithHead } from "pages/FinancePayout/components";
import IconTooltip from "pages/FinancePayout/components/IconTooltip";
import { dateConvertor } from "utils";

const CreditDocumentPrice = ({ data, addInfo, tcsTdsDeductionReq }) => (
  <Grid sx={{ maxWidth: 320 }} container rowGap={3}>
    <Grid item xs={12}>
      <AmountWithHead
        head={"Invoice amount"}
        amount={data.totalInvoicedAmount}
      />
    </Grid>
    <Grid item xs={6}>
      <AmountWithHead head={"Credit amount"} amount={addInfo.creditAmount} />
    </Grid>
    <Grid item xs={6}>
      <Box display={"flex"} flex={1} flexDirection={"column"}>
        <Typography
          color={"text.disabled"}
          fontWeight={600}
          fontSize={12}
          noWrap
        >
          {"Credit due date"}
        </Typography>
        <Typography fontWeight={600}>
          {dateConvertor(addInfo.creditDueDate)}
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={12}>
      <AmountWithHead head={"Advance amount"} amount={addInfo.advanceAmount} />
    </Grid>
    <Grid item xs={12}>
      <Typography fontWeight={800} fontSize={14}>
        GST breakup
      </Typography>
    </Grid>
    <Grid item xs={6}>
      <AmountWithHead head={"Base amount"} amount={data.invoicedBaseAmount} />
    </Grid>
    <Grid item xs={6}>
      <AmountWithHead head={"CGST amount"} amount={data.cgstamount} />
    </Grid>
    <Grid item xs={6}>
      <AmountWithHead head={"SGST amount"} amount={data.sgstamount} />
    </Grid>
    <Grid item xs={12}>
      <AmountWithHead head={"IGST amount"} amount={data.igstamount} />
    </Grid>
    <Grid item xs={12}>
      <FormControlLabel
        control={<Checkbox checked={tcsTdsDeductionReq} disabled={true} />}
        label="TDS/TCS deductions required?"
        sx={{ ".MuiTypography-root": { fontSize: 14 } }}
      />
    </Grid>
  </Grid>
);

const DocumentBreakUp = ({ data, tcsTdsDeductionReq }) => (
  <Box display={"flex"} flexDirection={"column"} gap={2}>
    <AmountWithHead
      head={"Total invoice amount"}
      amount={data.totalInvoicedAmount}
    />
    <Typography fontWeight={800} fontSize={14}>
      Amount breakup
    </Typography>
    <AmountWithHead head={"Base amount"} amount={data.invoicedBaseAmount} />
    <AmountWithHead head={"CGST amount"} amount={data.cgstamount} />
    <AmountWithHead head={"IGST amount"} amount={data.igstamount} />
    <AmountWithHead head={"SGST amount"} amount={data.sgstamount} />
    <FormControlLabel
      control={<Checkbox checked={tcsTdsDeductionReq} disabled={true} />}
      label="TDS/TCS deductions required?"
      sx={{ ".MuiTypography-root": { fontSize: 14 } }}
    />
  </Box>
);

const DocumentPriceBreakup = (props) => {
  return (
    <IconTooltip
      icon={
        <Box height={16}>
          <SvgIcon name={"info"} width={16} height={16} />
        </Box>
      }
    >
      {props.addInfo ? (
        <CreditDocumentPrice {...props} />
      ) : (
        <DocumentBreakUp {...props} />
      )}
    </IconTooltip>
  );
};

export default DocumentPriceBreakup;
