import { URL_PATH, FINANCE_TAB, ORDER_DETAIL_TAB } from "constants";

/**
 *
 * @param {*} allowedModules
 * @param {*} queryParams
 * @description check access within tab in order details url
 * @returns boolean if false will allow user to that route & if true will restrict user
 */
const restrictAccessForOrderDetailsTab = (allowedModules, queryParams) => {
  const selectedTab = new URLSearchParams(queryParams)?.get("tab");

  if (!["reconciliation", "payouts"].includes(selectedTab)) {
    return false;
  }
  const accessForPayouts = allowedModules?.some(
    ({ name }) => name === ORDER_DETAIL_TAB[selectedTab],
  );
  return !accessForPayouts;
};
/**
 * @description Check user has access to off oms payout & pending payout
 */
const restrictAccessForPayouts = (allowedModules, queryParams) => {
  const selectedTab = new URLSearchParams(queryParams)?.get("tab");
  if (selectedTab === FINANCE_TAB.historical) {
    return false;
  }

  const payoutAccess = (type) => {
    return allowedModules?.some(({ name }) => name === type);
  };
  if (selectedTab === FINANCE_TAB.pending) {
    return !payoutAccess(FINANCE_TAB.pendingPayoutAuth);
  }
  if (selectedTab === FINANCE_TAB.upload) {
    return !payoutAccess(FINANCE_TAB.uploadPayoutAuth);
  }
};

/**
 *
 * @param {*} allowedModules
 * @param {*} pathUrl
 * @param {*} queryParams
 * @param {*} pathParams
 * @description checks user has access on given route
 * @returns boolean if false will allow user to that route & if true will restrict user
 */
export const restrictPath = (
  allowedModules,
  pathUrl,
  queryParams,
  pathParams,
) => {
  const mainRoute = pathUrl?.split("/")[1];
  const routeName = URL_PATH[mainRoute];
  if (allowedModules?.length) {
    const hasAccess = allowedModules?.some(({ name }) => name === routeName);
    switch (mainRoute) {
      case "order-list":
        if (pathParams?.id === pathUrl?.split("/")[2] && hasAccess) {
          // If Order-detail page
          return restrictAccessForOrderDetailsTab(allowedModules, queryParams);
        } else {
          // If Order-list page
          return !hasAccess;
        }
      case "finance-payout": {
        return restrictAccessForPayouts(allowedModules, queryParams);
      }
      default:
        return !hasAccess;
    }
  } else {
    return true;
  }
};
