import { combineReducers } from "redux";
import isLoggedIn from "./auth";
import userData from "./userProfile";
import orderDetails from "./orderDetails";
import showSnackBar from "./showSnackBar";
import verifyFieldsEdit from "./verifyFieldsEdit";
import selectedTab from "./selectedTab";
import shipmentDetails from "./shipmentDetails";
import dashboardPathParams from "./dashboardPathParams";
import orderListPathParams from "./orderListPathParams";
import selectedUserProfile from "./selectedUserProfile";
import approvedCheckedTx from "./approvedCheckedTx";
import approvalPayoutUploadFlow from "./approvalPayoutUploadFlow";

const rootReducer = combineReducers({
  isLoggedIn,
  userData,
  orderDetails,
  showSnackBar,
  verifyFieldsEdit,
  selectedTab,
  shipmentDetails,
  dashboardPathParams,
  orderListPathParams,
  selectedUserProfile,
  approvedCheckedTx,
  approvalPayoutUploadFlow,
});

export default rootReducer;
