import { styled } from "@mui/material/styles";
import {
  Box,
  Toolbar,
  Typography,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import theme from "themeProvider";

const drawerWidth = 240;
const StyledListItem = styled(ListItem)(() => ({
  "&.MuiButtonBase-root": {
    padding: "0.2rem 0.5rem 0.2rem 0rem",
    justifyContent: "unset",
  },
}));

const CustomListItemText = styled(ListItemText)(() => ({
  ".MuiListItemText-primary": {
    fontFamily: "unset",
    letterSpacing: "unset",
    lineHeight: 1,
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 25px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 25px)`,
  },
});

const DrawerHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const CustomTypography = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontFamily: "unset",
    letterSpacing: "unset",
  },
}));

const Icon = ({ name, width = 20 }) => {
  return (
    <object
      aria-label="JSW logo"
      width={width}
      type="image/svg+xml"
      data={`/assets/${name}.svg`}
      style={{ pointerEvents: "none" }}
    />
  );
};

const SIDE_MENU = [
  {
    label: "Dashboard",
    path: "dashboard",
    icon: <Icon name="dashboard" />,
    selectedIcon: <Icon name="selected-dashboard" />,
  },
  {
    label: "Order list",
    path: "order-list",
    icon: <Icon name="order-list" />,
    selectedIcon: <Icon name="selected-order-list" />,
  },
  {
    label: "Incoming payments",
    path: "incoming-payments",
    icon: <Icon name="incoming-payments" />,
    selectedIcon: <Icon name="selected-incoming-payments" />,
  },
  {
    label: "Ledger",
    path: "ledger",
    icon: <Icon name="ledger" />,
    selectedIcon: <Icon name="selected-ledger" />,
  },
  {
    label: "User management",
    path: "user-management",
    icon: <Icon name="user-management" />,
    selectedIcon: <Icon name="selected-user-management" />,
  },
  {
    label: "Finance payout",
    path: "finance-payout",
    icon: <Icon name="payout" />,
    selectedIcon: <Icon name="selected-payout" />,
  },
  {
    label: "Credit limit",
    path: "credit-limit",
    icon: <Icon name="credit" />,
    selectedIcon: <Icon name="selected-credit" />,
  },
  {
    label: "Credit Order",
    path: "credit-orders",
    icon: <Icon name="credit-card" />,
    selectedIcon: <Icon name="selected-credit-card" />,
  },
  {
    label: "Customer profile",
    path: "customer-profile",
    icon: <Icon name="customer-profile" />,
    selectedIcon: <Icon name="selected-customer-profile" />,
  },
  {
    label: "Supply panel",
    path: "supply-panel",
    icon: <Icon name="supply-panel" />,
    selectedIcon: <Icon name="selected-supply-panel" />,
  },
  {
    label: "Zoho order view",
    path: "zoho-order-view",
    icon: <Icon name="zoho-order-view" />,
    selectedIcon: <Icon name="selected-zoho-order-view" />,
  },
];

const ToggleSidebar = ({ open, handleDrawerClose, handleDrawerOpen }) => {
  return (
    <IconButton
      onClick={open ? handleDrawerClose : handleDrawerOpen}
      style={{
        backgroundColor: open
          ? theme.palette.primary.main
          : theme.palette.common.white,
        borderRadius: "5px 0px 0px 5px",
        padding: 4,
        paddingRight: 2,
        display: "flex",
        alignSelf: "flex-end",
        marginBottom: 8,
      }}
    >
      {open ? (
        <ChevronLeft
          style={{
            color: theme.palette.common.white,
          }}
        />
      ) : (
        <ChevronRight />
      )}
    </IconButton>
  );
};

const AppHeader = ({ redirectToHome, userName }) => {
  return (
    <Toolbar
      sx={{
        background: (theme) => theme.palette.common.white,
        backgroundColor: theme.palette.grey["100"],
        boxShadow: `2px 2px 2px  2px ${theme.palette.grey["200"]}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          marginLeft: 4,
        }}
      >
        <Icon name="JSWMSME" width={107} />
        <CustomTypography
          variant="h5"
          style={{
            paddingLeft: 4,
            color: theme.palette.text.secondary,
            margin: 0,
            fontSize: 20,
          }}
        >
          |
        </CustomTypography>
        <CustomTypography
          variant="h5"
          style={{
            paddingLeft: 4,
            color: theme.palette.text.primary,
            margin: 0,
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          JOPL ERP
        </CustomTypography>
      </Box>

      <Box style={{ display: "flex", alignItems: "center", zIndex: 1 }}>
        <CustomTypography
          style={{
            color: theme.palette.common.black,
            fontFamily: 14,
            marginRight: 2,
          }}
        >
          Welcome,
        </CustomTypography>
        <CustomTypography
          style={{
            paddingLeft: 4,
            color: theme.palette.common.black,
            whiteSpace: "nowrap",
            fontFamily: 14,
            fontWeight: "700",
            marginRight: 24,
          }}
        >
          {userName}
        </CustomTypography>
        {/* <Box p={2} mr={4} mt={1}>
          <Icon name="notification" width={26} />
        </Box> */}
        <IconButton
          onClick={redirectToHome}
          sx={{
            "&.MuiIconButton-root": {
              borderRadius: "unset",
              color: "#FF9EA1",
              padding: 2,
              marginRight: 4,
            },
          }}
        >
          <Icon name="logout" width={26} />
        </IconButton>
      </Box>
    </Toolbar>
  );
};

const CustomListItemIcon = ({ selectedPath, menu }) => {
  return (
    <ListItemIcon
      sx={{
        minWidth: 0,
        mr: 3,
        height: 45,
        justifyContent: "center",
        backgroundColor:
          selectedPath === menu.path ? theme.palette.primary.main : "",
        borderColor:
          selectedPath === menu.path ? theme.palette.primary.main : "",
        padding: 2.5,
        pl: 5,
        boxShadow: selectedPath === menu.path && `inset 0px 0px 8px #0000002e`,
        borderRadius: "0px 10px 10px 0px",
      }}
    >
      {selectedPath === menu.path ? menu.selectedIcon : menu.icon}
    </ListItemIcon>
  );
};

export {
  closedMixin,
  openedMixin,
  SIDE_MENU,
  CustomTypography,
  DrawerHeader,
  CustomListItemText,
  StyledListItem,
  drawerWidth,
  ToggleSidebar,
  AppHeader,
  CustomListItemIcon,
};
