import { TableCell, TableRow, styled } from "@mui/material";
import { Timestamp } from "components";
import { moneyFormat, validateNull } from "utils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTopWidth: 1,
  borderTopColor: theme.palette.grey[400],
  borderTopStyle: "solid",
}));

const CreditDetailTable = ({ order }) => {
  return (
    <>
      {order?.requestList?.map((cred) => (
        <TableRow key={cred?.camId} className="row-no-bg">
          <StyledTableCell />
          <StyledTableCell>{validateNull(cred?.camId)}</StyledTableCell>
          <StyledTableCell>
            {validateNull(cred?.creditType)}-
            {validateNull(cred?.creditInstrument)}
          </StyledTableCell>
          <StyledTableCell />
          <StyledTableCell />
          <StyledTableCell>
            {moneyFormat(cred?.sanctionedLimit)}
          </StyledTableCell>
          <StyledTableCell>{moneyFormat(cred?.utilisedLimit)}</StyledTableCell>
          <StyledTableCell>
            <Timestamp timeStamp={cred?.expiryDate} showTime={false} />
          </StyledTableCell>
          <StyledTableCell />
          <StyledTableCell />
          <StyledTableCell />
        </TableRow>
      ))}
      {order?.requestList?.length % 2 !== 0 && <TableRow></TableRow>}
    </>
  );
};

export default CreditDetailTable;
