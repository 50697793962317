import React from "react";
import { Chip, useTheme } from "@mui/material";
import { SHIPMENT_STATUS_CHIP } from "./constants";

const StatusChip = ({ label, status }) => {
  const theme = useTheme();

  const getCustomStyle = () => {
    let style = {};
    switch (status) {
      case SHIPMENT_STATUS_CHIP.ON_TIME:
        style = {
          borderColor: theme.palette.success.dark,
          color: theme.palette.success.dark,
          background: "white",
        };
        break;
      case SHIPMENT_STATUS_CHIP.DELAYED:
        style = {
          borderColor: theme.palette.error.dark,
          color: theme.palette.error.dark,
          background: "#fff4f4",
        };
        break;
      case SHIPMENT_STATUS_CHIP.CANCELLED:
        style = {
          borderColor: theme.palette.text.secondary,
          color: theme.palette.text.secondary,
          background: "#f1f1f1",
        };
        break;
      default:
        break;
    }
    return style;
  };

  return (
    <Chip
      label={label}
      sx={{
        ...getCustomStyle(),
        borderRadius: 1,
        border: 1,
        borderStyle: "solid",
        fontWeight: 600,
      }}
    />
  );
};

export default React.memo(StatusChip);
