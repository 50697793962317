import { useState } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import { ButtonV1, DropdownV1, ModalBox } from "components";

export const CreditOrderRejectModal = ({
  isCreditRejectModalOpen,
  toggleCreditRejectModalOpen,
  onRejectCredit,
  reasons,
}) => {
  const [rejectText, setReasonText] = useState("");
  const handleReject = () => {
    onRejectCredit(rejectText);
  };

  return (
    <ModalBox
      width="50%"
      open={isCreditRejectModalOpen}
      onCloseModal={toggleCreditRejectModalOpen}
      avoidBackDropClick={true}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
        p={8}
        gap={4}
        alignItems={"center"}
      >
        <Box width={"80%"}>
          <DropdownV1
            sx={{ marginBottom: 4 }}
            onChange={(evt) => setReasonText(evt?.target?.value)}
            itemList={reasons}
            valueKey={"reason"}
            displayKey={"reason"}
            value={rejectText}
            placeholder={"Please specify order rejection reason"}
          />
        </Box>
        <ButtonV1
          title="Reject"
          disabled={!rejectText}
          onClick={handleReject}
        />
      </Box>
    </ModalBox>
  );
};

CreditOrderRejectModal.propTypes = {
  isCreditRejectModalOpen: PropTypes.bool,
  toggleCreditRejectModalOpen: PropTypes.func,
  onRejectCredit: PropTypes.func,
  reasons: PropTypes.array,
};
export default CreditOrderRejectModal;
