import { useMemo } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import { StyledTableRow } from "CustomStyledComponents";
import { validateNull } from "utils";

const InvoiceStatusTable = ({ invoiceStatusList }) => {
  const getInvoiceStatus = (invoiceStatus) => {
    const status = {
      IN_PROGRESS: "In progress",
      INV_CREATED: "Invoice created",
      INV_CREATE_FAIL: "Invoice creation failed",
      INV_POSTED: "Invoice posted",
      INV_POSTING_FAIL: "Invoice posting failed",
      INV_PROCESSED: "Invoice processed",
      INV_PROCESSING_PENDING: "Invoice processing pending",
      FAILED: "Failed",
      INV_PROCESSING_FAILED: "Invoice processing failed",
    };

    return status[invoiceStatus] ? status[invoiceStatus] : "-";
  };
  const tableFields = useMemo(() => {
    return [
      {
        title: "Shipment number ",
        field: "shipmentNumber",
        render: (ele) => (
          <Typography style={{ width: 120 }} fontSize={14}>
            {validateNull(ele?.shipmentNumber)}
          </Typography>
        ),
      },
      {
        title: "Invoice number",
        field: "invoiceNumber",
        render: (ele) => (
          <Typography style={{ width: 120 }} fontSize={14}>
            {validateNull(ele?.invoiceNumber)}
          </Typography>
        ),
      },
      {
        title: "Shipment status",
        field: "shipmentStatus",
        render: (ele) => (
          <Typography fontSize={14}>
            {validateNull(ele?.shipmentCurrentState?.state)}
          </Typography>
        ),
      },
      {
        title: "Invoice created date",
        field: "invoiceCreatedDate",
        render: (ele) => (
          <Typography fontSize={14}>
            {validateNull(ele?.invoiceCreatedDate)}
          </Typography>
        ),
      },
      {
        title: "Zoho invoice posting status",
        field: "zohoInvoiceStatus",
        render: (ele) => (
          <Typography fontSize={14}>
            {getInvoiceStatus(ele?.zohoInvoiceStatus)}
          </Typography>
        ),
      },
    ];
  }, []);

  return (
    <Box p={2}>
      <Box component={Paper}>
        <TableContainer
          sx={{
            minWidth: 700,
            maxHeight: "calc(100vh - 120px)",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              {tableFields?.map((el) => (
                <TableCell key={el.field}>{el.title}</TableCell>
              ))}
            </TableHead>
            <TableBody>
              {!invoiceStatusList?.length ? (
                <StyledTableRow>
                  <TableCell colSpan={tableFields.length} align="center">
                    <Typography textAlign={"center"}>No data found</Typography>
                  </TableCell>
                </StyledTableRow>
              ) : (
                invoiceStatusList?.map((row) => (
                  <StyledTableRow key={row?.shipmentNumber}>
                    {tableFields?.map((el) => (
                      <TableCell key={el?.field}>{el.render(row)}</TableCell>
                    ))}
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

InvoiceStatusTable.propTypes = {
  invoiceStatusList: PropTypes.array,
};
export default InvoiceStatusTable;
