export const cartTableHeaders = [
  "Email",
  "Cart id",
  "Opportunity id",
  "Ledger balance",
  "Created date",
  "Action",
];

export const orderTableHeaders = [
  "Email",
  "Cart id",
  "Opportunity id",
  "Ledger balance",
  "When created",
  "Order number",
];

export const orderCreationFailed = "Order creation failed, please try again";
