import {
  TableCell,
  Box,
  Typography,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { forwardRef, useImperativeHandle } from "react";

import { HyperLink, TextField } from "components";
import { StyledTableRow, ColumnTitle } from "CustomStyledComponents";
import StatusChip from "../../PendingPayoutsTab/components/StatusChip";

import { moneyFormat } from "utils";
import { FinancePayouts } from "../../constants";

const { refundDisbursementHeader } = FinancePayouts;

const MarkSuccess = forwardRef(({ data }, ref) => {
  const { getValues, control } = useForm({
    mode: "onTouched",
  });
  const UTR_Column = ["UTR"];
  useImperativeHandle(ref, () => ({
    fetchUTR: () => getValues(),
  }));

  return (
    <>
      <Typography fontWeight={"bold"} textAlign={"center"} mb={4} mt={4}>
        Are you sure you want to mark these payments as successful?
      </Typography>
      {!!data && (
        <Box mb={6}>
          <TableContainer sx={{ maxHeight: 420 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {[...refundDisbursementHeader, ...UTR_Column].map((item) => (
                    <TableCell key={item}>
                      <ColumnTitle>{item}</ColumnTitle>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((ele) => {
                  return (
                    <>
                      <StyledTableRow key={ele?.id}>
                        <TableCell>
                          <StatusChip status={ele?.orderType} isOrderType />
                        </TableCell>
                        <TableCell>
                          {ele?.orderType === FinancePayouts.ORDER_BOOK ? (
                            <Typography>{ele?.orderNumber}</Typography>
                          ) : (
                            <HyperLink title={ele?.orderNumber} />
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography>{moneyFormat(ele?.amount)} </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{ele?.entityName} </Typography>
                        </TableCell>
                        <TableCell>
                          <Controller
                            control={control}
                            name={`utr_${ele?.id}`}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                style={{ padding: 2, margin: 0 }}
                                size={"small"}
                              />
                            )}
                          />
                        </TableCell>
                      </StyledTableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
});

export default MarkSuccess;
