import { useRef, useState, useCallback, useMemo, useEffect } from "react";
import { Box, styled } from "@mui/material";

import { useFetchData, useToaster } from "hooks";
import { deleteCall, postCall_v2, putCall } from "services";
import { DialogBox } from "components";
import { error, success } from "constants";
import { FinancePayouts } from "pages/FinancePayout/constants";
import FileDragAndDrop from "../components/FileDragAndDrop";
import { formatCSVData, payloadMapper } from "./makeData";
import OverlayLoader from "../components/OverlayLoader";
import EditableTransaction from "./EditableTransaction";
import { INTERNAL_FUND_TRANSFER } from "./constants";
import PendingTransaction from "./PendingTransactions";

const UploadBoxLayout = styled(Box)(({ theme }) => ({
  border: 1,
  borderStyle: "solid",
  borderColor: theme.palette.grey[900],
  borderRadius: 12,
  width: "100%",
  paddingRight: 24,
  paddingLeft: 24,
  paddingTop: 20,
  paddingBottom: 20,
}));

const rowsPerPage = 50;

const getInvalidTransactionData = (data) => {
  const errorList = [];
  const transactionList = Object.values(data)?.map((item) => {
    const errorResponseMapper = item[0];
    const errorYupName = Object.keys(errorResponseMapper).filter(
      (item) => !!errorResponseMapper[item],
    );
    errorList?.push(errorYupName);

    return item[1];
  });
  return { transactionList, errorList };
};

const InternalFundTransfer = ({ transactionType, filters, children }) => {
  const uploadFileRef = useRef(null);
  const scrollRef = useRef(null);

  const [editPayout, setEditPayout] = useState(formatCSVData());
  const [errorTransaction, setErrorTransaction] = useState();
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [page, setPage] = useState(0);

  const triggerToaster = useToaster();

  const filterParams = useMemo(
    () => ({
      transactionType: FinancePayouts.transactionFilterValue[transactionType],
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      ...filters,
    }),
    [page, filters, transactionType],
  );

  const {
    refetch: getPendingTxList,
    isFetching,
    data: internalFundList,
  } = useFetchData(
    "fetch-pending-transaction",
    "oms/off-oms-internal-transfer/list",
    null,
    (err) => triggerToaster(err, error),
    filterParams,
  );

  const handlePageChange = useCallback(
    (_event, pageNumber) => setPage(pageNumber),
    [],
  );

  const handleReset = useCallback(() => {
    setEditPayout(formatCSVData());
    setIsBulkUpload(false);
    setErrorTransaction();
  }, []);

  const postTransaction = useCallback(
    async (payload) => {
      try {
        const data = await postCall_v2(
          "oms/off-oms-internal-transfer",
          payload,
        );

        if (Object.values(data.data).length) {
          triggerToaster(
            `${Object.values(data.data).length} transaction is not added`,
            error,
          );

          const { transactionList, errorList } = getInvalidTransactionData(
            data.data,
          );

          setErrorTransaction(errorList);
          setEditPayout(transactionList);
        } else {
          triggerToaster(
            isBulkUpload
              ? FinancePayouts.msgStatements.SAVED_SUCCESS_MSG
              : FinancePayouts.msgStatements.TRANSACTION_SAVED_MSG,
            success,
          );
          handleReset();
        }
        getPendingTxList();
      } catch (err) {
        console.error(err, ":: postTransaction call failed");
        triggerToaster(FinancePayouts.msgStatements.ERROR_MSG, error);
      }
    },
    [isBulkUpload],
  );

  const putUpdateTransaction = useCallback(async (payload, id) => {
    try {
      const result = await putCall(
        `/oms/off-oms-internal-transfer?transferId=${id}`,
        payload,
        {},
        (err) => triggerToaster(err?.data?.detail, error),
      );

      if (Object.keys(result).length) {
        const { transactionList, errorList } =
          getInvalidTransactionData(result);

        setErrorTransaction(errorList);
        setEditPayout(transactionList);
      } else {
        triggerToaster(
          FinancePayouts.msgStatements.TRANSACTION_UPDATED_MSG,
          success,
        );
        handleReset();
      }
      getPendingTxList();
    } catch (ersr) {
      triggerToaster(FinancePayouts.msgStatements.ERROR_MSG, error);
    }
  }, []);

  const handleSubmitEditTransaction = async (request) => {
    const id = editPayout?.[0]?.id;
    const payload = {
      offOmsInternalTransferRtabDTOS: payloadMapper(request),
    };

    setIsUploading(true);
    if (id) {
      await putUpdateTransaction(payload, id);
    } else {
      await postTransaction(payload);
    }

    setIsUploading(false);
    setIsBulkUpload(false);

    isBulkUpload && uploadFileRef.current.resetFile();
  };

  const handleResetTransaction = useCallback(() => {
    handleReset();
    uploadFileRef.current.resetFile();
  }, []);

  const handleUploadFile = useCallback((data) => {
    setIsBulkUpload(true);
    const formatData = formatCSVData(data);
    setEditPayout(formatData);
  }, []);

  const handleApiResponseMapper = useCallback((data) => {
    setEditPayout(data);
  }, []);

  const toggleDeleteId = useCallback((id) => {
    setDeleteId((prev) => (prev ? null : id));
  }, []);

  const handleDeleteTransaction = async () => {
    const res = await deleteCall(
      `/oms/off-oms-internal-transfer?transferId=${deleteId}`,
      {},
      {},
      (err) => {
        triggerToaster(err?.data?.detail, error);
      },
    );
    if (res) {
      triggerToaster(res, success);
      getPendingTxList();
    }
    setDeleteId(null);
  };

  const handleMenuClick = useCallback((action, trans) => {
    const actions = INTERNAL_FUND_TRANSFER.action;
    switch (actions[action]) {
      case actions.edit:
        scrollRef.current.scroll();
        setIsBulkUpload(false);
        handleApiResponseMapper([trans]);
        break;
      case actions.delete:
        toggleDeleteId(trans.id);
        break;
      default:
        console.error("Out of action finance payout out of option");
        break;
    }
  }, []);

  useEffect(() => {
    if (page) {
      setPage(0);
    } else {
      getPendingTxList();
    }
  }, [filters]);

  useEffect(() => {
    getPendingTxList();
  }, [page]);

  return (
    <>
      <OverlayLoader isLoading={isUploading}>
        <UploadBoxLayout>
          <Box>
            <FileDragAndDrop
              ref={uploadFileRef}
              invokeOMSPayout={handleUploadFile}
            />
            <EditableTransaction
              ref={scrollRef}
              data={editPayout}
              errorList={errorTransaction}
              onSubmit={handleSubmitEditTransaction}
              onReset={handleResetTransaction}
              isBulkUpload={isBulkUpload}
            />
          </Box>
        </UploadBoxLayout>
      </OverlayLoader>
      {children}

      <PendingTransaction
        editingId={editPayout?.[0]?.id}
        data={internalFundList?.data}
        onClickMenu={handleMenuClick}
        isFetching={isFetching}
        onPageChange={handlePageChange}
        page={page}
        rowsPerPage={rowsPerPage}
      />
      <DialogBox
        handleClose={toggleDeleteId}
        open={deleteId}
        handleConfirm={handleDeleteTransaction}
        message={FinancePayouts.msgStatements.DELETE_CONFIRMATION_MSG}
      />
    </>
  );
};

export default InternalFundTransfer;
