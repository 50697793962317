import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Grid,
  TextField as MuiTextField,
  styled,
  useTheme,
} from "@mui/material";
import { Controller } from "react-hook-form";

import { DatePickerV1, DropdownV1 } from "components";
import { ErrorMsg } from "CustomStyledComponents";
import { ORDER_BLOCK } from "./constant";
import Comment from "../components/Comment";
import { getCall_v2 } from "services";

const { manualUpload, invoiceYup } = ORDER_BLOCK;

const ManualEntryBox = styled(Box)(({ theme }) => ({
  border: 1,
  borderStyle: "solid",
  borderColor: theme.palette.grey[900],
  borderRadius: 12,
  width: "100%",
  paddingRight: 24,
  paddingLeft: 24,
  paddingTop: 20,
  paddingBottom: 20,
}));

const TextField = styled(MuiTextField)(({ theme }) => ({
  width: "100%",
  ".MuiOutlinedInput-root.Mui-disabled": {
    background: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
  },
  ".MuiOutlinedInput-root.Mui-error": {
    fieldset: {
      borderColor: theme.palette.error.dark,
    },
  },
  label: {
    "&.Mui-error": {
      color: theme.palette.error.dark,
    },
  },
  ".MuiFormHelperText-root.Mui-error": {
    color: theme.palette.error.dark,
  },
}));

const ManualEntry = ({ unique, item, methods, errors }) => {
  const theme = useTheme();
  const [creditInstrumentList, setCreditInstrumentList] = useState(null);

  const yupFormName = useMemo(() => `${invoiceYup}.${unique}.`, [unique]);

  const formError = useMemo(
    () => errors?.[invoiceYup]?.[unique],
    [errors, unique],
  );

  const { control, watch, setValue, setError } = methods;

  const invoiceComments = watch(
    `${yupFormName}${manualUpload.invoiceComments}`,
  );
  const entityGstin = watch(`${yupFormName}${manualUpload.entityGstin}`);
  const creditAmount = watch(`${yupFormName}${manualUpload.creditAmount}`);

  const getCreditInstruments = async () => {
    const url = "oms/off-oms-payout/order-block/available-instruments";
    const params = {
      gstin: entityGstin,
      creditAmount,
    };
    if (
      entityGstin.length === 15 &&
      !formError?.[manualUpload.creditAmount]?.message
    ) {
      const { data } = await getCall_v2(url, params);
      const creditInstrumentListData = data.map((item) => {
        return { label: item.creditInstrument, value: item.creditInstrument };
      });
      if (creditAmount >= data?.[0]?.creditUsable) {
        setError(`${yupFormName}${manualUpload.creditAmount}`, {
          message: `credit amount value cannot be greater that ${data[0].creditUsable}`,
        });
      }
      if (!data.length) {
        setError(`${yupFormName}${manualUpload.creditInstrument}`, {
          message: `credit instrument not available`,
        });
        setValue(`${yupFormName}${manualUpload.creditInstrument}`, "");
      }
      data && setCreditInstrumentList(creditInstrumentListData);
    }
  };

  useEffect(() => {
    creditAmount && entityGstin && getCreditInstruments();
  }, [creditAmount, entityGstin]);

  return (
    <ManualEntryBox>
      <Grid
        container
        gap={3}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid item xs={1.5}>
          <Controller
            control={control}
            name={`${yupFormName}${manualUpload.orderId}`}
            defaultValue={""}
            render={({ field: { value, onChange } }) => (
              <TextField
                label="Order ID"
                size="small"
                onChange={onChange}
                value={value}
                error={!!formError?.[manualUpload.orderId]?.message}
                helperText={formError?.[manualUpload.orderId]?.message || " "}
              />
            )}
          />
        </Grid>
        <Grid item xs={1.5}>
          <Controller
            control={control}
            defaultValue={""}
            name={`${yupFormName}${manualUpload.entityGstin}`}
            render={({ field: { value, onChange } }) => (
              <TextField
                onChange={onChange}
                size="small"
                label="Entity GSTIN"
                value={value}
                error={!!formError?.[manualUpload.entityGstin]?.message}
                helperText={
                  formError?.[manualUpload.entityGstin]?.message || " "
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={1.5}>
          <Controller
            control={control}
            defaultValue={""}
            name={`${yupFormName}${manualUpload.date}`}
            render={({ field: { value, onChange } }) => (
              <DatePickerV1 label="Date" onChange={onChange} value={value} />
            )}
          />
        </Grid>
        <Grid item xs={1.5}>
          <Controller
            control={control}
            defaultValue={""}
            name={`${yupFormName}${manualUpload.orderAmount}`}
            render={({ field: { value, onChange } }) => (
              <TextField
                label="Order Amount"
                size="small"
                onChange={onChange}
                value={value}
                error={!!formError?.[manualUpload.orderAmount]?.message}
                helperText={
                  formError?.[manualUpload.orderAmount]?.message || " "
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={1.5}>
          <Controller
            control={control}
            defaultValue={""}
            name={`${yupFormName}${manualUpload.advanceAmount}`}
            render={({ field: { value, onChange } }) => (
              <TextField
                label="Advance Amount"
                size="small"
                onChange={onChange}
                value={value}
                error={!!formError?.[manualUpload.advanceAmount]?.message}
                helperText={
                  formError?.[manualUpload.advanceAmount]?.message || " "
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={1.5}>
          <Controller
            control={control}
            defaultValue={""}
            name={`${yupFormName}${manualUpload.creditAmount}`}
            render={({ field: { value, onChange } }) => (
              <TextField
                label="Credit Amount"
                size="small"
                disabled={!entityGstin}
                onChange={(e) => {
                  onChange(e);
                  getCreditInstruments();
                }}
                value={value}
                helperText={
                  formError?.[manualUpload.creditAmount]?.message || " "
                }
                error={!!formError?.[manualUpload.creditAmount]?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={1.5}>
          <Controller
            control={control}
            defaultValue={""}
            name={`${yupFormName}${manualUpload.creditInstrument}`}
            render={({ field: { value, onChange } }) => (
              <DropdownV1
                placeholder="Credit Instrument"
                size="small"
                onChange={onChange}
                value={value}
                itemList={creditInstrumentList}
                disabled={!creditInstrumentList?.length}
                menuStyle={
                  !!formError?.[manualUpload.creditInstrument]?.message && {
                    "~fieldset": {
                      borderColor: theme.palette.error.dark,
                    },
                  }
                }
                labelStyle={{
                  color:
                    !!formError?.[manualUpload.creditInstrument]?.message &&
                    theme.palette.error.dark,
                }}
              />
            )}
          />
          <ErrorMsg sx={{ position: "absolute", maxWidth: 100 }}>
            {formError?.[manualUpload.creditInstrument]?.message}
          </ErrorMsg>
        </Grid>
        <Grid item>
          <Comment
            placeholder="Enter your invoice comments"
            name={`${yupFormName}${manualUpload.invoiceComments}`}
            invoiceComments={invoiceComments}
          />
        </Grid>
      </Grid>
    </ManualEntryBox>
  );
};

export default ManualEntry;
