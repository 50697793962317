import { useState, useImperativeHandle, forwardRef, useRef } from "react";
import { Box, Typography } from "@mui/material";
import Papa from "papaparse";

import { SvgIcon } from "components";

import { useToaster } from "hooks";

import theme from "themeProvider";
import { error } from "constants";

const FileDragAndDrop = forwardRef(({ invokeOMSPayout }, ref) => {
  const fileUpload = useRef(null);

  const [file, setFile] = useState();
  const [csvData, setcsvData] = useState();

  const triggerToaster = useToaster();

  // hook to expose function - which will return parsed csv data and reset file
  useImperativeHandle(ref, () => ({
    fetchFileContents: () => csvData,
    resetFile: () => setFile(null),
  }));

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const parseFile = (uploadedFile) => {
    // Initialize a reader
    const reader = new FileReader();
    //listener event
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;
      if (parsedData.length) {
        setcsvData(parsedData);
        invokeOMSPayout(parsedData);
      } else {
        setFile(null);
        triggerToaster(`No data available`, error);
      }
    };
    reader.readAsText(uploadedFile);
  };

  const handleClick = () => {
    fileUpload.current.click();
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const fileFormat = "text/csv";

    const file = [
      ...(e.type === "drop" ? e.dataTransfer.files : e.target.files),
    ];

    if (file.length > 1) {
      triggerToaster(`Only 1 file can be uploaded at a time`, error);
      return;
    }
    const allowedMaxSize = 2;
    const fileSize = file?.[0]?.size / 1024 / 1024; //convert to MB

    if (fileSize.toFixed(2) > allowedMaxSize) {
      triggerToaster("Allowed max file size is 2MB", error);
      return;
    }

    if (file?.[0]?.type !== fileFormat) {
      triggerToaster(`Please upload a csv file`, error);
      return;
    }
    if (file && file.length) {
      setFile(file?.[0]);
      parseFile(file?.[0]);
    }
  };

  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"} mb={4}>
        <Typography fontWeight={"bold"} fontSize={20}>
          Bulk upload
        </Typography>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        style={{
          border: 1,
          borderStyle: "dashed",
          borderColor: theme.palette.grey[900],
          borderRadius: 12,
          backgroundColor: theme.palette.info.lightBlueBackround,
        }}
        mb={2}
        p={8}
        pt={4}
        onClick={handleClick}
        onDragOver={handleDragOver}
        onDrop={handleFileUpload}
      >
        {/* OnClick file upload */}
        <input
          style={{ display: "none" }}
          ref={fileUpload}
          type="file"
          onClick={(event) => {
            event.target.value = null;
          }}
          onChange={handleFileUpload}
        />
        <SvgIcon
          name={"file"}
          style={{
            height: 48,
            width: 48,
            marginBottom: 20,
          }}
        />
        {file && <Typography marginBottom={2}>{file?.name}</Typography>}
        {!file && (
          <>
            <Typography style={{ fontSize: 16, lineHeight: 1.25 }}>
              Click or drag file to this area to upload
            </Typography>
            <Typography
              style={{
                fontSize: 14,
                color: theme.palette.grey[1000],
                lineHeight: 1.29,
              }}
            >
              Support for a single or bulk upload. Maximum file size 2MB.
            </Typography>
          </>
        )}
      </Box>
    </>
  );
});

export default FileDragAndDrop;
