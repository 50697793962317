import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "../SvgIcon";
import { fileExportCsv } from "./utils";

const ExportCSVCTA = ({ onExportData, fileName, tableFields }) => {
  const theme = useTheme();

  const handleFileExport = async () => {
    const data = await onExportData();

    fileExportCsv(tableFields, data, fileName);
  };

  if (!onExportData) {
    return null;
  }

  return (
    <Box
      display={"flex"}
      pb={3}
      sx={{
        gap: 2,
        cursor: "pointer",
      }}
      onClick={handleFileExport}
    >
      <Typography sx={{ color: theme.palette.primary.main, fontWeight: 600 }}>
        Export
      </Typography>
      <SvgIcon
        name={"export"}
        height={20}
        width={20}
        style={{ color: theme.palette.primary.main }}
      />
    </Box>
  );
};

ExportCSVCTA.prototype = {
  onExportData: PropTypes.func,
};

export default ExportCSVCTA;
