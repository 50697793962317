import { useEffect, useState } from "react";
import { Grid, Typography, Box, Switch } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import { ButtonV1 } from "components";
import CreditInstrumentView from "./CreditInstrumentView";
import AddNewInstrumentModal from "./AddNewInstrumentModal";

import { getCall_v2 } from "services";
import { formatCustomerDetails } from "./makeData";
import { useFetchData, useToaster } from "hooks";
import { success, error } from "constants";

const CreditInstrument = () => {
  const { id } = useParams();
  const omsUserDetail = useSelector((state) => state.userData);
  const [isCustomerCreditBlocked, setCustomerCreditBlocked] = useState();
  const [searchparams] = useSearchParams();
  const triggerToaster = useToaster();

  const { refetch: fetchCustomers, data: fetchedCustomersData } = useFetchData(
    "searched-customers",
    `oms/credit/list/search?&searchType=PAN&searchValue=${id}`,
  );
  const { refetch: fetchInstruments, data: fetchedInstrumentData } =
    useFetchData("credit-instruments", `/oms/credit/getCreditInfo?pan=${id}`);

  const gstIn = searchparams.get("gstin");
  const { refetch: fetchCustomerCredit, data: customerCreditInfo } =
    useFetchData(
      "customer-credit-info",
      `/oms/credit/getCreditInfoByPan/?pan=${id}&gstIn=${gstIn}`,
    );

  const { refetch: fetchInstrumentList, data: dropdownDetails } = useFetchData(
    "instrument-type",
    `/oms/credit/getCreditInstrumentList?pan=${id}`,
  );

  const [isModalOpen, setModalOpen] = useState(false);
  const customerDetails = formatCustomerDetails(
    fetchedCustomersData?.data?.[0],
  );

  const handleModal = (value) => {
    setModalOpen(value);
  };

  useEffect(() => {
    fetchCustomers();
    fetchCustomerCredit();
    fetchInstruments();
    fetchInstrumentList();
  }, []);

  useEffect(() => {
    setCustomerCreditBlocked(
      customerCreditInfo?.data?.isCustomerCreditLimitBlocked,
    );
  }, [customerCreditInfo]);

  const handleCustomerBlockLimit = async (e) => {
    try {
      const { data } = await getCall_v2(
        `/oms/credit/updateAllCreditBlockedFlag`,
        {
          pan: fetchedCustomersData?.data?.[0]?.pan,
          creditBlockFlag: e.target.checked,
        },
      );
      setCustomerCreditBlocked(data?.[0]?.isCreditBlocked);
      triggerToaster(
        data?.[0]?.isCreditBlocked ? `Credit blocked` : `Credit unblocked`,
        success,
      );
      fetchInstruments();
    } catch (err) {
      triggerToaster(
        e.target.checked
          ? `Unable to unblock credit limit`
          : `Unable to block credit limit`,
        error,
      );
    }
  };
  return (
    <Box px={8} py={2}>
      <Typography sx={{ fontWeight: 600, fontSize: 28, marginBottom: 5 }}>
        Customer details
      </Typography>
      <Grid container spacing={4} marginBottom={8}>
        {customerDetails.map((ele, i) => (
          <Grid item xs={4} key={i}>
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <Typography fontWeight={400}>{ele?.label}</Typography>
              <Typography fontWeight={600}>{ele?.value}</Typography>
            </Box>
          </Grid>
        ))}
        <Grid item xs={4}>
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Typography fontWeight={400} fontSize={"medium"} marginRight={2}>
              Block credit limits
            </Typography>
            <Switch
              checked={isCustomerCreditBlocked}
              onChange={handleCustomerBlockLimit}
            />
          </Box>
        </Grid>
      </Grid>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        marginBottom={2}
      >
        <Typography fontSize={20} fontWeight={600} marginBottom={4}>
          Credit instruments
        </Typography>
        <ButtonV1
          title={"Add new"}
          size="small"
          onClick={() => handleModal(true)}
        />
      </Box>
      {fetchedInstrumentData?.data?.map((ele) => (
        <Box marginBottom={4}>
          <CreditInstrumentView
            instrumentDetails={ele}
            customerDetails={fetchedCustomersData}
            pan={id}
            fetchCustomerCredit={fetchCustomerCredit}
            omsUserDetail={omsUserDetail}
          />
        </Box>
      ))}
      <AddNewInstrumentModal
        open={isModalOpen}
        handleModal={handleModal}
        dropdownDetails={dropdownDetails?.data}
        customerDetails={fetchedCustomersData}
        omsUserDetail={omsUserDetail}
      />
    </Box>
  );
};

export default CreditInstrument;
