import { useState, useRef } from "react";
import { Box, Grid, Typography, useTheme, TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { useLocation, useSearchParams } from "react-router-dom";

import {
  ButtonV1,
  DropdownV1,
  MultiSelectDropdown,
  MultiSelectSearch,
} from "components";
import { getCall } from "services";
import { opsSpocList } from "constants";
import { updateQuery } from "../utils";
import {
  PLANNED_SHIPMENT_STATUS,
  ORDER_PAYMENT_TRANSACTION_TYPES,
} from "../constant";
import { DISPLAY_ATTRIBUTE, BUSINESS_TYPES } from "constants";
import { useFetchData } from "hooks";

export const OrderFilter = ({
  disableReset,
  refetchList,
  setDisableReset,
  disableListCallFn,
}) => {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const { search } = useLocation();
  const buyerRef = useRef();
  const deliveryStateRef = useRef();
  const sellerNameRef = useRef();
  const selectRef = useRef(null);
  const businessUnitRef = useRef(null);
  const paymentStateRef = useRef(null);
  const orderStateRef = useRef(null);
  const sellerStateRef = useRef();
  const plannedShipmentStatusRef = useRef(null);
  const orderCategoryRef = useRef(null);

  const sellerNameParams = searchParams.get("companyName")?.split(",");
  const deliveryStateParams = searchParams.get("orderState")?.split(",");
  const sellerStateParams = searchParams.get("sellerState")?.split(",");
  const opsSpocParams = searchParams.get("opsSpoc")?.split(",");
  const businessUnitParams = searchParams.get("businessUnit")?.split(",");
  const buyerNameParams = searchParams.get("buyerName")?.split(",");
  const filterTypeParams = searchParams.get("filterType");
  const filterValueParams = searchParams.get("filterValue");
  const sellerOrderNumberParam = searchParams.get("sellerOrderNumber");
  const customerOrderNumberParam = searchParams.get("customerOrderNumber");
  const orderStatusParam = searchParams.get("orderStatusList")?.split(",");
  const paymentTypeParam = searchParams.get("orderPaymentTypes")?.split(",");
  const plannedShipmentStatusParam = searchParams
    .get("plannedShipmentStatus")
    ?.split(",");
  const orderCategoryParams = searchParams.get("orderCategory")?.split(",");
  const orderPaymentTransactionType = searchParams
    .get("orderPaymentTransactionType")
    ?.split(",");

  const [toggleFilter, setToggleFilter] = useState(false);
  const [isDirtyBuyer, setIsDirtBuyer] = useState(false);
  const [isDirtyDeliveryState, setIsDirtyDeliveryState] = useState(false);
  const [isDirtySellerName, setIsDirtySellerName] = useState(false);
  const [isOpsMemberSelected, setIsOpsMemberSelected] = useState(false);
  const [isOrderTypeSelected, setIsOrderTypeSelected] = useState(false);
  const [isPaymentType, setIsPaymentType] = useState(false);
  const [isOrderType, setIsOrderType] = useState(false);
  const [isDirtyPlannedShipment, setIsDirtyPlannedShipmet] = useState(false);
  const [isDirtyOrderCategory, setIsDirtyOrderCategory] = useState(false);

  const { getValues, control, setValue, reset, watch } = useForm({
    mode: "onTouched",
  });

  const getSearchedData = async (search, endpoint) => {
    const params = {
      [endpoint]: search,
    };
    const res = await getCall(`/oms/order/list/search/auto-suggest`, params);
    return res;
  };

  const handleReset = () => {
    disableListCallFn(false);
    setSearchParams(
      `filterType=${filterTypeParams}&filterValue=${filterValueParams}`,
    );
    refetchList();
    reset();
    setValue("sellerOrderNumber", "");
    setValue("customerOrderNumber", "");

    setDisableReset(true);
    //reset filter using ref
    businessUnitRef.current?.resetValue();
    orderStateRef?.current?.resetValue();
    paymentStateRef?.current?.resetValue();
    selectRef.current?.resetValue();
    buyerRef?.current?.resetValue();
    deliveryStateRef?.current?.resetValue();
    sellerNameRef?.current?.resetValue();
    sellerStateRef?.current?.resetValue();
    plannedShipmentStatusRef?.current?.resetValue();
  };

  /**
   * @description Format values from array of object or string in string with comma separated
   * @param {*} ref Reference of textfield
   * @param {*} attribute attribute for key selection from object
   * @returns Return values in string formatted with comma separated
   */
  const validateFilterValue = (ref, attribute) => {
    const values = ref?.current?.fetchValue();
    const formattedValues = values?.map((company) => {
      if (typeof company !== "string") {
        return company[attribute];
      }
      return company;
    });
    return formattedValues?.join();
  };

  const handleFilter = () => {
    const companyName = validateFilterValue(
      sellerNameRef,
      DISPLAY_ATTRIBUTE.sellerName,
    );
    const buyerName = validateFilterValue(
      buyerRef,
      DISPLAY_ATTRIBUTE.buyerName,
    );
    const orderState = validateFilterValue(
      deliveryStateRef,
      DISPLAY_ATTRIBUTE.deliveryState,
    );
    const sellerState = validateFilterValue(
      sellerStateRef,
      DISPLAY_ATTRIBUTE.sellerState,
    );

    const orderPaymentTransactionType = getValues(
      "orderPaymentTransactionType",
    );

    const filterData = {
      customerOrderNumber: getValues("customerOrderNumber")?.trim(),
      sellerOrderNumber: getValues("sellerOrderNumber")?.trim(),
      orderPaymentTransactionType:
        orderPaymentTransactionType ?? orderPaymentTransactionType?.trim(),
      companyName,
      buyerName,
      orderState,
      sellerState,
      opsSpoc: selectRef.current?.fetchValue(),
      businessUnit: businessUnitRef.current?.fetchValue(),
      plannedShipmentStatus: plannedShipmentStatusRef?.current?.fetchValue(),
      paymentType: paymentStateRef?.current?.fetchValue(),
      orderStatusList: orderStateRef?.current?.fetchValue(),
      orderPaymentTypes: paymentStateRef?.current?.fetchValue(),
      orderCategory: orderCategoryRef?.current?.fetchValue(),
      page: 0,
    };

    if (filterTypeParams) {
      filterData.filterType = filterTypeParams;
    }
    if (filterValueParams) {
      filterData.filterValue = filterValueParams;
    }
    const updatedQuery = updateQuery(searchParams, filterData);
    disableListCallFn(true);
    setSearchParams(updatedQuery);
  };

  const isCTADisabled =
    watch("customerOrderNumber") ||
    watch("sellerOrderNumber") ||
    isDirtyBuyer ||
    isDirtyDeliveryState ||
    isDirtySellerName ||
    isOpsMemberSelected ||
    isOrderTypeSelected ||
    isDirtyPlannedShipment ||
    isDirtyOrderCategory ||
    isPaymentType ||
    isOrderType ||
    watch("orderPaymentTransactionType");

  const handleToggleFilter = () => setToggleFilter((prev) => !prev);

  const { data: orderStateList } = useFetchData(
    "orderState",
    "oms/master-data/ORDER_STATE",
    null,
    null,
    {},
    null,
    true,
  );

  const { data: paymentType } = useFetchData(
    "paymentType",
    "oms/master-data/PAYMENT_TYPE",
    null,
    null,
    {},
    null,
    true,
  );
  const { data: orderCategory } = useFetchData(
    "orderCategory",
    "oms/master-data/ORDER_CATEGORY",
    null,
    null,
    {},
    null,
    true,
  );

  return (
    <Box
      style={{
        borderRadius: 8,
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 32,
        paddingRight: 32,
        border: `2px solid ${theme.palette.grey["A100"]}`,
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%",
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      <Box display={"flex"} flexDirection="column">
        <Grid container flex={4} spacing={4} rowGap={2}>
          <Grid item xs={2}>
            <MultiSelectDropdown
              ref={selectRef}
              itemList={opsSpocList}
              displayAttribute={"value"}
              label={"Ops SPOC"}
              isListOfObjects={true}
              defaultSelectedValues={opsSpocParams ?? []}
              setIsDirty={setIsOpsMemberSelected}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              control={control}
              name={"sellerOrderNumber"}
              defaultValue={sellerOrderNumberParam ?? ""}
              render={({ field: { value, onChange } }) => (
                <TextField
                  label="Seller order number"
                  size="small"
                  onChange={onChange}
                  value={value}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <MultiSelectSearch
              ref={buyerRef}
              defaultSelectedValues={buyerNameParams}
              displayAttribute={DISPLAY_ATTRIBUTE.buyerName}
              label="Customer name"
              onSearch={(searchInput) =>
                getSearchedData(searchInput, DISPLAY_ATTRIBUTE.buyerName)
              }
              setIsDirty={setIsDirtBuyer}
            />
          </Grid>
          <Grid item xs={2}>
            <MultiSelectSearch
              ref={deliveryStateRef}
              defaultSelectedValues={deliveryStateParams}
              displayAttribute={DISPLAY_ATTRIBUTE.deliveryState}
              label="Delivery state"
              onSearch={(searchInput) =>
                getSearchedData(searchInput, DISPLAY_ATTRIBUTE.deliveryState)
              }
              setIsDirty={setIsDirtyDeliveryState}
            />
          </Grid>
          <Grid item xs={2}>
            <MultiSelectDropdown
              ref={paymentStateRef}
              itemList={paymentType?.data}
              displayAttribute={"value"}
              defaultSelectedValues={paymentTypeParam}
              label={"Payment type"}
              isListOfObjects={true}
              setIsDirty={setIsPaymentType}
            />
          </Grid>
          <Grid item xs={2}>
            <MultiSelectDropdown
              ref={orderStateRef}
              itemList={orderStateList?.data}
              displayAttribute={"state"}
              label={"Order status"}
              isListOfObjects={true}
              defaultSelectedValues={orderStatusParam}
              setIsDirty={setIsOrderType}
            />
          </Grid>
          {toggleFilter && (
            <>
              <Grid item xs={2}>
                <MultiSelectSearch
                  ref={sellerNameRef}
                  defaultSelectedValues={sellerNameParams}
                  displayAttribute="companyName"
                  label="Seller name"
                  onSearch={(searchInput) =>
                    getSearchedData(searchInput, DISPLAY_ATTRIBUTE.sellerName)
                  }
                  setIsDirty={setIsDirtySellerName}
                />
              </Grid>
              <Grid item xs={2}>
                <MultiSelectDropdown
                  ref={businessUnitRef}
                  itemList={BUSINESS_TYPES}
                  displayAttribute={"state"}
                  label={"Business unit"}
                  isListOfObjects={true}
                  defaultSelectedValues={businessUnitParams ?? []}
                  setIsDirty={setIsOrderTypeSelected}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  control={control}
                  defaultValue={customerOrderNumberParam ?? ""}
                  name={"customerOrderNumber"}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      label="Customer order number"
                      size="small"
                      onChange={onChange}
                      value={value}
                      sx={{ width: "100%" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <MultiSelectSearch
                  ref={sellerStateRef}
                  defaultSelectedValues={sellerStateParams}
                  displayAttribute={DISPLAY_ATTRIBUTE.sellerState}
                  label="Seller state"
                  onSearch={(searchInput) =>
                    getSearchedData(searchInput, DISPLAY_ATTRIBUTE.sellerState)
                  }
                  setIsDirty={setIsDirtyDeliveryState}
                />
              </Grid>
              <Grid item xs={2.3}>
                <MultiSelectDropdown
                  ref={plannedShipmentStatusRef}
                  defaultSelectedValues={plannedShipmentStatusParam}
                  itemList={PLANNED_SHIPMENT_STATUS}
                  displayAttribute={"state"}
                  label={"Planned Shipment Status"}
                  isListOfObjects={true}
                  setIsDirty={setIsDirtyPlannedShipmet}
                />
              </Grid>
              <Grid item xs={1.7}>
                <MultiSelectDropdown
                  ref={orderCategoryRef}
                  defaultSelectedValues={orderCategoryParams}
                  itemList={orderCategory?.data}
                  label={"Order category"}
                  isListOfObjects={false}
                  setIsDirty={setIsDirtyOrderCategory}
                />
              </Grid>
            </>
          )}
          {toggleFilter && (
            <Grid item xs={2}>
              <Controller
                control={control}
                defaultValue={orderPaymentTransactionType ?? ""}
                name={"orderPaymentTransactionType"}
                render={({ field: { value, onChange } }) => (
                  <DropdownV1
                    placeholder={"Order payment type"}
                    itemList={ORDER_PAYMENT_TRANSACTION_TYPES}
                    value={value}
                    displayKey="label"
                    valueKey="value"
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
          )}
          <Grid
            item
            xs={toggleFilter ? 10 : 12}
            style={{
              display: "flex",
              gap: 20,
              alignItems: "start",
            }}
          >
            <ButtonV1
              title="Submit"
              onClick={handleFilter}
              disabled={!isCTADisabled}
              color="primary"
              variant="contained"
            />
            <ButtonV1
              style={
                !disableReset
                  ? {
                      color: theme.palette.primary.main,
                      border: `1px solid ${theme.palette.primary.main}`,
                    }
                  : {}
              }
              onClick={handleReset}
              disabled={!isCTADisabled && !search.includes("opsSpoc")}
              variant="outlined"
              title="Reset"
            />
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontWeight: 600,
                display: "flex",
                flex: 1,
                cursor: "pointer",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
              onClick={handleToggleFilter}
            >
              {toggleFilter ? "Show less filter" : "Show advance filter"}
              {toggleFilter ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
