const SvgIcon = ({ name, width, height, style = {} }) => {
  return (
    <object
      aria-label={`JSW ${name}`}
      width={width}
      height={height}
      type="image/svg+xml"
      data={`/assets/${name}.svg`}
      style={{ pointerEvents: "none", ...style }}
    />
  );
};

export default SvgIcon;
