import { useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";

import { OrderFilter } from "./OrderFilter";
import OrderTable from "./OrderTable";
import { updateQuery } from "../utils";
import { useFetchData } from "hooks/useFetchData";
import { getCall_v2 } from "services";
import { error } from "constants";
import { useToaster } from "hooks";

const PendingPaymentOrdersTab = () => {
  const [ordersData, setOrdersData] = useState({});
  const [disableReset, setDisableReset] = useState(true);
  const { search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [disableListCall, setDisableListCall] = useState(false);
  const orderFilterDuration = searchParams.get("filterValue");
  const page = searchParams.get("page");
  const triggerToaster = useToaster();

  const onSuccess = (res) => {
    setOrdersData(res?.data);
  };

  const { refetch, isFetching } = useFetchData(
    "order-list",
    `/oms/order/orderInfo/pending-payment${search}&offset=${
      page * 20
    }&limit=20`,
    onSuccess,
  );

  useEffect(() => {
    refetch();
  }, []);

  const handlePageChange = (_event, page) => {
    const updatedQuery = updateQuery(searchParams, { page });
    setSearchParams(updatedQuery);
  };

  const handleExportData = async () => {
    try {
      const result = await getCall_v2(
        `/oms/order/orderInfo/pending-payment${search}&offset=${0}&limit=1000`,
      );
      if (result?.data) {
        const formattedRes = result?.data?.response?.map((item) => {
          return {
            ...item,
            ledgerBalance: item?.accountBalance?.balance,
            orderAmount: item?.netOrderAmount?.displayAmount,
          };
        });
        return formattedRes;
      }
    } catch (err) {
      triggerToaster(err.message, error);
    }
  };

  const disableListCallFn = (evt) => setDisableListCall(evt);

  return (
    <Box>
      <OrderFilter
        disableReset={disableReset}
        setDisableReset={setDisableReset}
        refetchList={() => {
          setSearchParams(`filterValue=${orderFilterDuration}`);
        }}
        disableListCallFn={(evt) => disableListCallFn(evt)}
      />
      <Box py={4}>
        <OrderTable
          res={ordersData?.response}
          totalCount={ordersData?.totalCount}
          offset={ordersData?.offset}
          onPageChange={handlePageChange}
          onExportData={handleExportData}
          defaultQuickFilter={orderFilterDuration || "today"}
          isLoading={isFetching}
        />
      </Box>
    </Box>
  );
};
export default PendingPaymentOrdersTab;
