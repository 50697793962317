import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const NoAccess = () => {
  return (
    <Box
      display={"flex"}
      sx={{ justifyContent: "center", alignItems: "center", height: "90vh" }}
    >
      <Typography variant="h4">
        You don't have access to requested feature
      </Typography>
    </Box>
  );
};

export default NoAccess;
