import { useEffect, useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { AttachmentSharp } from "@mui/icons-material";
import PropTypes from "prop-types";

import theme from "themeProvider";
import { ModalBox } from "components";
import ModalContent from "./ModalContent";

const allowedMaxSize = 5; //MB
const allowedFileCount = 10; //fileCount

const Attachment = ({
  fileType,
  fileDetails = {},
  onFileChange,
  onFileDownload,
  onFileDelete,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [files, setFiles] = useState({});

  const noOfFiles = Object?.keys(fileDetails)?.length;

  const getSortedFiles = (files) => {
    const sortedFiles = Object.fromEntries(
      Object.entries(files).sort(
        ([, a], [, b]) =>
          new Date(b.whenCreated).getTime() - new Date(a.whenCreated).getTime(),
      ),
    );
    return sortedFiles;
  };

  useEffect(() => {
    if (fileDetails && Object.keys(fileDetails).length) {
      setFiles(getSortedFiles(fileDetails));
    }
  }, [fileDetails]);

  const onFileAttach = (evt, fileType) => {
    let attachedFiles = evt?.target?.files;
    if (attachedFiles.length) {
      let validatedResult = [];
      let lengthOfFiles = attachedFiles.length;
      if (files && typeof files === "object") {
        lengthOfFiles += Object.keys(files).length;
      }
      Object.keys(attachedFiles).forEach((ele) => {
        const tempFile = attachedFiles[ele];
        const name = tempFile?.name;
        const fileSize = tempFile.size / 1024 / 1024; //convert to MB

        if (fileSize.toFixed(2) > allowedMaxSize) {
          setErrorMessage(`Allowed max file size is ${allowedMaxSize}MB`);
          validatedResult.push(false);
        } else if (
          Object.keys(files)?.some((ele) => files[ele].originalName === name)
        ) {
          validatedResult.push(false);
          setErrorMessage("File is already selected");
        } else if (lengthOfFiles > allowedFileCount) {
          validatedResult.push(false);
          setErrorMessage(`You can upload only upto ${allowedFileCount} files`);
        }
      });
      if (!validatedResult.includes(false)) {
        setErrorMessage("");
        onFileChange(attachedFiles, fileType);
      }
    }
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };
  return (
    <Box>
      <Box>
        <Box display={"flex"} mb={2}>
          <Typography pr={2}>{fileType?.label}</Typography>
          <Typography color={theme.palette.grey["400"]} pr={2}>
            &#x25CF;
          </Typography>
          <Typography style={{ color: theme.palette.grey["700"] }}>
            Max {allowedFileCount} files
          </Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            border: 1,
            borderStyle: "dashed",
            borderWidth: 1,
            borderRadius: 4,
            borderColor: theme.palette.grey["400"],
          }}
          p={2}
        >
          <IconButton
            sx={{ p: 0 }}
            disableRipple={true}
            component="label"
            disabled={noOfFiles >= allowedFileCount}
          >
            <AttachmentSharp
              style={{
                color:
                  noOfFiles >= allowedFileCount
                    ? theme.palette.grey["700"]
                    : theme.palette.primary.main,
              }}
            />
            <input
              aria-label="file"
              type="file"
              multiple
              accept="image/*, .pdf,.PDF"
              hidden
              onChange={(e) => onFileAttach(e, fileType?.fileType)}
            />
            <Typography
              ml={4}
              style={{
                color:
                  noOfFiles >= 5
                    ? theme.palette.grey["700"]
                    : theme.palette.primary.main,
              }}
            >
              Attach file
            </Typography>
          </IconButton>
          {!!noOfFiles && (
            <Box
              sx={{ p: 0, cursor: "pointer" }}
              component="label"
              onClick={toggleModal}
            >
              <Typography color="primary" ml={4} fontWeight={600}>
                {noOfFiles > 1
                  ? `${noOfFiles} attachments`
                  : `${noOfFiles} attachment`}
              </Typography>
            </Box>
          )}
        </Box>
        <Typography color={theme.palette.error.dark}>{errorMessage}</Typography>
      </Box>
      <ModalBox
        open={isModalOpen}
        onCloseModal={toggleModal}
        width={"55%"}
        hideCrossIcon={true}
      >
        <ModalContent
          fileType={fileType}
          toggleModal={toggleModal}
          files={files}
          onFileDownload={onFileDownload}
          onFileDelete={onFileDelete}
        />
      </ModalBox>
    </Box>
  );
};

Attachment.propTypes = {
  fileType: PropTypes.object,
  files: PropTypes.object,
  onFileChange: PropTypes.func,
  onFileDownload: PropTypes.func,
  onFileDelete: PropTypes.func,
};

export default Attachment;
