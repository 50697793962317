import { useState } from "react";
import { IconButton, TextField } from "@mui/material";
import { EditOutlined, CheckCircle } from "@mui/icons-material";
import PropTypes from "prop-types";

import theme from "themeProvider";

const EditableInput = ({ icon, value, error, ...rest }) => {
  const [isDisabled, setDisabled] = useState(true);
  return (
    <>
      <TextField
        size="small"
        value={value}
        InputProps={{
          startAdornment: icon,
          style: {
            backgroundColor: !isDisabled && theme.palette.common.white,
            padding: 1,
            color: theme.palette.common.black,
          },
        }}
        disabled={isDisabled}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiOutlinedInput-input": {
            padding: 1,
          },
          "& .MuiOutlinedInput-input.Mui-disabled": {
            WebkitTextFillColor: theme.palette.common.black,
          },
        }}
        color="secondary"
        {...rest}
      />
      {isDisabled ? (
        <IconButton
          type="submit"
          size="small"
          onClick={() => setDisabled(false)}
        >
          <EditOutlined sx={{ fontSize: 16 }} />
        </IconButton>
      ) : (
        <IconButton
          size="small"
          onClick={() => {
            !error && setDisabled(true);
          }}
        >
          <CheckCircle sx={{ fontSize: 16 }} />
        </IconButton>
      )}
    </>
  );
};

export default EditableInput;

EditableInput.propTypes = {
  icon: PropTypes.object,
  value: PropTypes.string,
  error: PropTypes.object,
  rest: PropTypes.object,
};
