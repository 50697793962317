import { memo } from "react";
import { Box, Typography } from "@mui/material";

import theme from "themeProvider";

const EmptyList = () => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        borderRadius: 6,
        border: `1px solid theme.palette.grey["200"]`,
        backgroundColor: theme.palette.grey["50"],
        boxShadow: `0 0 4px 2px ${theme.palette.grey[200]}`,
      }}
      mt={10}
      p={10}
    >
      <Typography>Error occured, please try again !</Typography>
    </Box>
  );
};

export default memo(EmptyList);
