import _ from "lodash";
export class GroupDetailModel {
  constructor(groupDetail, allUser) {
    this.getGroupData(groupDetail.groups[0]);
    this.getUserList(groupDetail.users);
    this.getUserRole(allUser);
  }

  getUserList = (data) => {
    let userList = {};
    data?.forEach((elem) => {
      userList = {
        [elem.id]: {
          email: elem.email,
          name: elem.name,
          imageUrl: elem.imageUrl,
          id: elem.id,
        },
        ...userList,
      };
    });
    this.userList = userList;
  };

  getRoleList = (data) => {
    if (!data) {
      return [];
    } else {
      return data?.map((elem) => elem.name);
    }
  };

  getGroupData = (data) => {
    this.groupInfo = {
      id: data?.id,
      email: data?.email,
      name: data?.name,
    };
  };

  getUserRole = (data) => {
    data?.forEach(({ users, roles }) => {
      const id = users[0].id;
      const checkUserExist = Object.keys(this.userList).includes(`${id}`);
      if (checkUserExist) {
        const roleList = this.getRoleList(roles);
        this.userList = {
          ...this.userList,
          [id]: { roles: roleList, ...this.userList[id] },
        };
      }
    });
  };

  getFilterResult = (search) => {
    if (!search) this.filteredUser = null;
    else {
      const searchWord = _.camelCase(search);
      const filterUser = Object.values(this.userList).filter(
        ({ name, email }) =>
          _.camelCase(name)?.includes(searchWord) ||
          _.camelCase(email)?.includes(searchWord),
      );
      this.filteredUser = filterUser;
    }
  };

  resetFilterResult = () => {
    this.filteredUser = null;
  };
}
