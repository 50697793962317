import { forwardRef, useImperativeHandle, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { RemoveCircle } from "@mui/icons-material";

import { ColumnView } from "./CustomDetailComponents";
import { GridSkeleton } from "CustomStyledComponents";
import { ButtonV1, DropdownV1, TextField, Loader } from "components";
import { useToaster } from "hooks";

import { getCall_v2, postCall_v2 } from "services";
import { error, success } from "constants";
import { SO_TYPE } from "./constants";

const MAX_SO_LINEITEM = 10;
/**
 * @description Each lineitem will have multiple SO lineitems
 * Component has a prop lineitem id which could be used to map multiple SO lineitems to them
 */
const LineItemSoInput = forwardRef(({ lineItemId }, ref) => {
  const [loaderVisible, setLoaderVisibility] = useState(false);
  const orderDetails = useSelector((state) => state?.orderDetails);

  const { control, getValues, handleSubmit } = useForm({
    mode: "onTouched",
  });
  const { fields, remove, append } = useFieldArray({
    control,
    name: "soLineItemList",
  });

  const triggerToaster = useToaster();

  /**
   * @description Fetch SO lineitems
   */
  const fetchSoLineItems = async () => {
    try {
      const { data } = await getCall_v2(
        `/oms/line-item/so-mappings/${lineItemId}`,
      );
      if (data && data.length) {
        remove();
        data.forEach((ele) => append(ele));
      }
    } catch (ex) {
      console.error(`Error in fetching SO lineitems ${ex}`);
    }
  };

  /**
   * Invoke API to fetch so lineitems for existing SO
   */
  useEffect(() => {
    (async () => {
      await fetchSoLineItems();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   * @description
   * Increment by 1 so lineitem box
   * Display error if SO count more than limit
   */
  const addSoLineItem = () => {
    if (fields && fields.length < MAX_SO_LINEITEM) {
      const so_Obj = { soNumber: "", soLineItemNumber: "", soType: "" };
      append(so_Obj);
    } else {
      triggerToaster(`Max ${MAX_SO_LINEITEM} SO lineitems allowed`, error);
    }
  };

  // hook to expose function - which will increase the counter for the lineItem SO
  useImperativeHandle(ref, () => ({
    handleSoCounter: () => addSoLineItem(),
  }));

  /**
   * @description save or update SO lineitems
   */
  const saveOrUpdateSO = async () => {
    try {
      setLoaderVisibility(true);
      const formValues = getValues();
      const SOLineItemsFormValue = Object.values(formValues?.soLineItemList);
      SOLineItemsFormValue.forEach((ele) => {
        ele.lineItemId = lineItemId;
        ele.buyerGstin = orderDetails?.buyerDetails?.gstin;
      });
      if (SOLineItemsFormValue && SOLineItemsFormValue.length) {
        await postCall_v2(
          `/oms/line-item/saveOrUpdateSoMapping`,
          SOLineItemsFormValue,
        );
        triggerToaster(`SO lineitems updated`, success);
      }
    } catch (ex) {
      console.error(`Unable to save/update SO lineitem ${ex}`);
      triggerToaster(
        ex?.data?.detail || `Failure in updating SO lineitems`,
        error,
      );
    } finally {
      await reloadSoLineItems();
    }
  };

  /**
   * @description Delete SO lineitem
   * @param lineItemKeylineitem array index
   */
  const deleteSO = async (lineItemIndex) => {
    try {
      setLoaderVisibility(true);
      const values = getValues();
      const payload = values?.soLineItemList[lineItemIndex];
      payload.lineItemId = lineItemId;
      await postCall_v2(`/oms/line-item/deleteSoMapping`, payload);
      triggerToaster(`Deleted SO lineitem successfully`, success);
    } catch (ex) {
      console.error(`Unable to delete SO lineitem ${ex}`);
      triggerToaster(`Unable to delete SO`, error);
    } finally {
      await reloadSoLineItems();
    }
  };

  /**
   * @description Reload SO lineitems
   */
  const reloadSoLineItems = async () => {
    try {
      remove();
      await fetchSoLineItems();
    } catch (ex) {
      console.error(`Error on reload operation`);
    } finally {
      setLoaderVisibility(false);
    }
  };

  return (
    <>
      {loaderVisible ? (
        <Loader sx={{ marginTop: 0 }} />
      ) : (
        <>
          {fields && fields.length ? (
            <Box
              as="form"
              width={"100%"}
              onSubmit={handleSubmit(saveOrUpdateSO)}
              p={8}
              style={{
                height: 250,
                overflowY: "auto",
                border: 1,
                borderStyle: "solid",
                borderRadius: 8,
              }}
            >
              {fields.map((ele, index) => {
                const lineItemKey = index;
                return (
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    key={`${lineItemKey}`}
                    mb={4}
                  >
                    <GridSkeleton
                      firstChild={
                        <ColumnView label={"SO number"} bold={true}>
                          <Controller
                            control={control}
                            name={`soLineItemList.${lineItemKey}.soNumber`}
                            defaultValue={ele?.soNumber}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                size="small"
                                onChange={onChange}
                                value={value}
                              />
                            )}
                          />
                        </ColumnView>
                      }
                      secondChild={
                        <ColumnView label={"SO line item"} bold={true}>
                          <Controller
                            control={control}
                            name={`soLineItemList.${lineItemKey}.soLineItemNumber`}
                            defaultValue={ele?.soLineItemNumber}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                size="small"
                                onChange={onChange}
                                value={value}
                              />
                            )}
                          />
                        </ColumnView>
                      }
                      thirdChild={
                        <ColumnView label={"SO type"} bold={true}>
                          <Controller
                            control={control}
                            name={`soLineItemList.${lineItemKey}.soType`}
                            defaultValue={ele?.soType}
                            render={({ field: { value, onChange } }) => (
                              <DropdownV1
                                onChange={onChange}
                                itemList={SO_TYPE}
                                listType="constants"
                                value={value}
                                menuStyle={{ minWidth: 170 }}
                              />
                            )}
                          />
                        </ColumnView>
                      }
                    />
                    <RemoveCircle
                      style={{ cursor: "pointer", marginTop: 8 }}
                      color="primary"
                      onClick={() => deleteSO(index)}
                    />
                  </Box>
                );
              })}
              <ButtonV1 title={"Save"} type="submit" />
            </Box>
          ) : (
            <Typography variant="h6">No sales order mapped</Typography>
          )}
        </>
      )}
    </>
  );
});

LineItemSoInput.propTypes = {
  lineItemId: PropTypes.any.isRequired,
};
export default LineItemSoInput;
