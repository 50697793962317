import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";

import Filter from "./Filter";
import QuickFilter from "./QuickFilter";
import Export from "components/ExportCta";
import CreditTable from "./CreditTable";

import { downloadCsv } from "utils";
import { useFetchData, useToaster } from "hooks";
import { getCall_v2 } from "services";

import { makeCsvData } from "../makeData";
import { CREDIT } from "../constant";
import { error } from "constants";

const LIMIT = 20;
const CSV_LIMIT = 1000;

const PendingCredit = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [filter, setFilter] = useState({
    filterValue: Object.keys(CREDIT.quickFilter)[0],
  });
  const triggerToaster = useToaster();

  const getUrl = useCallback(
    (page, pageLimit) => {
      let newUrl = "";
      if (filter?.businessUnit !== undefined) {
        const urlParams = Object.keys(filter)
          .map((key) =>
            key === "filterValue" && filter[key] === "all"
              ? "filterValue="
              : `${key}=${filter?.[key] || ""}`,
          )
          .join("&");
        newUrl = `oms/order/CreditInfo?offset=${
          page * pageLimit
        }&limit=${pageLimit}&${urlParams}`;
      } else {
        newUrl = `oms/order/credit/list?offset=${
          page * pageLimit
        }&limit=${pageLimit}&filterValue=${
          filter.filterValue === "all" ? "" : filter.filterValue
        }`;
      }
      return newUrl;
    },
    [filter],
  );

  const url = useMemo(() => {
    return getUrl(pageNumber, LIMIT);
  }, [getUrl, pageNumber]);

  const { data, refetch, isFetching } = useFetchData("order-credit-list", url);

  useEffect(() => {
    refetch();
  }, [refetch, url]);

  const handleFilter = useCallback(
    (filterPayload) => setFilter((prev) => ({ ...prev, ...filterPayload })),
    [],
  );

  const handleQuickFilter = useCallback((filter) => {
    setFilter((prev) => ({ ...prev, filterValue: filter }));
  }, []);

  const handleReset = useCallback(() => {
    setFilter((prev) => ({ filterValue: prev.filterValue }));
  }, []);

  const handleFileExport = useCallback(async () => {
    try {
      const result = await getCall_v2(getUrl(0, CSV_LIMIT));
      const csvRaw = makeCsvData(result?.data);
      downloadCsv(csvRaw, `credit_order_${new Date().toLocaleDateString()}`);
    } catch (err) {
      triggerToaster(CREDIT.ERROR_MSG, error);
      console.log(error);
    }
  }, [getUrl]);

  const handlePageChange = useCallback(
    (_event, pageNumber) => setPageNumber(pageNumber),
    [],
  );

  return (
    <>
      <Filter onFilter={handleFilter} onreset={handleReset} />
      <Box
        display="flex"
        justifyContent={"space-between"}
        alignItems={"center"}
        marginBottom={4}
      >
        <QuickFilter
          state={filter?.filterValue}
          onQuickFilter={handleQuickFilter}
        />
        <Export onExport={handleFileExport} />
      </Box>
      <CreditTable
        data={data?.data}
        onPageChange={handlePageChange}
        isFetching={isFetching}
      />
    </>
  );
};

export default PendingCredit;
