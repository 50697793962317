import { Box } from "@mui/material";
import { styled } from "@mui/system";
import PropTypes from "prop-types";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { TextField } from "components";

const Label = styled("span")({
  fontWeight: "600",
});

const DatePicker = ({
  label,
  value,
  onChange,
  disabled,
  dateStyle,
  labelStyle,
  size = "small",
  rest,
  disablePast = false,
  disableFuture = false,
  customPadding,
  minDate,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        ...rest,
      }}
    >
      <Label
        style={{
          ...(labelStyle && { ...labelStyle }),
        }}
      >
        {label}
      </Label>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          value={value || null}
          disablePast={disablePast}
          disableFuture={disableFuture}
          minDate={minDate && new Date(minDate)}
          onChange={onChange}
          disabled={disabled || false}
          inputFormat="dd/MM/yyyy"
          renderInput={(params) => (
            <TextField
              customPadding={customPadding}
              {...params}
              style={{
                ...(dateStyle && { ...dateStyle }),
              }}
              size={size}
              data-testid="date-picker"
              area-label="Choose date"
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
};

DatePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  dateStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
};

export default DatePicker;
