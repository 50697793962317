import { createSlice } from "@reduxjs/toolkit";

export const selectedUserProfile = createSlice({
  name: "selectedUserProfile",
  initialState: {},
  reducers: {
    setSelectedUserProfileAction: (state, action) => {
      return action.payload;
    },
    remSelectedUserProfileAction: () => {
      return {};
    },
  },
});

export const { setSelectedUserProfileAction, remSelectedUserProfileAction } =
  selectedUserProfile.actions;

export default selectedUserProfile.reducer;
