import { createSlice } from "@reduxjs/toolkit";

export const verifyFieldsEdit = createSlice({
  name: "verifyFormEdit",
  initialState: { isFieldsEdit: false },
  reducers: {
    setVerifyFieldsEdit: (state, action) => {
      return { ...state, isFieldsEdit: action.payload };
    },
  },
});

export const { setVerifyFieldsEdit } = verifyFieldsEdit.actions;

export default verifyFieldsEdit.reducer;
