import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { FinancePayouts } from "pages/FinancePayout/constants";

const FundAccountIdsView = ({ value, onChange, fundAccounts }) => {
  const formattedFundAccounts =
    fundAccounts &&
    Object.keys(fundAccounts).map((key) => ({
      [FinancePayouts.FUND_ACC_CONSTANTS[key]]: fundAccounts[key],
    }));
  return (
    <RadioGroup
      sx={
        {
          // display: "flex",
          // flexDirection: "row",
          // justifyContent: "flex-end",
          // borderTop: fundAccounts && `1px solid ${theme.palette.grey["400"]}`,
        }
      }
      value={value}
      onChange={onChange}
    >
      {formattedFundAccounts?.map((fundAccount) => {
        if (fundAccount) {
          const value = Object.values(fundAccount)?.[0];
          const label = `${Object.keys(fundAccount)?.[0]} - ${value}`;
          return (
            <FormControlLabel
              value={value}
              control={<Radio size="small" style={{ padding: 0, margin: 4 }} />}
              label={<Typography fontSize={14}>{label}</Typography>}
            />
          );
        }
        return null;
      })}
    </RadioGroup>
  );
};

FundAccountIdsView.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  fundAccounts: PropTypes.array,
};

export default FundAccountIdsView;
