import { IconButton } from "@mui/material";
import ContentCopy from "@mui/icons-material/ContentCopy";

import { useToaster } from "hooks";
import { success } from "constants";

const Clipboard = ({ text, message, style }) => {
  const triggerToaster = useToaster();

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    triggerToaster(message, success);
  };

  return (
    <IconButton
      aria-label="copy-icon"
      sx={{ borderRadius: 1 }}
      style={style}
      onClick={handleCopy}
    >
      <ContentCopy />
    </IconButton>
  );
};

export default Clipboard;
