const plantSupplyTabs = {
  BULK_SHIPMENT: "Bulk shipment",
  PLANT_SUPPLY_TAB: "Plant Supply",
  DO_SHORTCLOSE: "DO short closure approval",
};
const SO_DETAIL_COLUMN_HEADER = "SO details";
const SO_TYPE = ["PRODUCTION_SO", "DISPATCH_SO"];
const PLANT_SUPPLY_HEADER = [
  "Buyer’s Order ID",
  "Customer Name",
  "SKU Name",
  "Quantity Ordered",
  "Quantity Shipped",
  "Price/Unit",
  SO_DETAIL_COLUMN_HEADER,
];
const DO_SHORTCLOSE_HEADER = [
  "DO ID",
  "Sales order ID",
  "SKU name",
  "Released SKU quantity",
  "Dispatched quantity",
  "Residual quantity",
  "Residual value",
  "Released value",
  "Remark",
  "Action",
];
const DO_STATUS = {
  approved: "Approved",
  rejected: "Rejected",
  requested: "Requested",
};

const filterQueries = {
  buyerOrderId: "Buyer's order ID",
  soDetails: "SO details",
};

export {
  plantSupplyTabs,
  SO_DETAIL_COLUMN_HEADER,
  DO_SHORTCLOSE_HEADER,
  PLANT_SUPPLY_HEADER,
  SO_TYPE,
  filterQueries,
  DO_STATUS,
};
