import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { ButtonV1, ExportCta, ModalBox } from "components";
import PayoutPrompt from "../components/PayoutPrompt";
import ActionCta from "../components/ActionCta";
import RefundTable from "./RefundTable";
import MarkSuccess from "./MarkSuccess";
import UploadRedirectCTA from "../components/UploadRedirectCTA";
import { useFetchData, useToaster } from "hooks";
import { truncateTx } from "dataStore/approvedCheckedTx";

import { getCall_v2, postCall_v2 } from "services";
import { FinancePayouts } from "pages/FinancePayout/constants";
import { success, error } from "constants";
import { createPayoutCSV } from "../makeData";
import { downloadCsv } from "utils";
import { getValidPayoutId } from "pages/FinancePayout/makeData";

const {
  APPROVAL_ACTION_TYPE,
  PAYMENT_IN_PROGRESS,
  PAYMENT_IN_PROCESS,
  MARK_SUCCESS_ERROR_MSG,
  STATUS_DATA,
} = FinancePayouts;

const rowsPerPage = 50;

const RefundCashback = ({ quickFilter, filters }) => {
  const [page, setPage] = useState(0);
  const [actionType, setActionType] = useState();
  const [disbursementData, setDisbursementData] = useState();
  const [checkBox, setCheckBox] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [markSuccessData, setSuccessData] = useState();
  const [formattedTransaction, setFormattedTransaction] = useState();

  const checkedTx = useSelector((state) => state.approvedCheckedTx);

  const refundRef = useRef(null);
  const dispatch = useDispatch();

  const [selectedTx, setSelectedTx] = useState([]);

  const triggerToaster = useToaster();

  const fetchApiParams = useMemo(
    () => ({
      limit: rowsPerPage,
      transactionType: "RC",
      filterType: quickFilter,
      ...filters,
    }),
    [quickFilter, filters],
  );

  const formatApprovedTransactions = (transactions) => {
    let formattedTransactions = [];
    transactions?.data?.payoutSummaries?.map((item) => {
      formattedTransactions.push({
        ...item,
        payoutId: `${item?.payoutId}:${item?.payoutType}`,
      });
    });
    setFormattedTransaction({
      limit: transactions?.data?.limit,
      payoutSummaries: formattedTransactions,
      offset: transactions?.data?.offset,
      totalCount: transactions?.data?.totalCount,
    });
  };

  const { isFetching, refetch: refetchApprovedList } = useFetchData(
    "fetch-approval-refund-and-cashback",
    "/oms/payout/approved-payouts",
    formatApprovedTransactions,
    null,
    { ...fetchApiParams, offset: page * rowsPerPage },
  );

  /**
   * @description Handle pagination
   */
  const handlePageChange = useCallback(
    (_event, pageNumber) => setPage(pageNumber),
    [],
  );

  const makeDisbursement = (paymentAction = null) => {
    const disbursementData = {};
    if (paymentAction === PAYMENT_IN_PROGRESS) {
      checkedTx.forEach((ele) => {
        if (checkBox.includes(ele?.payoutId)) {
          disbursementData[ele?.payoutId] = {
            payoutId: ele?.payoutId,
            orderType: ele?.orderType,
            orderId: ele?.customerOrdNumber,
            payoutEntity: ele?.entityName,
            documentAmount: ele?.shipmentInvoiceDetails.totalInvoicedAmount,
            payoutAmount: ele?.amount,
            documentId:
              ele?.shipmentInvoiceDetails.invoiceDetails[0].invoiceNumber,
            shipmentId: ele?.shipmentId,
            fundAccountDetails: ele?.fundAccountDetails,
            payoutType: ele?.payoutType,
            selectedFundAccountId:
              ele?.selectedFundAccountId ||
              ele?.defaultFundAccountId ||
              ele?.fundAccountDetails?.sellerAccount,
          };
        }
      });
    } else {
      formattedTransaction.payoutSummaries.forEach((ele) => {
        if (checkBox.includes(ele?.payoutId)) {
          disbursementData[ele?.payoutId] = {
            payoutId: ele?.payoutId,
            orderType: ele?.orderType,
            orderId: ele?.customerOrdNumber,
            payoutEntity: ele?.entityName,
            documentAmount: ele?.shipmentInvoiceDetails.totalInvoicedAmount,
            payoutAmount: ele?.amount,
            documentId:
              ele?.shipmentInvoiceDetails.invoiceDetails[0].invoiceNumber,
            shipmentId: ele?.shipmentId,
            fundAccountDetails: ele?.fundAccountDetails,
            payoutType: ele?.payoutType,
            selectedFundAccountId:
              ele?.selectedFundAccountId ||
              ele?.defaultFundAccountId ||
              ele?.fundAccountDetails?.sellerAccount,
          };
        }
      });
    }

    setDisbursementData(disbursementData);
  };

  const handlePayout = (action) => {
    makeDisbursement(action);
    setActionType(action);
  };

  const handleCloseDisbursement = useCallback(() => {
    setActionType(null);
  }, []);

  const handleCloseAndReload = useCallback(() => {
    setActionType(null);
    window.location.reload();
  }, []);

  const onMarkSuccessful = () => {
    let dataArray = [];
    let isError = false;
    formattedTransaction.payoutSummaries.forEach((ele) => {
      if (checkBox.includes(`${ele?.payoutId}`)) {
        if (ele?.status === PAYMENT_IN_PROGRESS) {
          isError = true;
          triggerToaster(MARK_SUCCESS_ERROR_MSG, error);
          return;
        } else {
          dataArray.push({
            id: ele.payoutId,
            payoutType: ele.payoutType,
            amount: ele?.amount,
            orderNumber: ele?.customerOrdNumber,
            entityName: ele?.entityName,
            orderType: ele?.orderType,
          });
        }
      }
    });
    if (!isError) {
      setSuccessModalOpen(true);
      setSuccessData(dataArray);
    }
  };

  const handleMarkSuccessful = async () => {
    const txData = refundRef.current.fetchUTR();
    for (let i = 0; i < markSuccessData.length; i++) {
      let utr = txData?.[`utr_${markSuccessData[i].id}`];
      if (!utr) {
        triggerToaster(`Please add UTR to mark transaction successfull`, error);
        return;
      }
      markSuccessData[i].utr = utr;
      markSuccessData[i].id = getValidPayoutId(markSuccessData[i].id);
    }

    const payload = {
      payoutRequestDTOList: markSuccessData,
    };
    try {
      await postCall_v2("/oms/payout/status-change-for-refund", payload);
      triggerToaster("Successfully marked transaction", success);
    } catch (err) {
      triggerToaster("Unable to initiate the process.Try again", error);
      console.warn("handleMarkSuccessful:- ", err);
    } finally {
      onCloseSuccessModal();
      refetchApprovedList();
      setCheckBox([]);
    }
  };

  const onCloseSuccessModal = () => {
    setSuccessModalOpen(null);
    setSuccessData(null);
  };

  const onExportData = async () => {
    try {
      const payoutUrl = `/oms/payout/download/approved-payouts`;
      const { data } = await getCall_v2(payoutUrl, {
        ...fetchApiParams,
        limit: 5000,
        offset: 0,
      });
      triggerToaster(`Downloading file`, success);
      downloadCsv(data, "approved_refund_and_cashback");
    } catch (err) {
      console.error(`Error in download, ${err}`);
      triggerToaster(`Please try again later`, error);
    }
  };

  const handleInitiatePayout = async (tx) => {
    setLoading(true);
    const payload = {
      payoutRequestDTOList: tx,
      nextState: {
        state: actionType,
      },
    };
    try {
      if (actionType === APPROVAL_ACTION_TYPE.payout) {
        //temporary fix
        const temp = [];
        tx.forEach((ele) => {
          temp.push(ele.id);
        });
        setSelectedTx(temp);

        await postCall_v2("/oms/payout/initiate-payouts", payload);
      } else {
        await postCall_v2("/oms/payout/approve-payouts", payload);
      }

      handleCloseDisbursement();
      setDisbursementData(null);
      setCheckBox([]);
      setTimeout(() => {
        refetchApprovedList();
      }, 1000);
      setLoading(false);
    } catch (err) {
      triggerToaster("Unable to initate the process. Try again", error);
      setLoading(false);

      setSelectedTx([]);
    }
  };

  useEffect(() => {
    if (page) {
      setPage(0);
    } else {
      refetchApprovedList();
    }
  }, [fetchApiParams]);

  useEffect(() => {
    refetchApprovedList();
    refundRef.current.unSelectCheckAllBox();
  }, [page]);

  /**
   * @description Download checked transactions
   * produce csv
   */
  const downloadApprovedTx = async (tx, isRefund = false) => {
    try {
      const payload = checkedTx?.map((ele) => {
        const { payoutId, payoutType } = ele;

        return {
          payoutId: payoutId?.split(":")[0],
          payoutType,
        };
      });
      const toBeDownloadedTx = await postCall_v2(
        "/oms/payout/download-payout",
        payload,
      );
      const { data } = toBeDownloadedTx;
      if (!data || !data.length) {
        triggerToaster("No transactions to be downloaded", error);
      } else {
        await createPayoutCSV(data);
        triggerToaster(
          "Selected transactions downloaded successfully",
          success,
        );
      }
    } catch (ex) {
      console.error(`Exception occurred : ${ex}`);
    } finally {
      handleCloseDisbursement(); //hides modal
      setDisbursementData(null);
      setCheckBox([]);
      setSelectedTx([]);
      dispatch(truncateTx());
    }
  };

  /**
   * @description Download tx based on tx status
   */
  const downloadTx = async () => {
    try {
      const {
        status = "",
        maxTimestamp = "",
        minTimestamp = "",
        transactionType = "",
        payoutType = "",
        orderType = "",
        orderNumber = "",
      } = fetchApiParams;
      const resultSheet = await getCall_v2(
        `/oms/payout/download-processed-payout?payoutStatus=${status}&transactionType=${transactionType}&maxTimestamp=${maxTimestamp}&minTimestamp=${minTimestamp}&payoutType=${payoutType}&orderNumber=${
          orderNumber ?? ""
        }&orderType=${orderType ?? ""}`,
      );
      const { data } = resultSheet;
      if (!data || !data.length) {
        triggerToaster("No tx to be downloaded", error);
        return;
      } else {
        await createPayoutCSV(data);
        triggerToaster("Transactions downloaded successfully", success);
        return;
      }
    } catch (ex) {
      console.log(`Exception occurred ${ex}`);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 2,
          marginTop: "-2.8%",
          alignItems: "baseline",
          gap: 4,
        }}
      >
        <ExportCta onExport={onExportData} />
        <UploadRedirectCTA />
        <ButtonV1
          variant="outlined"
          color="secondary"
          title="Download filtered transactions"
          size="medium"
          onClick={downloadTx}
        />
        <ActionCta
          isEnabled={checkBox.length}
          onInitiatePayout={handlePayout}
          onMarkSuccessful={onMarkSuccessful}
        />
      </Box>
      <RefundTable
        approvedTransactions={formattedTransaction}
        rowsPerPage={rowsPerPage}
        pageNumber={page}
        loading={isFetching}
        onRefetch={refetchApprovedList}
        onPageChange={handlePageChange}
        setCheckBox={setCheckBox}
        checkBox={checkBox}
        selectedTx={selectedTx}
        ref={refundRef}
      />
      {actionType && (
        <PayoutPrompt
          actionType={actionType}
          onClosePayoutDisbursement={handleCloseAndReload}
          disbursementData={disbursementData}
          onInitiatePayout={handleInitiatePayout}
          onDownloadClick={downloadApprovedTx}
          loading={loading}
          isFundIdsViewHidden={true}
          isRefund={true}
        />
      )}
      {
        <ModalBox
          open={!!isSuccessModalOpen}
          onCloseModal={onCloseSuccessModal}
          width="70%"
        >
          <>
            <Box padding={5} textAlign="center">
              <MarkSuccess data={markSuccessData} ref={refundRef} />
              <ButtonV1
                variant="contained"
                color="secondary"
                title="Mark success"
                size="medium"
                loading={loading}
                onClick={handleMarkSuccessful}
              />
            </Box>
          </>
        </ModalBox>
      }
    </>
  );
};

export default RefundCashback;
