import { Box } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";

import { RefundAndCashbackPayout } from "./constants";
import RefundCashbackForm from "./RefundCashbackForm";
import ButtonView from "../components/ButtonView";

const { REFUND_FORM } = RefundAndCashbackPayout;

const UpdateTransaction = ({ updateTransaction, transaction, onReset }) => {
  const methods = useForm({
    mode: "onTouched",
  });

  const {
    reset,
    handleSubmit,
    getValues,
    formState: { isDirty },
  } = methods;

  /**
   * @description Update pending transaction
   */
  const handleUpdate = () => {
    const {
      tcsTdsDeductionReq,
      documentDate,
      payoutAmount: invoiceAmount,
      id,
    } = transaction;
    const formData = getValues(REFUND_FORM);
    const body = formData.map((data) => {
      return {
        ...data,
        id,
        tcsTdsDeductionReq,
        documentDate,
        invoiceAmount,
      };
    });
    updateTransaction(body);
  };

  return (
    <>
      <FormProvider {...methods}>
        <Box as="form" onSubmit={handleSubmit(handleUpdate)}>
          {[transaction].map((txItem, index) => (
            <RefundCashbackForm key={index} id={index} transaction={txItem} />
          ))}

          <ButtonView
            disabled={!isDirty}
            onClearAll={() => {
              onReset();
              reset();
            }}
            title={"Update"}
          />
        </Box>
      </FormProvider>
    </>
  );
};

export default UpdateTransaction;
