import { useEffect } from "react";
import { DeleteOutlined } from "@mui/icons-material";

import {
  Typography,
  Box,
  Grid,
  TextField,
  Checkbox,
  IconButton,
  useTheme,
  Divider,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import { DatePickerV1, DropdownV1 } from "components";
import { ErrorMsg } from "CustomStyledComponents";

import Comment from "../components/Comment";
import { CreditNotesSellerPayout } from "./constants";
import InvoiceIds from "../components/InvoiceIds";
import { getError } from "./makeData";

const { transactionTypes, creditNotesManualInput, creditYup } =
  CreditNotesSellerPayout;

const CustomCheckBox = ({ name, defaultValue, label, control }) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingTop: 5,
        marginLeft: 0,
      }}
    >
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { value, onChange } }) => (
          <Checkbox
            value={value}
            checked={!!value}
            onChange={onChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        )}
      />
      <Typography style={{ fontSize: 12, marginLeft: 8 }}>{label}</Typography>
    </Box>
  );
};

const CreditNotesManualInputForm = ({
  transaction,
  toConfirmSingleTxRemoval,
  unique,
  txErrors,
  isReviewTransaction,
  clearInvoiceIds,
}) => {
  const methods = useFormContext();
  const theme = useTheme();
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = methods;

  const {
    transactionType,
    originalInvoiceId,
    documentId,
    documentDate,
    invoiceComments,
    documentAmount,
    cgstAmount,
    sgstAmount,
    igstAmount,
    reverseTcsTds,
    payoutRequired,
    fromAccount,
    toAccount,
  } = creditNotesManualInput;

  const getFieldValue = (name) => {
    const value = watch(`${creditYup}.${unique}.${name}`);
    if (name === fromAccount || name === toAccount) {
      return value;
    }
    return !isNaN(value) ? value : 0;
  };

  useEffect(() => {
    setValue(
      `${creditYup}.${unique}.${creditNotesManualInput.gstAmount}`,
      gstAmount(),
    );
  }, [
    getFieldValue(sgstAmount),
    getFieldValue(cgstAmount),
    getFieldValue(igstAmount),
  ]);
  /**
   * @description If the first 2 digits of the GSTINs match, then it is  (CGST+SGST), else IGST
   * @returns boolean, true or false
   */
  const verifyBuyerSellerState = () => {
    const isSameState =
      (getFieldValue(fromAccount) || transaction?.fromAccount)
        ?.substring(0, 2)
        ?.toLowerCase() ===
      (getFieldValue(toAccount) || transaction?.toAccount)
        ?.substring(0, 2)
        ?.toLowerCase();
    return isSameState;
  };

  /**
   * @description Get gst amounts on the baseis of buyer seller state
   * @returns gst amount
   */
  const gstAmount = () =>
    verifyBuyerSellerState()
      ? +getFieldValue(sgstAmount) + +getFieldValue(cgstAmount)
      : +getFieldValue(igstAmount);

  const baseAmountValue = getFieldValue(documentAmount) - +gstAmount();
  const defaultBaseAmount =
    +transaction?.documentAmount - +transaction?.gstAmount;

  return (
    <>
      <Grid
        container
        gap={2}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid item xs={2.25}>
          <Controller
            control={control}
            name={`${creditYup}.${unique}.${transactionType}`}
            defaultValue={transaction?.transactionType ?? ""}
            render={({ field: { value, onChange } }) => (
              <DropdownV1
                onChange={onChange}
                itemList={transactionTypes}
                placeholder="Transaction type"
                listType="constants"
                value={value}
              />
            )}
          />
          <ErrorMsg sx={{ position: "absolute", marginTop: 0 }}>
            {getError(errors, unique, transactionType, txErrors)}
          </ErrorMsg>
        </Grid>
        <Grid item xs={2.25}>
          <Controller
            control={control}
            name={`${creditYup}.${unique}.${fromAccount}`}
            defaultValue={transaction?.fromAccount ?? ""}
            render={({ field: { value, onChange } }) => (
              <TextField
                label="From account"
                size="small"
                onChange={(e) => {
                  onChange(e);
                  verifyBuyerSellerState();
                }}
                value={value}
              />
            )}
          />
          <ErrorMsg sx={{ position: "absolute", marginTop: 0 }}>
            {getError(errors, unique, fromAccount, txErrors)}
          </ErrorMsg>
        </Grid>
        <Grid item xs={2.25}>
          <Controller
            control={control}
            defaultValue={transaction?.toAccount ?? ""}
            name={`${creditYup}.${unique}.${toAccount}`}
            render={({ field: { value, onChange } }) => (
              <TextField
                label="To account"
                size="small"
                onChange={(e) => {
                  onChange(e);
                  verifyBuyerSellerState();
                }}
                value={value}
              />
            )}
          />
          <ErrorMsg sx={{ position: "absolute", marginTop: 0 }}>
            {getError(errors, unique, toAccount, txErrors)}
          </ErrorMsg>
        </Grid>
        <Grid item xs={2.25}>
          <InvoiceIds
            defaultValue={transaction?.originalInvoiceId}
            name={`${creditYup}.${unique}.${originalInvoiceId}`}
            methods={methods}
            isCredit={true}
            clearInvoiceIds={clearInvoiceIds}
          />
          <ErrorMsg sx={{ position: "absolute", marginTop: 0 }}>
            {getError(errors, unique, originalInvoiceId)}
          </ErrorMsg>
        </Grid>
      </Grid>
      {/* Seocond row grid */}
      <Grid
        container
        gap={2}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 16,
        }}
      >
        <Grid item xs={2.25}>
          <Controller
            control={control}
            defaultValue={transaction?.documentId ?? ""}
            name={`${creditYup}.${unique}.${documentId}`}
            render={({ field: { value, onChange } }) => (
              <TextField
                label="Document ID"
                size="small"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <ErrorMsg sx={{ position: "absolute", marginTop: 0 }}>
            {getError(errors, unique, documentId)}
          </ErrorMsg>
        </Grid>
        <Grid item xs={2.25}>
          <Controller
            control={control}
            defaultValue={transaction?.documentDate ?? ""}
            name={`${creditYup}.${unique}.${documentDate}`}
            render={({ field: { value, onChange } }) => (
              <DatePickerV1
                onChange={onChange}
                value={value}
                label={"Document date"}
              />
            )}
          />
        </Grid>
        <Grid item xs={2.25}>
          <Comment
            name={`${creditYup}.${unique}.${invoiceComments}`}
            invoiceComments={transaction?.invoiceComments}
          />
        </Grid>
        <Grid
          item
          xs={2.25}
          style={{ display: "flex", justifyContent: "flex-start" }}
        />
      </Grid>
      <Divider
        flexItem
        sx={{
          borderColor: theme.palette.grey["400"],
          marginTop: 3,
        }}
      />

      {/* Third row grid */}
      <Grid
        container
        gap={2}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 12,
        }}
      >
        <Grid item xs={2.25}>
          <Controller
            control={control}
            name={`${creditYup}.${unique}.${documentAmount}`}
            defaultValue={transaction?.documentAmount ?? ""}
            render={({ field: { value, onChange } }) => (
              <TextField
                label="Document amount"
                size="small"
                type={"number"}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xs={2.25}>
          <TextField
            label="Base amount"
            disabled={true}
            size="small"
            type={"number"}
            value={baseAmountValue || defaultBaseAmount}
          />
        </Grid>
        <Grid item xs={2.25}>
          <Controller
            control={control}
            defaultValue={
              verifyBuyerSellerState() ? Number(transaction?.gstAmount) / 2 : ""
            }
            name={`${creditYup}.${unique}.${cgstAmount}`}
            render={({ field: { value, onChange } }) => (
              <TextField
                disabled={!verifyBuyerSellerState()}
                type={"number"}
                label="CGST amount"
                size="small"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xs={2.25}>
          <Controller
            control={control}
            defaultValue={
              verifyBuyerSellerState() ? Number(transaction?.gstAmount) / 2 : 0
            }
            name={`${creditYup}.${unique}.${sgstAmount}`}
            render={({ field: { value, onChange } }) => (
              <TextField
                disabled={!verifyBuyerSellerState()}
                type={"number"}
                label="SGST amount"
                size="small"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
      </Grid>
      {/* Fourth row grid */}
      <Grid
        container
        gap={2}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 16,
        }}
      >
        <Grid item xs={2.25}>
          <Controller
            control={control}
            defaultValue={
              !verifyBuyerSellerState() ? Number(transaction?.gstAmount) : ""
            }
            name={`${creditYup}.${unique}.${igstAmount}`}
            render={({ field: { value, onChange } }) => (
              <TextField
                disabled={verifyBuyerSellerState()}
                label="IGST amount"
                size="small"
                type={"number"}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xs={2.25}>
          <CustomCheckBox
            control={control}
            name={`${creditYup}.${unique}.${reverseTcsTds}`}
            label={"Reverse TCS/TDS deductions?"}
            defaultValue={transaction?.reverseTcsTds}
          />
        </Grid>
        <Grid item xs={2.25}>
          <CustomCheckBox
            control={control}
            name={`${creditYup}.${unique}.${payoutRequired}`}
            label={"Payout required?"}
            defaultValue={transaction?.payoutRequired}
          />
        </Grid>
        <Grid item xs={2.25}>
          {isReviewTransaction && (
            <IconButton
              aria-label="comment-icon"
              disableRipple
              onClick={() => toConfirmSingleTxRemoval(unique)}
            >
              <DeleteOutlined />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CreditNotesManualInputForm;
