import { createSlice } from "@reduxjs/toolkit";

export const showSnackBar = createSlice({
  name: "showSnackBar",
  initialState: { value: false, message: "", variant: "" },
  reducers: {
    displayToasterAction: (state, action) => {
      return { ...state, ...action.payload };
    },
    hideToasterAction: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { displayToasterAction, hideToasterAction } = showSnackBar.actions;

export default showSnackBar.reducer;
