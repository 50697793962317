export const DISPATCH_ORDER = Object.freeze({
  orderPaymentTypeKey: {
    cashNcarryAdvance: "Cash & Carry - Advance",
    cashNcarryPart: "Cash & Carry - Part",
    credit: "Credit",
  },
  orderStatusKey: {
    cancelled: "Cancelled",
    delivered: "Delivered",
  },
  status: {
    release: "release",
    cancel: "cancel",
    rejected: "rejected",
  },
  uomBag: "NUMBER_OF_BAGS",
  uomBagLabel: "bags",
  paymentRequest: [
    "SKU name",
    "Total order quantity",
    "Planning quantity",
    "Final value",
  ],
  dispatchTableHeader: [
    "",
    "Order SKU",
    "Ordered quantity",
    "Planning quantity",
    "Planned quantity",
    "Released quantity",
    "Short closed quantity",
    "Rate/MT",
    "Final value",
  ],
  dispatchRecordHeader: [
    "DO ID",
    "Total DO value",
    "Total DO quantity",
    "Dispatched quantity",
    "Pending quantity",
    "Transaction status",
    "Action",
  ],
  dispatchOrderLineItemHeader: [
    "Shipment ID",
    "Released quantity",
    "Dispatched quantity",
    "Pending quantity",
    "SKU value",
  ],
  dipatchOrderDetailsHeader: [
    "Order SKU",
    "SKU quantity",
    "SKU dispatched quantity",
    "SKU pending quantity",
    "SKU value",
    "Action",
  ],
  yupConfig: {
    plannedQty: "plannedQty",
    checkbox: "checkbox",
    estimatedDispatchDate: "estimatedDispatchDate",
    requestPayment: "requestPayment",
  },
  toasterMessage: {
    invalidQty: {
      message:
        "Planning quantity should be less than (total quantity - planned quantity - released quantity).",
      variant: "error",
    },
  },
  paymentTypeList: [
    { value: "ADVANCE", label: "ADVANCE" },
    { value: "DO", label: "DO" },
    { value: "PART", label: "PART" },
    { value: "FINAL", label: "FINAL" },
  ],
  plannedShipmentStatus: {
    Paid: "Paid",
    Planned: "Planned",
    Partial: "Partial",
    Release: "Release",
    Revoke: "Revoked",
    new: "New",
    Rejected: "Rejected",
    Created: "Created",
  },
});
