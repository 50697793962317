import { useState, useCallback, useEffect, useRef, useMemo } from "react";
import { Box, Typography, Divider, useTheme } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";

import { ButtonV1, ExportCta, TooltipLabel, ModalBox } from "components";
import { QuickFilter } from "../components";
import PayoutTable from "./PayoutTable";
import RefundModalTable from "./RefundModalTable";

import { getValidPayoutId } from "pages/FinancePayout/makeData";
import { getCall_v2, postCall_v2 } from "services";
import { useFetchData, useToaster } from "hooks";
import { downloadCsv } from "utils";

import { error, success } from "constants";
import { FinancePayouts } from "../../constants";

const initialQuickFilter = "all";
const {
  quick_filter,
  transactionFilterValue,
  msgStatements: { ERROR_MSG },
} = FinancePayouts;
const rowsPerPage = 50;
let endpoint = `oms/payout/seller-payout-summaries`;

const RefundAndCashback = ({ transactionType, filters }) => {
  const [selectedQuickFilter, setSelectedQuickFilter] =
    useState(initialQuickFilter);
  const [page, setPage] = useState(0);
  const [filterCount, setFilterCount] = useState({});
  const [selectedPayout, setSelectedPayout] = useState([]);
  const [isPaymentChecked, setPaymentChecked] = useState([]);
  const [formattedTransaction, setFormattedTransaction] = useState();
  const [isModalOpen, setModalOpen] = useState(false);

  const triggerToaster = useToaster();
  const payoutTableRef = useRef(null);
  const theme = useTheme();

  const pendingRefundParam = useMemo(
    () => ({
      ...filters,
      filterType: selectedQuickFilter,

      limit: rowsPerPage,
      transactionType: transactionFilterValue[transactionType],
    }),
    [filters, selectedQuickFilter, transactionType],
  );

  const addTotalCount = (res) => {
    let filterCountRef = { ...filterCount };
    filterCountRef[selectedQuickFilter] = res?.data?.totalCount;
    setFilterCount(filterCountRef);
  };

  const formatTransactionData = (res) => {
    addTotalCount(res);
    let formattedTransactions = [];
    res?.data?.payoutSummaries?.map((item) => {
      formattedTransactions.push({
        ...item,
        payoutId: `${item?.payoutId}:${item?.payoutType}`,
      });
    });
    setFormattedTransaction({
      limit: res?.data?.limit,
      payoutSummaries: formattedTransactions,
      offset: res?.data?.offset,
      totalCount: res?.data?.totalCount,
    });
  };

  const onApproveClick = () => {
    setModalOpen(true);
  };

  const { refetch: getPendingPayouts, isFetching } = useFetchData(
    ["pending-payouts", pendingRefundParam],
    endpoint,
    formatTransactionData,
    null,
    { ...pendingRefundParam, offset: page * rowsPerPage },
  );

  /**
   * @description set filters on click of quick filters
   */
  const handleQuickFilter = useCallback((value) => {
    setSelectedQuickFilter(value);
  }, []);

  /**
   * @description fetch pending payouts during page load
   */
  useEffect(() => {
    if (page) {
      setPage(0);
    } else {
      getPendingPayouts();
    }
  }, [pendingRefundParam]);

  useEffect(() => {
    getPendingPayouts();
  }, [page]);

  /**
   * @description Handle pagination
   */
  const handlePageChange = useCallback((_, pageNumber) => {
    setPage(pageNumber);
    resetSelectItems();
  }, []);

  const getSelectedSummaryData = (data) => {
    setSelectedPayout(data);
  };

  const onInitiatePayout = async (isApproved) => {
    if (selectedPayout.length) {
      let selectedData = selectedPayout.map((ele, i) => {
        return {
          isPayoutRequired: isPaymentChecked[i] === false ? false : true,
          id: getValidPayoutId(ele.payoutId),
          payoutType: ele.payoutType,
        };
      });

      try {
        const res = await postCall_v2("oms/payout/approve-payouts", {
          payoutRequestDTOList: selectedData,
          nextState: {
            state: isApproved ? "PAYMENT_APPROVED" : "PAYMENT_ON_HOLD",
          },
        });
        if (res.status === 200) {
          let failedTransaction = 0;
          let successfulTransactions = 0;
          res?.data?.map((ele) => {
            if (ele?.isError) {
              failedTransaction += 1;
            } else {
              successfulTransactions += 1;
            }
          });
          if (failedTransaction) {
            const errorMsg =
              failedTransaction === 1 && res?.data?.length === 1
                ? res?.data[0]?.errorMessage
                : `Out of ${res?.data?.length} transactions, ${successfulTransactions} approved & ${failedTransaction} got failed`;
            triggerToaster(errorMsg, error);
          } else {
            triggerToaster(
              isApproved
                ? "Transaction successfully approved"
                : "Transaction moved to On hold",
              success,
            );
          }
          getPendingPayouts();
          resetSelectItems();
          setSelectedPayout([]);
          isApproved && setModalOpen(false);
        } else {
          triggerToaster(ERROR_MSG, error);
        }
      } catch (err) {
        const errMsg = err?.request?.response
          ? JSON.parse(err?.request?.response)?.detail
          : ERROR_MSG;
        triggerToaster(errMsg, error);
      } finally {
        setPaymentChecked([]);
      }
    }
  };

  /**
   * @description reset selected items and parent checkbox
   */
  const resetSelectItems = useCallback(() => {
    payoutTableRef?.current?.resetParentCheckBox();
    setSelectedPayout([]);
  }, []);

  const onExportCSV = async () => {
    try {
      const payoutUrl = `/oms/payout/download/seller-payout-summaries`;
      const { data } = await getCall_v2(payoutUrl, {
        ...pendingRefundParam,
        limit: null,
      });
      triggerToaster(`Downloading file`, success);
      downloadCsv(data, "refund_cashback_payouts.csv");
    } catch (err) {
      console.error(`Error in download, ${err}`);
      triggerToaster(`Please try again later`, error);
    }
  };

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={4}
      >
        <Box>
          <Typography fontSize={20} fontWeight={600} mb={2}>
            Pending payouts
          </Typography>
          <Box display={"flex"}>
            {Object.keys(quick_filter).map((elem, ind) => (
              <QuickFilter
                key={ind}
                label={quick_filter[elem]}
                value={elem}
                onClick={handleQuickFilter}
                selectedFilter={selectedQuickFilter}
                filterCount={filterCount}
              />
            ))}
          </Box>
        </Box>
        <Box sx={{ flexDirection: "row", display: "flex" }}>
          <Box
            mr={3}
            sx={{ display: "flex", alignItems: "center", marginBottom: -4 }}
          >
            <ExportCta onExport={onExportCSV} />
          </Box>
          <TooltipLabel
            customStyle={{
              "& .MuiTooltip-tooltip": {
                border: `1px solid ${theme.palette.grey["400"]}`,
              },
              "& .MuiTooltip-arrow": {
                "&::before": {
                  backgroundColor: "white",
                  border: `1px solid ${theme.palette.grey["400"]}`,
                },
              },
            }}
            title={
              <>
                <ButtonV1
                  title={"Approve"}
                  size={"small"}
                  variant="text"
                  onClick={onApproveClick}
                />
                <Divider />
                <ButtonV1
                  title={"Hold"}
                  size={"small"}
                  variant="text"
                  onClick={() => onInitiatePayout()}
                />
              </>
            }
            labelChildren={
              <Box
                style={{
                  padding: 8,
                  backgroundColor: !selectedPayout?.length
                    ? theme.palette.grey["400"]
                    : theme.palette.primary.main,
                  color: !selectedPayout?.length
                    ? theme.palette.text.disabled
                    : theme.palette.common.white,
                  borderRadius: 4,
                  display: "flex",
                  pointerEvents: !selectedPayout?.length && "none",
                }}
              >
                <Typography fontWeight={"600"}>Action</Typography>
                <KeyboardArrowDown fontSize="small" />
              </Box>
            }
          />
        </Box>
      </Box>
      <PayoutTable
        ref={payoutTableRef}
        summary={formattedTransaction}
        rowsPerPage={rowsPerPage}
        pageNumber={page}
        loading={isFetching}
        onPageChange={handlePageChange}
        passSelectedData={getSelectedSummaryData}
        getPendingPayouts={getPendingPayouts}
        selectedPayout={selectedPayout}
      />
      <ModalBox open={isModalOpen} onCloseModal={() => setModalOpen(false)}>
        <Box marginY={6} justifyContent={"center"} display={"flex"}>
          <Typography textAlign={"center"} fontWeight={600}>
            Some of the transactions do not have their account number verified.
            <br />
            Do you still want to Proceed?
          </Typography>
        </Box>
        <Box marginBottom={4}>
          <RefundModalTable
            data={formattedTransaction}
            selectedData={selectedPayout}
          />
        </Box>
        <Box display={"flex"} justifyContent={"center"} gap={4}>
          <ButtonV1
            title={"Confirm"}
            size={"small"}
            onClick={() => onInitiatePayout(true)}
          />
          <ButtonV1
            title={"Close"}
            size={"small"}
            variant="outlined"
            onClick={() => setModalOpen(false)}
          />
        </Box>
      </ModalBox>
    </>
  );
};

export default RefundAndCashback;
