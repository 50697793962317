import * as React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import ButtonV1 from "components/ButtonV1";

const defaultTitle = "Confirmation message";
const defaultMessage =
  "There are unsaved changes on this page. Would you like to exit without saving?";

export default function DialogBox({
  title = defaultTitle,
  message = defaultMessage,
  cancelButtonLabel = "No",
  okButtonLabel = "Yes",
  handleClose,
  open,
  handleConfirm,
  msgStyle,
  buttonWrapperStyle,
  okButtonVariant = "text",
  cancelButtonVariant = "text",
  isCloseIcon = false,
}) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        sx={{ cursor: "move", paddingRight: 20 }}
        id="draggable-dialog-title"
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{ paddingRight: 20 }}>
        <DialogContentText sx={msgStyle}>{message}</DialogContentText>
      </DialogContent>
      {isCloseIcon && (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 20,
            color: (theme) => theme.palette.common.black,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogActions sx={buttonWrapperStyle}>
        <ButtonV1
          autoFocus
          onClick={handleClose}
          title={cancelButtonLabel}
          variant={cancelButtonVariant}
        />

        <ButtonV1
          onClick={handleConfirm}
          title={okButtonLabel}
          variant={okButtonVariant}
        />
      </DialogActions>
    </Dialog>
  );
}

DialogBox.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  okButtonLabel: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  msgStyle: PropTypes.object,
  buttonWrapperStyle: PropTypes.object,
  okButtonVariant: PropTypes.string,
  cancelButtonVariant: PropTypes.string,
  isCloseIcon: PropTypes.bool,
};
