/** 
 * Querys in Order-list page
 filterType
 filterValue - quickFilter
 companyName
 orderState
 opsSpoc
 buyerName
 orderType
**/

const updateQuery = (searchParams, updateObject) => {
  let queryObject = {};
  searchParams?.forEach((val, key) => {
    queryObject[key] = val;
  });
  Object.keys(updateObject)?.forEach((key) => {
    queryObject[key] =
      (Array.isArray(updateObject?.[key])
        ? updateObject?.[key]?.join(",")
        : updateObject?.[key]) || "";
  });
  return queryObject;
};

export { updateQuery };
