import {
  useCallback,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
  useState,
} from "react";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TablePagination,
  Typography,
  IconButton,
  Paper,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";

import {
  PaginationAction,
  Loader,
  SvgIcon,
  HyperLink,
  TooltipLabel,
  ButtonV1,
} from "components";
import { StatusChip } from "pages/FinancePayout/PendingPayoutsTab/components";
import { HoverBlock } from "pages/FinancePayout/components";
import {
  DocumentPriceBreakup,
  IconTooltip,
  PriceBreakup,
} from "pages/FinancePayout/components";
import { useDownloadInvoiceFile, useToaster } from "hooks";

import {
  addTx,
  removeSingleTx,
  removeTxList,
  truncateTx,
} from "dataStore/approvedCheckedTx";
import { dateConvertor, getSubstring, moneyFormat } from "utils";
import { FinancePayouts } from "pages/FinancePayout/constants";
import { error } from "constants";

const { INVOICE_APPROVED_HEADERS, PAYMENT_FAILED } = FinancePayouts;

const InvoiceTable = forwardRef(
  (
    {
      approvedTransactions = {},
      rowsPerPage,
      pageNumber,
      onPageChange,
      loading,
      setCheckBox,
      checkBox,
      selectedTx,
    },
    ref,
  ) => {
    const { triggerDownloadInvoice } = useDownloadInvoiceFile();
    const [isMarkAllCheckboxChecked, setIsMarkAllCheckboxChecked] =
      useState(false);
    const dispatch = useDispatch();
    const allTxCheckBoxRef = useRef(null);
    const triggerToaster = useToaster();

    const getFileName = (file) => {
      let fileID = file && Object?.keys(file)?.[0];
      return file?.[fileID]?.originalName;
    };

    const handleDocDownload = (files, item) => {
      let fileName = getFileName(files);
      let file = {
        generatedId: files && Object?.keys(files)?.[0],
        originalName: fileName,
      };
      triggerDownloadInvoice(
        file,
        "INVOICE",
        item?.shipmentId,
        item?.shipmentInvoiceDetails?.invoiceDetails?.[0]?.invoiceNumber,
      );
    };

    const handleCheckBox = useCallback((item) => {
      let ids = [item?.payoutId];
      setCheckBox((prev) => {
        let checkBox = [...prev];
        ids.forEach((id) => {
          if (checkBox.includes(id)) {
            checkBox = checkBox.filter((check) => check !== id);
            //remove single tx
            dispatch(removeSingleTx(id));
          } else {
            checkBox = [...checkBox, id];
            //store checked tx
            dispatch(addTx([item]));
          }
        });
        return checkBox ?? [];
      });
    }, []);

    const handleAllCheckBox = useCallback(
      (ev, tx) => {
        const filteredTx = tx ?? [];
        if (!filteredTx || !filteredTx.length) {
          triggerToaster(`No valid transaction available in this list`, error);
          return;
        }
        setIsMarkAllCheckboxChecked(ev.target.checked); // set state for UI visibility
        if (checkBox.length !== filteredTx.length) {
          const payoutIds = filteredTx?.map(({ payoutId }) => payoutId) ?? [];
          setCheckBox(payoutIds);
          //store tx
          dispatch(addTx(filteredTx));
        } else {
          setCheckBox([]);
          //remove tx list
          dispatch(removeTxList(tx));
        }
      },
      [setCheckBox, checkBox],
    );

    /**
     * @description mimic component will unmount
     */
    useEffect(() => {
      return () => {
        dispatch(truncateTx());
      };
    }, []);

    /**
     * expose checkbox reference
     */
    useImperativeHandle(ref, () => ({
      unSelectCheckAllBox: () => setIsMarkAllCheckboxChecked(false),
    }));

    return (
      <Paper>
        <TableContainer
          sx={{
            minWidth: 700,
            maxHeight: "calc(100vh - 120px)",
          }}
        >
          <Table stickyHeader>
            <TableHead style={{ borderRadius: "10px 10px 0 0" }}>
              <TableRow>
                <TableCell>
                  <Checkbox
                    onChange={(ev) => {
                      handleAllCheckBox(
                        ev,
                        approvedTransactions?.payoutSummaries,
                      );
                    }}
                    ref={allTxCheckBoxRef}
                    checked={isMarkAllCheckboxChecked}
                  />
                </TableCell>
                {INVOICE_APPROVED_HEADERS.map((heading, i) => (
                  <TableCell key={i}>{heading}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {approvedTransactions?.payoutSummaries?.length && !loading ? (
                approvedTransactions?.payoutSummaries?.map((item, i) => (
                  <TableRow key={item?.payoutId}>
                    <TableCell>
                      {!selectedTx.includes(item?.payoutId) && (
                        <Checkbox
                          key={item?.payoutId}
                          disabled={selectedTx.includes(item?.payoutId)}
                          checked={checkBox.includes(item?.payoutId)}
                          onChange={() => {
                            handleCheckBox(item);
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <HyperLink title={item?.customerOrdNumber} />
                    </TableCell>
                    <TableCell>{item?.entityName}</TableCell>
                    <TableCell>
                      {dateConvertor(item?.transactionCreatedDate)}
                    </TableCell>
                    <TableCell>
                      <Box display={"flex"} alignItems={"center"} gap={2}>
                        {
                          item?.shipmentInvoiceDetails?.invoiceDetails[0]
                            ?.invoiceNumber
                        }
                      </Box>
                      {item?.originalInvoiceId?.[0] ? (
                        <IconTooltip
                          label={item?.originalInvoiceId?.[0]}
                          icon={
                            <Box height={16}>
                              <SvgIcon name={"info"} width={16} height={16} />
                            </Box>
                          }
                        >
                          <>
                            <Typography
                              color={"text.secondary"}
                              sx={{ paddingBottom: 2 }}
                            >
                              Original invoices
                            </Typography>
                            {item?.originalInvoiceId?.map((id) => (
                              <Typography>{id}</Typography>
                            ))}
                          </>
                        </IconTooltip>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {item?.documentDate
                        ? dateConvertor(item?.documentDate)
                        : dateConvertor(
                            item?.shipmentInvoiceDetails?.invoiceDetails?.[0]
                              .invoiceDate,
                          )}

                      <br />
                      {dateConvertor(
                        item?.shipmentInvoiceDetails?.invoiceDetails?.[0]
                          .invoiceDueDate,
                      )}
                    </TableCell>
                    <TableCell>
                      <Box display={"flex"} alignItems={"center"} gap={1}>
                        {moneyFormat(
                          item?.shipmentInvoiceDetails?.totalInvoicedAmount,
                        )}
                        <DocumentPriceBreakup
                          data={item.shipmentInvoiceDetails}
                          addInfo={item.creditMetaInfo}
                          tcsTdsDeductionReq={item.tcsTdsDeductionReq}
                        />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box display={"flex"} gap={1}>
                        {moneyFormat(item?.amount)}
                        <PriceBreakup data={item} />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <ButtonV1
                          boxStyle={{ justifyContent: "flex-start" }}
                          variant="text"
                          title={getSubstring(
                            getFileName(
                              item?.shipmentInvoiceDetails?.invoiceDetails?.[0]
                                ?.files?.invoice,
                            ),
                            20,
                          )}
                          size="small"
                          onClick={() =>
                            handleDocDownload(
                              item?.shipmentInvoiceDetails?.invoiceDetails?.[0]
                                ?.files?.invoice,
                              item,
                            )
                          }
                          style={{
                            padding: 0,
                            minWidth: "fit-content",
                            fontSize: 14,
                          }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box display={"flex"} alignItems={"center"}>
                        <StatusChip
                          status={item?.nextStates}
                          isTooltip={true}
                        />
                        {item?.status === PAYMENT_FAILED && (
                          <TooltipLabel
                            placement="right"
                            title={
                              <Typography sx={{ fontSize: 14, margin: 4 }}>
                                {item?.errorMessage}
                              </Typography>
                            }
                            labelChildren={
                              <InfoOutlined
                                style={{
                                  width: 16,
                                  marginLeft: 6,
                                  marginTop: 4,
                                }}
                                color={"primary"}
                              />
                            }
                          />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box style={{ textAlign: "center" }}>
                        {item?.invoiceComments ? (
                          <HoverBlock
                            LabelChildren={
                              <IconButton>
                                <SvgIcon
                                  name={"chat-unread"}
                                  width={24}
                                  height={24}
                                />
                              </IconButton>
                            }
                            data={{
                              "Invoice comments": item?.invoiceComments,
                            }}
                          />
                        ) : (
                          <IconButton>
                            <SvgIcon
                              name={"chat-read"}
                              width={24}
                              height={24}
                            />
                          </IconButton>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : loading ? (
                <TableCell
                  colSpan={INVOICE_APPROVED_HEADERS.length}
                  sx={{ textAlign: "center" }}
                >
                  <Loader sx={{ marginTop: 0 }} />
                </TableCell>
              ) : (
                <TableCell
                  colSpan={INVOICE_APPROVED_HEADERS.length}
                  sx={{ textAlign: "center" }}
                >
                  No data found
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!!approvedTransactions?.payoutSummaries?.length && (
          <TablePagination
            rowsPerPageOptions={[rowsPerPage]}
            rowsPerPage={rowsPerPage}
            page={pageNumber}
            count={approvedTransactions?.totalCount ?? 0}
            onPageChange={onPageChange}
            sx={{ display: "flex", flexDirection: "column-reverse" }}
            ActionsComponent={PaginationAction}
          />
        )}
      </Paper>
    );
  },
);

export default InvoiceTable;
