import { useEffect, useRef, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";

const ButtonModule = ({
  loading = false,
  title,
  style,
  disabled,
  onClick,
  variant = "contained",
  color = "secondary",
  size = "medium",
  type = "button",
  ...rest
}) => {
  const buttonRef = useRef(null);
  const [ctaSize, setCtaSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    setCtaSize((prev) => {
      if (!prev?.width) {
        return {
          width: buttonRef?.current?.offsetWidth,
          height: buttonRef?.current?.offsetHeight,
        };
      }
      return prev;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonRef.current]);

  return (
    <Button
      onClick={onClick}
      ref={buttonRef}
      size={size}
      type={type}
      color={color}
      variant={variant}
      disabled={disabled || loading}
      style={{
        borderRadius: 8,
        whiteSpace: "nowrap",
        minWidth: ctaSize.width,
        cursor: "pointer",
        ...(style && { ...style }),
      }}
      {...rest}
    >
      {loading ? <CircularProgress size={24} /> : title}
    </Button>
  );
};

ButtonModule.propTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  color: PropTypes.string,
  variant: PropTypes.string,
};

export default ButtonModule;
