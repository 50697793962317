import {
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
  forwardRef,
  Fragment,
} from "react";
import PropTypes from "prop-types";
import {
  List,
  ListItem,
  ListItemButton,
  ClickAwayListener,
  Collapse,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";

import theme from "themeProvider";
import { TextField } from "components";
import { useDebounce } from "hooks";

const SearchBar = forwardRef(
  (
    {
      onItemSelect,
      displayAttribute,
      placeholder = "Search here",
      onClear,
      defaultValue = "",
      customListStyle = {},
      customListWrapperStyle = {},
      style = {},
      inputStyle = {},
      resolver,
      renderItem,
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState(defaultValue);
    const [searchResult, setSearchResult] = useState([]);
    const [openList, setOpenList] = useState(false);
    const search = useDebounce(inputValue, 1000);

    useImperativeHandle(
      ref,
      () => ({
        resetValue: () => {
          setSearchResult([]);
          setInputValue("");
        },
      }),
      [],
    );

    const listStyles = {
      border: `1px solid ${theme.palette.text.primary}`,
      width: "fit-content",
      left: -1,
      right: 0,
      bgcolor: "background.paper",
      maxHeight: 300,
      zIndex: 3,
      position: "absolute",
      borderRadius: 1,
      overflowY: "auto",
      "& ul": { padding: 0 },
    };
    const dropStyle = {
      position: "absolute",
      maxWidth: "9.5rem",
      width: "100%",
      marginTop: 1,
    };

    const fetchResults = useCallback(async () => {
      if (!search) {
        setSearchResult([]);
        return;
      }
      try {
        const res = await resolver(search);
        setSearchResult(res ?? []);
      } catch (err) {
        setSearchResult([]);
      }
    }, [search]);

    useEffect(() => {
      fetchResults();
    }, [fetchResults]);

    const handleListClick = (result) => {
      setInputValue(result[displayAttribute]);
      setOpenList(false);
      if (onItemSelect) {
        onItemSelect(result);
      }
    };

    const handleChange = (e) => {
      setInputValue(e.target.value);
      setOpenList(true);
    };

    const handleClickAway = () => {
      setOpenList(false);
    };

    const handleClearInput = () => {
      setInputValue("");
      onClear();
    };

    useEffect(() => {
      if (defaultValue) {
        setInputValue(defaultValue);
      }
    }, [defaultValue]);

    const renderListItem = (suggestion) => {
      if (renderItem) {
        return renderItem(suggestion);
      }

      return suggestion[displayAttribute];
    };

    return (
      <Box sx={style}>
        <TextField
          sx={{ position: "relative", width: "100%" }}
          id="search-bar"
          InputProps={{
            style: inputStyle,
            startAdornment: (
              <SearchIcon
                fontSize="small"
                className={inputValue && "text-added"}
                sx={{
                  "&.MuiSvgIcon-root": {
                    color: theme.palette.text.disabled,
                    marginRight: 1,
                    "&.text-added": {
                      color: theme.palette.text.primary,
                    },
                  },
                }}
              />
            ),
            endAdornment: !!onClear && !!inputValue && (
              <ClearRoundedIcon
                sx={{ cursor: "pointer" }}
                fontSize="small"
                onClick={handleClearInput}
              />
            ),
          }}
          value={inputValue}
          onChange={handleChange}
          placeholder={placeholder}
          size="small"
          variant="outlined"
        />
        <ClickAwayListener onClickAway={handleClickAway}>
          <Collapse
            in={openList}
            sx={{ ...dropStyle, ...customListWrapperStyle }}
          >
            <List sx={{ ...listStyles, ...customListStyle }}>
              {searchResult?.length ? (
                <Fragment>
                  {searchResult.map((suggestion, index) => (
                    <ListItem key={index} sx={{ padding: 0 }}>
                      <ListItemButton
                        sx={{ display: "block", padding: "6px 16px" }}
                        onClick={() => handleListClick(suggestion)}
                      >
                        {renderListItem(suggestion)}
                      </ListItemButton>
                    </ListItem>
                  ))}
                </Fragment>
              ) : (
                <ListItem>No items found</ListItem>
              )}
            </List>
          </Collapse>
        </ClickAwayListener>
      </Box>
    );
  },
);

SearchBar.propTypes = {
  onItemSelect: PropTypes.func,
  resolver: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  renderItem: PropTypes.func,
  displayAttribute: PropTypes.string.isRequired,
};

export default SearchBar;
