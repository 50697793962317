import { createSlice } from "@reduxjs/toolkit";

export const approvedCheckedTx = createSlice({
  name: "approvedCheckedTx",
  initialState: [],
  reducers: {
    //for single tx
    addTx: (state, action) => {
      const payoutIds = action?.payload?.map((ele) => ele?.payoutId) ?? [];
      let prevState = [...state];
      const result = prevState.filter(
        (ele) => !payoutIds.includes(ele.payoutId),
      );
      return [...result, ...action.payload];
    },
    //replace single existing tx
    removeSingleTx: (state, action) => {
      const id = action.payload;
      const newState = [...state];
      const result = newState.filter((ele) => ele.payoutId !== id);
      return result;
    },
    removeTxList: (state, action) => {
      const newState = [...state];
      const idToBeRemoved =
        action?.payload?.map(({ payoutId }) => payoutId) ?? [];
      const result = newState?.filter(
        (ele) => !idToBeRemoved.includes(ele.payoutId),
      );
      return result;
    },
    // empty existing list
    truncateTx: () => {
      return [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { addTx, removeSingleTx, removeTxList, truncateTx } =
  approvedCheckedTx.actions;

export default approvedCheckedTx.reducer;
