import {
  TableCell,
  Box,
  Typography,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";

import { StyledTableRow } from "CustomStyledComponents";

import { moneyFormat, validateNull } from "utils";

import { FinancePayouts } from "../../constants";

const { ORDER_BLOCK_DISBURSEMENT_HEADERS } = FinancePayouts;

const OrderBlockDisbursement = ({ data }) => {
  return (
    <Box mb={4}>
      <Typography fontWeight={"bold"} textAlign={"center"} mb={4}>
        Do you want to confirm the payout ?
      </Typography>
      {!!data && (
        <Box mb={6}>
          <TableContainer sx={{ maxHeight: 420 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {ORDER_BLOCK_DISBURSEMENT_HEADERS.map((item) => (
                    <TableCell key={item}>{item}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((ele) => (
                  <StyledTableRow key={ele?.id}>
                    <TableCell>{validateNull(ele?.entityGstin)}</TableCell>
                    <TableCell>{validateNull(ele?.orderId)}</TableCell>
                    <TableCell>{validateNull(ele?.payoutId)}</TableCell>
                    <TableCell>{moneyFormat(ele?.advanceAmount)}</TableCell>
                    <TableCell>{moneyFormat(ele?.creditAmount)}</TableCell>
                    <TableCell>{validateNull(ele?.creditInstrument)}</TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default OrderBlockDisbursement;
