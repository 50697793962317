import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from "@mui/material";

import { ButtonV1, HyperLink } from "components";

import { moneyFormat } from "utils";
import { DetailsTab } from "./constants";

const { BLOCKED_AMOUNT_HEADERS } = DetailsTab;

const BlockedAmountTable = ({ onClose, data }) => {
  return (
    <>
      <Box>
        <Typography style={{ fontWeight: 600 }}>Block amount info</Typography>
        <TableContainer
          sx={{
            marginTop: 4,
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {BLOCKED_AMOUNT_HEADERS.map((heading) => (
                  <TableCell key={heading}>{heading}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <HyperLink title={item?.orderNumber} />
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={14}>
                      {moneyFormat(item?.orderAmount) ?? "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={14}>
                      {moneyFormat(item?.blockAmount) ?? "-"}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Divider
        style={{
          marginTop: 12,
          borderBottomWidth: 2,
          marginBottom: 16,
        }}
      />
      <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        <ButtonV1
          title={"Close"}
          style={{ textAlign: "flex-end" }}
          onClick={onClose}
        />
      </Box>
    </>
  );
};

export default BlockedAmountTable;
