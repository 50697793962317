import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import { Login } from "pages/LandingPage";
import Order from "pages/OrderList";
import Dashboard from "pages/Dashboard";
import Details from "pages/OrderDetails";
import IncomingPayments from "pages/IncomingPayments";
import Ledger from "pages/Ledger";
import SLO from "pages/SLO";
import UserManagement from "pages/UAM";
import Users from "pages/UAM/ViewUsers";
import UserDetails from "pages/UAM/ViewUsers/UserDetails";
import RoleDetails from "pages/UAM/Roles/RoleDetails";
import FinancePayout from "pages/FinancePayout";
import CreditUpdate from "pages/CreditUpdate";
import GroupDetail from "pages/UAM/Groups/GroupDetail";
import LedgerCorrection from "pages/LedgerCorrection";
import CreditOrder from "pages/CreditOrder";
import CreditInstrument from "pages/CreditUpdate/CreditInstrument";
import CustomerProfile from "pages/CustomerProfile";
import CustomerProfileDetails from "pages/CustomerProfileDetails";
import SupplyPanel from "pages/SupplyPanel";
import ZohoOrderView from "pages/ZohoOrderView";

import {
  OAuthRedirectHandler,
  SideBar,
  PageNotFound,
  ProtectedRoute,
} from "components";

function App() {
  /**
   * @description If app url is used in iframe it will redirect to app url, It will not be used within iframe
   */
  useEffect(() => {
    if (window !== window.top) {
      window.top.location = window.location;
    }
  }, []);

  return (
    <Routes>
      <Route path="/oauth2/redirect" element={<OAuthRedirectHandler />} />

      <Route path="/" element={<Login />} />
      {/* Authenticated Routes */}
      <Route element={<ProtectedRoute />}>
        <Route
          path="/dashboard"
          element={
            <SideBar>
              <Dashboard />
            </SideBar>
          }
        />
        <Route
          path="/order-list"
          element={
            <SideBar>
              <Order />
            </SideBar>
          }
        />
        <Route
          path={`/order-list/:id`}
          element={
            <SideBar>
              <Details />
            </SideBar>
          }
        />
        <Route
          path={"/incoming-payments"}
          element={
            <SideBar>
              <IncomingPayments />
            </SideBar>
          }
        />
        <Route
          path="/ledger"
          element={
            <SideBar>
              <Ledger />
            </SideBar>
          }
        />
        <Route
          path="/slo-details"
          element={
            <SideBar>
              <SLO />
            </SideBar>
          }
        />
        <Route
          path="/user-management"
          element={
            <SideBar>
              <UserManagement />
            </SideBar>
          }
        />
        <Route
          path="/user-management/users"
          element={
            <SideBar>
              <Users />
            </SideBar>
          }
        />
        <Route
          path="/user-management/user-details"
          element={
            <SideBar>
              <UserDetails />
            </SideBar>
          }
        />
        <Route
          path="/user-management/group-detail/:groupId"
          element={
            <SideBar>
              <GroupDetail />
            </SideBar>
          }
        />
        <Route
          path="/user-management/role-details/:roleId"
          element={
            <SideBar>
              <RoleDetails />
            </SideBar>
          }
        />
        <Route
          path="/ledger-correction"
          element={
            <SideBar>
              <LedgerCorrection />
            </SideBar>
          }
        />

        <Route
          path="/finance-payout"
          element={
            <SideBar>
              <FinancePayout />
            </SideBar>
          }
        />

        <Route
          path="/credit-limit"
          element={
            <SideBar>
              <CreditUpdate />
            </SideBar>
          }
        />
        <Route
          path="/credit-orders"
          element={
            <SideBar>
              <CreditOrder />
            </SideBar>
          }
        />

        <Route
          path="/credit-limit/:id"
          element={
            <SideBar>
              <CreditInstrument />
            </SideBar>
          }
        />
        <Route
          path="/customer-profile"
          element={
            <SideBar>
              <CustomerProfile />
            </SideBar>
          }
        />
        <Route
          path="/customer-profile-details/:id"
          element={
            <SideBar>
              <CustomerProfileDetails />
            </SideBar>
          }
        />
        <Route
          path="/supply-panel"
          element={
            <SideBar>
              <SupplyPanel />
            </SideBar>
          }
        />
        <Route
          path="/zoho-order-view"
          element={
            <SideBar>
              <ZohoOrderView />
            </SideBar>
          }
        />
      </Route>

      {/* Page not Found */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
