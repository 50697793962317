import * as Yup from "yup";
import { CreditNotesSellerPayout } from "../constants";

const { creditNotesManualInput, creditYup } = CreditNotesSellerPayout;

const creditNotesSchema = Yup.object().shape({
  [creditYup]: Yup.array().of(
    Yup.object()
      .shape({
        [creditNotesManualInput.transactionType]: Yup.string().required(
          "Please select a transaction type",
        ),
        [creditNotesManualInput.fromAccount]: Yup.string()
          .required("Enter valid GSTIN")
          .min(15, "GSTIN must be 15 characters"),
        [creditNotesManualInput.toAccount]: Yup.string()
          .required("Enter valid GSTIN")
          .min(15, "GSTIN must be 15 characters"),

        [creditNotesManualInput.originalInvoiceId]: Yup.array().test({
          name: "originalInvoiceId",
          message: "Enter the original Invoice number",
          test(value, ctx) {
            const { originalInvoiceId } = ctx.parent;
            return !!originalInvoiceId?.length;
          },
        }),

        [creditNotesManualInput.documentId]:
          Yup.string().required("Enter document ID"),
      })
      .nullable(),
  ),
});

export { creditNotesSchema };
