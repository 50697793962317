import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { FinancePayouts } from "./constants";

const { queryFilters, INVOICE_SELLER_PAYOUTS, financeTab } = FinancePayouts;

const useFetchParam = (paramName = "filter") => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateParams = ({
    param = {
      [queryFilters.tab]: financeTab.historical,
      [queryFilters.transactionType]: INVOICE_SELLER_PAYOUTS,
    },
    appendWithExisting = true,
    setParam = true,
  }) => {
    try {
      const isExstingParams = searchParams.has(paramName);
      let jsonParse = {};
      if (isExstingParams && appendWithExisting) {
        const existingParams = searchParams.get(paramName);
        const json = atob(existingParams);
        jsonParse = JSON?.parse(json);
      }

      const newParam = {
        ...jsonParse,
        ...param,
      };

      const stringify = JSON.stringify(newParam);
      const encodedParam = btoa(stringify);

      if (setParam) {
        searchParams.set(paramName, encodedParam);
        setSearchParams(searchParams);
      } else {
        return {
          paramName: encodedParam,
        };
      }
    } catch (error) {
      console.error("something failed ::", error);
    }
  };

  const fetchParams = useMemo(() => {
    try {
      const existingParams = searchParams.get(paramName);

      const json = atob(existingParams);

      return JSON?.parse(json);
    } catch (error) {
      console.log(JSON.stringify(error), ":: fetch fail");

      return {};
    }
  }, [paramName, searchParams]);

  return { updateParams, fetchParams };
};

export default useFetchParam;
