import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TablePagination,
} from "@mui/material";

import {
  ButtonV1,
  Loader,
  SearchBar,
  PaginationAction,
  Timestamp,
} from "components";
import { SearchList } from "../components";
import BankDetailTip from "../components/BankDetailTip";

import { appendRupeeIcon } from "utils";
import { IncomingPayment } from "../constants";

const { UNTAGGED_PAYMENT_HEADING } = IncomingPayment;

const PaymentTable = ({
  isLoading,
  data = [],
  rowsPerPage,
  pageNumber = 0,
  totalCount,
  onPageChange,
  onSearchCompany,
  onSetCompanyData,
  onCheckSaveDisabled,
  onTaggingPayment,
  onCreateLedger,
  validCreateLedgers,
}) => {
  return (
    <Paper key={"taggedPayment"}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {UNTAGGED_PAYMENT_HEADING.map((heading) => (
                <TableCell key={heading}>{heading}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={UNTAGGED_PAYMENT_HEADING.length}>
                  <Loader sx={{ marginTop: 0 }} />
                </TableCell>
              </TableRow>
            )}
            {!isLoading && !data.length && (
              <TableRow>
                <TableCell colSpan={UNTAGGED_PAYMENT_HEADING.length}>
                  <Typography textAlign={"center"}>No Payment Found</Typography>
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              !!data.length &&
              data.map((row) => (
                <TableRow
                  style={{ cursor: "default" }}
                  key={row.transactionReference}
                >
                  <TableCell>
                    <Timestamp timeStamp={row.whenCreated} />
                  </TableCell>
                  <TableCell>{row?.notes?.paymentMethod}</TableCell>
                  <TableCell>
                    {appendRupeeIcon(row.money.displayAmount ?? "0")}
                  </TableCell>
                  <TableCell>{row.transactionReference}</TableCell>
                  <TableCell>{row?.notes?.status}</TableCell>
                  <TableCell>
                    <BankDetailTip
                      bankInfo={row?.notes}
                      vanEmail={row?.accountReference}
                    />
                  </TableCell>
                  <TableCell>
                    <SearchBar
                      resolver={(search) => onSearchCompany(search, row?.id)}
                      displayAttribute="companyName"
                      onItemSelect={(value) =>
                        onSetCompanyData(value, row.transactionReference)
                      }
                      renderItem={SearchList}
                    />
                  </TableCell>
                  <TableCell sx={{ display: "flex", flexDirection: "row" }}>
                    {validCreateLedgers.includes(row?.transactionReference) && (
                      <ButtonV1
                        onClick={() => onCreateLedger(row)}
                        disabled={false}
                        title="Create ledger"
                        variant={"text"}
                        style={{ paddingX: 2, marginLeft: -2 }}
                      />
                    )}
                    <ButtonV1
                      onClick={() => onTaggingPayment(row)}
                      disabled={
                        !onCheckSaveDisabled(row.transactionReference) ||
                        validCreateLedgers.includes(row?.transactionReference)
                      }
                      title="Save"
                      variant={"text"}
                      style={{ paddingX: 2, marginLeft: -2 }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!!data.length && (
        <TablePagination
          rowsPerPageOptions={[rowsPerPage]}
          colSpan={UNTAGGED_PAYMENT_HEADING.length}
          rowsPerPage={rowsPerPage}
          page={pageNumber}
          count={totalCount}
          onPageChange={onPageChange}
          sx={{ display: "flex", flexDirection: "column-reverse" }}
          ActionsComponent={PaginationAction}
        />
      )}
    </Paper>
  );
};

export default PaymentTable;
