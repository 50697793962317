import { styled } from "@mui/system";
import { TextField as Text } from "@mui/material";
import theme from "themeProvider";

const TextField = styled(Text)(({ customPadding, highlightError }) => ({
  "& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
    fontWeight: "unset",
    padding: customPadding,
  },
  "& fieldset": {
    borderColor: !!highlightError && `${theme.palette.error.dark}`,
  },
}));
export default TextField;
