import { useState } from "react";
import { Avatar, Box, TableCell, Typography, useTheme } from "@mui/material";

import { TableRowEdit, GreyChip } from "pages/UAM/components";
import { DialogBox, TableWrapper } from "components";
import { GROUP_TABLE_HEAD } from "./constant";
import { DELETE_USER_CONFIRM_MSG } from "constants";

const UserList = ({ userList = {}, onDelete, onViewDetail }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [user, setUser] = useState({});

  const theme = useTheme();
  const users = Object.values(userList);

  const handleExitPage = () => {
    setIsOpenModal(false);
    onDelete(user);
  };

  const handleDelete = (user) => {
    setUser(user);
    setIsOpenModal(true);
  };

  return (
    <>
      <TableWrapper
        tableHeader={GROUP_TABLE_HEAD?.map((item) => {
          return (
            <TableCell key={item}>
              <Typography
                variant="body2"
                style={{
                  color: theme.palette.grey["A200"],
                  fontSize: 18,
                  fontWeight: "600",
                }}
              >
                {item}
              </Typography>
            </TableCell>
          );
        })}
        tableBody={(users || []).map((user) => (
          <TableRowEdit
            onDelete={() => handleDelete(user)}
            onViewDetail={() => onViewDetail(user)}
          >
            <TableCell>
              <Box display="flex" sx={{ alignItems: "center", gap: 4 }}>
                <Avatar
                  alt="user"
                  src={user?.imageUrl}
                  style={{ height: 38, width: 38 }}
                />
                <Typography
                  variant="body2"
                  style={{
                    marginLeft: 12,
                    fontSize: 16,
                    color: theme.palette.grey["A200"],
                    fontWeight: "normal",
                  }}
                >
                  {user?.name}
                </Typography>
              </Box>
            </TableCell>
            <TableCell>
              <a href={`mailTo:${user?.email}`}>{user?.email}</a>
            </TableCell>
            <TableCell>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  flexWrap: "wrap",
                  maxWidth: 520,
                }}
              >
                {(user?.roles || []).map((role) => (
                  <GreyChip label={role} />
                ))}
              </Box>
            </TableCell>
          </TableRowEdit>
        ))}
        errorMessage={!users.length && "No user found."}
        tableHeaderStyle={{ backgroundColor: theme.palette.common.white }}
        wrapperStyle={{ marginTop: 8 }}
      />
      <DialogBox
        handleClose={() => setIsOpenModal(false)}
        open={isOpenModal}
        handleConfirm={handleExitPage}
        message={DELETE_USER_CONFIRM_MSG}
      />
    </>
  );
};

export default UserList;
