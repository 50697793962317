import { Grid, Box, Typography } from "@mui/material";
import theme from "themeProvider";

const borderColor = `2px solid ${theme.palette.grey["400"]}`;

const HoverBlock = ({ data, title, firstColumnXs, secondColumnXs }) => {
  return (
    <Box width={300} p={2} borderRight={title !== "JODL" && borderColor}>
      <Box p={2} pl={0} borderBottom={borderColor}>
        <Typography fontWeight={"bold"}> {title}</Typography>
      </Box>
      {Object.entries(data).map(([key, value], i) => (
        <Grid container key={key} pt={2} pb={2}>
          <Grid item xs={firstColumnXs}>
            <Typography fontSize={12}>{key}</Typography>
          </Grid>
          <Grid item xs={secondColumnXs}>
            <Typography fontSize={12} fontWeight={"bold"}>
              {value}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

const SellerJODLHoverBlock = ({
  sourceSellerData,
  jodlData,
  firstColumnXs = 3,
  secondColumnXs = 9,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: theme.palette.common.white,
        width: 600,
      }}
      borderRadius={1}
      boxShadow={`0 0 3px 2px ${theme.palette.text.secondary}`}
    >
      <HoverBlock
        data={sourceSellerData}
        firstColumnXs={firstColumnXs}
        secondColumnXs={secondColumnXs}
        title={"Source seller"}
      />
      <HoverBlock
        data={jodlData}
        firstColumnXs={firstColumnXs}
        secondColumnXs={secondColumnXs}
        title={"JODL"}
      />
    </Box>
  );
};

export default SellerJODLHoverBlock;
