import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";

import { verifyLoginAction } from "dataStore/auth";

import { ACCESS_TOKEN } from "constants";

function OAuthRedirectHandler() {
  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    let token;
    if (params.get(ACCESS_TOKEN)) {
      token = params.get(ACCESS_TOKEN);
    } else {
      token = localStorage.getItem(ACCESS_TOKEN);
      localStorage.removeItem(ACCESS_TOKEN);
    }
    dispatch(verifyLoginAction({ value: true, token }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Outlet />;
}

export default OAuthRedirectHandler;
