import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useSelector } from "react-redux";

import { ButtonV1, DatePickerV1, TextField, Loader } from "components";
import ColumnStack from "../../components/ColumnStack";
import Attachment from "../../components/Attachment";
import { ErrorMsgV1 } from "CustomStyledComponents";

import createCreditLimit from "./test";

import { postCall_v2 } from "services";
import { useToaster } from "hooks";
import { success, error } from "constants";

/**
 *
 * @description Review content
 * post file upload
 */
const ReviewContent = ({ creditData, onCancel }) => {
  const theme = useTheme();
  const [isLoaderVisibile, setLoaderVisibility] = useState(false);
  const userData = useSelector((state) => state.userData);

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(createCreditLimit),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "fileContent",
  });

  const triggerToaster = useToaster();

  /**
   * Add validation to avoid render twice which happened due to stickMode
   */
  useEffect(() => {
    if (creditData && creditData.length) {
      creditData.forEach((ele) => {
        const { GSTIN, CREDIT_PROGRAM_NAME, PAN } = ele;
        if (GSTIN && CREDIT_PROGRAM_NAME && PAN) {
          append(ele);
        } else {
          console.error("Some column values are missing");
        }
      });
    }
  }, [creditData]);

  /**
   * @description submit bulk credit instrument create data
   */
  const onCreditCreate = async () => {
    try {
      setLoaderVisibility(true);
      const payload = getValues();
      const formattedData = payload?.fileContent.map((ele) => {
        const parsedDate = moment.utc(ele?.expiryDate).startOf("day");
        const formattedDate = parsedDate.format("YYYY-MM-DDTHH:mm:ss[Z]");

        const obj = {
          creditDueDays: ele?.creditDueDays,
          expiryDate: formattedDate,
          creditType: ele?.creditType,
          penalRate: ele?.penalRate,
          sanctionedLimit: ele?.sanctionedLimit,
          utilisedLimit: "0",
          creditInstrumentType: ele?.creditInstrumentType,
          pan: ele?.pan ?? null,
          gracePeriod: ele?.gracePeriod,
          camId: ele?.camId,
          comments: ele?.comments,
          path: ele?.file ?? null,
          companyName: ele?.companyName ?? null,
          gstin: ele?.gstin ?? null,
          sellerGstin: ele?.sellerGstin,
          requestedBy: userData?.name ?? null,
          advancePercentage: ele?.advancePercentage,
        };
        return obj;
      });

      const { data } = await postCall_v2(
        "/oms/credit/createNewInstrumentList",
        formattedData,
      );

      if (data?.successful) {
        triggerToaster(`Credit instruments created successfully`, success);
        remove();
        onCancel();
      } else {
        triggerToaster(`Creating credit instruments failed`, error);

        //remove data & only show row which has error
        remove();
        onCancel();

        const result = [];
        /**
         * Pushed credit limit item in result only if successful false
         * Used forEach instead of map as map return undefined even not return anything
         */
        data?.creditCreateBulkResponse?.forEach((ele) => {
          if (!ele.successful) {
            const {
              camId,
              gracePeriod,
              comments,
              companyName,
              gstin,
              advancePercentage,
              creditType,
              expiryDate,
              creditInstrumentType,
              sanctionedLimit,
              penalRate,
              creditDueDays,
              pan,
              message,
              sellerGstin,
            } = ele;
            const errorCreditItem = {
              INSTRUMENT_NAME: creditType,
              EXPIRY_DATE: expiryDate,
              CREDIT_PROGRAM_NAME: creditInstrumentType,
              PENAL_RATE: penalRate ?? 0,
              CREDIT_DUE_DAYS: creditDueDays ?? 0,
              GRACE_PERIOD: gracePeriod ?? 0,
              CAM_ID: camId,
              ADVANCE_PERCENTAGE: advancePercentage ?? 0,
              COMMENTS: comments,
              SANCTIONED_LIMIT: sanctionedLimit ?? 0,
              COMPANY_NAME: companyName,
              GSTIN: gstin,
              PAN: pan,
              SELLER_GSTIN: sellerGstin,
              isError: message,
            };
            result.push(errorCreditItem);
          }
        });
        //timer to ensure append() operation is completed
        setTimeout(() => {
          result.forEach((ele) => append(ele));
        }, 1000);
      }
    } catch (ex) {
      console.log(`Exception on updating credit data ${ex}`);
      triggerToaster(`Creating credit instruments failed`, error);
    } finally {
      setLoaderVisibility(false);
    }
  };

  /**
   *
   * @description Remove all credit instruments in review list
   */
  const removeAll = () => {
    remove();
    onCancel();
    triggerToaster(`Removed uploaded credit limit sheet successfully`, success);
  };

  /**
   * @description Format error msg
   */
  const getError = (name, index) => {
    return errors?.fileContent?.[index]?.[name]?.message;
  };

  /**
   *@description prevent input field negative and e
   */
  const negativeCheck = (evt) => {
    if (evt.key === "e" || evt.key === "-") {
      evt.preventDefault();
    }
  };

  /**
   * @description Upload selected file
   * @param {*} files Selected file
   * @param {*} index index of row item
   */
  const handleUpload = async (files, index) => {
    try {
      const formData = new FormData();
      formData.append("file", files);
      const res = await postCall_v2(
        `/oms/credit/document/upload?pan=${getValues(
          `fileContent.${index}.pan`,
        )}`,
        formData,
      );
      if (res) {
        setValue(`fileContent.${index}.file`, res?.data?.creditDocumentPath);
        triggerToaster(`Files uploaded`, success);
      }
    } catch (err) {
      triggerToaster(
        err?.data?.message || `Error occurred, please try again`,
        error,
      );
    }
  };

  return (
    <>
      {isLoaderVisibile ? (
        <Loader sx={{ marginTop: 0 }} />
      ) : (
        <>
          {!!(fields && fields.length) && (
            <>
              <Typography fontSize={18} fontWeight={"bold"} mb={2}>
                Review uploaded credit limits
              </Typography>
              <Box
                as="form"
                onSubmit={handleSubmit(onCreditCreate)}
                border={1}
                maxHeight={600}
                borderColor={"lightgray"}
                overflow={"auto"}
                overflowY={"scroll"}
                borderRadius={4}
                p={8}
              >
                {fields.map((ele, index) => {
                  return (
                    <Grid
                      key={index}
                      container
                      mb={6}
                      border={1}
                      borderColor={
                        ele?.isError ? theme.palette.error.dark : "lightgray"
                      }
                      borderRadius={4}
                      p={8}
                    >
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>
                            Instrument name
                          </Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.creditType`}
                            defaultValue={ele?.INSTRUMENT_NAME}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                size={"small"}
                              />
                            )}
                          />
                          <ErrorMsgV1 msg={getError("creditType", index)} />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>Expiry date</Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.expiryDate`}
                            defaultValue={ele?.EXPIRY_DATE}
                            render={({ field: { value, onChange } }) => (
                              <DatePickerV1
                                dateStyle={{ width: "80%" }}
                                value={value}
                                onChange={onChange}
                              />
                            )}
                          />
                          <ErrorMsgV1 msg={getError("expiryDate", index)} />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>
                            Credit program name
                          </Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.creditInstrumentType`}
                            defaultValue={ele?.CREDIT_PROGRAM_NAME}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                size={"small"}
                              />
                            )}
                          />
                          <ErrorMsgV1
                            msg={getError("creditInstrumentType", index)}
                          />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>
                            Penal charges (% per annum)
                          </Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.penalRate`}
                            defaultValue={+ele?.PENAL_RATE}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                type="number"
                                size={"small"}
                                inputProps={{ min: 0 }}
                                onKeyDown={negativeCheck}
                              />
                            )}
                          />
                          <ErrorMsgV1 msg={getError("penalRate", index)} />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>Credit days</Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.creditDueDays`}
                            defaultValue={+ele?.CREDIT_DUE_DAYS ?? 0}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                type="number"
                                size={"small"}
                                inputProps={{ min: 0 }}
                                onKeyDown={negativeCheck}
                              />
                            )}
                          />
                          <ErrorMsgV1 msg={getError("creditDueDays", index)} />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>
                            Grace period (in days)
                          </Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.gracePeriod`}
                            defaultValue={+ele?.GRACE_PERIOD ?? 0}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                type="number"
                                size={"small"}
                                inputProps={{ min: 0 }}
                                onKeyDown={negativeCheck}
                              />
                            )}
                          />
                          <ErrorMsgV1 msg={getError("gracePeriod", index)} />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>
                            Credit limit Reference ID
                          </Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.camId`}
                            defaultValue={ele?.CAM_ID}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                size={"small"}
                              />
                            )}
                          />
                          <ErrorMsgV1 msg={getError("camId", index)} />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>Advance (%)</Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.advancePercentage`}
                            defaultValue={+ele?.ADVANCE_PERCENTAGE ?? 0}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                type="number"
                                onChange={onChange}
                                size={"small"}
                                inputProps={{ min: 0 }}
                                onKeyDown={negativeCheck}
                              />
                            )}
                          />
                          <ErrorMsgV1
                            msg={getError("advancePercentage", index)}
                          />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>Remarks</Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.comments`}
                            defaultValue={ele?.COMMENTS}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                size={"small"}
                              />
                            )}
                          />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>
                            Sanctioned limit
                          </Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.sanctionedLimit`}
                            defaultValue={+ele?.SANCTIONED_LIMIT ?? 0}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                type="number"
                                size={"small"}
                                inputProps={{ min: 0 }}
                                onKeyDown={negativeCheck}
                              />
                            )}
                          />
                          <ErrorMsgV1
                            msg={getError("sanctionedLimit", index)}
                          />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>Company name</Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.companyName`}
                            defaultValue={ele?.COMPANY_NAME}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                size={"small"}
                              />
                            )}
                          />
                          <ErrorMsgV1 msg={getError("companyName", index)} />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>GSTIN</Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.gstin`}
                            defaultValue={ele?.GSTIN}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                size={"small"}
                              />
                            )}
                          />
                          <ErrorMsgV1 msg={getError("gstin", index)} />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>Seller gstin</Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.sellerGstin`}
                            defaultValue={ele?.SELLER_GSTIN}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                size={"small"}
                              />
                            )}
                          />
                          <ErrorMsgV1 msg={getError("sellerGstin", index)} />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3} mb={4}>
                        <ColumnStack>
                          <Typography fontWeight={400}>Pan</Typography>
                          <Controller
                            control={control}
                            name={`fileContent.${index}.pan`}
                            defaultValue={ele?.PAN}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                size={"small"}
                              />
                            )}
                          />
                          <ErrorMsgV1 msg={getError("pan", index)} />
                        </ColumnStack>
                      </Grid>
                      <Grid item xs={3}>
                        <ColumnStack>
                          <Typography fontWeight={400}>CAM document</Typography>
                          <Attachment
                            style={{ padding: 0 }}
                            boxStyle={{ width: "100%" }}
                            onFileUpload={(files) => handleUpload(files, index)}
                          />
                        </ColumnStack>
                      </Grid>
                      {!!ele.isError && (
                        <Grid item xs={12} mb={2}>
                          <Typography
                            fontSize={14}
                            color={theme.palette.error.dark}
                          >
                            {ele?.isError}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  );
                })}
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                  gap={4}
                  position={"sticky"}
                  bottom={0}
                >
                  <ButtonV1
                    type={"submit"}
                    title="Submit"
                    color="primary"
                    variant="contained"
                  />
                  <ButtonV1
                    title="Cancel"
                    variant="outlined"
                    onClick={removeAll}
                  />
                </Box>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

ReviewContent.Proptype = {
  creditData: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
};
export default ReviewContent;
