import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import {
  dateConvertor,
  getPaymentStatusColor,
  pascalCase,
  validateNull,
} from "utils";
import { HyperLink, Loader, PaginationAction } from "components";
import { CREDIT } from "../constant";

const rowsPerPage = 20;

const CreditTable = ({ data, onPageChange, isFetching }) => {
  return (
    <Paper>
      <TableContainer
        sx={{
          minWidth: 700,
          maxHeight: "calc(100vh - 120px)",
        }}
      >
        <Table stickyHeader>
          <TableHead style={{ borderRadius: "10px 10px 0 0" }}>
            <TableRow>
              {CREDIT.tableHeader.map((heading) => (
                <TableCell key={heading}>{heading}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <TableRow>
                <TableCell colSpan={CREDIT.tableHeader.length}>
                  <Loader sx={{ margin: 0 }} />
                </TableCell>
              </TableRow>
            ) : !!data?.response?.length ? (
              data.response.map((order) => (
                <TableRow key={order.sellerOrdNumber}>
                  <TableCell>
                    <HyperLink title={order?.customerOrderNumber} />
                  </TableCell>
                  <TableCell>{order.sellerOrdNumber}</TableCell>
                  <TableCell>{dateConvertor(order?.buyerOrderDate)}</TableCell>
                  <TableCell>{validateNull(order?.currentOrderType)}</TableCell>
                  <TableCell>
                    {validateNull(order?.currentOrderState)}
                  </TableCell>
                  <TableCell>{order?.childCurrentState.orderState}</TableCell>
                  <TableCell>{validateNull(order?.opsSpoc)}</TableCell>
                  <TableCell>
                    <span
                      style={{
                        color: getPaymentStatusColor(
                          order?.currentPaymentStatus,
                        ),
                      }}
                    >
                      {order?.currentPaymentStatus}
                    </span>
                  </TableCell>
                  <TableCell>
                    {dateConvertor(order?.committedDeliveryDates)}
                  </TableCell>
                  <TableCell>
                    {dateConvertor(order?.scheduledDeliveryDates)}
                  </TableCell>
                  <TableCell>{pascalCase(order?.companyName)}</TableCell>
                  <TableCell>
                    {validateNull(order?.sellerCompanyInfo?.name)}
                  </TableCell>
                  <TableCell>{validateNull(order?.shippingState)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={CREDIT.tableHeader.length}>
                  <Typography textAlign={"center"}>No data found</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {!!data?.response?.length && (
          <TablePagination
            rowsPerPageOptions={[rowsPerPage]}
            rowsPerPage={rowsPerPage}
            page={Math.ceil(data?.offset / rowsPerPage)}
            count={data?.totalCount ?? 0}
            onPageChange={onPageChange}
            sx={{ display: "flex", flexDirection: "column-reverse" }}
            ActionsComponent={PaginationAction}
          />
        )}
      </TableContainer>
    </Paper>
  );
};

export default CreditTable;
