import Box from "@mui/material/Box";
import { Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "40%",
  maxWidth: "90%",
  minHeight: "10%",
  maxHeight: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  borderRadius: 2,
};

const closeIconStyle = {
  position: "absolute",
  top: "2%",
  right: "1%",
  bgcolor: "background.paper",
};

export default function ModalBox({
  open,
  onCloseModal,
  children,
  width,
  hideCrossIcon = false,
  avoidBackDropClick = false,
  handleCloseIconClick = false,
}) {
  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (
          ["backdropClick", "escapeKeyDown"].includes(reason) &&
          avoidBackDropClick
        ) {
          return;
        }
        onCloseModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} style={{ width }}>
        {!hideCrossIcon && (
          <IconButton
            aria-label="close-icon"
            sx={closeIconStyle}
            onClick={() => {
              if (handleCloseIconClick) {
                handleCloseIconClick && handleCloseIconClick();
              } else {
                onCloseModal();
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        {children}
      </Box>
    </Modal>
  );
}

ModalBox.propTypes = {
  open: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  width: PropTypes.string,
};
