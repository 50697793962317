import { useEffect, useState } from "react";
import { Box, Chip, IconButton } from "@mui/material";
import { AddSharp, CloseOutlined } from "@mui/icons-material";
import PropTypes from "prop-types";

import { ButtonV1 } from "components";
import theme from "themeProvider";

const EditableChipSet = ({
  data = [],
  options = [],
  onUpdate,
  dataKey,
  optionKey,
}) => {
  const [showAvailableOptions, setShowAvailableOptions] = useState(false);
  const [dataArray, setDataArray] = useState([]);
  const [optionsArray, setOptionsArray] = useState([]);

  useEffect(() => {
    setDataArray(data);
    setOptionsArray(filterOptionList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, options]);

  const filterOptionList = () => {
    const defaultSelectedId = data?.map(({ id }) => id);
    return options.filter(({ id }) => !defaultSelectedId?.includes(id));
  };

  /**
   * * when user clicks on save changes button this function gets called
   * @envokes callback functions provided by parent
   */
  const onSubmit = () => {
    setShowAvailableOptions(false);
    onUpdate(dataArray);
  };

  /**
   * * when options chip is clicked
   * @updates the state of options and data arrays
   */
  const onChipAdd = (ele) => {
    setOptionsArray((data) =>
      data?.filter((option) => option?.[optionKey] !== ele?.[optionKey]),
    );
    dataArray?.length
      ? setDataArray((dataArray) => [...dataArray, { ...ele }])
      : setDataArray([{ ...ele }]);
  };
  /**
   * * when data chip is clicked to remove items
   * @updates the state of options and data arrays
   */
  const onChipDelete = (ele) => {
    setOptionsArray((optionsArray) => [...optionsArray, { ...ele }]);
    setDataArray((data) =>
      data.filter((option) => option?.[optionKey] !== ele?.[optionKey]),
    );
  };

  return (
    <Box
      width={"90%"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"flex-start"}
    >
      <Box>
        <Box>
          {dataArray?.map((ele) => {
            return (
              <Chip
                key={ele.id}
                label={ele?.[dataKey]}
                style={{
                  marginRight: 10,
                  marginBottom: 16,
                  borderColor: theme.palette.grey[700],
                  color: theme.palette.grey[700],
                }}
                variant="outlined"
                deleteIcon={
                  showAvailableOptions ? (
                    <CloseOutlined fontSize="small" style={{ height: 15 }} />
                  ) : (
                    <></>
                  )
                }
                onDelete={() => onChipDelete(ele)}
              />
            );
          })}
          {!showAvailableOptions && (
            <IconButton
              size="small"
              color="primary"
              style={{
                height: 40,
                marginBottom: 16,
                marginTop: !dataArray?.length && 14,
              }}
              onClick={() => {
                setShowAvailableOptions(true);
              }}
            >
              <object
                type="image/svg+xml"
                data={`/assets/add-chipset.svg`}
                style={{ pointerEvents: "none" }}
                alt="Add chipset icon"
                aria-label="add chipset icon"
              />
            </IconButton>
          )}
        </Box>
        {showAvailableOptions && (
          <Box>
            {optionsArray &&
              optionsArray.map((ele) => {
                return (
                  <Chip
                    key={ele.id}
                    label={ele?.[optionKey]}
                    style={{
                      marginRight: 10,
                      marginBottom: 10,
                      backgroundColor: theme.palette.green.light,
                      color: theme.palette.green.dark,
                    }}
                    variant="filled"
                    deleteIcon={
                      <AddSharp
                        fontSize="small"
                        style={{ color: theme.palette.green.dark, height: 15 }}
                      />
                    }
                    onDelete={() => onChipAdd(ele)}
                  />
                );
              })}
          </Box>
        )}
      </Box>
      {showAvailableOptions && (
        <ButtonV1
          title={"Save changes"}
          color="primary"
          variant="contained"
          size="small"
          style={{ height: 30, minWidth: 112 }}
          onClick={onSubmit}
        />
      )}
    </Box>
  );
};

export default EditableChipSet;

EditableChipSet.propTypes = {
  data: PropTypes.array,
  options: PropTypes.array,
  onUpdate: PropTypes.func,
  dataKey: PropTypes.string,
  optionKey: PropTypes.string,
};
