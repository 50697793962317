export const getFormattedListData = (data) => {
  let formattedObj = {};
  let formattedResponse = [];
  const { offset, totalCount } = data ?? {};
  data?.response?.forEach((element) => {
    let obj = {
      orderNumber: element?.orderNumber,
      customerOrderNumber: element?.customerOrderNumber,
      buyerOrderDates: element?.orderDate,
      currentOrderType: element?.orderType,
      currentOrderStatus: element?.orderStatus,
      orderStatus: element?.orderStatus,
      orderPaymentType: element?.orderPaymentType,
      childOrderStatus: element?.childOrderStatus,
      opsSpoc: element?.opsSpoc,
      orderPaymentTransactionType: element?.orderPaymentTransactionType,
      category: element?.category,
      currentPaymentStatus: element?.paymentStatus,
      committedDeliveryDates: element?.committedDeliveryDate,
      scheduledDeliveryDates: element?.scheduledDeliveryDate,
      buyerDetails: {
        companyName: element?.buyerDetails?.companyName,
        shippingState: element?.buyerDetails.shippingAddress?.state,
      },
      sellerDetails: {
        companyName: element?.sellerDetails?.companyName,
      },
    };
    formattedResponse.push(obj);
  });

  formattedObj = {
    response: formattedResponse,
    offset,
    totalCount,
  };
  return formattedObj;
};
