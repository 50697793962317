import { useCallback, useRef, useState } from "react";
import { Grid, TextField, Typography, useTheme } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import { getCall } from "services";
import { ButtonV1, MultiSelectDropdown, MultiSelectSearch } from "components";
import { opsSpocList, BUSINESS_TYPES, DISPLAY_ATTRIBUTE } from "constants";
import { BoxShadow } from "../components";

const filterRefCombination = (ref, field) => {
  let value = ref?.current?.fetchValue();
  if (field) {
    value = value?.map((item) => item[field]);
  }

  const params = value?.join(",");
  return params || null;
};

const resetRef = (ref) => {
  ref?.current?.resetValue();
};

const Filter = ({ onFilter, onreset }) => {
  const theme = useTheme();

  const opsRef = useRef(null);
  const buyerRef = useRef(null);
  const deliveryStateRef = useRef(null);
  const businessUnitRef = useRef(null);
  const sellerStateRef = useRef(null);
  const sellerNameRef = useRef(null);

  const [orderNumber, setOrderNumber] = useState("");
  const [sellerOrderNumber, setSellerOrderNumber] = useState("");
  const [toggleFilter, setToggleFilter] = useState(false);

  const handleOrderNumber = useCallback(
    ({ target: { value } }) => setOrderNumber(value),
    [],
  );

  const handleSellerOrderNumber = useCallback(
    ({ target: { value } }) => setSellerOrderNumber(value),
    [],
  );

  const handleToggleFilter = useCallback(
    () => setToggleFilter((prev) => !prev),
    [],
  );

  const getSearchedData = async (search, endpoint) => {
    const params = {
      [endpoint]: search,
    };
    const res = await getCall(`/oms/order/list/search/auto-suggest`, params);
    return res;
  };

  const handleSelectedFilter = useCallback(() => {
    const filters = {
      businessUnit: filterRefCombination(businessUnitRef),
      customerOrderNumber: orderNumber,
      sellerOrderNumber: sellerOrderNumber,
      companyName: filterRefCombination(sellerNameRef, "companyName"),
      buyerName: filterRefCombination(buyerRef, "buyerName"),
      orderState: filterRefCombination(deliveryStateRef, "orderState"),
      sellerState: filterRefCombination(sellerStateRef, "sellerState"),
      opsSpoc: filterRefCombination(opsRef),
    };
    onFilter(filters);
  }, [onFilter, orderNumber, sellerOrderNumber]);

  const handleReset = useCallback(() => {
    resetRef(businessUnitRef);
    resetRef(sellerNameRef);
    resetRef(buyerRef);
    resetRef(deliveryStateRef);
    resetRef(sellerStateRef);
    resetRef(opsRef);
    setOrderNumber("");
    setSellerOrderNumber("");
    onreset();
  }, [onreset]);

  return (
    <BoxShadow>
      <Grid container gap={3}>
        <Grid
          container
          xs={8}
          flex={4}
          spacing={4}
          rowGap={2}
          sx={{
            display: "flex",
          }}
        >
          <Grid item xs={3}>
            <MultiSelectDropdown
              ref={opsRef}
              itemList={opsSpocList}
              displayAttribute={"value"}
              label={"Ops SPOC"}
              isListOfObjects={true}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Seller order number"
              size="small"
              onChange={handleSellerOrderNumber}
              value={sellerOrderNumber}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={3}>
            <MultiSelectSearch
              ref={buyerRef}
              displayAttribute={DISPLAY_ATTRIBUTE.buyerName}
              label="Customer name"
              onSearch={(searchInput) =>
                getSearchedData(searchInput, DISPLAY_ATTRIBUTE.buyerName)
              }
              // setIsDirty={setIsDirtBuyer}
            />
          </Grid>
          <Grid item xs={3}>
            <MultiSelectSearch
              ref={deliveryStateRef}
              displayAttribute={DISPLAY_ATTRIBUTE.deliveryState}
              label="Delivery state"
              onSearch={(searchInput) =>
                getSearchedData(searchInput, DISPLAY_ATTRIBUTE.deliveryState)
              }
              // setIsDirty={setIsDirtyDeliveryState}
            />
          </Grid>
          {toggleFilter && (
            <>
              <Grid item xs={3}>
                <MultiSelectSearch
                  ref={sellerNameRef}
                  displayAttribute="companyName"
                  label="Seller name"
                  onSearch={(searchInput) =>
                    getSearchedData(searchInput, DISPLAY_ATTRIBUTE.sellerName)
                  }
                  // setIsDirty={setIsDirtySellerName}
                />
              </Grid>
              <Grid item xs={3}>
                <MultiSelectDropdown
                  ref={businessUnitRef}
                  itemList={BUSINESS_TYPES}
                  displayAttribute={"state"}
                  label={"Business unit"}
                  isListOfObjects={true}
                  // setIsDirty={setIsOrderTypeSelected}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Customer order number"
                  size="small"
                  onChange={handleOrderNumber}
                  value={orderNumber}
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={3}>
                <MultiSelectSearch
                  ref={sellerStateRef}
                  displayAttribute={DISPLAY_ATTRIBUTE.sellerState}
                  label="Seller state"
                  onSearch={(searchInput) =>
                    getSearchedData(searchInput, DISPLAY_ATTRIBUTE.sellerState)
                  }
                  // setIsDirty={setIsDirtyDeliveryState}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid
          container
          xs={4}
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={3.5}>
            <ButtonV1
              onClick={handleSelectedFilter}
              title="Submit"
              color="primary"
              variant="contained"
            />
          </Grid>
          <Grid item xs={3.5}>
            <ButtonV1
              onClick={handleReset}
              title="Reset"
              color="primary"
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={5}
            flex={1}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontWeight: 600,
                cursor: "pointer",
              }}
              onClick={handleToggleFilter}
            >
              {toggleFilter ? "Show less filter" : "Show advance filter"}
            </Typography>
            {toggleFilter ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Grid>
        </Grid>
      </Grid>
    </BoxShadow>
  );
};

export default Filter;
