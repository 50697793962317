import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Tab, Tabs, Typography, useTheme } from "@mui/material";

import CreditCustomers from "pages/CreditUpdate/CreditCustomers";
import CreditApprove from "./CreditApproval";
import PendingApproval from "./PendingApproval";
import BulkUpload from "./BulkUpload";

import { userModule } from "constants";
import CREDIT_UPDATE from "./constants";

/**
 * @description Credit workflow page
 */
const CreditUpdate = () => {
  const theme = useTheme();
  const taggedPaymentRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();

  const [tab, setTab] = useState();
  const userModules = useSelector((state) => state.userData.modules);

  const isCreditApprovalUser = useMemo(
    () => userModules?.some(({ name }) => name === userModule.creditApprover),
    [userModules],
  );

  /**
   * @description update change in url params
   */
  const handleUpdateQueryParam = (tab) => {
    searchParams.set("tab", tab);
    setSearchParams(searchParams);
  };

  const handleTabChange = useCallback((_, newValue) => {
    setTab(newValue);
    handleUpdateQueryParam(newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tabType = searchParams.get("tab");

    if (
      !tabType ||
      (tabType === CREDIT_UPDATE.tab.creditApproval && !isCreditApprovalUser)
    ) {
      setTab(CREDIT_UPDATE.tab.creditCustomer);
    } else {
      setTab(tabType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * update query param on tab change
   */
  useEffect(() => {
    if (tab) {
      handleUpdateQueryParam(tab);
    }
  }, [tab]);

  /**
   * @description get selected tab view
   */
  const getTabView = () => {
    switch (tab) {
      case CREDIT_UPDATE.tab.creditCustomer: {
        return <CreditCustomers ref={taggedPaymentRef} />;
      }
      case CREDIT_UPDATE.tab.creditApproval: {
        return <CreditApprove />;
      }
      case CREDIT_UPDATE.tab.pendingApproval: {
        return <PendingApproval />;
      }
      case CREDIT_UPDATE.tab.bulkUpload: {
        return <BulkUpload />;
      }
    }
  };

  return (
    <Box padding={3}>
      <Typography variant="h5">Credit workflow</Typography>
      <Box sx={{ borderBottom: 1, borderColor: "lightgrey", marginBottom: 5 }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          sx={{
            ">.MuiTabs-scroller": {
              ">.MuiTabs-indicator": {
                backgroundColor: theme.palette.common.black,
              },

              ">.MuiTabs-flexContainer>.Mui-selected": {
                fontWeight: 600,
                color: theme.palette.common.black,
              },
            },
          }}
        >
          <Tab
            label={CREDIT_UPDATE.tab.creditCustomer}
            value={CREDIT_UPDATE.tab.creditCustomer}
          />
          {isCreditApprovalUser && (
            <Tab
              label={CREDIT_UPDATE.tab.creditApproval}
              value={CREDIT_UPDATE.tab.creditApproval}
            />
          )}

          <Tab
            label={CREDIT_UPDATE.tab.pendingApproval}
            value={CREDIT_UPDATE.tab.pendingApproval}
          />
          <Tab
            label={CREDIT_UPDATE.tab.bulkUpload}
            value={CREDIT_UPDATE.tab.bulkUpload}
          />
        </Tabs>
      </Box>
      <Box sx={{ p: tab === CREDIT_UPDATE.tab.bulkUpload ? 0 : 3 }}>
        {getTabView()}
      </Box>
    </Box>
  );
};

export default CreditUpdate;
