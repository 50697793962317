import { Box, Typography } from "@mui/material";

import OrderListView from "./OrderListView";

const ZohoOrderView = () => {
  return (
    <Box p={6}>
      <Typography variant="h2">Zoho order view</Typography>
      <OrderListView />
    </Box>
  );
};

export default ZohoOrderView;
