import { Box, Popover, TextField, useTheme } from "@mui/material";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCallback, useEffect, useState } from "react";
import { ButtonV1 } from "components";

const FieldBox = ({ invoiceId, onChange }) => {
  const theme = useTheme();
  return (
    <TextField
      value={invoiceId}
      size="small"
      placeholder="Enter original invoice no."
      onChange={({ target }) => onChange(target.value)}
      InputProps={{
        endAdornment: invoiceId ? (
          <DeleteIcon
            onClick={() => onChange(null)}
            style={{
              color: theme.palette.text.secondary,
              width: 20,
              height: 20,
            }}
          />
        ) : (
          <></>
        ),
      }}
    />
  );
};

const OriginalInvoiceId = ({ item = [], setValue, yupName }) => {
  const theme = useTheme();
  const [invoiceList, setInvoiceList] = useState([]);

  const [anchorEl, setAnchorEl] = useState();

  const handleInvoicePopperClose = useCallback(() => {
    setAnchorEl(null);
    setValue(yupName, invoiceList);
  }, [invoiceList]);

  const addNewInvoice = useCallback(
    () => setInvoiceList((prev) => [...prev, ""]),
    [],
  );

  const handleInvoicePopperOpen = useCallback(
    (e) => {
      if (!invoiceList.length) {
        addNewInvoice();
      }
      setAnchorEl(e?.currentTarget);
    },
    [invoiceList],
  );

  const handleInput = (value, idIndex) => {
    if (!value) {
      setInvoiceList((prev) => {
        if (prev.length === 1) return [""];
        else return prev.filter((_id, index) => index !== idIndex);
      });
    } else {
      setInvoiceList((prev) =>
        prev.map((id, index) => (index !== idIndex ? id : value)),
      );
    }
  };

  useEffect(() => {
    setInvoiceList(item);
    setValue(yupName, item);
  }, [item]);

  return (
    <>
      <TextField
        label="Original Invoice no."
        value={invoiceList?.[0] || ""}
        onClick={handleInvoicePopperOpen}
        size="small"
        sx={{ input: { cursor: "pointer" }, width: "100%" }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <OpenInFullIcon
              style={{
                color: theme.palette.text.secondary,
                width: 20,
                height: 20,
              }}
            />
          ),
        }}
      />
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleInvoicePopperClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box display={"flex"} flexDirection={"column"} gap={3} padding={2}>
          {invoiceList?.map((invoice, index) => (
            <FieldBox
              onChange={(value) => handleInput(value, index)}
              invoiceId={invoice}
              key={index}
            />
          ))}
          <Box display={"flex"}>
            <ButtonV1
              size="small"
              variant="text"
              onClick={addNewInvoice}
              title={"Add another"}
              textStyle={{ flex: 1, justifyContent: "flex-start" }}
            />
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default OriginalInvoiceId;
