import {
  Box,
  Table,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

const TableRowWithError = ({ message, onClose, id, length, sx }) => {
  const theme = useTheme();
  return (
    <Table sx={sx}>
      <TableRow>
        <TableCell
          colSpan={length}
          sx={{
            paddingX: 5,
            paddingY: 2,
            background: theme.palette.error.light,
          }}
        >
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography color={theme.palette.error.dark}>{message}</Typography>
            {!!onClose && (
              <CloseIcon
                onClick={() => onClose(id)}
                sx={{ color: theme.palette.error.dark, width: 18 }}
              />
            )}
          </Box>
        </TableCell>
      </TableRow>
    </Table>
  );
};

export default TableRowWithError;

TableRowWithError.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  length: PropTypes.number,
  sx: PropTypes.object,
};
