import { useEffect, useState } from "react";
import { Attachment } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  IconButton,
  TextareaAutosize,
  Typography,
  useTheme,
} from "@mui/material";
import { ButtonV1, ModalBox } from "components";
import { useToaster } from "hooks";
import { getCall_v2 } from "services";
import { DO_STATUS } from "../constant";
import { error } from "constants";

const ApproveModal = ({ open, handleModal, modalData, handleStatusChange }) => {
  const theme = useTheme();
  const [fileUrl, setFileUrl] = useState();
  const triggerToaster = useToaster();
  const { control } = useForm({
    mode: "onTouched",
  });

  const onLinkClick = (link) => {
    if (!link) {
      triggerToaster("Unable to open document", error);
    } else {
      window.open(link, "_blank");
    }
  };

  const getSellerFile = async () => {
    try {
      if (modalData) {
        const { data } = await getCall_v2(
          `/oms/plant-supply/view/do/short-close/file`,
          {
            psIdentifier: modalData?.psIdentifier,
            lineItemId: modalData?.lineItemId,
          },
        );
        setFileUrl(data);
      }
    } catch (err) {
      triggerToaster(
        err?.data?.title ?? "Error occured while fetching file",
        error,
      );
    }
  };

  useEffect(() => {
    if (open) {
      getSellerFile();
    }
  }, [open]);

  return (
    <ModalBox open={open} onCloseModal={handleModal}>
      <Box style={{ marginBottom: 8 }}>
        <Typography fontWeight={600} fontSize={20}>
          Approve DO short closure
        </Typography>
      </Box>
      <Divider style={{ marginBottom: 20 }} />
      <Box
        style={{
          background: theme.palette.info.lightest,
          padding: 16,
          marginBottom: 18,
        }}
      >
        <Typography fontWeight={600}>
          Review the seller confirmation before approving
        </Typography>
        <IconButton disableRipple={true} onClick={() => onLinkClick(fileUrl)}>
          <Attachment
            style={{
              color: theme.palette.primary.main,
              transform: "rotate(-45deg)",
            }}
          />
          <Typography
            style={{
              color: theme.palette.primary.main,
              fontSize: 14,
              textDecoration: "underline",
            }}
          >
            View seller confirmation
          </Typography>
        </IconButton>
      </Box>
      <Box marginBottom={3}>
        <Controller
          control={control}
          name="=comment"
          defaultValue={
            modalData?.remarks ? modalData?.remarks : "No comments to display"
          }
          render={({ field: { value, onChange } }) => (
            <>
              <TextareaAutosize
                style={{
                  borderRadius: 8,
                  border: `1px solid ${theme.palette.grey[400]}`,
                  padding: 8,
                  fontFamily: "inherit",
                  resize: "none",
                  width: "100%",
                }}
                placeholder="Enter comments"
                value={value}
                disabled
                onChange={onChange}
                minRows={8}
                maxRows={8}
              />
            </>
          )}
        />
      </Box>
      <Box display={"flex"} gap={2} justifyContent={"flex-end"}>
        <ButtonV1
          title={"Cancel"}
          variant="outlined"
          onClick={() => {
            handleModal();
          }}
        />
        <ButtonV1
          title={"Approve"}
          onClick={() => handleStatusChange(DO_STATUS.approved)}
        />
      </Box>
    </ModalBox>
  );
};

ApproveModal.propTypes = {
  modalData: PropTypes.object,
  handleModal: PropTypes.func,
  handleStatusChange: PropTypes.func,
  open: PropTypes.bool,
};

export default ApproveModal;
