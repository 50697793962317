import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import { Grid, Box, IconButton } from "@mui/material";
import { RemoveCircle } from "@mui/icons-material";
import PropTypes from "prop-types";

import { ButtonV1, DropdownV1, TextField } from "components";
import { SO_TYPE } from "../constant";
import { useToaster } from "hooks";
import { success, error } from "constants";
import { postCall_v2 } from "services";

//No. of so lineItems that can be created
const MAX_SO_LINEITEM = 10;

const SoInputFields = forwardRef(({ lineItemData = [], fetchSoList }, ref) => {
  const [lineItemSoData, setLineItemSoData] = useState();
  const { control, getValues } = useForm({
    mode: "onTouched",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "soLineItemList",
  });
  const triggerToaster = useToaster();

  /**
   * @description add a new row of SO lineItems
   */
  const addSoLineItem = () => {
    if (fields && fields.length < MAX_SO_LINEITEM) {
      const so_Obj = { soNumber: "", soLineItemNumber: "", soType: "" };
      append(so_Obj);
    } else {
      triggerToaster(`Max ${MAX_SO_LINEITEM} SO lineitems allowed`, error);
    }
  };

  /**
   * @description removes a single SolineItem row at orderLineItem level
   * @param {*} lineItemId which is soLineItem index in the array
   */
  const removeSoLineItem = async (lineItemIdIndex) => {
    const values = getValues();
    const payload = values?.soLineItemList[lineItemIdIndex];
    if (payload?.id) {
      try {
        await postCall_v2(`/oms/line-item/deleteSoMapping`, payload);
        triggerToaster(`Deleted SO lineitem successfully`, success);
        fetchSoList();
      } catch (err) {
        console.error(`Unable to delete SO lineitem ${err}`);
        triggerToaster(`Unable to delete SO`, error);
      }
    } else {
      remove(lineItemIdIndex);
    }
  };

  /**
   * @description groups soLineItem values based on the index it is stored at
   * @returns formatted data of soLineItem for a unique orderLineItem
   */

  const mapSoLineItems = () => {
    const formValues = getValues();
    const SOLineItemsFormValue = Object.values(formValues?.soLineItemList);
    return SOLineItemsFormValue;
  };

  /**
   *@description prevent input field negative and e
   */
  const negativeCheck = (evt) => {
    if (evt.key === "e" || evt.key === "-") {
      evt.preventDefault();
    }
  };

  useEffect(() => {
    if (lineItemData) {
      setLineItemSoData(lineItemData);
    }
  }, [lineItemData]);

  useEffect(() => {
    remove();
    lineItemSoData?.forEach((ele) => {
      append(ele);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineItemSoData]);

  useImperativeHandle(ref, () => ({
    getSoInputs: () => mapSoLineItems(),
  }));

  return (
    <Box as="form">
      {!!fields.length && (
        <Grid wrap={"nowrap"} mb={2} columnSpacing={30} container>
          <Grid item> SO number</Grid>
          <Grid item> SO line item</Grid>
          <Grid item>SO type</Grid>
        </Grid>
      )}
      {fields?.map((lineItem, index) => {
        const lineItemKey = index;
        return (
          <>
            <Grid
              wrap={"nowrap"}
              // useFieldArray provides a unique id to every field value
              key={lineItem.id}
              container
              mb={2}
              columnSpacing={2}
            >
              <Grid item>
                <Controller
                  control={control}
                  name={`soLineItemList.${lineItemKey}.soNumber`}
                  defaultValue={lineItem?.soNumber}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      size="small"
                      onChange={onChange}
                      value={value}
                      inputProps={{ min: 0 }}
                      type="number"
                      onKeyDown={negativeCheck}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  control={control}
                  name={`soLineItemList.${lineItemKey}.soLineItemNumber`}
                  defaultValue={lineItem?.soLineItemNumber}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      size="small"
                      onChange={onChange}
                      value={value}
                      inputProps={{ min: 0 }}
                      type="number"
                      onKeyDown={negativeCheck}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  control={control}
                  name={`soLineItemList.${lineItemKey}.soType`}
                  defaultValue={lineItem?.soType}
                  render={({ field: { value, onChange } }) => (
                    <DropdownV1
                      size="small"
                      onChange={onChange}
                      itemList={SO_TYPE}
                      listType="constants"
                      value={value}
                      menuStyle={{ minWidth: 120 }}
                    />
                  )}
                />
              </Grid>
              <IconButton
                onClick={() => removeSoLineItem(lineItemKey)}
                sx={{ padding: 0 }}
                disableRipple={true}
              >
                <RemoveCircle color="primary" />
              </IconButton>
            </Grid>
          </>
        );
      })}

      <ButtonV1
        title={fields.length ? "Add another SO" : "Add SO"}
        variant="text"
        size="small"
        onClick={addSoLineItem}
        boxStyle={{ justifyContent: "flex-start" }}
      />
    </Box>
  );
});
SoInputFields.propTypes = {
  lineItemData: PropTypes.array.isRequired,
  fetchSoList: PropTypes.func,
};
export default SoInputFields;
