import React from "react";
import ReactDOM from "react-dom/client";
import Container from "@mui/material/Container";
import { styled } from "@mui/system";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@mui/material/styles";

import App from "./App";
import { Toaster } from "components";
import ErrorBoundary from "./ErrorBoundary";
import { store, persistor } from "./dataStore";
import theme from "./themeProvider";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

const queryClient = new QueryClient();
// if (process.env.NODE_ENV === "development") {
//   makeServer({ environment: "development" });
// }
const root = ReactDOM.createRoot(document.getElementById("root"));

const AppContainer = styled(Container)(() => ({
  "&.MuiContainer-root": {
    maxWidth: "unset",
    padding: 5,
  },
}));

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <ErrorBoundary>
                <AppContainer>
                  <Toaster />
                  <App />
                </AppContainer>
              </ErrorBoundary>
            </ThemeProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
