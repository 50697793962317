import { useCallback, useState } from "react";
import { Chip, Stack, Typography, useTheme } from "@mui/material";

import customerProfile from "../constants";

const { profileStatus } = customerProfile;

const QuickFilter = ({ passQuickFilter }) => {
  const [selected, setSelected] = useState("");
  const theme = useTheme();

  const handleClick = useCallback((event) => {
    setSelected(event?.target?.innerText);
    passQuickFilter(event?.target?.innerText);
  }, []);

  return (
    <Stack direction="row" spacing={2} py={4}>
      <Typography
        sx={{
          marginTop: 1,
          fontSize: 16,
          marginRight: 2,
          color: theme.palette.grey["A300"],
        }}
      >
        Quick filters:
      </Typography>
      {Object.values(profileStatus)?.map((value) => (
        <Chip
          style={{
            borderRadius: 4,
            border:
              value === selected && `1px solid ${theme.palette.grey["A300"]}`,
          }}
          label={value}
          variant={value === selected ? "outlined" : "filled"}
          onClick={handleClick}
        />
      ))}
    </Stack>
  );
};

export default QuickFilter;
