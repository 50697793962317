import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Box } from "@mui/material";

import { ExportCta } from "components";
import PaymentTable from "./PaymentTable";
import TaggedPaymentHeader from "./TaggedPaymentHeader";

import { useFetchData, useToaster } from "hooks";
import { postCall, getCall_v2 } from "services";

import { error, success } from "constants";
import { IncomingPayment } from "../constants";
import { downloadCsv } from "utils";

const { PAYMENT_ACTION } = IncomingPayment;
const paymentsPerPage = 25;

const TaggedPayment = forwardRef((_, ref) => {
  const [taggedFilters, setTaggedFilters] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [updatePayments, setUpdatePayment] = useState([]);

  const triggerToaster = useToaster();

  const filterRef = useRef(null);

  useImperativeHandle(ref, () => ({
    triggerTaggedPaymentApi() {
      if (!page && !taggedFilters) {
        setIsLoading(true);
        fetchTaggedPayments();
      } else {
        setPage(0);
        setTaggedFilters("");
      }
      filterRef.current.resetFilter();
    },
  }));

  const taggedPaymentMasterApi = `oms/payment/incomingPayment?limit=${paymentsPerPage}&offSet=${
    page * paymentsPerPage
  }&type=CREDIT&state=CLOSED&category=BUYER${taggedFilters}`;

  const pauseLoader = () => setIsLoading(false);

  const { refetch: fetchTaggedPayments, data: taggedPaymentsData } =
    useFetchData(
      "tagged-payments",
      taggedPaymentMasterApi,
      pauseLoader,
      pauseLoader,
    );

  // trigger on Page number change and filterChange
  useEffect(() => {
    setIsLoading(true);
    fetchTaggedPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, taggedFilters]);

  const handleFilterApi = useCallback((filter) => {
    const TAGGED_PAYMENT_FILTERS_MAP = {
      Status: "status",
      "Buyer GST": "buyer_gst",
      "Buyer company": "buyer_company_name",
      "Transaction type": "transaction_type",
      Action: "action",
      "Transaction ID": "transaction_id",
    };
    const searchType = TAGGED_PAYMENT_FILTERS_MAP[filter?.searchType];
    setTaggedFilters(
      `&searchKey=${searchType}&searchValue=${filter?.searchValue}`,
    );

    setPage(0);
  }, []);

  const showToaster = (message, variant) => triggerToaster(message, variant);

  const handleVerifyPayment = async ({ transactionReference, notes }) => {
    setUpdatePayment((prev) => [...prev, transactionReference]);
    const payload = {
      reference: transactionReference,
      notes: { ...notes, paymentAction: PAYMENT_ACTION.VERIFIED },
    };

    const data = await postCall(
      "oms/payment/paymentVerify",
      payload,
      {},
      () => {
        showToaster("Something went wrong. Try again", error);
      },
    );
    setUpdatePayment((prev) =>
      prev.filter((id) => id !== transactionReference),
    );
    if (data) {
      setIsLoading(true);
      fetchTaggedPayments();
      showToaster("Payment is verified", success);
    }
  };

  const handlePageChange = useCallback((_event, pageNumber) => {
    setPage(pageNumber);
  }, []);

  /**
   * @description - download tagged payments csv
   */
  const downloadTaggedPaymentsCSV = async () => {
    try {
      const { data } = await getCall_v2(
        `oms/payment/download/incomingPayment?type=CREDIT&state=CLOSED&category=BUYER${taggedFilters}`,
      );
      triggerToaster(`Downloaded tagged payments successfully`, success);
      downloadCsv(data, `Tagged_payments`);
    } catch (err) {
      console.error(`Error in download, ${err}`);
      triggerToaster(`Please try again later`, error);
    }
  };

  return (
    <>
      <TaggedPaymentHeader onTriggerFilter={handleFilterApi} ref={filterRef} />
      <Box display={"flex"} sx={{ flex: 1, justifyContent: "flex-end" }}>
        <ExportCta onExport={downloadTaggedPaymentsCSV} />
      </Box>
      <PaymentTable
        isLoading={isLoading}
        data={taggedPaymentsData?.data?.result ?? []}
        rowsPerPage={paymentsPerPage}
        pageNumber={page}
        totalCount={taggedPaymentsData?.data?.totalCount ?? 0}
        onPageChange={handlePageChange}
        onVerify={handleVerifyPayment}
        updatePayments={updatePayments}
      />
    </>
  );
});

export default TaggedPayment;
