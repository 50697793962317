import { useState, useEffect, useCallback, useMemo } from "react";
import { Box } from "@mui/material";

import { Filters, TransactionFilter } from "../components";
import CreditNotesAndSellerReturn from "./CreditNotesAndSellerReturn";
import RefundAndCashback from "./RefundAndCashback";
import InvoiceSeller from "./InvoiceSeller";
import InternalFundTransfer from "./InternalFundTransfer";

import { FinancePayouts } from "../constants";
import { formatFiltersData } from "../makeData";
import OrderBlock from "./OrderBlock";
import { getCall_v2 } from "services";
import useFetchParam from "../useFetchParam";

const {
  transactionFilter: {
    INVOICE_SELLER_PAYOUTS,
    CREDIT_NOTE_SALES_RETURN,
    INTERNAL_FUND_TRANSFER,
    ORDER_BLOCK,
    REFUND_CASHBACK,
  },
  financeTab,
  queryFilters,
} = FinancePayouts;

const PendingView = ({ transactionType, filters }) => {
  if (!transactionType) {
    return;
  }

  switch (transactionType) {
    case CREDIT_NOTE_SALES_RETURN:
      return (
        <CreditNotesAndSellerReturn
          transactionType={transactionType}
          filters={filters}
        />
      );
    case INVOICE_SELLER_PAYOUTS:
      return (
        <InvoiceSeller transactionType={transactionType} filters={filters} />
      );
    case INTERNAL_FUND_TRANSFER:
      return <InternalFundTransfer filters={filters} />;
    case ORDER_BLOCK:
      return <OrderBlock filters={filters} />;
    case REFUND_CASHBACK:
      return (
        <RefundAndCashback
          transactionType={transactionType}
          filters={filters}
        />
      );
    default:
      console.warn("out of option", transactionType);
      break;
  }
};

const PendingTab = () => {
  const { fetchParams, updateParams } = useFetchParam();
  const [dropdownList, setDropdownList] = useState([]);

  const transactionType = useMemo(
    () =>
      FinancePayouts.transactionFilter[
        fetchParams?.[queryFilters.transactionType]
      ],
    [fetchParams],
  );

  const filterParams = useMemo(() => {
    const {
      [queryFilters.tab]: tab,
      [queryFilters.transactionType]: type,
      ...filter
    } = fetchParams;

    return filter;
  }, [fetchParams]);

  const getDropdownList = useCallback(async (transactionTypeParams) => {
    if (transactionTypeParams === ORDER_BLOCK)
      try {
        const res = await getCall_v2("/oms/credit/getAllCreditInstrumentList");

        setDropdownList(res.data);
      } catch (error) {
        console.error("Unable to fetch credit instrument");
      }
  }, []);

  /**
   * @description invoked on submitting filters
   */
  const onSubmit = (filters) => {
    const formattedFiltersData = formatFiltersData(
      filters,
      financeTab.pending,
      transactionType,
    );
    updateParams({ param: formattedFiltersData });
  };

  /**
   * @description fetch pending payouts on page change
   */

  const onReset = () => {
    const param = {
      [queryFilters.tab]: financeTab.pending,
      [queryFilters.transactionType]:
        fetchParams?.[queryFilters.transactionType],
    };

    updateParams({ param, appendWithExisting: false });
  };

  useEffect(() => {
    getDropdownList(transactionType);
  }, [transactionType]);

  return (
    <>
      <TransactionFilter />
      <Box mb={4}>
        <Filters
          onSubmit={onSubmit}
          financeTab={fetchParams?.[queryFilters.tab]}
          hideDateRange={
            transactionType === INVOICE_SELLER_PAYOUTS ||
            transactionType === REFUND_CASHBACK
              ? false
              : true
          }
          onReset={onReset}
          transactionType={transactionType}
          dropdownList={dropdownList}
        />
      </Box>
      <PendingView transactionType={transactionType} filters={filterParams} />
    </>
  );
};

export default PendingTab;
