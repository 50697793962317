import { CloseSharp } from "@mui/icons-material";
import { Drawer, Box, Typography } from "@mui/material";

import { ButtonV1 } from "components";

const RightDrawer = ({
  icon,
  heading,
  children,
  toggleDrawer,
  isDrawerOpen,
}) => {
  const closeDrawer = () => toggleDrawer(false);

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={closeDrawer}
      PaperProps={{ style: { width: "40%" } }}
    >
      <Box pl={8} pr={8} pt={16}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mb={10}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Box mr={2}>{icon}</Box>
            <Typography variant="h4" m={0}>
              {heading}
            </Typography>
          </Box>
          <ButtonV1
            startIcon={<CloseSharp fontSize="small" />}
            onClick={closeDrawer}
            title="Close"
            variant="text"
            size="small"
          />
        </Box>
        {children}
      </Box>
    </Drawer>
  );
};

export default RightDrawer;
