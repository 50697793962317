import { useEffect, useMemo, useState } from "react";

import { useFetchData } from "hooks";

import CreditNotesTable from "./CreditNotesTable";
import { getDateRange } from "utils";

const baseUrl = "oms/off-oms-payout/credit-and-sales-note/historical";
const limit = 50;
const { startDate, endDate } = getDateRange();

const CreditNotesAndSellerReturn = ({ filters }) => {
  const [page, setPage] = useState(0);

  const pendingCreditParams = useMemo(() => {
    return {
      limit,
      minTimestamp: startDate,
      maxTimestamp: endDate,
      ...filters,
    };
  }, [filters]);

  const {
    refetch: getHistoricalPayouts,
    data: historicalPayouts,
    isFetching,
  } = useFetchData("historical-Credit-notes", baseUrl, null, null, {
    ...pendingCreditParams,
    offset: page * limit,
  });

  /**
   * @description fetch history payouts during page load
   */
  useEffect(() => {
    if (page) {
      setPage(0);
    } else {
      getHistoricalPayouts();
    }
  }, [pendingCreditParams]);

  useEffect(() => {
    getHistoricalPayouts();
  }, [page]);

  /**
   * @description Handle pagination
   */
  const handlePageChange = (_event, pageNumber) => {
    setPage(pageNumber);
  };

  return (
    <>
      <CreditNotesTable
        payoutData={historicalPayouts?.data ?? {}}
        isLoading={isFetching}
        page={page}
        onPageChange={handlePageChange}
        filters={pendingCreditParams}
      />
    </>
  );
};

export default CreditNotesAndSellerReturn;
