import { useCallback, useMemo } from "react";
import {
  Box,
  TextField,
  useTheme,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { ButtonV1, DropdownV1 } from "components";
import { Controller, useForm } from "react-hook-form";
import CREDIT_UPDATE from "../constants";

const Filter = ({ onSumbit, onReset, isPendingApproval }) => {
  const theme = useTheme();

  const { control, handleSubmit, reset, watch } = useForm({
    mode: "onTouched",
  });

  const searchValue = watch(CREDIT_UPDATE.searchFilter.searchValue);
  const searchStatus = watch(CREDIT_UPDATE.searchFilter.searchStatus);
  const isDirty = useMemo(() => {
    if (isPendingApproval && (searchStatus || searchValue)) {
      return true;
    } else if (searchValue) {
      return true;
    } else {
      return false;
    }
  }, [searchStatus, searchValue]);

  const handleReset = useCallback(() => {
    reset({
      [CREDIT_UPDATE.searchFilter.searchType]: isPendingApproval
        ? ""
        : CREDIT_UPDATE.searchTypeList[0].value,
      [CREDIT_UPDATE.searchFilter.searchValue]: "",
      [CREDIT_UPDATE.searchFilter.searchStatus]: false,
    });
    onReset();
  }, []);

  return (
    <Box
      p={4}
      mb={4}
      border={`1px solid ${theme.palette.grey["A100"]}`}
      display={"flex"}
      gap={3}
      as="form"
      onSubmit={handleSubmit(onSumbit)}
    >
      <Controller
        control={control}
        name={CREDIT_UPDATE.searchFilter.searchType}
        defaultValue={
          isPendingApproval ? "" : CREDIT_UPDATE.searchTypeList[0].value
        }
        render={({ field: { value, onChange } }) => (
          <>
            <DropdownV1
              itemList={CREDIT_UPDATE.searchTypeList}
              value={value}
              onChange={onChange}
              placeholder={"Filter type"}
              sx={{ width: 240 }}
            />
          </>
        )}
      />
      <Controller
        control={control}
        name={CREDIT_UPDATE.searchFilter.searchValue}
        render={({ field: { value, onChange } }) => (
          <Box position={"relative"}>
            <TextField
              sx={{ width: 240 }}
              value={value}
              size="small"
              onChange={onChange}
              label="Search by filter"
            />
          </Box>
        )}
      />
      {!!isPendingApproval && (
        <FormControlLabel
          label="Pending"
          control={
            <Controller
              name={CREDIT_UPDATE.searchFilter.searchStatus}
              control={control}
              defaultValue={false}
              render={({ field: { value, onChange } }) => (
                <Checkbox checked={value} onChange={onChange} />
              )}
            />
          }
        />
      )}
      <ButtonV1 title={"Search"} type="submit" disabled={!isDirty} />
      <ButtonV1 title={"Reset"} onClick={handleReset} variant="outlined" />
    </Box>
  );
};

export default Filter;
