import { useState, forwardRef, useImperativeHandle } from "react";
import { IconButton, Menu } from "@mui/material";

const HoverModal = forwardRef(
  ({ buttonIcon, children, onOpen, isClickClose }, ref) => {
    const [anchor, setAnchor] = useState(null);

    useImperativeHandle(ref, () => ({
      handleClose: () => handleClose(),
    }));

    const handleOpen = (event) => {
      setAnchor(event.currentTarget);
      onOpen && onOpen();
    };

    const handleClose = () => {
      setAnchor(null);
    };

    return (
      <>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-haspopup="true"
          onClick={handleOpen}
          disableRipple={true}
        >
          {buttonIcon}
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchor}
          open={Boolean(anchor)}
          onClose={handleClose}
          onClick={() => isClickClose && handleClose()}
        >
          {children}
        </Menu>
      </>
    );
  },
);

export default HoverModal;
