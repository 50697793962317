import * as Yup from "yup";

export const addNewInstrumentSchema = Yup.object().shape({
  creditType: Yup.string().required("Please enter instrument name"),
  expiryDate: Yup.date().required("Please enter expiry date"),
  creditInstrumentType: Yup.string().when(["creditType"], {
    is: (creditType) => creditType !== "LC",
    then: Yup.string().required("credit program name is required."),
  }),
  penalRate: Yup.number().required("Please enter penal rate"),
  creditDueDays: Yup.number().required("Please enter credit due days"),
  sanctionedLimit: Yup.number().required("Please enter the sanctioned limit"),
  gracePeriod: Yup.number().required("Please enter the grace period"),
  advancePercentage: Yup.number()
    .min(0, "Enter a value between 0 to 100")
    .max(100, "Enter a value between 0 to 100"),
  sellerGstin: Yup.string().length(15, "GSTIN must be 15 characters"),
});

export const UpdateCreditInstrumentSchema = Yup.object().shape({
  advancePercentage: Yup.number()
    .min(0, "Enter a value between 0 to 100")
    .max(100, "Enter a value between 0 to 100"),
});
