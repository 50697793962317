import { createSlice } from "@reduxjs/toolkit";

export const selectedTab = createSlice({
  name: "selectedTab",
  initialState: "",
  reducers: {
    setTab: (state, action) => {
      return action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTab } = selectedTab.actions;

export default selectedTab.reducer;
