export const disbursementHeader = [
  "Shipment id",
  "Seller order number",
  "Amount Payable to Seller",
];

export const disbursementState = {
  pending: "PAYMENT_PENDING",
  confirmation: "PAYMENT_CONFIRM",
  success: "PAYMENT_SUCCESS",
  failed: "PAYMENT_FAILED",
  reversed: "PAYMENT_REVERSED",
  failed_str: "Payment Failed",
};

export const PAYOUT_CHANGE_DISPLAY_NAME = {
  PT_PAYOUT: "PT Payout",
  INCOME_PAYOUT: "Invoice Payout",
  HOLD_PAYOUT: "Hold Payout",
};

export const NO_PAYOUTS_MSG = "No payouts available for the order";

export const PAYMENT_REVERSED = "Payment Reversed";
