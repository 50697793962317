import { useCallback, useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";

import PaymentTable from "./PaymentTable";
import { ExportCta } from "components";
import { useFetchData, useToaster } from "hooks";

import { getCall_v2, postCall_v2 } from "services";
import { getTagApiPayload } from "./makeData";
import { error, success } from "constants";
import { downloadCsv, getDateRange } from "utils";

const UntaggedPaymentTxs = ({
  triggerTaggedPaymentApi,
  minTimestamp,
  maxTimestamp,
  title,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [buyerAccount, setBuyerAccount] = useState({});
  const triggerToaster = useToaster();

  const paymentsPerPage = 20;

  const unTaggedPaymentMasterApi = `oms/payment/incomingPayment`;

  const params = {
    limit: paymentsPerPage,
    offSet: page * paymentsPerPage,
    type: "CREDIT",
    state: "CLOSED",
    minTimestamp,
    maxTimestamp,
  };
  const pauseLoading = useCallback(() => setIsLoading(false), []);
  const [validCreateLedgers, setValidCreateLedgers] = useState([]);
  const [search, setSearch] = useState("");

  const { refetch, data: taggedPayment } = useFetchData(
    "untagged-payments",
    unTaggedPaymentMasterApi,
    pauseLoading,
    pauseLoading,
    params,
  );

  useEffect(() => {
    setIsLoading(true);
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleSearchCompany = async (searchCompany, id) => {
    setSearch(searchCompany);
    try {
      const res = await getCall_v2(
        `/oms/customer-info-rtabs/compName/v1/${searchCompany}`,
      );
      const filteredRes = res?.data?.map((item) => {
        return {
          ...item.customerInfoDTO,
          isLedgerPresent: item?.isLedgerPresent,
        };
      });
      return filteredRes;
    } catch (err) {
      console.warn(err);
    }
  };

  const handlePageChange = useCallback(
    (_event, pageNumber) => setPage(pageNumber),
    [],
  );

  const handleSetCompany = useCallback((companyData, refId) => {
    setBuyerAccount((prev) => ({ ...prev, [refId]: companyData }));
    const validCreateLedgersClone = [...validCreateLedgers];
    if (!companyData?.isLedgerPresent) {
      validCreateLedgersClone.push(refId);
      setValidCreateLedgers(validCreateLedgersClone);
    } else {
      const ledgersIdsList = [...new Set(validCreateLedgers)];
      const validLedgers = ledgersIdsList.filter((item) => item !== refId);
      setValidCreateLedgers(validLedgers);
    }
  }, []);

  const handleCheckSaveDisabled = useCallback(
    (refId) => !!buyerAccount?.[refId],
    [buyerAccount],
  );

  const updatePayment = async (payload) => {
    const { transactionReference } = payload;
    try {
      const url = "oms/payment/saveUntaggedPayment";
      await postCall_v2(url, payload);
      triggerToaster(
        `The Payment has been tagged successfully-${transactionReference}.`,
        success,
      );
      setIsLoading(true);
      refetch();
      triggerTaggedPaymentApi();
    } catch (ex) {
      console.error(ex);
      triggerToaster(
        `Update failed for Transaction-${transactionReference}. Pls try again`,
        error,
      );
    }
  };

  const handleTaggingPayment = (data) => {
    const payload = getTagApiPayload(
      data,
      buyerAccount?.[data.transactionReference],
    );
    updatePayment(payload);
  };

  const handleCreateLedger = async (data) => {
    try {
      const res = await postCall_v2(
        `/oms/customer/create-ledger?param=${search}`,
      );
      if (res) {
        triggerToaster(`Ledger created for ${search}`, success);
        const ledgersIdsList = [...new Set(validCreateLedgers)];
        const validLedgers = ledgersIdsList.filter(
          (item) => item !== data?.transactionReference,
        );
        setValidCreateLedgers(validLedgers);
        handleSearchCompany(search, data?.id);
        refetch();
      }
    } catch (err) {
      triggerToaster(`Something went wrong`, error);
    }
  };

  /**
   * @description - download untagged payments csv
   */
  const downloadUnTaggedPaymentsCSV = async () => {
    /**
     * Date range is 372 as per below=>
     * minTimestamp = [ min timestamp of old incoming payments]
     * maxTimestamp = [ max timestamp of new incoming payment]
     */
    const { endDate, startDate } = getDateRange(372);
    try {
      const { data } = await getCall_v2(
        `/oms/payment/download/incomingPayment?limit=20&offSet=0&type=CREDIT&state=CLOSED&minTimestamp=${
          startDate * 1000
        }&maxTimestamp=${endDate * 1000}`,
      );
      downloadCsv(data, `Untagged_payments`);
      triggerToaster(`Downloaded untagged payment file successfully`, success);
    } catch (err) {
      console.error(`Error in download, ${err}`);
      triggerToaster(`Please try again later`, error);
    }
  };

  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"} mb={4}>
        <Typography sx={{ fontWeight: 600, fontSize: 20 }}>{title}</Typography>
        <ExportCta onExport={downloadUnTaggedPaymentsCSV} />
      </Box>

      <PaymentTable
        isLoading={isLoading}
        data={taggedPayment?.data?.result}
        rowsPerPage={paymentsPerPage}
        pageNumber={page}
        totalCount={taggedPayment?.data?.totalCount}
        onPageChange={handlePageChange}
        onSearchCompany={handleSearchCompany}
        onSetCompanyData={handleSetCompany}
        onCheckSaveDisabled={handleCheckSaveDisabled}
        onTaggingPayment={handleTaggingPayment}
        onCreateLedger={handleCreateLedger}
        validCreateLedgers={validCreateLedgers}
      />
    </>
  );
};

export default UntaggedPaymentTxs;
