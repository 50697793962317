import {
  Box,
  Checkbox,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { InfoOutlined } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";

import { moneyFormat } from "utils";
import { FinancePayouts } from "../constants";

const {
  financeTab: { pending },
} = FinancePayouts;

const Heading = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 10,
}));

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey["A300"],
  fontSize: 14,
}));

const CustomCheckBox = ({ value, label, style }) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        ...style,
      }}
    >
      <Checkbox
        checked={!!value}
        disabled
        inputProps={{ "aria-label": "controlled" }}
      />
      <Text>{label}</Text>
    </Box>
  );
};

const DocumentAmountBreakUp = ({ data }) => {
  const {
    gstAmount = 0,
    sgstAmount,
    igstAmount,
    cgstAmount,
    payoutRequired,
    documentAmount,
    tcsTdsReverse,
    reverseTcsTds,
  } = data;
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get("tab");

  const isReverseTcsTds =
    currentTab === pending ? tcsTdsReverse : reverseTcsTds;

  return (
    <>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ fontSize: 14 }}>
          {moneyFormat(documentAmount)}
        </Typography>
        <Tooltip
          PopperProps={{
            disablePortal: true,
            sx: {
              "& .MuiTooltip-tooltip": {
                border: 1,
                borderColor: theme.palette.grey[400],
                color: theme.palette.grey["A300"],
                backgroundColor: "white",
                fontSize: 14,
                textAlign: "left",
                "& .MuiTooltip-arrow::before": {
                  color: "white",
                  border: 1,
                  borderColor: theme.palette.grey[400],
                },
              },
            },
          }}
          arrow
          placement="bottom"
          title={
            <>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 12,
                  gap: 10,
                }}
              >
                <>
                  <Heading>Document amount</Heading>
                  <Text>{moneyFormat(documentAmount)}</Text>
                </>
                <Text style={{ fontWeight: "600" }}>GST breakup</Text>
                <Grid style={{ display: "flex", gap: 24 }}>
                  <Grid item xs={6}>
                    <Heading>Base amount</Heading>
                    <Text>{moneyFormat(+documentAmount - +gstAmount)}</Text>
                  </Grid>
                  <Grid xs={6}>
                    <Heading>CGST amount</Heading>
                    <Text>{moneyFormat(cgstAmount)}</Text>
                  </Grid>
                </Grid>
                <Grid style={{ display: "flex", gap: 24 }}>
                  <Grid item xs={6}>
                    <Heading>SGST amount</Heading>
                    <Text>{moneyFormat(sgstAmount)}</Text>
                  </Grid>
                  <Grid xs={6}>
                    <Heading>IGST amount</Heading>
                    <Text>{moneyFormat(igstAmount)}</Text>
                  </Grid>
                </Grid>

                <CustomCheckBox
                  value={isReverseTcsTds}
                  label="Reverse TDC/TCS transactions?"
                  style={{ marginTop: -8, marginLeft: -8 }}
                />
                <CustomCheckBox
                  value={payoutRequired}
                  label="Payout required?"
                  style={{ marginTop: -12, marginLeft: -8 }}
                />
              </Box>
            </>
          }
        >
          <InfoOutlined
            style={{ width: 16, marginLeft: 6 }}
            color={"primary"}
          />
        </Tooltip>
      </Box>
    </>
  );
};

export default DocumentAmountBreakUp;
