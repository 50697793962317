import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";

import Oauth from "./Oauth";

const StyledBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  width: 418,
  boxShadow: "0px 0px 1px 1px #c9c9c9",
  borderRadius: 8,
  border: "1px solid #dadce0",
  minHeight: 560,
  paddingLeft: 16,
  paddingRight: 16,
});

const Header = React.memo(() => {
  return (
    <>
      <Box marginTop={12} marginBottom={12}>
        <Box
          component="img"
          sx={{
            width: "80%",
          }}
          alt="JSW logo"
          src="/assets/logo.svg"
        />
      </Box>
      <Typography fontSize={18} mb={4} style={{ display: "inline" }}>
        Please sign-in{" "}
        <Typography style={{ color: "blue", display: "inline" }}>
          ERP
        </Typography>{" "}
        using your account
      </Typography>
    </>
  );
});

const Login = () => {
  const navigation = useNavigate();
  const login = useSelector((state) => state.isLoggedIn);

  const onLogin = () => {
    navigation("/dashboard");
  };

  useEffect(() => {
    if (login.value) {
      onLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <StyledBox>
        <Header />
        <Oauth />
      </StyledBox>
    </Box>
  );
};

export default Login;
