import { forwardRef, useImperativeHandle } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { ButtonV1, DropdownV1 } from "components";
import { IncomingPayment } from "../constants";

const { TAGGED_PAYMENT_FILTERS, STATUS_VALUES, ACTION_VALUES } =
  IncomingPayment;

const status = "Status";
const action = "Action";

const TaggedPaymentHeader = forwardRef(({ onTriggerFilter }, ref) => {
  const { getValues, setValue, control, handleSubmit, reset, watch } = useForm({
    mode: "onSubmit",
  });

  useImperativeHandle(ref, () => ({
    resetFilter() {
      reset();
    },
  }));

  const handleTriggerFilter = () => {
    onTriggerFilter(getValues());
  };

  const watchSearchType = watch("searchType");
  const watchSearchValue = watch("searchValue");

  return (
    <>
      <Typography sx={{ fontWeight: 600, fontSize: 20, mb: 4 }}>
        Tagged payments
      </Typography>
      <Box
        display={"flex"}
        gap={4}
        as="form"
        onSubmit={handleSubmit(handleTriggerFilter)}
      >
        <Box display={"flex"} gap={4} width={"30%"}>
          <Controller
            control={control}
            name={"searchType"}
            render={({ field: { value, onChange } }) => (
              <DropdownV1
                placeholder="Choose search option"
                onChange={(e) => {
                  onChange(e);
                  setValue("searchValue", "");
                }}
                value={value}
                listType="constants"
                itemList={TAGGED_PAYMENT_FILTERS}
              />
            )}
          />
          {![action, status].includes(watchSearchType) ? (
            <Controller
              control={control}
              name={"searchValue"}
              render={({ field: { value, onChange } }) => (
                <TextField
                  size="small"
                  onChange={onChange}
                  disabled={!watchSearchType}
                  value={value}
                  fullWidth
                  placeholder="Search value"
                />
              )}
            />
          ) : (
            <Controller
              control={control}
              name={"searchValue"}
              render={({ field: { value, onChange } }) => (
                <DropdownV1
                  value={value}
                  onChange={onChange}
                  itemList={
                    watchSearchType === status ? STATUS_VALUES : ACTION_VALUES
                  }
                />
              )}
            />
          )}
        </Box>

        <ButtonV1
          title={"Show result"}
          disabled={!watchSearchValue}
          type="submit"
        />
      </Box>
    </>
  );
});

export default TaggedPaymentHeader;
