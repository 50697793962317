import { Box, Grid, TextField as MuiTextField, styled } from "@mui/material";
import { useCallback, useMemo } from "react";
import { Controller } from "react-hook-form";
import { INTERNAL_FUND_TRANSFER } from "./constants";
import Comment from "../components/Comment";

const ManualEntryBox = styled(Box)(({ theme }) => ({
  border: 1,
  borderStyle: "solid",
  borderColor: theme.palette.grey[900],
  borderRadius: 12,
  width: "100%",
  paddingRight: 24,
  paddingLeft: 24,
  paddingTop: 20,
  paddingBottom: 20,
}));

const TextField = styled(MuiTextField)(({ theme }) => ({
  width: "100%",
  ".MuiOutlinedInput-root.Mui-disabled": {
    background: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
  },
  ".MuiOutlinedInput-root.Mui-error": {
    fieldset: {
      borderColor: theme.palette.error.dark,
    },
  },
  label: {
    "&.Mui-error": {
      color: theme.palette.error.dark,
    },
  },
  ".MuiFormHelperText-root.Mui-error": {
    color: theme.palette.error.dark,
  },
}));

const { yupName, manualUpload } = INTERNAL_FUND_TRANSFER;

const ManualEntry = ({ unique, methods, errors, data }) => {
  const yupFormName = useMemo(() => `${yupName}.${unique}.`, [unique]);

  const formError = useMemo(
    () => errors?.[yupName]?.[unique],
    [errors, unique],
  );

  const { control, getValues, setValue } = methods;

  const handleGstinChange = useCallback(
    ({ from, to }) => {
      const fromGst =
        from ?? getValues(`${yupFormName}${manualUpload.fromGstin}`);
      const toGst = to ?? getValues(`${yupFormName}${manualUpload.toGstin}`);

      const ledgerDescription = `Transfer - ${fromGst} to ${toGst}`;
      const advice = `Transferred funds from ${fromGst} to ${toGst}`;

      setValue(
        `${yupFormName}${manualUpload.ledgerDescription}`,
        ledgerDescription,
      );
      setValue(`${yupFormName}${manualUpload.advice}`, advice);
    },
    [getValues, setValue, yupFormName],
  );

  return (
    <ManualEntryBox>
      <Grid container gap={3} style={{ display: "flex" }}>
        <Grid item xs={2}>
          <Controller
            control={control}
            name={`${yupFormName}${manualUpload.fromGstin}`}
            defaultValue={""}
            render={({ field: { value, onChange } }) => (
              <TextField
                label="From account"
                size="small"
                sx={{ width: "100%" }}
                onChange={(e) => {
                  handleGstinChange({ from: e.target.value });
                  onChange(e);
                }}
                value={value}
                error={!!formError?.[manualUpload.fromGstin]?.message}
                helperText={formError?.[manualUpload.fromGstin]?.message || ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            control={control}
            defaultValue={""}
            name={`${yupFormName}${manualUpload.toGstin}`}
            render={({ field: { value, onChange } }) => (
              <TextField
                onChange={(e) => {
                  handleGstinChange({ to: e.target.value });
                  onChange(e);
                }}
                sx={{ width: "100%" }}
                size="small"
                label="To account"
                value={value}
                error={!!formError?.[manualUpload.toGstin]?.message}
                helperText={formError?.[manualUpload.toGstin]?.message || ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            control={control}
            defaultValue={""}
            name={`${yupFormName}${manualUpload.ledgerDescription}`}
            render={({ field: { value, onChange } }) => (
              <TextField
                label="Ledger description"
                sx={{ width: "100%" }}
                size="small"
                onChange={onChange}
                value={value}
                error={!!formError?.[manualUpload.ledgerDescription]?.message}
                helperText={
                  formError?.[manualUpload.ledgerDescription]?.message || ""
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            control={control}
            defaultValue={""}
            name={`${yupFormName}${manualUpload.advice}`}
            render={({ field: { value, onChange } }) => (
              <TextField
                label="Advice"
                size="small"
                sx={{ width: "100%" }}
                onChange={onChange}
                value={value}
                error={!!formError?.[manualUpload.advice]?.message}
                helperText={formError?.[manualUpload.advice]?.message || ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={1.5}>
          <Controller
            control={control}
            defaultValue={""}
            name={`${yupFormName}${manualUpload.amount}`}
            render={({ field: { value, onChange } }) => (
              <TextField
                label="Amount"
                size="small"
                sx={{ width: "100%" }}
                onChange={onChange}
                value={value}
                error={!!formError?.[manualUpload.amount]?.message}
                helperText={formError?.[manualUpload.amount]?.message || ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={0.5}>
          <Comment
            placeholder="Enter your invoice comments"
            name={`${yupFormName}${manualUpload.comment}`}
            invoiceComments={data?.[manualUpload.comment]}
          />
        </Grid>
      </Grid>
    </ManualEntryBox>
  );
};

export default ManualEntry;
