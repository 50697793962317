import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { ButtonV1, ModalBox, Loader } from "components";

import { DetailsTab as detailsTab } from "./constants";

const { PAYOUT_CREATION_CONFIRM_MSG, CANCELLED, CANCEL, DECLINE } = detailsTab;

const PayoutCreationModal = ({
  open,
  onCloseModal,
  onConfirm,
  orderStatus,
  isShortClosePayout = false,
  isShortCloseLoader,
}) => {
  const theme = useTheme();
  const isCancelled = orderStatus === CANCELLED;
  const cancelDecliningConfirmationMsg = `You are ${
    isCancelled ? "cancelling" : "declining"
  } the order.`;

  const cancelDeclineTitle = `${isCancelled ? CANCEL : DECLINE} order`;

  const confirmationMsg = isShortClosePayout
    ? "You are short closing the order."
    : cancelDecliningConfirmationMsg;
  const title = isShortClosePayout ? "Short close" : cancelDeclineTitle;

  return (
    <ModalBox open={open} onCloseModal={onCloseModal}>
      {isShortCloseLoader ? (
        <Loader sx={{ margin: 0 }} />
      ) : (
        <Box>
          <Box
            sx={{
              borderBottom: `1px solid ${theme.palette.grey["400"]}`,
              padding: 4,
              paddingTop: 0,
            }}
          >
            <Typography fontWeight={600} fontSize={20} marginTop={-2}>
              {title}
            </Typography>
          </Box>
          <Box
            sx={{
              borderBottom: `1px solid ${theme.palette.grey["400"]}`,
              padding: 4,
            }}
          >
            <Typography>{confirmationMsg}</Typography>
            <Typography>{PAYOUT_CREATION_CONFIRM_MSG}</Typography>
          </Box>
          <Box display={"flex"} justifyContent={"center"} paddingTop={4}>
            <ButtonV1
              title={"No"}
              variant="outlined"
              onClick={() => onConfirm(false)}
              style={{ marginRight: 5 }}
            />
            <ButtonV1 title={"Yes"} onClick={() => onConfirm(true)} />
          </Box>
        </Box>
      )}
    </ModalBox>
  );
};

PayoutCreationModal.propTypes = {
  orderStatus: PropTypes.string,
  onCloseModal: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default PayoutCreationModal;
