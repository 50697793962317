import { Box, Typography, Tooltip } from "@mui/material";
import PropTypes from "prop-types";

import theme from "themeProvider";

const HoverBlock = ({ data, open, onClose, LabelChildren }) => {
  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
        sx: {
          "& .MuiTooltip-tooltip": {
            border: 1,
            borderColor: theme.palette.grey[400],
            color: theme.palette.grey["A300"],
            backgroundColor: "white",
            fontSize: 14,
            textAlign: "left",
            "& .MuiTooltip-arrow::before": {
              color: "white",
              border: 1,
              borderColor: theme.palette.grey[400],
            },
          },
        },
      }}
      onClose={onClose}
      open={open}
      placement="bottom"
      arrow
      title={
        <>
          {!!data && (
            <Box borderRadius={1} maxWidth={280} minWidth={160}>
              {Object.entries(data).map(([key, value], i) => (
                <Box key={key} pt={2} pb={2} pl={2}>
                  <Box mb={1}>
                    <Typography
                      fontSize={12}
                      color={theme.palette.text.secondary}
                      fontWeight={600}
                    >
                      {key}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize={14}>{value}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </>
      }
    >
      {LabelChildren}
    </Tooltip>
  );
};

HoverBlock.propTypes = {
  data: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  LabelChildren: PropTypes.element.isRequired,
};

export default HoverBlock;
