import { useCallback } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { ButtonV1 } from "components";
import { getCall_v2 } from "services";
import { success, error } from "constants";
import CREDIT_UPDATE from "../constants";
import { dateConvertor, moneyFormat, validateNull, getSubstring } from "utils";
import { useDownload, useToaster } from "hooks";

const LabelHead = ({ label, head, type = CREDIT_UPDATE.modalType.string }) => {
  const labelFormat = {
    [CREDIT_UPDATE.modalType.string]: validateNull,
    [CREDIT_UPDATE.modalType.date]: dateConvertor,
    [CREDIT_UPDATE.modalType.amount]: moneyFormat,
  };

  return (
    <Box display={"flex"} flexDirection={"column"} width={"30%"}>
      <Typography color={"text.disabled"} fontWeight={600} fontSize={12} noWrap>
        {head}
      </Typography>
      <Typography fontWeight={600} fontSize={14}>
        {labelFormat[type](label)}
      </Typography>
    </Box>
  );
};

const ModalInfo = ({
  order: { type, order },
  approver,
  onClick,
  onCancel,
  onMessage,
}) => {
  const isApprove = type === CREDIT_UPDATE.actionType.approve;
  const { downloadFile } = useDownload();
  const triggerToaster = useToaster();

  const handleDocDownload = useCallback(async (filePath) => {
    const fileName = "CAM document";
    try {
      const { data } = await getCall_v2(
        `/oms/credit/documents/customerCreditDocs`,
        { filePath: filePath },
        "blob",
      );
      downloadFile(data, fileName);
      triggerToaster(`Downloading ${fileName}`, success);
    } catch (err) {
      triggerToaster(`Unable to download ${fileName}`, error);
    }
  }, []);
  return (
    <>
      <Typography variant="h4" fontWeight={600}>
        {isApprove ? "Approve credit limit" : "Reject credit limit"}
      </Typography>
      <Box display={"flex"} gap={4} flexWrap={"wrap"} p={4}>
        <LabelHead
          head={CREDIT_UPDATE.tableHeader.name}
          label={order?.customerName}
        />
        <LabelHead
          head={CREDIT_UPDATE.tableHeader.customerPan}
          label={order?.customerPan}
        />
        <LabelHead
          head={CREDIT_UPDATE.tableHeader.gstin}
          label={order?.customerGstin}
        />
        <LabelHead
          head={CREDIT_UPDATE.tableHeader.instrument}
          label={`${order?.creditType} - ${order?.creditInstrument}`}
        />
        <LabelHead
          head={CREDIT_UPDATE.tableHeader.limit}
          label={order?.sanctionedLimit}
          type={CREDIT_UPDATE.modalType.amount}
        />
        <LabelHead
          head={CREDIT_UPDATE.tableHeader.expireBy}
          label={order?.expiryDate}
          type={CREDIT_UPDATE.modalType.date}
        />
        <LabelHead head={"Credit limit Reference ID"} label={order?.camId} />
        <Box>
          <Typography
            color={"text.disabled"}
            fontWeight={600}
            fontSize={12}
            noWrap
          >
            {"CAM document"}
          </Typography>
          <ButtonV1
            variant="text"
            title={getSubstring(order?.fileName, 20)}
            size="small"
            onClick={() => handleDocDownload(order?.path)}
            style={{ padding: 0, minWidth: "fit-content" }}
          />
        </Box>
        <LabelHead head={"Grace period"} label={order?.gracePeriod} />

        <LabelHead
          head={CREDIT_UPDATE.tableHeader.requestedBy}
          label={order?.requestedBy}
        />
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <TextField
          label="Comments"
          multiline
          rows={3}
          onChange={onMessage}
          placeholder="Enter the remark/comment"
          sx={{ paddingBottom: 4, width: "60%" }}
        />
      </Box>
      <Box display={"flex"} justifyContent={"center"} gap={4}>
        <ButtonV1
          size="small"
          variant="outlined"
          title={"Cancel"}
          onClick={onCancel}
        />
        <ButtonV1
          size="small"
          title={isApprove ? "Approve" : "Reject"}
          onClick={() => onClick(order?.comments)}
        />
      </Box>
    </>
  );
};

export default ModalInfo;
