import { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography, useTheme } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";

import { ButtonV1, InputField, Loader, MultiSelectSearch } from "components";
import { GroupDetailModel } from "../../DataModel";
import GroupHead from "./GroupHead";
import UserList from "./UserList";
import { deleteCall, getCall, postCall_v2, putCall } from "services";
import { RightDrawer } from "pages/UAM/components";
import { error, success } from "constants";
import { useToaster } from "hooks";

const GroupDetail = () => {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const theme = useTheme();
  const userEmailRef = useRef(null);
  const triggerToaster = useToaster();
  const [groupDetails, setGroupDetails] = useState();
  const [search, setSearch] = useState("");
  const [drawer, setDrawer] = useState(false);

  const handleUserNavigation = ({ email }) => {
    navigate(`/user-management/user-details?email=${email}`);
  };

  useEffect(() => {
    groupId && getGroupApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGroupApi = async () => {
    const getGroupDetail = getCall(
      `/auth2-service/users/by?groupId=${groupId}`,
    );
    const getAllUser = getCall("/auth2-service/user/all");
    const result = await Promise.all([getGroupDetail, getAllUser]);
    const data = new GroupDetailModel(result[0][0], result[1]);
    setGroupDetails(data);
  };

  const handleSearchResult = (event) => {
    const value = event?.target?.value || null;
    setSearch(value);
    groupDetails.getFilterResult(value);
  };

  const handleToggleDrawer = useCallback(() => setDrawer((prev) => !prev), []);

  const searchEnteredtext = async (val) => {
    const res = await getCall(
      `/auth2-service/user/search?email=${val}`,
    );
    if (res?.[0]?.users?.length) {
      const { email, id } = res?.[0].users?.[0];
      return [{ email, id }];
    } else {
      return [];
    }
  };

  const handleGroupDelete = async () => {
    const res = await deleteCall(
      `/auth2-service/group?id=${groupDetails?.groupInfo.id}`,
      {},
      {},
      () => {
        triggerToaster("Group is not deleted. Please try later", error);
      },
    );
    if (res) {
      navigate("/user-management");
      triggerToaster("Group is deleted", success);
    }
  };

  const handleDeleteUser = async ({ id: userId }) => {
    const res = await putCall(
      `/auth2-service/group/user?groupId=${groupDetails?.groupInfo.id}&userId=${userId}`,
      {},
      () => {
        triggerToaster(
          "User is not deleted from the group. Please try later",
          error,
        );
      },
    );
    if (res) {
      triggerToaster("User is deleted from the group", success);
      getGroupApi();
    }
  };

  const handleAddUser = async () => {
    const userIdList = userEmailRef?.current?.fetchValue()?.map(({ id }) => id);

    const payload = {
      userId: userIdList,
      groupId: parseInt(groupId),
    };
    try {
      const res = await postCall_v2(
        "/auth2-service/group/addUser",
        payload,
      );

      if (res.status === 200) {
        triggerToaster(res?.data, success);
        userEmailRef.current.resetValue();
        getGroupApi();
        handleToggleDrawer();
      }
    } catch (err) {
      triggerToaster("User is not added in this group", error);
    }
  };

  const handleEditGroup = async (name) => {
    const payload = {
      ...groupDetails?.groupInfo,
      name,
      userId: [],
      createdBy: "system",
    };
    const res = await putCall(
      "/auth2-service/group",
      payload,
      {},
      () => triggerToaster("Group name is not updated", error),
    );
    if (res) {
      getGroupApi();
      triggerToaster("Group name is updated", success);
    }
  };
  if (!groupDetails) {
    return <Loader />;
  }
  return (
    <Box p={3} display="flex" sx={{ flexDirection: "column", gap: 3 }}>
      <GroupHead
        groupName={groupDetails?.groupInfo.name}
        onEdit={handleEditGroup}
        onDeleteGroup={handleGroupDelete}
        onAddUser={handleToggleDrawer}
      />
      <InputField
        placeholder="Search by user"
        onChange={handleSearchResult}
        value={search}
        rest={{ width: "100%", flex: 1 }}
        valueStyle={{ width: "100%" }}
      />
      <UserList
        userList={groupDetails?.filteredUser || groupDetails?.userList}
        onDelete={handleDeleteUser}
        onViewDetail={handleUserNavigation}
      />
      <RightDrawer
        isDrawerOpen={drawer}
        heading={"Add user"}
        icon={<GroupsIcon sx={{ width: 45, height: 45 }} />}
        toggleDrawer={handleToggleDrawer}
      >
        <Typography variant="body1" mb={2}>
          Email address
          <span style={{ color: theme.palette.warning.main }}>*</span>
        </Typography>
        <MultiSelectSearch
          onSearch={searchEnteredtext}
          ref={userEmailRef}
          displayAttribute={"email"}
        />
        <Box
          sx={{ marginTop: 80, display: "flex", justifyContent: "flex-end" }}
        >
          <ButtonV1 title="Add user" onClick={handleAddUser} />
        </Box>
      </RightDrawer>
    </Box>
  );
};

export default GroupDetail;
