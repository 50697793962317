import { memo, useEffect, useState } from "react";
import { TableCell, Typography } from "@mui/material";
import { useFetchData } from "hooks";

import { TableWrapper } from "components";
import { StyledTableRow } from "CustomStyledComponents";

import { orderTableHeaders } from "./constants";

import EmptyList from "./EmptyList";

const CustomerOrder = () => {
  const [customerOrders, setCustomerOrders] = useState();
  const [orderPage, setOrderPage] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const orders = useFetchData(
    "customer-orders",
    `/oms/cart/list/converted?limit=20&offSet=${orderPage}`,
    (res) => {
      if (res) {
        const orders = res?.data;
        setCustomerOrders(orders);
        setLoading(false);
      }
    },
    () => setLoading(false),
  );

  useEffect(() => {
    setLoading(true);
    orders.refetch();
    /* eslint-disable */
  }, [orderPage]);

  const handlePagination = (event, newPage) => setOrderPage(newPage);

  return (
    <>
      <TableWrapper
        tableHeader={orderTableHeaders.map((item) => {
          return (
            <TableCell key={item}>
              <Typography>{item}</Typography>
            </TableCell>
          );
        })}
        errorMessage={
          (customerOrders?.response?.length === 0 || !customerOrders) &&
          !isLoading && <EmptyList />
        }
        tableBody={customerOrders?.response?.map((row, i) => {
          return (
            <StyledTableRow key={row.whenCreated} id={row.timestamp}>
              <TableCell>{row?.customerEmail}</TableCell>
              <TableCell>{row?.cartId}</TableCell>
              <TableCell>{row?.opportunityId}</TableCell>
              <TableCell>{row?.whenCreated?.split("T")[0]}</TableCell>
              <TableCell>{row.orderNumber}</TableCell>
            </StyledTableRow>
          );
        })}
        isPagination={true}
        page={orderPage}
        count={customerOrders?.totalCount}
        rowsPerPage={20}
        handleChangePage={handlePagination}
      />
    </>
  );
};

export default memo(CustomerOrder);
