import { Box, Typography } from "@mui/material";

import theme from "themeProvider";

const QuickFilter = ({
  label,
  value,
  selectedFilter,
  onClick,
  filterCount,
}) => {
  return (
    <Box
      m={2}
      p={2}
      onClick={() => {
        onClick(value);
      }}
      style={{
        cursor: "pointer",
        borderRadius: 4,
        width: "fit-content",
        height: 24,
        display: "flex",
        alignItems: "center",
        border: 1,
        borderStyle: "solid",
        borderColor:
          value === selectedFilter
            ? theme.palette.grey["A300"]
            : theme.palette.grey["400"],
      }}
    >
      <Typography
        fontSize={12}
        fontWeight={600}
        color={
          value === selectedFilter
            ? theme.palette.grey["A300"]
            : theme.palette.text.secondary
        }
      >
        {label}
        {!!filterCount && !!filterCount[value] && `(${filterCount[value]})`}
      </Typography>
    </Box>
  );
};

export default QuickFilter;
