import { useCallback, useEffect, useRef } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { RightDrawer, InputField, ErrorMsg } from "../components";
import { createRoleSchema } from "../TestSchema/createRoleSchema";
import { getCall, postCall_v2 } from "services";
import theme from "themeProvider";
import { useFetchData, useToaster } from "hooks";
import { error, success } from "constants";
import { createRoleModel } from "../DataModel";
import { ButtonV1, MultiSelectDropdown, MultiSelectSearch } from "components";

const FormLabel = ({ title }) => {
  return (
    <Typography variant="body1" mb={2}>
      {title}
      <span style={{ color: theme.palette.warning.main }}>*</span>
    </Typography>
  );
};

const RoleCreationDrawer = ({
  isDrawerOpen,
  handleToggleDrawer,
  getRolesData,
}) => {
  const userEmailRef = useRef(null);
  const moduleSelectionRef = useRef(null);
  const businessSelectionRef = useRef(null);
  const regionSelectionRef = useRef(null);

  const triggerToaster = useToaster();

  const {
    control,
    getValues,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(createRoleSchema),
  });

  const resetForm = useCallback(() => {
    reset();
    moduleSelectionRef.current?.resetValue();
    businessSelectionRef.current?.resetValue();
    regionSelectionRef.current?.resetValue();
    userEmailRef?.current?.resetValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    const userIdList = userEmailRef?.current?.fetchValue()?.map(({ id }) => id);

    const selectedLocationList =
      regionSelectionRef.current?.fetchSelectedObjects() || [];
    const selectedBusinessList =
      businessSelectionRef.current?.fetchSelectedObjects() || [];
    const selectedModuleList =
      moduleSelectionRef.current?.fetchSelectedObjects() || [];

    if (
      !selectedLocationList.length ||
      !selectedBusinessList.length ||
      !selectedModuleList.length ||
      !userIdList?.length
    ) {
      triggerToaster(`Please add required fields`, error);
      return;
    }

    const dataModel = createRoleModel(
      selectedLocationList,
      selectedBusinessList,
      selectedModuleList,
      getValues("roleName"),
      userIdList,
    );

    try {
      await postCall_v2("/auth2-service/roles", dataModel);
      triggerToaster("Role created successfully", success);
      resetForm();
      handleToggleDrawer();
      //invoke get roles again
      getRolesData();
    } catch (ex) {
      triggerToaster(
        ex?.data?.message ?? "Please try again, error occurred",
        error,
      );
      console.error(ex, "Exception in creating role");
    }
  };

  const searchEnteredtext = async (val) => {
    const res = await getCall(`/auth2-service/user/search?email=${val}`);
    if (res?.[0]?.users?.length) {
      const { email, id } = res?.[0].users?.[0];
      return [{ email, id }];
    } else {
      return [];
    }
  };

  const { refetch: getLocationData, data: regionList } = useFetchData(
    "region",
    "/auth2-service/region",
  );
  const { refetch: getBusinessList, data: businessUnitList } = useFetchData(
    "business",
    "/auth2-service/business-unit",
  );
  const { refetch: getModuleList, data: modulesList } = useFetchData(
    "modules",
    "/auth2-service/modules",
  );

  useEffect(() => {
    getLocationData();
    getBusinessList();
    getModuleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RightDrawer
      toggleDrawer={handleToggleDrawer}
      icon={
        <object
          data={`/assets/userRole.svg`}
          width={28}
          alt="User role icon"
          aria-label="user role icon"
        />
      }
      heading={"Create role"}
      isDrawerOpen={isDrawerOpen}
    >
      <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
        <Box display={"flex"} flexDirection={"column"}>
          <Box mb={6}>
            <Controller
              control={control}
              name="roleName"
              render={({ field: { value, onChange, onBlur } }) => (
                <InputField
                  label="Role name"
                  isRequired="true"
                  type={"text"}
                  value={value}
                  onChange={onChange}
                  highlightError={errors?.roleName?.hasOwnProperty("message")}
                  onBlur={onBlur}
                />
              )}
            />
            <ErrorMsg msg={errors?.roleName?.message} />
          </Box>
          <Divider style={{ marginBottom: 12 }} />

          <Box mb={6}>
            <Typography variant="body1" style={{ fontWeight: "bold" }} mb={4}>
              Assign access
            </Typography>

            <Box mb={4}>
              <FormLabel title={"Location"} />
              <MultiSelectDropdown
                ref={regionSelectionRef}
                itemList={regionList?.data}
                displayAttribute={"stateName"}
                label={"Select location"}
                isListOfObjects={true}
              />
            </Box>

            <Box mb={4}>
              <FormLabel title={"Business"} />
              <MultiSelectDropdown
                ref={businessSelectionRef}
                itemList={businessUnitList?.data}
                displayAttribute={"name"}
                label={"Select business"}
                isListOfObjects={true}
              />
            </Box>

            <Box mb={4}>
              <FormLabel title={"Module"} />
              <MultiSelectDropdown
                ref={moduleSelectionRef}
                itemList={modulesList?.data}
                displayAttribute={"name"}
                label={"Select module"}
                isListOfObjects={true}
              />
            </Box>
          </Box>

          <Divider style={{ marginBottom: 30 }} />

          <Box mb={8}>
            <Typography variant="body1" style={{ fontWeight: "bold" }} mb={4}>
              Assign user
            </Typography>
            <Typography variant="body1" mb={2}>
              Email address
              <span style={{ color: theme.palette.warning.main }}>*</span>
            </Typography>
            <MultiSelectSearch
              onSearch={searchEnteredtext}
              ref={userEmailRef}
              displayAttribute={"email"}
            />
          </Box>

          <Box alignSelf={"flex-end"}>
            <ButtonV1 type="submit" title={"Create role"} size="medium" />
          </Box>
        </Box>
      </Box>
    </RightDrawer>
  );
};

export default RoleCreationDrawer;
