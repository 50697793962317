import { AvatarGroup, Avatar } from "@mui/material";

import theme from "themeProvider";

const AvatarGroups = ({ userNames }) => {
  const styles = {
    color: theme.palette.grey["700"],
    width: 30,
    height: 30,
    fontSize: 12,
  };
  return (
    <AvatarGroup max={3} style={{ justifyContent: "flex-end" }}>
      {userNames[0] && (
        <Avatar
          style={{ backgroundColor: theme.palette.avatar.yellow, ...styles }}
        >
          {userNames[0]}
        </Avatar>
      )}
      {userNames[1] && (
        <Avatar
          style={{ backgroundColor: theme.palette.avatar.pink, ...styles }}
        >
          {userNames[1]}
        </Avatar>
      )}
      {userNames[2] && (
        <Avatar
          style={{ backgroundColor: theme.palette.avatar.blue, ...styles }}
        >
          {userNames[2]}
        </Avatar>
      )}
    </AvatarGroup>
  );
};

export default AvatarGroups;
