import { useEffect } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";

import { Divider } from "components";

import { useFetchData } from "hooks";
import { moneyFormat } from "utils";
import { selectedCustomerPropTypes } from "./propTypes";

const CustomViewOnly = ({
  label,
  value,
  bottomBorder = true,
  rightBorder = true,
}) => {
  const theme = useTheme();
  return (
    <Grid
      item
      md={4}
      padding={2}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRight: rightBorder && `1px solid ${theme.palette.grey["400"]}`,
        borderBottom: bottomBorder && `1px solid ${theme.palette.grey["400"]}`,
      }}
    >
      <Typography ml={8} mt={2} variant="body2">
        {label}
      </Typography>
      <Typography mr={10} mt={2} fontWeight={"bold"} variant="body2">
        {moneyFormat(value)}
      </Typography>
    </Grid>
  );
};

const CustomerBalanceDetails = ({ selectedCustomer }) => {
  const theme = useTheme();

  const { refetch: fetchLedgerSummary, data: ledgerSummary } = useFetchData(
    "fetch-ledger-summary",
    `ledger-service/api/external/v1/fetch-ledger-summary-details?gstin=${selectedCustomer?.gstin}`,
  );

  useEffect(() => {
    fetchLedgerSummary();
  }, [selectedCustomer]);

  const ledgerData = ledgerSummary?.data?.data;

  const pan = selectedCustomer?.gstin.substr(2, 10);

  return (
    <>
      <Box mt={4} mb={4} style={{ display: "flex", flexDirection: "row" }}>
        <Typography fontWeight="bold" fontSize={18}>
          {selectedCustomer?.sellerName}
        </Typography>
        <Divider />
        <Typography
          style={{
            fontSize: 18,
          }}
        >
          GSTIN:
        </Typography>
        <Typography
          style={{
            color: theme.palette.grey["1000"],
            fontSize: 18,
            marginLeft: 8,
          }}
          component="span"
        >
          {selectedCustomer?.gstin}
        </Typography>
        <Divider />
        <Typography
          style={{
            fontSize: 18,
          }}
        >
          PAN:
        </Typography>
        <Typography
          style={{
            color: theme.palette.grey["1000"],
            fontSize: 18,
            marginLeft: 8,
          }}
          component="span"
        >
          {pan}
        </Typography>
      </Box>

      <Grid
        container
        spacing={2}
        mt={2}
        style={{
          border: `1px solid ${theme.palette.grey["400"]}`,
          borderRadius: 10,
        }}
      >
        <CustomViewOnly
          label="Ledger Balance"
          value={ledgerData?.ledgerBalance}
        />
        <CustomViewOnly
          label="Total incoming payments"
          value={ledgerData?.totalIncomingPayments}
        />
        <CustomViewOnly
          label="Total cashback issued"
          value={ledgerData?.totalCashbackIssued}
          rightBorder={false}
        />
        <CustomViewOnly
          label="Blocked Amount"
          value={ledgerData?.totalBlockedAmount}
        />
        <CustomViewOnly
          label="Total Invoice Amount"
          value={ledgerData?.totalInvoiceAmount}
        />
        <CustomViewOnly
          label="Total Payout Done"
          value={ledgerData?.totalPayoutDone}
          rightBorder={false}
        />
        <CustomViewOnly label="AR Not Due" value={ledgerData?.arNotDue} />
        <CustomViewOnly
          label="Total Debit Note Amount"
          value={ledgerData?.totalDebitNoteAmount}
        />
        <Grid md={4} borderBottom={`1px solid ${theme.palette.grey["400"]}`} />
        <CustomViewOnly
          label="Usable Ledger Balance"
          value={ledgerData?.totalUsableLedgerBalance}
          bottomBorder={false}
        />
        <CustomViewOnly
          label="Total Credit Note Amount"
          value={ledgerData?.totalCreditNoteAmount}
          bottomBorder={false}
        />
      </Grid>
    </>
  );
};

export default CustomerBalanceDetails;

CustomerBalanceDetails.propTypes = {
  selectedCustomer: PropTypes.shape(selectedCustomerPropTypes).isRequired,
};
