import { useForm, Controller } from "react-hook-form";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  ButtonV1,
  DatePickerV1,
  DropdownV1,
  SearchBar,
  TextField,
} from "components";
import { ErrorMsg } from "CustomStyledComponents";
import theme from "themeProvider";

import payoutSchema from "./tests";
import { FinancePayouts, financePayoutsFilter } from "../constants";
import { getCall_v2 } from "services";
import { useEffect, useRef, useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import useFetchParam from "../useFetchParam";

const {
  payoutFilters,
  filterType: { TEXT, NUMBER, DROPDOWN, SEARCH_BAR },
} = FinancePayouts;

const SearchResultBox = ({ name, email, gstin }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={1} minWidth={180}>
      <Typography sx={{ fontWeight: 600 }}>{name}</Typography>
      <Typography variant="body2"> {email}</Typography>
      <Typography variant="body2"> {gstin}</Typography>
    </Box>
  );
};

const Filters = ({
  onSubmit,
  onReset,
  hideDateRange = false,
  transactionType,
  financeTab,
  dropdownList,
}) => {
  const [searchDetails, setSearchDetails] = useState();
  const [toggleFilter, setToggleFilter] = useState(false);

  const { fetchParams } = useFetchParam();

  const entityRef = useRef(null);

  const {
    control,
    reset,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(payoutSchema(hideDateRange)),
  });

  const unixTsToDate = (milliseconds) => new Date(milliseconds);

  /**
   * @description - onsubmit
   */
  const passFilters = () => {
    const filterObj = getValues();
    filterObj.entityName = searchDetails?.gstin;
    // add multiple invoice nos separated by comma
    filterObj.invoiceNo = filterObj.invoiceNo
      ? filterObj.invoiceNo.split(" ").join(",")
      : "";

    // Remove the empty filter
    const appliedFilter = {};
    Object.entries(filterObj).forEach((entries) => {
      if (!!entries[1]) {
        appliedFilter[entries[0]] = entries[1];
      }
    });
    onSubmit(appliedFilter);
  };

  /**
   * @description - onreset
   */
  const resetValues = () => {
    reset({ [payoutFilters.orderNumber]: "" });
    onReset();
    entityRef.current?.resetValue();
  };

  const getSearchedData = async (search) => {
    try {
      const { data } = await getCall_v2(
        `/oms/off-oms-payout/search-user/${search}`,
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  };

  const passValue = (data) => setSearchDetails(data);

  const handleToggleFilter = () => setToggleFilter((prev) => !prev);

  const { toDate, fromDate } = errors;
  const filters = financePayoutsFilter?.[financeTab]?.[transactionType];

  // Reset filters and toggle filter state when transaction type changed
  useEffect(() => {
    setToggleFilter(false);
    entityRef.current?.resetValue();
    reset();
    fetchParams?.[payoutFilters.orderNumber] &&
      setValue(
        payoutFilters.orderNumber,
        fetchParams?.[payoutFilters.orderNumber],
        { shouldDirty: true },
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionType]);

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(passFilters)}
      sx={{
        paddingY: 6,
        paddingX: 5,
        borderRadius: 2,
        border: `solid 1px ${theme.palette.grey[400]}`,
      }}
    >
      <Grid container gap={6}>
        {!hideDateRange && (
          <>
            <Grid item xs={2}>
              <Box>
                <Controller
                  control={control}
                  name={payoutFilters.fromDate}
                  defaultValue={unixTsToDate(
                    new Date().setMonth(new Date().getMonth() - 2),
                  )}
                  render={({ field: { value, onChange } }) => (
                    <DatePickerV1
                      onChange={onChange}
                      value={value}
                      inputProps={{
                        placeholder: "From",
                      }}
                    />
                  )}
                />
                <ErrorMsg sx={{ m: 0, position: "absolute" }} a>
                  {fromDate?.message || ""}
                </ErrorMsg>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Controller
                control={control}
                name={payoutFilters.toDate}
                defaultValue={
                  new Date(new Date().setDate(new Date().getDate() + 3))
                }
                render={({ field: { value, onChange } }) => (
                  <DatePickerV1
                    inputProps={{
                      placeholder: "To",
                    }}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <ErrorMsg sx={{ m: 0, position: "absolute" }}>
                {toDate?.message || ""}
              </ErrorMsg>
            </Grid>
          </>
        )}
        {financePayoutsFilter?.[financeTab]?.[transactionType]?.map(
          (filter, index) => {
            const {
              itemList,
              type,
              placeholder = "",
              listType = "constants",
              valueKey,
            } = filter;
            if (!toggleFilter && !hideDateRange && index >= 3) return null;
            return (
              <Grid item xs={2}>
                <Controller
                  control={control}
                  name={filter.name}
                  defaultValue={""}
                  render={({ field: { value, onChange } }) => {
                    switch (type) {
                      case DROPDOWN:
                        return (
                          <DropdownV1
                            onChange={onChange}
                            listType={listType}
                            valueKey={valueKey}
                            itemList={itemList ?? dropdownList}
                            placeholder={placeholder}
                            value={value}
                          />
                        );
                      case TEXT:
                      case NUMBER:
                        return (
                          <TextField
                            type={type}
                            placeholder={placeholder}
                            size="small"
                            fullWidth
                            onChange={onChange}
                            value={value}
                          />
                        );
                      case SEARCH_BAR:
                        return (
                          <SearchBar
                            resolver={getSearchedData}
                            displayAttribute="name"
                            onItemSelect={passValue}
                            renderItem={SearchResultBox}
                            placeholder={"Entity name"}
                            ref={entityRef}
                          />
                        );
                      default:
                        return "";
                    }
                  }}
                />
              </Grid>
            );
          },
        )}
        <Grid
          style={{
            display: "flex",
            width: "100%",
            marginRight: 12,
            justifyContent: "space-between",
          }}
        >
          <Grid display={"flex"} gap={6}>
            <ButtonV1
              title="Submit"
              color="primary"
              variant="contained"
              type={"submit"}
              disabled={!isDirty && !searchDetails}
            />
            <ButtonV1
              variant="outlined"
              title="Reset"
              disabled={!isDirty && !searchDetails}
              onClick={resetValues}
            />
          </Grid>
          {!hideDateRange && (
            <Grid item xs={2.5}>
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  fontWeight: 600,
                  display: "flex",
                  cursor: "pointer",
                  marginTop: 2,
                  marginLeft: 8,
                }}
                onClick={handleToggleFilter}
              >
                {toggleFilter ? "Show less filter" : "Show advance filter"}
                {toggleFilter ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

Filters.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  hideDateRange: PropTypes.bool.isRequired,
  transactionType: PropTypes.string.isRequired,
};
export default Filters;
