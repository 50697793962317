import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";

import { ButtonV1, DropdownV1, TextField } from "components";

import { filterQueries } from "../constant";

const Filter = ({ onFilter, onReset }) => {
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const { buyerOrderId, soDetails } = filterQueries;
  const { control, reset, handleSubmit, getValues, watch, resetField } =
    useForm({
      mode: "onTouched",
    });

  /**
   * @description Handle reset field
   */
  const handleReset = () => {
    reset();
    onReset();
  };

  const queryValue = watch("filterQuery");

  /**
   * @description Handle search and pass filter values
   */
  const handleSearch = () => {
    onFilter(getValues());
  };

  const handleDisabled = () => {
    if (queryValue === buyerOrderId && getValues("buyerOrderId")) {
      setButtonDisabled(false);
    } else if (queryValue === soDetails && getValues("soNumber")) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const resetFields = () => {
    resetField("soLineItemId");
    resetField("buyerOrderId");
    resetField("soNumber");
  };

  useEffect(() => {
    handleDisabled();
  }, [watch()]);

  useEffect(() => {
    resetFields();
  }, [queryValue]);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "row", gap: 6, marginBottom: 4 }}
      as="form"
      onSubmit={handleSubmit(handleSearch)}
    >
      <Controller
        control={control}
        name={"filterQuery"}
        defaultValue={""}
        render={({ field: { value, onChange } }) => (
          <DropdownV1
            sx={{ width: 200 }}
            size="small"
            listType="array"
            itemList={[buyerOrderId, soDetails]}
            placeholder="Choose filter"
            onChange={onChange}
            value={value}
          />
        )}
      />

      {queryValue === buyerOrderId && (
        <Controller
          control={control}
          name={"buyerOrderId"}
          defaultValue={""}
          render={({ field: { value, onChange } }) => (
            <TextField
              size="small"
              placeholder="Buyer’s Order ID"
              onChange={onChange}
              value={value}
            />
          )}
        />
      )}
      {queryValue === soDetails && (
        <>
          <Controller
            control={control}
            name={"soNumber"}
            defaultValue={""}
            render={({ field: { value, onChange } }) => (
              <TextField
                size="small"
                placeholder="SO number"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name={"soLineItemId"}
            defaultValue={""}
            render={({ field: { value, onChange } }) => (
              <TextField
                size="small"
                placeholder="SO line item"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </>
      )}

      <ButtonV1 title={"Submit"} type="submit" disabled={isButtonDisabled} />
      <ButtonV1
        title={"Reset"}
        variant="outlined"
        disabled={!queryValue}
        onClick={handleReset}
      />
    </Box>
  );
};

export default Filter;

Filter.propTypes = {
  onFilter: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};
