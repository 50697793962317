import {
  AddIcCallOutlined,
  LocalShippingOutlined,
  MonetizationOnOutlined,
  EventAvailableOutlined,
  InventoryOutlined,
  CurrencyExchangeOutlined,
} from "@mui/icons-material";

export const tags = {
  followUps: { label: "Follow ups", icon: AddIcCallOutlined },
  shipmentsDue: { label: "Shipments due", icon: LocalShippingOutlined },
  payoutDue: { label: "Payout due", icon: MonetizationOnOutlined },
  confirmationPending: {
    label: "Confirmation pending",
    icon: EventAvailableOutlined,
  },
  openOrder: {
    label: "Open orders",
    icon: InventoryOutlined,
  },
  refundDue: {
    label: "Refunds",
    icon: CurrencyExchangeOutlined,
  },
};

export const scheduleList = ["today", "day_1_3", "day_4_7", "day_8"];

export const labelColorCode = ["#f1c40f", "#e67e23", "#f49b13", "#cf5203"];

export const summaryTag = {
  followUps: "followUps",
  shipmentsDue: "shipmentsDue",
  payoutDue: "payoutDue",
  confirmationPending: "confirmationPending",
  openOrder: "openOrder",
  refundDue: "refundDue",
};

export const filterDisplayFields = {
  sellerCompany: "companyName",
  deliveryState: "orderState",
};
