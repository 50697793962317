import { useEffect, useState } from "react";
import { IconButton, Menu, Box } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import { SvgIcon, TextField } from "components";

const Comment = ({ name, invoiceComments = "" }) => {
  const [anchor, setAnchor] = useState(null);
  const { control, setValue } = useFormContext();
  const open = Boolean(anchor);

  const handleComments = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  useEffect(() => {
    setValue(name, invoiceComments);
  }, []);

  return (
    <>
      <IconButton
        aria-label="comment-icon"
        onClick={handleComments}
        disableRipple={true}
      >
        {invoiceComments ? (
          <SvgIcon name={"chat-unread"} width={24} height={24} />
        ) : (
          <SvgIcon name={"chat-read"} width={24} height={24} />
        )}
      </IconButton>
      <Menu id="long-menu" anchorEl={anchor} open={open} onClose={handleClose}>
        <Box style={{ padding: 8 }}>
          <Controller
            control={control}
            defaultValue={invoiceComments}
            name={name}
            render={({ field: { value, onChange } }) => (
              <TextField
                placeholder={"Comments"}
                size="small"
                onChange={onChange}
                value={value}
                inputProps={{ maxLength: 150 }}
              />
            )}
          />
        </Box>
      </Menu>
    </>
  );
};

export default Comment;
