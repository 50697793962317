export const fetchRegion = (data, regionId) => {
  const regionList = data?.filter((ele) => regionId?.includes(ele?.id));
  return regionList ?? [];
};

export const fetchModules = (data, moduleId) => {
  const moduleList = data?.filter((ele) => moduleId?.includes(ele?.id));
  return moduleList ?? [];
};

export const fetchBusiness = (data, businessUnits) => {
  const businessUnitList = data?.filter((ele) =>
    businessUnits?.includes(ele?.id),
  );
  return businessUnitList ?? [];
};
