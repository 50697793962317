import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Grid, Box, Typography, Divider } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import {
  ModalBox,
  TextField,
  DatePickerV1,
  DropdownV1,
  ButtonV1,
} from "components";
import { ErrorMsgV1 } from "CustomStyledComponents";

import { postCall_v2 } from "services";
import { getCreditType, getInstrumentType } from "./makeData";
import { removeTimeOffset, moneyTextField } from "utils";
import { addNewInstrumentSchema } from "./test";
import { useToaster } from "hooks";

import { success, error } from "constants";

import CREDIT_UPDATE from "../constants";
import Attachment from "../components/Attachment";

const AddNewInstrumentModal = ({
  open,
  handleModal,
  dropdownDetails,
  customerDetails,
  omsUserDetail,
}) => {
  const [instrumentType, setInstrumentType] = useState([]);
  const [filePath, setFilePath] = useState();
  const [docError, setDocError] = useState(false);
  const triggerToaster = useToaster();
  const {
    control,
    reset,
    getValues,
    resetField,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(addNewInstrumentSchema),
  });

  const onSaveDetails = async () => {
    const {
      creditDueDays,
      expiryDate,
      creditType,
      penalRate,
      sanctionedLimit,
      creditInstrumentType,
      camId,
      gracePeriod,
      comments,
      advancePercentage,
      sellerGstin,
    } = getValues();
    if (!filePath) {
      setDocError(true);
      return;
    } else {
      setDocError(false);
    }

    const payload = {
      creditDueDays: creditDueDays ?? "",
      expiryDate: removeTimeOffset(expiryDate),
      creditType: creditType,
      penalRate: penalRate,
      sanctionedLimit: +sanctionedLimit * CREDIT_UPDATE.lakh ?? null,
      utilisedLimit: "0",
      creditInstrumentType: creditInstrumentType ?? "",
      pan: customerDetails?.data?.[0]?.pan,
      gracePeriod,
      camId,
      comments: comments ?? "",
      path: filePath,
      companyName: customerDetails?.data?.[0]?.companyName,
      gstin: customerDetails?.data?.[0]?.gstin,
      requestedBy: omsUserDetail.name,
      advancePercentage,
      sellerGstin,
    };
    const res = await postCall_v2(
      "/oms/credit/createNewInstrument",
      payload,
      "",
      () => {
        triggerToaster(`An error occured`, error);
      },
    );
    if (res.status === 200) {
      if (res?.data?.successful) {
        triggerToaster(`Request submitted for approval`, success);
      } else {
        triggerToaster(res?.data?.message, error);
      }
      handleModal(false);
      reset();
    }
  };

  const creditType = getCreditType(dropdownDetails);

  const handleInstrumentType = (event) => {
    setInstrumentType(getInstrumentType(dropdownDetails, event.target.value));
    resetField("creditInstrumentType");
  };

  const onCloseModal = () => {
    handleModal(false);
    reset();
  };

  const handleUpload = async (files) => {
    const formData = new FormData();
    formData.append("file", files);
    const res = await postCall_v2(
      `/oms/credit/document/upload?pan=${customerDetails?.data?.[0]?.pan}`,
      formData,
      "",
      (err) => {
        triggerToaster(
          err?.data?.message || `Error occurred, please try again`,
          error,
        );
      },
    );
    if (res) {
      setFilePath(res?.data?.creditDocumentPath);
      triggerToaster(`Files uploaded`, success);
      return true;
    }
  };

  return (
    <>
      <ModalBox open={open} onCloseModal={onCloseModal} width="50%">
        <Box
          as="form"
          onSubmit={handleSubmit(onSaveDetails)}
          style={{ padding: 30 }}
        >
          <Typography fontWeight={600} fontSize={20} marginBottom={8}>
            Add new instrument
          </Typography>
          <Grid container marginBottom={8}>
            <Grid item xs={6} marginBottom={4}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>Instrument name</Typography>
                <Controller
                  control={control}
                  name={"creditType"}
                  render={({ field: { value, onChange } }) => (
                    <DropdownV1
                      sx={{
                        width: "80%",
                      }}
                      valueKey={"value"}
                      itemList={creditType}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        handleInstrumentType(e);
                      }}
                    />
                  )}
                />
              </Box>
              <ErrorMsgV1 msg={errors?.creditType?.message} />
            </Grid>
            <Grid item xs={6} marginBottom={4}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>Expiry date</Typography>
                <Controller
                  control={control}
                  name={"expiryDate"}
                  defaultValue={moment().add(6, "months")}
                  render={({ field: { value, onChange } }) => (
                    <DatePickerV1
                      dateStyle={{ width: "80%" }}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Box>
              <ErrorMsgV1 msg={errors?.expiryDate?.message} />
            </Grid>
            <Grid item xs={6} marginBottom={4}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>Credit program name</Typography>
                <Controller
                  control={control}
                  name={"creditInstrumentType"}
                  render={({ field: { value, onChange } }) => (
                    <DropdownV1
                      sx={{
                        width: "80%",
                      }}
                      valueKey={"value"}
                      itemList={instrumentType}
                      value={value}
                      disabled={!instrumentType.length}
                      onChange={onChange}
                    />
                  )}
                />
              </Box>
              <ErrorMsgV1 msg={errors?.creditInstrumentType?.message} />
            </Grid>
            <Grid item xs={6} marginBottom={4}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>
                  Penal charges (% per annum)
                </Typography>
                <Controller
                  control={control}
                  name={"penalRate"}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      inputProps={{
                        style: {
                          height: 10,
                        },
                      }}
                      sx={{
                        width: "80%",
                      }}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Box>
              <ErrorMsgV1 msg={errors?.penalRate?.message} />
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>Credit days</Typography>
                <Controller
                  control={control}
                  name={"creditDueDays"}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      inputProps={{
                        style: {
                          height: 10,
                        },
                      }}
                      sx={{
                        width: "80%",
                      }}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Box>
              <ErrorMsgV1 msg={errors?.creditDueDays?.message} />
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>Grace period (in days)</Typography>
                <Controller
                  control={control}
                  name={"gracePeriod"}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      inputProps={{
                        style: {
                          height: 10,
                        },
                      }}
                      sx={{
                        width: "80%",
                      }}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Box>
              <ErrorMsgV1 msg={errors?.gracePeriod?.message} />
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>
                  Credit limit Reference ID
                </Typography>
                <Controller
                  control={control}
                  name={"camId"}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      inputProps={{
                        style: {
                          height: 10,
                        },
                      }}
                      sx={{
                        width: "80%",
                      }}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Box>
              <ErrorMsgV1 msg={errors?.camId?.message} />
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>Advance (%)</Typography>
                <Controller
                  control={control}
                  name={"advancePercentage"}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      inputProps={{
                        style: {
                          height: 10,
                        },
                      }}
                      sx={{
                        width: "80%",
                      }}
                      type={"number"}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Box>
              <ErrorMsgV1 msg={errors?.advancePercentage?.message} />
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>CAM document</Typography>
                <Attachment onFileUpload={handleUpload} />
              </Box>
              {!!docError && <ErrorMsgV1 msg={"Please upload document"} />}
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>Remarks</Typography>
                <Controller
                  control={control}
                  name={"comments"}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      inputProps={{
                        style: {
                          height: 40,
                        },
                      }}
                      placeholder="Comment goes here"
                      multiline
                      sx={{
                        width: "80%",
                      }}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
          <Divider />
          <Grid container marginBottom={8} marginTop={4}>
            <Grid item xs={6} marginBottom={4}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>
                  Sanctioned limit (in lakhs)
                </Typography>
                <Controller
                  control={control}
                  name={"sanctionedLimit"}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      inputProps={{
                        style: {
                          height: 10,
                        },
                      }}
                      sx={{
                        width: "80%",
                      }}
                      {...moneyTextField(value, onChange)}
                    />
                  )}
                />
              </Box>
              <ErrorMsgV1 msg={errors?.sanctionedLimit?.message} />
            </Grid>
            <Grid item xs={6} marginBottom={4}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Typography fontWeight={400}>Seller GSTIN</Typography>
                <Controller
                  control={control}
                  name={"sellerGstin"}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      inputProps={{
                        style: {
                          height: 10,
                        },
                      }}
                      sx={{
                        width: "80%",
                      }}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </Box>
              <ErrorMsgV1 msg={errors?.sellerGstin?.message} />
            </Grid>
          </Grid>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <ButtonV1
              title={"Close"}
              variant="outlined"
              onClick={onCloseModal}
              style={{ marginRight: 5 }}
            />
            <ButtonV1 title={"Save"} disabled={!isDirty} type="submit" />
          </Box>
        </Box>
      </ModalBox>
    </>
  );
};

export default AddNewInstrumentModal;
