import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  CustomTextField,
  CustomViewOnly,
  GridSkeleton,
  ErrorMsg,
} from "./ShipmentCustomComponents";
import { ButtonV1, FloatingBar, DropdownV1 } from "components";
import theme from "themeProvider";

import {
  amountWithGST,
  calculateAllFreightCharges,
} from "./shipmentCalculationUtil";
import { decimalRoundOff, moneyFormat, removeCurrencyFormat } from "utils";
import {
  hookFormLabel,
  FrieghtChargeWarning,
  FREIGHT_CHARGES,
} from "./constants";

const ZohoOtherServiceCharges = forwardRef(
  (
    {
      serviceCharges,
      submitCharges,
      sellerOrderId,
      shipmentId,
      onCloseModal,
      processingCharges,
    },
    ref,
  ) => {
    const {
      getValues,
      setValue,
      control,
      resetField,
      formState: { errors },
    } = useFormContext();

    const [frieghtChargeWarning, setFreightChargeWarning] = useState(null);
    const [totalFrieghtAmount, setTotalFreightAmount] = useState(0);

    const sellerShipmentsInfo = useSelector((state) => state?.shipmentDetails);
    const orderDetails = useSelector((state) => state?.orderDetails);

    useImperativeHandle(ref, () => ({
      resetValues() {
        Object.keys(serviceCharges).forEach((ele, i) => {
          resetField(ele);
          resetField(`zohoServiceCharges.${i}.confirmAmount`);
          resetField(`zohoServiceCharges.${i}.percent`);
          resetField(`zohoServiceCharges.${i}.amountWithTax`);
        });
        resetField(hookFormLabel.totalInvoiceOtherServicesAmt);
        resetField(hookFormLabel.totalGSTInvoiceAmount);
        resetField(hookFormLabel.totalBaseInvoiceAmt);
        resetField(hookFormLabel.totalInvoiceAmt);
      },
    }));

    const calculateAmountWithGST = (charge, gst, ele, ind) => {
      const result = amountWithGST(charge, gst);
      if (ele?.key === FREIGHT_CHARGES) {
        const freightCharge = removeCurrencyFormat(
          orderDetails?.formattedAmounts?.["Freight charges"],
        );
        if (
          freightCharge !== 0 &&
          totalFrieghtAmount + Number(result) > freightCharge
        ) {
          setFreightChargeWarning(FrieghtChargeWarning);
        } else {
          setFreightChargeWarning(null);
        }
      }
      setValue(`zohoServiceCharges.${ind}.amountWithGst`, result);
    };

    useEffect(() => {
      const totalAmount = calculateAllFreightCharges(
        sellerShipmentsInfo,
        sellerOrderId,
        shipmentId,
      );
      setTotalFreightAmount(totalAmount ?? 0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * @description Get selected service charges to add mmId, chargeDescriptionType, taxPercentage etc in payload
     */
    const getSelectedServiceData = (value, ele, ind) => {
      const gstPercentData = ele?.[ele?.key];
      const selectedGstPercentage = gstPercentData?.find(
        (item) => +item?.taxPercentage === +value,
      );

      const enterData = getValues(`zohoServiceCharges.${ind}`);

      const formattedData = {
        ...enterData,
        description: selectedGstPercentage?.description,
        type: selectedGstPercentage?.type,
        taxPercentage: value,
        igst: selectedGstPercentage?.igst,
        cgst: selectedGstPercentage?.cgst,
        creatorId: selectedGstPercentage?.creatorId,
        mmId: selectedGstPercentage?.mmId,
        chargeDescriptionType: selectedGstPercentage?.chargeDescriptionType,
        amount: enterData?.amount,
      };

      setValue(`zohoServiceCharges.${ind}`, formattedData);
    };

    /**
     * @description format service charge, All list of service charge and already saved service charges
     */
    const formattedServiceCharge = serviceCharges.map((serviceCharge) => {
      const alreadySavedCharges = processingCharges?.find(
        (processingCharge) =>
          processingCharge.chargeDescriptionType === serviceCharge?.key,
      );
      if (alreadySavedCharges) {
        return {
          ...serviceCharge,
          ...alreadySavedCharges,
        };
      } else {
        return serviceCharge;
      }
    });

    /**
     * @description getAmountWithGst default value which is calculated on the basis of already saved value
     */
    const getAmountWithGst = (ele, ind) => {
      if (ele?.amount) {
        getSelectedServiceData(ele?.taxPercentage, ele, ind);
        return +ele?.amount + (+ele?.amount * +ele?.taxPercentage) / 100;
      }
      return 0;
    };

/**
 * 
 * @description allow two decimal points 
 */
    const allowTwoDecimal=(value) => {
      let sanitizedValue = value.replace(/[^0-9.]/g, '');
      // Limit to one decimal point
      const parts = sanitizedValue.split('.');
      if (parts.length > 2) {
          sanitizedValue = parts[0] + '.' + parts.slice(1).join('');
      }
      
      // Limit to two decimal places
      if (parts[1] && parts[1].length > 2) {
          sanitizedValue = parts[0] + '.' + parts[1].substring(0, 2);
      }
  
      return sanitizedValue;
  };

    return (
      <Box>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            position: "sticky",
            top: 0,
            height: 64,
            width: "100%",
            backgroundColor: theme.palette.common.white,
            zIndex: 1,
            justifyContent: "space-between",
          }}
          mb={4}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              color={theme.palette.secondary.main}
              pr={2}
            >
              Invoiced amount
            </Typography>
            <Typography component={"span"}>(other services)</Typography>
          </Box>
          <IconButton
            onClick={onCloseModal}
            size="small"
            style={{
              height: 40,
              marginBottom: 12,
            }}
          >
            <CloseOutlined style={{ height: 24 }} />
          </IconButton>
        </Box>
        {formattedServiceCharge &&
          formattedServiceCharge.map((ele, ind) => {
            return (
              <Box
                style={{
                  borderRadius: 4,
                  border: `1px solid ${theme.palette.grey[400]}`,
                }}
                key={ind}
                p={4}
                mb={4}
              >
                <Typography mb={4}>{ele?.description}</Typography>
                <GridSkeleton
                  firstChildMd={6}
                  secondChildMd={2}
                  firstChild={
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        style={{ display: "flex", alignItems: "flex-end" }}
                        mb={1}
                      >
                        <Controller
                          control={control}
                          name={`zohoServiceCharges.${ind}.amount`}
                          defaultValue={ele?.amount ?? 0}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography mb={2}>Amount</Typography>
                              <CustomTextField
                                value={value}
                                mask={true}
                                onChange={(evt)=>{
                                  const value = allowTwoDecimal(evt.target.value);
                                  onChange(value);
                                }}
                                customPadding={2}
                                onBlur={onBlur}
                              />
                            </Box>
                          )}
                        />
                        <Controller
                          control={control}
                          name={`zohoServiceCharges.${ind}.confirmAmount`}
                          defaultValue={ele?.amount ?? 0}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <CustomTextField
                              value={value}
                              onChange={(evt) => {
                                const value = allowTwoDecimal(evt.target.value);
                                onChange(value);
                                calculateAmountWithGST(
                                  evt?.target.value,
                                  getValues(
                                    `zohoServiceCharges.${ind}.percent`,
                                  ),
                                  ele,
                                  ind,
                                );
                                getSelectedServiceData(
                                  getValues(
                                    `zohoServiceCharges.${ind}.percent`,
                                  ),
                                  ele,
                                  ind,
                                );
                              }}
                              highlightError={errors?.zohoServiceCharges?.[
                                ind
                              ]?.confirmAmount?.hasOwnProperty("message")}
                              customPadding={2}
                              onBlur={onBlur}
                            />
                          )}
                        />
                      </Box>
                      <ErrorMsg
                        msg={
                          errors?.zohoServiceCharges?.[ind]?.confirmAmount
                            ?.message
                        }
                      />
                      {!!(ele?.key === FREIGHT_CHARGES) && (
                        <ErrorMsg msg={frieghtChargeWarning} />
                      )}
                    </Box>
                  }
                  secondChild={
                    <Box marginRight={3}>
                      <Typography fontWeight={600} style={{ marginBottom: 8 }}>
                        GST %
                      </Typography>
                      <Controller
                        control={control}
                        name={`zohoServiceCharges.${ind}.percent`}
                        defaultValue={ele?.taxPercentage}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <DropdownV1
                              itemList={ele?.[ele?.key]}
                              valueKey="taxPercentage"
                              labelKey="taxPercentage"
                              value={value?.toString()}
                              onChange={(evt) => {
                                calculateAmountWithGST(
                                  getValues(
                                    `zohoServiceCharges.${ind}.confirmAmount`,
                                  ),
                                  evt.target.value,
                                  ele,
                                  ind,
                                );
                                getSelectedServiceData(
                                  evt?.target?.value,
                                  ele,
                                  ind,
                                );
                                onChange(evt);
                              }}
                              menuStyle={{
                                minWidth: 80,
                                padding: "6px",
                              }}
                            />
                          );
                        }}
                      />
                    </Box>
                  }
                  thirdChild={
                    <Controller
                      control={control}
                      name={`zohoServiceCharges.${ind}.amountWithGst`}
                      defaultValue={getAmountWithGst(ele, ind)}
                      render={({ field: { value } }) => (
                        <CustomViewOnly
                          label="Amount with GST"
                          value={moneyFormat(decimalRoundOff(value) ?? 0)}
                        />
                      )}
                    />
                  }
                />
              </Box>
            );
          })}
        <FloatingBar position="sticky">
          <ButtonV1
            onClick={submitCharges}
            title="Submit"
            disabled={!!errors?.zohoServiceCharges?.length}
          />
        </FloatingBar>
      </Box>
    );
  },
);

ZohoOtherServiceCharges.propTypes = {
  serviceCharges: PropTypes.object,
  submitCharges: PropTypes.func,
  sellerOrderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  shipmentId: PropTypes.number,
};

export default ZohoOtherServiceCharges;
