import { Divider as Separator } from "@mui/material";
import PropTypes from "prop-types";

/**
 * @description It is used to make vertical or horizontal line.
 */
const Divider = ({
  style = {
    marginLeft: 12,
    marginRight: 12,
  },
  orientation = "vertical",
}) => {
  return (
    <Separator
      orientation={orientation}
      variant="middle"
      flexItem
      style={style}
    />
  );
};

export default Divider;

Divider.propTypes = {
  style: PropTypes.object,
  orientation: PropTypes.string,
};
