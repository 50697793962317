import { createSlice } from "@reduxjs/toolkit";

export const orderDetails = createSlice({
  name: "orderDetails",
  initialState: {},
  reducers: {
    setOrderDetailsAction: (state, action) => {
      const temp = action.payload;
      delete temp["lineItems"];
      return temp;
    },
    setLineItemsAction: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateOrderDetailsAction: (state, action) => {
      return action.payload;
    },
    setCreditDetailsAction: (state, action) => {
      return { ...state, ...action.payload };
    },
    setCustomerFreezeFlagAction: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const {
  setOrderDetailsAction,
  setLineItemsAction,
  updateOrderDetailsAction,
  setCreditDetailsAction,
  setCustomerFreezeFlagAction,
} = orderDetails.actions;

export default orderDetails.reducer;
