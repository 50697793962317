import { removeTimeOffset } from "utils";
import { standardUnit } from "../constants";

export const createShipmentModel = (shipmentValues, shipmentInfo) => {
  try {
    const updatedShipmentVal = shipmentValues;
    const warehouseKey = shipmentValues?.warehouseDetail;
    const lineItems = shipmentValues?.lineItemsDetails;
    const shipmentNumber = shipmentValues?.sapShipmentNo;
    const { truckNo, driverNo, truckReceiptNumber, driverName } =
      shipmentValues;
    const warehouseDetails =
      shipmentInfo?.warehouses?.length &&
      // eslint-disable-next-line array-callback-return
      shipmentInfo?.warehouses.find((ele) => {
        if (ele.hasOwnProperty("name")) {
          return ele.name === warehouseKey;
        }
      });
    const data = {
      currentState: {
        state: "Planned",
      },
      plannedDispatchDate:
        removeTimeOffset(updatedShipmentVal?.scheduledDispatchDate) ?? "",
      deliveryType: { state: updatedShipmentVal?.deliveryType },
      modeOfShipment: updatedShipmentVal?.shipmentMode,
      sellerOrderId: shipmentInfo?.sellerOrderId,
      vehicleDetails: {
        truckNumber: truckNo,
        driverNumber: driverNo,
        truckReceiptNumber,
        driverName,
      },
      warehouseId: warehouseDetails?.id ?? "",
      shipmentNumber: shipmentNumber ? shipmentNumber : null,
    };

    const lineItemsDetails = [];

    // eslint-disable-next-line array-callback-return
    lineItems.map((ele, index) => {
      const quantity = ele?.enteredQuantity;
      const temp = {
        lineitemId: index,
        plannedDelivery: {
          quantity: quantity,
          shipmentId: null,
          measurementId: standardUnit,
        },
      };
      if (Number(quantity) > 0) {
        lineItemsDetails.push(temp);
      }
    });

    return {
      ...data,
      lineItemsDetails: lineItemsDetails,
      sellerGstin: shipmentInfo.sellerInfo.gstin,
    };
  } catch (err) {
    console.error(`Error in creating shipment data - ${err}`);
  }
};

export const warehouseNameFix = (shipmentData) => {
  let warehouse = [];
  const shipmentDetails = { ...shipmentData };
  // eslint-disable-next-line array-callback-return
  shipmentDetails?.warehouses?.map((ele) => {
    let obj = {
      ...ele,
      name: ele?.name?.en,
    };
    warehouse.push(obj);
  });

  shipmentDetails.warehouses = warehouse;
  return shipmentDetails;
};
