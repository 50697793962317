import moment from "moment";

/**
 *
 * @param {*} parsedData
 * @description returns parsed data in required format
 */
export const generateRefundOffErpData = (parsedData, isEmptyForm = false) => {
  let offOMSData = [];
  parsedData?.forEach((ele, _) => {
    // Bulk upload csv file should contain at least 6 columns as it is required for Refund and cashback.
    if (isEmptyForm || Object.keys(ele).length >= 6) {
      let offOMSMapper = {};
      offOMSMapper.orderNumber = ele["Order number"];
      offOMSMapper.payoutType = ele["Payout type"];
      offOMSMapper.fromGstin = ele["From account"];
      offOMSMapper.toGstin = ele["To account"];
      offOMSMapper.invoiceAmount = ele["Invoice amount"];
      offOMSMapper.gstAmount = ele["GST amount"];
      offOMSMapper.payoutAmount = ele["Payout amount"];
      offOMSMapper.invoiceComments = ele["Invoice comments"];
      offOMSData.push(offOMSMapper);
    }
  });

  return offOMSData;
};

/**
 * @param {*} txList
 * @description returns mapping error & txData in
 * different objects in a list
 */
export const constructInvalidTxList = (txList) => {
  let result = [];
  txList.forEach((ele, i) => {
    let errors, txData;
    if (Object.keys(ele[0]).length > Object.keys(ele[1]).length) {
      errors = ele[1];
      txData = ele[0];
    } else {
      errors = ele[0];
      txData = ele[1];
    }
    txData.id = `invalidDataMap-${i}`;
    let response = { errors, txData };
    result.push(response);
  });
  return result;
};

/**
 * @description Generate data after transaction correction
 */
export const constructCorrectedTx = (txData, invalidCsvList) => {
  // Convert object of object list to array of object
  const formattedTxData = [];
  Object.keys(txData.txList)?.forEach((key) => {
    if (txData?.txList[key] && Object.values(txData?.txList[key]).length > 3) {
      formattedTxData.push(txData?.txList[key]);
    }
  });

  return formattedTxData?.map((ele, i) => {
    return {
      orderNumber: ele?.orderNumber,
      payoutType: ele?.payoutType,
      fromGstin: ele?.fromAccount,
      toGstin: ele?.toAccount,
      payoutAmount: ele?.payoutAmount,
      invoiceComments: ele?.invoiceComments,
      invoiceNumber: ele?.invoiceNo,
      invoiceDate: ele?.invoiceDate,
      invoiceAmount: ele?.invoiceAmount,
      gstAmount:
        ele?.gstAmount?.toString() || invalidCsvList[i]?.txData?.gstAmount,
      isTcsOnIt: ele?.isTcs || invalidCsvList[i]?.txData?.onePercentIt,
    };
  });
};

/**
 * @param {*} csvData
 * @returns format csv data to use as body in api
 */
export const formatCSVData = (csvData) => {
  const response = csvData.map((item) => {
    return {
      fromGstin: item?.fromGstin?.trim(),
      gstAmount: item?.gstAmount,
      invoiceAmount: item?.invoiceAmount?.toString()?.trim(),
      invoiceComments: item?.invoiceComments?.trim(),
      invoiceDate: item?.invoiceDate
        ? moment(item?.invoiceDate).format("YYYY-MM-DD")
        : "",
      invoiceNumber: item?.invoiceNumber?.toString()?.trim(),
      onePercentIt: Boolean(item?.isTcsOnIt),
      jswOneCommission: item?.jswOneCommission?.trim(),
      orderNumber: item?.orderNumber?.trim(),
      payoutAmount: item?.payoutAmount?.toString()?.trim(),
      payoutType: item?.payoutType?.trim(),
      toGstin: item?.toGstin?.trim(),
    };
  });
  return response;
};

export const dateConverter = (date) => {
  const convertDate = moment(date?.toString(), "DD/MM/YYYY");

  if (convertDate.isValid()) return convertDate;
  else return null;
};

/**
 * @description - group by pending files
 */
export const groupByPendingFile = (data, groupBy = "filePath") => {
  if (data && data.length) {
    return data.sort((ele) => {
      if (ele[groupBy]) return 1;
      return -1;
    });
  }
};
