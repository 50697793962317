export const searchConstants = Object.freeze({
  CUSTOMER_SEARCH_HEADING: [
    "Buyer Company Name",
    "GSTIN",
    "PAN",
    "Email",
    "Mobile Number",
    "Action",
  ],
  SEARCH_FILTER_FORM: {
    searchType: "searchType",
    searchValue: "searchValue",
  },
  searchTypeList: [
    { label: "Customer Name", value: "Customer Name" },
    { label: "Email", value: "Email" },
    { label: "Mobile Number", value: "Mobile Number" },
    { label: "PAN", value: "PAN" },
    { label: "GSTIN", value: "GSTIN" },
  ],
});
