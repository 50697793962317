import { useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { ButtonV1 } from "components";
import { Filters, TransactionFilter } from "../components";
import { QuickFilter } from "../PendingPayoutsTab/components";
import InvoiceSeller from "./InvoiceSeller";
import RefundCashback from "./RefundCashback";
import PayoutTxUpload from "./components/PayoutTxUpload";
import useFetchParam from "../useFetchParam";

import { FinancePayouts } from "../constants";
import { formatFiltersData } from "../makeData";
import { toggleApprovalFlow } from "dataStore/approvalPayoutUploadFlow";

const {
  transactionFilter: { INVOICE_SELLER_PAYOUTS, REFUND_CASHBACK },
  quick_filter,
  queryFilters,
  financeTab,
  INVOICE_SELLER_PAYOUTS: INVOICE_SELLER_PAYOUTS_TYPE,
} = FinancePayouts;

const initialQuickFilter = "all";

const PanelTable = ({ transactionType, quickFilter, filters, onReset }) => {
  switch (transactionType) {
    case INVOICE_SELLER_PAYOUTS:
      return (
        <InvoiceSeller
          transactionType={transactionType}
          quickFilter={quickFilter}
          filters={filters}
        />
      );
    case REFUND_CASHBACK:
      return <RefundCashback quickFilter={quickFilter} filters={filters} />;
    default:
      onReset(INVOICE_SELLER_PAYOUTS_TYPE);
      console.warn("Out of option ::", transactionType);
  }
};

const ApprovalPayoutTab = () => {
  const { fetchParams, updateParams } = useFetchParam();
  const dispatch = useDispatch();
  const uploadFlow = useSelector((state) => state.approvalPayoutUploadFlow);
  const [selectedQuickFilter, setSelectedQuickFilter] =
    useState(initialQuickFilter);

  const transactionType = useMemo(
    () =>
      FinancePayouts.transactionFilter[
        fetchParams?.[queryFilters.transactionType]
      ],
    [fetchParams],
  );

  const filterParams = useMemo(() => {
    const {
      [queryFilters.tab]: tab,
      [queryFilters.transactionType]: type,
      ...filter
    } = fetchParams;

    return filter;
  }, [fetchParams]);

  const handleQuickFilter = (value) => {
    setSelectedQuickFilter(value);
  };
  /**
   * @description invoked on submitting filters
   */
  const onSubmit = (filters) => {
    const formattedFiltersData = formatFiltersData(
      filters,
      financeTab.approve,
      transactionType,
    );

    updateParams({ param: formattedFiltersData });
  };

  const onReset = (type) => {
    const param = {
      [queryFilters.tab]: financeTab.approve,
      [queryFilters.transactionType]:
        type ?? fetchParams?.[queryFilters.transactionType],
    };

    updateParams({ param, appendWithExisting: false });
    setSelectedQuickFilter(initialQuickFilter);
  };

  /**
   * @description Redirect to default approval flow
   */
  const redirectToApprovalFlow = () => {
    dispatch(toggleApprovalFlow(false));
  };

  /**
   * @description mimic component will unmount
   */
  useEffect(() => {
    return () => {
      redirectToApprovalFlow();
    };
  }, []);

  return (
    <>
      {uploadFlow ? (
        <>
          <Box display={"flex"} alignContent={"flexStart"} mb={8}>
            <ButtonV1
              title={"Approve Transaction"}
              size={"medium"}
              variant="outlined"
              onClick={redirectToApprovalFlow}
            />
          </Box>
          <PayoutTxUpload />
        </>
      ) : (
        <>
          <Box mb={4}>
            <TransactionFilter />
          </Box>
          <Box mb={4}>
            <Filters
              onSubmit={onSubmit}
              onReset={onReset}
              financeTab={financeTab.approve}
              transactionType={transactionType}
            />
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography fontSize={20} fontWeight={600} marginBottom={2}>
              Approved transactions
            </Typography>
            <Box display={"flex"}>
              {Object.keys(quick_filter).map((elem, ind) => (
                <QuickFilter
                  key={ind}
                  label={quick_filter[elem]}
                  value={elem}
                  onClick={handleQuickFilter}
                  selectedFilter={selectedQuickFilter}
                  // filterCount={filterCount}
                />
              ))}
            </Box>
          </Box>
          <PanelTable
            transactionType={transactionType}
            quickFilter={selectedQuickFilter}
            filters={filterParams}
            onReset={onReset}
          />
        </>
      )}
    </>
  );
};

export default ApprovalPayoutTab;
