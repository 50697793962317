import { useState, useEffect } from "react";
import { Avatar, Box, Chip, Divider, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  MailOutlineOutlined,
  DeleteOutline,
  PhoneAndroidOutlined,
  Report,
} from "@mui/icons-material";
import { styled } from "@mui/system";
import { useForm, Controller } from "react-hook-form";
import { differenceBy } from "lodash";

import { Loader, ButtonV1 } from "components";
import {
  UserCard,
  EditableInput,
  ErrorMsg,
  EditableChipSet,
  RightDrawer,
} from "../components";
import { useFetchData, useToaster } from "hooks";
import { deleteCall, putCall_v2 } from "services";
import theme from "themeProvider";
import {
  DELETE_API_RESPONSE,
  DELETE_USER_SUCCESS_MSG,
  API_FAILED_MSG,
} from "./constants";
import { UserDetailsSchema } from "../TestSchema/userDetailsSchema";
import { error, success } from "constants";

const UserDetails = () => {
  const [userData, setUserData] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [searchParams] = useSearchParams();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const triggerToaster = useToaster();

  const StyledChip = styled(Chip)({
    marginRight: 10,
    marginBottom: 16,
    borderColor: theme.palette.grey[700],
    color: theme.palette.grey[700],
  });

  const {
    getValues,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(UserDetailsSchema),
  });

  const email = searchParams.get("email");

  const onSuccess = (res) => {
    setUserData(res.data[0]);
    setValue("mobileNumber", res?.data?.[0]?.users?.[0]?.mobileNumber);
  };

  const getGroupsFromData = (data, option) => {
    let arr = [];
    option
      ? // eslint-disable-next-line array-callback-return
        data.map((element) => {
          const filteredElement = element?.users?.find(
            (ele) => ele.email === email,
          );
          !filteredElement && arr.push(element?.groups?.[0]);
        })
      : // eslint-disable-next-line array-callback-return
        data.map((element) => {
          const filteredElement = element?.users?.filter(
            (ele) => ele.email === email,
          );
          filteredElement?.length && arr.push(element?.groups?.[0]);
        });

    return arr;
  };

  const setUserRoleOptions = (data) => {
    const allRoles = data.map((ele) => ele.roles?.[0]);
    const arr = differenceBy(allRoles, userData?.roles, "id");
    setUserRoles(arr);
  };

  const { refetch: fetchUserDetails, isLoading } = useFetchData(
    "user-details",
    `/auth2-service/user/search?email=${email}`,
    onSuccess,
  );

  const { refetch: getGroupsData, isLoading: isLoadingGroup } = useFetchData(
    "groups",
    `/auth2-service/groups/all`,
    (res) => {
      const data = res?.data;
      setUserGroups(getGroupsFromData(data));
      setGroupOptions(getGroupsFromData(data, true));
    },
  );

  const { refetch: getRolesData, isLoading: isLoadingRole } = useFetchData(
    "roles",
    "/auth2-service/user/roles",
    (res) => {
      const data = res?.data;
      setUserRoleOptions(data);
    },
  );

  useEffect(() => {
    fetchUserDetails();
    getGroupsData();
    getRolesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUserDetails = async ({ groups, roles }) => {
    const user = userData?.users?.[0];
    const {
      id,
      name,
      email,
      imageUrl,
      emailVerified,
      provider,
      location,
      providerId,
    } = user;
    const payload = {
      id,
      name,
      email,
      imageUrl,
      provider,
      locations: location,
      providerId,
      emailVerified,
      mobileNumber: getValues("mobileNumber"),
      groupId: groups,
      roleId: roles,
    };

    try {
      await putCall_v2("/auth2-service/user", payload);
      triggerToaster("User details updated", success);
      fetchUserDetails();
      getGroupsData();
      getRolesData();
    } catch (ex) {
      console.log(ex);
      triggerToaster(
        ex?.data?.message ?? "Please try again, error occurred",
        error,
      );
    }
  };

  const handleDeleteUser = async (userId) => {
    const result = await deleteCall(`/auth2-service/user?id=${userId}`);
    if (result === DELETE_API_RESPONSE) {
      triggerToaster(DELETE_USER_SUCCESS_MSG, success);
      navigate(`/user-management/users`);
    } else {
      triggerToaster(API_FAILED_MSG, error);
    }
  };

  const onUpdateGroup = (data) => {
    let groupId = [];
    data.map((ele) => groupId.push(ele.id));
    updateUserDetails({ groups: groupId });
  };
  const onUpdateRoles = (data) => {
    let rolesId = [];
    data.map((ele) => rolesId.push(ele.id));
    updateUserDetails({ roles: rolesId });
  };

  const toggleDrawer = (value) => {
    setDrawerOpen(value);
  };

  return (
    <Box pl={10} pr={10}>
      <Typography variant="h3">User details</Typography>
      {isLoading && isLoadingGroup && isLoadingRole ? (
        <Box sx={{ width: "80%", minHeight: 520 }}>
          <Loader />
        </Box>
      ) : (
        <Box display={"flex"}>
          <Box display={"flex"}>
            <UserCard
              cardHeader={
                <>
                  <Avatar
                    alt="user"
                    src={userData?.users?.[0]?.imageUrl}
                    style={{ marginBottom: 10 }}
                  ></Avatar>
                  <Typography>{userData?.users?.[0]?.name}</Typography>
                </>
              }
              cardBody={
                <Box p={6}>
                  <Box display={"flex"} alignItems={"center"} pt={3} pb={3}>
                    <MailOutlineOutlined
                      fontSize="12"
                      style={{ marginRight: 10 }}
                    />
                    <Typography>{userData?.users?.[0]?.email}</Typography>
                  </Box>
                  <Divider style={{ borderStyle: "dashed" }} />
                  <Box display={"flex"} flexDirection={"column"} pt={3} pb={3}>
                    <Box
                      as="form"
                      display={"flex"}
                      onSubmit={handleSubmit(updateUserDetails)}
                    >
                      <Controller
                        control={control}
                        name="mobileNumber"
                        defaultValue={userData?.users?.[0]?.mobileNumber ?? ""}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <EditableInput
                            value={value}
                            error={errors.mobileNumber}
                            onChange={onChange}
                            onBlur={onBlur}
                            icon={
                              <PhoneAndroidOutlined
                                fontSize="12"
                                style={{ marginRight: 10 }}
                              />
                            }
                          />
                        )}
                      />
                    </Box>
                    <ErrorMsg msg={errors?.mobileNumber?.message} />
                  </Box>
                </Box>
              }
              cardFooter={
                <ButtonV1
                  startIcon={<DeleteOutline />}
                  title="Remove user"
                  variant="text"
                  size="small"
                  onClick={() => toggleDrawer(true)}
                  style={{
                    color: theme.palette.text.primary,
                    fontWeight: "normal",
                  }}
                />
              }
            />
          </Box>
          <Box ml={6} display={"flex"} flexDirection={"column"} width={"100%"}>
            <Box width={"100%"}>
              <Typography variant="h4">Roles</Typography>
              <EditableChipSet
                data={userData?.roles}
                options={userRoles}
                dataKey={"name"}
                optionKey={"name"}
                onUpdate={onUpdateRoles}
              />
            </Box>
            <Box width={"100%"}>
              <Divider style={{ marginBottom: 8 }} />
              <Typography variant="h4">Groups</Typography>
              <EditableChipSet
                data={userGroups}
                options={groupOptions}
                dataKey={"name"}
                optionKey={"name"}
                onUpdate={onUpdateGroup}
              />
            </Box>

            <Box width={"100%"}>
              {!!userData?.regions?.length && (
                <Box>
                  <Divider style={{ marginBottom: 8 }} />
                  <Typography variant="h4" mb={2}>
                    Access
                  </Typography>
                  <Typography variant="body1" mb={3}>
                    Location
                  </Typography>
                  {userData?.regions?.map((location) => (
                    <StyledChip
                      label={location.stateName}
                      variant={"outlined"}
                    />
                  ))}
                </Box>
              )}
            </Box>

            <Box width={"100%"}>
              {!!userData?.modules?.length && (
                <Box>
                  <Divider style={{ marginBottom: 8 }} />
                  <Typography variant="body1" mb={3}>
                    Modules
                  </Typography>
                  {userData?.modules?.map((modules) => (
                    <StyledChip label={modules.name} variant={"outlined"} />
                  ))}
                </Box>
              )}
            </Box>

            <Box width={"100%"}>
              {!!userData?.businessUnits?.length && (
                <Box>
                  <Divider style={{ marginBottom: 8 }} />
                  <Typography variant="body1" mb={3}>
                    Business
                  </Typography>
                  <Box>
                    {userData?.businessUnits?.map((business) => (
                      <StyledChip label={business.name} variant={"outlined"} />
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
      <RightDrawer
        toggleDrawer={toggleDrawer}
        icon={
          <object
            data={`/assets/group.svg`}
            width={28}
            alt="Group icon"
            aria-label="group icon"
          />
        }
        heading={"Remove user"}
        isDrawerOpen={isDrawerOpen}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          style={{ height: 650 }}
        >
          <Box>
            <Typography variant="h4" mb={2}>
              {userData?.users?.[0]?.name}
            </Typography>
            <Typography variant="body1" mb={2}>
              Are you sure you want to delete this user?
            </Typography>
            <Box
              height={110}
              mt={5}
              style={{
                backgroundColor: theme.palette.warning.light,
                border: 1,
                borderStyle: "solid",
                borderColor: theme.palette.warning.dark,
                borderRadius: 8,
              }}
              px={4}
              py={5}
            >
              <Box display={"flex"}>
                <Report
                  style={{
                    color: theme.palette.warning.dark,
                    width: 16,
                    marginRight: 8,
                  }}
                />
                <Box>
                  <Typography variant="h5">Warning</Typography>
                  <Typography variant="body1" mb={2}>
                    By deleting this user you will also be deleting assigned
                    roles and groups
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box alignSelf={"flex-end"} mr={9}>
            <ButtonV1
              variant="contained"
              title={"Delete"}
              style={{ paddingLeft: 10, paddingRight: 10 }}
              onClick={() => handleDeleteUser(userData?.users?.[0]?.id)}
            />
          </Box>
        </Box>
      </RightDrawer>
    </Box>
  );
};

export default UserDetails;
