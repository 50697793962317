import { Typography, Box } from "@mui/material";
import theme from "themeProvider";

const Ribbon = () =>
  ["QA", "Dev"].includes(process.env.REACT_APP_ENV) && (
    <Box
      style={{
        position: "fixed",
        flexDirection: "column",
        top: 0,
        width: "100%",
        zIndex: 1,
      }}
    >
      <Box
        style={{
          width: "100%",
          background: theme.palette.primary.main,
          height: 6,
        }}
      ></Box>
      <Box
        style={{
          width: "12%",
          position: "relative",
          left: "45%",
          padding: 10,
          paddingLeft: 20,
          paddingTop: 0,
          borderBottomRightRadius: 10,
          borderBottomLeftRadius: 10,
          backgroundColor: theme.palette.primary.main,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          clipPath: "polygon(0 0,100% 0,95% 100%,5% 100%)",
        }}
      >
        <Typography
          style={{
            fontSize: 16,
            display: "inline-block",
          }}
        >
          You're in{" "}
          <Typography display={"inline-block"} fontWeight={"bold"}>
            {process.env.REACT_APP_ENV} env
          </Typography>
        </Typography>
      </Box>
    </Box>
  );

export default Ribbon;
