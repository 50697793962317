import { useCallback } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";

import { SvgIcon } from "components";

import { useDownload, useToaster } from "hooks";
import { getSubstring } from "utils";
import { getCall_v2 } from "services";
import { success, error } from "constants";

const Attachment = ({ filePath }) => {
  const theme = useTheme();
  const triggerToaster = useToaster();
  const { downloadFile } = useDownload();

  const splittedArr = filePath.split("/");
  const documentName = splittedArr[splittedArr.length - 1] ?? filePath;

  const handleDownload = useCallback(async () => {
    try {
      const { data } = await getCall_v2(
        `/oms/customer/fetchBankAccountDocument`,
        { filePath },
        "blob",
      );
      downloadFile(data, documentName);
      triggerToaster(`Downloading ${documentName} document`, success);
    } catch (err) {
      triggerToaster(`Unable to download ${documentName} document`, error);
    }
  }, []);

  return (
    <Box
      display={"flex"}
      pb={3}
      sx={{
        gap: 2,
        cursor: "pointer",
      }}
      onClick={handleDownload}
    >
      <SvgIcon
        name={"download-file"}
        height={20}
        width={20}
        style={{ color: theme.palette.primary.main }}
      />
      <Typography
        sx={{
          color: theme.palette.primary.main,
          fontWeight: 600,
        }}
      >
        {getSubstring(documentName, 40)}
      </Typography>
    </Box>
  );
};

Attachment.propTypes = {
  filePath: PropTypes.string.isRequired,
};

export default Attachment;
