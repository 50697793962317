import { memo } from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { ButtonV1 } from "components";
import AvatarGroups from "./AvatarGroups";
import theme from "themeProvider";
import { useEffect, useState } from "react";

const CardDetails = ({ title, description, users, styles, onClick }) => {
  const [userNames, setUserNames] = useState([]);
  useEffect(() => {
    let userObject = [];
    // eslint-disable-next-line array-callback-return
    users?.map((ele) => {
      const name = ele?.name?.split(" ");
      const userName =
        name?.length > 1
          ? name[0]?.charAt(0) + name[1]?.charAt(0)
          : name[0]?.charAt(0);
      userObject.push(userName);
    });
    setUserNames(userObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      style={{
        width: 290,
        height: 180,
        border: 1,
        borderColor: `${theme.palette.grey["A700"]}`,
        borderStyle: "solid",
        borderRadius: 10,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        cursor: "pointer",
        ...styles,
      }}
      pt={5}
      pb={5}
      pl={4}
      pr={4}
      onClick={onClick}
    >
      <Box>
        <Typography fontSize={18} marginBottom={3}>
          {title}
        </Typography>
        <Typography
          fontSize={14}
          width={216}
          style={{ wordBreak: "break-word" }}
        >
          {description}
        </Typography>
      </Box>
      <Box display={"flex"}>
        <AvatarGroups userNames={userNames} />
        <ButtonV1
          variant="text"
          size="small"
          title={
            users?.length
              ? `${users?.length} ${users?.length === 1 ? "user" : "users"}`
              : ""
          }
          textStyle={{ fontSize: 14 }}
          style={{ paddingInline: 0 }}
        />
      </Box>
    </Box>
  );
};
export default memo(CardDetails);

CardDetails.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  userNames: PropTypes.array,
  styles: PropTypes.object,
  onClick: PropTypes.func,
};
