import { useState, useCallback, useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  Divider,
  useTheme,
  Switch,
} from "@mui/material";

import { ButtonV1, ModalBox } from "components";
import UpdateCreditInstrumentModal from "./UpdateCreditInstrumentModal";
import AmountBreakupTable from "./AmountBreakupTable";

import { getCreditInstrumentDetails } from "./makeData";
import { getCall_v2 } from "services";
import { useDownload, useFetchData, useToaster } from "hooks";
import { success, error } from "constants";

const utilisedLimit = "Utilised limit";
const blockedLimit = "Blocked limit";

const CreditInstrumentView = ({
  instrumentDetails,
  omsUserDetail,
  customerDetails,
  fetchCustomerCredit,
}) => {
  const theme = useTheme();
  const [isModalOpen, setModalOpen] = useState();
  const { downloadFile } = useDownload();
  const [isCreditBlocked, setCreditBlocked] = useState();
  const [openAmountModal, setOpenAmountModal] = useState(false);
  const [breakupAmountType, setBreakupAmountType] = useState();

  const triggerToaster = useToaster();

  useEffect(() => {
    setCreditBlocked(instrumentDetails?.isCreditBlocked);
  }, [instrumentDetails]);

  useEffect(() => {
    setCreditBlocked(instrumentDetails?.isCreditBlocked);
  }, [instrumentDetails]);

  const { creditInfo, limitDetails } =
    getCreditInstrumentDetails(instrumentDetails);

  const handleModal = (value) => {
    setModalOpen(value);
  };

  const handleDocDownload = useCallback(async (filePath) => {
    const fileName = "CAM document";
    try {
      const { data } = await getCall_v2(
        `/oms/credit/documents/customerCreditDocs`,
        { filePath: filePath },
        "blob",
      );
      downloadFile(data, fileName);
      triggerToaster(`Downloading ${fileName}`, success);
    } catch (err) {
      triggerToaster(`Unable to download ${fileName}`, error);
    } finally {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBlockLimit = async (e) => {
    try {
      const { data } = await getCall_v2(`/oms/credit/updateCreditBlockedFlag`, {
        pan: customerDetails?.data?.[0]?.pan,
        creditType: instrumentDetails?.creditType,
        creditInstrument: instrumentDetails?.creditInstrument,
        creditBlockFlag: e.target.checked,
      });

      setCreditBlocked(data?.[0]?.isCreditBlocked);
      triggerToaster(
        data?.[0]?.isCreditBlocked ? `Credit blocked` : `Credit unblocked`,
        success,
      );
      fetchCustomerCredit();
    } catch (err) {
      setCreditBlocked(instrumentDetails?.isCreditBlocked);
      triggerToaster(
        e.target.checked
          ? `Unable to unblock credit limit`
          : `Unable to block credit limit`,
        error,
      );
    } finally {
      return;
    }
  };
  /**
   *  @description - get amount breakup
   */
  const {
    data: amountBreakupInfo,
    refetch: fetchBreakupInfo,
    isFetching: isBreakupFetching,
  } = useFetchData(
    "breakup-amount",
    `/oms/credit/blocked-and-utilized-limit?customerCreditInfoId=${instrumentDetails?.id}`,
  );

  const handleShowBreakUp = async (type) => {
    fetchBreakupInfo();
    setOpenAmountModal(true);
    setBreakupAmountType(type);
  };

  return (
    <>
      <Box
        style={{
          border: 1,
          borderColor: theme.palette.grey[400],
          borderStyle: "solid",
          borderRadius: 12,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          style={{ padding: 20 }}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Typography fontWeight={600} marginRight={3}>
              {`${instrumentDetails?.creditType} (${instrumentDetails?.creditInstrument})`}
            </Typography>
            <Typography fontWeight={400} fontSize={"medium"} marginRight={2}>
              Block credit limits
            </Typography>
            <Switch
              size="small"
              checked={isCreditBlocked}
              onChange={handleBlockLimit}
            />
          </Box>
          <Box display={"flex"}>
            <ButtonV1
              title={"Add block"}
              disabled={true}
              size="small"
              variant="outlined"
              style={{ marginRight: 5 }}
            />
            <ButtonV1
              title={"Edit"}
              variant="outlined"
              size="small"
              onClick={handleModal}
            />
          </Box>
        </Box>
        <Divider />
        <Box
          style={{
            padding: 20,
            borderBottom: 0,
          }}
        >
          <Grid container spacing={4} marginBottom={2}>
            {creditInfo.map((ele, i) => (
              <Grid item xs={4} key={i}>
                <Box display={"flex"} flexDirection={"column"} gap={2}>
                  <Typography fontWeight={400}>{ele.label}</Typography>
                  {ele.label === "CAM document" ? (
                    <Box display={"flex"}>
                      <ButtonV1
                        variant="text"
                        title={ele.value}
                        size="small"
                        onClick={() =>
                          handleDocDownload(instrumentDetails?.path)
                        }
                        style={{ padding: 0, minWidth: "fit-content" }}
                      />
                    </Box>
                  ) : (
                    <Typography fontWeight={600}>{ele.value ?? "-"}</Typography>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
          <Divider />
          <Grid container spacing={4} marginBottom={8} marginTop={2}>
            {limitDetails.map((ele) => (
              <Grid item xs={4}>
                {ele?.label === utilisedLimit || ele?.label === blockedLimit ? (
                  <Box display={"flex"} flexDirection={"column"} gap={2}>
                    <Typography fontWeight={400}>{ele.label}</Typography>
                    <ButtonV1
                      onClick={() => handleShowBreakUp(ele?.label)}
                      title={ele?.value}
                      variant="text"
                      size="small"
                      disableRipple={true}
                      endIcon={
                        <img
                          width={16}
                          src="/assets/external-link.svg"
                          style={{
                            stroke: theme.palette.primary.main,
                          }}
                          alt="external link icon"
                        />
                      }
                      boxStyle={{
                        justifyContent: "flex-start",
                        alignItems: "start",
                        padding: 0,
                      }}
                      style={{
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                    />
                  </Box>
                ) : (
                  <Box display={"flex"} flexDirection={"column"} gap={2}>
                    <Typography fontWeight={400}>{ele.label}</Typography>
                    <Typography fontWeight={600}>{ele.value}</Typography>
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <ModalBox
        open={openAmountModal}
        onCloseModal={() => setOpenAmountModal(false)}
      >
        <AmountBreakupTable
          data={amountBreakupInfo?.data}
          onClose={() => setOpenAmountModal(false)}
          type={breakupAmountType}
        />
      </ModalBox>
      <UpdateCreditInstrumentModal
        open={isModalOpen}
        handleModal={handleModal}
        data={instrumentDetails}
        customerDetails={customerDetails}
        omsUserDetail={omsUserDetail}
      />
    </>
  );
};

export default CreditInstrumentView;
