import * as Yup from "yup";
import { ORDER_BLOCK } from "../constant";
import ERROR_MSG from "./constant";

const { manualUpload, invoiceYup } = ORDER_BLOCK;

export const orderBlockSchema = Yup.object().shape({
  [invoiceYup]: Yup.array().of(
    Yup.object().shape({
      [manualUpload.orderId]: Yup.string().required(
        ERROR_MSG[manualUpload.orderId],
      ),
      [manualUpload.entityGstin]: Yup.string()
        .length(15, "GSTIN must be 15 characters")
        .required(ERROR_MSG[manualUpload.entityGstin]),
      [manualUpload.orderAmount]: Yup.number()
        .required(ERROR_MSG[manualUpload.orderAmount])
        .test({
          name: "orderAmount",
          test(_value, ctx) {
            const { advanceAmount, creditAmount, orderAmount } = ctx.parent;
            if (orderAmount !== advanceAmount + creditAmount) {
              return ctx.createError({
                message: `Please enter a valid order amount`,
              });
            }
            return true;
          },
        }),
      [manualUpload.advanceAmount]: Yup.number().required(
        ERROR_MSG[manualUpload.advanceAmount],
      ),
      [manualUpload.creditAmount]: Yup.number().required(
        ERROR_MSG[manualUpload.creditAmount],
      ),
      [manualUpload.creditInstrument]: Yup.string().required(
        ERROR_MSG[manualUpload.creditInstrument],
      ),
    }),
  ),
});
