import { Typography } from "@mui/material";
import theme from "themeProvider";

const ErrorMsg = ({ msg }) => (
  <Typography
    style={{
      fontSize: 12,
      color: theme.palette.error.dark,
      visibility: msg ? "visible" : "hidden",
      height: 12,
    }}
  >
    {msg}
  </Typography>
);

export default ErrorMsg;
