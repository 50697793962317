import { Link } from "@mui/material";
import PropTypes from "prop-types";

const HyperLink = ({ url = "#", title, isOrderHyperlink = true }) => {
  const linkUrl = isOrderHyperlink ? `/order-list/${title}` : url;

  /**
   *
   * @description prevent event getting passed to parent
   */
  const preventEventPropagation = (ev) => {
    ev.stopPropagation();
  };

  return title ? (
    <Link
      href={linkUrl}
      target="_blank"
      underline="none"
      onClick={preventEventPropagation}
    >
      {title}
    </Link>
  ) : (
    "-"
  );
};

export default HyperLink;

HyperLink.propTypes = {
  title: PropTypes.string.isRequired,
  isOrderHyperlink: PropTypes.bool,
  url: PropTypes.string,
};
