import axios from "./axiosInstance";

const convertBlobToText = async (err) => {
  return JSON.parse(await err?.response?.data?.text());
};

export const postCall = async (url, data, params = {}, errorCallback = "") => {
  try {
    const response = await axios.post(url, data, {
      params,
    });
    return response.data;
  } catch (err) {
    errorCallback && errorCallback(err?.response);
  }
};

export const putCall = async (url, data, params = {}, errorCallback = "") => {
  try {
    const response = await axios.put(url, data, {
      params,
    });
    return response.data;
  } catch (err) {
    errorCallback && errorCallback(err?.response);
  }
};

export const getCall = async (
  url,
  params = {},
  errorCallback = "",
  responseType = "json",
) => {
  try {
    const response = await axios.get(url, {
      params,
      responseType,
    });
    return response.data;
  } catch (err) {
    if (!errorCallback) return;

    if (responseType === "blob") {
      errorCallback(await convertBlobToText(err));
    } else {
      errorCallback(err?.response);
    }
  }
};

export const getCall_v2 = async (url, params = {}, responseType = "json") => {
  try {
    const response = await axios.get(url, {
      params,
      responseType,
    });
    return response;
  } catch (err) {
    if (responseType === "blob") {
      throw await convertBlobToText(err);
    } else {
      throw err;
    }
  }
};

export const patchCall = async (url, data, params = {}, errorCallback = "") => {
  try {
    const response = await axios.patch(url, data, {
      params,
    });
    return response.data;
  } catch (err) {
    errorCallback && errorCallback(err?.response);
  }
};

export const deleteCall = async (
  url,
  data,
  params = {},
  errorCallback = "",
) => {
  try {
    const response = await axios.delete(url, {
      data,
      params,
    });
    return response.data;
  } catch (err) {
    errorCallback && errorCallback(err?.response);
  }
};

export const deleteCall_v2 = async (url, data, params = {}) => {
  try {
    const response = await axios.delete(url, {
      data,
      params,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const postCall_v2 = async (url, data, params = {}) => {
  try {
    const response = await axios.post(url, data, {
      params,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const putCall_v2 = async (url, data, params = {}) => {
  try {
    const response = await axios.put(url, data, {
      params,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};
