import { useState, useEffect, useMemo, Fragment } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { Loader, ViewOnly, ButtonV1, ModalBox } from "components";
import CreateShipment from "./CreateShipment";
import ShipmentUpdate from "./ShipmentUpdate";

import { useFetchData, useToaster } from "hooks";
import { setShipmentDetailsAction } from "dataStore/shipmentDetails";
import { enableShipmentCreation } from "./shipmentCalculationUtil";
import { orderStatus } from "./constants";
import { error,categoryType } from "constants";

const SellerOrderDetail = ({ sellerOrderNumber, companyName }) => {
  return useMemo(
    () => (
      <Box style={{ display: "flex" }}>
        <ViewOnly label="Seller Order - " value={sellerOrderNumber} />
        <Typography sx={{ ml: 4, mr: 4 }}>|</Typography>
        <ViewOnly label="Seller Name - " value={companyName} />
      </Box>
    ),
    /*eslint-disable */
    [],
  );
};

const ShipmentsTab = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const triggerToaster = useToaster();
  const orderDetails = useSelector((state) => state?.orderDetails);

  const { search } = useLocation();
  const shipmentIdParam = new URLSearchParams(search).get("shipmentId");
  const customerPan = orderDetails?.buyerDetails?.gstin.substr(2, 10);
  const customerGst = orderDetails?.buyerDetails?.gstin;

  const [openShipmentCreateModal, toggleOpenShipmentCreateModal] =
    useState(false);

  const [shipmentData, setShipmentData] = useState([]);

  const { isLoading, refetch: getShipmentData } = useFetchData(
    "shipment-details",
    `/oms/shipment/order-number/${id}`,
    (res) => {
      const data = res?.data?.sellerShipmentsInfo ?? [];
      setShipmentData(data);
      dispatch(setShipmentDetailsAction(data));
    },
  );

  const { refetch: fetchCustomerCreditInfo, data: customerCreditInfo } =
    useFetchData(
      "customer-credit-info",
      `/oms/credit/getCreditInfoByPan/?pan=${customerPan}&gstin=${customerGst}`,
    );

  /**
   * @description Fetch data for processing charges
   */
  const { data: zohoProcessingCharges, refetch: fetchProcessingCharges } =
    useFetchData("processing-charges", `/oms/shipment/processing-charges`);

  useEffect(() => {
    orderDetails.orderStatus === orderStatus.placed &&
      triggerToaster("Please confirm order, to create shipment", error);
  }, [orderDetails]);

  useEffect(() => {
    getShipmentData();
    fetchProcessingCharges();
    fetchCustomerCreditInfo();
    /*eslint-disable */
  }, []);

  const shipmentCreateModal = () =>
    toggleOpenShipmentCreateModal(!openShipmentCreateModal);

  const fetchNewShipment = () => {
    shipmentCreateModal();
    getShipmentData();
  };

  if (isLoading) return <Loader />;
  return (
    <>
      <Typography variant="h3">Shipment details</Typography>
      {shipmentData &&
        shipmentData.map((element, index) => {
          return (
            <Fragment key={index}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 16,
                }}
              >
                <SellerOrderDetail
                  sellerOrderNumber={element.sellerOrderNumber}
                  companyName={element?.sellerInfo?.companyName}
                />
                {!index && (
                  <ButtonV1
                    title="Create shipment"
                    onClick={shipmentCreateModal}
                    color="secondary"
                    disabled={
                      !enableShipmentCreation(shipmentData, orderDetails)
                    }
                  />
                )}
              </Box>
              {element?.shipmentDetails?.length &&
                element.shipmentDetails.map((data, _) => {
                  const sellerShipmentsInfo = element?.lineItems[0]?.sellerShippingAddress;
                  const shipmentInfoObject = {
                    ...data,
                    sellerShipmentsInfo
                  }
                  return (
                    <ShipmentUpdate
                      shipmentInfo={shipmentInfoObject}
                      lineItems={element?.lineItems}
                      key={data.id}
                      id={Number(shipmentIdParam)}
                      getShipmentData={getShipmentData}
                      zohoProcessingCharges={zohoProcessingCharges?.data?.data}
                    />
                  );
                })}

              <ModalBox
                open={openShipmentCreateModal}
                onCloseModal={shipmentCreateModal}
                width={"60%"}
              >
                <CreateShipment
                  shipmentData={shipmentData}
                  closeModal={fetchNewShipment}
                />
              </ModalBox>
            </Fragment>
          );
        })}
    </>
  );
};

export default ShipmentsTab;
