import { useState } from "react";
import { Box } from "@mui/material";
import { Chip, useTheme, Grid } from "@mui/material";

import UploadCSV from "components/UploadCSV";
import ReviewContent from "./UpdateCreditLimit/ReviewContent";
import CreateReviewContent from "./CreateCreditLimit/ReviewContent";

import CREDIT_UPDATE from "../constants";

const { CREDIT_LIMIT_TABS } = CREDIT_UPDATE;

/**
 *
 * @description Update credit limit component through csv
 */
const UpdateCreditLimit = () => {
  const theme = useTheme();
  const [fileContent, setFileContent] = useState(null);
  const [selectedTab, setSelectedTab] = useState(
    CREDIT_LIMIT_TABS?.createCreditLimit,
  );

  /**
   *
   * @description callback fn on file upload
   */
  const fileUploadCallback = (contents) => {
    if (contents) {
      setFileContent(contents);
    }
  };

  /**
   *
   * @description Delete file contents on cancellation
   */
  const removeFileContents = () => setFileContent(null);

  /**
   * @description Tab change
   */
  const handleTabChange = (ev) => {
    setSelectedTab(ev?.target?.innerText);
    removeFileContents();
  };

  /**
   * @description Change review section as per tab change
   */
  const ReviewSection = () => {
    if (selectedTab === CREDIT_LIMIT_TABS?.createCreditLimit) {
      return (
        <CreateReviewContent
          creditData={fileContent}
          onCancel={removeFileContents}
        />
      );
    }
    return (
      <ReviewContent creditData={fileContent} onCancel={removeFileContents} />
    );
  };

  return (
    <>
      <Box p={4}>
        <Box mb={8}>
          {/* Tab management */}
          <Grid container>
            {Object.values(CREDIT_LIMIT_TABS).map((label) => {
              return (
                <Grid item xs={2}>
                  <Chip
                    key={label}
                    label={label}
                    style={{
                      color:
                        label === selectedTab
                          ? theme.palette.grey["A800"]
                          : theme.palette.info.blueNavyColor,
                      fontWeight: label === selectedTab ? 600 : "normal",
                      border: 0,
                      borderBottom:
                        label === selectedTab &&
                        `2px solid ${theme.palette.info.blueBorderColor}`,
                      borderRadius: 0,
                      fontSize: 14,
                      backgroundColor:
                        label === selectedTab
                          ? theme.palette.info.lightBlueBackround
                          : theme.palette.common.white,
                    }}
                    onClick={handleTabChange}
                  />
                </Grid>
              );
            })}
          </Grid>
          {/* Tab management ends */}
        </Box>
        <UploadCSV
          headerText={"Upload credit limit sheet"}
          parsedFileCallbackFn={fileUploadCallback}
        />
        {ReviewSection()}
      </Box>
    </>
  );
};

export default UpdateCreditLimit;
