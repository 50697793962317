import { categoryType } from "constants";
import { removeTimeOffset, checkIsDirectOrder } from "utils";
import { seller, customer } from "./constants";

export const getSumOfLineItem = (lineItems, key) => {
  return lineItems.reduce((acc, lineItem) => acc + lineItem[key], 0);
};

export const isValidQuantityForDelivery = (lineItemDetails) => {
  const quantityDelivered = getSumOfLineItem(
    lineItemDetails,
    "quantityDelivered",
  );
  const quantityShortClose = getSumOfLineItem(
    lineItemDetails,
    "quantityShortClose",
  );
  const quantityCancelled = getSumOfLineItem(
    lineItemDetails,
    "quantityCancelled",
  );
  const quantityOrdered = getSumOfLineItem(lineItemDetails, "quantityOrdered");
  if (
    quantityDelivered + quantityShortClose + quantityCancelled >=
    quantityOrdered * 0.95
  ) {
    return true;
  }
  return false;
};

export const getOrderAmounts = (amounts) => {
  const totalPaidAmount = amounts?.TPA?.amount.centAmount;
  const totalOrderAmount = amounts?.TOA?.amount?.centAmount;
  const totalPendingAmount =
    amounts?.TOA?.amount?.centAmount +
    amounts?.TTA?.amount?.centAmount -
    amounts?.TPA?.amount?.centAmount;
  return { totalPaidAmount, totalOrderAmount, totalPendingAmount };
};

export const isValidQuantityForCreated = (amounts) => {
  const { totalPaidAmount, totalOrderAmount, totalPendingAmount } =
    getOrderAmounts(amounts);

  return totalPaidAmount === totalOrderAmount && totalPendingAmount !== 0;
};

const getChangeInChildOrderStatus = (items, orderInfo) => {
  let childOrderStatus = [];
  const { category } = orderInfo;

  if (checkIsDirectOrder(category)) {
    if (
      items?.isVerifiedPO &&
      items?.isVerifiedSO &&
      (!orderInfo?.isVerifiedPO || !orderInfo?.isVerifiedSO)
    ) {
      childOrderStatus = orderInfo?.childOrders;
      return childOrderStatus;
    }
  } else {
    if (items?.isVerifiedPO && !orderInfo?.isVerifiedPO) {
      childOrderStatus = orderInfo?.childOrders;
      return childOrderStatus;
    }
  }

  orderInfo?.childOrders &&
    orderInfo?.childOrders.forEach(({ sellerOrderNumber, currentState }) => {
      if (items.sellerOrderStatus?.[sellerOrderNumber] !== currentState) {
        childOrderStatus.push({
          sellerOrderNumber: sellerOrderNumber,
          updatedStatus: items.sellerOrderStatus?.[sellerOrderNumber],
        });
      }
    });
  return childOrderStatus;
};

export const getUpdatedData = (items, id, orderInfo, isPayoutApplicable) => {
  const checkOrderIsAlreadyConfirmed = () => {
    const { category } = orderInfo;
    if (checkIsDirectOrder(category)) {
      return orderInfo?.isVerifiedPO && orderInfo?.isVerifiedSO;
    } else {
      return orderInfo?.isVerifiedPO ? true : false;
    }
  };
  return {
    specialDeliveryInstructions: items?.specialDeliveryInstructions,
    orderStatus: items?.orderStatus,
    reasonForCancellation: items?.reasonForCancellation,
    committedDeliverDate: removeTimeOffset(items?.committedDeliveryDate),
    scheduledDeliverDate: removeTimeOffset(items?.scheduledDeliveryDate),
    nextActionDate: removeTimeOffset(items?.nextActionDate),
    salesSpoc: items?.salesSpoc,
    channelOfOrder: items?.channelOfOrder,
    opsSpoc: items?.opsSpoc,
    orderType: items?.orderType,
    businessUnit: items?.businessUnit,
    deliveryType: items?.deliveryType,
    orderNumber: id,
    buyerComments: items?.buyerComments,
    sellerComments: items?.sellerComments,
    childOrders: getChangeInChildOrderStatus(items, orderInfo),
    paymentType: items?.paymentType,
    reasonForDecline: items?.reasonForDecline,
    isVerifiedPO: items?.isVerifiedPO,
    isVerifiedSO: items?.isVerifiedSO,
    isOrderConfirmed: checkOrderIsAlreadyConfirmed(),
    isCreditApproved: orderInfo?.isCreditApproved,
    isPayoutApplicable,
  };
};

export const makeCashbackData = (orderInfo, updatedValues) => {
  const cashBacks = orderInfo?.cashBacks;

  let cashbackUpdateObj = [];
  // eslint-disable-next-line array-callback-return
  Object.keys(cashBacks).map((item) => {
    const { id } = cashBacks[item];
    let cashbackObj = { id, name: item };

    if (cashBacks[item]["cashbackType"] === seller) {
      cashbackObj.amount = isNaN(parseFloat(updatedValues[`${seller}-${id}`]))
        ? null
        : parseFloat(updatedValues[`${seller}-${id}`]);
      cashbackObj.cashbackType = seller;
    } else if (cashBacks[item]["cashbackType"] === customer) {
      cashbackObj.amount = isNaN(parseFloat(updatedValues[`${customer}-${id}`]))
        ? null
        : parseFloat(updatedValues[`${customer}-${id}`]);
      cashbackObj.cashbackType = customer;
    }

    cashbackUpdateObj.push(cashbackObj);
  });

  return cashbackUpdateObj;
};
