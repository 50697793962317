const CREDIT_UPDATE = Object.freeze({
  tab: {
    creditApproval: "Credit Approval",
    creditCustomer: "Credit Customer",
    pendingApproval: "Pending approvals",
    bulkUpload: "Bulk upload",
  },
  apiRequestType: {
    insert: "INSERT",
  },
  lakh: 100000,
  creditType: "LC",
  tableHeader: {
    name: "Customer name / PAN / GSTIN",
    camID: "Credit limit Reference ID",
    instrument: "Credit instrument",
    document: "CAM document",
    limit: "Sanctioned limit",
    utilisedLimit: "Utilised limit",
    expireBy: "Expiry date",
    requestedBy: "Requested by",
    remark: "Remark",
    action: "Action",
  },
  pendingApprovalHeader: {
    name: "Customer name / PAN / GSTIN",
    camID: "Credit limit Reference ID",
    instrument: "Credit instrument",
    document: "CAM document",
    limit: "Sanctioned limit",
    utilisedLimit: "Utilised limit",
    expireBy: "Expiry date",
    requestedBy: "Requested by",
    remark: "Remark",
    status: "Status",
  },
  orderInReview: "IN_REVIEW",
  orderApproved: "APPROVED",
  orderRejected: "REJECTED",
  orderStatus: {
    REJECTED: "Rejected",
    APPROVED: "Approved",
    IN_REVIEW: "Progress",
  },
  pendingOrderStatus: {
    REJECTED: "Rejected",
    APPROVED: "Approved",
    IN_REVIEW: "Pending",
  },
  actionType: {
    reject: "reject",
    approve: "approve",
  },
  modalType: {
    date: "date",
    amount: "amount",
    string: "string",
  },
  searchFilter: {
    searchType: "searchType",
    searchValue: "searchValue",
    searchStatus: "searchStatus",
  },
  searchTypeList: [
    { label: "Customer Name", value: "Customer Name" },
    { label: "Email", value: "Email" },
    { label: "Mobile Number", value: "Mobile Number" },
    { label: "PAN", value: "PAN" },
    { label: "GSTIN", value: "GSTIN" },
  ],
  blockedLimitBreakupHeader: ["Order ID", "Blocked Amount"],
  utilisedLimitBreakupHeader: [
    "Order ID",
    "Invoice ID",
    "Utilized amount",
    "Blocked amount",
  ],
  CREDIT_LIMIT_TABS: {
    createCreditLimit: "Create credit limit",
    updateCreditLimit: "Update credit limit",
  },
  CONFIRMATION_APPROVALS_HEADER: [
    "Customer name",
    "Credit limit Reference ID",
    "Credit instrument",
    "Sanctioned limit",
  ],
});

export default CREDIT_UPDATE;
