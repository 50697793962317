import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const ColumnView = ({ label, children, bold = false }) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
      }}
    >
      <Typography
        fontWeight={bold ? "bold" : "normal"}
        style={{ marginBottom: 6 }}
      >
        {label}
      </Typography>
      <Box>{children}</Box>
    </Box>
  );
};

ColumnView.propTypes = {
  label: PropTypes.string.isRequired,
  bold: PropTypes.bool,
  children: PropTypes.element,
};

export default ColumnView;
