import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  CheckBoxOutlineBlank,
  CheckBox as CheckBoxOutline,
} from "@mui/icons-material";
import { Autocomplete, TextField, Checkbox } from "@mui/material";
import PropTypes from "prop-types";

import CustomChip from "./CustomChip";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxOutline fontSize="small" />;

const MultiSelectSearch = forwardRef(
  (
    {
      defaultSelectedValues,
      displayAttribute,
      label,
      onSearch,
      sx,
      setIsDirty,
      customChip = { size: "small" },
      ...rest
    },
    ref,
  ) => {
    let timer = null;
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);

    useImperativeHandle(ref, () => ({
      fetchValue: () => selectedValue,
      resetValue: () => {
        setSelectedValue([]);
        setIsDirty && setIsDirty(false);
        setOptions([]);
      },
    }));

    useEffect(() => {
      if (defaultSelectedValues?.length) {
        setSelectedValue(defaultSelectedValues?.filter((val) => val));
        setIsDirty && setIsDirty(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInputChange = (_event, value) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(async () => {
        if (onSearch && value) {
          const options = await onSearch(value);
          Array.isArray(options) && setOptions(options);
        }
      }, 1000);
    };

    const handleChange = useCallback((_event, list) => {
      if (list.length) {
        setIsDirty && setIsDirty(true);
      } else {
        setIsDirty && setIsDirty(false);
      }
      setSelectedValue(list);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dropDownOption =
      options?.filter((option) => {
        const isSelected = selectedValue?.some(
          (selected) => selected[displayAttribute] === option[displayAttribute],
        );
        return isSelected ? false : true;
      }) || [];

    return (
      <Autocomplete
        multiple
        limitTags={1}
        size="small"
        disableCloseOnSelect
        options={[...selectedValue, ...dropDownOption]}
        value={selectedValue}
        onInputChange={handleInputChange}
        noOptionsText={"No Options Found"}
        onChange={handleChange}
        getOptionLabel={(option) => option?.[displayAttribute] || option}
        renderOption={(props, option, { selected }) => {
          const displayValue = option?.[displayAttribute] || option;
          if (!displayValue) return <></>;
          return (
            <li {...props} style={{ padding: 0 }}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                checked={selected}
              />
              {displayValue}
            </li>
          );
        }}
        disableClearable
        renderTags={(value, getTagProps) => {
          return value.map((option, index) => (
            <CustomChip
              option={option}
              displayAttribute={displayAttribute}
              {...customChip}
              {...getTagProps({ index })}
            />
          ));
        }}
        renderInput={(params) => <TextField {...params} label={label} />}
        sx={{
          ".MuiAutocomplete-tag": {
            maxWidth: "calc(100% - 60px)",
          },
          ".MuiAutocomplete-input": {
            minWidth: "10px !important",
          },
          ...sx,
        }}
        {...rest}
      />
    );
  },
);

MultiSelectSearch.propTypes = {
  defaultSelectedValues: PropTypes.array,
  displayAttribute: PropTypes.string,
  label: PropTypes.string,
  onSearch: PropTypes.func,
  sx: PropTypes.object,
  setIsDirty: PropTypes.func,
};

export default MultiSelectSearch;
