import { appendRupeeIcon, formatDate } from "utils";
import { disbursementState } from "./constants";
import { paymentStatus } from "constants";

const makeData = (summary, paymentConfirmation) => {
  let shipmentSummaryData = [
    {
      label: "Invoice date",
      value: formatDate(
        summary.shipmentInvoiceDetails?.invoiceDetails[0]?.invoiceDate,
      ),
    },
    {
      label: "TCS on GST (1%) on basic value",
      value: appendRupeeIcon(summary?.shipmentTcsAmts?.displayAmount),
    },
    {
      label: "TDS (1%) on basic value",
      value: appendRupeeIcon(summary?.shipmentTdsAmts?.displayAmount),
    },
    {
      label: "Tax deductions (TCS + TDS)",
      value: appendRupeeIcon(summary.taxDeduction?.displayAmount),
    },
    {
      label: "JOPL commission from seller",
      value: appendRupeeIcon(summary?.shipmentCmsAmts?.displayAmount) ?? 0,
    },
    {
      label: "Payable to seller",
      value: appendRupeeIcon(summary?.shipmentAmtToBePaids?.displayAmount),
    },
    {
      label: "Payable now",
      value: appendRupeeIcon(summary?.invPayableNowAmount?.displayAmount),
    },
    {
      label: "Payout completed",
      value: appendRupeeIcon(
        summary?.shipmentPayoutCompletedDisplayAmt?.displayAmount,
      ),
    },
  ];

  let confirmPaymentData = [
    {
      label: "Payout date",
      value: new Date(summary?.shipmentPayoutDate).toLocaleDateString(),
    },
    {
      label: "Razorpay ID",
      value: summary?.shipmentTransferId ?? "",
    },
  ];
  if (
    disbursementState.success === paymentConfirmation ||
    disbursementState.confirmation === paymentConfirmation
  ) {
    shipmentSummaryData = [...shipmentSummaryData, ...confirmPaymentData];
  }

  const shipmentSummaryHeader = [
    { label: `Seller order no.`, value: summary?.sellerOrdNumber },
    { label: "Shipment no.", value: summary?.shipmentNumber },
    {
      label: "Shipment invoice total",
      value: appendRupeeIcon(summary?.totalInvoicedAmounts?.displayAmount),
    },
    {
      label: "Invoice no.",
      value: summary?.shipmentInvoiceDetails?.invoiceDetails[0]?.invoiceNumber,
    },
    {
      label: "State of disbursement",
      value: paymentStatus[summary?.stateOfDisbursement?.state],
    },
  ];
  return { shipmentSummaryData, shipmentSummaryHeader };
};

export default makeData;
