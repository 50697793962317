import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { GridSkeleton, CustomViewOnly } from "./CustomComponents";
import BuyerRefundSummary from "./BuyerRefundSummary";
import SellerDetails from "./SellerDetails";

import { Loader } from "components";

import { useFetchData, useToaster } from "hooks";
import { appendRupeeIcon } from "utils";
import { postCall_v2 } from "services";
import useFetchParam from "pages/FinancePayout/useFetchParam";

import {
  success,
  error,
  disbursementState,
  financePayouts,
  CREATE_REFUND_SUCCESS_MSG,
} from "./constants";

const ReconciliationTab = () => {
  const [reconData, setreconData] = useState(null);
  const { updateParams } = useFetchParam();
  const navigate = useNavigate();
  const triggerToaster = useToaster();

  const { id } = useParams();

  const { refetch, isLoading } = useFetchData(
    "payment-summary",
    `/oms/payout/seller-payout-summaries/order-number/${id}`,
    (res) => {
      setreconData(res.data);
      const { sellerPayoutSummary } = res.data;
      let totalShipmentPayouts = [];
      if (sellerPayoutSummary) {
        // eslint-disable-next-line array-callback-return
        sellerPayoutSummary?.payoutSellerSummary.map((ele) => {
          totalShipmentPayouts = [
            ...totalShipmentPayouts,
            ...ele.shipmentPayouts,
          ];
        });
      }
    },
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFinanceRouting = (item, isInvoice) => {
    const state = isInvoice
      ? item?.stateOfDisbursement?.state
      : item?.statusState;
    let tabType;

    switch (state) {
      case disbursementState.pending:
        tabType = financePayouts.financeTab.pending;
        break;
      case disbursementState.success:
      case disbursementState.rejected:
        tabType = financePayouts.financeTab.historical;
        break;

      default:
        tabType = financePayouts.financeTab.approve;
        break;
    }

    const { paramName } = updateParams({
      param: {
        [financePayouts.queryFilter.tab]: tabType,
        [financePayouts.queryFilter.transactionType]: isInvoice
          ? financePayouts.financeType.invoiceSeller
          : financePayouts.financeType.refundCashback,
        [financePayouts.payoutFilters.orderNumber]: id,
      },
      setParam: false,
      appendWithExisting: false,
    });

    navigate(`/finance-payout?filter=${paramName}`);
  };

  const refetchSummaryData = (res, message) => {
    if (res === success) {
      triggerToaster(message ?? `Refund initiated successfully`, success);
      refetch();
    } else {
      triggerToaster(
        message ?? `Refund initialisation failed, please try again later`,
        error,
      );
    }
  };

  /**
   * @description Handle initiate refund and show message
   */
  const handleInitiateRefund = async () => {
    try {
      const res = await postCall_v2(
        `/oms/payout/create-refund?buyerOrdNumber=${id}`,
      );
      if (res?.data) {
        triggerToaster(
          res?.data?.message ?? CREATE_REFUND_SUCCESS_MSG,
          success,
        );
        refetch();
      }
    } catch (err) {
      triggerToaster(err?.response?.data?.detail, error);
    }
  };

  if (isLoading) return <Loader />;
  return (
    <>
      {reconData && (
        <>
          <Box sx={{ mt: 2, mb: 4 }}>
            <GridSkeleton
              firstChild={
                <CustomViewOnly
                  label="Order amount"
                  value={appendRupeeIcon(
                    reconData?.totalOrderAmountDisplay?.displayAmount,
                  )}
                />
              }
              secondChild={<CustomViewOnly label="Order Tax:" />}
            />
            <GridSkeleton
              firstChild={
                <CustomViewOnly
                  label="Invoice amount"
                  value={appendRupeeIcon(
                    reconData?.orderInvoiceAmountDisplay?.displayAmount,
                  )}
                />
              }
              secondChild={
                <CustomViewOnly
                  labelStyle={{ marginLeft: "1rem" }}
                  label={"TCS:"}
                  value={appendRupeeIcon(
                    reconData?.orderTcsAmountDisplay?.displayAmount,
                  )}
                />
              }
            />
            <GridSkeleton
              secondChild={
                <CustomViewOnly
                  labelStyle={{ marginLeft: "1rem" }}
                  label={"TDS:"}
                  value={appendRupeeIcon(
                    reconData?.orderTdsAmountDisplay?.displayAmount,
                  )}
                />
              }
            />
            <GridSkeleton
              secondChild={
                <CustomViewOnly
                  labelStyle={{ marginLeft: "1rem" }}
                  label="JOPL commission"
                  value={
                    appendRupeeIcon(
                      reconData?.orderCmsAmountDisplay?.displayAmount,
                    ) ?? "-"
                  }
                />
              }
            />
          </Box>
          <SellerDetails
            reconData={reconData}
            onIntiateDisbursement={handleFinanceRouting}
          />
          <BuyerRefundSummary
            buyerRefundSummary={reconData?.buyerRefundSummary}
            refetchSummaryData={refetchSummaryData}
            customerOrderId={reconData?.customerOrderId}
            onClickStatus={handleFinanceRouting}
            onInitiateRefund={handleInitiateRefund}
          />
          {/* <ModalBox open={false} onCloseModal={onCloseRefund} width="90%">
            <span>Initiate buyer refund</span>
          </ModalBox> */}
        </>
      )}
    </>
  );
};
export default ReconciliationTab;
