export const CreditNotesSellerPayout = Object.freeze({
  creditNotesManualInput: {
    transactionType: "transactionType",
    fromAccount: "fromAccount",
    toAccount: "toAccount",
    originalInvoiceId: "originalInvoiceId",
    documentId: "documentId",
    documentDate: "documentDate",
    invoiceComments: "invoiceComments",
    documentAmount: "documentAmount",
    baseAmount: "baseAmount",
    cgstAmount: "cgstAmount",
    sgstAmount: "sgstAmount",
    igstAmount: "igstAmount",
    gstAmount: "gstAmount",
    reverseTcsTds: "reverseTcsTds",
    payoutRequired: "payoutRequired",
    documentInfo: "documentInfo",
  },
  transactionTypes: ["Sales return", "Credit note"],
  reviewTransactionHeaders: [
    "Order type",
    "Order no. / Entity name",
    "Transaction type",
    "Document ID / Original invoice no.",
    "Document date",
    "Payout date",
    "Document amount",
    "Document",
    // "Action",
    "Comment",
  ],
  pendingTransactionHeaders: [
    "Order type",
    "Order no. / Entity name",
    "Transaction type",
    "Document ID / Original invoice no.",
    "Document date",
    "Payout date",
    "Document amount",
    "Status",
    "Document",
    "Action",
  ],
  msgStatements: {
    DELETE_CONFIRMATION_MSG: "Are you sure to delete?",
    SAVED_SUCCESS_MSG: "Transactions have been successfully uploaded",
    ERROR_MSG: "An error occured, please try again.",
    TRANSACTION_SAVED_MSG: `Transaction has been saved successfully`,
    TRANSACTION_UPDATED_MSG: `Transaction has been updated successfully`,
    CLEAR_ALL_MSG: "Are you sure you want to clear all?",
    CLEAR_ONE_MSG: "Are you sure you want to clear record",
    GST_ERROR_MSG: "GSTIN doesn’t exist on ERP",
    PAYOUT_ERROR_MSG: "Invalid payout amount",
    PAYOUT_TYPE_ERROR_MSG: "Invalid payout type",
    GST_ERROR: "GSTIN doesn’t exist",
  },
  transactionFilterValue: {
    "Invoice & seller payouts": "Invoice & seller payouts",
    "Refunds & Cashbacks": "RC",
    "Internal fund transfer": "Internal fund transfer",
    "Incoming payments": "Incoming payments",
    "Credit note & Sales return": "Credit note & Sales return",
  },
  creditYup: "creditYup",
  creditFieldErrors: {
    fromAccount: "GSTIN doesn’t exist",
    toAccount: "GSTIN doesn’t exist",
    transactionType: "Invalid transaction type",
  },
});
