import { createSlice } from "@reduxjs/toolkit";

export const approvalPayoutUploadFlow = createSlice({
  name: "approvalPayoutUploadFlow",
  initialState: false,
  reducers: {
    toggleApprovalFlow: (_, action) => {
      return action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleApprovalFlow } = approvalPayoutUploadFlow.actions;

export default approvalPayoutUploadFlow.reducer;
