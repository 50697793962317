import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
  Checkbox,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";

import {
  ButtonV1,
  Loader,
  PaginationAction,
  StatusChip,
  Timestamp,
} from "components";
import HoverBlock from "../components/HoverBlock";
import ApproveRejectModalConfirmation from "./ApproveRejectModalConfirmation";

import { getCall_v2, postCall_v2 } from "services";
import { error, success, STATUS_CHIP } from "constants";
import {
  dateConvertor,
  moneyFormat,
  validateNull,
  pascalCase,
  getSubstring,
} from "utils";
import { useFetchData, useToaster, useDownload } from "hooks";
import CREDIT_UPDATE from "../constants";

const rowsPerPage = 20;
const {
  apiRequestType: { insert },
} = CREDIT_UPDATE;

const PendingApprovalTable = ({ filter }) => {
  const [page, setPage] = useState(0);
  const [isAllChecked, setAllChecked] = useState(false);
  const [selectedCreditList, setSelectedCreditList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isApprove, setIsApprove] = useState(false);

  const { downloadFile } = useDownload();
  const triggerToaster = useToaster();

  const orderDetails = useSelector((state) => state.userData);

  const filterParams = useMemo(() => {
    if (filter) {
      const { searchStatus } = filter;
      searchStatus
        ? (filter.searchStatus = CREDIT_UPDATE.orderInReview)
        : (filter.searchStatus = "");
    }
    return filter ? new URLSearchParams(filter).toString() : "";
  }, [filter]);

  const url = useMemo(() => {
    return `oms/credit/getCreditUpdateRequestListForMaker?offset=${
      page * rowsPerPage
    }&limit=${rowsPerPage}&${filterParams}`;
  }, [page, filterParams]);

  const {
    data,
    isFetching,
    refetch: fetchPendingCreditList,
  } = useFetchData("credit-pending", url);

  const handlePageChange = useCallback((_event, pageNumber) => {
    setPage(pageNumber);
    setAllChecked(false);
    setSelectedCreditList([]);
  }, []);

  useEffect(() => {
    fetchPendingCreditList();
  }, [url, fetchPendingCreditList]);

  /**
   *
   * @param {*} comments
   * @description this function splits the comment using a given sequence
   * @returns  object of splitted comments with respective keys
   */
  const getFormattedComments = (comment) => {
    let splittedComment = comment.split("@#%");
    const requesterComments =
      splittedComment?.[0]?.replace("Requester comments -", "") ?? "-";
    const approverComments =
      splittedComment?.[1]?.replace("Approver comments-", "") ?? "-";
    let formattedComment = {
      "Requester comments": requesterComments,
      "Approver comments": approverComments,
    };
    return formattedComment;
  };

  const getUpdatedDate = (date, status) => {
    const label = `${pascalCase(status)} on`;
    let formattedObj = {};
    formattedObj[label] = dateConvertor(date);
    return formattedObj;
  };

  const handleDocDownload = useCallback(async (filePath) => {
    const fileName = "CAM document";
    try {
      const { data } = await getCall_v2(
        `/oms/credit/documents/customerCreditDocs`,
        { filePath: filePath },
        "blob",
      );
      downloadFile(data, fileName);
      triggerToaster(`Downloading ${fileName}`, success);
    } catch (err) {
      triggerToaster(`Unable to download ${fileName}`, error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @description Select and unselect all items by selecting parent checkbox where requestType === "INSERT"
   */
  const handleParentCheckbox = (e) => {
    let tempData = [];
    if (e.target.checked) {
      tempData = data?.data.response.filter(
        (ele) => ele?.requestType === insert,
      );
    } else {
      tempData = [];
    }
    setAllChecked(e.target.checked);
    setSelectedCreditList(tempData);
  };

  /**
   * @description Select and unselect item
   */
  const handleChildCheckbox = (e, i, val) => {
    let tempSelectedData = [...selectedCreditList];
    if (e.target.checked) {
      tempSelectedData.push(data?.data.response[i]);
    } else {
      tempSelectedData = tempSelectedData.filter(
        (item) => item?.["updateId"] !== val?.["updateId"],
      );
    }
    setSelectedCreditList(tempSelectedData);
  };

  /**
   * @description Validated checkbox is selected or not.
   * @param {*} updateId
   * @returns true if selected else false
   */
  const getIsTxSelected = (updateId) => {
    return selectedCreditList?.some((item) => item?.updateId === updateId);
  };

  /**
   * @description bulk approve and reject. If status is true, it means approve else rejected
   */
  const handleBulkApproveReject = async () => {
    try {
      const formattedData = selectedCreditList?.map((ele) => {
        return {
          status: isApprove,
          id: ele?.updateId,
          message: `Request for bulk ${isApprove ? "approve" : "reject"}`,
          creditType: ele?.creditType,
          approvedBy: orderDetails?.name ?? "",
        };
      });

      const { data } = await postCall_v2(
        "/oms/credit/statusUpdate/createCreditList/",
        formattedData,
      );

      if (data?.successful) {
        triggerToaster(
          `Credit instruments ${
            isApprove ? "approved" : "rejected"
          } successfully`,
          success,
        );
        setIsModalOpen(false);
        setSelectedCreditList([]);
        fetchPendingCreditList();
      } else {
        triggerToaster(
          `Credit instruments ${isApprove ? "approvals" : "rejection"}  failed`,
          error,
        );
      }
    } catch (ex) {
      triggerToaster(
        `Credit instruments ${isApprove ? "approvals" : "rejection"}  failed`,
        error,
      );
    }
  };

  const handleApproveReject = (status) => {
    setIsApprove(status);
    setIsModalOpen(true);
  };

  return (
    <>
      <Box
        sx={{ display: "flex", justifyContent: "flex-end", marginBottom: 4 }}
        gap={2}
      >
        <ButtonV1
          title={"Approve"}
          onClick={() => handleApproveReject(true)}
          disabled={!selectedCreditList.length}
        />
        <ButtonV1
          title={"Reject"}
          variant="outlined"
          disabled={!selectedCreditList.length}
          onClick={() => handleApproveReject(false)}
        />
      </Box>

      <Paper>
        <TableContainer
          sx={{
            minWidth: 700,
            maxHeight: "calc(100vh - 120px)",
          }}
        >
          <Table stickyHeader>
            <TableHead style={{ borderRadius: "10px 10px 0 0" }}>
              <TableRow>
                <TableCell>
                  <Checkbox
                    checked={isAllChecked}
                    onChange={(e) => handleParentCheckbox(e)}
                  />
                </TableCell>
                {Object.values(CREDIT_UPDATE.pendingApprovalHeader).map(
                  (heading) => (
                    <TableCell key={heading}>{heading}</TableCell>
                  ),
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                <TableRow>
                  <TableCell
                    colSpan={
                      Object.values(CREDIT_UPDATE.pendingApprovalHeader).length
                    }
                  >
                    <Loader sx={{ margin: 0 }} />
                  </TableCell>
                </TableRow>
              ) : !!data?.data.response?.length ? (
                data?.data.response.map((order, index) => {
                  return (
                    <TableRow key={order.updateId}>
                      <TableCell>
                        <Checkbox
                          checked={
                            getIsTxSelected(order?.updateId) &&
                            order?.requestType === insert
                          }
                          disabled={order?.requestType !== insert}
                          onChange={(e) => handleChildCheckbox(e, index, order)}
                        />
                      </TableCell>

                      <TableCell>
                        {validateNull(order?.customerName)}/<br />
                        {validateNull(order?.customerPan)}/ <br />
                        {validateNull(order?.customerGstin)}
                      </TableCell>
                      <TableCell>{validateNull(order?.camId)}</TableCell>
                      <TableCell>
                        {validateNull(order?.creditType)}-
                        {validateNull(order?.creditInstrument)}
                      </TableCell>
                      <TableCell>
                        <Box>
                          <ButtonV1
                            boxStyle={{ justifyContent: "flex-start" }}
                            variant="text"
                            title={getSubstring(order.fileName, 20)}
                            size="small"
                            onClick={() => handleDocDownload(order?.path)}
                            style={{
                              padding: 0,
                              minWidth: "fit-content",
                              fontSize: 14,
                            }}
                          />
                        </Box>
                      </TableCell>
                      <TableCell>
                        {moneyFormat(order?.sanctionedLimit)}
                      </TableCell>
                      <TableCell>{moneyFormat(order?.utilisedLimit)}</TableCell>
                      <TableCell>
                        <Timestamp timeStamp={order?.expiryDate} />
                      </TableCell>
                      <TableCell>{validateNull(order?.requestedBy)}</TableCell>
                      <TableCell>
                        <Box style={{ textAlign: "center" }}>
                          {order?.comments ? (
                            <HoverBlock
                              LabelChildren={
                                <IconButton>
                                  <object
                                    data={`/assets/chat-unread.png`}
                                    width={24}
                                    alt="Group icon"
                                    aria-label="group icon"
                                  />
                                </IconButton>
                              }
                              data={getFormattedComments(order?.comments)}
                            />
                          ) : (
                            <IconButton>
                              <object
                                data={`/assets/chat-read.png`}
                                width={24}
                                alt="Group icon"
                                aria-label="group icon"
                              />
                            </IconButton>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box display={"flex"} alignItems={"center"}>
                          <StatusChip
                            label={
                              CREDIT_UPDATE.pendingOrderStatus[order.status]
                            }
                            type={
                              CREDIT_UPDATE.orderApproved === order.status
                                ? STATUS_CHIP.success
                                : CREDIT_UPDATE.orderInReview === order.status
                                ? STATUS_CHIP.warning
                                : STATUS_CHIP.error
                            }
                          />
                          {!!(
                            CREDIT_UPDATE.orderApproved === order.status ||
                            CREDIT_UPDATE.orderRejected === order.status
                          ) && (
                            <HoverBlock
                              LabelChildren={
                                <IconButton>
                                  <InfoOutlined fontSize="small" />
                                </IconButton>
                              }
                              data={getUpdatedDate(
                                order?.updatedDate,
                                order.status,
                              )}
                            />
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={Object.values(CREDIT_UPDATE.tableHeader).length}
                  >
                    <Typography textAlign={"center"}>No data found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!!data?.data.response?.length && (
          <TablePagination
            rowsPerPageOptions={[rowsPerPage]}
            rowsPerPage={rowsPerPage}
            page={Math.ceil(data?.data.offset / rowsPerPage)}
            count={data?.data.totalCount ?? 0}
            onPageChange={handlePageChange}
            sx={{ display: "flex", flexDirection: "column-reverse" }}
            ActionsComponent={PaginationAction}
          />
        )}
      </Paper>
      <ApproveRejectModalConfirmation
        data={selectedCreditList}
        isModalOpen={isModalOpen}
        onCloseModal={() => setIsModalOpen(false)}
        onConfirm={handleBulkApproveReject}
        isApprove={isApprove}
      />
    </>
  );
};

export default PendingApprovalTable;
