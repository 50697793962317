import {
  useImperativeHandle,
  useState,
  forwardRef,
  useCallback,
  useEffect,
} from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TablePagination,
  Typography,
  TextField,
  IconButton,
  Paper,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

import {
  PaginationAction,
  ModalBox,
  Loader,
  HyperLink,
  SvgIcon,
  ButtonV1,
  TableRowWithError,
} from "components";
import { success, error } from "constants";
import { formatDate } from "utils";
import { postCall_v2 } from "services";
import { useToaster } from "hooks";

import DocumentAndInvoiceColumn from "pages/FinancePayout/components/DocumentAndInvoiceColumn";
import DocumentAmountBreakUp from "pages/FinancePayout/components/DocumentAmountBreakUp";

import { StatusChip, HoverBlock } from "../components";
import { FinancePayouts } from "../../constants";

const {
  CREDIT_NOTE_SALES_RETURN_HEADERS,
  PAYMENT_IN_PROGRESS,
  PAYMENT_FAILED,
  msgStatements: { ERROR_MSG },
} = FinancePayouts;

const PayoutTable = forwardRef(
  (
    {
      pendingTransactions = {},
      rowsPerPage,
      pageNumber,
      onPageChange,
      passSelectedData,
      getPendingPayouts,
      loading,
      selectedPayout,
    },
    ref,
  ) => {
    const [isAllChecked, setAllChecked] = useState(false);
    const [isButtonLoading, setButtonLoading] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [rejectData, setRejectData] = useState();
    const [showErrorList, setShowErrorList] = useState([]);

    const showToaster = useToaster();

    const {
      control,
      reset,
      getValues,
      formState: { isDirty },
    } = useForm({
      mode: "onTouched",
      defaultValues: { reason: "" },
    });

    const onCloseModal = () => {
      reset();
      setRejectData("");
      setModalOpen(false);
    };

    const validateRejection = (data) => {
      setModalOpen(true);
      setRejectData(data);
    };

    /**
     * @description retry payout
     * @param {*} id
     * @param {*} i
     */
    const onRetryPayout = async (id, i) => {
      try {
        let tempLoading = [...isButtonLoading];
        tempLoading[i] = true;
        setButtonLoading(tempLoading);
        const res = await postCall_v2(
          "oms/payout/initiate-credit-sales-return",
          {
            ids: [id],
          },
        );
        if (res) {
          getPendingPayouts();
          showToaster(`Payout initiated`, success);
        }
      } catch (err) {
        console.error(`Retry failed`, err);
        showToaster(ERROR_MSG, error);
      } finally {
        let tempLoading = [...isButtonLoading];
        tempLoading[i] = false;
        setButtonLoading(tempLoading);
      }
    };

    const onRejectPayout = async () => {
      try {
        const { shipmentId, orderType, payoutId } = rejectData;
        const res = await postCall_v2("oms/payout/reject/shipmentPayout", {
          message: getValues("reason"),
          reference:
            orderType === FinancePayouts.orderTypes[0] ? shipmentId : payoutId,
          orderType: "Credit and sale return",
        });
        if (res) {
          getPendingPayouts();
          showToaster(`Payout rejected`, success);
        }
      } catch (err) {
        showToaster(ERROR_MSG, error);
      } finally {
        onCloseModal();
      }
    };

    const handleChildCheckbox = (e, i, val) => {
      let tempSelectedData = [...selectedPayout];
      if (e.target.checked) {
        tempSelectedData.push(pendingTransactions?.payoutSummaries[i]);
      } else {
        tempSelectedData = tempSelectedData.filter(
          (item) => item?.["payoutId"] !== val?.["payoutId"],
        );
      }
      passSelectedData(tempSelectedData);
    };

    const handleParentCheckbox = (e) => {
      let tempData = [];
      if (e.target.checked) {
        tempData = pendingTransactions?.payoutSummaries.filter(
          (ele) => ele?.status !== PAYMENT_IN_PROGRESS,
        );
      } else {
        tempData = [];
      }

      setAllChecked(e.target.checked);
      passSelectedData(tempData);
    };

    const handleErrorToggle = useCallback((value) => {
      setShowErrorList((prev) => {
        if (prev.includes(value)) {
          return prev.filter((id) => value !== id);
        } else {
          return [...prev, value];
        }
      });
    }, []);

    // hook to expose function - which will unselect all selected items
    useImperativeHandle(
      ref,
      () => ({
        resetParentCheckBox: () => {
          setAllChecked(false);
        },
      }),
      [],
    );

    useEffect(() => {
      const errorList = [];
      pendingTransactions?.payoutSummaries?.forEach((data) => {
        if (data?.currentState?.state === PAYMENT_FAILED) {
          errorList.push(data?.payoutId);
        }
      });
      setShowErrorList(errorList);
    }, [pendingTransactions]);

    /**
     * @description Validated checkbox is selected or not.
     * @param {*} payoutId
     * @returns true if selected else false
     */
    const getIsTxSelected = (payoutId) => {
      return selectedPayout?.some((item) => item?.payoutId === payoutId);
    };

    return (
      <Box>
        <>
          <Paper>
            <TableContainer
              sx={{
                minWidth: 700,
                maxHeight: "calc(100vh - 120px)",
              }}
            >
              <Table stickyHeader>
                <TableHead style={{ borderRadius: "10px 10px 0 0" }}>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={isAllChecked}
                        onChange={(e) => handleParentCheckbox(e)}
                      />
                    </TableCell>
                    {CREDIT_NOTE_SALES_RETURN_HEADERS.map((heading, i) => (
                      <TableCell key={i}>{heading}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pendingTransactions?.payoutSummaries?.length && !loading ? (
                    pendingTransactions?.payoutSummaries?.map((data, i) => (
                      <>
                        <TableRow key={data?.payoutId}>
                          <TableCell>
                            <Checkbox
                              key={data?.payoutId}
                              checked={
                                getIsTxSelected(data?.payoutId) &&
                                data?.status !== PAYMENT_IN_PROGRESS
                              }
                              disabled={data?.status === PAYMENT_IN_PROGRESS}
                              onChange={(e) => handleChildCheckbox(e, i, data)}
                            />
                          </TableCell>
                          <TableCell>
                            <StatusChip status={data?.orderType} isOrderType />
                          </TableCell>
                          <TableCell style={{ maxWidth: 120 }}>
                            <>
                              <HyperLink title={data?.customerOrdNumber} />
                              <br />
                              <Typography fontSize={14}>
                                {data?.entityName || "-"}
                              </Typography>
                            </>
                          </TableCell>
                          <TableCell style={{ maxWidth: 120 }}>
                            {data?.transactionType || "-"}
                          </TableCell>
                          <TableCell style={{ maxWidth: 120 }}>
                            <DocumentAndInvoiceColumn ele={data} />
                          </TableCell>
                          <TableCell>
                            <>
                              <Typography fontSize={14}>
                                {formatDate(data?.documentDate) ?? "-"}
                              </Typography>
                              <Typography fontSize={14}>
                                {formatDate(data?.payoutDate) ?? "-"}
                              </Typography>
                            </>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              <DocumentAmountBreakUp data={data} />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <StatusChip status={data?.status} />
                          </TableCell>
                          <TableCell>
                            <Box style={{ textAlign: "center" }}>
                              {data?.invoiceComments ? (
                                <HoverBlock
                                  LabelChildren={
                                    <IconButton>
                                      <SvgIcon
                                        name={"chat-unread"}
                                        width={24}
                                        height={24}
                                      />
                                    </IconButton>
                                  }
                                  data={{
                                    "Invoice comments": data?.invoiceComments,
                                  }}
                                />
                              ) : (
                                <IconButton>
                                  <SvgIcon
                                    name={"chat-read"}
                                    width={24}
                                    height={24}
                                  />
                                </IconButton>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box display={"flex"} alignItems={"center"}>
                              {data.status === "PAYMENT_FAILED" ? (
                                <Box display={"flex"} gap={1}>
                                  <ButtonV1
                                    title={"Retry"}
                                    variant={"contained"}
                                    size={"small"}
                                    onClick={() =>
                                      onRetryPayout(data?.payoutId, i)
                                    }
                                  />
                                  {!!(
                                    data?.payoutTypeKey === "IPA" ||
                                    data?.orderType ===
                                      FinancePayouts.orderTypes[1]
                                  ) && (
                                    <ButtonV1
                                      title={"Reject"}
                                      variant={"text"}
                                      size={"small"}
                                      onClick={() => validateRejection(data)}
                                      disabled={
                                        data?.status === PAYMENT_IN_PROGRESS
                                      }
                                    />
                                  )}
                                </Box>
                              ) : (
                                (data?.payoutTypeKey === "IPA" ||
                                  data?.orderType ===
                                    FinancePayouts.orderTypes[1]) && (
                                  <ButtonV1
                                    title={"Reject"}
                                    variant={"outlined"}
                                    size={"small"}
                                    onClick={() => validateRejection(data)}
                                    disabled={
                                      data?.status === PAYMENT_IN_PROGRESS
                                    }
                                  />
                                )
                              )}

                              {data?.status === "PAYMENT_FAILED" && (
                                <InfoOutlined
                                  onClick={() =>
                                    handleErrorToggle(data?.payoutId)
                                  }
                                  style={{ width: 16, marginLeft: 6 }}
                                />
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                        {showErrorList.includes(data?.payoutId) && (
                          <TableCell
                            colSpan={
                              CREDIT_NOTE_SALES_RETURN_HEADERS.length + 1
                            }
                            sx={{ textAlign: "center", padding: 0 }}
                          >
                            <TableRowWithError
                              message={data?.errorMessage}
                              onClose={handleErrorToggle}
                              id={data?.payoutId}
                              key={data?.payoutId}
                              length={
                                CREDIT_NOTE_SALES_RETURN_HEADERS.length + 1
                              }
                            />
                          </TableCell>
                        )}
                      </>
                    ))
                  ) : loading ? (
                    <TableCell
                      colSpan={CREDIT_NOTE_SALES_RETURN_HEADERS.length}
                      sx={{ textAlign: "center" }}
                    >
                      <Loader sx={{ marginTop: 0 }} />
                    </TableCell>
                  ) : (
                    <TableCell
                      colSpan={CREDIT_NOTE_SALES_RETURN_HEADERS.length}
                      sx={{ textAlign: "center" }}
                    >
                      No data found
                    </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {!!pendingTransactions?.payoutSummaries?.length && (
              <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                rowsPerPage={rowsPerPage}
                page={pageNumber}
                count={pendingTransactions?.totalCount ?? 0}
                onPageChange={onPageChange}
                sx={{ display: "flex", flexDirection: "column-reverse" }}
                ActionsComponent={PaginationAction}
              />
            )}
          </Paper>
          <ModalBox open={isModalOpen} onCloseModal={onCloseModal} width="50%">
            <Typography fontWeight={"bold"} mb={4}>
              Reject {rejectData?.customerOrdNumber}
            </Typography>
            <Controller
              control={control}
              name={"reason"}
              render={({ field: { value, onChange } }) => (
                <TextField
                  multiline
                  inputProps={{
                    style: {
                      height: 104,
                    },
                  }}
                  sx={{
                    width: "100%",
                  }}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                marginTop: 4,
              }}
            >
              <ButtonV1
                variant="outlined"
                color="secondary"
                title="Close"
                size="small"
                onClick={onCloseModal}
                style={{ marginRight: 4 }}
              />
              <ButtonV1
                variant="contained"
                color="secondary"
                title="Confirm"
                disabled={!isDirty}
                onClick={onRejectPayout}
                size="small"
              />
            </Box>
          </ModalBox>
        </>
      </Box>
    );
  },
);

export default PayoutTable;
