import { useCallback, useEffect, useState } from "react";
import { KeyboardArrowLeft } from "@mui/icons-material";
import {
  Typography,
  Box,
  useTheme,
  IconButton,
  Stack,
  Checkbox,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { ButtonV1, CollapsibleInfo, DialogBox, StatusChip } from "components";
import { GridSkeleton } from "CustomStyledComponents";
import { getStatusType } from "pages/CustomerProfile";
import { ColumnView, Attachment } from "./components";
import ArchivedDetailsList from "./ArchivedDetailsList";

import { useFetchData, useToaster } from "hooks";
import { validateNull, getMaskData } from "utils";
import { postCall_v2 } from "services";
import { success, error, STATUS_CHIP } from "constants";

export const getAccountStatusType = (isActive) => {
  switch (isActive) {
    case true:
      return STATUS_CHIP.success;
    case false:
      return STATUS_CHIP.error;
    default:
      break;
  }
};

const CustomerProfileDetails = () => {
  const [isFileVerified, setIsFileVerified] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const theme = useTheme();
  const navigation = useNavigate();
  const routeParams = useParams();
  const triggerToaster = useToaster();
  const selectedUserProfile = useSelector((state) => state.selectedUserProfile);

  const { data: accountDetails, refetch } = useFetchData(
    "bank-account-details",
    `/oms/customer/getCustomerAccountDetails?gstin=${routeParams?.id}`,
  );

  useEffect(() => {
    refetch();
  }, [routeParams]);

  const accountList = accountDetails?.data?.customerBankAccountList || [];
  const archivedAccounts = accountList?.filter(
    (account) => account?.status === "DELETED",
  );

  const handleApproveReject = async (status) => {
    try {
      const res = await postCall_v2(
        `/oms/customer/updateCustomerBankDetailsRequest`,
        {
          id: selectedUserProfile?.id,
          status,
          comments: "",
          customerGstin: selectedUserProfile?.customerGstin ?? "",
        },
      );
      if (res) {
        const { data } = res;
        if (typeof data?.message === "string") {
          const msgType = data.success ? success : error;
          triggerToaster(data?.message, msgType);
          setIsModalOpen(false);
          refetch();
          navigation("/customer-profile");
        }
      }
    } catch (err) {
      triggerToaster(`Something went wrong`, error);
    }
  };

  const handleCheckBox = useCallback((e) => {
    setIsFileVerified(e.target.checked);
  }, []);

  const onCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleReject = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  return (
    <Box p={4} pt={0}>
      <Stack direction={"row"} sx={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={() => navigation(-1)}>
          <KeyboardArrowLeft
            sx={{
              fontSize: 24,
              fontWeight: "600",
              height: 30,
              width: 30,
              color: theme.palette.grey["A300"],
            }}
          />
        </IconButton>
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: "600",
            color: theme.palette.grey["A300"],
          }}
        >
          {selectedUserProfile?.beneficiaryName}
        </Typography>
      </Stack>
      <CollapsibleInfo
        hideExpandIcon={false}
        defaultExpand={true}
        summaryStyles={{ paddingLeft: 10 }}
        summary={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: 18,
                fontWeight: "600",
              }}
            >
              {selectedUserProfile?.companyName} -{" "}
              {selectedUserProfile?.customerGstin}
            </Typography>
          </Box>
        }
        details={
          <>
            <Box p={4}>
              <GridSkeleton
                style={{
                  borderBottom: `1px solid ${theme.palette.grey["400"]}`,
                  paddingBottom: 10,
                }}
                firstChild={
                  <ColumnView label="Bank account name">
                    <Typography sx={{ fontWeight: "600" }}>
                      {validateNull(selectedUserProfile?.beneficiaryName)}
                    </Typography>
                  </ColumnView>
                }
                secondChild={
                  <ColumnView label="Bank account number">
                    <Typography sx={{ fontWeight: "600" }}>
                      {getMaskData(selectedUserProfile?.bankAccountNumber)}
                    </Typography>
                  </ColumnView>
                }
                thirdChild={
                  <ColumnView label="IFSC code">
                    <Typography sx={{ fontWeight: "600" }}>
                      {validateNull(selectedUserProfile?.ifscCode)}
                    </Typography>
                  </ColumnView>
                }
              />
              <GridSkeleton
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderBottom:
                    selectedUserProfile.status === "PENDING" &&
                    `1px solid ${theme.palette.grey["400"]}`,
                }}
                firstChild={
                  <ColumnView label="Verification status">
                    <StatusChip
                      label={selectedUserProfile?.status}
                      type={getStatusType(selectedUserProfile?.status)}
                      style={{ fontWeight: "bold" }}
                    />
                  </ColumnView>
                }
                secondChild={
                  <ColumnView label="Attachment">
                    {selectedUserProfile?.filePath ? (
                      <Attachment filePath={selectedUserProfile?.filePath} />
                    ) : (
                      <b>NA</b>
                    )}
                  </ColumnView>
                }
                thirdChild={
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <ColumnView label="Fund account ID">
                      <Typography sx={{ fontWeight: "600" }}>
                        {selectedUserProfile?.fundAccountId ?? <b>NA</b>}
                      </Typography>
                    </ColumnView>
                    {selectedUserProfile.status === "APPROVED" && (
                      <ColumnView label="Account status">
                        <StatusChip
                          label={
                            selectedUserProfile?.isActive ? "Active" : "Deleted"
                          }
                          type={getAccountStatusType(
                            !!selectedUserProfile?.isActive,
                          )}
                          style={{ fontWeight: "bold" }}
                        />
                      </ColumnView>
                    )}
                  </Box>
                }
              />
              {selectedUserProfile.status === "PENDING" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    checked={isFileVerified}
                    onChange={handleCheckBox}
                  />
                  <Typography>
                    I have verified the attached authorization letter and
                    approved by sales SPOC
                  </Typography>
                </Box>
              )}
            </Box>
            {selectedUserProfile.status === "PENDING" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  paddingBottom: 2,
                }}
              >
                <ButtonV1
                  title="Reject"
                  variant="outlined"
                  color="buttonError"
                  disabled={!isFileVerified}
                  onClick={() => handleReject()}
                />
                <ButtonV1
                  title="Approve"
                  variant="outlined"
                  disabled={!isFileVerified}
                  sx={{ marginLeft: 4 }}
                  onClick={() => handleApproveReject("APPROVED")}
                />
              </Box>
            )}
          </>
        }
      />
      <DialogBox
        handleClose={() => onCloseModal(false)}
        open={isModalOpen}
        handleConfirm={() => handleApproveReject("REJECTED")}
        message={"Are you sure you want to reject?"}
      />
      <ArchivedDetailsList archivedAccounts={archivedAccounts} />
    </Box>
  );
};

export default CustomerProfileDetails;
