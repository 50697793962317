import { useEffect, useState, useCallback } from "react";
import { Typography, Box, Grid } from "@mui/material";
import { AddSharp, ManageAccountsSharp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useFetchData } from "hooks";

import { CardDetails, ComponentSlider } from "../components";
import { Loader, ButtonV1 } from "components";
import RoleCreationDrawer from "./RoleCreationDrawer";

const Roles = () => {
  const [rolesData, setRolesData] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();

  const { refetch: getRolesData, isLoading } = useFetchData(
    "roles",
    `/auth2-service/user/roles`,
    (res) => {
      const data = res?.data;
      setRolesData(data);
    },
  );

  useEffect(() => {
    getRolesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleDrawer = useCallback(
    () => setOpenDrawer(!openDrawer),
    [openDrawer],
  );

  const RoleCard = ({ element, key }) => (
    <Grid item key={key}>
      <CardDetails
        title={element?.roles?.[0]?.label ?? element?.roles?.[0]?.name}
        users={element?.users}
        styles={{ height: 133, cursor: "pointer" }}
        onClick={() =>
          navigate(`/user-management/role-details/${element?.roles?.[0]?.id}`)
        }
      />
    </Grid>
  );

  return (
    <>
      <Box display={"flex"} alignItems={"center"} mb={3}>
        <>
          <ManageAccountsSharp fontSize="large" />
          <Typography variant="h4" m={0} mr={4}>
            Roles
          </Typography>
        </>
        <ButtonV1
          title="Create role"
          startIcon={<AddSharp />}
          onClick={handleToggleDrawer}
          variant="outlined"
          size="small"
          style={{ borderRadius: 20 }}
        />
      </Box>
      <Grid container rowSpacing={3} mt={3}>
        {isLoading ? (
          <Box sx={{ width: "100%", minHeight: 320 }}>
            <Loader />
          </Box>
        ) : (
          <ComponentSlider data={rolesData} component={RoleCard} />
        )}
      </Grid>

      <RoleCreationDrawer
        isDrawerOpen={openDrawer}
        handleToggleDrawer={handleToggleDrawer}
        getRolesData={getRolesData}
      />
    </>
  );
};

export default Roles;
