import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { DialogBox } from "components";

import CreditNotesManualInputForm from "./CreditNotesManualInputForm";
import ButtonView from "../components/ButtonView";
import { CreditNotesSellerPayout } from "./constants";

const {
  msgStatements: { CLEAR_ALL_MSG, CLEAR_ONE_MSG },
  creditYup,
} = CreditNotesSellerPayout;

const ReviewTransactions = ({
  invalidCsvList,
  passInvalidCsvList,
  retryPayoutSubmission,
  clearAll,
}) => {
  const [isResetTxConfirmationOpen, setResetTxConfirmation] = useState(false);
  const [singleTxDeletionId, setSingleTxDeletionId] = useState(null);

  const theme = useTheme();

  const methods = useForm({
    mode: "onTouch",
  });

  const { handleSubmit, getValues, resetField } = methods;

  const handleSave = () => {
    retryPayoutSubmission(getValues(creditYup));
  };

  /**
   * @description toggle confirmation modal
   */
  const resetConfirmation = () => {
    if (!isResetTxConfirmationOpen && singleTxDeletionId) {
      setSingleTxDeletionId(null);
    }
    setResetTxConfirmation(!isResetTxConfirmationOpen);
  };

  /**
   * @description get confirmation to remove a single tx
   * @param {*} txData
   */
  const toConfirmSingleTxRemoval = (id) => {
    setSingleTxDeletionId(id);
    setResetTxConfirmation(true);
  };

  /**
   * @description - clear all invalid records
   */
  const removeRecord = () => {
    clearTransactions(singleTxDeletionId);
    setResetTxConfirmation(false);
    setSingleTxDeletionId(null);
    clearAll();
  };

  /**
   * @description - clears invalid transactions
   */
  const clearTransactions = (listId = null) => {
    if (listId) {
      //fetch single tx & remove
      let listRef = [...invalidCsvList];
      if (singleTxDeletionId !== -1) {
        listRef.splice(singleTxDeletionId, 1);
      }
      passInvalidCsvList(listRef);
      // singleTxDeletionId
      resetField(`${creditYup}.${singleTxDeletionId}`);
    } else {
      //clear all tx
      passInvalidCsvList([]);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <Typography fontSize={16} fontWeight={"bold"} mb={4}>
          Review transactions
        </Typography>
        <Box
          as="form"
          onSubmit={handleSubmit(handleSave)}
          sx={{
            paddingY: 3,
          }}
        >
          {invalidCsvList.map((item, index) => {
            return (
              <Box
                key={item.txData.id}
                style={{
                  padding: 10,
                  border: `1px solid ${theme.palette.grey["400"]}`,
                  borderRadius: 8,
                  marginTop: 12,
                }}
              >
                <CreditNotesManualInputForm
                  transaction={item?.txData}
                  unique={index}
                  toConfirmSingleTxRemoval={(id) =>
                    toConfirmSingleTxRemoval(id)
                  }
                  txErrors={item.errors}
                  isReviewTransaction={true}
                />
              </Box>
            );
          })}
          <ButtonView onClearAll={resetConfirmation} />
        </Box>
      </FormProvider>
      <DialogBox
        handleClose={() => resetConfirmation(false)}
        open={isResetTxConfirmationOpen}
        handleConfirm={removeRecord}
        message={
          singleTxDeletionId
            ? `${CLEAR_ONE_MSG} ${singleTxDeletionId} ?`
            : CLEAR_ALL_MSG
        }
      />
    </>
  );
};

export default ReviewTransactions;
