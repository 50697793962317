import { Box, useTheme } from "@mui/material";

import { ButtonV1 } from "components";

const ButtonView = ({ disabled, onClearAll, title = "Save" }) => {
  const theme = useTheme();
  return (
    <Box
      style={{
        display: "flex",
        gap: 16,
        padding: 12,
        border: 1,
        borderStyle: "solid",
        borderColor: theme.palette.grey[900],
        borderRadius: 12,
        marginTop: 40,
        justifyContent: "flex-end",
      }}
    >
      <ButtonV1
        variant="outlined"
        title="Clear all"
        disabled={disabled}
        onClick={onClearAll}
      />
      <ButtonV1
        title={title}
        color="primary"
        variant="contained"
        type={"submit"}
        disabled={disabled}
      />
    </Box>
  );
};

export default ButtonView;
