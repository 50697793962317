import { forwardRef, useImperativeHandle, useState } from "react";
import { Typography, Box } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { creditNotesSchema } from "./tests";
import ButtonView from "../components/ButtonView";
import CreditNotesManualInputForm from "./CreditNotesManualInputForm";
import { CreditNotesSellerPayout } from "./constants";
import { removeTimeOffset } from "utils";
const { creditYup, creditNotesManualInput } = CreditNotesSellerPayout;

const ManualInput = forwardRef(({ saveManualUpload }, ref) => {
  const [clearInvoiceIds, setClearInvoiceIds] = useState(0);
  const methods = useForm({
    mode: "onTouched",
    resolver: yupResolver(creditNotesSchema),
  });

  const {
    reset,
    handleSubmit,
    getValues,
    formState: { isDirty },
  } = methods;

  const handleSave = () => {
    const formData = getValues()?.[creditYup]?.[0];
    const validatedFormData = {
      ...formData,
      documentDate: removeTimeOffset(formData.documentDate),
    };
    saveManualUpload([validatedFormData], reset);
    setClearInvoiceIds(Math.random());
  };

  const handleClearAll = () => {
    reset();
    setClearInvoiceIds(Math.random());
  };

  // hook to expose function - Reset fields
  useImperativeHandle(
    ref,
    () => ({
      reset: () => handleClearAll(),
    }),
    [],
  );

  return (
    <FormProvider {...methods}>
      <Typography marginTop={5}>Manual upload</Typography>
      <Box as="form" onSubmit={handleSubmit(handleSave)} sx={{ paddingY: 3 }}>
        {[creditNotesManualInput].map((txItem, index) => {
          return (
            <CreditNotesManualInputForm
              key={index}
              unique={index}
              clearInvoiceIds={clearInvoiceIds}
            />
          );
        })}
        <ButtonView disabled={!isDirty} onClearAll={handleClearAll} />
      </Box>
    </FormProvider>
  );
});

export default ManualInput;
