import { useEffect, useState } from "react";
import { Box, styled } from "@mui/material";

import DocumentUploader from "./DocumentUploader";
import { InputField, DatePickerV1 } from "components";

const LabelBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const FileUploadModule = ({
  fileDetails,
  onFileUpload,
  onFileDownload,
  isLoading,
}) => {
  const [files, setFiles] = useState();
  const [error, setError] = useState(null);
  const [documentId, setDocumentId] = useState();
  const [documentDate, setDocumentDate] = useState();

  useEffect(() => {
    if (fileDetails) {
      setFiles(fileDetails);
      setDocumentId(Object.values(fileDetails)[0]?.documentId);
      setDocumentDate(Object.values(fileDetails)[0]?.documentDate);
    }
  }, [fileDetails]);

  const uploadFile = (files) => {
    if (documentDate && documentId) {
      setError(null);
      const d = new Date(documentDate);
      let formattedDate = new Intl.DateTimeFormat("fr-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format(d);
      onFileUpload(files, documentId, formattedDate);
    } else if (!documentDate && documentId) {
      setError("Please enter the document date");
    } else if (documentDate && !documentId) {
      setError("Please enter the document ID");
    } else {
      setError("Please enter the document ID and the document date");
    }
  };

  const onChange = (event, type) => {
    type === "date"
      ? setDocumentDate(event)
      : setDocumentId(event.target.value);
  };

  return (
    <Box
      sx={{
        alignItems: "center",
      }}
      py={2}
    >
      <Box width={"100%"} display={"flex"} justifyContent={"space-between"}>
        <Box width={"80%"}>
          <DocumentUploader
            onUpload={uploadFile}
            uploadedFiles={files}
            onDownload={onFileDownload}
            isLoading={isLoading}
          />
        </Box>
        {documentId && (
          <>
            <LabelBox sx={{ marginBottom: 2 }}>
              <InputField
                placeholder="Document Id"
                onChange={(e) => onChange(e, "text")}
                disabled={fileDetails ? true : false}
                value={documentId}
                highlightError={error?.length}
                rest={{ padding: "2px" }}
              />
            </LabelBox>
            <LabelBox sx={{ marginBottom: 2 }}>
              <DatePickerV1
                dateStyle={{ width: "70%" }}
                onChange={(e) => onChange(e, "date")}
                value={documentDate}
                disabled={fileDetails ? true : false}
                customPadding="0.5rem 0.8rem"
                disableFuture={true}
              />
            </LabelBox>
          </>
        )}
      </Box>
    </Box>
  );
};

export default FileUploadModule;
