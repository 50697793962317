import { useState, useEffect } from "react";
import {
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TableBody,
  Box,
  TableCell,
} from "@mui/material";
import { StyledTableRow, StyledTableCell } from "./dashboard.style";
import { formatDate } from "utils";
import { useNavigate } from "react-router-dom";
import { HyperLink, Loader } from "components";

const tableHead = [
  "Buyer order no.",
  "Seller order no.",
  "Order date",
  "Order type",
  "Buyer order status",
  "Seller order status",
  "Ops SPOC",
  "Payment status",
  "Committed delivery",
  "Planned delivery",
  "Buyer",
  "Seller",
  "Delivery state",
];

const Tabulation = ({ table, isLoading }) => {
  const [filteredTable, setFilteredTable] = useState();
  const navigate = useNavigate();

  useEffect(() => setFilteredTable(table), [table]);

  const navigateToOrderSummary = (orderID) => {
    navigate(`/order-list/${orderID}?tab=orderDetails`);
  };

  return (
    <Box>
      <TableContainer
        sx={{
          minWidth: 700,
          maxHeight: "calc(100vh - 80px)",
        }}
        component={Paper}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {tableHead.map((head) => (
                <StyledTableCell key={head}>{head}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTable?.length && !isLoading ? (
              filteredTable.map((item, index) => (
                <StyledTableRow
                  key={`${item?.orderNumber}${index}`}
                  onClick={() =>
                    navigateToOrderSummary(item?.customerOrderNumber)
                  }
                >
                  <StyledTableCell component="th" scope="row">
                    <HyperLink title={item?.customerOrderNumber} />
                  </StyledTableCell>
                  <StyledTableCell scope="row">
                    {item?.orderNumber}
                  </StyledTableCell>
                  <StyledTableCell>
                    {formatDate(item?.buyerOrderDates)}
                  </StyledTableCell>
                  <StyledTableCell>{item?.currentOrderType}</StyledTableCell>

                  <StyledTableCell>{item?.currentOrderStatus}</StyledTableCell>
                  <StyledTableCell>{item?.childOrderStatus}</StyledTableCell>
                  <StyledTableCell>{item?.opsSpoc}</StyledTableCell>
                  <StyledTableCell>
                    {item?.currentPaymentStatus}
                  </StyledTableCell>
                  <StyledTableCell>
                    {formatDate(item?.committedDeliveryDates)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {formatDate(item?.scheduledDeliveryDates)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item?.buyerDetails?.companyName ?? "-"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item?.sellerDetails?.companyName ?? "-"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item?.buyerDetails?.shippingState ?? "-"}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <>
                {isLoading ? (
                  <TableRow>
                    <TableCell
                      colSpan={tableHead.length}
                      sx={{ textAlign: "center" }}
                    >
                      <Loader sx={{ marginTop: 0 }} />
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={tableHead.length}
                      sx={{ textAlign: "center" }}
                    >
                      No data found
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Tabulation;
