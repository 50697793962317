import { useState } from "react";
import { Box, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { CREDIT } from "./constant";
import PendingCredit from "./Pending";

function TabPanel({ value }) {
  const tab = {
    [CREDIT.tab.pending]: () => <PendingCredit />,
  };

  return tab[value]();
}

const CreditOrder = () => {
  const theme = useTheme();

  const [tab, setTab] = useState(CREDIT.tab.pending);

  const handleTabChange = (_event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box padding={3}>
      <Typography variant="h5">Credit workflow</Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          sx={{
            ">.MuiTabs-scroller": {
              ">.MuiTabs-indicator": {
                backgroundColor: theme.palette.common.black,
              },

              ">.MuiTabs-flexContainer>.Mui-selected": {
                fontWeight: 600,
                color: theme.palette.common.black,
              },
            },
          }}
        >
          <Tab label="Payment terms pending" value={CREDIT.tab.pending} />
          {/* <Tab label="Payment credit approval" value={CREDIT.tab.approval} /> */}
        </Tabs>
      </Box>
      <TabPanel value={tab} />
    </Box>
  );
};

export default CreditOrder;
