import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";

const ComponentSlider = ({ component, data = [] }) => {
  const [count, setCount] = useState(8);
  const [page, setPage] = useState(0);

  const sliderRef = useRef();
  const theme = useTheme();

  useLayoutEffect(() => {
    const componentWidth = sliderRef.current.offsetWidth;
    setCount(Math.floor(componentWidth / (290 + 16)) * 2);
  }, [sliderRef]);

  const nextPageEnable = Math.ceil(data.length / count) - 1;

  const showArrow = useMemo(() => data.length > count, [data, count]);

  const slicedData = useMemo(() => {
    const startCount = count * page;
    const endCount = count * page + count;
    return data?.slice(
      startCount,
      data.length > endCount ? endCount : data.length,
    );
  }, [count, page, data]);

  const handleNextPage = () => {
    page < nextPageEnable && setPage((prev) => ++prev);
  };

  const handlePrevPage = () => {
    page !== 0 && setPage((prev) => --prev);
  };

  return (
    <Box display={"flex"} sx={{ gap: 2, alignItems: "center", flex: 1 }}>
      {showArrow && (
        <ArrowCircleLeft
          onClick={handlePrevPage}
          sx={{
            width: 30,
            height: 30,
            cursor: page === 0 ? "default" : "pointer",
            color:
              page === 0
                ? theme.palette.text.disabled
                : theme.palette.common.black,
          }}
        />
      )}
      <Grid
        container
        rowSpacing={3}
        spacing={4}
        mt={1}
        flex={1}
        position="relative"
        ref={sliderRef}
      >
        {slicedData?.map((elem, index) =>
          component({ element: elem, key: `page${page}-${index}` }),
        )}
      </Grid>
      {showArrow && (
        <ArrowCircleRight
          onClick={handleNextPage}
          sx={{
            width: 30,
            height: 30,
            cursor: page === nextPageEnable ? "default" : "pointer",
            color:
              page === nextPageEnable
                ? theme.palette.text.disabled
                : theme.palette.common.black,
          }}
        />
      )}
    </Box>
  );
};

export default ComponentSlider;
