import { useEffect, useMemo, useState } from "react";

import { useFetchData } from "hooks";

import PayoutTable from "./PayoutTable";
import { getDateRange } from "utils";
const rowsPerPage = 50;

const endPoint = `oms/payout/history`;
const { startDate, endDate } = getDateRange();

const RefundAndCashback = ({ transactionType, filters }) => {
  const [page, setPage] = useState(0);

  const pendingRefundParams = useMemo(() => {
    return {
      limit: rowsPerPage,
      transactionType: "RC",
      minTimestamp: startDate,
      maxTimestamp: endDate,
      ...filters,
    };
  }, [filters]);

  const {
    refetch: getHistoricalPayouts,
    data: historicalPayouts,
    isFetching,
  } = useFetchData("historical-payouts-data", endPoint, null, null, {
    ...pendingRefundParams,
    offset: page * rowsPerPage,
  });

  /**
   * @description fetch history payouts during page load
   */
  useEffect(() => {
    if (page) {
      setPage(0);
    } else {
      getHistoricalPayouts();
    }
  }, [pendingRefundParams]);

  useEffect(() => {
    getHistoricalPayouts();
  }, [page]);

  /**
   * @description Handle pagination
   */
  const handlePageChange = (_event, pageNumber) => {
    setPage(pageNumber);
  };

  return (
    <>
      <PayoutTable
        transactionType={transactionType}
        payoutData={historicalPayouts?.data ?? {}}
        isLoading={isFetching}
        page={page}
        onPageChange={handlePageChange}
        filters={pendingRefundParams}
      />
    </>
  );
};

export default RefundAndCashback;
