import { useEffect, useRef, useState } from "react";
import {
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { ButtonV1, FloatingBar, Loader } from "components";
import SoInputFields from "./SoInputFields";
import Filter from "./Filter";
import { useFetchData, useToaster } from "hooks";
import { getCall_v2, postCall_v2 } from "services";
import {
  PLANT_SUPPLY_HEADER,
  SO_DETAIL_COLUMN_HEADER,
  filterQueries,
} from "../constant";
import { success, error } from "constants";

const PlantSupplyTab = () => {
  const soRef = useRef({});
  const [page, setPage] = useState(1);
  const [soListData, setSoListData] = useState([]);
  const triggerToaster = useToaster();
  const navigation = useNavigate();
  const next = "next";
  const prev = "prev";

  const successCallback = (res) => {
    setSoListData(res?.data);
  };

  const { refetch: fetchSoList, isFetching } = useFetchData(
    "lineItemMappings",
    `/oms/line-item/getSoMappingList?offset=${page}&limit=1`,
    successCallback,
  );

  const mapSoLineItems = async () => {
    try {
      let payload = [];
      soListData?.response?.forEach((order, index) => {
        const lineItemKey = index;
        const lineItemId = order?.lineItemId;
        const soInputs = soRef.current[lineItemKey].getSoInputs();
        let validSoInputs = [];
        if (soInputs.length) {
          soInputs.forEach((ele) => {
            if (ele?.soNumber && ele?.soLineItemNumber && ele?.soType) {
              ele.lineItemId = lineItemId;
              validSoInputs.push(ele);
            }
          });
          order.soLineItemMappings = validSoInputs;
          payload.push(order);
        }
      });

      if (payload && payload.length) {
        await postCall_v2(`/oms/line-item/saveBulkSoMapping`, payload);
        triggerToaster(`SO lineItems updated`, success);
        fetchSoList();
      }
    } catch (err) {
      console.error(`Unable to save/update SO lineitem ${err}`);
      triggerToaster(
        err?.data?.detail || `Failure in updating SO lineitems`,
        error,
      );
    }
  };

  const navigateToOrderDetail = (customerOrderNumber) => {
    if (customerOrderNumber) {
      navigation(`/order-list/${customerOrderNumber}?tab=orderDetails`);
    }
  };
  const handlePageCount = (action) => {
    if (action === prev) {
      setPage((previous) => previous - 1);
    } else if (action === next) {
      if (soListData?.nextExist) {
        setPage((previous) => previous + 1);
      }
    }
  };

  useEffect(() => {
    fetchSoList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  /**
   * @description handle filter
   */

  const handleFilter = async (data) => {
    const { filterQuery, buyerOrderId, soLineItemId, soNumber } = data;
    if (filterQuery === filterQueries.buyerOrderId) {
      try {
        const result = await getCall_v2(
          `/oms/line-item/get-so-mapping-using-orderId/${data?.buyerOrderId}`,
        );
        setSoListData({
          response: result?.data,
        });
      } catch (err) {
        triggerToaster(err?.data?.detail, error);
        console.log(err);
      }
    } else if (filterQuery === filterQueries.soDetails) {
      try {
        const result = await getCall_v2(
          `/oms/line-item/getSoMappingUsingSoNumberAndSoLineItem`,
          { soNumber, soLineItemId },
        );
        setSoListData({
          response: result?.data,
        });
      } catch (err) {
        triggerToaster(err?.data?.detail, error);
        console.log(err);
      }
    }
  };
  /**
   * @description handle reset function
   */
  const onReset = () => {
    fetchSoList();
  };

  return (
    <>
      <Filter onFilter={handleFilter} onReset={onReset} />
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {PLANT_SUPPLY_HEADER.map((ele) =>
                ele === SO_DETAIL_COLUMN_HEADER ? (
                  <TableCell colSpan={3}>{ele}</TableCell>
                ) : (
                  <TableCell>{ele}</TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <TableRow>
                <TableCell colspan={PLANT_SUPPLY_HEADER.length}>
                  <Loader sx={{ marginTop: 0 }} />
                </TableCell>
              </TableRow>
            ) : !!soListData?.response?.length ? (
              soListData?.response?.map((order, index) => {
                const lineItemKey = index;
                return (
                  <TableRow key={lineItemKey}>
                    <TableCell width={100}>
                      <ButtonV1
                        textStyle={{ fontSize: 14 }}
                        title={order.customerOrderNumber}
                        variant="text"
                        size="small"
                        onClick={() =>
                          navigateToOrderDetail(order.customerOrderNumber)
                        }
                      />
                    </TableCell>
                    <TableCell width={100}>{order.customerName}</TableCell>
                    <TableCell width={150}>{order.sku}</TableCell>
                    <TableCell width={150}>
                      {`${order.quantityOrdered} ${order.measurementUnit}`}
                    </TableCell>
                    <TableCell width={150}>
                      {`${order.quantityShipped} ${order.measurementUnit}`}
                    </TableCell>
                    <TableCell>{order.perUnitPrice?.displayAmount}</TableCell>
                    <TableCell colSpan={3}>
                      <SoInputFields
                        key={lineItemKey}
                        ref={(ref) => (soRef.current[lineItemKey] = ref)}
                        lineItemData={order?.soLineItemMappings}
                        fetchSoList={fetchSoList}
                      />
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colspan={PLANT_SUPPLY_HEADER.length}>
                  <Typography textAlign={"center"}>No data found</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <FloatingBar bottom={10}>
        <ButtonV1
          title={"Map items"}
          onClick={mapSoLineItems}
          style={{ marginRight: 2 }}
        />
        <ButtonV1
          title={"prev"}
          disabled={page === 1}
          variant="outlined"
          style={{ marginRight: 2 }}
          onClick={() => handlePageCount(prev)}
        />
        <ButtonV1
          variant="outlined"
          title={"next"}
          disabled={!soListData?.nextExist}
          onClick={() => handlePageCount(next)}
        />
      </FloatingBar>
    </>
  );
};

export default PlantSupplyTab;
