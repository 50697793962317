import { Box } from "@mui/material";

import { FileUploadModule } from "components";
import { getCall } from "services";

const FileUpload = ({ fileDetails, type, orderNumber }) => {
  const onSuccessDownload = (res, fileName) => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(res);
    link.download = fileName;
    link.click();
  };

  const onFileDownload = async () => {
    const downloadFilesRes = await getCall(
      `oms/order/documents/download`,
      {
        referenceNumber: orderNumber,
        fileId: Object.keys(fileDetails)[0],
        fileType: type,
      },
      (err) => console.error(err),
      "blob",
    );
    onSuccessDownload(
      downloadFilesRes,
      fileDetails[Object.keys(fileDetails)[0]]?.originalName,
    );
  };

  return (
    <Box>
      <FileUploadModule
        fileDetails={fileDetails}
        onFileDownload={onFileDownload}
      />
    </Box>
  );
};

export default FileUpload;
