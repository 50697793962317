import { MoreVert, DeleteOutline } from "@mui/icons-material";
import { Box, Button, Popover, Typography, useTheme } from "@mui/material";
import { ButtonV1, InputField, SvgIcon } from "components";
import { useCallback, useState } from "react";

const ButtonIcon = ({ name, title, color, onClick }) => (
  <ButtonV1
    variant="outlined"
    onClick={onClick}
    size="small"
    title={
      <Box display="flex" sx={{ gap: 3, alignItems: "center" }}>
        <SvgIcon name={name} height={25} width={25} style={{ color: color }} />
        <Typography>{title}</Typography>
      </Box>
    }
  />
);

const GroupHead = ({ groupName, onAddUser, onEdit, onDeleteGroup }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEdit, setEdit] = useState(false);
  const [newGroupName, setNewGroupName] = useState();

  const handleMoreOption = useCallback(
    (e) => setAnchorEl(e?.currentTarget),
    [],
  );

  const handleCloseModal = useCallback(() => setAnchorEl(null), []);

  const toggleEditGroup = useCallback(() => {
    setEdit((prev) => !prev);
    setNewGroupName(groupName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGroupName = useCallback((e) => {
    setNewGroupName(e?.target?.value);
  }, []);

  const handleEditedGroup = () => {
    setEdit(false);
    onEdit(newGroupName);
  };

  return (
    <Box display="flex" sx={{ justifyContent: "space-between" }}>
      <Box display="flex" sx={{ gap: 4, alignItems: "center" }}>
        {isEdit ? (
          <InputField
            value={newGroupName}
            defaultValue={groupName}
            onChange={handleGroupName}
          />
        ) : (
          <Typography variant="h3" sx={{ margin: 0 }}>
            {groupName || ""}
          </Typography>
        )}
        {isEdit ? (
          <ButtonV1
            disabled={!newGroupName}
            title={"Done"}
            onClick={handleEditedGroup}
          />
        ) : (
          <ButtonIcon
            onClick={toggleEditGroup}
            name="edit"
            title="Edit"
            color={theme.palette.primary.main}
          />
        )}
      </Box>
      <Box display="flex" sx={{ gap: 4 }}>
        <ButtonIcon
          name="addUser"
          title="Add user"
          color={theme.palette.primary.main}
          onClick={onAddUser}
        />
        <ButtonV1
          title={<MoreVert />}
          variant="outlined"
          textStyle={{ marginBottom: "-9px" }}
          onClick={handleMoreOption}
          style={{ borderRadius: "100%", padding: 1, minWidth: "unset" }}
        />
        <Popover
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleCloseModal}
          sx={{ marginTop: 1 }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Button
            onClick={() => {
              onDeleteGroup();
              handleCloseModal();
            }}
            startIcon={
              <DeleteOutline sx={{ color: theme.palette.error.dark }} />
            }
          >
            <Typography
              sx={{ color: theme.palette.error.dark, whiteSpace: "no-wrap" }}
            >
              Delete group
            </Typography>
          </Button>
        </Popover>
      </Box>
    </Box>
  );
};

export default GroupHead;
