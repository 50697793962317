import { Box, useTheme } from "@mui/material";

const FileUploadWrapper = ({ children }) => {
  const theme = useTheme();

  return (
    <>
      <Box display={"flex"} justifyContent={"center"} marginTop={6}>
        <Box
          px={6}
          py={4}
          style={{
            border: 1,
            borderStyle: "solid",
            borderColor: theme.palette.grey[900],
            borderRadius: 12,
            width: "100%",
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};

export default FileUploadWrapper;
