import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Grid,
  TextField as MuiTextField,
  Divider,
  FormControlLabel,
  Checkbox,
  styled,
  useTheme,
} from "@mui/material";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { Controller } from "react-hook-form";

import { DatePickerV1, DropdownV1 } from "components";
import { ErrorMsg } from "CustomStyledComponents";
import { INVOICE_SELLER_PAYOUT } from "./constant";
import Comment from "../components/Comment";
import PayoutBreakup from "./PayoutBreakup";
import moment from "moment";
import OriginalInvoiceId from "../components/OriginalInvoiceId";

const { manualUpload, gstTypes, invoiceYup, documentType, plantGstin } =
  INVOICE_SELLER_PAYOUT;

const ManualEntryBox = styled(Box)(({ theme }) => ({
  border: 1,
  borderStyle: "solid",
  borderColor: theme.palette.grey[900],
  borderRadius: 12,
  width: "100%",
  paddingRight: 24,
  paddingLeft: 24,
  paddingTop: 20,
  paddingBottom: 20,
}));

const TextField = styled(MuiTextField)(({ theme }) => ({
  width: "100%",
  ".MuiOutlinedInput-root.Mui-disabled": {
    background: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
  },
  ".MuiOutlinedInput-root.Mui-error": {
    fieldset: {
      borderColor: theme.palette.error.dark,
    },
  },
  label: {
    "&.Mui-error": {
      color: theme.palette.error.dark,
    },
  },
  ".MuiFormHelperText-root.Mui-error": {
    color: theme.palette.error.dark,
  },
}));

const ManualEntry = ({ unique, item, methods, errors }) => {
  const theme = useTheme();
  const [gstType, setGstType] = useState(null);

  const yupFormName = useMemo(() => `${invoiceYup}.${unique}.`, [unique]);

  const formError = useMemo(
    () => errors?.[invoiceYup]?.[unique],
    [errors, unique],
  );

  const { control, getValues, watch, setValue } = methods;

  const checkGsts = useCallback(() => {
    const from = getValues(`${yupFormName}${manualUpload.fromGstin}`);
    const to = getValues(`${yupFormName}${manualUpload.toGstin}`);
    if (from?.length !== 15 && to?.length !== 15) {
      setGstType(null);
    } else if (
      from?.substring(0, 2)?.toLowerCase() ===
      to?.substring(0, 2)?.toLowerCase()
    ) {
      setGstType(gstTypes.intraState);
    } else {
      setGstType(gstTypes.interState);
    }
  }, [getValues, yupFormName]);

  const getYupValue = useCallback(
    (value) => getValues(`${yupFormName}${value}`) ?? 0,
    [getValues],
  );

  //   Watch auto-calculate value
  const gstin = {
    to: watch(`${yupFormName}${manualUpload.toGstin}`),
    from: watch(`${yupFormName}${manualUpload.fromGstin}`),
  };

  const invoiceComments = watch(
    `${yupFormName}${manualUpload.invoiceComments}`,
  );

  const gstAmount =
    +(watch(`${yupFormName}${manualUpload.cgstAmount}`) || 0) +
      +(watch(`${yupFormName}${manualUpload.sgstAmount}`) || 0) +
      +(watch(`${yupFormName}${manualUpload.igstAmount}`) || 0) ?? 0;

  const baseAmount =
    +(watch(`${yupFormName}${manualUpload.invoiceAmount}`) ?? 0) - gstAmount;

  const advanceAmount =
    (watch(`${yupFormName}${manualUpload.invoiceAmount}`) || 0) -
    (watch(`${yupFormName}${manualUpload.creditAmount}`) || 0);

  const isTcsTdsDetected = watch(
    `${yupFormName}${manualUpload.isTcsTdsDetected}`,
  );

  const documentTypeValue = watch(`${yupFormName}${manualUpload.documentType}`);

  const checkPlantGST = () => {
    const payoutType = getValues(`${yupFormName}${manualUpload.documentType}`);
    if ([documentType.penalCharges].includes(payoutType)) {
      // return false if document type is penal charge or debit note
      return false;
    }
    const gstin =
      getValues(`${yupFormName}${manualUpload.toGstin}`) || item?.toGstin || "";

    const plantPanRegex = new RegExp(`${plantGstin.join("|")}`, "g");

    return !!gstin.match(plantPanRegex)?.[0];
  };

  const setPayableAmount = ({ igst, sgst, cgst, inv, isChecked }) => {
    const invoiceAmount =
      inv ?? getValues(`${yupFormName}${manualUpload.invoiceAmount}`) ?? 0;

    if (checkPlantGST() || !(isChecked ?? isTcsTdsDetected)) {
      setValue(`${yupFormName}${manualUpload.payoutAmount}`, invoiceAmount);
      return;
    }

    const baseAmount =
      invoiceAmount -
      (igst ?? getYupValue(manualUpload.igstAmount)) -
      (sgst ?? getYupValue(manualUpload.sgstAmount)) -
      (cgst ?? getYupValue(manualUpload.cgstAmount));

    const tcsTds = +baseAmount * 0.01 ?? 0;
    const payoutAmountCalc = Number(invoiceAmount - tcsTds * 2).toFixed(2);

    setValue(`${yupFormName}${manualUpload.payoutAmount}`, +payoutAmountCalc);
  };

  useEffect(() => {
    checkGsts();
  }, [gstin]);

  /**
   * @description reset invoice amount incase of change
   * in document type or to gstin
   */
  const resetInvoiceAmount = () => {
    setValue(`${yupFormName}${manualUpload.invoiceAmount}`, "");
    setValue(`${yupFormName}${manualUpload.igstAmount}`, "");
    setValue(`${yupFormName}${manualUpload.cgstAmount}`, "");
    setValue(`${yupFormName}${manualUpload.sgstAmount}`, "");
    setValue(`${yupFormName}${manualUpload.payoutAmount}`, "");
  };

  return (
    <ManualEntryBox>
      <Grid
        container
        key={unique}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid item xs={2}>
          <Controller
            control={control}
            name={`${yupFormName}${manualUpload.orderNumber}`}
            render={({ field: { value, onChange } }) => (
              <TextField
                label="Order number"
                size="small"
                onChange={onChange}
                value={value}
                sx={{ width: "100%" }}
                InputLabelProps={{ shrink: value }}
                helperText={
                  formError?.[manualUpload.orderNumber]?.message || " "
                }
                error={!!formError?.[manualUpload.orderNumber]?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            control={control}
            name={`${yupFormName}${manualUpload.fromGstin}`}
            render={({ field: { value, onChange } }) => (
              <TextField
                label="From account"
                size="small"
                onChange={(e) => {
                  onChange(e);
                  checkGsts();
                }}
                InputLabelProps={{ shrink: value }}
                sx={{ width: "100%" }}
                value={value}
                helperText={formError?.[manualUpload.fromGstin]?.message || " "}
                error={!!formError?.[manualUpload.fromGstin]?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            control={control}
            name={`${yupFormName}${manualUpload.toGstin}`}
            render={({ field: { value, onChange } }) => (
              <TextField
                label="To account"
                size="small"
                onChange={(e) => {
                  resetInvoiceAmount();
                  onChange(e);
                  checkGsts();
                }}
                InputLabelProps={{ shrink: value }}
                value={value}
                sx={{ width: "100%" }}
                helperText={formError?.[manualUpload.toGstin]?.message || " "}
                error={!!formError?.[manualUpload.toGstin]?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={12}>
          <Divider style={{ marginBottom: 12 }} />
        </Grid>
        <Grid item xs={2}>
          <Controller
            control={control}
            name={`${yupFormName}${manualUpload.documentType}`}
            render={({ field: { value, onChange } }) => {
              const handleChange = (e) => {
                resetInvoiceAmount();
                onChange(e);
                if (e.target.value === documentType.channelFinance) {
                  setValue(
                    `${yupFormName}${manualUpload.creditDueDate}`,
                    moment().add(3, "d"),
                  );
                } else {
                  setValue(`${yupFormName}${manualUpload.creditDueDate}`, "");
                }
              };

              return (
                <>
                  <DropdownV1
                    onChange={handleChange}
                    itemList={Object.values(documentType)}
                    placeholder="Document type"
                    listType="constants"
                    value={value}
                    menuStyle={
                      !!formError?.[manualUpload.documentType]?.message && {
                        "~fieldset": {
                          borderColor: theme.palette.error.dark,
                        },
                      }
                    }
                    labelStyle={{
                      color:
                        !!formError?.[manualUpload.documentType]?.message &&
                        theme.palette.error.dark,
                    }}
                  />
                  <ErrorMsg sx={{ position: "absolute", maxWidth: 100 }}>
                    {formError?.[manualUpload.documentType]?.message}
                  </ErrorMsg>
                </>
              );
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            control={control}
            name={`${yupFormName}${manualUpload.documentId}`}
            render={({ field: { value, onChange } }) => (
              <TextField
                label="Document / Invoice ID"
                size="small"
                onChange={onChange}
                value={value}
                InputLabelProps={{ shrink: value }}
                helperText={
                  formError?.[manualUpload.documentId]?.message || " "
                }
                error={!!formError?.[manualUpload.documentId]?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            control={control}
            name={`${yupFormName}${manualUpload.documentDate}`}
            render={({ field: { value, onChange } }) => (
              <>
                <DatePickerV1
                  label="Document date"
                  onChange={onChange}
                  value={value}
                  dateStyle={{
                    fieldset: {
                      borderColor:
                        !!formError?.[manualUpload.documentDate]?.message &&
                        theme.palette.error.dark,
                    },
                    label: {
                      color:
                        !!formError?.[manualUpload.documentDate]?.message &&
                        theme.palette.error.dark,
                    },
                    width: "100%",
                  }}
                />
                <ErrorMsg sx={{ position: "absolute", maxWidth: 150 }}>
                  {formError?.[manualUpload.documentDate]?.message}
                </ErrorMsg>
              </>
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <OriginalInvoiceId
            item={item.originalInvoiceId}
            setValue={setValue}
            yupName={`${yupFormName}${manualUpload.originalInvoiceId}`}
          />
          <ErrorMsg sx={{ position: "absolute", minWidth: 150, maxWidth: 200 }}>
            {formError?.[manualUpload.originalInvoiceId]?.message}
          </ErrorMsg>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ marginBottom: 12 }} />
        </Grid>
        <Grid item xs={2}>
          <Controller
            control={control}
            name={`${yupFormName}${manualUpload.invoiceAmount}`}
            render={({ field: { value, onChange } }) => {
              const handleChange = (e) => {
                setPayableAmount({ inv: e.target.value });
                onChange(e);
              };
              return (
                <TextField
                  label="Document / Invoice amount (Incl. of GST)"
                  size="small"
                  onChange={handleChange}
                  value={value}
                  type="number"
                  sx={{ width: "100%" }}
                  InputLabelProps={{ shrink: value }}
                  helperText={
                    formError?.[manualUpload.invoiceAmount]?.message || " "
                  }
                  error={!!formError?.[manualUpload.invoiceAmount]?.message}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            control={control}
            name={`${yupFormName}${manualUpload.creditAmount}`}
            render={({ field: { value, onChange } }) => {
              const isEnabled = ![
                documentType.channelFinance,
                documentType.bnplInvoice,
              ].includes(documentTypeValue);
              if (value && isEnabled && documentTypeValue !== undefined) {
                setValue(`${yupFormName}${manualUpload.creditAmount}`, "");
              }
              return (
                <TextField
                  label="Credit amount"
                  size="small"
                  onChange={onChange}
                  value={value}
                  type="number"
                  sx={{ width: "100%" }}
                  disabled={isEnabled}
                  InputLabelProps={{ shrink: value }}
                  helperText={
                    formError?.[manualUpload.creditAmount]?.message || " "
                  }
                  error={!!formError?.[manualUpload.creditAmount]?.message}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={2} sx={{ width: "100%" }}>
          <>
            <Controller
              control={control}
              name={`${yupFormName}${manualUpload.creditDueDate}`}
              render={({ field: { value, onChange } }) => {
                const isEnabled = ![
                  documentType.channelFinance,
                  documentType.bnplInvoice,
                ].includes(documentTypeValue);

                if (isEnabled && value && documentTypeValue !== undefined) {
                  setValue(`${yupFormName}${manualUpload.creditDueDate}`, "");
                }
                return (
                  <DatePickerV1
                    label="Credit due date"
                    onChange={onChange}
                    value={value}
                    disabled={isEnabled}
                    dateStyle={{
                      width: "100%",
                      ...(!!formError?.[manualUpload.creditDueDate]
                        ?.message && {
                        fieldset: { borderColor: "red" },
                        label: { color: "red" },
                      }),
                      ...(isEnabled && {
                        ".Mui-disabled": {
                          background: theme.palette.grey[200],
                        },
                      }),
                    }}
                  />
                );
              }}
            />
            <ErrorMsg sx={{ maxWidth: 140 }}>
              {formError?.[manualUpload.creditDueDate]?.message}
            </ErrorMsg>
          </>
        </Grid>
        <Grid item xs={2}>
          <TextField
            label="Advance amount"
            size="small"
            disabled={true}
            value={advanceAmount}
            type="number"
            sx={{
              width: "100%",
              background: theme.palette.grey[200],
              borderRadius: 1,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ marginBottom: 12 }} />
        </Grid>
        <Grid item xs={2}>
          <TextField
            label="Base amount"
            size="small"
            disabled="true"
            value={baseAmount}
            type="number"
            sx={{
              width: "100%",
              background: theme.palette.grey[200],
              borderRadius: 1,
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            control={control}
            name={`${yupFormName}${manualUpload.cgstAmount}`}
            render={({ field: { value, onChange } }) => {
              const handleChange = (e) => {
                setPayableAmount({ cgst: e.target.value });
                onChange(e);
              };
              const isDisabled = !gstType || gstType !== gstTypes.intraState;
              return (
                <TextField
                  label="CGST amount"
                  disabled={isDisabled}
                  size="small"
                  onChange={handleChange}
                  value={isDisabled || !value ? "" : value}
                  type="number"
                  sx={{ width: "100%" }}
                  InputLabelProps={{ shrink: value }}
                  helperText={
                    formError?.[manualUpload.cgstAmount]?.message || " "
                  }
                  error={!!formError?.[manualUpload.cgstAmount]?.message}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            control={control}
            name={`${yupFormName}${manualUpload.sgstAmount}`}
            render={({ field: { value, onChange } }) => {
              const handleChange = (e) => {
                setPayableAmount({ sgst: e.target.value });
                onChange(e);
              };
              const isDisabled = !gstType || gstType !== gstTypes.intraState;
              return (
                <TextField
                  label="SGST amount"
                  disabled={isDisabled}
                  size="small"
                  onChange={handleChange}
                  value={isDisabled || !value ? "" : value}
                  type="number"
                  sx={{ width: "100%" }}
                  InputLabelProps={{ shrink: value }}
                  helperText={
                    formError?.[manualUpload.sgstAmount]?.message || " "
                  }
                  error={!!formError?.[manualUpload.sgstAmount]?.message}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            control={control}
            name={`${yupFormName}${manualUpload.igstAmount}`}
            render={({ field: { value, onChange } }) => {
              const handleChange = (e) => {
                onChange(e);
                setPayableAmount({ igst: e.target.value });
              };
              const isDisabled = !gstType || gstType !== gstTypes.interState;
              return (
                <TextField
                  label="IGST amount"
                  disabled={isDisabled}
                  size="small"
                  onChange={handleChange}
                  value={isDisabled || !value ? "" : value}
                  type="number"
                  InputLabelProps={{ shrink: value }}
                  sx={{ width: "100%" }}
                  helperText={
                    formError?.[manualUpload.igstAmount]?.message || " "
                  }
                  error={!!formError?.[manualUpload.igstAmount]?.message}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ marginBottom: 12 }} />
        </Grid>
        <Grid item xs={2}>
          <Controller
            control={control}
            name={`${yupFormName}${manualUpload.isTcsTdsDetected}`}
            defaultValue={item.isTcsTdsDetected}
            render={({ field: { value, onChange } }) => {
              const handleChange = (e) => {
                setPayableAmount({ isChecked: e.target.checked });
                onChange(e);
              };
              return (
                <FormControlLabel
                  control={<Checkbox checked={value} onChange={handleChange} />}
                  label="TCS/TDS deductions required?"
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <Comment
            placeholder="Enter your invoice comments"
            name={`${yupFormName}${manualUpload.invoiceComments}`}
            invoiceComments={invoiceComments}
          />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={2} />
        <Grid item xs={12}>
          <Divider style={{ marginBottom: 12 }} />
        </Grid>
        <Grid item xs={2}>
          <Controller
            control={control}
            name={`${yupFormName}${manualUpload.payoutAmount}`}
            render={({ field: { value, onChange } }) => (
              <TextField
                label="Payout amount"
                size="small"
                onChange={onChange}
                value={!!value ? value : ""}
                InputLabelProps={{ shrink: value }}
                type="number"
                InputProps={{
                  endAdornment: (
                    <PayoutBreakup
                      advanceAmount={advanceAmount}
                      creditAmount={getValues(
                        `${yupFormName}.${manualUpload.creditAmount}`,
                      )}
                      invoiceAmount={getValues(
                        `${yupFormName}.${manualUpload.invoiceAmount}`,
                      )}
                      gstAmount={gstAmount}
                      isTcsTdsDetected={isTcsTdsDetected}
                      isPlantGST={checkPlantGST()}
                      icon={
                        <OpenInFullIcon
                          style={{
                            color: theme.palette.text.secondary,
                            width: 20,
                            height: 20,
                            cursor: "pointer",
                          }}
                        />
                      }
                    />
                  ),
                }}
                helperText={
                  formError?.[manualUpload.payoutAmount]?.message || " "
                }
                error={!!formError?.[manualUpload.payoutAmount]?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </ManualEntryBox>
  );
};

export default ManualEntry;
