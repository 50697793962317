import { useState, useEffect, useRef } from "react";
import {
  Typography,
  Box,
  Grid,
  Divider,
  TextareaAutosize,
  Stack,
} from "@mui/material";
import { GroupsSharp, AddSharp } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

import {
  CardDetails,
  RightDrawer,
  InputField,
  ErrorMsg,
  ComponentSlider,
  SearchBar,
} from "../components";
import { useFetchData, useToaster } from "hooks";
import { createGroupSchema } from "../TestSchema/createGroupSchema";
import { getCall, postCall } from "services";
import { system } from "../constants";
import theme from "themeProvider";
import { ButtonV1, Loader, MultiSelectSearch } from "components";
import { success, error } from "constants";

const Groups = () => {
  const [groupsData, setGroupsData] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);

  const navigate = useNavigate();
  const triggerToaster = useToaster();

  const userEmailRef = useRef(null);

  const {
    control,
    getValues,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(createGroupSchema),
  });

  const { refetch: getGroupsData, isLoading } = useFetchData(
    "groups",
    `/auth2-service/groups/all`,
    (res) => {
      const data = res?.data;
      setGroupsData(data);
      setFilteredGroups(data);
    },
  );

  useEffect(() => {
    getGroupsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDrawer = () => setOpenDrawer(!openDrawer);

  const onSubmit = async () => {
    const userId = userEmailRef.current?.fetchValue()?.map(({ id }) => id);
    if (!userId.length) {
      triggerToaster("Please select any user", error);
      return;
    }

    const groupObj = {
      name: getValues("groupName"),
      email: "",
      description: getValues("groupDescription"),
      createdBy: system,
      userId,
    };
    const response = await postCall(
      "/auth2-service/group",
      groupObj,
      {},
      () => {
        triggerToaster("Group creation failed, please try later", error);
      },
    );
    if (response) {
      triggerToaster("Group created successfully", success);
      reset();
      userEmailRef.current?.resetValue();
      toggleDrawer();
      getGroupsData();
    }
  };

  const searchEnteredtext = async (val) => {
    const res = await getCall(`/auth2-service/user/search?email=${val}`);
    if (res?.[0]?.users?.length) {
      const { email, id } = res?.[0].users?.[0];
      return [{ email, id }];
    } else {
      return [];
    }
  };

  /**
   *
   * @param {search} input value in searchbar,
   * @description handles the filtering of data based on the input
   *
   */
  const getSearchedData = (search) => {
    if (search) {
      let searchResults = groupsData.filter((group) =>
        group?.groups?.[0]?.name.toLowerCase().includes(search),
      );
      setFilteredGroups(searchResults);
    } else {
      setFilteredGroups(groupsData);
    }
  };

  const navigateToGroup = (elem) => {
    const id = elem?.groups?.[0]?.id;
    id && navigate(`/user-management/group-detail/${id}`);
  };

  const GroupCard = ({ element, key }) => {
    const description = element?.groups?.[0]?.description || "";
    return (
      <Grid item onClick={() => navigateToGroup(element)} key={key}>
        <CardDetails
          title={element?.groups?.[0]?.name}
          description={
            description.length > 100
              ? `${description.slice(0, 100)}...`
              : description
          }
          users={element?.users}
        />
      </Grid>
    );
  };

  return (
    <>
      <Box mb={8}>
        <Box display={"flex"} alignItems={"center"}>
          <>
            <GroupsSharp fontSize="large" style={{ marginRight: 10 }} />
            <Typography variant="h4" margin={0}>
              Groups
            </Typography>
          </>
          <ButtonV1
            title="Create group"
            startIcon={<AddSharp />}
            onClick={toggleDrawer}
            variant="outlined"
            size="small"
            style={{ borderRadius: 20, marginLeft: 16 }}
          />
          <Box sx={{ marginLeft: 2 }}>
            <SearchBar
              placeholder="Search groups"
              onInputSearch={getSearchedData}
              onClear={() => setFilteredGroups(groupsData)}
            />
          </Box>
        </Box>
        {isLoading ? (
          <Box sx={{ width: "100%", minHeight: 320 }}>
            <Loader />
          </Box>
        ) : (
          <ComponentSlider data={filteredGroups} component={GroupCard} />
        )}
      </Box>
      <RightDrawer
        toggleDrawer={toggleDrawer}
        icon={
          <object
            data={`/assets/group.svg`}
            width={28}
            alt="Group icon"
            aria-label="group icon"
          />
        }
        heading={"Create group"}
        isDrawerOpen={openDrawer}
      >
        <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            <>
              <Box mb={6}>
                <Controller
                  control={control}
                  name="groupName"
                  render={({ field: { value, onChange, onBlur } }) => (
                    <InputField
                      label="Group name"
                      isRequired="true"
                      type={"text"}
                      value={value}
                      onChange={onChange}
                      highlightError={errors?.groupName?.hasOwnProperty(
                        "message",
                      )}
                      onBlur={onBlur}
                    />
                  )}
                />
                <ErrorMsg msg={errors?.groupName?.message} />
              </Box>
              <Box mb={12}>
                <Stack direction={"column"}>
                  <Typography variant="body1" mb={2}>
                    About group
                  </Typography>
                  <Controller
                    control={control}
                    name="groupDescription"
                    render={({ field: { value, onChange, onBlur } }) => (
                      <>
                        <TextareaAutosize
                          type={"text"}
                          value={value}
                          minRows={2}
                          maxRows={2}
                          multiline={true}
                          helperText={"150 words"}
                          style={{
                            width: "100%",
                            borderRadius: 6,
                            padding: 8,
                            fontFamily: "inherit",
                          }}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                        <ErrorMsg msg={errors?.groupDescription?.message} />
                      </>
                    )}
                  />
                  <Typography
                    variant="body1"
                    mt={1}
                    style={{ alignSelf: "flex-end" }}
                  >
                    150 words
                  </Typography>
                </Stack>
              </Box>
              <Divider style={{ marginBottom: 30 }} />
              <Box mb={50}>
                <Typography variant="body1" mb={2}>
                  Email address
                  <span style={{ color: theme.palette.warning.main }}>*</span>
                </Typography>
                <MultiSelectSearch
                  onSearch={searchEnteredtext}
                  ref={userEmailRef}
                  displayAttribute={"email"}
                  size={"medium"}
                  customChip={{ size: "medium" }}
                />
              </Box>
            </>
            <Box alignSelf={"flex-end"}>
              <ButtonV1 title={"Create group"} size="medium" type="submit" />
            </Box>
          </Box>
        </Box>
      </RightDrawer>
    </>
  );
};

export default Groups;
