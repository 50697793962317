import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { orderListTabs } from "./constant";
import { userModule } from "constants";
import FullPaymentOrdersTab from "./FullPaymentOrdersTab";
import PendingPaymentOrdersTab from "./PendingPaymentOrdersTab";

const OrderList = () => {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentTab = searchParams.get("tab");
  const [tab, setTab] = useState(currentTab ?? orderListTabs.FULL_PAYMENT_TAB);
  const userModules = useSelector((state) => state.userData.modules);

  useEffect(() => {
    if (!searchParams.get("tab")) {
      searchParams.set("tab", orderListTabs.FULL_PAYMENT_TAB);
      setSearchParams(searchParams);
    }
  }, []);

  const TabPanel = ({ children, value, selectedTab, tabName, ...rest }) => {
    return (
      <Box
        role="tab-panel"
        hidden={value !== selectedTab}
        id={`tab-panel-${value}`}
        aria-labelledby={`tab-${value}`}
        {...rest}
      >
        {value === selectedTab && <>{children}</>}
      </Box>
    );
  };

  //Ops lead
  const isOpsLeadUser = useMemo(
    () => userModules?.some(({ name }) => name === userModule.pendingPayment),
    [userModules],
  );

  const handleUpdateQueryParam = useCallback(
    (tab) => {
      searchParams.set("tab", tab);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const handleTabChange = useCallback(
    (_event, newValue) => {
      setTab(newValue);
      handleUpdateQueryParam(newValue);
    },
    [isOpsLeadUser],
  );

  return (
    <Box padding={3} px={8}>
      <Typography variant="h3">Order list</Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 5 }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          sx={{
            ">.MuiTabs-scroller": {
              ">.MuiTabs-indicator": {
                backgroundColor: theme.palette.common.black,
              },

              ">.MuiTabs-flexContainer>.Mui-selected": {
                fontWeight: 600,
                color: theme.palette.common.black,
              },
            },
          }}
        >
          <Tab
            label={orderListTabs.FULL_PAYMENT_TAB}
            value={orderListTabs.FULL_PAYMENT_TAB}
          />
          {!!isOpsLeadUser && (
            <Tab
              label={orderListTabs.PENDING_PAYMENT_TAB}
              value={orderListTabs.PENDING_PAYMENT_TAB}
            />
          )}
        </Tabs>
      </Box>
      <TabPanel value={orderListTabs.FULL_PAYMENT_TAB} selectedTab={tab}>
        <FullPaymentOrdersTab />
      </TabPanel>
      {!!isOpsLeadUser && (
        <TabPanel value={orderListTabs.PENDING_PAYMENT_TAB} selectedTab={tab}>
          <PendingPaymentOrdersTab />
        </TabPanel>
      )}
    </Box>
  );
};

export default OrderList;
