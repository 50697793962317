import axios from "axios";

import { store } from "dataStore";
import { verifyLoginAction } from "dataStore/auth";

class AxiosDefaultInstance {
  constructor(origin) {
    this.instance = axios.create({
      baseURL: origin,
      timeout: 300000,
    });

    //default headers
    // this.instance.defaults.headers.common["content-type"] = "application/json";
    // this.instance.defaults.headers.common["cache-control"] =
    //   "private, no-cache, no-store, max-age=0, must-revalidate";
    // this.instance.defaults.headers.common["set-cookie"] =
    //   "homes_lang_cookie=en; Path=/; Expires=Fri, 21 Mar 2025 10:02:21 GMT; Secure; SameSite=Lax";
    // this.instance.defaults.headers.common["Strict-Transport-Security"] =
    //   "max-age=63072000";
    // this.instance.defaults.headers.common["X-Content-Type-Options"] = "nosniff";
    // this.instance.defaults.headers.common["X-Frame-Options"] = "DENY";
    // this.instance.defaults.headers.common["x-xss-Protection"] = "1; mode=block";
    // this.instance.defaults.headers.common["content-security-policy"] =
    //   "default-src 'self' 'unsafe-inline' 'unsafe-eval' data: https://*.googleapis.com/ https://*.gstatic.com/ https://*.jsdelivr.net/ https://*.googletagmanager.com/ https://*.google-analytics.com/  https://*.google.com/; img-src 'self' data: https://* http://localhost:3000; font-src 'self' 'unsafe-inline' data: https://*.gstatic.com/ https://*.jsdelivr.net;";

    this.instance.interceptors.request.use(this.handleRequest);
    this.instance.interceptors.response.use(
      this.handleResponse,
      this.handleError,
    );
  }

  getInstance() {
    return this.instance;
  }

  handleRequest(config) {
    const state = store.getState();
    config.headers.common["Authorization"] = state.isLoggedIn.value
      ? `Bearer ${state.isLoggedIn.token}`
      : "";
    return config;
  }

  handleResponse(response) {
    return response;
  }

  handleError(error) {
    if (error?.response?.status === 401) {
      store.dispatch(verifyLoginAction({ value: false, token: "" }));
    }
    throw error?.response;
  }
}

const currentOrigin = `${window.location.protocol}//${window.location.host}`;
const isLocalEnv = currentOrigin.includes("localhost");
const origin = isLocalEnv ? "https://qa-oms.msme.jswone.in/" : currentOrigin;
const axiosInstance = new AxiosDefaultInstance(origin);

export default axiosInstance.getInstance();
