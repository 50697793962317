import { FINANCE_TAB } from "constants";

export const FinancePayouts = Object.freeze({
  FUND_ACC_CONSTANTS: {
    sellerAccount: "Seller",
    plantSupplyAccount: "Plant supply",
    deutscheBankAccount: "Deutsche bank",
  },
  accountStatus: {
    verified: "ACCOUNT_VERIFIED",
    unverified: "ACCOUNT_UNVERIFIED",
  },
  MARK_SUCCESS: "MARK_SUCCESS",
  success: "Success",
  INVOICE_SELLER_PAYOUTS: "INVOICE_SELLER_PAYOUTS",
  REFUND_CASHBACK: "REFUND_CASHBACK",
  MARK_SUCCESS_ERROR_MSG:
    "Only transactions that are in failed or approved can be marked as success",
  financeTab: {
    pending: "pending",
    historical: "historical",
    upload: "upload",
    approve: "approved",
    pendingPayoutAuth: "Pending-payouts",
    uploadPayoutAuth: "Upload-payouts",
  },
  ERP: "ERP",
  ORDER_BOOK: "OrderBook",
  payoutTypes: {
    CASHBACK: "Cashback",
    REFUND: "Refund",
    IPTA: "Seller payout - PT",
    IPHA: "Seller payout - Hold payout",
    IPA: "Seller payout - Invoice",
  },
  manualPayoutTypes: {
    IPA: "Seller payout - Invoice",
    CASHBACK: "Cashback",
    REFUND: "Refund",
  },
  orderTypes: ["ERP", "OrderBook"],
  quick_filter: {
    due_today: "Due today",
    overdue: "Overdue",
    due_1_3_days: "Due in 1-3 days",
    all: "All",
  },
  queryFilters: {
    tab: "tab",
    transactionType: "type",
    quickFilter: "quickFilter",
  },
  quick_filter_approved: {
    ALL: "All",
    PAYMENT_FAILED: "Failed",
    PAYMENT_IN_PROGRESS: "In-progress",
  },
  pendingPayoutHeaders: [
    "Payout source",
    "Order number/ Entity name",
    "Payout type",
    "Invoice date/Due date",
    "Payout amount",
    "Status",
    "Invoice comments",
    "Action",
  ],
  pendingRefundHeaders: [
    "Payout source",
    "Order number/ Entity name",
    "Payout type",
    "Transaction create date",
    "Order completion date",
    "Customer Balance",
    "Payout amount",
    "A/C Verification Status",
    "Status",
    "Invoice comments",
    "Action",
  ],
  disbursementHeader: [
    "Payout source",
    "Document ID",
    "Shipment ID",
    "Order number",
    "Payout amount",
    "Payout entity",
    "Select account",
  ],
  refundDisbursementHeader: [
    "Payout source",
    "Order number",
    "Payout amount",
    "Payout entity",
  ],
  manualUpload: {
    orderNumber: "orderNumber",
    payoutType: "payoutType",
    fromAccount: "fromAccount",
    toAccount: "toAccount",
    invoiceNo: "invoiceNo",
    invoiceDate: "invoiceDate",
    invoiceAmount: "invoiceAmount",
    payoutAmount: "payoutAmount",
    invoiceComments: "invoiceComments",
    advice: "advice",
    ledgerDescription: "ledgerDescription",
    comment: "comment",
  },
  manualUploadOrderBlock: {
    orderId: "orderId",
    entityGstin: "entityGstin",
    date: "date",
    orderAmount: "orderAmount",
    advanceAmount: "advanceAmount",
    creditAmount: "creditAmount",
    creditInstrument: "creditInstrument",
  },
  reviewTx: {
    orderNumber: "orderNumber",
    payoutType: "payoutType",
    fromAccount: "fromAccount",
    toAccount: "toAccount",
    invoiceNo: "invoiceNo",
    invoiceDate: "invoiceDate",
    invoiceAmount: "invoiceAmount",
    payoutAmount: "payoutAmount",
    invoiceComments: "invoiceComments",
    sgstAmount: "sgstAmount",
    cgstAmount: "cgstAmount",
    baseAmount: "baseAmount",
    isTcs: "isTcs",
    igstAmount: "igstAmount",
    gstAmount: "gstAmount",
    advice: "advice",
    ledgerDescription: "ledgerDescription",
    comment: "comment",
  },
  pendingInternalFundsHeaders: [
    "From Account",
    "To Account",
    "Ledger description",
    "Advice",
    "Amount",
    "Transaction date",
    "Action",
  ],
  pendingTransactionsHeaders: [
    "Order number",
    "Payout type",
    "From account GSTIN",
    "To account GSTIN",
    "Invoice number",
    "Invoice date",
    "Invoice amount",
    "Payout amount",
    "Action",
  ],

  msgStatements: {
    DELETE_CONFIRMATION_MSG: "Are you sure to delete?",
    SAVED_SUCCESS_MSG: "Transactions have been successfully uploaded",
    ERROR_MSG: "An error occurred, please try again.",
    TRANSACTION_SAVED_MSG: `Transaction has been saved successfully`,
    CLEAR_ALL_MSG: "Are you sure you want to clear all?",
    CLEAR_ONE_MSG: "Are you sure you want to clear record",
    GST_ERROR_MSG: "GSTIN doesn’t exist on ERP",
    PAYOUT_ERROR_MSG: "Invalid payout amount",
    PAYOUT_TYPE_ERROR_MSG: "Invalid payout type",
    TRANSACTION_UPDATED_MSG: `Transaction has been updated successfully`,
  },
  transactionFilterValue: {
    "Invoice & seller payouts": "Invoice & seller payouts",
    "Refunds & Cashbacks": "RC",
    "Internal fund transfer": "Internal fund transfer",
    "Credit note & Sales return": "Credit note & Sales return",
  },
  refundReviewHeaders: [
    "Order number",
    "Payout type",
    "From account GSTIN",
    "To account GSTIN",
    "Payout amount",
    // "Action",
    "Comment",
  ],
  sellerDocumentTypes: {
    IPTA: "Seller payout - PT",
    IPHA: "Seller payout - Hold payout",
    IPA: "Seller payout - Invoice",
  },

  PAYMENT_IN_PROGRESS: "PAYMENT_IN_PROGRESS",
  PAYMENT_FAILED: "PAYMENT_FAILED",
  PAYMENT_PENDING: "PAYMENT_PENDING",
  PAYMENT_IN_PROCESS: "PAYMENT_IN_PROCESS",

  approvedPaymentStatus: {
    PAYMENT_IN_PROCESS: "Initiated",
    PAYMENT_IN_PROGRESS: "In-progress",
    PAYMENT_FAILED: "Failed",
    PAYMENT_APPROVED: "Approved",
  },
  pendingPaymentStatus: {
    PAYMENT_PENDING: "Pending",
    PAYMENT_ON_HOLD: "On-hold",
  },
  documentType: {
    advanceInvoice: "Advance Invoice",
    channelFinance: "Channel Finance - Invoice",
    bnplInvoice: "Trade Credit - Invoice",
    debitNotes: "Debit Note",
    interestCharge: "Interest Charges",
    penalCharges: "Penal Charges",
  },
  CREDIT_NOTE_SALES_RETURN_HEADERS: [
    "Order type",
    "Order number / Entity name",
    "Transaction type",
    "Document ID / Original invoice number",
    "Document date / Due date",
    "Document amount",
    "Status",
    "Comments",
    "Action",
  ],
  tabs: {
    HISTORICAL: "Historical",
    PENDING: "Pending",
    UPLOAD: "Upload",
    APPROVE: "Approved",
  },
  transactionFilter: {
    INVOICE_SELLER_PAYOUTS: "Invoice & seller payouts",
    REFUND_CASHBACK: "Refunds & Cashbacks",
    ORDER_BLOCK: "Order block",
    CREDIT_NOTE_SALES_RETURN: "Credit note & Sales return",
    INTERNAL_FUND_TRANSFER: "Internal fund transfer",
  },
  payoutFilters: {
    fromDate: "fromDate",
    toDate: "toDate",
    entityName: "entityName",
    invoiceNo: "invoiceNo",
    payoutType: "payoutType",
    orderType: "orderType",
    orderNumber: "orderNumber",
    orderId: "orderId",
    creditInstrument: "creditInstrument",
    paymentStatus: "status",
  },
  filterType: {
    TEXT: "text",
    NUMBER: "number",
    DROPDOWN: "dropdown",
    SEARCH_BAR: "searchBar",
  },
  REFUND_DOCUMENTS_TYPES: ["Cashback", "Refund"],
  CREDIT_DOCUMENTS_TYPES: ["Sales return", "Credit note"],
  INTERNAL_FUND_TRANSFER_HEADERS: [
    "From Account",
    "To Account",
    "Ledger description",
    "Advice",
    "Amount",
    "Status",
    "Comments",
    "Action",
  ],
  INTERNAL_DISBURSEMENT_HEADERS: ["From Account", "To Account", "Amount"],
  ORDER_BLOCK_DISBURSEMENT_HEADERS: [
    "Entity GSTIN",
    "Order Id",
    "Payout Id",
    "Advance Amount",
    "Credit Amount",
    "Credit Instrument",
  ],
  INVOICE_PENDING_HEADERS: [
    "Order type",
    "Order number / Entity name",
    "Document type",
    "Transaction created date",
    "Document number / Original invoice number",
    "Document date / Due date",
    "Document / Invoice amount",
    "Payout amount",
    "Status",
    "Comments",
    "Action",
  ],
  ORDER_BLOCK_PENDING: [
    "Order Id",
    "Order type",
    "Entity GSTIN",
    "Credit instrument",
    "Date",
    "Order amount",
    "Advance amount",
    "Credit amount",
    "Status",
    "Comments",
    "Action",
  ],
  ORDER_BLOCK_HISTORICAL_HEADER: [
    "Order Id",
    "Order type",
    "Entity GSTIN",
    "Credit instrument",
    "Date",
    "Payout amount",
    "Advance amount",
    "Credit amount",
    "Status",
    "Action",
  ],
  INVOICE_HISTORICAL_HEADERS: [
    "Order type",
    "Order number / Entity name",
    "Created At",
    "Initated At",
    "Completed At",
    "Document type",
    "Utr number",
    "Document number / Original invoice number",
    "Document / Invoice date",
    "Payout date",
    "Document / Invoice amount",
    "Payout amount",
    "Status",
  ],
  INVOICE_APPROVED_HEADERS: [
    "Order ID",
    "From account GSTIN",
    "Transaction created date",
    "Document / Invoice ID Original invoice ID",
    "Invoice / Document date",
    "Document / Invoice amount",
    "Payout amount",
    "Document",
    "Status",
    "Action",
  ],
  REFUND_APPROVED_HEADERS: [
    "Payout source",
    "Order no / Entity Name",
    "Payout Type",
    "From account GSTIN",
    "To account GSTIN",
    "Order completion date",
    "Payout amount",
    "Status",
    "Action",
  ],
  REFUND_MODAL_HEADER: [
    "Payout source",
    "Order number/ Entity name",
    "Payout type",
    "Payout amount",
    "A/C Verification Status",
  ],
  TRX_ERROR_MSG: "Transfer failed due to insufficient ledger balance",
  APPROVAL_ACTION_TYPE: {
    payout: "PAYMENT_IN_PROGRESS",
    hold: "PAYMENT_ON_HOLD",
    markSuccess: "MARK_SUCCESS",
  },
  STATUS_DATA: {
    PAYMENT_PENDING: "Pending",
    PAYMENT_FAILED: "Failed",
    PAYMENT_IN_PROGRESS: "In-progress",
    PAYMENT_SUCCESS: "Success",
    PAYMENT_ON_HOLD: "On-hold",
    PAYMENT_REJECTED: "Rejected",
    PAYMENT_INITIATED: "Initiated",
  },
  FUND_VALIDATION_ERROR:
    "Validation failed - Ledger balance is less than refund amount",
});

const {
  transactionFilter: {
    INVOICE_SELLER_PAYOUTS,
    REFUND_CASHBACK,
    ORDER_BLOCK,
    INTERNAL_FUND_TRANSFER,
    INCOMING_PAYMENTS,
    CREDIT_NOTE_SALES_RETURN,
  },
  payoutFilters: {
    entityName,
    invoiceNo,
    payoutType,
    orderType,
    orderNumber,
    orderId,
    creditInstrument,
    paymentStatus,
  },
  filterType: { TEXT, DROPDOWN, SEARCH_BAR },
  orderTypes,
  sellerDocumentTypes,
  REFUND_DOCUMENTS_TYPES,
  CREDIT_DOCUMENTS_TYPES,
  approvedPaymentStatus,
  pendingPaymentStatus,
} = FinancePayouts;

export const financePayoutsFilter = Object.freeze({
  [FINANCE_TAB.historical]: {
    [INVOICE_SELLER_PAYOUTS]: [
      {
        name: entityName,
        placeholder: "Entity name",
        type: SEARCH_BAR,
      },
      { name: invoiceNo, placeholder: "Invoice number", type: TEXT },
      {
        name: orderNumber,
        placeholder: "Order number",
        type: TEXT,
      },
      {
        name: payoutType,
        placeholder: "Document type",
        type: DROPDOWN,
        itemList: Object.values(sellerDocumentTypes),
      },
      {
        name: orderType,
        placeholder: "Order type",
        type: DROPDOWN,
        itemList: orderTypes,
      },
    ],
    [REFUND_CASHBACK]: [
      {
        name: entityName,
        placeholder: "Entity name",
        type: SEARCH_BAR,
      },
      {
        name: orderNumber,
        placeholder: "Order number",
        type: TEXT,
      },
      {
        name: payoutType,
        placeholder: "Document type",
        type: DROPDOWN,
        itemList: REFUND_DOCUMENTS_TYPES,
      },
      {
        name: orderType,
        placeholder: "Order type",
        type: DROPDOWN,
        itemList: orderTypes,
      },
    ],
    [ORDER_BLOCK]: [
      { name: orderId, placeholder: "Enter Order ID", type: TEXT },
      {
        name: creditInstrument,
        placeholder: "Credit instrument",
        type: DROPDOWN,
        valueKey: "instrumentType",
        listType: "object",
      },
    ],
    [INTERNAL_FUND_TRANSFER]: [
      {
        name: entityName,
        placeholder: "Entity name",
        type: SEARCH_BAR,
      },
      { name: orderNumber, placeholder: "Order Number", type: TEXT },
    ],
    [INCOMING_PAYMENTS]: [
      {
        name: entityName,
        placeholder: "Entity name",
        type: SEARCH_BAR,
      },
      { name: invoiceNo, placeholder: "Invoice number", type: TEXT },
      {
        name: payoutType,
        placeholder: "Document type",
        type: DROPDOWN,
        itemList: Object.values(sellerDocumentTypes),
      },
      {
        name: orderType,
        placeholder: "Order type",
        type: DROPDOWN,
        itemList: orderTypes,
      },
    ],
    [CREDIT_NOTE_SALES_RETURN]: [
      {
        name: entityName,
        placeholder: "Entity name",
        type: SEARCH_BAR,
      },
      { name: invoiceNo, placeholder: "Invoice number", type: TEXT },
      {
        name: orderNumber,
        placeholder: "Order number",
        type: TEXT,
      },
      {
        name: payoutType,
        placeholder: "Document type",
        type: DROPDOWN,
        itemList: CREDIT_DOCUMENTS_TYPES,
      },
      {
        name: orderType,
        placeholder: "Order type",
        type: DROPDOWN,
        itemList: orderTypes,
      },
    ],
  },
  [FINANCE_TAB.pending]: {
    [INVOICE_SELLER_PAYOUTS]: [
      {
        name: entityName,
        placeholder: "Entity name",
        type: SEARCH_BAR,
      },
      { name: invoiceNo, placeholder: "Invoice number", type: TEXT },
      {
        name: payoutType,
        placeholder: "Document type",
        type: DROPDOWN,
        itemList: Object.values(sellerDocumentTypes),
      },
      {
        name: orderType,
        placeholder: "Order type",
        type: DROPDOWN,
        itemList: orderTypes,
      },
      { name: orderNumber, placeholder: "Order Number", type: TEXT },
      {
        name: paymentStatus,
        placeholder: "Payment Status",
        type: DROPDOWN,
        itemList: Object.values(pendingPaymentStatus),
      },
    ],
    [REFUND_CASHBACK]: [
      {
        name: entityName,
        placeholder: "Entity name",
        type: SEARCH_BAR,
      },
      // { name: invoiceNo, placeholder: "Invoice number", type: TEXT },
      {
        name: payoutType,
        placeholder: "Document type",
        type: DROPDOWN,
        itemList: REFUND_DOCUMENTS_TYPES,
      },
      {
        name: orderType,
        placeholder: "Order type",
        type: DROPDOWN,
        itemList: orderTypes,
      },
      { name: orderNumber, placeholder: "Order Number", type: TEXT },
      {
        name: paymentStatus,
        placeholder: "Payment Status",
        type: DROPDOWN,
        itemList: Object.values(pendingPaymentStatus),
      },
    ],
    [INTERNAL_FUND_TRANSFER]: [
      {
        name: entityName,
        placeholder: "Entity name",
        type: SEARCH_BAR,
      },
      { name: orderNumber, placeholder: "Order Number", type: TEXT },
    ],
    [ORDER_BLOCK]: [
      { name: orderId, placeholder: "Enter Order ID", type: TEXT },
      {
        name: creditInstrument,
        placeholder: "Credit instrument",
        type: DROPDOWN,
        valueKey: "instrumentType",
        listType: "object",
      },
    ],
    [INCOMING_PAYMENTS]: [
      {
        name: entityName,
        placeholder: "Entity name",
        type: SEARCH_BAR,
      },
      { name: invoiceNo, placeholder: "Invoice number", type: TEXT },
      {
        name: payoutType,
        placeholder: "Document type",
        type: DROPDOWN,
        itemList: Object.values(sellerDocumentTypes),
      },
      {
        name: orderType,
        placeholder: "Order type",
        type: DROPDOWN,
        itemList: orderTypes,
      },
      { name: orderNumber, placeholder: "Order Number", type: TEXT },
    ],
    [CREDIT_NOTE_SALES_RETURN]: [
      {
        name: entityName,
        placeholder: "Entity name",
        type: SEARCH_BAR,
      },
      { name: invoiceNo, placeholder: "Invoice number", type: TEXT },
      {
        name: payoutType,
        placeholder: "Document type",
        type: DROPDOWN,
        itemList: CREDIT_DOCUMENTS_TYPES,
      },
      {
        name: orderType,
        placeholder: "Order type",
        type: DROPDOWN,
        itemList: orderTypes,
      },
      { name: orderNumber, placeholder: "Order Number", type: TEXT },
    ],
  },
  [FINANCE_TAB.upload]: {
    [INVOICE_SELLER_PAYOUTS]: [
      {
        name: entityName,
        placeholder: "Entity name",
        type: SEARCH_BAR,
      },
      { name: invoiceNo, placeholder: "Invoice number", type: TEXT },
      {
        name: orderNumber,
        placeholder: "Order number",
        type: TEXT,
      },
    ],
    [REFUND_CASHBACK]: [
      {
        name: entityName,
        placeholder: "Entity name",
        type: SEARCH_BAR,
      },
      {
        name: orderNumber,
        placeholder: "Order number",
        type: TEXT,
      },
    ],
    [ORDER_BLOCK]: [
      {
        name: entityName,
        placeholder: "Entity name",
        type: SEARCH_BAR,
      },
      {
        name: orderNumber,
        placeholder: "Order number",
        type: TEXT,
      },
    ],
    [INTERNAL_FUND_TRANSFER]: [
      {
        name: entityName,
        placeholder: "Entity name",
        type: SEARCH_BAR,
      },
      {
        name: orderNumber,
        placeholder: "Order number",
        type: TEXT,
      },
    ],
    [CREDIT_NOTE_SALES_RETURN]: [
      {
        name: entityName,
        placeholder: "Entity name",
        type: SEARCH_BAR,
      },
      { name: invoiceNo, placeholder: "Invoice number", type: TEXT },
    ],
  },
  [FINANCE_TAB.approved]: {
    [INVOICE_SELLER_PAYOUTS]: [
      {
        name: entityName,
        placeholder: "Entity name",
        type: SEARCH_BAR,
      },
      { name: invoiceNo, placeholder: "Invoice number", type: TEXT },
      {
        name: payoutType,
        placeholder: "Document type",
        type: DROPDOWN,
        itemList: Object.values(sellerDocumentTypes),
      },
      {
        name: orderType,
        placeholder: "Order type",
        type: DROPDOWN,
        itemList: orderTypes,
      },
      { name: orderNumber, placeholder: "Order Number", type: TEXT },
      {
        name: paymentStatus,
        placeholder: "Payment Status",
        type: DROPDOWN,
        itemList: Object.values(approvedPaymentStatus),
      },
    ],
    [REFUND_CASHBACK]: [
      {
        name: entityName,
        placeholder: "Entity name",
        type: SEARCH_BAR,
      },
      {
        name: payoutType,
        placeholder: "Document type",
        type: DROPDOWN,
        itemList: REFUND_DOCUMENTS_TYPES,
      },
      {
        name: orderType,
        placeholder: "Order type",
        type: DROPDOWN,
        itemList: orderTypes,
      },
      { name: orderNumber, placeholder: "Order Number", type: TEXT },
      {
        name: paymentStatus,
        placeholder: "Payment Status",
        type: DROPDOWN,
        itemList: Object.values(approvedPaymentStatus),
      },
    ],
  },
});
