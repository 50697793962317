import { FinancePayouts } from "../../FinancePayout/constants";

export const sellerRelatedHeaders = [
  "Seller name",
  "Invoiced amount",
  "To be paid to seller",
  "Payout done",
  "Payout pending",
  "Net amount",
  "Status",
  "Payout date",
  "Utr number",
];

export const buyerRefundSummaryHeader = [
  "Amount paid by buyer",
  "Invoiced amount",
  "Amount to be refunded",
  "Total cashback to buyer",
  "Pending payment to be collected from buyer",
  "Net refund amount",
  "Action",
  "Razorpay ID",
];

export const disbursementState = {
  pending: "PAYMENT_PENDING",
  confirmation: "PAYMENT_CONFIRM",
  success: "PAYMENT_SUCCESS",
  failed: "PAYMENT_FAILED",
  approved: "PAYMENT_APPROVED",
  in_process: "PAYMENT_IN_PROCESS",
  rejected: "PAYMENT_REJECTED",
};

export const financePayouts = {
  queryFilter: FinancePayouts.queryFilters,
  financeTab: FinancePayouts.financeTab,
  payoutFilters: FinancePayouts.payoutFilters,
  financeType: {
    invoiceSeller: FinancePayouts.INVOICE_SELLER_PAYOUTS,
    refundCashback: FinancePayouts.REFUND_CASHBACK,
  },
};
export const success = "success";
export const error = "error";
export const refund = "Refund";
export const CREATE_REFUND_SUCCESS_MSG = "Refund is created successfully";
export const CASHBACK = "Cashback";
