import * as Yup from "yup";

export const createCreditLimit = Yup.object().shape({
  fileContent: Yup.array().of(
    Yup.object().shape({
      creditType: Yup.string().required("Please enter instrument name"),
      expiryDate: Yup.date().required("Please enter expiry date"),
      creditInstrumentType: Yup.string().required(
        "Please enter credit program name",
      ),
      penalRate: Yup.number()
        .typeError("Enter a valid penal rate")
        .required("Please enter penal rate"),
      creditDueDays: Yup.number()
        .typeError("Enter a valid credit due days")
        .required("Please enter credit due days"),
      sanctionedLimit: Yup.number()
        .typeError("Enter a valid sanctioned limit")
        .required("Please enter the sanctioned limit"),
      camId: Yup.string().required(
        "Please enter the Credit limit Reference ID",
      ),
      gracePeriod: Yup.number()
        .typeError("Enter a valid grace period")
        .required("Please enter the grace period"),
      advancePercentage: Yup.number()
        .typeError("Enter a valid advance percentage")
        .min(0, "Enter a value between 0 to 100")
        .max(100, "Enter a value between 0 to 100"),
      sellerGstin: Yup.string()
        .required("Enter valid GSTIN")
        .length(15, "Seller gstin must be 15 characters"),
      companyName: Yup.string().required("Please enter company name"),
      gstin: Yup.string()
        .required("Please enter gstin")
        .length(15, "GSTIN must be 15 characters"),
      pan: Yup.string()
        .required("Please enter pan")
        .length(10, "Pan must be 10 characters"),
    }),
  ),
});

export default createCreditLimit;
