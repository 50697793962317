import { createSlice } from "@reduxjs/toolkit";

export const auth = createSlice({
  name: "auth",
  initialState: {},
  reducers: {
    verifyLoginAction: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { verifyLoginAction } = auth.actions;

export default auth.reducer;
