import { TableCell, Box } from "@mui/material";
import { SectionTitle } from "./CustomComponents";
import { TableWrapper, ButtonV1 } from "components";
import { sellerRelatedHeaders } from "./constants";
import { StyledTableRow, ColumnTitle } from "CustomStyledComponents";
import { appendRupeeIcon, moneyFormat, validateNull } from "utils";

const SellerDetails = ({ reconData, onIntiateDisbursement }) => {
  const getPayoutDone = (shipmentPayout) => {
    let payoutDone = 0;
    shipmentPayout.forEach((element) => {
      payoutDone = payoutDone + element?.shipmentPayoutCompleted;
    });

    return moneyFormat(payoutDone);
  };
  return (
    !!reconData?.sellerPayoutSummary?.payoutSellerSummary?.length && (
      <Box style={{ marginBottom: 16 }}>
        <SectionTitle title="Seller reconciliation" />
        <TableWrapper
          tableHeader={sellerRelatedHeaders.map((item) => {
            return (
              <TableCell key={item}>
                <ColumnTitle
                  style={{
                    display: "flex",
                    justifyContent: item === "Action" ? "center" : "flex-start",
                  }}
                >
                  {item}
                </ColumnTitle>
              </TableCell>
            );
          })}
          tableBody={reconData?.sellerPayoutSummary?.payoutSellerSummary?.map(
            (item, i) => {
              return (
                <StyledTableRow key={i}>
                  <TableCell>{item?.sellerCompanyName}</TableCell>
                  <TableCell>
                    {appendRupeeIcon(
                      item?.sellerInvoiceAmountDisplay?.displayAmount,
                    )}
                  </TableCell>
                  <TableCell>
                    {appendRupeeIcon(item?.toBePaidDisplay?.displayAmount)}
                  </TableCell>
                  <TableCell>{getPayoutDone(item?.shipmentPayouts)}</TableCell>
                  <TableCell>
                    {appendRupeeIcon(
                      item?.payoutPendingToSellerDisplay?.displayAmount,
                    )}
                  </TableCell>
                  <TableCell>
                    {appendRupeeIcon(item?.netAmountDisplay?.displayAmount)}
                  </TableCell>
                  <TableCell>
                    <ButtonV1
                      size="small"
                      title={item?.stateOfDisbursement?.label}
                      variant={"text"}
                      onClick={() => onIntiateDisbursement(item, true)}
                    />
                  </TableCell>
                  <TableCell>
                    {item?.payoutDate
                      ? new Date(item?.payoutDate).toLocaleDateString()
                      : ""}
                    <br />
                    {item?.payoutDate
                      ? new Date(item?.payoutDate).toLocaleTimeString()
                      : ""}
                  </TableCell>
                  <TableCell>
                    {validateNull(item?.shipmentPayouts?.[0]?.utr)}
                  </TableCell>
                  {/* <TableCell>{item?.stateOfDisbursement?.state}</TableCell> */}
                  {/* <TableCell>{item?.transferId}</TableCell> */}
                </StyledTableRow>
              );
            },
          )}
        />
      </Box>
    )
  );
};

export default SellerDetails;
