import { useMemo } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import { StyledTableRow } from "CustomStyledComponents";
import { moneyFormat, validateNull } from "utils";
import { ZOHO_ORDER_CONSTANTS } from "../constants";

const ReleaseStatusTable = ({ releaseStatusList }) => {
  const tableFields = useMemo(() => {
    return [
      {
        title: "Order number ",
        field: "shipmentNumber",
        render: (ele) => (
          <Typography style={{ width: 120 }} fontSize={14}>
            {validateNull(ele?.orderNumber)}
          </Typography>
        ),
      },
      {
        title: "Advance amount",
        field: "advanceAmount",
        render: (ele) => (
          <Typography style={{ width: 120 }} fontSize={14}>
            {moneyFormat(ele?.advanceAmount)}
          </Typography>
        ),
      },
      {
        title: "Credit amount",
        field: "creditAmount",
        render: (ele) => (
          <Typography style={{ width: 120 }} fontSize={14}>
            {moneyFormat(ele?.creditAmount)}
          </Typography>
        ),
      },
      {
        title: "Release reason",
        field: "releaseReason",
        render: (ele) => (
          <Typography fontSize={14}>{ele?.releaseReason}</Typography>
        ),
      },
      {
        title: "Zoho status",
        field: "status",
        render: (ele) => (
          <Typography fontSize={14}>
            {ZOHO_ORDER_CONSTANTS[ele?.status]}
          </Typography>
        ),
      },
    ];
  }, []);

  return (
    <Box p={2}>
      <Box component={Paper}>
        <TableContainer
          sx={{
            minWidth: 700,
            maxHeight: "calc(100vh - 120px)",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              {tableFields?.map((el) => (
                <TableCell key={el.field}>{el.title}</TableCell>
              ))}
            </TableHead>
            <TableBody>
              {!releaseStatusList?.length ? (
                <StyledTableRow>
                  <TableCell colSpan={tableFields.length} align="center">
                    <Typography textAlign={"center"}>No data found</Typography>
                  </TableCell>
                </StyledTableRow>
              ) : (
                releaseStatusList?.map((row) => (
                  <StyledTableRow key={row?.shipmentNumber}>
                    {tableFields?.map((el) => (
                      <TableCell key={el?.field}>{el.render(row)}</TableCell>
                    ))}
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

ReleaseStatusTable.propTypes = {
  releaseStatusList: PropTypes.array,
};
export default ReleaseStatusTable;
