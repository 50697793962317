export const ZOHO_ORDER_CONSTANTS = {
  PENDING: "Pending",
  SUCCESSFULL: "Success",
  PICKED: "Picked",
  INITIATED: "Initiated",
  CANCELLED: "Cancelled",
  CREATED: "Created",
  SUCCESSFUL: "Success",
  SUCCESS: "Success",
};
