export const createRoleModel = (
  selectedRegionList,
  selectedBusinessList,
  selectedModuleList,
  roleName,
  employeeId,
) => {
  const selectedRegionId = selectedRegionList?.map(({ id }) => id);
  const selectedBusinessId = selectedBusinessList?.map(({ id }) => id);
  const selectedModuleId = selectedModuleList?.map(({ id }) => id);

  const result = {
    name: roleName,
    label: roleName,
    scopes: [
      {
        stateId: selectedRegionId,
        businessUnitId: selectedBusinessId,
        modulesId: selectedModuleId,
        userId: employeeId,
      },
    ],
  };
  return result;
};
