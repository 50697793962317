// import { useRef } from "react";
import { Typography, Box } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { creditNotesSchema } from "./tests";
import ButtonView from "../components/ButtonView";
import CreditNotesManualInputForm from "./CreditNotesManualInputForm";
import { CreditNotesSellerPayout } from "./constants";

const { creditYup } = CreditNotesSellerPayout;

const UpdateTransaction = ({ updateTransaction, transaction }) => {
  const methods = useForm({
    mode: "onTouched",
    resolver: yupResolver(creditNotesSchema),
  });
  // const scrollToRef = useRef(null);

  // useEffect(() => {
  //   window.scrollTo({
  //     top: scrollToRef.current.offsetTop - 100,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // }, []);

  const {
    reset,
    handleSubmit,
    getValues,
    formState: { isDirty },
  } = methods;

  const handleUpdate = () => {
    const body = {
      id: transaction?.id,
      ...getValues(creditYup)?.[0],
    };
    updateTransaction(body);
  };

  return (
    <FormProvider {...methods}>
      <Typography marginTop={5}>Manual upload</Typography>
      <Box
        as="form"
        // ref={scrollToRef}
        onSubmit={handleSubmit(handleUpdate)}
        sx={{ paddingY: 3 }}
      >
        {[transaction].map((txItem, index) => (
          <CreditNotesManualInputForm
            key={txItem.id}
            unique={index}
            transaction={txItem}
          />
        ))}

        <ButtonView
          title="Update"
          disabled={!isDirty}
          onClearAll={() => {
            reset();
          }}
        />
      </Box>
    </FormProvider>
  );
};

export default UpdateTransaction;
