import * as Yup from "yup";

const UserDetailsSchema = Yup.object().shape({
  mobileNumber: Yup.number()
    .typeError("Please enter a number")
    .required("Please enter your mobile number")
    .min(1000000000, "Invalid number. Eg:91xxxxxx81")
    .max(9999999999, "Invalid number. Eg:91xxxxxx81"),
});

export { UserDetailsSchema };
