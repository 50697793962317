import { useMemo } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Typography,
} from "@mui/material";

import { ExportCta, Loader, PaginationAction, StatusChip } from "components";
import { ColumnTitle, StyledTableRow } from "CustomStyledComponents";

import { getCall_v2 } from "services";
import { downloadCsv, moneyFormat, validateNull } from "utils";
import { useToaster } from "hooks";

import { FinancePayouts } from "pages/FinancePayout/constants";
import { STATUS_CHIP } from "constants";
import { success } from "constants";
import { error } from "constants";

const ROWS_PER_PAGE = 50;

const InternalFundTable = ({
  payoutData,
  onPageChange,
  isLoading,
  page,
  filters,
}) => {
  const triggerToaster = useToaster();

  const tableFields = useMemo(() => {
    return [
      {
        title: "From Account",
        field: "fromGstin",
        render: (ele) => (
          <Typography fontSize={14}>
            {validateNull(ele?.fromCompanyData?.companyName)}
            <br />({ele?.fromGstin})
          </Typography>
        ),
      },
      {
        title: "To Account",
        field: "toGstin",
        render: (ele) => (
          <Typography fontSize={14}>
            {validateNull(ele?.toCompanyData?.companyName)}
            <br />({ele?.toGstin})
          </Typography>
        ),
      },
      {
        title: "Ledger description",
        field: "ledgerDescription",
        render: (ele) => (
          <Typography fontSize={14}>
            {validateNull(ele?.ledgerDescription)}
          </Typography>
        ),
      },
      {
        title: "Advice",
        field: "advice",
        render: (ele) => {
          return (
            <Typography fontSize={14}>{validateNull(ele?.advice)}</Typography>
          );
        },
      },
      {
        title: "Transaction date",
        field: "transferDate",
        render: (ele) => (
          <Typography fontSize={14}>
            {validateNull(ele?.transferDate)}
          </Typography>
        ),
      },
      {
        title: "Amount",
        field: "amount",
        render: (ele) => (
          <Typography fontSize={14}>
            {validateNull(moneyFormat(ele?.amount))}
          </Typography>
        ),
      },
      {
        title: "Status",
        field: "status",
        render: () => (
          <StatusChip
            label={FinancePayouts.success}
            type={STATUS_CHIP.success}
          />
        ),
      },
    ];
  }, []);

  const onExportData = async () => {
    try {
      const payoutUrl = `/oms/off-oms-internal-transfer/download/list`;
      const { data } = await getCall_v2(payoutUrl, {
        ...filters,
        limit: 5000,
        offset: 0,
      });
      triggerToaster(`Downloading file`, success);
      downloadCsv(data, "internal_fund_transfer");
    } catch (err) {
      console.error(`Error in download, ${err}`);
      triggerToaster(`Please try again later`, error);
    }
  };

  const { response, totalCount } = payoutData;

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end">
        <ExportCta onExport={onExportData} />
      </Box>
      <Box component={Paper}>
        <TableContainer
          sx={{
            minWidth: 700,
            maxHeight: "calc(100vh - 120px)",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              {tableFields.map((el) => (
                <TableCell key={el.field}>
                  <ColumnTitle>{el.title}</ColumnTitle>
                </TableCell>
              ))}
            </TableHead>
            <TableBody>
              {isLoading || !response?.length ? (
                <StyledTableRow>
                  <TableCell colSpan={tableFields.length} align="center">
                    {isLoading ? (
                      <Loader sx={{ marginTop: 0 }} />
                    ) : (
                      <Typography textAlign={"center"}>
                        No data found
                      </Typography>
                    )}
                  </TableCell>
                </StyledTableRow>
              ) : (
                response?.map((row) => (
                  <StyledTableRow key={row?.customerOrdNumber}>
                    {tableFields?.map((el) => (
                      <TableCell key={el?.field}>{el.render(row)}</TableCell>
                    ))}
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!!response?.length && (
          <TablePagination
            rowsPerPageOptions={[ROWS_PER_PAGE]}
            rowsPerPage={ROWS_PER_PAGE}
            page={page}
            count={totalCount ?? 0}
            onPageChange={onPageChange}
            sx={{ display: "flex", flexDirection: "column-reverse" }}
            ActionsComponent={PaginationAction}
          />
        )}
      </Box>
    </Box>
  );
};

export default InternalFundTable;
