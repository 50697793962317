import { useCallback, useState } from "react";

import PendingApprovalTable from "./PendingApprovalTable";
import Filter from "../components/Filter";

const PendingApproval = () => {
  const [filter, setFilter] = useState(null);

  const handleResetFilter = useCallback(() => setFilter(null), []);
  const handleSubmitFilter = useCallback((value) => setFilter(value), []);

  return (
    <>
      <Filter
        onReset={handleResetFilter}
        onSumbit={handleSubmitFilter}
        isPendingApproval={true}
      />
      <PendingApprovalTable filter={filter} />
    </>
  );
};

export default PendingApproval;
