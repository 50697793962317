import { useMemo } from "react";
import { Typography, Box, styled, Grid } from "@mui/material";
import theme from "themeProvider";

import { ViewOnly } from "components";
import StatusChip from "./StatusChip";
import makeData from "./makeData";

export const SectionTitle = ({ title }) => {
  return (
    <Typography variant="h4" margin={0} fontWeight="800" alignSelf={"center"}>
      {title}
    </Typography>
  );
};

export const SummaryHeader = ({ label, value }) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="body1"
        style={{ fontWeight: "400", marginBottom: 8 }}
      >
        {label}
      </Typography>
      <Typography
        variant="body1"
        style={{
          fontWeight: "900",
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export const PayoutSummaryHeader = ({ item }) => {
  const { shipmentSummaryHeader } = makeData(item);
  return useMemo(
    () => (
      <>
        <Box display={"flex"} width={"100%"}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginLeft: 30,
            }}
          >
            {shipmentSummaryHeader.map((item) => {
              return (
                <SummaryHeader
                  label={item.label}
                  value={item.value}
                  key={item.label}
                />
              );
            })}
            {item?.shipmentInvoiceDetails?.payoutHoldType && (
              <StatusChip
                status={item?.shipmentInvoiceDetails?.payoutHoldType}
              />
            )}
          </Box>
        </Box>
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item?.stateOfDisbursement?.state],
  );
};

export const CustomViewOnly = ({ label, value, ...rest }) => {
  return (
    <ViewOnly
      label={label}
      labelStyle={{
        marginBottom: 0,
        fontWeight: "500",
      }}
      rest={{
        flexDirection: "column",
        gap: theme.spacing(2),
        width: "100%",
        ...rest,
      }}
      value={value}
    />
  );
};

export const FlexBox = styled(Box)(({ theme, styles }) => ({
  display: "flex",
  flexWrap: "wrap",
  rowGap: theme.spacing(8),
  justifyContent: "space-between",
  marginBottom: 32,
  width: "100%",
  ...styles,
}));

export const GridSkeleton = ({ firstChild, secondChild, thirdChild }) => {
  return (
    <>
      <Grid container width={"100%"} justifyContent={"space-between"}>
        <Grid xs={4} item>
          {firstChild}
        </Grid>
        <Grid xs={4} item>
          {secondChild}
        </Grid>
        <Grid xs={2} item>
          {thirdChild}
        </Grid>
      </Grid>
    </>
  );
};
