import { orderPaymentTypes } from "constants";
import { CREDIT } from "../constants";
import { checkIsDirectOrder } from "utils";

const full = "Full";
const partial = "Partial";
const additionalPercentageAllowed = 20 / 100;

export const validateLineItem = (orderDetails, updatedValues) => {
  try {
    let validationSchema = { isValidLineItem: true, message: "" };

    const {
      paymentStatus,
      orderAmount,
      orderPaymentType,
      paymentType,
      isZeroAdvanceOrder = false,
      totalInvoiceAmount = {},
      category,
      lineItemDetails,
    } = orderDetails;

    //DO lineitem check
    if (checkIsDirectOrder(category)) {
      let invalidLineItemQty = false;
      for (let i = 0; i < lineItemDetails.length; i++) {
        const id = lineItemDetails[i]?.lineItemId;
        const totalQty =
          lineItemDetails[i].quantityOrdered -
            lineItemDetails[i]?.quantityCancelled ??
          0 - lineItemDetails[i]?.shortClosed ??
          0;
        const enteredQty =
          updatedValues?.lineItemsDetails?.[id]?.enteredQuantity ?? 0;
        const maxQtyAllowed =
          totalQty +
            Number((additionalPercentageAllowed * totalQty).toFixed(2)) ?? 0;
        if (enteredQty > maxQtyAllowed) {
          invalidLineItemQty = true;
          break;
        }
      }
      if (invalidLineItemQty) {
        validationSchema.isValidLineItem = false;
        validationSchema.message =
          "Shipment can only be created if planning quantity is less than pending released qty.";
        return validationSchema;
      }
    }

    if (
      (paymentStatus === full ||
        paymentStatus === partial ||
        isZeroAdvanceOrder) &&
      paymentType !== CREDIT
    ) {
      if (
        totalInvoiceAmount.hasOwnProperty("centAmount") &&
        Number(totalInvoiceAmount?.centAmount ?? 0) >
          Number(orderAmount.centAmount)
      ) {
        validationSchema.isValidLineItem = false;
        validationSchema.message =
          "Shipment can only be created if invoiced Amount is less than order amount";
        return validationSchema;
      } else {
        // for payment type cash carry or any other type apart from credit
        return validationSchema;
      }
    } else if (orderPaymentType !== orderPaymentTypes.CREDIT) {
      validationSchema.isValidLineItem = false;
      validationSchema.message =
        "Shipment can only be created if payment status is full or partial";

      return validationSchema;
    } else {
      return validationSchema;
    }
  } catch (exception) {
    console.error(exception);
    return { isValidLineItem: false, message: "validate lineitem fn failed" };
  }
};
