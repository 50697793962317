import { dateConvertor, pascalCase } from "utils";
import { CREDIT } from "./constant";

function escapeCommaInCSV(value) {
  // If the value contains a comma, enclose it within double quotes
  if (typeof value === "string" && value.includes(",")) {
    value = '"' + value + '"';
  }
  return value;
}
export const makeCsvData = (data) => {
  const header = CREDIT.tableHeader.join(",");
  const body = data?.response?.map((order) => {
    return [
      order?.customerOrderNumber || "-",
      order.sellerOrdNumber || "-",
      dateConvertor(order?.buyerOrderDate) || "-",
      order?.currentOrderType || "-",
      order?.currentOrderState || "-",
      order?.childCurrentState.orderState || "-",
      order?.opsSpoc || "-",
      order?.currentPaymentStatus || "-",
      dateConvertor(order?.committedDeliveryDates) || "-",
      dateConvertor(order?.scheduledDeliveryDates) || "-",
      pascalCase(order?.companyName) || "-",
      order?.sellerCompanyInfo?.name || "-",
      order?.shippingState || "-",
    ]
      .map((item) => escapeCommaInCSV(item))
      .join(",");
  });
  return [header, ...body].join("\n");
};
