import { Typography, Box, Divider } from "@mui/material";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { styled } from "@mui/system";

import theme from "themeProvider";

const FlexJustify = styled(Box)({
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  padding: 0,
});

const FlexGap = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  color: theme.palette.text.primary,
});

const DiscountBox = styled(Box)({
  position: "relative",
  backgroundColor: theme.palette.grey["A100"],
  marginBottom: 24,
  padding: 16,
  borderRadius: 8,
  "::before": {
    content: "''",
    width: "10px",
    height: "10px",
    top: "55px",
    position: "absolute",
    left: "-7px",
    borderRadius: "100%",
    background: "white",
  },
  "::after": {
    content: "''",
    width: "10px",
    height: "10px",
    top: "55px",
    position: "absolute",
    right: "-7px",
    borderRadius: "100%",
    background: "white",
  },
});

const Discount = ({ data }) => {
  return (
    <DiscountBox>
      <FlexJustify style={{ marginBottom: 4 }}>
        <Typography variant="h3" m={0}>
          Discount
        </Typography>
        <LocalOfferOutlinedIcon sx={{ fontSize: 30 }} />
      </FlexJustify>
      <Divider
        style={{
          borderStyle: "dashed",
          borderBottomWidth: 3,
          marginBottom: 8,
        }}
      />
      <FlexGap>
        <FlexJustify>
          <Typography>Coupon code</Typography>
          <Typography>{data["Coupon code"]}</Typography>
        </FlexJustify>
        <FlexJustify sx={{ flexWrap: "unset" }}>
          <Typography>Description</Typography>
          <Typography sx={{ textAlign: "end" }}>
            {data["Description"]}
          </Typography>
        </FlexJustify>
      </FlexGap>
    </DiscountBox>
  );
};

export default Discount;
