import { downloadCsv } from "utils";

const createCsv = (head, data) => {
  const csvData = [];
  const headings = [];
  const fields = [];
  const fieldRender = [];

  const getFieldValue = (elem, field = "", index) => {
    let value = elem;
    field.split(".")?.forEach((elem) => {
      value = value?.[elem];
    });
    if (fieldRender[index]) {
      const output = fieldRender[index](value);
      if (typeof output === "string" || typeof output === "number") {
        return output;
      }
      return value?.toString() || "-";
    }
    return value || "";
  };

  //   Get the table Heading
  head?.forEach(({ title, field, render }) => {
    headings.push(title);
    fields.push(field);
    fieldRender.push(render || null);
  });
  csvData.push(headings.join(","));

  //   Get the body of table
  data?.forEach((elem) => {
    const values =
      fields?.map((field, index) => {
        const value = getFieldValue(elem, field, index).toString();
        return value.includes(",")
          ? value.replace(new RegExp(",", "g"), "")
          : value;
      }) || [];
    csvData.push(values.join(","));
  });
  return csvData.join("\n");
};

const fileExportCsv = (head, data, fileName) => {
  const csvData = createCsv(head, data);
  downloadCsv(csvData, fileName);
};

export { fileExportCsv };
