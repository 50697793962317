import { useDispatch } from "react-redux";
import { displayToasterAction } from "dataStore/showSnackBar";

const useToaster = () => {
  const dispatch = useDispatch();

  const triggerToaster = (message, variant) => {
    dispatch(
      displayToasterAction({
        value: true,
        message,
        variant,
      }),
    );
  };

  return triggerToaster;
};
export default useToaster;
