import {
  useCallback,
  useMemo,
  forwardRef,
  useEffect,
  useRef,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import {
  HyperLink,
  Loader,
  PaginationAction,
  SvgIcon,
  TooltipLabel,
  TableRowWithError,
} from "components";
import { StatusChip } from "pages/FinancePayout/PendingPayoutsTab/components";
import { HoverBlock } from "pages/FinancePayout/components";
import { useToaster } from "hooks";

import {
  addTx,
  removeSingleTx,
  removeTxList,
  truncateTx,
} from "dataStore/approvedCheckedTx";
import { dateConvertor, moneyFormat } from "utils";
import { FinancePayouts } from "pages/FinancePayout/constants";
import { error } from "constants";

const { REFUND_APPROVED_HEADERS, PAYMENT_FAILED } = FinancePayouts;

const RefundTable = forwardRef(
  (
    {
      approvedTransactions = {},
      rowsPerPage,
      pageNumber,
      onPageChange,
      loading,
      setCheckBox,
      checkBox,
      selectedTx,
    },
    ref,
  ) => {
    const dispatch = useDispatch();
    const allTxCheckBoxRef = useRef(null);
    const triggerToaster = useToaster();
    const [isMarkAllCheckboxChecked, setIsMarkAllCheckboxChecked] =
      useState(false);

    const getPayoutTooltipData = (data) => {
      let formattedData = {
        "Invoice amount": moneyFormat(
          data?.shipmentInvoiceDetails?.totalInvoicedAmount,
        ),
        "TCS on GST (1%) on Invoice": moneyFormat(data?.tcsInvoiceAmount),
        "TDS (1%) on Invoice": moneyFormat(data?.tdsInvoiceAmount),
        "JOPL commission for  seller": moneyFormat(data?.joplCommision),
        "Payable to seller": moneyFormat(data?.payableToSeller),
        "Payout amount  now": moneyFormat(data?.amount),
      };
      return formattedData;
    };

    const handleCheckBox = useCallback(
      (item) => {
        let ids = [item.payoutId];
        setCheckBox((prev) => {
          let checkBox = prev;
          ids.forEach((id) => {
            if (checkBox.includes(id)) {
              checkBox = checkBox.filter((check) => check !== id);
              //remove single tx from list
              dispatch(removeSingleTx(id));
            } else {
              checkBox = [...checkBox, id];
              //store tx to be saved
              dispatch(addTx([item]));
            }
          });
          return checkBox ?? [];
        });
      },
      [setCheckBox],
    );

    // validate, select if customer account exists
    const validTxs = useMemo(() => {
      return approvedTransactions?.payoutSummaries?.filter((txData) => {
        if (txData?.isNew) {
          return txData?.fundAccountDetails?.customerAccount;
        }
        return txData;
      });
    }, [approvedTransactions?.payoutSummaries]);

    const handleAllCheckBox = useCallback(
      (ev) => {
        const filteredTx = validTxs;
        if (!filteredTx || !filteredTx.length) {
          triggerToaster(`No valid transaction available in this list`, error);
          return;
        }
        const payoutIds = filteredTx?.map(({ payoutId }) => payoutId) ?? [];
        setIsMarkAllCheckboxChecked(ev.target.checked); // set state for UI visibility
        if (checkBox.length !== filteredTx?.length) {
          setCheckBox(payoutIds);
          //save checkedTx
          dispatch(addTx(filteredTx));
        } else {
          setCheckBox([]);
          //remove list of tx
          dispatch(removeTxList(filteredTx));
        }
      },
      [checkBox.length, validTxs],
    );

    const getIsCheckBoxDisabled = (item) => {
      const isDisabled = selectedTx.includes(item?.payoutId);
      if (item?.isNew) {
        return isDisabled || !item?.fundAccountDetails?.customerAccount;
      }
      return isDisabled;
    };

    /**
     * expose checkbox reference
     */
    useImperativeHandle(ref, () => ({
      unSelectCheckAllBox: () => setIsMarkAllCheckboxChecked(false),
    }));

    //component will unmount hook
    useEffect(() => {
      return () => {
        dispatch(truncateTx());
      };
    }, []);

    return (
      <Paper>
        <TableContainer
          sx={{
            minWidth: 700,
            maxHeight: "calc(100vh - 120px)",
          }}
        >
          <Table stickyHeader>
            <TableHead style={{ borderRadius: "10px 10px 0 0" }}>
              <TableRow>
                <TableCell>
                  <Checkbox
                    onChange={handleAllCheckBox}
                    checked={isMarkAllCheckboxChecked}
                    ref={allTxCheckBoxRef}
                  />
                </TableCell>
                {REFUND_APPROVED_HEADERS.map((heading, i) => (
                  <TableCell key={i}>{heading}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {approvedTransactions?.payoutSummaries?.length && !loading ? (
                approvedTransactions?.payoutSummaries?.map((item, i) => (
                  <>
                    <TableRow key={item?.payoutId}>
                      <TableCell>
                        {!selectedTx.includes(item?.payoutId) && (
                          <Checkbox
                            key={item?.payoutId}
                            checked={checkBox.includes(item?.payoutId)}
                            disabled={getIsCheckBoxDisabled(item)}
                            onChange={() => {
                              handleCheckBox(item);
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <StatusChip status={item?.orderType} isOrderType />
                      </TableCell>
                      <TableCell>
                        <HyperLink title={item?.customerOrdNumber} /> /
                        <Typography>{item?.entityName}</Typography>
                      </TableCell>
                      <TableCell>{item?.payoutType}</TableCell>
                      <TableCell>{item?.fromGstin}</TableCell>
                      <TableCell>{item?.toGstin}</TableCell>
                      <TableCell>
                        {dateConvertor(
                          item?.shipmentInvoiceDetails.invoiceDetails[0]
                            ?.invoiceDate,
                        )}
                      </TableCell>

                      <TableCell>
                        <Box display={"flex"} alignItems={"center"}>
                          {!!item?.amount && moneyFormat(item?.amount)}
                          {!!item?.amount &&
                            !["CASHBACK", "REFUND"].includes(
                              item?.payoutTypeKey,
                            ) && (
                              <HoverBlock
                                LabelChildren={
                                  <InfoOutlined
                                    style={{ width: 16, marginLeft: 6 }}
                                    color={"primary"}
                                  />
                                }
                                data={getPayoutTooltipData(item)}
                              />
                            )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box display={"flex"} alignItems={"center"}>
                          <StatusChip
                            status={item?.nextStates}
                            isTooltip={true}
                          />
                          {item?.status === PAYMENT_FAILED && (
                            <TooltipLabel
                              placement="right"
                              title={
                                <Typography sx={{ fontSize: 14, margin: 4 }}>
                                  {item?.errorMessage}
                                </Typography>
                              }
                              labelChildren={
                                <InfoOutlined
                                  style={{
                                    width: 16,
                                    marginLeft: 6,
                                    marginTop: 4,
                                  }}
                                  color={"primary"}
                                />
                              }
                            />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box style={{ textAlign: "center" }}>
                          {item?.invoiceComments ? (
                            <HoverBlock
                              LabelChildren={
                                <IconButton>
                                  <SvgIcon
                                    name={"chat-unread"}
                                    width={24}
                                    height={24}
                                  />
                                </IconButton>
                              }
                              data={{
                                "Invoice comments": item?.invoiceComments,
                              }}
                            />
                          ) : (
                            <IconButton>
                              <SvgIcon
                                name={"chat-read"}
                                width={24}
                                height={24}
                              />
                            </IconButton>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                    {!!item?.isNew &&
                      !item?.fundAccountDetails?.customerAccount && (
                        <TableCell
                          colSpan={REFUND_APPROVED_HEADERS.length + 1}
                          sx={{ textAlign: "center", padding: 0 }}
                        >
                          <TableRowWithError
                            message={"Customer account not found."}
                            id={item?.payoutId}
                            key={item?.payoutId}
                            length={REFUND_APPROVED_HEADERS.length + 1}
                          />
                        </TableCell>
                      )}
                  </>
                ))
              ) : loading ? (
                <TableCell
                  colSpan={REFUND_APPROVED_HEADERS.length}
                  sx={{ textAlign: "center" }}
                >
                  <Loader sx={{ marginTop: 0 }} />
                </TableCell>
              ) : (
                <TableCell
                  colSpan={REFUND_APPROVED_HEADERS.length}
                  sx={{ textAlign: "center" }}
                >
                  No data found
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!!approvedTransactions?.payoutSummaries?.length && (
          <TablePagination
            rowsPerPageOptions={[rowsPerPage]}
            rowsPerPage={rowsPerPage}
            page={pageNumber}
            count={approvedTransactions?.totalCount ?? 0}
            onPageChange={onPageChange}
            sx={{ display: "flex", flexDirection: "column-reverse" }}
            ActionsComponent={PaginationAction}
          />
        )}
      </Paper>
    );
  },
);

export default RefundTable;
