import { useState } from "react";
import { Typography, Stack, Box, IconButton } from "@mui/material";
import { Attachment, DeleteForever } from "@mui/icons-material";

import { ButtonV1 } from "components";
import theme from "themeProvider";

const DocumentUploader = ({
  uploadedFiles = {},
  onUpload,
  onDownload,
  isLoading,
}) => {
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [fileNames, setFileNames] = useState([]);

  const allowedMaxSize = 2; //MB

  const validateSize = (evt) => {
    const tempFile = evt?.target?.files?.[0];
    const name = tempFile?.name;

    const fileSize = tempFile.size / 1024 / 1024; //convert to MB
    if (fileSize.toFixed(2) > allowedMaxSize) {
      setErrorMessage("Allowed max file size is 2MB");
      return;
    } else {
      if (fileNames.some((ele) => ele === name)) {
        setErrorMessage("File is already selected");
        return;
      }

      if (uploadedFiles?.originalName === name) {
        setErrorMessage("File is already selected");
        return;
      }
    }
    setErrorMessage(null);
    const cloneFiles = [...files, tempFile];
    const cloneFileNames = [...fileNames, name];
    setFiles(cloneFiles);
    setFileNames(cloneFileNames);
  };

  const onRemoveFile = () => {
    setFileNames([]);
    setFiles([]);
  };

  return (
    <Box width={"100%"}>
      {uploadedFiles &&
        Object.keys(uploadedFiles).map((ele, k) => {
          return (
            <Box
              key={k}
              px={2}
              py={2}
              mt={1}
              style={{
                border: `1px solid ${theme.palette.grey["400"]}`,
                borderRadius: 10,
              }}
            >
              <Box
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                }}
                onClick={() => onDownload()}
              >
                <Attachment />
                <Typography style={{ fontWeight: "bold" }} ml={1}>
                  {uploadedFiles[ele]?.originalName?.substring(0, 30)}
                </Typography>
              </Box>
            </Box>
          );
        })}
      {/* Shown after a file has been attached and ready to upload */}
      {!Object.keys(uploadedFiles).length && !!fileNames.length && (
        <Box
          px={2}
          py={2}
          mt={1}
          style={{
            border: `1px solid ${theme.palette.grey["400"]}`,
            borderRadius: 10,
          }}
          mb={2}
        >
          <Stack direction={"row"}>
            {fileNames.map((name, k) => {
              return (
                <Box key={k} display={"flex"} alignItems={"center"} pl={2}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {name?.substring(0, 30)}
                  </Typography>
                  <IconButton
                    component="label"
                    onClick={() => onRemoveFile()}
                    sx={{ p: 0 }}
                    disableRipple={true}
                  >
                    <DeleteForever />
                  </IconButton>
                </Box>
              );
            })}
            <ButtonV1
              variant="text"
              onClick={() => onUpload(files)}
              title={"Upload"}
              style={{ padding: 0 }}
            />
          </Stack>
        </Box>
      )}
      {/* show for user to attach a file  */}
      {Object.keys(uploadedFiles).length === 0 && !fileNames.length && (
        <>
          <Box
            px={2}
            py={2}
            mt={1}
            style={{
              border: `1px solid ${theme.palette.grey["400"]}`,
              borderRadius: 10,
              width: "100%",
            }}
          >
            <IconButton sx={{ p: 0 }} disableRipple={true} component="label">
              <Attachment style={{ color: theme.palette.grey["700"] }} />
              <input
                aria-label="file"
                type="file"
                accept="image/*, .pdf,.PDF"
                hidden
                onChange={(e) => validateSize(e)}
              />
              <Typography color={theme.palette.grey["700"]} ml={4}>
                Attach file
              </Typography>
            </IconButton>
          </Box>
          {/* <Typography
            style={{
              fontSize: 12,
              color: theme.palette.error.dark,
              visibility: errorMessage ? "visible" : "hidden",
              height: 12,
            }}
          >
            {errorMessage}
          </Typography> */}
        </>
      )}
    </Box>
  );
};

export default DocumentUploader;
