import { Typography, Box } from "@mui/material";

import DispatchOrderTable from "./DisptachOrderTable";

/**
 * @description Dispatch order tab
 */
const DispatchOrder = () => {
  return (
    <Box p={4}>
      <Typography variant="h4">Dispatch order</Typography>
      <DispatchOrderTable />
    </Box>
  );
};

export default DispatchOrder;
