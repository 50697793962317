import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TablePagination,
  useTheme,
} from "@mui/material";
import moment from "moment";

import { Loader, PaginationAction, Timestamp, ButtonV1 } from "components";
import BankDetailTip from "../components/BankDetailTip";
import { IncomingPayment } from "../constants";
import { appendRupeeIcon } from "utils";

const { TAGGED_PAYMENT_HEADING, PAYMENT_ACTION } = IncomingPayment;

const PaymentTable = ({
  isLoading,
  data = [],
  rowsPerPage,
  pageNumber = 0,
  totalCount,
  onPageChange,
  onVerify,
  updatePayments,
}) => {
  const theme = useTheme();

  const checkDateTimeDifference = (creationDate) => {
    var now = moment(new Date());
    var end = moment(creationDate + "Z");
    var duration = moment.duration(now.diff(end));
    var hours = duration.asHours();
    return hours;
  };
  return (
    <Paper key={"taggedPayment"}>
      <TableContainer
        sx={{
          minWidth: 700,
          maxHeight: "calc(100vh - 120px)",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {TAGGED_PAYMENT_HEADING.map((heading, index) => (
                <TableCell key={`${heading}${index}`}>{heading}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={TAGGED_PAYMENT_HEADING.length}>
                  <Loader sx={{ marginTop: 0 }} />
                </TableCell>
              </TableRow>
            )}
            {!isLoading && !data.length && (
              <TableRow>
                <TableCell colSpan={TAGGED_PAYMENT_HEADING.length}>
                  <Typography textAlign={"center"}>No Payment Found</Typography>
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              !!data.length &&
              data.map((row, index) => (
                <TableRow
                  key={row.transactionReference + index}
                  style={{ cursor: "default" }}
                >
                  <TableCell>
                    <Timestamp timeStamp={row.whenCreated} />
                  </TableCell>
                  <TableCell>{row?.notes?.paymentMethod}</TableCell>
                  <TableCell>
                    {appendRupeeIcon(row.money.displayAmount ?? "0")}
                  </TableCell>
                  <TableCell>
                    {row?.notes?.razorPayId ?? row.transactionReference}
                  </TableCell>
                  <TableCell>{row?.notes?.status}</TableCell>
                  <TableCell>{row?.notes?.buyerGstin}</TableCell>
                  <TableCell>
                    <BankDetailTip
                      bankInfo={row?.notes}
                      vanEmail={row?.accountReference}
                    />
                  </TableCell>
                  <TableCell style={{ maxWidth: 220 }}>
                    {row?.notes?.buyerCompanyName ?? "-"}
                  </TableCell>
                  <TableCell>
                    {row?.notes?.paymentAction === PAYMENT_ACTION.VERIFIED ? (
                      <Typography
                        color={theme.palette.text.disabled}
                        fontWeight={600}
                        textAlign="center"
                      >
                        Verified
                      </Typography>
                    ) : (
                      <ButtonV1
                        variant="outlined"
                        title={"Verify"}
                        style={{
                          color:
                            checkDateTimeDifference(row.whenCreated) > 24
                              ? theme.palette.error.dark
                              : "primary",
                          borderColor:
                            checkDateTimeDifference(row.whenCreated) > 24
                              ? theme.palette.error.dark
                              : "primary",
                        }}
                        onClick={() => onVerify(row)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!!data.length && (
        <TablePagination
          rowsPerPageOptions={[rowsPerPage]}
          colSpan={TAGGED_PAYMENT_HEADING.length}
          rowsPerPage={rowsPerPage}
          page={pageNumber}
          count={totalCount}
          onPageChange={onPageChange}
          sx={{ display: "flex", flexDirection: "column-reverse" }}
          ActionsComponent={PaginationAction}
        />
      )}
    </Paper>
  );
};

export default PaymentTable;
