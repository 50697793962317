import { useDispatch } from "react-redux";

import { ButtonV1 } from "components";
import { toggleApprovalFlow } from "dataStore/approvalPayoutUploadFlow";
/**
 *
 * @description onclick toggle payout upload flow
 */
const UploadRedirectCTA = () => {
  const dispatch = useDispatch();
  /**
   * @description change layout for different flow
   * dispatch action
   */
  const handleFlow = () => {
    dispatch(toggleApprovalFlow(true));
  };

  return (
    <ButtonV1
      variant="outlined"
      color="secondary"
      title="Upload ICICI payout sheet"
      size="medium"
      onClick={handleFlow}
    />
  );
};

export default UploadRedirectCTA;
