import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ButtonV1, Loader, StatusChip, SvgIcon } from "components";
import ApproveModal from "./ApproveModal";
import HoverBlock from "./HoverBlock";
import { useFetchData, useToaster } from "hooks";
import { moneyFormat } from "utils";
import { postCall_v2 } from "services";
import { DO_SHORTCLOSE_HEADER, DO_STATUS } from "../constant";
import { STATUS_CHIP, success, error } from "constants";

const DoShortClose = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const navigation = useNavigate();
  const triggerToaster = useToaster();

  const navigateToOrderDetail = (customerOrderNumber) => {
    if (customerOrderNumber) {
      navigation(`/order-list/${customerOrderNumber}?tab=orderDetails`);
    }
  };

  const handleModal = (data) => {
    setModalData(data);
    setModalOpen(!isModalOpen);
  };

  const {
    refetch: fetchRequestData,
    isFetching: isRequestFetching,
    data: requestData,
  } = useFetchData(
    "do-request-data",
    "/oms/plant-supply/do-updation/requestList?limit=100&offset=0",
  );

  const handleStatusChange = async (status, data) => {
    const requestData = data ? data : modalData;
    const payload = {
      psIdentifier: requestData?.psIdentifier,
      lineItemId: requestData?.lineItemId,
      orderNumber: requestData?.orderNumber,
      status,
    };
    try {
      const res = await postCall_v2(
        `/oms/plant-supply/do/short-close/change-status`,
        payload,
      );
      if (res) {
        triggerToaster(res?.data ?? "Status changes successfully", success);
        fetchRequestData();
        setModalOpen(false);
      }
    } catch (err) {
      triggerToaster(
        err?.data?.title || `Error occurred, please try again`,
        error,
      );
    }
  };

  useEffect(() => {
    fetchRequestData();
  }, []);

  return (
    <Box>
      <>
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {DO_SHORTCLOSE_HEADER.map((ele) => (
                  <TableCell>{ele}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isRequestFetching ? (
                <TableRow>
                  <TableCell colspan={DO_SHORTCLOSE_HEADER.length}>
                    <Loader sx={{ marginTop: 0 }} />
                  </TableCell>
                </TableRow>
              ) : !!requestData?.data?.length ? (
                requestData?.data?.map((request, index) => {
                  const lineItemKey = index;
                  return (
                    <TableRow key={lineItemKey}>
                      <TableCell width={100}>{request?.psIdentifier}</TableCell>
                      <TableCell width={100}>
                        <ButtonV1
                          textStyle={{ fontSize: 14 }}
                          title={request.orderNumber}
                          variant="text"
                          size="small"
                          onClick={() =>
                            navigateToOrderDetail(request.orderNumber)
                          }
                        />
                      </TableCell>
                      <TableCell>{request?.productInfo?.name}</TableCell>
                      <TableCell>{request?.releasedSkuQuantity}</TableCell>
                      <TableCell>{request?.dispatchedSkuQuantity}</TableCell>
                      <TableCell>
                        {request?.releasedSkuQuantity -
                          request?.dispatchedSkuQuantity}
                      </TableCell>
                      <TableCell>
                        {moneyFormat(request?.residualValue)}
                      </TableCell>
                      <TableCell>
                        {moneyFormat(request?.releasedValue)}
                      </TableCell>
                      <TableCell>
                        <Box style={{ textAlign: "center" }}>
                          {request?.remarks ? (
                            <HoverBlock
                              LabelChildren={
                                <IconButton>
                                  <SvgIcon
                                    name={"chat-unread"}
                                    width={24}
                                    height={24}
                                  />
                                </IconButton>
                              }
                              data={{
                                Remarks: request?.remarks,
                              }}
                            />
                          ) : (
                            <IconButton>
                              <SvgIcon
                                name={"chat-read"}
                                width={24}
                                height={24}
                              />
                            </IconButton>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {request?.status === "Requested" ? (
                          <Box display={"flex"} gap={2}>
                            <ButtonV1
                              size="small"
                              title={"Approve"}
                              onClick={() => handleModal(request)}
                            />
                            <ButtonV1
                              size="small"
                              title={"Reject"}
                              variant="outlined"
                              onClick={() =>
                                handleStatusChange(DO_STATUS.rejected, request)
                              }
                            />
                          </Box>
                        ) : (
                          <>
                            <StatusChip
                              label={
                                request?.status === DO_STATUS.approved
                                  ? "Approved"
                                  : "Rejected"
                              }
                              type={
                                request?.status === DO_STATUS.approved
                                  ? STATUS_CHIP.success
                                  : STATUS_CHIP.error
                              }
                            />
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colspan={DO_SHORTCLOSE_HEADER.length}>
                    <Typography textAlign={"center"}>No data found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
      <>
        <ApproveModal
          open={isModalOpen}
          handleModal={handleModal}
          modalData={modalData}
          handleStatusChange={handleStatusChange}
        />
      </>
    </Box>
  );
};

export default DoShortClose;
