import { useMemo, memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme, Avatar } from "@mui/material";
import PropTypes from "prop-types";

import { TextHead, FlexBox, SummaryModal } from "./dashboard.style";
import CountLabel from "./CountLabel";
import { tags, scheduleList, summaryTag } from "./constant";

const SummaryBox = ({ heading, info, tag }) => {
  const theme = useTheme();
  const navigation = useNavigate();
  const { search } = useLocation();

  const Icon = useMemo(() => tags[tag].icon, [tag]);

  const handleNavigation = (tag, label) => {
    label || tag
      ? navigation(
          `/order-list${search ? `${search}&` : "?"}filterType=${
            tag === summaryTag.openOrder ? "openOrders" : tag
          }&filterValue=${label}`,
        )
      : navigation("/order-list");
  };

  const getCounts = (info, label) => {
    if (info?.isFetching) {
      return null;
    }
    return info?.[label] ?? 0;
  };

  if (!info) return <></>;

  return (
    <SummaryModal>
      <FlexBox>
        <Avatar
          size="small"
          sx={{ color: theme.palette.text.primary }}
          bg={theme.palette.grey[100]}
        >
          <Icon />
        </Avatar>
        <TextHead>{heading}</TextHead>
      </FlexBox>
      <FlexBox sx={{ gap: theme.spacing(6) }}>
        {scheduleList.map((label, index) => (
          <CountLabel
            index={index}
            label={label}
            count={getCounts(info, label)}
            key={`${tags[tag].label}${index}`}
            onClick={() => handleNavigation(tag, label)}
          />
        ))}
      </FlexBox>
      <Icon
        sx={{
          position: "absolute",
          width: 120,
          height: 120,
          zIndex: 0,
          right: 0,
          color: theme.palette.grey[100],
        }}
      />
    </SummaryModal>
  );
};

SummaryBox.propTypes = {
  info: PropTypes.object.isRequired,
  tag: PropTypes.string,
};

export default memo(SummaryBox);
