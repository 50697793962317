import { useMemo } from "react";
import { Box } from "@mui/material";

import { Filters, TransactionFilter } from "../components";
import RefundAndCashback from "./RefundAndCashback";
import InvoiceSellerPayout from "./InvoiceSellerPayout";
import CreditNotesAndSellerReturn from "./CreditNotesAndSellerReturn";
import InternalFundTransfer from "./InternalFundTransfer";
import OrderBlock from "./OrderBlock";
import useFetchParam from "../useFetchParam";

import { formatFiltersData } from "../makeData";
import { FinancePayouts } from "../constants";

const {
  transactionFilter: {
    INVOICE_SELLER_PAYOUTS,
    REFUND_CASHBACK,
    CREDIT_NOTE_SALES_RETURN,
    INTERNAL_FUND_TRANSFER,
    ORDER_BLOCK,
  },
  queryFilters,
  financeTab,
} = FinancePayouts;

const UploadPayoutsTab = () => {
  const { fetchParams, updateParams } = useFetchParam();

  const type = fetchParams?.[queryFilters.transactionType];
  const transactionType = useMemo(
    () => FinancePayouts.transactionFilter[type],
    [type],
  );

  /**
   * @description invoked on submitting filters
   */
  const onSubmit = (filters) => {
    const formattedFiltersData = formatFiltersData(
      filters,
      financeTab.upload,
      transactionType,
    );
    updateParams({ param: formattedFiltersData });
  };

  /**
   * @description fetch pending payouts on page change
   */

  const onReset = () => {
    const param = {
      [queryFilters.tab]: financeTab.upload,
      [queryFilters.transactionType]:
        fetchParams?.[queryFilters.transactionType],
    };

    updateParams({ param, appendWithExisting: false });
  };

  const filterParams = useMemo(() => {
    const {
      [queryFilters.tab]: tab,
      [queryFilters.transactionType]: type,
      ...filter
    } = fetchParams;

    return filter;
  }, [fetchParams]);

  const filterView = () => {
    return (
      <Box mt={4}>
        <Filters
          hideDateRange={true}
          onSubmit={onSubmit}
          onReset={onReset}
          financeTab={financeTab.upload}
          transactionType={transactionType}
        />
      </Box>
    );
  };

  const getUploadPayoutView = () => {
    switch (transactionType) {
      case REFUND_CASHBACK:
        return (
          <RefundAndCashback
            transactionType={transactionType}
            filters={filterParams}
          >
            {filterView()}
          </RefundAndCashback>
        );
      case INVOICE_SELLER_PAYOUTS:
        return (
          <InvoiceSellerPayout
            transactionType={transactionType}
            filters={filterParams}
          >
            {filterView()}
          </InvoiceSellerPayout>
        );
      case CREDIT_NOTE_SALES_RETURN:
        return (
          <CreditNotesAndSellerReturn
            transactionType={transactionType}
            filters={filterParams}
          >
            {filterView()}
          </CreditNotesAndSellerReturn>
        );
      case INTERNAL_FUND_TRANSFER:
        return (
          <InternalFundTransfer filters={filterParams}>
            {filterView()}
          </InternalFundTransfer>
        );
      case ORDER_BLOCK:
        return <OrderBlock />;
      default:
        return "";
    }
  };

  return (
    <>
      <TransactionFilter />
      {getUploadPayoutView()}
    </>
  );
};

export default UploadPayoutsTab;
