import { useCallback, useEffect, useState, useRef } from "react";
import { Box } from "@mui/material";

import { ButtonModule, ModalBox } from "components";
import { PayoutDisbursement } from "pages/FinancePayout/components";
import { useToaster } from "hooks";

import { FinancePayouts } from "pages/FinancePayout/constants";
import { error } from "constants";

const MarkSuccessPrompt = ({
  actionType,
  onClosePayoutDisbursement,
  disbursementData,
  onInitiatePayout,
  loading,
}) => {
  const [paymentChecked, setPaymentChecked] = useState([]);
  const [finalDisbursement, setFinalDisbursement] = useState(disbursementData);
  const promptRef = useRef(null);
  const triggerToaster = useToaster();

  const handlePayoutAction = useCallback(() => {
    const payoutTx = Object.values(finalDisbursement);
    const txUTR = promptRef.current.fetchUTR();
    for (let i = 0; i < payoutTx.length; i++) {
      if (!txUTR[`utr_${payoutTx[i].payoutId}`]) {
        //show toaster
        triggerToaster("Please add UTR to mark transaction success", error);
        return;
      }
      payoutTx[i].utr = txUTR[`utr_${payoutTx[i].payoutId}`];
    }

    const txInitate = paymentChecked.map((_, index) => {
      const tx = payoutTx[index];
      const payload = {
        utr: tx?.utr,
        id: tx?.payoutId,
        ...(tx?.selectedFundAccountId && {
          fundAccountId: tx?.selectedFundAccountId,
        }),
      };

      return payload;
    });

    onInitiatePayout(txInitate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalDisbursement, onInitiatePayout, paymentChecked]);

  const handleRadioButton = useCallback(
    (e, i) => {
      const payoutId = Object?.keys(finalDisbursement)?.at(i);
      const disbursementData = Object.values(finalDisbursement).at(i);
      setFinalDisbursement((prev) => ({
        ...prev,
        [payoutId]: {
          ...disbursementData,
          selectedFundAccountId: e.target.value,
        },
      }));
    },
    [finalDisbursement],
  );

  useEffect(() => {
    setFinalDisbursement(disbursementData);
    const checkAllPayout = Object.keys(disbursementData).map(() => true);
    setPaymentChecked(checkAllPayout);
  }, [disbursementData]);

  return (
    <ModalBox
      open={!!actionType}
      onCloseModal={onClosePayoutDisbursement}
      width="80%"
    >
      <>
        <PayoutDisbursement
          data={Object?.values(finalDisbursement)}
          handleRadioButton={handleRadioButton}
          title="Are you sure you want to mark these payments as successful ?"
          type={FinancePayouts.MARK_SUCCESS}
          ref={promptRef}
        />
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ButtonModule
            variant="contained"
            color="secondary"
            title="Mark success"
            size="medium"
            loading={loading}
            onClick={handlePayoutAction}
          />
        </Box>
      </>
    </ModalBox>
  );
};

export default MarkSuccessPrompt;
