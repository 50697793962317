import { memo } from "react";
import { Typography, Box, Skeleton } from "@mui/material";
import { LineSeperator, SubText, FlexBox } from "./dashboard.style";
import { useTheme } from "@mui/material";
import { labelColorCode } from "./constant";
import { displayLabel } from "constants";

const CountLabel = ({ index, label, count, isOrder, onClick }) => {
  const theme = useTheme();
  return (
    <Box onClick={onClick} sx={{ cursor: "pointer" }}>
      <FlexBox
        sx={{
          gap: theme.spacing(3),
          "&:hover": {
            backgroundColor: theme.palette.grey["A100"],
            borderRadius: "0 8px 8px 0",
            transform: "translateX(2px)",
          },
        }}
      >
        <LineSeperator
          orientation="vertical"
          flexItem
          sx={{
            borderColor: labelColorCode[index],
          }}
        />
        {!count && count !== 0 ? (
          <Skeleton variant="text" sx={{ fontSize: 24 }} />
        ) : (
          <Typography variant="h3" m={0}>
            {count}
          </Typography>
        )}
        {isOrder && (
          <Typography variant="body1" paddingTop={theme.spacing(2.5)}>
            {displayLabel[label]}
          </Typography>
        )}
      </FlexBox>
      {!isOrder && label && (
        <SubText variant="body1">{displayLabel[label]}</SubText>
      )}
    </Box>
  );
};

export default memo(CountLabel);
