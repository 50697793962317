import { Box } from "@mui/material";
import { ButtonV1, ModalBox } from "components";
import { PayoutDisbursement } from "pages/FinancePayout/components";
import { useCallback, useEffect, useState } from "react";

import { FinancePayouts } from "../../constants";
import { getValidPayoutId } from "pages/FinancePayout/makeData";

const PayoutPrompt = ({
  actionType,
  onClosePayoutDisbursement,
  disbursementData,
  onInitiatePayout,
  onDownloadClick,
  isFundIdsViewHidden,
  isRefund = false,
}) => {
  const [paymentChecked, setPaymentChecked] = useState([]);
  const [finalDisbursement, setFinalDisbursement] = useState(disbursementData);

  const handlePayoutAction = useCallback(() => {
    const payoutTx = Object.values(finalDisbursement);

    const txInitate = paymentChecked.map((_, index) => {
      const tx = payoutTx[index];
      const id = getValidPayoutId(tx?.payoutId);
      const payload = {
        isPayoutRequired: true,
        id,
        payoutType: tx?.payoutType,
        fundAccountId: tx?.selectedFundAccountId
          ? tx?.selectedFundAccountId
          : tx?.fundAccountDetails?.customerAccount,
      };

      return payload;
    });

    onInitiatePayout(txInitate);
  }, [finalDisbursement, onInitiatePayout, paymentChecked]);

  const handleRadioButton = useCallback(
    (e, i) => {
      const payoutId = Object.keys(finalDisbursement).at(i);
      const disbursementData = Object.values(finalDisbursement).at(i);
      setFinalDisbursement((prev) => ({
        ...prev,
        [payoutId]: {
          ...disbursementData,
          selectedFundAccountId: e.target.value,
        },
      }));
    },
    [finalDisbursement],
  );

  useEffect(() => {
    setFinalDisbursement(disbursementData);
    const checkAllPayout = Object.keys(disbursementData).map(() => true);
    setPaymentChecked(checkAllPayout);
  }, [disbursementData]);

  const getPayoutTitle = () => {
    switch (actionType) {
      case FinancePayouts.APPROVAL_ACTION_TYPE.payout: {
        return "Do you want to confirm these transactions ?";
      }
      case FinancePayouts.APPROVAL_ACTION_TYPE.hold: {
        return "Do you want to hold these transactions ?";
      }
      case FinancePayouts.APPROVAL_ACTION_TYPE.markSuccess: {
        return "Are you sure you want to mark these payments as successful ?";
      }
      default: {
        return " Do you want to confirm these transactions ?";
      }
    }
  };

  return (
    <ModalBox
      open={!!actionType}
      onCloseModal={onClosePayoutDisbursement}
      width="70%"
    >
      <>
        <PayoutDisbursement
          data={Object.values(finalDisbursement)}
          handleRadioButton={handleRadioButton}
          title={getPayoutTitle()}
          isFundIdsViewHidden={isFundIdsViewHidden}
          isRefund={isRefund}
        />
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
          {isRefund ||
          FinancePayouts.APPROVAL_ACTION_TYPE.hold === actionType ? (
            <ButtonV1
              variant="contained"
              color="secondary"
              title="Confirm"
              size="medium"
              onClick={handlePayoutAction}
            />
          ) : (
            <></>
          )}
          <ButtonV1
            variant="contained"
            color="secondary"
            title="Download transactions"
            size="medium"
            onClick={() =>
              onDownloadClick(Object.values(finalDisbursement), true)
            }
          />
        </Box>
      </>
    </ModalBox>
  );
};

export default PayoutPrompt;
