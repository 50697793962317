import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";

export const ColumnView = ({ label, value, color, labelStyle }) => {
  return (
    <>
      <Grid item>
        <Box display={"flex"} flexDirection="column">
          <Typography style={labelStyle}>{label}</Typography>
          <Typography fontWeight={600} color={color}>
            {value}
          </Typography>
        </Box>
      </Grid>
    </>
  );
};

ColumnView.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  color: PropTypes.string,
  labelStyle: PropTypes.object,
};
