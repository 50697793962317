import { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography, TableCell, Skeleton } from "@mui/material";
import { styled } from "@mui/system";
import moment from "moment";
import { BorderColor, Save } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";

import GridSkeleton from "./GridSkelton";
import {
  ModalBox,
  ViewOnly,
  TableWrapper,
  InputField,
  ButtonV1,
  Loader,
  DialogBox,
  DropdownV1,
  DatePickerV1,
} from "components";
import { StyledTableRow, ColumnTitle } from "CustomStyledComponents";
import { getCall_v2, postCall, postCall_v2 } from "services";
import { useFetchData, useToaster, useCategoryTypeCheck } from "hooks";

import theme from "themeProvider";
import {
  getFixedValue,
  appendRupeeIcon,
  timeConvertor,
  dateConvertor,
  moneyFormat,
  validateNull,
} from "utils";
import { success, error, categoryType, paymentTypes } from "constants";
import {
  recordedTransactionHeaders,
  paymentTypeList,
  successMessage,
  cancelled,
  declined,
  paymentType,
} from "./constants";

const SectionTitle = (props) => {
  return (
    <StyledBox>
      <Typography variant="h3">{props.title}</Typography>
    </StyledBox>
  );
};
const StyledBox = styled(Box)`
  && {
    padding-left: 0.2rem;
    margin-top: 0.7rem;
  }
`;

const ButtonBox = styled(Box)`
  display: flex;
  margin-top: 1rem;
`;

const Payment = ({ getTotalInvoiceAmount }) => {
  const [isModalOpen, setOpenModal] = useState(false);
  const [transactions, setTransactions] = useState({ recordedTransaction: [] });
  const orderDetails = useSelector((state) => state.orderDetails);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [customerGSTIN, setCustomerGSTIN] = useState();
  const [isLedgerBalanceTriggered, setIsLedgerBalanceTriggered] = useState();
  const [customerBalance, setCustomerBalance] = useState();
  const [dueDate, setDueDate] = useState({});
  const [edit, setEdit] = useState([]);
  const [creditDuration, setCreditDuration] = useState();
  const navigation = useNavigate();
  const triggerToaster = useToaster();
  const { isDirect } = useCategoryTypeCheck();

  const {
    getValues,
    control,
    formState: { isDirty },
  } = useForm({
    mode: "onTouched",
  });

  const { id } = useParams();

  const isCreditOrder = useMemo(
    () => orderDetails.orderPaymentType === "Credit",
    [orderDetails],
  );

  useEffect(() => {
    fetchTransaction();
    if (isDirect) {
      refetchAdvanceUtilised();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSuccess = (res) => {
    setTransactions(res.data);
    const { customerGstin, totalInvoiceAmount } = res?.data;
    setCustomerGSTIN(customerGstin);
    getCustomerBalance(customerGstin);
    getTotalInvoiceAmount(totalInvoiceAmount);
  };

  const getCustomerBalance = async (gstin) => {
    try {
      if (gstin) {
        setIsLedgerBalanceTriggered(true);
        const { data } = await getCall_v2(`/oms/payment/balance/${gstin}`);
        setCustomerBalance(data?.balance);
        setIsLedgerBalanceTriggered(false);
      }
    } catch (error) {
      console.error("getCustomerBalance is throwing error :: ", error);
    }
  };

  const { data: advanceUtilised, refetch: refetchAdvanceUtilised } =
    useFetchData(
      "advance-utilised",
      `/oms/payment/advanceUtilisation?orderNumber=${id}`,
    );

  const onError = (error) => {
    console.log("error", error);
  };

  const { isLoading, refetch: fetchTransaction } = useFetchData(
    "recorded-transaction",
    `/oms/payment/recordedTransaction?customerOrderNumber=${id}`,
    onSuccess,
    onError,
  );

  const onSendRequest = async () => {
    const requestPaymentBody = {
      orderNumber: id,
      paymentType: getValues("paymentType"),
      amount: getValues("amount"),
      orderType: orderDetails?.category,
    };

    const requestPaymentRes = await postCall(
      `/oms/payment/requestPayment`,
      requestPaymentBody,
      {},
      () => {
        triggerToaster(`Payment creation failed. Pls try again`, error);
      },
    );
    setOpenModal(false);
    if (requestPaymentRes) {
      fetchTransaction();
      triggerToaster(successMessage, success);
    }
  };

  const handleCheckBanlance = useCallback(() => {
    const encode = btoa(customerGSTIN);
    customerGSTIN && navigation(`/ledger?buyer=${encode}&tab=buyer`);
  }, [customerGSTIN, navigation]);

  const handleExitPage = () => {
    setIsConfirmModalOpen(false);
    setOpenModal(false);
  };

  const handleCloseModal = () => {
    if (isDirty) {
      setIsConfirmModalOpen(true);
    } else {
      setOpenModal(false);
    }
  };

  const CustomViewOnly = ({ label, value }) => {
    return (
      <ViewOnly
        valueStyle={{ width: "45%" }}
        labelStyle={{ width: "45%" }}
        label={label}
        value={value}
      />
    );
  };

  const { recordedTransaction = [], totalInvoiceAmount } = transactions;
  const orderSummary = orderDetails ? orderDetails.formattedAmounts : {};

  const getCreditDuration = useCallback(async () => {
    try {
      const endpoint = `oms/credit/details?orderNumber=${id}`;
      const data = await getCall_v2(endpoint);

      setCreditDuration(data.data?.creditDueDay ?? 0);
    } catch (err) {
      console.error("getCreditDuration api failed :: ", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleEdit = useCallback(
    (i) => {
      !creditDuration && getCreditDuration();
      setEdit((prev) => [...prev, i]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [creditDuration],
  );

  const handleSaveDueDate = useCallback(
    async (data, index) => {
      const date =
        dueDate[index] && moment(dueDate[index]).format("YYYY-MM-DD");
      const { ctId, invoiceDueDate } = data;

      setEdit((prev) => prev.filter((item) => item !== index));

      try {
        if (!date) {
          return;
        }

        if (moment(invoiceDueDate).add(creditDuration, "days").isBefore(date)) {
          triggerToaster(
            "Date should be less than invoice date + credit period",
            error,
          );
          setEdit((prev) => [...prev, index]);
          return;
        }

        await postCall_v2(
          `/oms/credit/updatePaymentDueDate?ctId=${ctId}&paymentDueDate=${date}`,
        );

        setDueDate((prev) => {
          const { [index]: _remove, ...rest } = prev;
          return rest;
        });
        fetchTransaction();
        triggerToaster("Update due date successfully", success);
      } catch (error) {
        setEdit((prev) => [...prev, index]);
        triggerToaster("Unable to update due date", error);
        console.warn("Unable to get credit period", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dueDate],
  );

  const handleDueDate = useCallback((date, index) => {
    setDueDate((prev) => ({ ...prev, [index]: date }));
  }, []);

  /**
   * @description calculate advance percentage content once
   */
  const showAdvancePercentage = useMemo(() => {
    const percent = orderDetails?.advancePercentage;
    if (
      orderDetails?.paymentType?.trim() === paymentType.CASH_AND_CARRY.trim()
    ) {
      if (percent) {
        return `(${percent}% advance in payments)`;
      }
      return "";
    } else {
      return `(${percent}% advance in payments)`;
    }
  }, [orderDetails]);

  if (isLoading) return <Loader />;

  return (
    <Box>
      {/* {!isCreditOrder && (
        <ButtonBox style={{ justifyContent: "center" }}>
          <ButtonV1
            title="Request payment"
            onClick={() => setOpenModal(true)}
            variant="text"
            disabled={
              orderDetails?.orderStatus === cancelled ||
              orderDetails?.orderStatus === declined
            }
            color="secondary"
          />
        </ButtonBox>
      )} */}
      <SectionTitle title="Payment summary" />
      <Box mb={10}>
        <GridSkeleton
          firstChild={
            <CustomViewOnly
              label="Total order amount"
              value={`${
                orderSummary && orderSummary["Total order amount"]
              } ${showAdvancePercentage}`}
            />
          }
          secondChild={
            <CustomViewOnly
              label="Invoice amount"
              value={
                totalInvoiceAmount &&
                appendRupeeIcon(totalInvoiceAmount.displayAmount)
              }
            />
          }
        />
        <GridSkeleton
          firstChild={
            <CustomViewOnly
              label="Paid amount"
              value={orderSummary && orderSummary["Total paid amount"]}
            />
          }
          secondChild={
            <CustomViewOnly
              label="Pending amount"
              value={orderSummary && orderSummary["Pending amount"]}
            />
          }
        />
        <GridSkeleton
          firstChild={
            <CustomViewOnly
              label="Excess amount"
              value={orderSummary && orderSummary["Positive tolerance amount"]}
            />
          }
          secondChild={
            <CustomViewOnly
              label="Customer balance"
              value={
                orderSummary && (
                  <Box
                    display={"flex"}
                    sx={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {!isLedgerBalanceTriggered ? (
                      moneyFormat(customerBalance)
                    ) : (
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: 16, width: 100 }}
                      />
                    )}
                    <ButtonV1
                      title="Ledger"
                      size="small"
                      variant="text"
                      onClick={handleCheckBanlance}
                    />
                  </Box>
                )
              }
            />
          }
        />
        {orderDetails?.category === categoryType?.DIRECT && (
          <GridSkeleton
            firstChild={
              <CustomViewOnly
                label="Advance utilised"
                value={appendRupeeIcon(advanceUtilised?.data?.amount || "0")}
              />
            }
          />
        )}
      </Box>
      <Box>
        <Typography mb={2} variant="h6" color={theme.palette.info.dark}>
          Recorded transaction
        </Typography>
        {recordedTransaction.length ? (
          <TableWrapper
            tableHeader={recordedTransactionHeaders(isCreditOrder).map(
              (item) => {
                return (
                  <TableCell key={item}>
                    <ColumnTitle>{item}</ColumnTitle>
                  </TableCell>
                );
              },
            )}
            tableBody={(rowsPerPage > 0
              ? recordedTransaction.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage,
                )
              : recordedTransaction
            ).map((row, i) => (
              <StyledTableRow key={row.timeStamp} id={row.timeStamp}>
                <TableCell>
                  {`${dateConvertor(row.timeStamp)} ${timeConvertor(
                    row.timeStamp,
                  )} `}
                </TableCell>
                <TableCell>
                  {validateNull(
                    row?.transactionDate &&
                      `${dateConvertor(row?.transactionDate)} ${timeConvertor(
                        row?.transactionDate,
                      )} `,
                  )}
                </TableCell>
                <TableCell>{paymentTypes[row.paymentType]}</TableCell>
                <TableCell>{validateNull(row.transactionType)}</TableCell>
                <TableCell>
                  {appendRupeeIcon(row.amount.displayAmount)}
                </TableCell>
                <TableCell>{validateNull(row.transactionId)}</TableCell>
                {isCreditOrder && (
                  <TableCell>
                    {!edit.includes(i) && !dueDate[i] ? (
                      dateConvertor(row?.paymentDueDate)
                    ) : (
                      <DatePickerV1
                        value={
                          dueDate?.[i] ??
                          moment(row?.paymentDueDate, "yyyy-MM-DD")
                        }
                        maxDate={
                          new Date(
                            moment(row?.invoiceDueDate)
                              .add(creditDuration ?? 0, "days")
                              .format("yyyy-MM-DD"),
                          )
                        }
                        onChange={(e) => handleDueDate(e, i)}
                        dateStyle={{ width: 180 }}
                      />
                    )}
                  </TableCell>
                )}
                <TableCell>{row.transactionStatus}</TableCell>
                {isCreditOrder && (
                  <TableCell>
                    {!!dueDate[i] && !edit.includes(i) ? (
                      <Loader sx={{ marginTop: 0 }} size={22} />
                    ) : (
                      <>
                        {edit.includes(i) ? (
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            gap={1}
                            onClick={() => handleSaveDueDate(row, i)}
                          >
                            <Save
                              sx={{
                                fill: theme.palette.primary.main,
                                width: 16,
                                height: 16,
                              }}
                            />
                            <Typography color={"primary"} fontWeight={600}>
                              Save
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            gap={1}
                            onClick={() => !row.transactionId && handleEdit(i)}
                          >
                            <BorderColor
                              sx={{
                                fill: row.transactionId
                                  ? theme.palette.text.disabled
                                  : theme.palette.primary.main,
                                width: 16,
                                height: 16,
                              }}
                            />
                            <Typography
                              color={
                                row.transactionId
                                  ? theme.palette.text.disabled
                                  : "primary"
                              }
                              fontWeight={600}
                            >
                              Edit
                            </Typography>
                          </Box>
                        )}
                      </>
                    )}
                  </TableCell>
                )}
              </StyledTableRow>
            ))}
            page={page}
            rowsPerPage={rowsPerPage}
            rows={recordedTransaction}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        ) : (
          <ButtonBox style={{ justifyContent: "center" }}>
            <Typography variant="p">
              No recorded transaction are available.
            </Typography>
          </ButtonBox>
        )}
      </Box>
      <ModalBox open={isModalOpen} onCloseModal={handleCloseModal} width="40%">
        <Box>
          <Typography variant="h2" mb={5} style={{ textAlign: "center" }}>
            Send payment request
          </Typography>
          <Box mb={5} display={"flex"} alignItems={"center"}>
            <Typography fontWeight={600} sx={{ width: "30%" }}>
              Payment type
            </Typography>
            <Controller
              control={control}
              name="paymentType"
              render={({ field: { value, onChange } }) => (
                <DropdownV1
                  sx={{ width: "30%" }}
                  customStyle={{ minWidth: "32%", maxWidth: "50%" }}
                  placeholder="Payment type"
                  itemList={paymentTypeList}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Box>
          <Controller
            control={control}
            name="amount"
            defaultValue={"0"}
            render={({ field: { value, onChange } }) => (
              <InputField
                label="Amount"
                type="number"
                labelStyle={{ width: "30%" }}
                value={getFixedValue(value)}
                onChange={onChange}
              />
            )}
          />
          <ButtonBox style={{ justifyContent: "center", marginTop: "2rem" }}>
            <ButtonV1
              title="Send request"
              onClick={onSendRequest}
              variant="contained"
            />
          </ButtonBox>
        </Box>
      </ModalBox>
      <DialogBox
        handleClose={() => setIsConfirmModalOpen(false)}
        open={isConfirmModalOpen}
        handleConfirm={handleExitPage}
      />
    </Box>
  );
};

export default Payment;
