import { useState, memo } from "react";
import { Tabs, Tab, Box } from "@mui/material";

import CustomerCart from "./CustomerCart";
import CustomerOrder from "./CustomerOrder";
import theme from "themeProvider";

const TabPanel = ({ children, value, selectedTab, tabName, ...rest }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== selectedTab}
      id={`tabpanel-${value}`}
      aria-labelledby={`tab-${value}`}
      {...rest}
    >
      {value === selectedTab && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
};

const SLO = () => {
  const [tabName, setTabName] = useState("customerCart");
  const changeTabName = (evt, value) => setTabName(value);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: theme.palette.grey[100] }}>
        <Tabs value={tabName} onChange={changeTabName}>
          <Tab label="Customer cart" value="customerCart" />
          <Tab label="Customer order" value="customerOrder" />
        </Tabs>
      </Box>
      <TabPanel value="customerCart" selectedTab={tabName}>
        <CustomerCart />
      </TabPanel>
      <TabPanel value="customerOrder" selectedTab={tabName}>
        <CustomerOrder />
      </TabPanel>
    </>
  );
};

export default memo(SLO);
