import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Tab, Tabs, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import UntaggedPaymentTxs from "./UntaggedPaymentTxs";

import { IncomingPayment } from "../constants";
import { userModule } from "constants";
import { getDateRange } from "utils";

const {
  UNTAGGED_PAYMENT_TABS: { NEW_PAYMENTS, OLD_PAYMENTS },
} = IncomingPayment;

const { startDate, endDate } = getDateRange(7);
const oldDateRange = getDateRange(365, new Date(startDate * 1000));

// const NewPayments = ({ triggerTaggedPaymentApi }) => {
//   return (
//     <UntaggedPaymentTxs
//       minTimestamp={startDate * 1000}
//       maxTimestamp={endDate * 1000}
//       triggerTaggedPaymentApi={triggerTaggedPaymentApi}
//       title="New untagged payments"
//     />
//   );
// };

// const OldPayments = ({ triggerTaggedPaymentApi }) => {
//   return (
//     <UntaggedPaymentTxs
//       minTimestamp={oldDateRange?.startDate * 1000}
//       maxTimestamp={oldDateRange?.endDate * 1000}
//       triggerTaggedPaymentApi={triggerTaggedPaymentApi}
//       title="Old untagged payments"
//     />
// //   );
// };

const TabPanel = ({ children, value, selectedTab, tabName, ...rest }) => {
  return (
    <Box role="tabpanel" hidden={value !== selectedTab} {...rest}>
      {value === selectedTab && <>{children}</>}
    </Box>
  );
};

const UntaggedPayment = ({ triggerTaggedPaymentApi }) => {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabName, setTabName] = useState(NEW_PAYMENTS);

  const userModules = useSelector((state) => state.userData.modules);

  const isNewPaymentsUser = useMemo(
    () => userModules?.some(({ name }) => name === userModule.newPayments),
    [userModules],
  );

  const isOldPaymentsUser = useMemo(
    () => userModules?.some(({ name }) => name === userModule.newPayments),
    [userModules],
  );

  /**
   * @description set query params in url
   * @param {*} tab
   */
  const updateQueryParams = useCallback(
    (tab) => {
      searchParams.set("tab", tab);
      setSearchParams(searchParams);
    },
    //  eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams],
  );

  useEffect(() => {
    updateQueryParams(NEW_PAYMENTS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @param {*} tabType - ledger type
   * @description -  change tab type & set query param
   */
  const handleChange = useCallback((tabName) => {
    updateQueryParams(tabName);
    setTabName(tabName);
  }, []);

  return (
    <Box padding={3}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 5 }}>
        <Tabs
          value={tabName}
          onChange={(_event, newValue) => {
            handleChange(newValue);
          }}
          sx={{
            ">.MuiTabs-scroller": {
              ">.MuiTabs-indicator": {
                backgroundColor: theme.palette.common.black,
              },

              ">.MuiTabs-flexContainer>.Mui-selected": {
                fontWeight: 600,
                color: theme.palette.common.black,
              },
              ">.MuiTabs-flexContainer>.MuiTab-root": {
                fontSize: 18,
              },
            },
          }}
        >
          {isNewPaymentsUser && (
            <Tab label={NEW_PAYMENTS} value={NEW_PAYMENTS} />
          )}
          {isOldPaymentsUser && (
            <Tab label={OLD_PAYMENTS} value={OLD_PAYMENTS} />
          )}
        </Tabs>
      </Box>
      {isNewPaymentsUser && (
        <TabPanel value={NEW_PAYMENTS} selectedTab={tabName}>
          <UntaggedPaymentTxs
            minTimestamp={startDate * 1000}
            maxTimestamp={endDate * 1000}
            triggerTaggedPaymentApi={triggerTaggedPaymentApi}
            title="New untagged payments"
          />
        </TabPanel>
      )}
      {isOldPaymentsUser && (
        <TabPanel value={OLD_PAYMENTS} selectedTab={tabName}>
          <UntaggedPaymentTxs
            minTimestamp={oldDateRange?.startDate * 1000}
            maxTimestamp={oldDateRange?.endDate * 1000}
            triggerTaggedPaymentApi={triggerTaggedPaymentApi}
            title="Old untagged payments"
          />
        </TabPanel>
      )}
    </Box>
  );
};

export default UntaggedPayment;
