import { dateConverter } from "../makeData";
import moment from "moment";
import { INVOICE_SELLER_PAYOUT } from "./constant";

const { documentType: DOCUMENT_TYPE } = INVOICE_SELLER_PAYOUT;

const gstCalculation = (from, to, gstAmount) => {
  if (
    from?.substring(0, 2)?.toLowerCase() === to?.substring(0, 2)?.toLowerCase()
  ) {
    return {
      sgstAmount: gstAmount / 2,
      cgstAmount: gstAmount / 2,
      igstAmount: undefined,
    };
  } else {
    return {
      igstAmount: gstAmount,
      cgstAmount: undefined,
      sgstAmount: undefined,
    };
  }
};
const getGstDetail = (item) => {
  const from = item?.["From account"];
  const to = item?.["To account"];
  const gstAmount = item?.["GST amount"] || 0;
  return gstCalculation(from, to, gstAmount);
};

const numberValidate = (value) => (isNaN(value) ? "" : value);

export const uploadCsvDataMapper = (data = [{}]) => {
  return data?.map((item) => ({
    orderNumber: item?.["Order number"],
    payoutType: item?.["Payout type"],
    fromGstin: item?.["From account"],
    toGstin: item?.["To account"],
    documentId: item?.["Document number"],
    invoiceAmount: numberValidate(item?.["Document amount"]),
    payoutAmount: numberValidate(item?.["Payout amount"]),
    invoiceComments: item?.["Comments"],
    documentType: item?.["Document type"],
    documentDate: dateConverter(item?.["Document date"]),
    creditAmount: numberValidate(item?.["Credit amount"]),
    creditDueDate: dateConverter(item?.["Credit due date"]),
    originalInvoiceId:
      item?.[`Original invoices (";" separated)`]?.split(";") ?? [],
    // jswOneCommission: item?.["JSW One commission"],
    isTcsTdsDetected: item?.["Is TCS/TDS deductions required?"]
      ? item?.["Is TCS/TDS deductions required?"]?.toLowerCase() === "true"
        ? true
        : false
      : true,
    ...getGstDetail(item),
  }));
};

export const resetFieldMapper = () =>
  uploadCsvDataMapper().map((item) =>
    Object.entries(item).reduce((result, [key, value]) => {
      return { ...result, [key]: !!value ? value : "" };
    }, {}),
  );

export const responseDataMapper = ({
  orderNumber,
  fromGstin,
  toGstin,
  invoiceNumber,
  payoutType,
  invoiceAmount,
  invoiceComments,
  payoutAmount,
  documentDate,
  creditMetaInfo,
  originalInvoiceId,
  tcsTdsDeductionReq,
  gstAmount,
  id,
}) => {
  return {
    id,
    orderNumber,
    fromGstin,
    toGstin,
    documentId: invoiceNumber,
    invoiceAmount,
    payoutAmount,
    invoiceComments,
    documentType: payoutType,
    documentDate,
    creditAmount: creditMetaInfo?.creditAmount,
    creditDueDate: creditMetaInfo?.creditDueDate,
    originalInvoiceId: originalInvoiceId,
    // jswOneCommission: item["JSW One commission"],
    isTcsTdsDetected: tcsTdsDeductionReq,
    ...gstCalculation(fromGstin, toGstin, gstAmount),
  };
};

export const requestDataMapper = (data = []) => {
  return data.map(
    ({
      orderNumber,
      invoiceComments,
      fromGstin,
      toGstin,
      documentType,
      documentId,
      invoiceAmount,
      creditAmount,
      creditDueDate,
      igstAmount,
      cgstAmount,
      sgstAmount,
      payoutAmount,
      documentDate,
      isTcsTdsDetected,
      originalInvoiceId,
    }) => ({
      orderNumber,
      invoiceComments,
      fromGstin,
      toGstin,
      payoutType: documentType,
      invoiceNumber: documentId,
      invoiceAmount,
      documentDate: documentDate
        ? moment(documentDate).format("YYYY-MM-DD")
        : null,
      creditMetaInfo: ![
        DOCUMENT_TYPE.bnplInvoice,
        DOCUMENT_TYPE.channelFinance,
      ].includes(documentType)
        ? null
        : {
            creditAmount,
            creditDueDate: creditDueDate
              ? moment(creditDueDate, "DD/MM/YYYY").format("YYYY-MM-DD")
              : null,
          },
      gstAmount: (+igstAmount || 0) + (+cgstAmount || 0) + (+sgstAmount || 0),
      payoutAmount,
      tcsTdsDeductionReq: isTcsTdsDetected,
      originalInvoiceId: originalInvoiceId,
    }),
  );
};
