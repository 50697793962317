import {
  useImperativeHandle,
  useState,
  forwardRef,
  useCallback,
  useEffect,
} from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TablePagination,
  Typography,
  TextField,
  IconButton,
  Paper,
  useTheme,
} from "@mui/material";
import { FileDownloadOutlined, InfoOutlined } from "@mui/icons-material";

import {
  ButtonV1,
  PaginationAction,
  ModalBox,
  Loader,
  SvgIcon,
  HyperLink,
  TableRowWithError,
} from "components";
import { StatusChip, HoverBlock } from "../components";
import {
  DocumentPriceBreakup,
  IconTooltip,
  PriceBreakup,
} from "pages/FinancePayout/components";

import { postCall_v2 } from "services";
import { dateConvertor, moneyFormat } from "utils";
import { useToaster, useDownloadInvoiceFile, useDownload } from "hooks";

import { success, error } from "constants";
import { FinancePayouts } from "pages/FinancePayout/constants";

const {
  PAYMENT_IN_PROGRESS,
  PAYMENT_FAILED,
  msgStatements: { ERROR_MSG },
  INVOICE_PENDING_HEADERS,
  ORDER_BOOK,
  ERP,
} = FinancePayouts;

const invoiceType = "INVOICE";

const PayoutTable = forwardRef(
  (
    {
      pendingTransactions = {},
      rowsPerPage,
      pageNumber,
      onPageChange,
      passSelectedData,
      getPendingPayouts,
      loading,
      selectedPayout,
    },
    ref,
  ) => {
    const [isAllChecked, setAllChecked] = useState(false);
    const [isButtonLoading, setButtonLoading] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [rejectData, setRejectData] = useState();
    const [showErrorList, setShowErrorList] = useState([]);

    const { triggerDownloadInvoice } = useDownloadInvoiceFile();
    const { downloadDocument } = useDownload();

    const showToaster = useToaster();
    const theme = useTheme();

    const {
      control,
      reset,
      getValues,
      formState: { isDirty },
    } = useForm({
      mode: "onTouched",
      defaultValues: { reason: "" },
    });

    const onCloseModal = () => {
      reset();
      setRejectData("");
      setModalOpen(false);
    };

    const validateRejection = (data) => {
      setModalOpen(true);
      setRejectData(data);
    };

    const onRetryPayout = async (id, i) => {
      try {
        let tempLoading = [...isButtonLoading];
        tempLoading[i] = true;
        setButtonLoading(tempLoading);
        const res = await postCall_v2("oms/payout/initiate-payouts", {
          payoutRequestDTOList: [{ id }],
        });
        if (res.status === 200) {
          getPendingPayouts();
          showToaster(`Payout initiated`, success);
        } else {
          showToaster(ERROR_MSG, error);
        }
      } catch (err) {
        console.log(err);
        showToaster(ERROR_MSG, error);
      } finally {
        let tempLoading = [...isButtonLoading];
        tempLoading[i] = false;
        setButtonLoading(tempLoading);
      }
    };

    const onRejectPayout = async () => {
      const { shipmentId, orderType, payoutId } = rejectData;
      try {
        const res = await postCall_v2("oms/payout/reject/shipmentPayout", {
          message: getValues("reason"),
          reference:
            orderType === FinancePayouts.orderTypes[0] ? shipmentId : payoutId,
          orderType,
        });
        if (res) {
          getPendingPayouts();
          showToaster(`Payout rejected`, success);
        } else {
          showToaster(ERROR_MSG, error);
        }
      } catch (err) {
        console.log(err);
        showToaster(ERROR_MSG, error);
      } finally {
        onCloseModal();
      }
    };

    const handleDownloadFile = async (order) => {
      if (order.orderType === ORDER_BOOK) {
        downloadDocument(
          `oms/off-oms-payout/document/get?payoutId=${order.payoutId}`,
          order?.shipmentInvoiceDetails?.invoiceDetails[0]?.invoiceNumber,
        );
      } else {
        const invoiceFileObj =
          order?.shipmentInvoiceDetails?.invoiceDetails[0]?.files?.invoice;
        triggerDownloadInvoice(
          invoiceFileObj[Object?.keys(invoiceFileObj)],
          invoiceType,
          order?.shipmentId,
          order?.shipmentInvoiceDetails?.invoiceDetails[0]?.invoiceNumber,
        );
      }
    };

    const handleChildCheckbox = (e, i, val) => {
      let tempSelectedData = [...selectedPayout];
      if (e.target.checked) {
        tempSelectedData.push(pendingTransactions?.payoutSummaries[i]);
      } else {
        tempSelectedData = tempSelectedData.filter(
          (item) => item?.["payoutId"] !== val?.["payoutId"],
        );
      }
      passSelectedData(tempSelectedData);
    };

    const handleParentCheckbox = (e) => {
      let tempData = [];
      if (e.target.checked) {
        tempData = pendingTransactions?.payoutSummaries.filter(
          (ele) => ele?.status !== PAYMENT_IN_PROGRESS,
        );
      } else {
        tempData = [];
      }
      setAllChecked(e.target.checked);
      passSelectedData(tempData);
    };

    const handleErrorToggle = useCallback((value) => {
      setShowErrorList((prev) => {
        if (prev.includes(value)) {
          return prev.filter((id) => value !== id);
        } else {
          return [...prev, value];
        }
      });
    }, []);

    // hook to expose function - which will unselect all selected items
    useImperativeHandle(
      ref,
      () => ({
        resetParentCheckBox: () => {
          setAllChecked(false);
        },
      }),
      [],
    );

    useEffect(() => {
      const errorList = [];
      pendingTransactions?.payoutSummaries?.forEach((data) => {
        if (
          data?.shipmentInvoiceDetails.invoiceDetails[0].currentState ===
          PAYMENT_FAILED
        ) {
          errorList.push(data?.payoutId);
        }
      });
      setShowErrorList(errorList);
    }, [pendingTransactions]);

    /**
     * @description Validated checkbox is selected or not.
     * @param {*} payoutId
     * @returns true if selected else false
     */
    const getIsTxSelected = (payoutId) => {
      return selectedPayout?.some((item) => item?.payoutId === payoutId);
    };

    return (
      <Box>
        <>
          <Paper>
            <TableContainer
              sx={{
                minWidth: 700,
                maxHeight: "calc(100vh - 120px)",
              }}
            >
              <Table stickyHeader>
                <TableHead style={{ borderRadius: "10px 10px 0 0" }}>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={isAllChecked}
                        onChange={(e) => handleParentCheckbox(e)}
                      />
                    </TableCell>
                    {INVOICE_PENDING_HEADERS.map((heading, i) => (
                      <TableCell key={i}>{heading}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pendingTransactions?.payoutSummaries?.length && !loading ? (
                    pendingTransactions?.payoutSummaries?.map((item, i) => (
                      <>
                        <TableRow key={item?.payoutId}>
                          <TableCell>
                            <Checkbox
                              key={item?.payoutId}
                              checked={
                                getIsTxSelected(item?.payoutId) &&
                                item?.status !== PAYMENT_IN_PROGRESS
                              }
                              disabled={item?.status === PAYMENT_IN_PROGRESS}
                              onChange={(e) => handleChildCheckbox(e, i, item)}
                            />
                          </TableCell>
                          <TableCell>
                            <StatusChip status={item?.orderType} isOrderType />
                          </TableCell>
                          <TableCell>
                            <HyperLink title={item?.customerOrdNumber} />
                            <br />
                            {item?.entityName}
                          </TableCell>
                          <TableCell>{item?.payoutType}</TableCell>
                          <TableCell>
                            {dateConvertor(item?.transactionCreatedDate)}
                          </TableCell>
                          <TableCell>
                            <Box display={"flex"} alignItems={"center"} gap={2}>
                              {
                                item?.shipmentInvoiceDetails?.invoiceDetails[0]
                                  ?.invoiceNumber
                              }
                              {((item?.shipmentInvoiceDetails?.invoiceDetails[0]
                                ?.files &&
                                item?.orderType === ERP) ||
                                item?.orderType === ORDER_BOOK) && (
                                <IconButton
                                  disableRipple={true}
                                  onClick={() => handleDownloadFile(item)}
                                >
                                  <FileDownloadOutlined
                                    fontSize="small"
                                    sx={{
                                      color: theme.palette.secondary.main,
                                    }}
                                  />
                                </IconButton>
                              )}
                            </Box>
                            {item?.originalInvoiceId?.[0] ? (
                              <IconTooltip
                                label={item?.originalInvoiceId?.[0]}
                                icon={
                                  <Box height={16}>
                                    <SvgIcon
                                      name={"info"}
                                      width={16}
                                      height={16}
                                    />
                                  </Box>
                                }
                              >
                                <>
                                  <Typography
                                    color={"text.secondary"}
                                    sx={{ paddingBottom: 2 }}
                                  >
                                    Original invoices
                                  </Typography>
                                  {item?.originalInvoiceId?.map((id) => (
                                    <Typography>{id}</Typography>
                                  ))}
                                </>
                              </IconTooltip>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell>
                            {item?.documentDate
                              ? dateConvertor(item?.documentDate)
                              : dateConvertor(
                                  item?.shipmentInvoiceDetails
                                    ?.invoiceDetails?.[0].invoiceDate,
                                )}

                            <br />
                            {dateConvertor(
                              item?.shipmentInvoiceDetails?.invoiceDetails?.[0]
                                .invoiceDueDate,
                            )}
                          </TableCell>
                          <TableCell>
                            <Box display={"flex"} alignItems={"center"} gap={1}>
                              {moneyFormat(
                                item?.shipmentInvoiceDetails
                                  ?.totalInvoicedAmount,
                              )}
                              <DocumentPriceBreakup
                                data={item.shipmentInvoiceDetails}
                                addInfo={item.creditMetaInfo}
                                tcsTdsDeductionReq={item.tcsTdsDeductionReq}
                              />
                            </Box>
                          </TableCell>

                          <TableCell>
                            <Box display={"flex"} gap={1}>
                              {moneyFormat(item?.amount)}
                              <PriceBreakup data={item} />
                            </Box>
                          </TableCell>
                          <TableCell>
                            <StatusChip
                              status={item?.nextStates}
                              isTooltip={true}
                            />
                          </TableCell>
                          <TableCell>
                            <Box style={{ textAlign: "center" }}>
                              {item?.invoiceComments ? (
                                <HoverBlock
                                  LabelChildren={
                                    <IconButton>
                                      <SvgIcon
                                        name={"chat-unread"}
                                        width={24}
                                        height={24}
                                      />
                                    </IconButton>
                                  }
                                  data={{
                                    "Invoice comments": item?.invoiceComments,
                                  }}
                                />
                              ) : (
                                <IconButton>
                                  <SvgIcon
                                    name={"chat-read"}
                                    width={24}
                                    height={24}
                                  />
                                </IconButton>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box display={"flex"} alignItems={"center"}>
                              {item.status === PAYMENT_FAILED ? (
                                <Box display={"flex"} gap={1}>
                                  <ButtonV1
                                    title={"Retry"}
                                    variant={"contained"}
                                    size={"small"}
                                    onClick={() =>
                                      onRetryPayout(item?.payoutId, i)
                                    }
                                  />
                                  <ButtonV1
                                    title={"Reject"}
                                    variant={"text"}
                                    size={"small"}
                                    onClick={() => validateRejection(item)}
                                    disabled={
                                      item?.status === PAYMENT_IN_PROGRESS ||
                                      item?.orderType === ERP
                                    }
                                  />
                                </Box>
                              ) : (
                                <ButtonV1
                                  title={"Reject"}
                                  variant={"outlined"}
                                  size={"small"}
                                  onClick={() => validateRejection(item)}
                                  disabled={
                                    item?.orderType === ERP ||
                                    item?.status === PAYMENT_IN_PROGRESS
                                  }
                                />
                              )}

                              {item.shipmentInvoiceDetails.invoiceDetails[0]
                                .currentState === PAYMENT_FAILED && (
                                <InfoOutlined
                                  onClick={() =>
                                    handleErrorToggle(item?.payoutId)
                                  }
                                  style={{ width: 16, marginLeft: 6 }}
                                />
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                        {showErrorList.includes(item?.payoutId) && (
                          <TableCell
                            colSpan={INVOICE_PENDING_HEADERS.length + 1}
                            sx={{ textAlign: "center", padding: 0 }}
                          >
                            <TableRowWithError
                              message={item?.errorMessage}
                              onClose={handleErrorToggle}
                              id={item?.payoutId}
                              key={item?.payoutId}
                              length={INVOICE_PENDING_HEADERS.length + 1}
                            />
                          </TableCell>
                        )}
                      </>
                    ))
                  ) : loading ? (
                    <TableCell
                      colSpan={INVOICE_PENDING_HEADERS.length}
                      sx={{ textAlign: "center" }}
                    >
                      <Loader sx={{ marginTop: 0 }} />
                    </TableCell>
                  ) : (
                    <TableCell
                      colSpan={INVOICE_PENDING_HEADERS.length}
                      sx={{ textAlign: "center" }}
                    >
                      No data found
                    </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {!!pendingTransactions?.payoutSummaries?.length && (
              <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                rowsPerPage={rowsPerPage}
                page={pageNumber}
                count={pendingTransactions?.totalCount ?? 0}
                onPageChange={onPageChange}
                sx={{ display: "flex", flexDirection: "column-reverse" }}
                ActionsComponent={PaginationAction}
              />
            )}
          </Paper>
          <ModalBox open={isModalOpen} onCloseModal={onCloseModal} width="50%">
            <Typography fontWeight={"bold"} mb={4}>
              Reject {rejectData?.customerOrdNumber}
            </Typography>
            <Controller
              control={control}
              name={"reason"}
              render={({ field: { value, onChange } }) => (
                <TextField
                  multiline
                  inputProps={{
                    style: {
                      height: 104,
                    },
                  }}
                  sx={{
                    width: "100%",
                  }}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                marginTop: 4,
              }}
            >
              <ButtonV1
                variant="outlined"
                color="secondary"
                title="Close"
                size="small"
                onClick={onCloseModal}
                style={{ marginRight: 4 }}
              />
              <ButtonV1
                variant="contained"
                color="secondary"
                title="Confirm"
                disabled={!isDirty}
                onClick={onRejectPayout}
                size="small"
              />
            </Box>
          </ModalBox>
        </>
      </Box>
    );
  },
);

export default PayoutTable;
