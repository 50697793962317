import {
  useCallback,
  useImperativeHandle,
  useEffect,
  useRef,
  forwardRef,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Typography, Box } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";

import ManualEntry from "./ManualEntry";
import { invoiceSellerSchema } from "./test";
import { ButtonV1 } from "components";
import { INVOICE_SELLER_PAYOUT } from "./constant";
import { requestDataMapper, resetFieldMapper } from "./makeData";
import ERROR_MSG from "./test/constant";

const EditableTransaction = forwardRef(
  ({ data, errorList, isBulkUpload, onSubmit, onReset }, ref) => {
    const scrollToRef = useRef(null);

    const methods = useForm({
      mode: "onSubmit",
      resolver: yupResolver(invoiceSellerSchema),
    });

    useImperativeHandle(ref, () => ({
      scroll() {
        window.scrollTo({
          top: scrollToRef.current.offsetTop,
          left: 0,
          behavior: "smooth",
        });
      },
    }));

    const {
      reset,
      handleSubmit,
      trigger,
      getValues,
      setError,
      formState: { errors },
    } = methods;

    const setNewData = useCallback(() => {
      reset(
        { [INVOICE_SELLER_PAYOUT.invoiceYup]: data },
        { keepErrors: false },
      );
    }, [data, reset]);

    const checkForError = useCallback(
      async (data) => {
        let hasError = false;

        if (isBulkUpload) {
          hasError = !(await trigger());
        } else if (errorList?.length) {
          errorList?.forEach((item, index) =>
            item?.forEach((error) => {
              setError(
                `${INVOICE_SELLER_PAYOUT.invoiceYup}.${index}.${error}`,
                {
                  message: ERROR_MSG[error],
                },
              );
            }),
          );
          hasError = true;
        }

        if (isBulkUpload && !hasError) handleSave(data);
      },
      [errorList, isBulkUpload, trigger, setError],
    );

    const handleResetForm = () => {
      reset({ [INVOICE_SELLER_PAYOUT.invoiceYup]: resetFieldMapper() });
      onReset();
    };

    useEffect(() => {
      setNewData();
      checkForError(data);
    }, [data]);

    const handleSave = (data) => {
      const result = requestDataMapper(
        !data?.invoiceSeller
          ? data
          : getValues(INVOICE_SELLER_PAYOUT.invoiceYup),
      );

      onSubmit({ offOmsPayoutRequestList: result });
      handleResetForm();
    };

    useImperativeHandle(ref, () => ({
      scroll() {
        window.scrollTo({
          top: scrollToRef.current.offsetTop,
          left: 0,
          behavior: "smooth",
        });
      },
    }));

    return (
      <FormProvider {...methods}>
        <Typography marginTop={5}>
          {isBulkUpload || data?.[0].id || errorList?.length > 0
            ? `Review transactions (${data.length})`
            : "Manual upload"}
        </Typography>
        <Box
          ref={scrollToRef}
          as="form"
          onSubmit={handleSubmit(handleSave)}
          sx={{
            paddingY: 3,
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          {data?.map((item, index) => (
            <ManualEntry
              methods={methods}
              key={index}
              unique={index}
              item={item}
              errors={errors}
            />
          ))}
          <Box display={"flex"} justifyContent={"flex-end"} gap={3}>
            <ButtonV1
              variant="outlined"
              title="Clear all"
              onClick={handleResetForm}
            />
            <ButtonV1
              title="Save"
              color="primary"
              variant="contained"
              type={"submit"}
            />
          </Box>
        </Box>
      </FormProvider>
    );
  },
);
export default EditableTransaction;
