import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";

export const LabelContainer = ({
  label,
  value,
  color,
  labelStyle,
  gridXs = 3,
  flexDirection = "row",
}) => {
  return (
    <>
      <Grid item xs={gridXs}>
        <Box display={"flex"} flexDirection={flexDirection}>
          <Typography style={labelStyle}>{label}</Typography>
          <Typography fontWeight={600} marginLeft={2} color={color}>
            {value}
          </Typography>
        </Box>
      </Grid>
    </>
  );
};

LabelContainer.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  color: PropTypes.string,
  labelStyle: PropTypes.object,
};
