import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from "@mui/material";

import { ButtonV1 } from "components";

import { moneyFormat } from "utils";
import CREDIT_UPDATE from "../constants";

const { utilisedLimitBreakupHeader } = CREDIT_UPDATE;

const getUtilizedList = (blockListData, utilizedDataList) => {
  const utilizedList = utilizedDataList?.map((item) => {
    const utilizedItem = blockListData?.find(
      (blockedItem) => blockedItem.ordNumber === item.orderNumber,
    );
    return {
      ...item,
      blockedAmount: utilizedItem?.creditAmount,
      utilisedAmount: item?.creditAmount,
    };
  });
  return utilizedList;
};

const getBlockedList = (blockList, utilizedList) => {
  const combinedList = [];
  for (const blockedItem of blockList) {
    const matchingUtilizedItems = utilizedList?.filter(
      (item) => item.orderNumber === blockedItem.ordNumber,
    );

    if (matchingUtilizedItems?.length > 0) {
      matchingUtilizedItems?.forEach((utilizedItem) => {
        combinedList.push({
          orderNumber: blockedItem.ordNumber,
          blockedAmount: blockedItem.creditAmount,
          invoiceNumber: utilizedItem.invoiceNumber,
          utilisedAmount: utilizedItem.creditAmount,
        });
      });
    } else {
      // If no match is found, add the item from blockedList with utilizedAmount and invoiceNo set to 0
      combinedList.push({
        orderNumber: blockedItem.ordNumber,
        blockedAmount: blockedItem.creditAmount,
        invoiceNumber: "NA",
        utilisedAmount: 0,
      });
    }
  }

  return combinedList;
};

const AmountBreakupTable = ({ onClose, data, type }) => {
  const isBlockedLimit = type === "Blocked limit";

  const blockListData = data?.blockedLimitsList ?? [];
  const utilizedList = data?.utilizedLimitsList ?? [];
  const amountsList = isBlockedLimit
    ? getBlockedList(blockListData, utilizedList)
    : getUtilizedList(blockListData, utilizedList);
  const title = `${isBlockedLimit ? "Blocked" : "Utilised"} amount breakup `;

  return (
    <>
      <Box>
        <Typography style={{ fontWeight: 600 }}>{title}</Typography>
        <TableContainer
          sx={{
            marginTop: 4,
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {utilisedLimitBreakupHeader.map((heading) => (
                  <TableCell key={heading}>{heading}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {amountsList?.map((item, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Typography fontSize={14}>
                      {item?.orderNumber ?? "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={14}>
                      {item?.invoiceNumber ?? "NA"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={14}>
                      {moneyFormat(item?.utilisedAmount) ?? "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize={14}>
                      {moneyFormat(item?.blockedAmount) ?? "-"}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Divider
        style={{
          marginTop: 12,
          borderBottomWidth: 2,
          marginBottom: 16,
        }}
      />
      <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        <ButtonV1
          title={"Close"}
          style={{ textAlign: "flex-end" }}
          onClick={onClose}
        />
      </Box>
    </>
  );
};

export default AmountBreakupTable;
