import { useCallback, useEffect, useState } from "react";
import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import { validateNull, dateConvertor, moneyFormat } from "utils";
import {
  ButtonV1,
  Loader,
  PaginationAction,
  SvgIcon,
  TableRowWithError,
} from "components";
import { FinancePayouts } from "../../constants";
import { HoverBlock, StatusChip } from "../components";

const { ORDER_BLOCK_PENDING, PAYMENT_FAILED, PAYMENT_PENDING } = FinancePayouts;

const OrderBlockTable = ({
  data,
  isLoading,
  onReject,
  onInitate,
  onSelectedPayout,
  selectedPayout = [],
  onPageChange,
}) => {
  const [showErrorList, setShowErrorList] = useState([]);

  const handleAllCheck = useCallback(() => {
    if (selectedPayout.length === data?.payoutSummaries?.length) {
      onSelectedPayout([]);
    } else {
      const allPayoutId = data?.payoutSummaries.map(
        (record) => record?.payoutId,
      );
      onSelectedPayout(allPayoutId);
    }
  }, [data, selectedPayout]);

  const handleErrorToggle = useCallback((value) => {
    setShowErrorList((prev) => {
      if (prev.includes(value)) {
        return prev.filter((id) => value !== id);
      } else {
        return [...prev, value];
      }
    });
  }, []);

  const handleSelect = useCallback(
    (payoutId) => {
      const isIdChecked = selectedPayout.includes(payoutId);
      if (isIdChecked) {
        onSelectedPayout(selectedPayout.filter((value) => value !== payoutId));
      } else {
        onSelectedPayout([...selectedPayout, payoutId]);
      }
    },
    [selectedPayout],
  );

  useEffect(() => {
    const errorList = [];
    data?.payoutSummaries?.forEach((data) => {
      if (data?.status === PAYMENT_FAILED) {
        errorList.push(data?.payoutId);
      }
    });
    setShowErrorList(errorList);
  }, [data]);

  return (
    <Paper>
      <TableContainer
        sx={{
          minWidth: 700,
          maxHeight: "calc(100vh - 120px)",
        }}
      >
        <Table stickyHeader>
          <TableHead style={{ borderRadius: "10px 10px 0 0" }}>
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={
                    selectedPayout.length !== 0 &&
                    selectedPayout.length === data?.payoutSummaries?.length
                  }
                  onChange={handleAllCheck}
                />
              </TableCell>
              {ORDER_BLOCK_PENDING.map((heading, i) => (
                <TableCell key={i}>{heading}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={ORDER_BLOCK_PENDING.length + 1}>
                  <Loader />
                </TableCell>
              </TableRow>
            )}
            {!isLoading && !data?.payoutSummaries.length && (
              <TableRow>
                <TableCell colSpan={ORDER_BLOCK_PENDING.length + 1}>
                  <Typography textAlign={"center"}>No data found</Typography>
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              data?.payoutSummaries?.map((record) => (
                <>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={selectedPayout.includes(record?.payoutId)}
                        onChange={() => handleSelect(record?.payoutId)}
                      />
                    </TableCell>
                    <TableCell>{validateNull(record?.orderId)}</TableCell>
                    <TableCell>
                      <StatusChip status={record?.orderType} isOrderType />
                    </TableCell>
                    <TableCell>{validateNull(record.entityGstin)}</TableCell>
                    <TableCell>
                      {validateNull(record.creditInstrument)}
                    </TableCell>
                    <TableCell>{dateConvertor(record.date)}</TableCell>
                    <TableCell>{moneyFormat(record.orderAmount)}</TableCell>
                    <TableCell>{moneyFormat(record.advanceAmount)}</TableCell>
                    <TableCell>{moneyFormat(record.creditAmount)}</TableCell>
                    <TableCell>
                      <StatusChip status={record?.status} />
                    </TableCell>
                    <TableCell>
                      <Box style={{ textAlign: "center" }}>
                        {record?.invoiceComments ? (
                          <HoverBlock
                            LabelChildren={
                              <IconButton>
                                <SvgIcon
                                  name={"chat-unread"}
                                  width={24}
                                  height={24}
                                />
                              </IconButton>
                            }
                            data={{
                              "Invoice comments": record?.invoiceComments,
                            }}
                          />
                        ) : (
                          <IconButton>
                            <SvgIcon
                              name={"chat-read"}
                              width={24}
                              height={24}
                            />
                          </IconButton>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {record?.status === PAYMENT_FAILED ? (
                        <Box display={"flex"} gap={1} alignItems={"center"}>
                          <ButtonV1
                            title={"Retry"}
                            size="small"
                            onClick={async () =>
                              await onInitate([record?.payoutId])
                            }
                          />
                          <ButtonV1
                            title={"Reject"}
                            variant="text"
                            size="small"
                            onClick={async () =>
                              await onReject(record?.payoutId)
                            }
                          />
                          {!!record?.errorMessage && (
                            <InfoOutlined
                              style={{ width: 16, marginLeft: 6 }}
                            />
                          )}
                        </Box>
                      ) : (
                        !!record?.status === PAYMENT_PENDING && (
                          <ButtonV1
                            title={"Reject"}
                            size="small"
                            onClick={async () =>
                              await onReject(record?.payoutId)
                            }
                          />
                        )
                      )}
                    </TableCell>
                  </TableRow>
                  {showErrorList.includes(record?.payoutId) && (
                    <TableCell
                      colSpan={ORDER_BLOCK_PENDING.length + 1}
                      sx={{ textAlign: "center", padding: 0 }}
                    >
                      <TableRowWithError
                        message={record?.errorMessage}
                        onClose={handleErrorToggle}
                        id={record?.payoutId}
                        key={record?.payoutId}
                        length={ORDER_BLOCK_PENDING.length + 1}
                      />
                    </TableCell>
                  )}
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!!data?.payoutSummaries?.length && (
        <TablePagination
          rowsPerPageOptions={[data.limit]}
          rowsPerPage={data.limit}
          page={data.offset / data.limit}
          count={data?.totalCount ?? 0}
          onPageChange={onPageChange}
          sx={{ display: "flex", flexDirection: "column-reverse" }}
          ActionsComponent={PaginationAction}
        />
      )}
    </Paper>
  );
};

OrderBlockTable.prototype = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  onReject: PropTypes.func,
  onInitate: PropTypes.func,
  onSelectedPayout: PropTypes.func,
  selectedPayout: PropTypes.array,
  onPageChange: PropTypes.func,
};

export default OrderBlockTable;
