import { Box, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { forwardRef } from "react";

import { ButtonV1, DropdownV1, TextField } from "components";
import { searchConstants } from "./constants";

const { SEARCH_FILTER_FORM, searchTypeList } = searchConstants;

const CreditCustomersHeader = forwardRef(({ onTriggerFilter }, ref) => {
  const {
    getValues,
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      lineItemsDetails: [],
    },
    reValidateMode: "onSubmit",
  });

  const handleTriggerFilter = () => {
    onTriggerFilter(getValues());
  };

  return (
    <Box
      display={"flex"}
      sx={{ alignItems: "left", padding: 4, gap: 20 }}
      as="form"
      onSubmit={handleSubmit(handleTriggerFilter)}
    >
      <Typography sx={{ fontWeight: 600, fontSize: 20 }}>
        Search Customers
      </Typography>
      <Box width={300}>
        <Controller
          control={control}
          name={SEARCH_FILTER_FORM.searchType}
          render={({ field: { value, onChange } }) => (
            <DropdownV1
              itemList={searchTypeList}
              value={value}
              onChange={onChange}
              placeholder="Search type"
            />
          )}
        />
      </Box>
      <Box width={300}>
        <Controller
          control={control}
          name={SEARCH_FILTER_FORM.searchValue}
          render={({ field: { value, onChange } }) => (
            <TextField
              style={{ width: `90%` }}
              value={value}
              size="small"
              onChange={onChange}
              label="Enter Search Value"
            />
          )}
        />
      </Box>
      <ButtonV1 title={"Show result"} disabled={!isDirty} type="submit" />
    </Box>
  );
});

export default CreditCustomersHeader;
