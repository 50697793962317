import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Grid, Box } from "@mui/material";
import PropTypes from "prop-types";

import { postCall, deleteCall_v2 } from "services";
import Attachment from "./Attachment";
import { fileUploadListData } from "../constants";
import { success, error } from "constants";
import { useDownloadInvoiceFile, useToaster } from "hooks";

const FileUpload = forwardRef(
  ({ fileList, shipmentId, invoiceNumber }, ref) => {
    const [fileDetails, setFileDetails] = useState();

    const triggerToaster = useToaster();

    const { triggerDownloadInvoice } = useDownloadInvoiceFile();

    useEffect(() => {
      if (fileList) {
        setFileDetails(fileList);
      }
    }, [fileList]);

    useImperativeHandle(
      ref,
      () => ({
        getUploadedInvoices() {
          return fileDetails?.invoice;
        },
      }),
      [fileDetails],
    );

    const fileUploadAction = (action, msg = null) => {
      if (action === success) {
        triggerToaster(`Files uploaded`, action);
      } else {
        triggerToaster(msg || `Error occurred, please try again`, action);
      }
    };

    const handleUpload = async (files, fileType) => {
      if (invoiceNumber) {
        const formData = new FormData();
        files?.length &&
          Object.keys(files).map((file) =>
            formData.append("files", files[file]),
          );
        const resp = await postCall(
          `/oms/shipment/documents/upload`,
          formData,
          {
            shipmentId,
            fileType,
            invoiceNumber,
          },
          (err) => {
            fileUploadAction(error, err?.data?.detail);
          },
        );
        if (resp) {
          setFileDetails(resp[shipmentId]?.invoiceDetails[0]?.files);
          fileUploadAction(success);
        }
      } else {
        fileUploadAction(
          error,
          "Please create invoice delivery to upload documents",
        );
      }
    };

    const handleFileDownload = (file, fileType) => {
      triggerDownloadInvoice(file, fileType, shipmentId, invoiceNumber);
    };

    const handleFileDelete = async (fileType, file, toggleModal) => {
      try {
        await deleteCall_v2(
          "/oms/shipment/documents",
          {},
          {
            fileType: fileType.fileType,
            shipmentId,
            path: file?.path,
          },
        );
        let tempData = JSON.parse(JSON.stringify(fileDetails));
        delete tempData?.[fileType?.key]?.[file?.generatedId];
        setFileDetails(tempData);
        triggerToaster("File is deleted", success);
        Object.keys(tempData?.[fileType?.key]).length || toggleModal();
      } catch (ex) {
        triggerToaster(
          ex?.data?.detail ?? "File not deleted. Please try later",
          error,
        );
        console.error(`Error in deleting document ${ex}`);
      }
    };

    return (
      <Box pt={3} pb={3}>
        <Grid container spacing={3} mb={3}>
          <Grid p={2} xs style={{ border: 1 }}>
            <Attachment
              fileType={fileUploadListData?.invoice}
              fileDetails={fileDetails?.invoice}
              onFileChange={handleUpload}
              onFileDownload={handleFileDownload}
              onFileDelete={handleFileDelete}
            />
          </Grid>
          <Grid p={2} xs style={{ border: 1 }}>
            <Attachment
              fileType={fileUploadListData?.testCertificate}
              fileDetails={fileDetails?.testCertificate}
              onFileChange={handleUpload}
              onFileDownload={handleFileDownload}
              onFileDelete={handleFileDelete}
            />
          </Grid>
          <Grid p={2} xs style={{ border: 1 }}>
            <Attachment
              fileType={fileUploadListData?.weightmentSlips}
              fileDetails={fileDetails?.weightmentSlips}
              onFileChange={handleUpload}
              onFileDownload={handleFileDownload}
              onFileDelete={handleFileDelete}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid p={2} xs={4} style={{ border: 1 }}>
            <Attachment
              fileType={fileUploadListData?.lorryReceipt}
              fileDetails={fileDetails?.lorryReceipt}
              onFileChange={handleUpload}
              onFileDownload={handleFileDownload}
              onFileDelete={handleFileDelete}
            />
          </Grid>
          <Grid p={2} xs={4} style={{ border: 1 }}>
            <Attachment
              fileType={fileUploadListData?.other}
              fileDetails={fileDetails?.other}
              onFileChange={handleUpload}
              onFileDownload={handleFileDownload}
              onFileDelete={handleFileDelete}
            />
          </Grid>
        </Grid>
      </Box>
    );
  },
);

FileUpload.propTypes = {
  fileList: PropTypes.object,
  shipmentId: PropTypes.number,
  invoiceNumber: PropTypes.string,
};

export default FileUpload;
