import { useState, useRef } from "react";
import { Box, Typography } from "@mui/material";
import Papa from "papaparse";

import { SvgIcon } from "components";
import { useToaster } from "hooks";

import theme from "themeProvider";
import { success, error } from "constants";
import { postCall_v2 } from "services";

const PayoutTxUpload = () => {
  const fileUpload = useRef(null);
  const [file, setFile] = useState();
  const triggerToaster = useToaster();

  const handleClick = () => {
    fileUpload.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  /**
   * @description Read uploaded file & invoke oms apis
   * @param {*} uploadedFile file contents
   */
  const parseFile = (uploadedFile) => {
    try {
      // Initialize a reader
      const reader = new FileReader();
      //listener event
      reader.onload = async ({ target }) => {
        const csv = Papa.parse(target.result, { header: true });
        const parsedData = csv?.data;
        const reqBody = [];
        if (parsedData.length) {
          parsedData.forEach((ele) => {
            const payload = {
              debitAccountNumber: ele["Debit Ac No"],
              beneficiaryAccountNumber: ele["Beneficiary Ac No"],
              beneficiaryName: ele["Beneficiary Name"],
              amount: ele?.Amt,
              paymentMode: ele["Pay Mod"],
              date: ele?.Date,
              ifscCode: ele["IFSC Code"],
              payableLocation: ele["Payable Location name"],
              printLocation: ele["Print Location"],
              beneficiaryMobileNo: ele["Bene Mobile no"],
              beneficiaryEmailId: ele["Bene email id"],
              beneficiaryAddressLine1: ele["Ben add1"],
              beneficiaryAddressLine2: ele["Ben add2"],
              beneficiaryAddressLine3: ele["Ben add3"],
              beneficiaryAddressLine4: ele["Ben add4"],
              systemReferenceNumber: ele["System Ref No."],
              beneficiaryLeiNumber: ele["BENE LEI Number"],
              beneficiaryLeiExpiryDate: ele["BENE LEI Expiry Date"],
              remitterLeiNumber: ele["Sender/ Remitter LEI Number"],
              remitterLeiExpiryDate: ele["Sender/ Remitter LEI Expiry Date"],
              orderNumber: ele["Order Number"],
              documentId: ele["Document Id"],
              documentType: ele["Transaction Type"],
              creditNarration: ele["Credit Narration"],
              debitNarration: ele["Debit Narration"],
              remarks: ele["Remarks"],
              utr: ele["UTR NO"],
              status: ele["Status"],
            };
            if (
              payload.utr &&
              payload.systemReferenceNumber &&
              payload.status
            ) {
              //to avoid null extra line entries
              reqBody.push(payload);
            }
          });
          const { data } = await postCall_v2(
            "/oms/payout/upload-payout",
            reqBody,
          );
          if (data) {
            triggerToaster(`Uploaded transaction successfully`, success);
          }
        } else {
          triggerToaster(`No data available`, error);
        }
      };
      reader.readAsText(uploadedFile);
    } catch (ex) {
      console.log(`Exception occured in reading file ${ex}`);
    } finally {
      setFile(null);
    }
  };

  /**
   *
   * @param {*} event
   * @description handle all file validations & invoke file parsing
   */
  const handleFileUpload = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const fileFormat = "text/csv";

    const file = [
      ...(e.type === "drop" ? e.dataTransfer.files : e.target.files),
    ];

    if (file.length > 1) {
      triggerToaster(`Only 1 file can be uploaded at a time`, error);
      return;
    }
    const allowedMaxSize = 2;
    const fileSize = file?.[0]?.size / 1024 / 1024; //convert to MB

    if (fileSize.toFixed(2) > allowedMaxSize) {
      triggerToaster("Allowed max file size is 2MB", error);
      return;
    }

    if (file?.[0]?.type !== fileFormat) {
      triggerToaster(`Please upload a csv file`, error);
      return;
    }
    if (file && file.length) {
      setFile(file?.[0]);
      parseFile(file?.[0]);
    }
  };

  return (
    <>
      <Typography fontSize={18} fontWeight={"bold"} mb={4}>
        Upload payout transactions sheet
      </Typography>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        style={{
          border: 1,
          borderStyle: "dashed",
          borderColor: theme.palette.grey[900],
          borderRadius: 12,
          backgroundColor: theme.palette.info.lightBlueBackround,
        }}
        mb={2}
        p={12}
        pt={4}
        onClick={handleClick}
        onDragOver={handleDragOver}
        onDrop={handleFileUpload}
      >
        {/* OnClick file upload */}
        <input
          style={{ display: "none" }}
          type="file"
          ref={fileUpload}
          onClick={(event) => {
            event.target.value = null;
          }}
          onChange={handleFileUpload}
        />
        <SvgIcon
          name={"file"}
          style={{
            height: 48,
            width: 48,
            marginBottom: 20,
          }}
        />
        {file && <Typography marginBottom={2}>{file?.name}</Typography>}
        {!file && (
          <>
            <Typography style={{ fontSize: 16, lineHeight: 1.25 }}>
              Click or drag file to this area to upload
            </Typography>
            <Typography
              style={{
                fontSize: 14,
                color: theme.palette.grey[1000],
                lineHeight: 1.29,
              }}
            >
              Support for a single or bulk upload. Maximum file size 2MB.
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};

export default PayoutTxUpload;
