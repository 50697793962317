export const generateFilterQuery = (filterParams) => {
  let query = "";
  if (filterParams) {
    const { companyName, orderState, opsSpoc } = filterParams;
    query = `companyName=${companyName}&orderState=${orderState}&opsSpoc=${opsSpoc}`;
  }
  return query;
};

export const createFilterObj = (queryParams) => {
  let selectedFilters = {};
  if (queryParams) {
    const urlSearchParams = new URLSearchParams(queryParams);
    selectedFilters = Object.fromEntries(urlSearchParams.entries());
  }
  return selectedFilters;
};
