import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      <Typography variant="h1">404</Typography>
      <Typography variant="h3">Page not found</Typography>
      <Link to="/" style={{ textDecoration: "none", fontSize: "1.05rem" }}>
        HomePage
      </Link>
    </Box>
  );
};

export default PageNotFound;
