import { memo, useEffect, useState } from "react";
import { TableCell, Typography } from "@mui/material";
import { useFetchData, useToaster } from "hooks";

import { ButtonV1, TableWrapper } from "components";
import { StyledTableRow } from "CustomStyledComponents";

import { appendRupeeIcon } from "utils";
import { cartTableHeaders, orderCreationFailed } from "./constants";
import { patchCall } from "services";
import EmptyList from "./EmptyList";

const CustomerCart = () => {
  const [customerOrders, setCustomerOrders] = useState();
  const [orderPage, setOrderPage] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const triggerToaster = useToaster();

  const orders = useFetchData(
    "customer-cart",
    `/oms/cart/list/?offset=${orderPage}&limit=20`,
    (res) => {
      const orders = res?.data;
      setCustomerOrders(orders);
      setLoading(false);
    },
    () => setLoading(false),
  );

  useEffect(() => {
    setLoading(true);
    orders.refetch();
    /* eslint-disable */
  }, [orderPage]);

  const convertCartToOrder = async (data) => {
    const { customerEmail = "", cartId = "", ctId = "" } = data;
    const res = await patchCall(
      "oms/cart/convert",
      { customerEmail, cartId, ctId },
      "",
      () => {
        triggerToaster(orderCreationFailed, "error");
      },
    );
    if (res) {
      triggerToaster(`Converted cart-${data?.cartId} to order`, "success");
      orders.refetch();
    }
  };

  const handlePagination = (event, newPage) => setOrderPage(newPage);

  return (
    <>
      <TableWrapper
        tableHeader={cartTableHeaders.map((item) => {
          return (
            <TableCell key={item}>
              <Typography>{item}</Typography>
            </TableCell>
          );
        })}
        errorMessage={
          (customerOrders?.response?.length === 0 || !customerOrders) &&
          !isLoading && <EmptyList />
        }
        tableBody={customerOrders?.response?.map((row, i) => {
          return (
            <StyledTableRow key={row.id} id={row.id}>
              <TableCell>{row?.customerEmail}</TableCell>
              <TableCell>{row?.cartId}</TableCell>
              <TableCell>{row?.opportunityId}</TableCell>
              <TableCell>
                {appendRupeeIcon(row?.ledgerBalance?.displayAmount)}
              </TableCell>
              <TableCell>{row?.whenCreated?.split("T")[0]}</TableCell>
              <TableCell>
                <ButtonV1
                  title="Create order"
                  onClick={() => convertCartToOrder(row)}
                  variant="text"
                  color="secondary"
                  style={{ padding: 0 }}
                />
              </TableCell>
            </StyledTableRow>
          );
        })}
        isPagination={true}
        page={orderPage}
        count={customerOrders?.totalCount}
        rowsPerPage={20}
        handleChangePage={handlePagination}
      />
    </>
  );
};

export default memo(CustomerCart);
