import * as Yup from "yup";

const createRoleSchema = Yup.object().shape({
  roleName: Yup.string()
    .required("Please enter role name")
    .test("spaceCheck", "No space between string allowed", (val) => {
      val = val?.trim();
      if (val?.split(" ").length > 1) return false;
      return true;
    }),
});

export { createRoleSchema };
