import { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { Box, Typography } from "@mui/material";
import Papa from "papaparse";
import PropTypes from "prop-types";

import { SvgIcon } from "components";
import { useToaster } from "hooks";

import theme from "themeProvider";
import { error } from "constants";

/**
 *
 * @description CSV Upload component wih max file size check &
 * callback function prop
 */
const UploadCSV = forwardRef(
  ({ headerText, parsedFileCallbackFn, allowedMaxSize = 2 }, ref) => {
    const fileUpload = useRef(null);
    const [file, setFile] = useState();
    const triggerToaster = useToaster();

    /**
     * @description handle click & drag operation
     */
    const handleClick = () => fileUpload.current.click();
    const handleDragOver = (e) => e.preventDefault();

    /**
     * @description Read uploaded file & invoke callback Fn
     * @param {*} uploadedFile file contents
     */
    const parseFile = (uploadedFile) => {
      try {
        // Initialize a reader
        const reader = new FileReader();
        //listener event
        reader.onload = async ({ target }) => {
          const csv = Papa.parse(target.result, {
            header: true,
            skipEmptyLines: true,
          });
          const parsedData = csv?.data;
          if (parsedData.length) {
            parsedFileCallbackFn && parsedFileCallbackFn(parsedData);
          } else {
            triggerToaster(`No data available`, error);
          }
        };
        reader.readAsText(uploadedFile);
      } catch (ex) {
        console.log(`Exception occured in reading file ${ex}`);
      }
    };

    /**
     *
     * @param {*} event
     * @description handle all file validations & invoke file parsing
     */
    const handleFileUpload = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const fileFormat = "text/csv";

      const file = [
        ...(e.type === "drop" ? e.dataTransfer.files : e.target.files),
      ];

      if (file.length > 1) {
        triggerToaster(`Only 1 file can be uploaded at a time`, error);
        return;
      }
      const fileSize = file?.[0]?.size / 1024 / 1024; //convert to MB

      if (fileSize.toFixed(2) > allowedMaxSize) {
        triggerToaster(`Allowed max file size is ${allowedMaxSize}MB`, error);
        return;
      }

      if (file?.[0]?.type !== fileFormat) {
        triggerToaster(`Please upload a csv file`, error);
        return;
      }
      if (file && file.length) {
        setFile(file?.[0]);
        parseFile(file?.[0]);
      }
    };

    // hook to expose function - which will return file and reset file
    useImperativeHandle(ref, () => ({
      getFile: () => file,
      resetFile: () => setFile(null),
    }));

    return (
      <>
        {!!headerText && (
          <Typography fontSize={18} fontWeight={"bold"} mb={4}>
            {headerText}
          </Typography>
        )}
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          style={{
            border: 1,
            borderStyle: "dashed",
            borderColor: theme.palette.grey[900],
            borderRadius: 12,
            backgroundColor: theme.palette.info.lightBlueBackround,
          }}
          mb={2}
          p={12}
          pt={4}
          onClick={handleClick}
          onDragOver={handleDragOver}
          onDrop={handleFileUpload}
        >
          {/* OnClick file upload */}
          <input
            style={{ display: "none" }}
            type="file"
            ref={fileUpload}
            onClick={(event) => {
              event.target.value = null;
            }}
            onChange={handleFileUpload}
          />
          <SvgIcon
            name={"file"}
            style={{
              height: 48,
              width: 48,
              marginBottom: 20,
            }}
          />
          {file && <Typography marginBottom={2}>{file?.name}</Typography>}
          {!file && (
            <>
              <Typography style={{ fontSize: 16, lineHeight: 1.25 }}>
                Click or drag file to this area to upload
              </Typography>
              <Typography
                style={{
                  fontSize: 14,
                  color: theme.palette.grey[1000],
                  lineHeight: 1.2,
                }}
              >
                Support for a single or bulk upload. Maximum file size{" "}
                {allowedMaxSize} MB.
              </Typography>
            </>
          )}
        </Box>
      </>
    );
  },
);

UploadCSV.propTypes = {
  headerText: PropTypes.string,
  parsedFileCallbackFn: PropTypes.func,
  allowedMaxSize: PropTypes.number,
};
export default UploadCSV;
