import {
  TableCell,
  Box,
  Typography,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { forwardRef, useImperativeHandle } from "react";

import { StyledTableRow, ColumnTitle } from "CustomStyledComponents";
import { HyperLink, TextField } from "components";
import FundAccountIdsView from "../PendingPayoutsTab/components/FundAccountIdsView";
import StatusChip from "../PendingPayoutsTab/components/StatusChip";

import { moneyFormat, validateNull } from "utils";
import { FinancePayouts } from "../constants";

const { disbursementHeader, refundDisbursementHeader } = FinancePayouts;

const PayoutDisbursement = forwardRef(
  (
    {
      data,
      handleRadioButton,
      title = "Do you want to confirm the payout ?",
      type,
      isRefund = false,
    },
    ref,
  ) => {
    const { getValues, control } = useForm({
      mode: "onTouched",
    });
    const UTR_Column = FinancePayouts.MARK_SUCCESS === type ? ["UTR"] : [];
    useImperativeHandle(ref, () => ({
      fetchUTR: () => getValues(),
    }));

    return (
      <>
        <Typography fontWeight={"bold"} textAlign={"center"} mb={4} mt={4}>
          {title}
        </Typography>
        {!!data && (
          <Box mb={6}>
            <TableContainer sx={{ maxHeight: 420 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {isRefund ? (
                      <>
                        {refundDisbursementHeader.map((item) => (
                          <TableCell key={item}>
                            <ColumnTitle>{item}</ColumnTitle>
                          </TableCell>
                        ))}
                      </>
                    ) : (
                      [...disbursementHeader, ...UTR_Column].map((item) => (
                        <TableCell key={item}>
                          <ColumnTitle>{item}</ColumnTitle>
                        </TableCell>
                      ))
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((ele, i) => {
                    return (
                      <>
                        <StyledTableRow key={ele?.shipmentId}>
                          <TableCell>
                            <StatusChip status={ele?.orderType} isOrderType />
                          </TableCell>
                          {!isRefund && (
                            <>
                              <TableCell>
                                {validateNull(ele?.documentId)}
                              </TableCell>
                              <TableCell>
                                {validateNull(ele?.shipmentId)}
                              </TableCell>
                            </>
                          )}

                          <TableCell>
                            <HyperLink title={ele?.orderId} />
                          </TableCell>
                          <TableCell>
                            {ele?.creditMetaInfo &&
                            Object.keys(ele?.creditMetaInfo)
                              ? moneyFormat(ele?.documentAmount)
                              : moneyFormat(ele?.payoutAmount)}
                          </TableCell>
                          <TableCell style={{ maxWidth: 60 }}>
                            {ele?.payoutEntity}
                          </TableCell>
                          {!isRefund && (
                            <TableCell>
                              <FundAccountIdsView
                                value={ele?.selectedFundAccountId}
                                onChange={(e) => handleRadioButton(e, i)}
                                fundAccounts={ele?.fundAccountDetails}
                              />
                            </TableCell>
                          )}

                          {!!UTR_Column.length && (
                            <TableCell>
                              <Controller
                                control={control}
                                name={`utr_${ele?.payoutId}`}
                                render={({ field: { value, onChange } }) => (
                                  <TextField
                                    value={value}
                                    onChange={onChange}
                                    style={{ padding: 2, margin: 0 }}
                                    size={"small"}
                                  />
                                )}
                              />
                            </TableCell>
                          )}
                        </StyledTableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </>
    );
  },
);

export default PayoutDisbursement;
