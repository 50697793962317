import { TableCell, Box, Typography } from "@mui/material";

import { ButtonV1, TableWrapper } from "components";
import { FundAccountIdsView } from "pages/FinancePayout/PendingPayoutsTab/components";
import { StyledTableRow, ColumnTitle } from "CustomStyledComponents";

import { disbursementHeader } from "./constants";
import { appendRupeeIcon } from "utils";

const SellerDisbursement = ({
  data = [],
  loading,
  onInitiateDisbursement,
  handleRadioButton,
}) => {
  return (
    <>
      <Typography fontWeight={"bold"} textAlign={"center"} mb={4} mt={4}>
        Do you want to confirm the payout ?
      </Typography>
      {!!data && (
        <Box mb={6}>
          <TableWrapper
            tableHeader={disbursementHeader.map((item) => {
              return (
                <TableCell key={item}>
                  <ColumnTitle>{item}</ColumnTitle>
                </TableCell>
              );
            })}
            tableBody={
              <>
                <StyledTableRow key={data?.shipmentId}>
                  <TableCell>{data?.shipmentNumber}</TableCell>
                  <TableCell>{data?.sellerOrdNumber}</TableCell>
                  <TableCell>{appendRupeeIcon(data?.amount)}</TableCell>
                </StyledTableRow>
                <TableCell
                  sx={{
                    textAlign: "center",
                    padding: 0,
                  }}
                  colSpan={disbursementHeader.length + 1}
                >
                  <FundAccountIdsView
                    value={data?.selectedFundAccountId}
                    onChange={handleRadioButton}
                    fundAccounts={data?.fundAccountDetails}
                  />
                </TableCell>
              </>
            }
          />
        </Box>
      )}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <ButtonV1
          variant="contained"
          color="secondary"
          title="Confirm"
          onClick={onInitiateDisbursement}
        />
      </Box>
    </>
  );
};

export default SellerDisbursement;
