import { dateConverter } from "../makeData";

const numberValidate = (value) => (isNaN(value) ? "" : value);

export const uploadCsvDataMapper = (data = [{}]) => {
  return data?.map((item) => ({
    orderId: item?.["Order ID"],
    entityGstin: item?.["Entity GSTIN"],
    date: dateConverter(item?.["Date"]),
    orderAmount: numberValidate(item?.["Order amount"]),
    advanceAmount: numberValidate(item?.["Advance amount"]),
    creditAmount: numberValidate(item?.["Credit amount"]),
    invoiceComments: item?.["Comments"],
    creditInstrument: item?.["Credit instrument"],
  }));
};

export const resetFieldMapper = () =>
  uploadCsvDataMapper().map((item) =>
    Object.entries(item).reduce((result, [key, value]) => {
      return { ...result, [key]: !!value ? value : "" };
    }, {}),
  );

export const responseDataMapper = ({
  orderId,
  entityGstin,
  date,
  orderAmount,
  advanceAmount,
  creditAmount,
  invoiceComments,
  creditInstrument,
  id,
}) => {
  return {
    orderId,
    entityGstin,
    date,
    orderAmount,
    advanceAmount,
    creditAmount,
    invoiceComments,
    creditInstrument,
    id,
  };
};

export const requestDataMapper = (data = []) => {
  return data.map(
    ({
      orderId,
      entityGstin,
      date,
      orderAmount,
      advanceAmount,
      creditAmount,
      invoiceComments,
      creditInstrument,
      id,
    }) => ({
      orderId,
      entityGstin,
      orderAmount,
      advanceAmount,
      creditAmount,
      creditInstrument,
      date,
      id,
      invoiceComments,
    }),
  );
};
