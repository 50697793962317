export const paymentTypeList = [
  { value: "ADVANCE", label: "ADVANCE" },
  { value: "PART", label: "PART" },
  { value: "FINAL", label: "FINAL" },
];

export const recordedTransactionHeaders = (isCreditOrder) => {
  const header = [
    "Request Initiated",
    "Payment Received",
    "Payment type",
    "Transaction type",
    "Amount",
    "Transaction id",
    ...[isCreditOrder && "Payment date"],
    "Transaction status",
    ...[isCreditOrder && "Action"],
  ];

  return header.filter((head) => typeof head !== "boolean");
};

export const successMessage =
  "Payment requested, transaction will be updated soon";

export const cancelled = "Cancelled";
export const declined = "Declined";

export const paymentType = {
  CASH_AND_CARRY: "Cash & Carry",
};
