import { useEffect, useState } from "react";
import { Tabs, Tab, Box, useTheme, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import DetailsTab from "./DetailsTab";
import ShipmentsTab from "./ShipmentsTab";
import PaymentsTab from "./PaymentsTab";
import ReconciliationTab from "./ReconciliationTab";
import PayoutsTab from "./PayoutsTab";
import DispatchOrder from "./DispatchOrder";
import { useCategoryTypeCheck } from "hooks";

const TabPanel = ({ children, value, selectedTab, tabName, ...rest }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== selectedTab}
      id={`tabpanel-${value}`}
      aria-labelledby={`tab-${value}`}
      {...rest}
    >
      {value === selectedTab && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
};

/**
 *
 * @description Order details tab
 */
const Details = () => {
  const theme = useTheme();
  const [searchparams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const { isPborDirect } = useCategoryTypeCheck();

  const [tab, setTab] = useState("orderDetails");
  const targetTab = searchparams.get("tab");

  const orderDetails = useSelector((state) => state.orderDetails);

  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState({});

  /**
   * @description change tab as per url tab searchparams, and stored orderNumber & url orderNumber should be same
   */
  useEffect(() => {
    if (
      orderDetails?.orderNumber === id &&
      targetTab &&
      orderDetails.hasOwnProperty("lineItemDetails")
    ) {
      setTab(targetTab);
    } else {
      searchparams.delete("tab");
      searchparams.delete("shipmentId");
      setSearchParams(searchparams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetails?.orderNumber, searchparams]);

  const getTotalInvoiceAmount = (totalInvoiceAmount) => {
    setTotalInvoiceAmount(totalInvoiceAmount);
  };

  /**
   *
   * @description Set selected tab in state
   */
  const handleTabChange = (newValue) => {
    setTab(newValue);
    searchparams.set("tab", newValue);
    if (newValue !== "shipments") {
      searchparams.delete("shipmentId");
    }
    setSearchParams(searchparams);
  };

  return (
    <Box>
      {!!(orderDetails?.customerFreezed || orderDetails?.isCreditBlocked) && (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          padding={8}
          sx={{
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.dark,
          }}
        >
          {orderDetails?.customerFreezed ? (
            <Typography>
              Customer has overdue invoices (DPD). Please check with credit team
              before processing this order
              {orderDetails?.freezeReason
                ? `(${orderDetails?.freezeReason})`
                : ""}
            </Typography>
          ) : (
            <Typography>
              Credit limit blocked for customer{" "}
              {orderDetails?.creditBlockedReason
                ? `(${orderDetails?.creditBlockedReason})`
                : ""}
            </Typography>
          )}
        </Box>
      )}
      <Box sx={{ borderBottom: 1, borderColor: "lightgrey" }}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Tabs
            value={tab}
            onChange={(_event, newValue) => {
              handleTabChange(newValue);
            }}
            sx={{
              ">.MuiTabs-scroller": {
                ">.MuiTabs-indicator": {
                  backgroundColor: theme.palette.common.black,
                },

                ">.MuiTabs-flexContainer>.Mui-selected": {
                  fontWeight: 600,
                  color: theme.palette.common.black,
                },
              },
            }}
            aria-label="Order details tab"
          >
            <Tab label="Order details" value="orderDetails" />
            <Tab label="Payments" value="payments" />
            <Tab label="Shipments" value="shipments" />
            <Tab label="Payouts" value="payouts" />
            <Tab label="Reconciliation" value="reconciliation" />
            {isPborDirect && (
              <Tab label="Dispatch Order" value="dispatchOrder" />
            )}
            {/* <Tab label="Dispatch Order" value="dispatchOrder" /> */}
          </Tabs>
          <>
            {!!orderDetails?.isZohoCustomer && (
              <Typography color={theme.palette.success.dark}>
                zoho-flow
              </Typography>
            )}
          </>
        </Box>
      </Box>
      <TabPanel value="orderDetails" selectedTab={tab}>
        <DetailsTab />
      </TabPanel>

      <TabPanel value="payments" selectedTab={tab}>
        <PaymentsTab
          getTotalInvoiceAmount={(totalInvoiceAmount) =>
            getTotalInvoiceAmount(totalInvoiceAmount)
          }
        />
      </TabPanel>
      <TabPanel value="shipments" selectedTab={tab}>
        <ShipmentsTab totalInvoiceAmount={totalInvoiceAmount} />
      </TabPanel>
      {/* <TabPanel value="plannedShipment" selectedTab={tab}>
        <PlannedShipment />
      </TabPanel> */}
      <TabPanel value="payouts" selectedTab={tab}>
        <PayoutsTab />
      </TabPanel>
      <TabPanel value="reconciliation" selectedTab={tab}>
        <ReconciliationTab />
      </TabPanel>
      <TabPanel value="dispatchOrder" selectedTab={tab}>
        <DispatchOrder />
      </TabPanel>
    </Box>
  );
};

export default Details;
